import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import { BOOLEAN, OBJECT, TEXT } from "constants/CommonConstants";
import {
  RudderStackAutomationBuilderContext,
  RudderStackAutomationBuilderEvents,
} from "constants/RudderStackConstants";
import { useJSEditor } from "lowcode/hooks/useJSEditor";
import { setScriptEditorPayload } from "lowcode/state/scriptOpenState";
import { EditorScriptLocation } from "lowcode/state/types/types";
import getInputType from "lowcode/util/getInputType";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import notification from "notifications/notifications";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { recordRSEvent, removeBraces, stopPropagate } from "utils/CommonUtils";
import {
  getLowCodeEditorDefaultReturnText,
  getLowCodeEditorText,
} from "utils/Utils";
import InputTempId from "views/automationBuilder/components/InputTempId";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import usePayload from "views/automationBuilder/hooks/usePayload";
import { DataFieldType } from "views/DataField/DataField.types";
import useWorkflow from "views/workflows/hooks/useWorkFlow";
import { setSelectedAction } from "views/workflows/reducers/workflowAction";
import ErrorMessageInput from "views/workflows/workflowactions/api/components/ErrorMessageInput";
import ExecuteCondition from "views/workflows/workflowactions/api/components/ExecuteCondition";
import IsActive from "views/workflows/workflowactions/api/components/IsActive";
import {
  saveGuard,
  ValidLocation,
} from "views/workflows/workflowactions/api/utils/utils";
import DatafieldSelector from "../../DatafieldSelector";
import { Heading, HeadingWrapper, Label } from "../../ModalContent.styles";
import ModalSmartInputTextField from "../components/ModalSmartInputTextField";
import "../PushNotification.css";
import {
  generateTempId,
  getActionNameForTempId,
  isTempIdUnique,
} from "../utils/utils";
import ErrorHandlingModal from "../APIAction/ErrorHandlingModal";
import { cloneDeep } from "lodash";
import { LuSettings2 } from "react-icons/lu";

const ExpressionGuard = () => {
  const dispatch = useDispatch();
  //@ts-ignore
  const {
    selectedAction,
    selectedTransition,
    selectedDecisionNode,
    selectedSystemTransition,
    getScriptValue,
    dataPicker,
  } = useWorkflow();

  const {
    selectedActionBlock,
    selectedAutomation,
    clearRightSide,
    decisionSwitchAutomation,
  } = useAutomation();

  const { openJSEditorLayout, scriptEditorOpen } = useJSEditor();
  const { actionConditionPayload } = usePayload();
  const [isEditing, setIsEditing] = useState(false);
  const [tempIdChanged, setTempIdChanged] = useState(true);
  const [currTempIdVal, setCurrTempIdVal] = useState(
    selectedAction?.tempId || ""
  );
  const [openErrorHandlingModal, setOpenErrorHandlingModal] = useState(false);

  // This function gets called when the user click on save button in Modal.
  // If the user is setting switch in choice node, updateConditionSwitch is called
  // else if user is setting up normal guard, updateGuard will be called.

  const onFormSubmit = (data: any) => {
    let updatedExpression = selectedAction?.expression;
    let updatedErrorMessage = selectedAction?.errorMessage;
    let tempActionId: string = selectedAction?.tempId || data?.tempId;
    if (!isEditing) {
      tempActionId =
        currTempIdVal.length === 0
          ? selectedAction?.actionId ||
            selectedAction?.guardId ||
            selectedAction?.conditionId
          : currTempIdVal;
      dispatch(setSelectedAction({ ...selectedAction, tempId: currTempIdVal }));
    }

    let isTempIdChanged = tempIdChanged;
    if (
      tempActionId ===
      (selectedAction?.actionId ||
        selectedAction?.guardId ||
        selectedAction?.conditionId)
    ) {
      setTempIdChanged(false);
      isTempIdChanged = false;
    }

    if (isTempIdChanged && !isTempIdUnique(tempActionId, selectedAutomation)) {
      setError("tempId", {
        type: "manual",
        message: "This ID already exists. Please choose a different one.",
      });
      return;
    }

    if (!data.guardName) {
      setError("guardName", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    if (data.guardName.length < 3) {
      setError("guardName", {
        type: "manual",
        message: "Function Name must have more than 3 characters",
      });
      return;
    }

    if (!decisionSwitchAutomation) {
      if (!updatedErrorMessage?.value) {
        setError("errorMessage", {
          type: "manual",
          message: "This is a required field",
        });
        return;
      }

      //@ts-ignore
      if (updatedErrorMessage?.value?.length < 3) {
        setError("errorMessage", {
          type: "manual",
          message: "Error message must have more than 3 characters",
        });
        return;
      }
    }

    if (
      !selectedAction[
        EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE
      ]?.value
    ) {
      notification("error", "Expression is Mandatory");
      return;
    }

    let payload = {
      ...data,
      guardId: tempActionId,
      tempId: tempActionId,
      guardType: selectedAction.guardType,
      expression: updatedExpression,
      errorMessage: updatedErrorMessage,
      transitionId: selectedTransition?.id,
      isActive: selectedAction?.isActive ?? true,
      [EditorScriptLocation.EXPRESSION_GUARD_CONDITION]:
        selectedAction[EditorScriptLocation.EXPRESSION_GUARD_CONDITION] ||
        undefined,
      retries: parseInt(selectedAction?.retries) || 3,
      delay: parseInt(selectedAction?.delay) || 2000,
      exponential: data.exponential === "exponential" ? true : false,
      isRetryEnabled: data.isRetryEnabled === "retryEnabled" ? true : false,
      exponentialFactor: parseInt(selectedAction?.exponentialFactor) || 2,
      [EditorScriptLocation.AB_SMART_ACTION_JS_FUNCTION]:
        selectedAction[EditorScriptLocation.AB_SMART_ACTION_JS_FUNCTION] ||
        undefined,

      [EditorScriptLocation.API_ACTION_SHOULD_RETRY]: selectedAction[
        EditorScriptLocation.API_ACTION_SHOULD_RETRY
      ] || {
        type: "script",
        dataType: BOOLEAN,
        value: `return false`,
      },
    };

    recordRSEvent(RudderStackAutomationBuilderEvents.expressionGuardSaveClick, {
      context: RudderStackAutomationBuilderContext.automationBuilderGuard,
      automationId: selectedAutomation.id,
      guardType: selectedAction.guardType,
      guardId: selectedAction.guardId,
    });

    saveGuard({
      payload,
      location: ValidLocation.AUTOMATION,
      dispatch,
      selectedAction,
    });

    clearRightSide();
  };

  const handleValChange = (name:any, value:any) => {
    dispatch(
      setSelectedAction({ ...selectedAction, [name]: value, hasChanged: true })
    );
  };

  const ShowCurrentActionPayload = cloneDeep(actionConditionPayload);
  if (ShowCurrentActionPayload.payload.inclusions.length > 0) {
    //@ts-ignore
    if (ShowCurrentActionPayload.payload.inclusions[0].config) {
      //@ts-ignore
      ShowCurrentActionPayload.payload.inclusions[0].config.showCurrentAction =
        true;
        //@ts-ignore
      ShowCurrentActionPayload.payload.inclusions[0].config.actionName =
        selectedAction?.actionName || selectedAction?.guardName;
    }
  }

  useEffect(() => {
    recordPageVisit(
      PageCategoryEnum.automationBuilder,
      RudderStackAutomationBuilderEvents.expressionGuardOnLoad
    );
  }, []);

  const tempId = generateTempId(
    selectedAction,
    selectedAutomation,
    getActionNameForTempId(selectedAction),
    "guard"
  )

  const {
    register,
    setValue,
    handleSubmit,
    control,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      guardName: selectedAction?.guardName,
      tempId: tempId,
      errorMessage:
        selectedAction?.errorMessage?.type === "script"
          ? "Smart Script"
          : selectedAction?.errorMessage?.value,
      isActive: selectedAction?.isActive ?? true,
      expression:
        selectedAction?.expression?.type === "script"
          ? "Smart Script"
          : selectedAction?.expression?.value,
      retries: selectedAction?.retries || 3,
      delay: selectedAction?.delay || 2000,
      exponentialFactor: selectedAction?.exponentialFactor || 2,
      isRetryEnabled:
        selectedAction?.isRetryEnabled === true
          ? "retryEnabled"
          : "retryDisabled",
      exponential:
        selectedAction?.exponential === undefined ||
        selectedAction?.exponential === false
          ? "uniform"
          : "exponential",
      [EditorScriptLocation.API_ACTION_SHOULD_RETRY]:
        selectedAction[EditorScriptLocation.API_ACTION_SHOULD_RETRY] ||
        `return false`,
    },
  });

  const expressionGuardConfig = {
    expressionConfig: {
      script: selectedAction[
        EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE
      ]
        ? selectedAction[
            EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE
          ]["value"]
        : getLowCodeEditorText(
            EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE,
            DataFieldType.BOOLEAN,
            `return ${getLowCodeEditorDefaultReturnText(DataFieldType.BOOLEAN)}`
          ),
      dataType: DataFieldType.BOOLEAN,
    },
    ExpressionGuardCondition: {
      script:
        getScriptValue(EditorScriptLocation.EXPRESSION_GUARD_CONDITION) ||
        getLowCodeEditorText(
          EditorScriptLocation.EXPRESSION_GUARD_CONDITION,
          DataFieldType.BOOLEAN,
          `return ${getLowCodeEditorDefaultReturnText(DataFieldType.BOOLEAN)}`
        ),
      dataType: DataFieldType.BOOLEAN,
    },
    ErrorMessageConfig: {
      script:
        getScriptValue(EditorScriptLocation.ERROR_MESSAGE) ||
        getLowCodeEditorText(
          EditorScriptLocation.ERROR_MESSAGE,
          DataFieldType.TEXT,
          `return ${getLowCodeEditorDefaultReturnText(DataFieldType.TEXT)}`
        ),
      dataType: TEXT,
    },
    ShouldRetry: {
      script:
        getScriptValue(EditorScriptLocation.API_ACTION_SHOULD_RETRY) ||
        getLowCodeEditorText(
          EditorScriptLocation.API_ACTION_SHOULD_RETRY,
          //@ts-ignore
          BOOLEAN,
          `return false`
        ),
      dataType: BOOLEAN,
    },
  };

  const handleErrorMessageChange = (e: any) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        [EditorScriptLocation.ERROR_MESSAGE]: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: TEXT,
        },
      })
    );
  };

  const handleTempActionValChange = (name:any, value:any) => {
    setCurrTempIdVal(value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setTempIdChanged(true);
  };

  const handleTickClick = () => {
    setIsEditing(false);
  };

  const setTempIdError = (error:any) => {
    if (error) {
      setError("tempId", { type: "manual", message: error });
    } else {
      clearErrors("tempId");
    }
  };

  return (
    <>
      <form
        onSubmit={stopPropagate(handleSubmit(onFormSubmit))}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div className="">
          <HeadingWrapper>
            <Heading> Expression Check </Heading>
            <IsActive control={control} closeOnClick={clearRightSide} />
          </HeadingWrapper>
          <Label> Name </Label>

          <ModalSmartInputTextField
            value={selectedAction?.guardName}
            placeholder="Name this expression Guard"
            dataType={DataFieldType.TEXT}
            register={register}
            control={control}
            name="guardName"
            errors={errors}
          />

          <InputTempId
            label="Validation Id"
            onChange={handleTempActionValChange}
            register={register}
            requiredErrorMessage="Validation Id is required"
            //@ts-ignore
            errors={errors}
            isReadOnly={!isEditing}
            handleTickClick={handleTickClick}
            handleEditClick={handleEditClick}
            getValues={getValues}
            setTempIdError={setTempIdError}
            isEditing={isEditing}
            name="tempId"
          />

          <ExecuteCondition
            showExpressionActive={
              selectedAction[EditorScriptLocation.EXPRESSION_GUARD_CONDITION]
                ?.value
                ? true
                : false
            }
            conditionLabel="Write Conditional Expression (Optional)"
            handleClick={() => {
              dispatch(setScriptEditorPayload(actionConditionPayload));

              openJSEditorLayout({
                config: expressionGuardConfig.ExpressionGuardCondition,
                editorLocation: EditorScriptLocation.EXPRESSION_GUARD_CONDITION,
              });
            }}
          />

          <ExecuteCondition
            showExpressionActive={
              selectedAction[
                EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE
              ]?.value
                ? true
                : false
            }
            conditionLabel="Write Expression to Execute"
            handleClick={() => {
              dispatch(setScriptEditorPayload(actionConditionPayload));
              openJSEditorLayout({
                config: expressionGuardConfig.expressionConfig,
                editorLocation:
                  EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE,
              });
            }}
          />
          <div
            onClick={() => setOpenErrorHandlingModal(true)}
            style={{
              color: "#3e66fb",
              fontWeight: 500,
              cursor: "pointer",
            }}
          >
            <LuSettings2
              style={{
                display: "inline",
              }}
            />{" "}
            Error Handling
          </div>

          {!decisionSwitchAutomation && (
            <div className="pt-4">
              <ErrorMessageInput
                label={"Error Message to be shown if the condition fails"}
                onChangeFunc={handleErrorMessageChange}
                register={register}
                errors={errors}
                apiBodyPayload={actionConditionPayload}
                openJSEditorLayout={openJSEditorLayout}
                config={expressionGuardConfig.ErrorMessageConfig}
              />
            </div>
          )}
        </div>
      </form>
      {scriptEditorOpen && <ZJSEditorLayout />}
      {dataPicker?.isOpened && <DatafieldSelector setValue={setValue} />}
      {openErrorHandlingModal && (
        <ErrorHandlingModal
          openErrorHandlingModal={openErrorHandlingModal}
          handleModalClose={() => {
            setOpenErrorHandlingModal(false);
          }}
          register={register}
          //@ts-ignore
          handleValChange={handleValChange}
          actionExecutionScriptConfig={expressionGuardConfig}
          selectedAction={selectedAction}
          ShowCurrentActionPayload={ShowCurrentActionPayload}
          //@ts-ignore
          control={control}
          getValues={getValues}
          watch={watch}
        />
      )}
    </>
  );
};

export default ExpressionGuard;
