//@ts-nocheck
import { useForm, FormProvider, useWatch } from "react-hook-form"
import styled from "styled-components"
import { CurlModal } from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents"
import { useDispatch } from "react-redux"
import {
  getEntityMetadata,
  setActiveEditColumn,
  setShowCreationModal,
} from "../reducers/entityReducers"
import { ColorPalets, Text, Flex } from "../styles"
import { AiOutlineCalendar, AiOutlineClose, AiOutlineLink } from "react-icons/ai"
import ZInput from "views/commonComponents/ZTextInput"
import ZSelect from "views/commonComponents/ZSelect"
import {
  ZButton,
  ZTextField,
} from "views/commonComponents/commonComponents.styles"
import useEntity from "../hooks/useEntity"
import notification from "notifications/notifications"
import { BiCopy as CopyIcon } from "react-icons/bi";
import {
  OnboardingIdsForComponent,
  recordRSEvent,
  removeSpecialCharacters,
} from "utils/CommonUtils"
import { DataFieldType, EntityTypeField } from "views/DataField/DataField.types"
import { useState } from "react"
import { columnNameErrorSchema } from "views/DataField/DataFieldErrorSchema"
import ErrorMessage from "views/DataField/ErrorMessage"
import { Label } from "views/DataField/DataField.styles"
import ColumnProps from "./ColumnProps"
import ConfirmationDialog from "views/automationBuilder/components/ConfirmationDialog"
import { isObject } from "lodash"
import { components } from "react-select"
import { IRelationReferringEntityType, TableRSEvents } from "../types"
import useQuery from "hooks/useQuery"
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog"
import {
  AutomationRIcon,
  CalendarRIcon,
  ComputedRIcon,
  DropdownRIcon,
  FileRIcon,
  ImageRIcon,
  IntegerRIcon,
  LocationRIcon,
  RelationRIcon,
  TextRIcon,
  ToggleRIcon,
  TrashRIcon,
  UserRIcon,
} from "assests"
import { ButtonContainerModal } from "../tables/table.styles"
import { LuClock } from "react-icons/lu"
import { TbUsers } from "react-icons/tb"
import { EntityService } from "providers/data/services/EntityService"

const Box = styled.div`
  background: #ffffff;
  display: flex;
  padding: 24px;
  flex-direction: column;
  width: 400px;
  border-radius: 4px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
`

type Props = {}

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${(props: any) => (props?.isAdd ? "#F5F8FF" : "")};
  color: ${(props: any) =>
    props?.isAdd ? `${props.theme.color.primary}` : "black"};
`

const LabelSpan = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
`

const { Option, SingleValue } = components
const IconOption = (props: any) => {
  return (
    <>
      <Option {...props}>
        <OptionWrapper isAdd={props.data.value === "newEntity"}>
          <div>
            <p style={{ fontSize: "12px", fontWeight: 500 }}>
              {props.data.data.icon}
            </p>
          </div>
          <div>{props.data.label}</div>
        </OptionWrapper>
      </Option>
    </>
  )
}

const SingleValueDiv = ({ children, ...props }: any) => (
  <SingleValue {...props}>
    {props.data.data.icon}
    {children}
  </SingleValue>
)

const ColumnCreation = (props: Props) => {
  const dispatch = useDispatch()
  const onModalClose = () => {
    dispatch(setShowCreationModal(false))
    dispatch(setActiveEditColumn(null))
  }

  const query = useQuery()

  const [showDialog, setShowDialog] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)

  const { selectedEntityMetadata, selectedEntity, activeEditColumn } =
    useEntity()
  const [loading, setLoading] = useState(false)

  /**
   * defaultValues will be null in case of create
   * defaultValues will have current column data in case of edit
   *
   * Some modification will be done in defaultValues in case of edit as they are not directly mapped to form fields
   * for example:
   * 1. oneToManyRelation
   * 2. oneToOneRelation
   * 3. relationEntity
   */
  const methods = useForm({
    defaultValues: {
      ...activeEditColumn,
      type:
        activeEditColumn?.type === "RELATION" &&
          (activeEditColumn?.relationDetails?.referringEntity ===
          IRelationReferringEntityType.USER)
          ? "USER"
          : (activeEditColumn?.relationDetails?.referringEntity ===
            IRelationReferringEntityType.TEAM)
            ? "TEAM"
          : activeEditColumn?.type,
      relation: activeEditColumn?.relationDetails?.cardinality === "MANY",
      isDisplayColumn: activeEditColumn?.otherDetails?.isDisplayColumn || false,
      isUnique: activeEditColumn?.isUnique || undefined,
      referringEntityType: activeEditColumn?.relationDetails?.referringEntityType,
      referringEntity: activeEditColumn?.relationDetails?.referringEntity,
      // createReferenceColumnInTarget: true,
      isMultiselect: activeEditColumn?.otherDetails?.isMultiselect || false,
      allowColorCoding: activeEditColumn?.properties?.color ? true : false,
      listOfValueArray: activeEditColumn?.properties?.options?.map(
        (item: any) => {
          return {
            value: item,
            color: activeEditColumn?.properties?.color?.[item],
          }
        }
      ),
    },
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = methods

  const type = useWatch({
    control,
    name: "type",
  })

  /**
   * Same function will be called in case of create and edit
   * in edit, we dont allow to change the following properties
   * 1. name (display name can be changed)
   * 2. type
   *
   * Properties that can be changed:
   * 1. displayName
   * 2. mandatory
   * 3. isDisplayColumn
   * 4. defaultValue
   * 5. isCreateTimeField
   *
   * activeEditColumn will be null in case of create and will have current column data in case of edit
   *
   *
   * @param data
   * @returns
   */
  const onSubmit = async (data: any) => {

    // add name to the custom fields
    const trimmedName = data?.name?.trim() || data?.displayName?.trim() || "";
    const prevCustomFields = selectedEntityMetadata?.customFields || []
    const restrictedKeywords: string[] = [
      "teamIds",
      "recordId",
      "createdOn",
      "updatedOn",
      "accountId",
      "_id",
      "tableType",
      "tableName",
      "isDeleted",
      "version",
      "teamIds",
      "data",
      "comments",
      "userName",
      "userId",
      "userPhoneNumber"
  ];
    if(restrictedKeywords.includes(trimmedName)) {
      setError("displayName", {
        type: "manual",
        message: `* Field name ${trimmedName} is restricted.`,
      })
      setLoading(false)
      return
    }

    // make sure display column is mandatory
    if (data.isDisplayColumn && !data.mandatory) {
      setError("mandatory", {
        type: "manual",
        message: "Display column must be mandatory",
      })
      return
    }

    // convert displayName to name
    if (!activeEditColumn?.type) {
      data.name = removeSpecialCharacters(data.displayName)
      data.type = data?.type?.value
      const columnTypeExists = data?.type ? true : false
      if(!columnTypeExists) {
        setError("columnType", {
          type: "manual",
          message: "Select any column type to proceed",
        })
        return 
      }   
    }

    data.otherDetails = {
      ...data?.otherDetails,
      isDisplayColumn: data.isDisplayColumn,
    }

    delete data.isDisplayColumn

    if (data.type === EntityTypeField.USER || data.type === EntityTypeField.TEAM) {
    
      let referringEntity = data.type
        // change it RELATION type
      data.type = EntityTypeField.RELATION
      
      data.relationDetails = {
        cardinality: data.relation ? "MANY" : "ONE",
        referringEntity: referringEntity,
        referringEntityType: null,
      }
    } 
    else if (data.type === EntityTypeField.RELATION) {
      const referringColumns = [];
      const columnsInRelatedTable = [];
      const createReferenceColumnInTarget = activeEditColumn?.relationDetails?.createReferenceColumnInTarget || data?.columnOption === 'createReferenceColumnInTarget' ? true : false
      const columnsInEditMode = activeEditColumn?.relationDetails?.relatedEntities?.[0]?.columns;
      if(!data?.columnOption && data?.referringEntityColumn) data.referringEntityColumn = undefined;
      const colCount = data?.numberOfColumnsInRelatedTable;
      if(colCount >= 0 ) {
        columnsInRelatedTable.push(`${selectedEntity?.tableDisplayName?.trim()?.replaceAll(' ', '_')}${colCount === 0 ? '' : '_' + colCount}`)
      }
      if(data?.referringEntityColumn?.value) referringColumns.push(data.referringEntityColumn.value)
      const referringEntityType = isObject(data.referringEntityType)
        ? data.referringEntityType.value
        : data.referringEntityType
      data.relationDetails = {
        cardinality: data.relation ? "MANY" : "ONE",
        referringEntity: data.referringEntity,
        referringEntityType: referringEntityType,
        createReferenceColumnInTarget: (createReferenceColumnInTarget === true) ? true : data?.referringEntityColumn ? false : false,
        manageRelatedEntity : data.manageRelatedEntity ? data.manageRelatedEntity: true,
        relatedEntities: [
          {
            tableType: referringEntityType,
            columns: columnsInEditMode ? columnsInEditMode :createReferenceColumnInTarget ? columnsInRelatedTable : data?.referringEntityColumn ? referringColumns : [],
          }
        ]
      }
      data.defaultValue = null

      delete data.oneToManyRelation
      delete data.oneToOneRelation
      delete data.referringEntityType
      delete data.relation
      delete data.columnOption
      delete data.referringEntityColumn
      delete data.createReferenceColumnInTarget
      delete data.numberOfColumnsInRelatedTable
    } else if (data.type === EntityTypeField.COMPUTED) {
      //@ts-ignore
      if (activeEditColumn?.script) {
        data.otherDetails = { ...data.otherDetails, expression: activeEditColumn?.script }
      }
    } else if (data.type === EntityTypeField.BUTTON) {
      // edit only if script is present, else take the older value
      if (activeEditColumn?.script) {
        data.url = {
          link: activeEditColumn?.script,
        }
      }
    }

    // if type is LIST_OF_TEXT
    else if (data.type === DataFieldType.LIST_OF_TEXT) {
      if (data.allowColorCoding) {
        data.listOfValueArray = data.listOfValueArray.map((item: any) => {
          return {
            ...item,
            color: item.color,
          }
        })

        /**
         * "properties": {
                "color": {
                    "PENDING": "RED",
                    "DONE": "GREEN"
                }
            }
         */

        data.properties = {
          options: data.listOfValueArray.map((item: any) => item.value?.trim()),
          color: data.listOfValueArray.reduce((acc: any, item: any) => {
            acc[item.value.trim()] = item.color
            return acc
          }, {}),
        }

        // remove listOfValueArray
        delete data.listOfValueArray
      } else {
        data.properties = {
          options: data.listOfValueArray.map((item: any) => item.value?.trim()),
        }

        // remove listOfValueArray
        delete data.listOfValueArray
        //
      }
      data.otherDetails = {
        isMultiselect: data.isMultiselect,
      }

      // set defaultValue from prevCustomFields
      const index = prevCustomFields.findIndex(
        (item: any) => item.name === data.name
      )

      if (index > -1) {
        data.defaultValue = prevCustomFields[index].defaultValue
      }

      // remove isMultiselect
      delete data.isMultiselect
    } else if (data.type === DataFieldType.LOCATION) {
      data = {
        ...data,
        address: data?.address,
        mandatory: data?.mandatory ?? false,
        isCreateTimeField: data?.isCreateTimeField ?? false,
        children: [
          {
            name: "address",
            displayName: "Address",
            description: "Address",
            type: "TEXT",
            defaultValue: data?.location?.address || "",
          },
          {
            name: "latitude",
            displayName: "Latitude",
            description: "Latitude",
            type: "NUMBER",
            defaultValue: data?.location?.latitude,
          },
          {
            name: "longitude",
            displayName: "Longitude",
            description: "Longitude",
            type: "NUMBER",
            defaultValue: data?.location?.longitude,
          },
        ],
      }
      data.location = undefined
    }

    const actionCols =
      selectedEntityMetadata?.tableMetadata?.automations?.actions || {}

    const prevCustomFieldCopy = [...prevCustomFields]
    // get keys of action cols
    const actionColKeys: string[] = Object.keys(actionCols)
    // loop through each and push data to prevCustomFields
    actionColKeys.forEach((key: string) => {
      prevCustomFieldCopy.push(actionCols[key])
    })

    /**
     * if activeEditColumn is null, then we are creating a new column
     * if activeEditColumn is not null, then we are editing a column
     *
     */
    if (!activeEditColumn?.type) {
      // display name of prevCustomFieldCopy
      const displayName = prevCustomFieldCopy.map(
        (item: any) => item.displayName
      )

      // check if display name already exists
      if (displayName.includes(data.displayName)) {
        notification("error", "Column name already exists")
        return
      }
    }

    /**
     * if activeEditColumn is not null, then we are editing a column
     * if activeEditColumn is null, then we are creating a new column
     *
     * get index of activeEditColumn in prevCustomFieldCopy
     * replace the data at that index with the new data
     */

    // isCreateTimeField is true
    data.isCreateTimeField = true

    let payload = {}
    if (activeEditColumn?.type) {
      const index = prevCustomFieldCopy.findIndex(
        (item: any) => item.name === activeEditColumn.name
      )
      prevCustomFieldCopy[index] = data
      if (isDeleted) prevCustomFieldCopy[index] = {
        ...prevCustomFieldCopy[index],
        isDeleted: true,
      }
      payload = {
        tableType: selectedEntity?.tableType,
        tableDisplayName: selectedEntity?.tableDisplayName,
        customFields: [...prevCustomFieldCopy],
        action: "EDIT",
        actionPayload: {
          ...data,
          displayName: data?.displayName?.trim(),
        }
      }
    } else {
      payload = {
        tableType: selectedEntity?.tableType,
        tableDisplayName: selectedEntity?.tableDisplayName,
        customFields: [...prevCustomFieldCopy, data],
        action: "CREATE",
        actionPayload: {
          ...data,
          displayName: data?.displayName?.trim(),
        }
      }
    }

    try {
      setLoading(true)
      await EntityService.updateEntity(payload)
      if (isDeleted) setIsDeleted(false);
      const notificationMessage = activeEditColumn?.type
        ? isDeleted ? "Column deleted successfully" : "Column edited successfully"
        : "Column created successfully"
      notification("success", notificationMessage)
      dispatch(getEntityMetadata(selectedEntity?.tableType || ""))
      onModalClose()
      setLoading(false)
      activeEditColumn?.type
        ? recordRSEvent(TableRSEvents.EDIT_SINGLE_RECORD_SUCCESS, {
          tableType: selectedEntity?.tableType,
        })
        : recordRSEvent(TableRSEvents.CREATE_NEW_COLUMN_SUCCESS, {
          tableType: selectedEntity?.tableType,
        })

      const localStorageValForViews = localStorage.getItem(`tableState_${selectedEntity?.tableType}_${query.get("viewId")}`)
      if (localStorageValForViews) {
        const localStorageValForViewsParsed = JSON.parse(localStorageValForViews)
        if (!activeEditColumn?.type) {
          delete localStorageValForViewsParsed['columnOrdering']
          localStorage.setItem(
            `tableState_${selectedEntity?.tableType}_${query.get("viewId")}`,
            JSON.stringify(localStorageValForViewsParsed)
          )
        }
      }

    } catch (error) {
      console.log(error)
      notification("error", "Something went wrong")
      setIsDeleted(false)
      setLoading(false)
      activeEditColumn?.type
        ? recordRSEvent(TableRSEvents.EDIT_SINGLE_RECORD_FAILURE, {
          tableType: selectedEntity?.tableType,
        })
        : recordRSEvent(TableRSEvents.CREATE_NEW_COLUMN_FAILURE, {
          tableType: selectedEntity?.tableType,
        })
    }
  }

  const copyToClipboard = (text: string) => {
    if(text) {
      navigator.clipboard.writeText(text)
      notification("success", "Copied to clipboard")
    } 
  }

  // const handleDeleteClick = async () => {
  //   // get all the customFields
  //   const prevCustomFields = selectedEntityMetadata?.customFields || []
  //   // get all the action columns
  //   const actionCols =
  //     selectedEntityMetadata?.tableMetadata?.automations?.actions || {}

  //   // merge both customFields and actionCols
  //   const prevCustomFieldCopy = [...prevCustomFields]
  //   // get keys of action cols
  //   const actionColKeys: string[] = Object.keys(actionCols)
  //   // loop through each and push data to prevCustomFields
  //   actionColKeys.forEach((key: string) => {
  //     prevCustomFieldCopy.push(actionCols[key])
  //   })

  //   // get activeEditColumn index
  //   const index = prevCustomFieldCopy.findIndex(
  //     (item: any) => item.name === activeEditColumn.name
  //   )

  //   // add isDeleted: true
  //   prevCustomFieldCopy[index] = {
  //     ...prevCustomFieldCopy[index],
  //     isDeleted: true,
  //   }

  //   const payload = {
  //     tableType: selectedEntity?.tableType,
  //     tableDisplayName: selectedEntity?.tableDisplayName,
  //     action: "UPDATE",
  //     actionPayload: {
  //       ...data
  //     },
  //     customFields: [...prevCustomFieldCopy],
  //   }

  //   try {
  //     await EntityService.updateEntity(payload)
  //     const notificationMessage = "Column deleted successfully"
  //     notification("success", notificationMessage)
  //     dispatch(initEntities(selectedEntity?.tableType))
  //     dispatch(getEntityMetadata(selectedEntity?.tableType || ""))
  //     onModalClose()
  //     setLoading(false)
  //     recordRSEvent(TableRSEvents.DELETE_COLUMN_SUCCESS, {
  //       tableType: selectedEntity?.tableType,
  //     })
  //     const localStorageValForViews = localStorage.getItem(`tableState_${selectedEntity?.tableType}_${query.get("viewId")}`)
  //     if (localStorageValForViews) {
  //       const localStorageValForViewsParsed = JSON.parse(localStorageValForViews)
  //       if (!activeEditColumn?.type) {
  //         delete localStorageValForViewsParsed['columnOrdering']
  //         localStorage.setItem(
  //           `tableState_${selectedEntity?.tableType}_${query.get("viewId")}`,
  //           JSON.stringify(localStorageValForViewsParsed)
  //         )
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     notification("error", "Something went wrong")
  //     setLoading(false)
  //     recordRSEvent(TableRSEvents.DELETE_COLUMN_FAILURE, {
  //       tableType: selectedEntity?.tableType,
  //     })
  //   }
  // }

  const handleCreateClick = () => {
    const dontShowColumnCreateConfirmation = localStorage.getItem(
      "dontShowColumnCreateConfirmation"
    )
    if (dontShowColumnCreateConfirmation === "true") {
      handleSubmit(onSubmit)()
      return
    } else {
      setShowDialog(true)
    }
  }

  const getSelectOption = () => {
    return [
      {
        value: DataFieldType.TEXT,
        label: "Text",
        data: {
          icon: <TextRIcon color="#667085" />,
        },
      },
      {
        value: DataFieldType.NUMBER,
        label: "Number",
        data: {
          icon: <IntegerRIcon color="#667085" />,
        },
      },
      {
        value: DataFieldType.BOOLEAN,
        label: "Boolean",
        data: {
          icon: <ToggleRIcon color="#667085" />,
        },
      },
      {
        value: DataFieldType.DATE,
        label: "Datetime",
        data: {
          icon: <CalendarRIcon color="#667085" size="1.2rem" />,
        },
      },
      {
        value: DataFieldType.IMAGE,
        label: "Image",
        data: {
          icon: <ImageRIcon color="#667085" />,
        },
      },
      {
        value: DataFieldType.LIST_OF_TEXT,
        label: "Dropdown",
        data: {
          icon: <DropdownRIcon color="#667085" />,
        },
      },
      {
        value: DataFieldType.FILE,
        label: "File",
        data: {
          icon: <FileRIcon color="#667085" />,
        },
      },
      {
        value: DataFieldType.LOCATION,
        label: "Location",
        data: {
          icon: <LocationRIcon color="#667085" />,
        },
      },
      {
        value: DataFieldType.TIME,
        label: "Time",
        data: {
          icon: <LuClock size="1.8rem" color="#334053" />,
        },
      },
      {
        value: DataFieldType.ONLY_DATE,
        label: "Date",
        data: {
          icon:<AiOutlineCalendar size="1.8rem" color="#334053" />
        },
      },
      {
        value: DataFieldType.LINKS,
        label: "Links",
        data: {
          icon: <AiOutlineLink size="1.8rem" color="#334053" />,
        },
      },
      {
        value: EntityTypeField.COMPUTED,
        label: "Computed Column",
        data: {
          icon: <ComputedRIcon color="#667085" />,
        },
      },
      {
        value: EntityTypeField.RELATION,
        label: "Relation",
        data: {
          icon: <RelationRIcon color="#667085" size="1.2rem" />,
        },
      },
      {
        value: EntityTypeField.USER,
        label: "User",
        data: {
          icon: <UserRIcon color="#667085" size="1.2rem" />,
        },
      },
      {
        value: EntityTypeField.TEAM,
        label: "Team",
        data: {
          icon: <TbUsers color="#344054" strokeWidth="2" size="1.7rem" />,
        },
      },
      {
        value: EntityTypeField.BUTTON,
        label: "Button",
        data: {
          icon: <AutomationRIcon color="#667085" size="1.2rem" />,
        },
      },
    ]
  }

  return (
    <CurlModal open={true} onClose={onModalClose}>
      <FormProvider {...methods}>
        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          style={{ padding: "0px" }}
        >
          <div
            style={{
              padding: "24px",
              borderRadius: "12px 12px 0px 0px",
            }}
          >
            <Flex justifyContent="space-between" alignItems="align-items">
              <Text
                fontSize="18px"
                fontWeight="600"
                color={ColorPalets.gray700}
              >
                {activeEditColumn?.type
                  ? `Edit ${activeEditColumn?.displayName}`
                  : `Create New Column`}
              </Text>

              <AiOutlineClose
                onClick={onModalClose}
                className="ml-auto cursor-pointer"
                size="1.5rem"
              />
            </Flex>
            <div className="mt-1"></div>
            <Text fontSize="14px" fontWeight="400" color="#475467">
            {activeEditColumn?.type
                  ? `Update ${activeEditColumn?.displayName} Column`
                  : `Select the type of column you would like to create`}
            </Text>
          </div>
          <div style={{ padding: "0px 24px 24px 24px" }}>
            {activeEditColumn?.type && (
              <>
                <LabelSpan>
                  <Label style={{ marginBottom: "6px" }}>Column Name</Label>
                  <CopyIcon cursor={'pointer'} onClick={() => copyToClipboard(getValues('displayName'))} fontSize={'14px'}/>
                </LabelSpan>
                <ZInput
                  register={register}
                  name="name"
                  placeholder="Column Name"
                  isReadOnly
                />

                <div className="mt-3"></div>
              </>
            )}
            <Label style={{ marginBottom: "6px" }}>Column Display Name</Label>
            <ZInput
              register={register}
              name="displayName"
              placeholder="Column Name"
              validations={columnNameErrorSchema}

            />
            <ErrorMessage errors={errors} fieldName="displayName" />
            <Label style={{ marginBottom: "6px", marginTop: "12px" }}>
              Column Type
            </Label>
            {activeEditColumn?.type ? (
              <ZTextField
                value={
                  activeEditColumn?.type === "LIST_OF_TEXT"
                    ? "Dropdown"
                    : activeEditColumn?.type === "RELATION" &&
                      activeEditColumn?.relationDetails?.referringEntity ===
                      IRelationReferringEntityType.USER
                      ? "User Relation"
                      : activeEditColumn?.type === "RELATION" &&
                      activeEditColumn?.relationDetails?.referringEntity ===
                      IRelationReferringEntityType.TEAM
                      ? "Team Relation"
                      : activeEditColumn?.type === "RELATION" &&
                      activeEditColumn?.relationDetails?.referringEntity ===
                      IRelationReferringEntityType.TASK
                      ? "Task Relation"
                      : activeEditColumn?.type === "RELATION" &&
                      activeEditColumn?.relationDetails?.referringEntity ===
                      IRelationReferringEntityType.CUSTOM
                      ? "Table Relation"

                      : activeEditColumn?.type
                }
                disabled
                fullWidth
                variant="outlined"
              />
            ) : (
              <>
                <ZSelect
                  control={control}
                  name="type"
                  styles={{
                    singleValue: (base: any) => ({
                      ...base,
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }),
                  }}
                  options={getSelectOption()}
                  components={{
                    Option: IconOption,
                    SingleValue: SingleValueDiv,
                  }}
                />
                <ErrorMessage errors={errors} fieldName="columnType" />
              </>
            )}
            <div className="mt-3"></div>
            <ColumnProps
              type={
                activeEditColumn?.type ? activeEditColumn?.type : type?.value
              }
              errors={errors}
              register={register}
              getValues={getValues}
              control={control}
              setValue={setValue}
            />
            <ButtonContainerModal style={{ justifyContent: "space-between", marginTop: "24px" }}>
              <div style={{ display: "flex", gap: "4px", justifyContent: "center", alignItems: "center" }}>
                <span>
                  {activeEditColumn?.type && <div onClick={() => { setIsDeleted(true); setShowDelete(true) }}><TrashRIcon color="#B42318" /></div>}
                </span>
                {/* <span>
                  {activeEditColumn?.type && <ColumnPermissions  onModalOpen={onModalClose} />}
                </span> */}
              </div>
              {/* Cancel Button */}
              <ButtonContainerModal>
                <ZButton
                  type="button"
                  onClick={onModalClose}
                  disabled={loading}
                  secondary
                  variant="contained"
                >
                  Cancel
                </ZButton>
                {activeEditColumn?.type ? (
                  <>
                    <ZButton
                      type="submit"
                      disabled={loading}
                      variant="contained"
                      id={OnboardingIdsForComponent.EDIT_COLUMN_MODAL_BUTTON}
                    >
                      {loading ? "Editing..." : "Save"}
                    </ZButton>
                  </>
                ) : (
                  <ZButton
                    type="button"
                    onClick={() => {
                      handleCreateClick()
                    }}
                    disabled={loading}
                    variant="contained"
                    id={
                      OnboardingIdsForComponent.CREATE_NEW_COLUMN_MODAL_CREATE_BUTTON
                    }
                  >
                    {loading ? "Creating..." : "Create"}
                  </ZButton>
                )}
              </ButtonContainerModal>
            </ButtonContainerModal>
          </div>
          <ConfirmationDialog
            id={"dontShowColumnCreateConfirmation"}
            deletionTitle={"Important"}
            deletionText={
              "This column will be assigned an auto generated name. You cannot change it later. However, You can change the display name later."
            }
            isOpen={showDialog}
            hasDontShowAgain={true}
            primaryButtonName="Yes"
            variant="primary"
            secondaryButtonName="No"
            onPrimaryBtnClick={() => {
              setShowDialog(false)
              handleSubmit(onSubmit)()
            }}
            onSecondaryClick={() => {
              setShowDialog(false)
            }}
            onClose={() => {
              setShowDialog(false)
            }}
          />
          <DeletionConfirmationDialog
            id={"subfieldRow"}
            deletionTitle={"Delete this column?"}
            deletionText={
              "Do you want to delete this column? This cannot be undone."
            }
            isOpen={showDelete}
            onConfirmDelete={() => { handleSubmit(onSubmit)(); }}
            onClose={() => { setShowDelete(false); setIsDeleted(false) }}
          />
        </Box>
      </FormProvider>
    </CurlModal>
  )
}

export default ColumnCreation