//@ts-nocheck
import styled from "styled-components";
import { BsChevronDown } from "react-icons/bs";
import useWorkflow from "views/workflows/hooks/useWorkFlow";
import notification from "notifications/notifications";
import { TaskFlowService } from "providers/data/services/TaskFlowService";
import { recordRSEvent } from "utils/CommonUtils";
import { OnboardingUtil } from "views/onboarding/OnboardingUtil";
import { setShowOnboardingModal } from "views/onboarding/reducer/onboardingAction";
import { OnboardingStep } from "views/onboarding/types/OnboardingTypes";
import { setLoading } from "views/reducer";
import { WorkflowUtils } from "views/workflows/WorkflowUtils";
import {
  setShowWorkflowConfigModal,
  setPostPublish,
  setIsSaveWorkflowCalledAtleastOnce,
  setShowPublishModal,
} from "views/workflows/reducers/workflowAction";
import { useDispatch, useStore, useSelector } from "react-redux";
import useOnboarding from "views/onboarding/hooks/useOnboarding";
import { useNavigate } from "react-router-dom";
import PostPublishModal from "./PostPublishModal";
import { InfoTooltip } from "views/workflows/Tootltips/InfoTooltip";
import useDeviceType from "utils/useDeviceType";
import { hasPermission } from "providers/auth/authUtils";
import { useState, useEffect } from "react";
import { memo } from "react";

const Button = styled.button`
  background-color: ${(props: { isOpened?: boolean; disabled?: boolean }) =>
    props.disabled
      ? "#F2F2F2"
      : props.isOpened
      ? props.theme.color.secondary
      : props.theme.color.primary};
  border-radius: 4px;
  color: ${(props: { isOpened?: boolean; disabled?: boolean }) =>
    props.disabled ? "#616569" : "white"};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  border: none !important;
`;

type Props = {
  onClick: () => void;
  text: string;
  disabled?: boolean;
  onChevronClick?: () => void;
};

const PublishButton = () => {
  const {
    showPublishModal,
    taskTypeId,
    taskTypeName,
    isSaveWorkflowCalledAtleastOnce,
    selectedNode,
    isBeingSaved,
  } = useWorkflow();
  const { isBeingOnboarded } = useOnboarding();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useStore();
  const deviceType = useDeviceType();
  //@ts-ignore
  const { loading } = useSelector((state) => state.loading);
  //@ts-ignore
  const { errors: invalidWorkflow } = useSelector(
    //@ts-ignore
    (state) => state?.validWorkflow
  );

  const [isPermittedToEditWorkflow, setIsPermittedToEditWorkflow] =
    useState(() =>  hasPermission("workflow", "edit"));


  const handleChveronClick = (e: any) => {
    e.stopPropagation();
    dispatch(setShowPublishModal(true));
  };

  const publishWorkflow = () => {
    dispatch(setLoading(true));
    recordRSEvent("publish_button_click", {
      context: "builder_general",
      workflow_id: taskTypeId,
      workflow_name: taskTypeName,
    });
    dispatch(setShowWorkflowConfigModal(false));
    TaskFlowService.publishWorkflow(taskTypeId)
      .then((res) => {
        if (!res) {
          //@ts-ignore
          notification("error", res?.message);
        } else if (res?.code && res?.code.indexOf("200") < 0) {
          // Error
          if (res?.code === "401") {
            notification("error", res?.message);
          } else {
            notification("error", res?.message);
          }
        } else {
          notification("success", res?.message);
          if (isBeingOnboarded) {
            OnboardingUtil.start({
              step: OnboardingStep.CREATE_TASK,
              dispatch,
              steps: store.getState()?.userOnboarding?.onboarding?.steps || [],
            });
            OnboardingUtil.updateOnboardingDataToBackend(
              OnboardingStep.WORKFLOW,
              dispatch,
              store.getState().userOnboarding?.onboarding?.steps || []
            );
            dispatch(setShowOnboardingModal(true));
            navigate("/home");
          }

          // if the workflow is published for the first time then show the post publish modal
          if (
            localStorage.getItem("showPostPublishModal") === "false" ||
            localStorage.getItem("showPostPublishModal") === null
          ) {
            dispatch(
              setPostPublish({
                postedWorkflow: taskTypeName,
                setPostPublish: true,
                postedWorkflowId: taskTypeId,
              })
            );
          }
        }

        /**
         * initBuilder calls:
         *  initWorkflow
         *  initMockData
         *  initScriptEditor
         *   api call to check if the workflow has been published before or not!
         */
        WorkflowUtils.initBuilder(dispatch, taskTypeId);

        // setting isSaveWorkflowCalledAtleastOnce to false as after the workflow is published
        // there are no changes in draft
        dispatch(setIsSaveWorkflowCalledAtleastOnce(false));
        if (
          localStorage.getItem("showPostPublishModal") === "false" ||
          localStorage.getItem("showPostPublishModal") === null
        ) {
          dispatch(setShowPublishModal(true));
        }

        dispatch(setLoading(false));
        recordRSEvent("publish_event_success", {
          context: "builder_general",
          workflow_id: taskTypeId,
          workflow_name: taskTypeName,
        });
      })
      .catch((err) => {
        console.error("Error", err);
        recordRSEvent("publish_event_fail", {
          context: "builder_general",
          workflow_id: taskTypeId,
          workflow_name: taskTypeName,
        });
        dispatch(setLoading(false));
      });
  };

  return (
    <>
      {isBeingOnboarded && deviceType !== "mobile" ? (
        <InfoTooltip
          placement="left"
          arrow
          open={true && !selectedNode}
          title="Click on publish to Publish the workflow!"
        >
          <span>
            <Button
              disabled={
                !isBeingOnboarded &&
                (isBeingSaved ||
                  invalidWorkflow ||
                  loading ||
                  !isSaveWorkflowCalledAtleastOnce ||
                  !isPermittedToEditWorkflow)
                
              }
              isOpened={showPublishModal}
              onClick={publishWorkflow}
            >
              <span style={{ padding: "10px 16px" }}> Publish </span>
              <div
                style={{
                  height: "36px",
                  width: "1px",
                  backgroundColor: "white",
                }}
                className="ajscnajs"
              ></div>
              <span
                onClick={handleChveronClick}
                style={{
                  marginLeft: "8px",
                  paddingTop: "10px",
                  paddingRight: "8px",
                }}
              >
                {" "}
                <BsChevronDown size={"1.4rem"} />{" "}
              </span>
            </Button>
          </span>
        </InfoTooltip>
      ) : (
        <InfoTooltip
          arrow
          title={
            !isSaveWorkflowCalledAtleastOnce
              ? "You haven't made any changes to the workflow. Make some changes to publish it."
              : invalidWorkflow
              ? "Your workflow has some errors. Please fix them before publishing"
              : !hasPermission("workflow", "edit")
              ? "You do not have permissions to publish the workflow"
              : "Publish"
          }
        >
          <span>
            <Button
              disabled={
                isBeingSaved || loading || !isSaveWorkflowCalledAtleastOnce || !isPermittedToEditWorkflow
              }
              isOpened={showPublishModal}
              onClick={publishWorkflow}
            >
              <span style={{ padding: "10px 16px" }}> Publish </span>
              <div
                style={{
                  height: "36px",
                  width: "1px",
                  backgroundColor: "white",
                }}
                className="ajscnajs"
              ></div>
              <span
                onClick={handleChveronClick}
                style={{
                  marginLeft: "8px",
                  paddingTop: "10px",
                  paddingRight: "8px",
                }}
              >
                {" "}
                <BsChevronDown size={"1.4rem"} />{" "}
              </span>
            </Button>
          </span>
        </InfoTooltip>
      )}
      {showPublishModal && <PostPublishModal />}
    </>
  );
};

export default memo(PublishButton)
