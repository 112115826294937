import { TextField } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import {
  UseFormRegister,
  FieldErrors,
  Controller,
  Control,
  UseFormSetValue,
  UseFormGetValues,
} from "react-hook-form";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { ComponentType } from "../ComponentTypeEnum";
import { IZDateTimeInterface } from "./IZDateTimeInterface";
import { useEffect } from "react";
import { ColSpan4, RedText } from "render-engine/render.styles";
import { TaskFormLabelForCreateAndEdit } from "render-engine/render.styles";
import {
  setInEditState,
  setTaskData,
} from "render-engine/reducers/show/TaskShowReducer";
import { useDispatch } from "react-redux";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import moment from "moment";
import { AiOutlineWarning } from "react-icons/ai";

export const ZDateTimeV2: FunctionComponent<{
  config: IZDateTimeInterface;
  onChange: Function;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  control: Control;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
}> = ({ config, control, onChange, register, errors, setValue, getValues }) => {
  const [dtValue, setDtValue] = React.useState<Date | null>(
    config.value ? new Date(config.value) : new Date()
  );

  const dispatch = useDispatch();
  const { convertDataTypeToString } = useTaskShow();

  const initialValue = convertDataTypeToString(`${config.key}` || "");
  console.log(`initialValue ${config.key}`, initialValue);

  // Construct validation
  const validation: any = {};
  if (config.isRequired) validation.required = `required`;

  function setTimeToNewDate(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes);
    return date;
  }

  // If there is an incoming value, store it in redux store (this is akin to default value)
  useEffect(() => {
    const initialValue = convertDataTypeToString(`${config.key}` || "");
    const valueToBeSetToReactHookForm = initialValue

    console.log(`useEffect ${config.key}`, initialValue);

    if(config.shape.toLowerCase() === "time") {
      setValue(
        config.key,
        valueToBeSetToReactHookForm ? setTimeToNewDate(valueToBeSetToReactHookForm) : null
      );
    } else {
      setValue(
        config.key,
        valueToBeSetToReactHookForm ? new Date(valueToBeSetToReactHookForm) : null
      );
    }
  }, []);

  const handleChange = (newDate: any) => {
    setDtValue(newDate?.toDate());
  };

  console.log(`getValue ${config.key}`, getValues());

  const handleBlur = (onClear ?: boolean) => {
    console.log("on blur", getValues());
    
    let selectedDate;
    if(onClear) {
      selectedDate = null;
      dispatch(setTaskData({ nodeId: config.key, data: selectedDate }));
      return
    } 
    selectedDate = new Date(getValues()[`${config.key}`]);
    // convert to UTC using toISOString and remove milliseconds
    let utcDate: any = null;
    if (config.shape.toLowerCase() === "date") {
      utcDate = new Date(
        Date.UTC(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate()
        )
      ).toISOString();
      dispatch(setTaskData({ nodeId: config.key, data: utcDate }));
    } else if (config.shape.toLowerCase() === "time") {
      console.log("on blur", getValues());
      // dispatch(setInEditState({ nodeId: config.key, inEditState: false }));
      const selectedDate = new Date(getValues()[`${config.key}`]);
      // Get the time
      const hours = String(selectedDate.getHours()).padStart(2, "0");
      const minutes = String(selectedDate.getMinutes()).padStart(2, "0");
      // const seconds = selectedDate.getSeconds();
      const time = `${hours}:${minutes}`;

      dispatch(setTaskData({ nodeId: config.key, data: time }));
    } else {
      // dispatch(setInEditState({ nodeId: config.key, inEditState: false }));
      dispatch(
        setTaskData({ nodeId: config.key, data: getValues()[`${config.key}`] })
      );
    }
    dispatch(setInEditState({ nodeId: config.key, inEditState: false }));
    // dispatch(setTaskData({ nodeId: config.key, data: utcDate }));
  };

  const isVisible = config?.isVisible ?? true;

  return (
    <>
      {isVisible && (
        <ColSpan4
          style={{
            float: "left",
          }}
        >
          {
            <TaskFormLabelForCreateAndEdit
              className="col-span-1 font-medium"
              fontSize={config?.label?.fontSize ? config.label.fontSize : 14}
              color={config?.label?.color ? config.label.color : "black"}
            >
              {config?.label?.isVisible ? config.label.val : ""}
              {config?.isRequired && <RedText>*</RedText>}
            </TaskFormLabelForCreateAndEdit>
          }
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {config.shape.toLowerCase() === "date" && (
              <Controller
                control={control}
                name={config.key}
                rules={validation}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DesktopDatePicker
                    sx={{ width: "100%", backgroundColor: "white" }}
                    inputFormat="MM/dd/yyyy"
                    isClearable
                    onClose={() => handleBlur(false)}
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      if (newValue === null) {
                        handleBlur(true);
                      } 
                      // else {
                      //   handleBlur(false);
                      // }

                    }}
                    slotProps={{ field: { clearable: true} }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                )}
              />
            )}

            {config.shape.toLowerCase() === "time" && (
              <Controller
                control={control}
                name={config.key}
                rules={validation}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TimePicker
                    value={value}
                    sx={{ width: "100%", backgroundColor: "white" }}
                    onClose={handleBlur}
                    onChange={(newValue) => {
                      onChange(newValue);
                      if (newValue === null) {
                        handleBlur(true);
                      } 
                      // else {
                      //   handleBlur(false);
                      // }

                    }}
                    slotProps={{ field: { clearable: true} }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                )}
              />
            )}
            {config.shape.toLowerCase() === "datetime" && (
              <Controller
                control={control}
                name={config.key}
                rules={validation}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DateTimePicker
                    sx={{ width: "100%", backgroundColor: "white" }}
                    onClose={handleBlur}
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      if (newValue === null) {
                        handleBlur(true);
                      } 
                      // else {
                      //   handleBlur(false);
                      // }

                    }}
                    slotProps={{ field: { clearable: true} }}
                    timeSteps={{ hours: 1, minutes: 1 }}

                    // renderInput={(params) => (
                    //   <TextField fullWidth {...params} />
                    // )}
                  />
                )}
              />
            )}
          </LocalizationProvider>
          {errors[config.key]?.message?.length > 0 && (
            <div className="font-semibold text-xs text-red-500">
              <AiOutlineWarning
                    size={"1rem"}
                    color="red"
                    style={{ display: "inline", marginRight: "4px" }}
                  />{" "}
              {errors[config.key]?.message}
            </div>
          )}
        </ColSpan4>
      )}
    </>
  );
};
