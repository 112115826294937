import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  TextField,
} from '@mui/material';
import obiImage from 'assests/obimage.svg';
import whiteLogo from 'assests/whitelogo.svg';
import { isEmpty } from 'lodash';
import { AccountService } from 'providers/data/services/AccountService';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/semantic-ui.css';
import { Navigate,useNavigate, useLocation, useParams } from 'react-router-dom';
import { fetchAndStoreAccountName, userDecodedData } from 'utils/UserAuth.utils';
import LoadingScreen from 'views/LoadingScreen/LoadingScreen';
import notification from 'notifications/notifications';
import jwt_decode from 'jwt-decode';
import { identifyUser } from 'config/Rudderstack';
import { recordRSEvent } from 'utils/CommonUtils';
import Select from "react-select";
import { UserService } from 'providers/data/services/UserService';
import useQuery from 'hooks/useQuery';
import useSessionStorage from 'hooks/useSessionStorage';
import { useRedirect, useRefresh } from 'react-admin';
import { useDispatch } from 'react-redux';
import { setPostPublish } from 'views/workflows/reducers/workflowAction';

const showError = (str, limit, message) => {
  if (str && limit && message) {
    let remText = str.replace(/ /g, '');
    let length = remText.length;
    if (length < limit && length !== 0) {
      return message;
    } else {
      return '';
    }
  }
};

const removeAndTrimString = (s) => {
  return !!s?.length ? s.trim().replace(/\s+/g, ' ') : s;
};

const AccountSetup = () => {
  const query = useQuery();
  const id = query?.get('id');
  const { email } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("Asia/Kolkata")
  const [timezone, setTimezone] = useState("us")
  const [templateId, setTemplateId] = useSessionStorage("templateId");
  const [solutionId, setSolutionId] = useSessionStorage("solutionId");
  const [redirectUrl, setRedirectUrl] = useSessionStorage("redirectUrl");
  const refresh = useRefresh()
  const dispatch = useDispatch();
  const redirect = useRedirect();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    UserService
      .getUserInfoByIP()
      .then((response) => {
        setCountryCode(response?.data?.location?.country?.code?.toLowerCase() || 'us');
        setTimezone(response?.data?.location?.time_zone?.id || 'Asia/Kolkata');
      });
  }, []);

  const [accountSetup, setAccountSetup] = useState({
    userName: '',
    accountName: '',
    password: '',
    signupMode: id ? 'EMAIL' : 'GOOGLE_OAUTH',
    phone: {
      countryCode: '',
      num: '',
    },
    signupId: id || localStorage.getItem('gaAT'),
    email,
  });

  const [showPassword, setShowPassword] = useState(false);

  if (!isEmpty(userDecodedData())) {
    return (
      <Navigate
        to={{
          pathname: '/',
        }}
      />
    );
  }

  const handleClickShowPassword = () => {
    !showPassword ? setShowPassword(true) : setShowPassword(false);
  };

  const createAccount = (data, e) => {
    e.preventDefault();
    // if (accountSetup.password.length < 8) {
    //   notification('error', 'Password length must be greater that 8');
    //   return;
    // }
    // if (accountSetup.accountName.length < 3) {
    //   notification('error', 'Account name should be greater than 3 characters');
    //   return;
    // }
    // if (accountSetup.userName.length < 3) {
    //   notification('error', 'User Name must be greater than 3 characters');
    //   return;
    // }

    setLoading(true);
    // accountSetup.userName = removeAndTrimString(accountSetup.userName);
    // accountSetup.accountName = removeAndTrimString(accountSetup.accountName);
    accountSetup.userName = data?.name?.trim();
    accountSetup.accountName = data?.companyName?.trim() || " ";
    accountSetup.email = data?.email?.trim() || email;
    accountSetup.password = data?.password?.trim();
    accountSetup.organization = data?.organization?.trim() || accountSetup?.organization;
    accountSetup.timezone = timezone;

    if (accountSetup?.phone && (!accountSetup?.phone.num || accountSetup?.phone?.num?.trim().length === 0))
      accountSetup.phone.countryCode = "";

      const solId = (solutionId !== "null" && solutionId !== "undefined") ? ((!solutionId || solutionId?.length === 0) ? "" : solutionId) : query.get('solutionId');
      const temId = (templateId !== "null" && templateId !== "undefined") ? ((!templateId || templateId?.length === 0) ? "" : templateId) : query.get('templateId');
      AccountService.createAccount({...accountSetup, templateIds: temId ? [temId] : undefined,solutionId : solId?.split(",") })
        .then(async (response) => {
       
        localStorage.setItem('at', response.data.at);
        localStorage.setItem('rt', response.data.rt);
        setTemplateId("",true);
        setSolutionId("",true);
        localStorage.removeItem('solutionId');
        // fetch account name and store in local storage
        await fetchAndStoreAccountName();
        const decoded = jwt_decode(response?.data?.at);
        if (decoded) identifyUser(decoded?.userId);

        var decodedToken = response?.data?.acl;
        localStorage.setItem('permissions', JSON.stringify(decodedToken));
        setLoading(false);
        recordRSEvent('account_setup_success', {
          context: 'signup',
          user_id: decoded?.userId,
        });
        refresh()
        if(redirectUrl) {
          navigate(redirectUrl);
        }
        if(templateId && templateId !== "undefined") {
          if(response.data.createdWorkflowIds?.length === 1){
            navigate(`/workflows/${response.data.createdWorkflowIds[0]}/show`);  
            // window.location.reload();
            dispatch(
              setPostPublish({
                postedWorkflow: "Workflow Created from template",
                setPostPublish: true,
                postedWorkflowId: response.data.createdWorkflowIds[0],
              })
            ); 
                     
          } else {
            // setTimeout(() => {
            //   navigate('/table');
            //   window.location.reload();
            // }, 1)
            redirect("/table")
          }
            
        }
        else if (response.data?.isOnboardingCompletedOnWeb) {
          navigate('/templates');
          window.location.reload();
        } else {
          redirect("/table")
          
        }
        
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        recordRSEvent('account_setup_fail', {
          context: 'signup',
        });
      });
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className='setupAccount'>
          <div className='setupAccountLeft'>
            <div className='setupAccountImage'>
              <img className='logoImgSA' src={whiteLogo} alt='p' />
              <img className='mainImage' src={obiImage} alt='p' />
            </div>
          </div>
          <div className='setupAccountRight'>
            <h2 className='loginTitle'>Let's setup your Account!</h2>
            <form className='loginForm' onSubmit={handleSubmit(createAccount)}>
              <Box sx={{ width: 1 }}>
                <FormControl variant='standard' fullWidth>
                  <FormLabel
                    className='labelTextForAccountCreate'
                    sx={{ mb: 1, fontWeight: 'bold', fontSize: 'small' }}
                  >
                    Name
                  </FormLabel>
                  <TextField
                    size='small'
                    {...register('name', {
                      required: 'Name is required',
                    })}
                    onChange={(e) => setAccountSetup({ ...accountSetup, userName: e.target.value })}
                  />
                  {errors.name?.message?.length > 0 && (
                    <div className='font-semibold text-sm text-red-500'>{errors.name?.message}</div>
                  )}
                  {/* <div class='font-semibold text-xs text-red-500 mt-3'>
                    {showError(accountSetup.userName, 3, 'User Name must be greater than 3 characters')}
                  </div> */}
                  <p className='helperTextForAccountCreate'> We are big on real names here.</p>
                </FormControl>
              </Box>

              <Box sx={{ width: 1, mt: 3 }}>
                <FormControl variant='standard' fullWidth>
                  <FormLabel
                    className='labelTextForAccountCreate'
                    sx={{ mb: 1, fontWeight: 'bold', fontSize: 'small' }}
                  >
                    Work Email
                  </FormLabel>
                  <TextField size='small' disabled={true}
                    {...register('email')}
                    value={email} />
                </FormControl>
              </Box>
              <Box sx={{ width: 1, mt: 3 }}>
                <FormControl variant='standard' fullWidth>
                  <FormLabel
                    className='labelTextForAccountCreate'
                    sx={{ mb: 1, fontWeight: 'bold', fontSize: 'small' }}
                  >
                    Phone
                  </FormLabel>
                  <PhoneInput
                    // value={getValues('phone')}
                    {...register('phone')}
                    enableSearch={true}
                    onChange={(val, countryData, e, formattedValue) => {
                      // setValue('phone', val);
                      // clearErrors('phone');
                      setAccountSetup({
                        ...accountSetup,
                        phone: {
                          countryCode: countryData.dialCode,
                          num: val.substring(countryData?.dialCode?.length),
                        },
                      });
                    }}
                    country={countryCode}
                    id='phone'
                    inputStyle={{ height: '100%' }}
                  />
                  <div class='font-semibold text-xs text-red-500 mt-3'>{errors?.phone?.message}</div>
                </FormControl>
              </Box>
              <Box sx={{ width: 1, mt: 3 }}>
                <FormControl variant='standard' fullWidth>
                  <FormLabel
                    className='labelTextForAccountCreate'
                    sx={{ mb: 1, fontWeight: 'bold', fontSize: 'small' }}
                  >
                    Company Name
                  </FormLabel>
                  <TextField
                    size='small'
                    {...register('companyName', {
                      required: 'Company Name is required',
                      minLength: {
                        value: 3,
                        message: "Company Name should be atleast 3 characters"
                      }
                    })}
                    onChange={(e) => setAccountSetup({ ...accountSetup, accountName: e.target.value })}
                  />

                  {errors.companyName?.message?.length > 0 && (
                    <div className='font-semibold text-sm text-red-500'>{errors.companyName?.message}</div>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ width: 1, mt: 3 }}>
                <FormControl variant='standard' fullWidth>
                  <FormLabel
                    className='labelTextForAccountCreate'
                    sx={{ mb: 1, fontWeight: 'bold', fontSize: 'small' }}
                  >
                    Password
                  </FormLabel>
                  <OutlinedInput
                    id='outlined-adornment-password'
                    type={showPassword ? 'text' : 'password'}
                    size='small'
                    onChange={(e) => setAccountSetup({ ...accountSetup, password: e.target.value })}
                    {...register('password', {
                      required: 'Password is required',
                      minLength: {
                        value: 8,
                        message: "Password should be atleast 8 characters"
                      },
                      pattern: {
                        value: /^\S*$/,
                        message: "Password cannot contain white space character(s)"
                      }
                    })}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        sx={{ mb: '3.2px', p: 0 }}
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  />
                  {/* <div class='font-semibold text-xs text-red-500 mt-3'>
                    {accountSetup.password.length > 0 && accountSetup.password.length < 8
                      ? 'Password length must be greater than 8 characters'
                      : ''}
                  </div> */}
                  {errors.password?.message?.length > 0 && (
                    <div className='font-semibold text-sm text-red-500'>{errors.password?.message}</div>
                  )}
                  <p className='helperTextForAccountCreate'>Make it secure and easy to remember. </p>
                </FormControl>
              </Box>

              {/* <Box sx={{ width: 1, mt: 3 }}>
                <FormControl variant='standard' fullWidth>
                  <FormLabel
                    className='labelTextForAccountCreate'
                    sx={{ mb: 1, fontWeight: 'bold', fontSize: 'small' }}
                  >
                    Your Role
                  </FormLabel>
                  <Select
                    // value={options.find((c) => c.value === value)}
                    isClearable
                    isSearchable={false}
                    className="react-dropdown"
                    classNamePrefix="dropdown"
                    styles="font-size: 0.875rem;"
                    {...register('organization')}
                    onChange={(selectedOption) => {
                      setAccountSetup({ ...accountSetup, organization: selectedOption.value });
                    }}
                    options={
                      [
                        { label: 'Founder/CXO', value: 'founder_cxo' },
                        { label: 'Product Management', value: 'product_management' },
                        { label: 'Engineering', value: 'engineering' },
                        { label: 'Operations', value: 'operations' },
                        { label: 'Business', value: 'business' },
                        { label: 'Others', value: 'others' },
                      ]
                    }
                  />
                </FormControl>
              </Box> */}
              <Box sx={{ width: 1, mt: 3 }}>
                <p className='helperTextForAccountCreate'>By signing up, I agree to the <a href="https://www.zorp.one/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>Terms & Conditions and privacy policy </a> of Zorp. </p>
              </Box>
              <Button
                sx={{ mt: 3, borderRadius: '4px', backgroundColor: '#3e66fb' }}
                variant='contained'
                fullWidth
                type='submit'
              >
                Finish Sign Up
              </Button>

              <p style={{ textAlign: "center", fontSize: "12px", fontWeight: '500', fontFamily: "'Inter', sans-serif", marginTop: "10px" }}>
                Already have an account?{' '}
                <Link style={{ fontWeight: '600', color: '#3e66fb' }} href='/#/login' underline='hover'>
                  Login
                </Link>
              </p>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountSetup;