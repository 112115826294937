import { useSelector,useStore } from 'react-redux';
import { EditorScriptLocation } from 'lowcode/state/types/types';
import useEntity from 'views/entities/hooks/useEntity';
import { get } from 'lodash';

const useWorkFlow = () => {
  const store = useStore();
  const {activeEditColumn} = useEntity()

  const {
    builder: { workflow = {} },
  } = useSelector((state: any) => state);
  
  const getHomeNode = () => {
    const elements = store.getState()?.builder?.workflow?.canvas?.elements;
    const homeNode = elements?.nodes?.find((element: any) => element.data?.isHomeNode);
    return homeNode
  }

  const getTerminalNodes = () => {
    const elements = store.getState()?.builder?.workflow?.canvas?.elements;
    const terminalNodes = elements?.nodes?.filter((element: any) => element.data?.isTerminalNode).map((element:any) => element.id);
    return terminalNodes
  }

  const isNewTaskType = useSelector((state: any) => workflow?.new);
  const creationInfo = useSelector((state: any) => workflow?.creationInfo || {
    from : "BUILDER",
    fromId: ""
  });
  const isFirstLoad = useSelector((state: any) => workflow?.isFirstLoad);
  const accountId = useSelector((state: any) => workflow?.accountId);
  const taskTypeId = useSelector((state: any) => workflow?.taskType);
  const isBeingSaved = useSelector((state) => workflow?.isBeingSaved);
  const dataFields = useSelector((state: any) => workflow?.dataFields);
  const activeEditField = useSelector((state) => workflow?.activeEditField);
  const showEditField = useSelector((state: any) => workflow?.showEditField);
  const selectedAction = useSelector((state: any) => workflow?.selectedAction);
  const postPublish = useSelector((state: any) => state?.builder?.postedWorkflowInfo?.setPostPublish);
  const postedWorkflow = useSelector((state: any) => state?.builder?.postedWorkflowInfo?.postedWorkflow);
  const postedWorkflowId = useSelector((state: any) => state?.builder?.postedWorkflowInfo?.postedWorkflowId);
  const elements = useSelector((state: any) => workflow?.canvas?.elements || []);
  const selectedNode = useSelector((state: any) => workflow?.selectedNode || null);
  const taskTypeName = useSelector((state: any) => workflow?.taskTypeDisplayName);
  const taskTypeDescription = useSelector((state: any) => workflow?.taskTypeDescription);
  const eventName = useSelector((state: any) => workflow?.selectedTransition?.eventName);
  const selectedTransition = useSelector((state: any) => workflow?.selectedTransition || null);
  const collectLocation = useSelector((state: any) => workflow?.selectedTransition?.data?.collectLocation);
  const allElements = Array.isArray(elements) ? elements?.map((item: any) => item.data) : [];
  const showNodeDropdown = useSelector((state: any) => state?.builder?.showNodeDropdown);
  const showDataFieldModal = useSelector((state: any) => state?.builder?.showDataFieldModal);
  const showDataFieldCreateModal = useSelector((state: any) => state?.builder?.showDataFieldCreateModal);
  const showDataFieldEditModal = useSelector((state: any) => state?.builder?.showDataFieldEditModal);
  const selectedDecisionNode = useSelector((state: any) => state?.builder?.workflow?.selectedDecisionNode);
  const showUpdateDecisionNodeModal = useSelector((state: any) => state?.builder?.showUpdateDecisionNodeModal);
  const showUpdateNodeNameModal = useSelector((state: any) => state?.builder?.showUpdateNodeNameModal);
  
  const dataFieldType = useSelector((state: any) => state?.builder?.dataFieldType);
  const showCreateDatafieldModalSingle = useSelector((state: any) => state?.builder?.showCreateDatafieldModalSingle);
  const showEditDatFieldModalSingle = useSelector((state: any) => state?.builder?.showEditDatFieldModalSingle);
  const deleteIconOnTransition = useSelector((state: any) => state?.builder?.deleteIconOnTransition);
  const showWorkflowErrors = useSelector((state: any) => state?.builder?.showWorkflowErrors);
  const allWorkflowErrors = useSelector((state: any) => state.builder.allWorkflowErrors);
  const showTaskCardConfig = useSelector((state: any) => state?.builder?.showTaskCardConfig);
  const flutterTaskCardConfig = useSelector((state: any) => state?.builder?.flutterTaskCardConfig);
  const showWorkflowConfigModal = useSelector((state: any) => state?.builder?.showWorkflowConfigModal);
  const showErrorTooltip = useSelector((state: any) => state?.builder?.showErrorTooltip);
  const activeSubField = useSelector((state: any) => state?.builder?.activeSubField);
  const updateTeam = useSelector((state: any) => workflow?.updateTeam);
  const updateSlot = useSelector((state: any) => workflow?.updateSlot);
  const createTask = useSelector((state: any) => workflow?.createTask);
  const updateTask = useSelector((state: any) => workflow?.updateTask);
  const deleteTask = useSelector((state: any) => workflow?.deleteTask);
  const changesInFlutter = useSelector((state: any) => state?.builder?.changesInFlutter)
  const selectedSystemTransition = useSelector((state: any) => workflow?.selectedSystemTransition);
  const appTaskCreateConfig = useSelector((state:any) => workflow?.appTaskCreateConfig)
  const allScreenElements = useSelector((state:any) => workflow?.canvas?.elements)
  const apiResponse = useSelector((state: any) => state?.builder?.apiResponse || {});
  const fetchingTestAPIResponse = useSelector((state:any) => state?.builder?.fetchingTestAPIResponse)
  const possibleHomeNodes = useSelector((state:any) => workflow?.possibleHomeNodes || [])
  const isContextMenuVisible = useSelector((state:any) => state?.builder?.isContextMenuVisible)
  const nodesThatCanBeVisited = useSelector((state:any) => state?.builder?.nodesThatCanBeVisited || [])
  const isSaveWorkflowCalledAtleastOnce = useSelector((state:any) => state?.builder?.isSaveWorkflowCalledAtleastOnce)
  const workflowNotLoaded = useSelector((state:any) => workflow?.workflowNotLoaded || false)
  const possibleTerminalNodes = useSelector((state:any) => workflow?.possibleTerminalNodes || [])
  const workflowStatus = useSelector((state:any) => workflow?.workflowStatus || 'draft')
  const dataPicker = useSelector((state:any) => state?.builder?.dataPicker)
  const systemTransitionAutomations = useSelector((state:any) => workflow?.systemTransitionAutomations || [])
  const createdFromTemplateId = useSelector((state:any) => workflow?.createdFromTemplateId);
  const version = useSelector((state:any) => workflow?.version);
  const showPublishModal = useSelector((state:any) => state?.builder?.showPublishModal);
  const isWorkflowPublishedBefore = useSelector((state:any) => state?.builder?.isWorkflowPublishedBefore);
  const showPreviewModal = useSelector((state:any) => state?.builder?.showPreviewModal);
  const nodes = useSelector((state:any) => state?.builder?.workflow?.canvas?.elements?.nodes || [])
  const edges = useSelector((state:any) => state?.builder?.workflow?.canvas?.elements?.edges || [])
  // TODO:  ADD DEFAULT VALUE in the function
  const getScriptValue = (editorLocation: EditorScriptLocation) => {
    let data = get(selectedAction, editorLocation, {})
    if (data?.hasOwnProperty('type') && data?.type !== 'literal') {
      return data?.value;
    }
    // TODO: Sachin: Please optimize this code.
    // fix for returning condition as return true when no condition has been changed.
    return '';
  };

  const getLiteralValue = (editorLocation: EditorScriptLocation) => {
    const data = get(selectedAction, editorLocation, {});
    if (data?.hasOwnProperty('type') && data?.type === 'literal') {
      return data?.value;
    }
    return '';
  };

  return {
    isBeingSaved,
    isFirstLoad,
    activeEditField,
    showEditField,
    accountId,
    workflow,
    selectedTransition,
    selectedAction,
    elements,
    eventName,
    collectLocation,
    activeSubField,
    isNewTaskType,
    dataFields,
    selectedNode,
    taskTypeId,
    taskTypeName,
    taskTypeDescription,
    allElements,
    showNodeDropdown,
    showDataFieldModal,
    showDataFieldCreateModal,
    showDataFieldEditModal,
    selectedDecisionNode,
    showUpdateDecisionNodeModal,
    postPublish,
    postedWorkflow,
    dataFieldType,
    showCreateDatafieldModalSingle,
    showEditDatFieldModalSingle,
    showTaskCardConfig,
    showErrorTooltip,
    getScriptValue,
    getLiteralValue,
    deleteIconOnTransition,
    allWorkflowErrors,
    showWorkflowErrors,
    showWorkflowConfigModal,
    updateTeam,
    updateSlot,
    updateTask,
    createTask,
    deleteTask,
    allScreenElements,
    selectedSystemTransition,
    changesInFlutter,
    appTaskCreateConfig,
    possibleHomeNodes,
    isContextMenuVisible,
    nodesThatCanBeVisited,
    possibleTerminalNodes,
    getHomeNode,
    flutterTaskCardConfig,
    getTerminalNodes,
    apiResponse,
    fetchingTestAPIResponse,
    postedWorkflowId,
    isSaveWorkflowCalledAtleastOnce,
    workflowNotLoaded,
    workflowStatus,
    dataPicker,
    systemTransitionAutomations,
    createdFromTemplateId,
    version,
    showPublishModal,
    isWorkflowPublishedBefore,
    showUpdateNodeNameModal,
    showPreviewModal,
    creationInfo,
    nodes,
    edges
  };
};

export default useWorkFlow;
