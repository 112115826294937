//@ts-nocheck
import React, { FunctionComponent } from "react";
import {
  UseFormRegister,
  FieldErrors,
  Control,
  UseFormSetValue,
  UseFormGetValues,
  UseFormSetError,
  UseFormClearErrors,
  Controller,
} from "react-hook-form";
import { IZListOfTextInterface } from "./IZListOfText";
import Select from "react-select";
import {
  ColSpan4,
  RedText,
  TaskFormLabelForCreateAndEdit,
} from "render-engine/render.styles";
import { IZBoolean } from "components/boolean/IZBoolean";
import {
  setInEditState,
  setTaskData,
} from "render-engine/reducers/show/TaskShowReducer";
import { useDispatch } from "react-redux";
import useTaskShow from "views/workflows/hooks/useTaskShow";

type Props = {};
const ZListOfTextDropdown: FunctionComponent<{
  config: IZListOfTextInterface;
  onChange: Function;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  control: Control;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
}> = ({
  config,
  onChange,
  register,
  errors,
  setValue,
  getValues,
  control,
  setError,
  clearErrors,
}) => {
  // const customStyles = {
  //   // clearIndicator: (provided: any) => ({
  //   //   ...provided,
  //   //   padding: "5px",
  //   // }),
  //   // dropdownIndicator: (provided: any) => ({
  //   //   ...provided,
  //   //   padding: "5px",
  //   // }),
  //   // control: (provided: any) => ({
  //   //   ...provided,
  //   //   minHeight: "32px",
  //   // }),

  // }
  const customStyles = {
    container: (provided) => ({ ...provided, width: "100%" }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#101828",
        fontSize: "12px",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        border: "1px solid #D0D5DD",
        fontSize: "12px",
        borderRadius: "6px",
        backgroundColor: "none", // change background color of the pill
      };
    },
    multiValueLabel: (styles) => {
      return {
        ...styles,
        fontSize: "12px",
        color: "#101828", // change text color inside the pill
      };
    },
    singleValue: (provided) => ({
      ...provided,
      fontSize: "12px", // added fontSize here
      marginRight: "3px",
      marginLeft: "0px",
      boxSizing: "border-box",
      marginBottom: "0px",
    }),
    control: (provided) => ({
      ...provided,
      height: "max-content !important",
      // padding: "4px 6px", // added padding here
    }),
    input: (provided) => ({ ...provided, margin: "0px", padding: "0px" }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "2px 2px 3px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      marginTop: "3px",
      marginBottom: "3px",
    }),
  };

  const dispatch = useDispatch();
  const { convertDataTypeToString } = useTaskShow();

  const handleBlur = () => {
    console.log("on blur", getValues());
    dispatch(setInEditState({ nodeId: config.key, inEditState: false }));
  };

  const val = convertDataTypeToString(`${config.key}` || "");

  return (
    <ColSpan4>
      <TaskFormLabelForCreateAndEdit
        fontSize={config?.label?.fontSize ? config.label.fontSize : 14}
        color={config?.label?.color ? config.label.color : "black"}
        className="col-span-1 font-medium"
        style={{ padding: "20px 0px 4px 0px" }}
      >
        {config?.label?.val}
        {config?.isRequired && <RedText>*</RedText>}
      </TaskFormLabelForCreateAndEdit>

      <Controller
        name={config?.key}
        control={control}
        defaultValue={val || config?.value}
        // rules={{
        //   required: config?.isRequired ? config.isRequired : false,
        // }}
        render={({ field: { onChange: fieldOnChange, value } }) => (
          <Select
            styles={customStyles}
            onBlur={handleBlur}
            isMulti={config?.isMultiselect}
            defaultValue={
              config?.isMultiselect
                ? config?.options.filter((option: any) =>
                    config?.value?.includes(option.value)
                  )
                : config?.options?.find(
                    (option: any) => option.value === config?.value?.[0]
                  )
            }
            // isClearable
            isSearchable={true}
            className="react-dropdown"
            classNamePrefix="dropdown"
            onChange={(selectedOption: any) => {
              // if multiple select is not allowed, then send only the value (as the selectedOption is an object)
              let val = []
                if (!config?.isMultiselect) {
                  if (selectedOption === null) {
                    val = []
                  } else {
                    val = [selectedOption.value]
                  }
                  
                } else {
                  if (selectedOption === null) {
                    val = []
                  } else {
                    val = selectedOption.map((option: any) => option.value)
                  }
                  
                }


              fieldOnChange(val);
              onChange(val);
              dispatch(
                setTaskData({
                  nodeId: config.key,
                  data: getValues()[`${config.key}`],
                })
              );
            }}
            options={config?.options}
            isClearable={true}
          />
        )}
      />
    </ColSpan4>
  );
};

export default ZListOfTextDropdown;
