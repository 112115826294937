//@ts-nocheck
import { PageCategoryEnum, recordPageVisit } from 'config/Rudderstack';
import { RudderStackAutomationBuilderContext, RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { recordRSEvent } from 'utils/CommonUtils';
import { generateId } from "utils/Utils";
import { DataFieldType } from 'views/DataField/DataField.types';
import ModalSmartInputTextField from "views/workflows/workflowactions/api/components/ModalSmartInputTextField";
import {
   Heading, HeadingWrapper, Label
} from 'views/workflows/workflowactions/ModalContent.styles';
import {
  ABSelectWrapper, ABText
} from "../automationBuilder.styles";
import useAutomation from "../hooks/useAutomation";
import IsActive from "views/workflows/workflowactions/api/components/IsActive";
import {
  addTriggerInAutomation, setActionPayload, updateTriggerInSelectedAutomation
} from "../reducer/automationReducer";
import { ABMetadataTypeEnum } from "../types/ABType";
import ABSelect from "./AppComponents/ABSelect";
import ListCard from "./ListCard";

const SelectTrigger = () => {
  const optionLabel = (option: any) => option.name;
  const optionId = (option: any) => option.id;

  const { trigger, selectedTrigger, metadataType, actionDropdown, selectedAutomation, clearRightSide } = useAutomation();

  const dispatch = useDispatch();

  const [showEvent, setShowEvent] = useState([]);
  const [selectedTriggerValue, setSelectedTriggerValue] = useState<any>({});

  // Function is to handle save for Screen, System, Decision Node
  const updateSelectedTrigger = (event:any) => {

    // Add New Trigger to selected Automation
    const triggerId = generateId(6);
    if (!selectedTrigger) {

      const newTriggerData = {
        triggerId,
        errors: [],
        event: event,
        metadataType
      }

      dispatch(addTriggerInAutomation({
        automationId: selectedAutomation.id,
        action: newTriggerData,
      }));

    } else {
      // Update Existing Trigger
      dispatch(updateTriggerInSelectedAutomation({ ...selectedTrigger, event }))
    }

    recordRSEvent(RudderStackAutomationBuilderEvents.smartComponentTriggerSaveClick, {
      context: RudderStackAutomationBuilderContext.automationBuilderTrigger,
      automationId: selectedAutomation.id,
      triggerId: !selectedTrigger ? triggerId : selectedTrigger.triggerId
    })

    clearRightSide();
  };

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.automationBuilder, RudderStackAutomationBuilderEvents.smartComponentTriggerOnLoad);
  }, [])

  // For Smart Component Automation
  const onFormSubmit = (data:FieldValues) => {
    // validation for Action Name
    if (!data?.triggerName) {
      setError("triggerName", {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    if (data?.triggerName.length <= 3) {
      setError("triggerName", {
        type: "manual",
        message: "Name must be greater than 3 characters",
      });
      return;
    }

    //TODO This is commented because this validation not working for list scenario  || Need to rewrite this validation for trigger logic

    // if (Object.keys(selectedTriggerValue).length == 0 || showEvent.length == 0) {
    //   setError("value", {
    //     type: "manual",
    //     message: "This is a required field",
    //   });
    //   return;
    // }

    const eventData = {
      label: data?.triggerName,
      description: data?.triggerName,
      componentData: actionDropdown
    }

    // Add New Trigger to selected Automation
    const triggerId = generateId(6);
    if (!selectedTrigger) {
      const newTriggerData = {
        triggerId,
        errors: [],
        event: eventData,
        metadataType
      }

      dispatch(addTriggerInAutomation({
        automationId: selectedAutomation.id,
        action: newTriggerData,
      }));


    } else {
      // Update Existing Trigger
      dispatch(updateTriggerInSelectedAutomation({ ...selectedTrigger, event: eventData }))
    }

    recordRSEvent(RudderStackAutomationBuilderEvents.smartComponentTriggerSaveClick, {
      context: RudderStackAutomationBuilderContext.automationBuilderTrigger,
      automationId: selectedAutomation.id,
      triggerId: !selectedTrigger ? triggerId : selectedTrigger.triggerId
    })


    clearRightSide();
  }

  useEffect(() => {
    if (selectedTrigger?.event) {
      dispatch(setActionPayload(selectedTrigger?.event?.componentData));
    }
  }, [selectedTrigger])

  useEffect(() => {

    if (selectedTrigger?.event?.componentData && actionDropdown?.length > 0) {

      const operationValue = getDefaultFunction(selectedTrigger?.event?.componentData.length - 2)?.events;
      const selectedValue = selectedTrigger?.event?.componentData[selectedTrigger?.event?.componentData.length - 1];

      if (operationValue) {
        // Trigger Option Array
        setShowEvent(operationValue);

        const defaultValue = operationValue.find((data:any) => data.id == selectedValue);
        setSelectedTriggerValue(defaultValue);
      }
    }
  }, [actionDropdown])

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
    clearErrors
  } = useForm();

  const getArrayToIterate = (index: number) => {
    const componentsData = trigger?.components;
    if (index == 0) {
      return componentsData;
    } else {
      const dropDownOptionsArray = [componentsData];
      for (let loopIndex = 0; loopIndex < actionDropdown.length; loopIndex++) {
        const newOne = dropDownOptionsArray[loopIndex]?.find((data: any) => data.id == actionDropdown?.[loopIndex]);
        dropDownOptionsArray.push(newOne?.struct);
        if (loopIndex == index) {
          break;
        }
      }
      return dropDownOptionsArray[index];
    }
  }

  const getSelectedValue = (index: number) => {
    return actionDropdown?.[index];
  }

  const getDefaultFunction = (index: number) => {
    const value = getArrayToIterate(index)?.find((data: any) => data.id == getSelectedValue(index));
    // if the value is undefined, sometime the  value in dropdown is not clearing, so we are sending {} - empty object when value is undefined
    return value || {};
  }

  return (
    <>

      {/* For Screen, System & Decision Node */}
      {trigger?.events && trigger?.events?.map((event: any) =>
        <>
          <ListCard event={event} clickList={() => {
            updateSelectedTrigger(event)
          }} />
        </>
      )}

      <form onSubmit={handleSubmit(onFormSubmit)}>

        {
          (metadataType == ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT) &&
          <>
          <ABText>Triggers are events that occur to let the user proceed to the next step. You can use triggers to initiate an automation.</ABText>
          <div style={{ marginBottom: "8px" }}></div>
          <HeadingWrapper>
            <Heading> Triggers </Heading>
            <IsActive closeOnClick={clearRightSide} />
          </HeadingWrapper>
          <div style={{ marginBottom: "8px" }}></div>
          </>
        }


        {/* For AB Smart Component */}
        {
          (trigger?.components) &&
          <>
            <Label> Name </Label>
            <ModalSmartInputTextField
              value={selectedTrigger?.event?.label || "New Trigger"}
              placeholder="Describe the Trigger Name Here"
              register={register}
              dataType={DataFieldType.TEXT}
              control={control}
              errors={errors}
              name="triggerName"
            />

            <ABSelectWrapper>
              <ABSelect
                options={trigger?.components}
                optionLabel={optionLabel}
                optionId={optionId}
                placeHolder={"Select Component"}
                label={"Select Component"}
                errors={Object.keys(getDefaultFunction(0)).length > 0 ? {} : errors}
                errorMessageName={"value"}
                defaultValue={getDefaultFunction(0)}
                onClickFunction={(optionValue) => {
                  clearErrors('value');

                  if (optionValue)
                    dispatch(setActionPayload([optionValue.id]));
                  else
                    dispatch(setActionPayload([]));


                  if (optionValue?.events)
                    setShowEvent(optionValue?.events);
                  else
                    setShowEvent([]);


                  setSelectedTriggerValue({});
                }}
              />
            </ABSelectWrapper>
          </>
        }

        {actionDropdown?.length > 0 &&
          actionDropdown.map((actionDropdownData: any, index: number) => {
            return (
              <>
                {
                  getDefaultFunction(index)?.struct?.length > 0 &&

                  <ABSelectWrapper>
                    <ABSelect
                      options={getDefaultFunction(index)?.struct}
                      optionLabel={optionLabel}
                      optionId={optionId}
                      placeHolder={getDefaultFunction(index)?.label}
                      label={getDefaultFunction(index)?.label}
                      errors={Object.keys(getDefaultFunction(index + 1)).length > 0 ? {} : errors}
                      errorMessageName={"value"}
                      defaultValue={getDefaultFunction(index + 1)}
                      onClickFunction={(optionValue: any) => {
                        clearErrors('value');

                        if (optionValue) {
                          if (actionDropdown.length === index + 1) {
                            // Newly selecting in dropdown value
                            dispatch(setActionPayload([...actionDropdown, optionValue?.id]));
                          } else {
                            // when already selected value is changed
                            // updating the array and remove the balance elements
                            const tmpData = cloneDeep(actionDropdown);
                            tmpData[index + 1] = optionValue?.id
                            tmpData.splice(index + 2);
                            dispatch(setActionPayload(tmpData));

                            // clearing event dropdown
                            setShowEvent([]);
                            setSelectedTriggerValue({});
                          }

                          // checking selectedValue has events, then show event dropdown
                          if (optionValue?.events)
                            setShowEvent(optionValue?.events);


                          setSelectedTriggerValue({});
                        } else {
                          // When clear button in dropdown is pressed
                          const tmpData = cloneDeep(actionDropdown);
                          tmpData.splice(index + 1);
                          dispatch(setActionPayload(tmpData));
                        }

                      }}
                    />
                  </ABSelectWrapper>
                }
              </>
            )
          })
        }

        {
          showEvent?.length > 0 &&
          <>
            <ABSelectWrapper>
              <ABSelect
                options={showEvent}
                optionLabel={optionLabel}
                optionId={optionId}
                placeHolder={"Select Trigger"}
                label={"Select Trigger"}
                errors={selectedTriggerValue && Object.keys(selectedTriggerValue)?.length > 0 ? {} : errors}
                errorMessageName={"value"}
                defaultValue={selectedTriggerValue}
                onClickFunction={(eventValue) => {
                  clearErrors('value');
                  if (eventValue) {
                    setSelectedTriggerValue(eventValue);
                    if (actionDropdown.includes(eventValue?.id)) {

                      const tmpData = cloneDeep(actionDropdown);
                      const eventValueIndex = tmpData.findIndex((data:any) => data == eventValue?.id);
                      tmpData[eventValueIndex] = eventValue?.id;

                    } else {
                      dispatch(setActionPayload([...actionDropdown, eventValue?.id]));
                    }

                  } else {

                  }

                }}
              />
            </ABSelectWrapper>
          </>
        }

      </form>

    </>
  );
};

export default SelectTrigger;
