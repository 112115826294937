// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pushNotification__container{
    /* display: flex;
    justify-content: center; */
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 35vw;
    background-color: white;
    margin-left: 30vw;
    margin-top: 30vh;
    padding: 8px;
    border-radius: 4px;
    
}
 
.pushNotification__input {
    border: 2px solid #eaeaef;
    padding: 8px;
    border-radius: 8px;
    color: black;
    width: 100%;
    font-weight: 400;
}

.pushNotification__heading {
    font-weight: 600;
    margin-bottom: 14px;
}


.flex__div {
    display: flex;
    gap: 8px;
}

.pushNotification__filed {
    margin-bottom: 1rem;
    display:flex;
    width: 50%;
    /* justify-content: center; */
}

.pushNotification__icon {
    display: flex;
    align-items: center;
    margin-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/views/workflows/workflowactions/api/PushNotification.css"],"names":[],"mappings":"AAAA;IACI;8BAC0B;IAC1B,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;;AAEtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;;AAGA;IACI,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,UAAU;IACV,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".pushNotification__container{\n    /* display: flex;\n    justify-content: center; */\n    margin-top: 2rem;\n    margin-left: 1rem;\n    margin-right: 1rem;\n    width: 35vw;\n    background-color: white;\n    margin-left: 30vw;\n    margin-top: 30vh;\n    padding: 8px;\n    border-radius: 4px;\n    \n}\n \n.pushNotification__input {\n    border: 2px solid #eaeaef;\n    padding: 8px;\n    border-radius: 8px;\n    color: black;\n    width: 100%;\n    font-weight: 400;\n}\n\n.pushNotification__heading {\n    font-weight: 600;\n    margin-bottom: 14px;\n}\n\n\n.flex__div {\n    display: flex;\n    gap: 8px;\n}\n\n.pushNotification__filed {\n    margin-bottom: 1rem;\n    display:flex;\n    width: 50%;\n    /* justify-content: center; */\n}\n\n.pushNotification__icon {\n    display: flex;\n    align-items: center;\n    margin-left: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
