import { Fragment, useState } from 'react';
import {Confirm, useRefresh, useNotify, useUnselectAll, useListContext } from 'react-admin';
import notification from "../../notifications/notifications";
import { getConfig as Config } from '../../config/envConfig/Config';
import { Button, CircularProgress } from "@mui/material";
import jwt_decode from "jwt-decode";

const CustomBulkActionButton = () => {
    const {
        selectedIds,
        resource,
        filterValues
    } = useListContext();
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify()
    const unselectAll = useUnselectAll();
    const confirmMessage = "Are you sure you want to collate \"" + selectedIds + "\" tasks?";
    const [executing, setExecuting] = useState(false);

    const collateTasks = () => {
        let jwtDecoded = jwt_decode(localStorage.getItem("at"));
        async function callCollateLambda() {
            
            const response = await fetch(Config("services.collateLambdaSvc.host"), {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "taskIds": Object.values(selectedIds),
                        "userId" : jwtDecoded.userId,
                        "emailId" : jwtDecoded.emailId
                    })
                });
                if (response?.status === 200) {
                    response.json().then(data => {
                        notification("success", data?.message);
                    }).catch(err=>{
                        notification("error", err);
                        console.error(err)
                    });
                } else if (response?.status === 400) {
                    response.json().then(data => {
                        notification("error", data?.message + "");
                        console.error(data);
                    }).catch(err=>{
                        notification("error", err);
                        console.error(err)
                    });
                }  else if (response?.status === 503) {
                    response.json().then(data => {
                        notification("error", data?.message + ". Could be taking more time to respond. Please check the task status.");
                        console.error(data);
                    }).catch(err=>{
                        notification("error", err);
                        console.error(err)
                    });
                }  else {
                    response.json().then(data => {
                        notification("error", data?.message);
                        console.error(data);
                    }).catch(err=>{
                        notification("error", err);
                        console.error(err);
                    });
                }
                setExecuting(false);
                unselectAll(resource);
                refresh();
        };
        callCollateLambda();
    };

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        setOpen(false);
        setExecuting(true);
        collateTasks();
    };

    return (
        <Fragment>
            <Button label="Collate" disabled={executing} onClick={handleClick}>
                {executing ? <CircularProgress size={14} /> : 'Collate'}
            </Button>
            <Confirm
                isOpen={open}
                title="Collate Tasks"
                content={confirmMessage}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />

        </Fragment>
    );
}

export default CustomBulkActionButton;