//@ts-nocheck
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";

import _ from "lodash";

import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import styled, { useTheme } from "styled-components";
import { StepIconProps } from "@mui/material";
import { IStepper } from "./types/types";
import { AiOutlineCheck } from "react-icons/ai";
import {
  FiZap,
  FiClock,
  FiUser,
  FiSettings,
  FiMonitor,
  FiTerminal,
  FiSmartphone,
  FiCheckCircle,
} from "react-icons/fi";
import { formatDate } from "./handlers/TransitionHandler";
import styledfrom from "styled-components";
import { HiOutlineChevronDown, HiOutlineChevronRight } from "react-icons/hi";
import { BiErrorAlt } from "react-icons/bi";

type Props = {
  logs: IStepper[];
};
//PILL
const DescriptionStrip = styled.div`
  background: ${(props) => props.theme.color.tertiary};
  color: ${(props) => props.theme.color.primary};
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  justify-content: space-between;
  border-radius: 4px;
  margin-left: 35px;
`;

const DescriptionWrapper = styled.div`
  margin-left: 10px;
  border-left: ${(props: { last?: boolean }) =>
    props.last ? "none" : "1px solid #bdbdbd"};
`;

const WrapperDiv = styled(Box)`
  &.special-word {
    color: ${(props) => `${props.theme.colors.primary} !important`};
  }
`;

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: theme.color.primary,
  width: 20,
  height: 20,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  background: theme.color.primary,
}));

export const returnIcon = (event: string) => {
  switch (event) {
    case "CC":
      return <FiMonitor style={{ color: "rgba(0,0,0,0.5)" }} size={"1.2rem"} />;
    case "Dashboard":
      return <FiMonitor style={{ color: "rgba(0,0,0,0.5)" }} size={"1.2rem"} />;
    case "api":
      return (
        <FiTerminal style={{ color: "rgba(0,0,0,0.5)" }} size={"1.2rem"} />
      );
    case "App":
      return (
        <FiSmartphone style={{ color: "rgba(0,0,0,0.5)" }} size={"1.2rem"} />
      );
    case "ASSIGN":
      return <FiUser style={{ color: "rgba(0,0,0,0.5)" }} size={"1.5rem"} />;
    case "UNASSIGN":
      return <FiUser style={{ color: "rgba(0,0,0,0.5)" }} size={"1.5rem"} />;
    case "UPDATE_TEAM":
      return <FiUser style={{ color: "rgba(0,0,0,0.5)" }} size={"1.5rem"} />;
    case "SCHEDULE":
      return <FiClock style={{ color: "rgba(0,0,0,0.5)" }} size={"1.5rem"} />;
    case "UNSCHEDULE":
      return <FiClock style={{ color: "rgba(0,0,0,0.5)" }} size={"1.5rem"} />;
    case "ACTION":
      return (
        <FiSettings style={{ color: "rgba(0,0,0,0.5)" }} size={"1.5rem"} />
      );
    case "GUARD_PASSED":
      return (
        <FiCheckCircle style={{ color: "rgba(0,0,0,0.5)" }} size={"1.5rem"} />
      );
    case "GUARD_FAILED":
      return (
        <BiErrorAlt style={{ color: "rgba(0,0,0,0.5)" }} size={"1.5rem"} />
      );
    default:
      return <FiZap style={{ color: "rgba(0,0,0,0.5)" }} size={"1.5rem"} />;
  }
};

function ColorlibStepIcon(props: StepIconProps & { index: number }) {
  const { active, completed, className, index } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <AiOutlineCheck />,
    2: <AiOutlineCheck />,
    3: <AiOutlineCheck />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {/* <AiOutlineCheck size={"1.5rem"} /> */}
    </ColorlibStepIconRoot>
  );
}

export default function ZStepper({ logs }: Props) {
  const [activeStep, setActiveStep] = React.useState(_.range(0, logs.length));
  const theme = useTheme();

  const handleNext = (index: number) => {
    setActiveStep((prevActiveStep: any) => {
      if (prevActiveStep.includes(index)) {
        return prevActiveStep.filter((i: any) => i !== index);
      } else {
        return [...prevActiveStep, index];
      }
    });
  };

  const showModal = (index: number) => { };

  return (
    <>
      <Box height="80vh" style={{ overflow: "auto" }} p="0px 8px">
        <Stepper orientation="vertical">
          {_.isArray(logs) && logs.length > 0
            ? logs.map((log, index) => (
              <Step
                active={
                  //@ts-ignore
                  activeStep.includes(index)
                }
                key={log.label}
              >
                <StepLabel
                  onClick={() => handleNext(index)}
                  StepIconComponent={ColorlibStepIcon}
                  sx={{
                    "& .MuiStepLabel-root": {
                      alignItems: "flex-start",
                    },
                  }}
                >
                  <>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Box display="flex" alignItems="center" gap="4px">
                          <Box display="flex">
                            {
                              //@ts-ignore
                              activeStep.includes(index) ? (
                                <HiOutlineChevronDown size="1.5rem" />
                              ) : (
                                <HiOutlineChevronRight size="1.5rem" />
                              )
                            }
                          </Box>
                          <Box>
                            <p
                              style={{
                                fontWeight: 600,
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              {log.label}{" "}
                            </p>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "rgba(0, 0, 0, 0.5)",
                            fontWeight: 400,
                          }}
                        >
                          {formatDate(log.createdOn)}
                        </p>
                      </Box>
                    </Box>
                  </>
                </StepLabel>
                <DescriptionWrapper last={index === logs.length - 1}>
                  <DescriptionStrip>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      gap="5px"
                    >
                      <Box>
                        <FiZap size="1rem" />
                      </Box>
                      <Box>
                        <p
                          style={{
                            fontSize: "12px",
                            color: theme.color.secondary,
                            fontWeight: 400,
                          }}
                        >
                          {log.description}
                        </p>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="8px">
                      <p style={{ color: theme.color.secondary, fontWeight: "600" }}>
                        By {log.subDescription}
                      </p>
                      {returnIcon(log?.icon || "")}
                    </Box>
                  </DescriptionStrip>
                </DescriptionWrapper>
                <StepContent
                  sx={{
                    paddingLeft: "42px",
                    marginLeft: "10px",
                  }}
                >
                  {log.points.map((point, index) => (
                    <>
                      <Box
                        display="flex"
                        gap="5px"
                        alignItems="center"
                        p="8px 0px"
                      >
                        <Box
                          style={{
                            background:
                              `linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), ${theme.color.primary}`,
                            padding: "4px",
                            borderRadius: "50%",
                          }}
                        >
                          {returnIcon(point.event)}
                        </Box>
                        <Typography
                          dangerouslySetInnerHTML={{ __html: point.label }}
                          fontSize="12px"
                          key={index}
                        />
                        {point.showMore}

                        {/* {point}
                        </Typography> */}
                      </Box>
                      <div style={{ marginBottom: "8px" }}>
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "rgba(0, 0, 0, 0.5)",
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {formatDate(point.createdOn)}
                        </Typography>
                      </div>
                    </>
                  ))}
                </StepContent>
              </Step>
            ))
            : Object.keys(logs).map((key: string, index: number) =>
              //@ts-ignore
              logs[key].map((log: IStepper, index: number) => (
                <Step key={log.label}>
                  <StepLabel
                    onClick={() => handleNext(index)}
                    StepIconComponent={ColorlibStepIcon}
                    sx={{
                      "& .MuiStepLabel-root": {
                        alignItems: "flex-start",
                      },
                    }}
                  >
                    <>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Box display="flex" alignItems="center" gap="4px">
                            <Box>
                              <HiOutlineChevronDown size="1.5rem" />
                            </Box>
                            <Box>
                              <p
                                style={{
                                  fontWeight: 600,
                                  fontSize: "12px",
                                }}
                              >
                                {" "}
                                {log.label}{" "}
                              </p>
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <p
                            style={{
                              fontSize: "12px",
                              color: "rgba(0, 0, 0, 0.5)",
                              fontWeight: 400,
                            }}
                          >
                            {formatDate(log.createdOn)}
                          </p>
                        </Box>
                      </Box>

                      <DescriptionStrip>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap="5px"
                        >
                          <Box>
                            <FiZap size="1rem" />
                          </Box>
                          <Box>
                            <p
                              style={{
                                fontSize: "12px",
                                // color: "rgba(0, 0, 0, 0.5)",
                                fontWeight: 400,
                              }}
                            >
                              {log.description}
                            </p>
                          </Box>
                        </Box>
                        <Box>
                          <p style={{ color: theme.color.primary }}>
                            By {log.subDescription}
                          </p>
                        </Box>
                      </DescriptionStrip>
                    </>
                  </StepLabel>
                  <StepContent
                    sx={{
                      paddingLeft: "42px",
                      marginLeft: "25px",
                    }}
                  >
                    {log.points.map((point, index) => (
                      <>
                        <Box
                          display="flex"
                          gap="5px"
                          alignItems="center"
                          p="8px 0px"
                        >
                          <Box
                            style={{
                              background: `linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), ${theme.color.primary}`,
                              padding: "4px",
                              borderRadius: "50%",
                            }}
                          >
                            <FiZap
                              style={{ color: "rgba(0,0,0,0.5)" }}
                              size="1rem"
                            />
                          </Box>
                          <Typography
                            dangerouslySetInnerHTML={{ __html: point.label }}
                            fontSize="12px"
                            key={index}
                          />
                          {/* {point}
                        </Typography> */}
                        </Box>
                        <div style={{ marginBottom: "8px" }}>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: "rgba(0, 0, 0, 0.5)",
                              fontWeight: 400,
                            }}
                          >
                            {" "}
                            {formatDate(point.createdOn)}{" "}
                          </Typography>
                        </div>
                      </>
                    ))}
                  </StepContent>
                </Step>
              ))
            )}
        </Stepper>
        {/* {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )} */}
      </Box>
    </>
  );
}
