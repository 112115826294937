import { Box, FormControl, Grid } from "@mui/material";
import { DocsLink } from "constants/CommonConstants";
import notification from "notifications/notifications";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RedText, TaskFormBody } from "render-engine/render.styles";
import { getFullDate } from "utils/DateFunctions.utils";
import {
  ZButton,
  ZLabel,
} from "views/commonComponents/commonComponents.styles";
import { ZInfo } from "views/commonComponents/ZInfo";
import ZInput from "views/commonComponents/ZTextInput";
import Callout from "views/onboarding/components/Callout";
import {
  clearRolesPageData,
  createRole,
  getRoleById,
  updateRole,
  updateRoleAcl,
} from "./reducers/Roles.actions";
import RoleAcl from "./RoleAcl";

const RoleCreate = (props: any) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { roleDetails = {}, status = "" } = useSelector(
    (state: any) => state.rolesPage
  );

  const initForm = (data: any) => {
    const { name = "", description = "", roleId = "", createdOn = "" } = data;
    setValue("name", name);
    setValue("description", description);
    setValue("roleId", roleId);
    setValue("createdOn", getFullDate(createdOn, "-"));
  };

  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (id) dispatch(getRoleById(id));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (roleDetails) {
      initForm(roleDetails);
    }
  }, [roleDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (status.match(/200|201/)) {
      Promise.resolve(clearRolesPageData()).then(() => {
        navigate("/settings/roles");
      });
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(clearRolesPageData());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAclChange = async (val: any) => {
    const roleUpdatePayload = {
      role: id,
      resourceActions: val,
    };
    await dispatch(updateRoleAcl(roleUpdatePayload));
  };

  const onSubmit = (data: any) => {
    const roleCreatePayload = {
      name: data?.name,
      description: data?.description,
    };
    if (id) {
      dispatch(updateRole({ id, roleCreatePayload }));
    } else {
      dispatch(createRole(roleCreatePayload));
    }
  };

  const cancelCreate = () => {
    navigate("/settings/roles");
    // notification("info", "Not implemented yet")
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "2rem" }}>
      <TaskFormBody>
        <div className="contentWrapper">
          <Callout
            text={
              <p>
                {" "}
                You can create your roles here.{" "}
                <a
                  style={{ color: "blue" }}
                  href={DocsLink.ROLES}
                  target="_black"
                  rel="noreferrer"
                >
                  {" "}
                  Know more about Role{" "}
                </a>{" "}
              </p>
            }
          />
          <Grid container spacing={2} mt={3} mb={4}>
            {id && (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <ZLabel className="mb-1">Role Id</ZLabel>
                    <ZInput register={register} name="roleId" isReadOnly />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <ZLabel className="mb-1">Created On</ZLabel>
                    <ZInput register={register} name="createdOn" isReadOnly />
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <ZLabel>
                  Name <RedText>*</RedText>{" "}
                </ZLabel>
                <ZInput
                  register={register}
                  name="name"
                  validations={{ required: true }}
                />
                {errors && errors["name"] && (
                  <ZInfo
                    className="mt-1"
                    errors={errors}
                    name={"name"}
                    requiredMessage={"Name is required"}
                    isError={true}
                  />
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <ZLabel>
                  Description <RedText>*</RedText>
                </ZLabel>
                <ZInput
                  register={register}
                  name="description"
                  validations={{ required: true }}
                />
                {errors && errors["description"] && (
                  <ZInfo
                    className="mt-1"
                    errors={errors}
                    name={"description"}
                    requiredMessage={"Description is required"}
                    isError={true}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>

          {id && (
            <>
              <RoleAcl
                roleId={id}
                readOnly={false}
                onChange={handleAclChange}
              />
              <hr />
            </>
          )}
          <hr />
          <Box
            mt={16}
            display="flex"
            alignItems="center"
            justifyContent="start"
          >
            <ZButton type="submit" variant="contained">
              {!id ? `Create Role` : `Update Role`}
            </ZButton>
            <ZButton
              variant="contained"
              onClick={cancelCreate}
              secondary
              className="ml-2"
            >
              Cancel
            </ZButton>
          </Box>
        </div>
      </TaskFormBody>
    </form>
  );
};

export default RoleCreate;
