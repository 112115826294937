import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import useWidth from "hooks/useWidth";
import React, { useEffect } from "react";
import {
  EditButton,
  Show,
  ShowController,
  TopToolbar,
  useRedirect,
  useRefresh,
  useShowContext,
} from "react-admin";
import { AiOutlineClose } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { SingleTableCell, TaskFormBody } from "render-engine/render.styles";
import {
  ZButton,
  ZIconButton,
} from "views/commonComponents/commonComponents.styles";
import UserDisplayBlock from "views/users/UserDisplayBlock";
import { PageCategoryEnum, recordPageVisit } from "../../config/Rudderstack";
import notification from "../../notifications/notifications";
import { TeamService } from "../../providers/data/services/TeamService";
import { UserService } from "../../providers/data/services/UserService";
import { TeamHierarchy } from "./TeamHierarchy";
import { ZIconButton2 } from "views/entities/styles";
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem; /* px-6 in Tailwind CSS */
  padding-right: 1.5rem; /* px-6 in Tailwind CSS */
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 1.5rem; /* mt-6 in Tailwind CSS */
  column-gap: 2rem; /* gap-x-8 in Tailwind CSS */

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr); /* sm:grid-cols-2 in Tailwind CSS */
  }
`;

const TeamShowActions = () => {
  const {id} = useParams();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);
  const large = useWidth();

  const navigate = useNavigate();

  const refresh = useRefresh();
  const redirect = useRedirect();

  const deleteTeam = () => {
    TeamService.deleteTeam(id)
      .then((res) => {
        if (res.code === "200") {
          notification("success", res?.message);
          redirect(`/teams`);
          refresh();
        } else {
          notification("error", res?.message);
          // redirect(`/users/${id}/show`);
          // refresh();
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    TeamService.deleteTeam(id)
      .then((res) => {
        if (res.code === "200") {
          notification("success", res?.message);
          redirect(`/teams`);
          refresh();
        } else {
          notification("error", res?.message);
          // redirect(`/users/${id}/show`);
          // refresh();
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };
  return (
    <>
      <TopToolbar className="tool-bar">
        <div>
          {large ? (
            <ZButton
              onClick={() => navigate("/settings/teams")}
              startIcon={<ChevronLeft className="svg_icons_l" />}
              secondary
              variant="contained"
            >
              Back
            </ZButton>
          ) : (
            <ZIconButton2
              variant="contained"
              onClick={() => navigate("/settings/teams")}
              style={{
                backgroundColor: "transparent",
                marginRight: 0,
              }}
              secondary
            >
              <ChevronLeft className="svg_icons_l" />
            </ZIconButton2>
          )}
        </div>
        <div style={{display:"flex", justifyContent:"center", alignContent:"center"}}>
        <EditButton
          style={{
            border: "1px solid #c2c9d1",
            color: "#344054",
            fontSize: "1rem",
            fontWeight: "600",
            backgroundColor: "#ffffff",
            marginRight: "8px",
            padding: "6px 16px",
            borderRadius: "4px",
          }}
        />
        {large ? (
          <ZButton
            onClick={deleteTeam}
            startIcon={<FiTrash2/>}
            danger
            variant="contained" 
            className="mr-2"
          >
            Delete
          </ZButton>
        ) : (
          <ZIconButton className="mr-2" variant="contained" onClick={deleteTeam} danger>
            <FiTrash2/>
          </ZIconButton>
        )}
        {large ? (
          <ZButton
            onClick={handleClickOpen}
            startIcon={<AccountTreeIcon />}
            variant="contained"
            secondary
          >
            Team Hierarchy
          </ZButton>
        ) : (
          <ZIconButton2 onClick={handleClickOpen}>
            <AccountTreeIcon />
          </ZIconButton2>
        )}
        </div>
      </TopToolbar>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
           Team Hierarchy
            </div>
            <div>
            
            <AiOutlineClose onClick={handleClose}/>
            </div>
          </div>
        </DialogTitle>
        <StyledDialogContent>
          <TeamHierarchy />
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UsersOfTeam = (props) => {
  const [users, setUsers] = React.useState([]);

  useEffect(() => {
    UserService.listUsers({
      filter: { teamIds: [props.teamId] },
      pagination: { page: 1, perPage: 3000 },
    })
      .then((response) => {
        setUsers(response?.data?.users);
      })
      .catch((error) => {
        notification("error", "Error while fetching users for team");
      });
  }, [props.teamId]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <SingleTableCell headerCell align="left">
            <p>User Id</p>
          </SingleTableCell>
          <SingleTableCell headerCell align="left">
            <p>Name</p>
          </SingleTableCell>
          <SingleTableCell headerCell align="left">
            <p>Phone Number</p>
          </SingleTableCell>
          <SingleTableCell headerCell align="left">
            <p>Role</p>
          </SingleTableCell>
          <SingleTableCell headerCell align="left">
            <p>Status</p>
          </SingleTableCell>
          <SingleTableCell headerCell align="left">
            <p>Active</p>
          </SingleTableCell>
        </TableRow>
      </TableHead>
      {users?.map((user, index) => (
        <TableBody>
          <TableRow
            style={
              index % 2 ? { background: "#fff" } : { background: "#F5F5F5" }
            }
            key={user.userId}
          >
            <SingleTableCell align="left">
              <p>{user.userId}</p>
            </SingleTableCell>
            <SingleTableCell align="left" scope="row">
              <p>{user.name}</p>
            </SingleTableCell>
            <SingleTableCell align="left" scope="row">
              <p>{user?.phone?.num}</p>
            </SingleTableCell>
            <SingleTableCell align="left" scope="row">
              <p>{user.role}</p>
            </SingleTableCell>
            {/* <TableCell align="left" scope="row">{user.teamIds}</TableCell> */}
            <SingleTableCell align="left" scope="row">
              <p>{user.status}</p>
            </SingleTableCell>
            <SingleTableCell align="left" scope="row">
              <p>{user.isActive.toString()}</p>
            </SingleTableCell>
          </TableRow>
        </TableBody>
      ))}
    </Table>
  );
};

export const TeamShow = (props) => {
  // const classes = useStyles();
  useEffect(() => {
    recordPageVisit(PageCategoryEnum.team, "team_details");
  }, [props]);
  // const {record} = useShowContext();
  const { id } = useParams();
  console.log(">>> id", id);

  return (
    <div className="showTaskParentDiv">
      <ShowController component="div">
        {(controllerProps) => {
          return (
            <TaskFormBody>
              <Show
                
                actions={<TeamShowActions />}
                {...controllerProps}
              >
                <div className="contentWrapper">
                  <div>
                    <h3 className="font-medium" style={{ padding: "10px 0px" }}>
                      Team Details
                    </h3>
                    <hr />
                    <GridContainer>
                      <UserDisplayBlock
                        label="Team Id"
                        value={controllerProps?.record?.teamId}
                      />
                      <UserDisplayBlock
                        label="Team Name"
                        value={controllerProps?.record?.name}
                      />
                      <UserDisplayBlock
                        label="Description"
                        value={controllerProps?.record?.description}
                      />
                      <UserDisplayBlock
                        label="Created On"
                        value={controllerProps?.record?.createdOn}
                      />
                    </GridContainer>
                  </div>
                  <div style={{
                    padding: "10px 0px",
                  }}>
                    <UsersOfTeam teamId={id} />
                  </div>
                </div>
              </Show>
            </TaskFormBody>
          );
        }}
      </ShowController>
    </div>
  );
};
