import styled from "styled-components";
import Modal from "@mui/material/Modal";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Box from "@mui/material/Box";

export const CurlTextArea = styled(TextareaAutosize)`
  width: 100%;
  padding-top: 6px;
  border: 1px solid #c2c9d1;
  border-radius: 8px;
  padding: 6px 8px;
  resize: none;
  height: 287px;
  max-height: 287px;
  border: 1px solid #d0d5dd;
  min-height: 287px;  
  margin-top: 8px;
  overflow: scroll;
  padding: 12px;
`;

export const CurlModal = styled(Modal)`
    display: flex;
    justify-content: center;
   align-items: center;
   /* height: 80vh; */
   overflow: auto;
`;

export const CurlModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: ${(props: { height?: string; width?: string }) =>
    props?.width ? props.width : "640px"};
  height: ${(props: { height?: string; width?: string }) =>
    props?.height ? props.height : "638px"};
  background: #ffffff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  position: absolute;
  top: 15%;
  left: 30%;
  border-radius: 12px;
`;

export const GPTDiv = styled.form`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
`;

export const CurlModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  height: 161px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #ffffff;
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid #eaecf0;
`

export const GPTModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #ffffff;
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid #eaecf0;
`

export const ImportIconWrapper = styled.div`
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #eaecf0;
  width: fit-content;
  height: fit-content;
`;

export const ImportCurlHeading = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
`;

export const ImportCurlSentence = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`;

export const ImportCurlHeaderInfoWrapper = styled.div`
  margin-top: 16px;
  padding-bottom: 16px;
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    display: block;
  }
`;

export const CreditRemaining = styled.div`
  font-weight: 600;
  color: #3054B9;
  font-size: 14px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }
`;

export const CurlModalBody = styled.div`
  width: 100%;
  height: 377px;
  padding-left: 24px;
  padding-right: 24px;
  background: #ffffff;
`;
export const GPTModalBody = styled.div`
  width: 100%;
  height: 84% !important;
  padding-left: 24px;
  padding-right: 24px;
`;

export const CurlModalFooter = styled.div`
  padding-top: 32px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 100px;
  background: #ffffff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

export const CurlBodyWrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  height: 313px;
`;

export const CurlBodyText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`;

export const CurlDocumentationTxt = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`;

export const CurlFooterLeftWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const CurlFooterRightWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

export const Heading = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #101828;
`;

export const Text = styled.p`
  color: #475467;
  font-size: 16px;
`;

export const TextSmall = styled.p`
  color: #475467;
  font-size: 12px;
`;

export const MainHeading = styled.p`
  font-weight: 600;
  font-size: 20px;
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledHr = styled.hr`
  border: none;
  // border-top: 1px solid #333; /* Change to desired color */
  color: #D3D3D3; /* Change to desired color */
  background-color: #D3D3D3; /* Change to desired color */
  height: 0.5px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const ColumnFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const GPTLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #344054;
`

export const GPTBodyDiv = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  /* height : calc(100% - 45px) !important; */
  overflow: hidden;
  height:100%;

  @media (max-width: 768px) {
    flex-direction: column;
    display: block;
    height: 70vh;
    overflow: auto;
  }
`

export const GPTSingleDiv = styled.div`
`

export const GPTNotEnoughCredits = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #d1270d;
  align-self: center;
  padding-top: 15px;
`
