import { Link, Modal } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { ModifyScheduleSlot } from "../task/edit/ModifyScheduleSlot";
import { ModifyTeamUser } from "../task/edit/ModifyTeamUser";
import { useNavigate } from "react-router-dom";


function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        borderRadius: "8px",
        border: "none",
        width: '15%'
    };
}

const PaperComp = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
}));


export const SchedulerModifyOptions = (props) => {
    const navigate = useNavigate();
    const [modalStyle] = React.useState(getModalStyle);

    const [openChangeScheduleSlot, setOpenChangeScheduleSlot] = React.useState(false);
    const [openTeamUserChange, setOpenTeamUserChange] = React.useState(false);

    const handleTaskDetailsClick = () => navigate(`tasks/${props.event.id}/show`);

    const handleScheduleSlot = () => setOpenChangeScheduleSlot(true);

    const handleUpdateTeamUserClick = () => setOpenTeamUserChange(true);

    const closeModification = () => {
        setOpenChangeScheduleSlot(false);
        setOpenTeamUserChange(false);
        props.onClose();
    }

    return (
        <Modal
            open={true}
            onClose={() => props.onClose(true)}
        >
            <PaperComp style={modalStyle}>
                <div className="flex flex-row">
                    <Link onClick={handleTaskDetailsClick}> Task Details </Link>
                </div>
                <div className="flex flex-row pt-4">
                    <Link onClick={handleScheduleSlot}> Schedule Slot </Link>
                    {openChangeScheduleSlot &&
                        <ModifyScheduleSlot
                            taskId={props.event.id}
                            scheduledFrom={props?.event?.start ? props.event.start : null}
                            scheduledTo={props?.event?.end ? props.event.end : null}
                            onClose={closeModification}
                        ></ModifyScheduleSlot>}
                </div>
                <div className="flex flex-row pt-4">
                    <Link onClick={handleUpdateTeamUserClick}> Change Team/User </Link>
                    {openTeamUserChange &&
                        <ModifyTeamUser
                            taskId={props.event.id}
                            userId={props.event.resourceId}
                            teamId={props.event.teamIds[0]}
                            onClose={closeModification} />
                    }
                </div>
            </PaperComp>
        </Modal >
    );

}