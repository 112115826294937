import { Modal } from '@mui/material';
import { FormControl, Grid } from "@mui/material";
import React from 'react';
import { useForm } from "react-hook-form";
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
import { stopPropagate } from 'utils/CommonUtils';
import { ZButton, ZLabel } from "views/commonComponents/commonComponents.styles";
import ZInput from "views/commonComponents/ZTextInput";
import ErrorMessage from 'views/DataField/ErrorMessage';
import { EditABNameButtonWrapper } from '../automationBuilder.styles';

export interface modalFormValue {
    webhookName: string;
    webhookSlug: string;
}

type IWebhookAutomationBuilderNameModal = {
    isOpen: boolean;
    onClose: () => void;
    onSave: (modalFormValue: modalFormValue) => void;
}

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        borderRadius: '4px',
        border: 'none',
        width: '30%',
        boxShadow: 'none'
    };
}

const Heading = styled.p`
  color: #101828;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

const HeaderLabel = styled.p`
  color: #475467;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;
const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 16px;
`;

const HelperText = styled.span`
  color: #475467;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 6px;
`;


const isValidString = (str: string) => {
    const regex = /^[a-zA-Z0-9_-]+$/;
    return regex.test(str);
}

// Styled components for modal and paper
const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaperContainer = styled.div`
  position: absolute;
  width: 450px;
  background-color: ${(props) => "#fff"};
  padding: ${(props) => "16px"};
`;

const WebhookAutomationBuilderNameModal = ({ onClose, onSave, isOpen }: IWebhookAutomationBuilderNameModal) => {
    const { register, handleSubmit, formState: { errors }, clearErrors, reset, setError } = useForm();
    const [modalStyle] = React.useState(getModalStyle);

    const onFormSubmit = (data: any) => {
        if (!isValidString(data?.webhookSlug)) {
            setError('webhookSlug', { type: 'manual', message: 'only alphabets, underscore and hyphen are allowed' });
            return;
        }

        onSave(data);
        reset();
    }

    return (
        <StyledModal open={isOpen} onClose={() => {
            reset();
            clearErrors();
            onClose();
        }}>
            <form onSubmit={stopPropagate(handleSubmit(onFormSubmit))}>
                <PaperContainer style={modalStyle}>
                    <HeadingWrapper>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Heading>Create New Webhook</Heading>
                            <HeaderLabel>Enter a name for your new hook</HeaderLabel>
                        </div>
                        <AiOutlineClose style={{ marginBottom: '19px', cursor: 'pointer' }} size="1.5rem" onClick={() => {
                            reset();
                            clearErrors();
                            onClose();
                        }} />
                    </HeadingWrapper>
                    <FieldWrapper>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" fullWidth>
                                <ZLabel>
                                    {" "}
                                    Webhook Name
                                </ZLabel>
                                <ZInput
                                    placeholder='Webhook Name'
                                    register={register}
                                    name="webhookName"
                                    validations={{ required: "Webhook Name is required" }}
                                />
                                <ErrorMessage errors={errors} fieldName={"webhookName"} />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} mt='16px'>
                            <FormControl variant="outlined" fullWidth>
                                <ZLabel>
                                    {" "}
                                    Unique Identifier Slug
                                </ZLabel>
                                <ZInput
                                    placeholder=''
                                    register={register}
                                    name="webhookSlug"
                                    validations={{ required: "Webhook Slug is required" }}
                                />
                                <HelperText>Used for webhook url</HelperText>
                                <ErrorMessage errors={errors} fieldName={"webhookSlug"} />
                            </FormControl>
                        </Grid>
                    </FieldWrapper>
                    <EditABNameButtonWrapper>
                        <ZButton
                            variant='contained'
                            secondary
                            onClick={() => {
                                reset();
                                clearErrors();
                                onClose();
                            }}>
                            Cancel
                        </ZButton>
                        <ZButton
                            type='submit'
                            variant='contained'
                            style={{ marginLeft: '12px' }}>
                            Confirm
                        </ZButton>
                    </EditABNameButtonWrapper>
                </PaperContainer>
            </form>
        </StyledModal>
    );
}

export default WebhookAutomationBuilderNameModal;
