import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import jwt_decode from "jwt-decode";
import * as React from "react";
import { forwardRef, useEffect } from "react";
import { useLogout, UserMenu } from "react-admin";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { recordRSEvent } from "utils/CommonUtils";
import notification from "../../notifications/notifications";
import { UserService } from "../../providers/data/services/UserService";
import UserIcon from "assests/user-01.svg";
import ChangePasswordIcon from "assests/edit-05.svg";
import LogoutIcon from "assests/log-out-01.svg";
import styled from "styled-components";
import { resetEntity } from "views/entities/reducers/entityReducers";

const WrapperDiv = styled.div`
  padding: 0px 10px;
  li {
    padding: 10px;
    border-radius: 4px;
  }
`;

const ConfigurationMenu = forwardRef((_props, _ref) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [passwd, setPasswd] = React.useState("");
  const [rePasswd, setRePasswd] = React.useState("");
  const [user, setUser] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const logout = useLogout();
  const handleClick = () => logout();
  const dispatch = useDispatch();

  useEffect(() => {
    const at = localStorage.getItem("at");
    const decoded = jwt_decode(at);
    setUser(decoded.userId);
    setUserName(decoded?.name);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRePasswd("");
    setPasswd("");
  };
  const handleLogout = async () => {
    try {
      dispatch(resetEntity());
      localStorage.removeItem("tableType");
      logout();
    } catch (error) {
      console.log(error);
    }
  };

  const redirect = () => {
    // navigate(`/users/${user}/show`);
    window.open(`/#/users/${user}/show`, "_blank");
  };

  const handleFinalChange = () => {
    if (passwd && passwd === rePasswd) {
      const taskPayLoad = {
        password: passwd,
      };
      const change = UserService.changePasswd(taskPayLoad);
      change
        .then((res) => {
          if (res.code === "200") {
            notification("success", res?.message);
            navigate("/");
          } else if (res?.data) {
            res?.data?.[0]?.split(",")?.map((errMsg) => {
              notification("error", errMsg);
            });

            recordRSEvent("changepassword_event_failed", {
              context: "user_password_change",
            });
          }
          setOpen(false);
          setPasswd("");
          setRePasswd("");
        })
        .catch((_err) => {
          notification("error", _err?.data);
          setOpen(false);
          setPasswd("");
          setRePasswd("");
        });
    }
  };

  return (
    <WrapperDiv>
      <MenuItem onClick={redirect}>
        <ListItemIcon>
          <img src={UserIcon} />
        </ListItemIcon>
        <div
          style={{ color: "#3C69E7", fontSize: "16px", marginLeft: "-15px" }}
        >
          User - {userName}{" "}
          <span style={{ color: "black", fontSize: "16px" }}> {user} </span>
        </div>
      </MenuItem>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
          <img src={ChangePasswordIcon} />
        </ListItemIcon>
        <div
          style={{ color: "#3C69E7", fontSize: "16px", marginLeft: "-15px" }}
        >
          Change Password
        </div>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <img src={LogoutIcon} />
        </ListItemIcon>
        <div
          style={{ color: "#3C69E7", fontSize: "16px", marginLeft: "-15px" }}
        >
          Logout
        </div>
      </MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent className="flex flex-col px-6">
          <TextField
            autoFocus
            variant="outlined"
            value={passwd}
            margin="dense"
            id="name"
            type="password"
            label="New Password"
            onChange={(e) => setPasswd(e.target.value)}
          />
          <TextField
            error={passwd !== rePasswd}
            variant="outlined"
            value={rePasswd}
            margin="dense"
            type="password"
            id="name"
            helperText={`The password must be between 8 and 30 characters in length.\n
              The password must not contain the username.\n
              The password must not include any whitespace characters.
              The password must contain at least one uppercase letter.
              The password must contain at least one lowercase letter.
              The password must include at least one digit.
              The password must have at least one special character (e.g., !, @, #).
              The password must not contain sequences of three or more consecutive alphabetical characters.
              The password must not contain sequences of three or more consecutive numerical characters.
              The password must not include sequences of three or more consecutive characters that appear in a line on a QWERTY keyboard.`}
            label="Re-Type Password"
            onChange={(e) => setRePasswd(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFinalChange} color="primary">
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
    </WrapperDiv>
  );
});

const CustomUserMenu = (props) => {

  return (
    <div>
      <UserMenu classes={{ root: "my-user-menu" }}>
        <ConfigurationMenu />
      </UserMenu>
    </div>
  );
};

const ZorpAppBar = (props) => {
  const isPreview = localStorage.getItem("isPreview");

  return (
    <div
      style={{
        // marginTop: isPreview ? "0px" : "-48px",
      }}
    ></div>
  );
};

export default ZorpAppBar;
