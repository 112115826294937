//@ts-nocheck
import notification from "notifications/notifications";
import { BffService } from "providers/data/services/BffService";
import { TaskRenderService } from "providers/data/services/TaskRenderService";
// @ts-ignore
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ITaskTypeWebRenderModel } from "render-engine/models/ITaskTypeWebRenderModel";
import { Render } from "render-engine/Render";
import { TaskFormBody } from "render-engine/render.styles";
import {
  getComponentParams,
  getComponentParamsEdit,
} from "render-engine/RenderUtils";
import TopBar from "render-engine/TopBar";
import { recordRSEvent } from "utils/CommonUtils";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import { setLoading } from "views/reducer/index";
import { isEmpty, isPlainObject } from "lodash";
import { initTaskShowMetaData } from "render-engine/reducers/show/TaskShowReducer";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { RenderV2 } from "render-engine/RenderV2";

const TransitionTask = () => {
  const store = useStore();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editTaskForm = useForm();

  const { taskId, selectedTransition, taskType } = useParams();

  const [disableEditButton, setDisableEditButton] = useState(false);
  const [renderConfig, setRenderConfig] = useState<ITaskTypeWebRenderModel>();

  const { loading } = useSelector((store: any) => store.loading);

  const preview = localStorage.getItem("isPreview") || false;
  const {
    taskData: data,
    isDataEdited,
    rowsToDelete,
    initTaskData,
    addedRecords,
    taskRenderDetails,
    recordDetails,
  } = useTaskShow();

  // Fetch the config for CC
  useEffect(() => {
    dispatch(initTaskShowMetaData({ taskId }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Task Edit Final Function
  const submitTaskEdit = (formData: any) => {
    /**
     * Removing location dataType key if the address, latitude and longitude are undefined
     */
    // loop through all the keys of data
    const payload = { ...data };
    const dataKeys = Object.keys(payload);
    // loop through dataKeys and check if the value is an object
    dataKeys.forEach((key) => {
      if (isPlainObject(payload[key]) === "object") {
        // get all the keys of the object
        const objectKeys = Object.keys(data[key]);
        // check if ojectKey has a value called address, latitude and longitude
        if (
          objectKeys.includes("address") &&
          objectKeys.includes("latitude") &&
          objectKeys.includes("longitude")
        ) {
          // check if the value of address, latitude and longitude is undefined
          if (
            (payload[key].address === undefined ||
              payload[key].address === "") &&
            payload[key].latitude === undefined &&
            payload[key].longitude === undefined
          ) {
            // if yes, then delete the key from data
            delete payload[key];
          }
        }
      }
    });
    if (selectedTransition) {
      dispatch(setLoading(true));
      const eventName = selectedTransition?.split("?")[0];
      const taskEditPayload: any = {
        taskId: taskId,
        event: eventName,
        formData: payload,
        channel: "Dashboard",
        mode: "single",
      };

      // const omitFieldsInData: Array<string> = ['scheduledSlot', 'taskType', 'teamIds'];
      // for (let key of Object.keys(store.getState().taskEdit)) {
      //   // Skip the omit fields for data
      //   if (!omitFieldsInData.includes(key)) taskEditPayload.formData[key] = store.getState().taskEdit[key];
      // }

      BffService.taskTransition(taskEditPayload)
        .then((res: any) => {
          if (res?.code) {
            if (res?.code.indexOf("200") < 0) {
              editTaskForm.reset();
              setDisableEditButton(false);
              // Error
              recordRSEvent("save_event_fail", {
                context: "task_edit",
                taskId: taskId,
                taskType: taskEditPayload.taskType,
              });
              notification("error", res.message);
            } else {
              setDisableEditButton(false);
              recordRSEvent("save_event_success", {
                context: "task_edit",
                taskId: taskId,
                taskType: taskEditPayload.taskType,
              });
              notification("success", res.message);
              //@ts-ignore
              if (preview) {
                window.parent.postMessage(
                  {
                    source: "zorp_web_preview",
                    name: "transit_task",
                    taskId,
                  },
                  "*"
                );
                navigate(`/task/${taskType}/show/${taskId}/show?preview=true`);
              } else {
                navigate(`/task/${taskType}/show/${taskId}`);
              }
            }
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          editTaskForm.reset();
          setDisableEditButton(false);
          dispatch(setLoading(false));
          recordRSEvent("save_event_fail", {
            context: "task_edit",
            taskId: taskId,
            taskType: taskEditPayload.taskType,
          });
          notification("error", "Task Transition Failed!");
        });
      return;
    }
  };

  // const handleEditClick = () => {
  //   // navigate(`/table/${taskType}/edit/${taskId}`)
  //   /**
  //    * Removing location dataType key if the address, latitude and longitude are undefined
  //    */
  //   // loop through all the keys of data
  //   const payload = { ...data };
  //   const dataKeys = Object.keys(payload);
  //   // loop through dataKeys and check if the value is an object
  //   dataKeys.forEach((key) => {
  //     if (isPlainObject(payload[key])) {
  //       // get all the keys of the object
  //       const objectKeys = Object.keys(payload[key]);
  //       // check if ojectKey has a value called address, latitude and longitude
  //       if (
  //         objectKeys.includes("address") &&
  //         objectKeys.includes("latitude") &&
  //         objectKeys.includes("longitude")
  //       ) {
  //         // check if the value of address, latitude and longitude is undefined
  //         if (
  //           (payload[key].address === undefined ||
  //             payload[key].address === "") &&
  //           payload[key].latitude === undefined &&
  //           payload[key].longitude === undefined
  //         ) {
  //           // if yes, then delete the key from payload
  //           delete payload[key];
  //         }
  //       }
  //     }
  //   });

  //   const keysFromRowsToDelete = Object.keys(rowsToDelete);
  //   // loop through the keys of rowsToDelete
  //   keysFromRowsToDelete.forEach((key) => {
  //     // check of the value of key is an array and it has length greater than 0
  //     if (Array.isArray(rowsToDelete[key]) && rowsToDelete[key].length > 0) {
  //       // check if this key is in payload
  //       if (dataKeys.includes(key)) {
  //         // if yes, then remove the rowsToDelete[key] from payload[key]
  //         const modifiedData = payload[key].filter(
  //           (item: any) => !rowsToDelete[key].includes(item)
  //         );
  //         payload[key] = modifiedData;
  //       } else {
  //         // check if the key is in initTaskData
  //         if (initTaskData && initTaskData[key]) {
  //           // if no, then remove the rowsToDelete[key] from initTaskData[key]
  //           const modifiedData = initTaskData[key].filter(
  //             (item: any) => !rowsToDelete[key].includes(item)
  //           );
  //           payload[key] = modifiedData;
  //         }
  //       }
  //     }
  //   });

  //   dispatch(setLoading(true));
  //   // const teamIds = data?.teamIds || []
  //   // delete data?.teamIds
  //   const taskEditPayload: any = {
  //     taskId,
  //     taskType,
  //     teamIds: recordDetails?.task?.teamIds || [],
  //     scheduledSlot: recordDetails?.task?.scheduledSlot,
  //     data: payload,
  //     mode: "single",
  //     channel: "CC",
  //   };

  //   BffService.taskTransition(taskEditPayload)
  //       .then((res: any) => {
  //         if (res?.code) {
  //           if (res?.code.indexOf("200") < 0) {
  //             editTaskForm.reset();
  //             setDisableEditButton(false);
  //             // Error
  //             recordRSEvent("save_event_fail", {
  //               context: "task_edit",
  //               taskId: taskId,
  //               taskType: taskEditPayload.taskType,
  //             });
  //             notification("error", res.message);
  //           } else {
  //             setDisableEditButton(false);
  //             recordRSEvent("save_event_success", {
  //               context: "task_edit",
  //               taskId: taskId,
  //               taskType: taskEditPayload.taskType,
  //             });
  //             dispatch(
  //               initTaskShowMetaData({
  //                 taskId,
  //               })
  //             );
  //             notification("success", res.message);

  //             //@ts-ignore
  //             if (preview) {
  //               window.parent.postMessage(
  //                 {
  //                   source: "zorp_web_preview",
  //                   name: "transit_task",
  //                   taskId,
  //                 },
  //                 "*"
  //               );
  //               navigate(`/task/${taskType}/show${taskId}/show?preview=true`);
  //             } else {
  //               navigate(`/task/${taskType}/show${taskId}`);
  //             }
  //           }
  //         }
  //         dispatch(setLoading(false));
  //       })
  //       .catch((err) => {
  //         editTaskForm.reset();
  //         setDisableEditButton(false);
  //         dispatch(setLoading(false));
  //         recordRSEvent("save_event_fail", {
  //           context: "task_edit",
  //           taskId: taskId,
  //           taskType: taskEditPayload.taskType,
  //         });
  //         notification("error", "Task Transition Failed!");
  //       });
  //     return;
  // };

  const cancelEdit = () => {
    notification("info", "Task edit cancelled!");
    navigate(`/task/${taskType}/show/${taskId}`);
  };

  return (
    <>
      {loading || isEmpty(taskRenderDetails) ? (
        <LoadingScreen />
      ) : (
        <FormProvider {...editTaskForm}>
          <form
            style={{ height: "94vh", overflow: "hidden" }}
            onSubmit={editTaskForm.handleSubmit(submitTaskEdit)}
          >
            <TopBar
              heading={`Transit Task - ${taskId}`}
              onCancelText={"Cancel"}
              onSaveText="Transit"
              onSave={() => {}}
              onCancel={cancelEdit}
            />
            <TaskFormBody key="taskCreate" className={`grid`}>
              <RenderV2
                componentParams={getComponentParams(dispatch, editTaskForm)}
                entityCategory={"TASK"}
                entityType={taskType}
                entityId={taskId}
                mode={"TRANSITION"}
              />
            </TaskFormBody>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default TransitionTask;
