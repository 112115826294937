// import React, { useEffect, useState } from "react";
import Select from "react-select";
import { UserService } from "../../providers/data/services/UserService";
// import { TeamValidator } from "../tasks/components/Validator";
import { FunctionComponent, useState, useEffect } from "react";
import {
  UseFormRegister,
  FieldErrors,
  Controller,
  Control,
  UseFormSetValue,
} from "react-hook-form";
import { IZUserListInterface } from "./IZUserListInterface";
import useTaskCreation from "views/workflows/hooks/useTaskCreation";
import { ColSpan4, HelperText } from "render-engine/render.styles";
import { AiOutlineWarning } from "react-icons/ai";


export const ZUserList: FunctionComponent<{
  config: IZUserListInterface;
  onChange: Function;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  control: Control;
  setValue: UseFormSetValue<any>;
  getValues: any
}> = ({ config, onChange, register, errors, control, setValue, getValues}) => {
  const [allUser, setAllUser] = useState<any[]>([]);
  const [driversAllAll, setDriversAllAll] = useState<any[]>([]);
  const [teamUser, setTeamUser] = useState(config?.value);
  const { teamId,userId } = useTaskCreation({ name: "" });
   
  useEffect(() => {
    const userResponse = UserService.listUsers({
      filter: { teamIds: [teamId] },
      pagination: { page: 1, perPage: 100 },
    });
    userResponse.then((res) => {
      let users: any[] = [];
      res?.data?.users.map((user: any) => {
        users.push({
          value: user?.userId,
          label: user?.name + " (" + user?.userId + ")",
        });
      });
      setAllUser(users);
      
    });
  }, [teamId, userId]);

  

  // useEffect(() => {
  //   setAllUser(driversAllAll);
  // }, [driversAllAll]);

  const handleUserChange = (e: any) => {
    setTeamUser(e);
    onChange(config.key, e);
  };

  return (
    <>
     
      <ColSpan4>
        {config?.label?.isVisible && (
          <div
            className="col-span-1 font-medium"
            style={{ padding: "4px 0px" }}
          >
            {config?.label?.val}
          </div>
        )}
        <Controller
          name={config?.key}
          // defaultValue={true}
          control={control}
          render={({
            field: { onChange: fieldOnChange, value, name, ref },
          }) => 
          
          (
            <Select
              // styles={customStyles}
              value={allUser.find((c: any) => c.value === value)}
              isClearable
              isSearchable={true}
              className="react-dropdown"
              classNamePrefix="dropdown"
              onChange={(selectedOption: any) => {
                fieldOnChange(selectedOption?.value);
                onChange(config.key, selectedOption?.value);
              }}
              options={allUser}
              key={`user_list_${teamId}`}
            />
          )}
        />
        {errors[config?.key] && errors[config?.key]?.message ? (
          <p className="text-red-500 text-sm font-medium mt-1">
            <>
              <AiOutlineWarning
                size={"1rem"}
                color="red"
                style={{ display: "inline", marginRight: "4px" }}
              />
              {errors[config?.key]?.message}
            </>
          </p>
        ) : (
          <HelperText>
            {" "}
            Select the user who will perform this task. You can change it later!{" "}
          </HelperText>
        )}
      </ColSpan4>
    </>
  );
};
