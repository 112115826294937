import { Link } from '@mui/material';
import logo from 'assests/darklogo.svg';
import emails from 'assests/emails.svg';
import { isEmpty } from 'lodash';
import { Navigate } from 'react-router-dom';
import { userDecodedData } from 'utils/UserAuth.utils';

const AwaitingVerification = () => {
  if (!isEmpty(userDecodedData())) {
    return (
      <Navigate
        to={{
          pathname: '/',
        }}
      />
    );
  }
  return (
    <div className='email'>
      <div className='emailInner'>
        <div style={{ display: 'flex', justifyContent: 'center' }} className='logoImg'>
          <img src={logo} alt='' />
        </div>
        <div className='emailImg'>
          <img src={emails} alt='' />
        </div>
        <h2 className='emailTitle'>Check your email for verification link</h2>
        <p style={{ fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>
          Already have an account?{' '}
          <Link style={{ fontWeight: '600', color: '#3e66fb' }} href='/#/login' underline='hover'>
            Login
          </Link>
        </p>
        <p className='copyright'>© Zorp 2022 </p>
      </div>
    </div>
  );
};

export default AwaitingVerification;
