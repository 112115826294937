import { CLOSE_AB, CLOSE_CONFIRMATION, CONTEXT_SWITCHING_CONFIRMATION } from 'constants/CommonMessages';
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { getAccountId } from 'utils/CommonUtils';
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { initAutomationBuilder, resetAutomation, setSelectedAutomation, updateAllAutomations } from 'views/automationBuilder/reducer/automationReducer';
import { ABMetadataTypeEnum, IAutomation, IAutomationBuilderOtherConfigs, OnSaveHandlerFunction } from 'views/automationBuilder/types/ABType';
import ZModalHeader from 'views/commonComponents/ZModalHeader';
import useWorkFlow from 'views/workflows/hooks/useWorkFlow';
import ABEntry from './ABEntry';
import './automationbuilder.css';
import ConfirmationDialog from './components/ConfirmationDialog';
import { HeaderComponent } from './components/HeaderComponent';
import { useNavigate } from 'react-router-dom';
import { useBlocker } from 'react-router-dom';

type Props = {
  prevAutomations?: IAutomation[];
  payload: ABMetadataTypeEnum,
  onSaveHandler: OnSaveHandlerFunction,
  otherConfigs: IAutomationBuilderOtherConfigs,
  onAutomationClose: () => void;
  openAutomationBuilder: boolean;
  stateId?: string,
  automationSelected?: string
}

const AutomationBuilder = ({ prevAutomations, payload, onSaveHandler, otherConfigs, onAutomationClose, openAutomationBuilder, stateId, automationSelected }: Props) => {

  const dispatch = useDispatch()
  const { taskTypeId, selectedAction } = useWorkFlow()
  const { selectedAutomation, allAutomations, handleABValidation, isTaskCreateScreenAutomation, metadataType, isTaskHistoryScreenAutomation } = useAutomation();
  const [confirmClose, setConfirmClose] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(updateAllAutomations());
    if (metadataType) { 
      handleABValidation();
    }
  }, [selectedAutomation])

  useEffect(() => {
    if (automationSelected && allAutomations.length > 0) {
      const selectedAutomation = allAutomations.find((automationData: IAutomation) => automationData.id == automationSelected);
      dispatch(setSelectedAutomation(selectedAutomation));
    }
  }, [allAutomations.length])

  useEffect(() => {
    const accountId = getAccountId();
    dispatch(resetAutomation(prevAutomations))
    dispatch(initAutomationBuilder({ workflowId: taskTypeId, accountId, stateId: stateId || "", metadataType: payload, otherConfigs,isTaskCreateScreenAutomation:isTaskCreateScreenAutomation || false, isTaskHistoryScreenAutomation:isTaskHistoryScreenAutomation || false  }));
  }, [])


  useEffect(() => {
    // Function to show confirmation dialog
    const handleBeforeUnload = (e:any) => {
      // Cancel the event as stated by the standard.
      e.preventDefault();
      // Chrome requires returnValue to be set.
      e.returnValue = '';
    };

    // Adding the event listener for the beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); // Empty array means this effect runs once on mount

  // useBlocker(
  //   (transition:any) => {
  //     if (transition.action === 'POP') {
  //       if (window.confirm("Do you really want to leave? You may lose unsaved changes.")) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }
  //     return true;
  //   },
  //   []
  // );


  return (
    <>
      <ZModalHeader
        isOpen={openAutomationBuilder}
        handleClose={() => {
          setConfirmClose(true);
        }}
        headerComponent={<HeaderComponent handleClose={(showAlertPopup) => {
          showAlertPopup ? setConfirmClose(true) : onAutomationClose();
        }} onSave={onSaveHandler} />}
        childComponent={<ABEntry />}
      />

      <ConfirmationDialog
        id={'automationBuilder'}
        deletionTitle={CLOSE_AB}
        deletionText={selectedAction?.hasChanged ? CONTEXT_SWITCHING_CONFIRMATION : CLOSE_CONFIRMATION}
        isOpen={confirmClose}
        primaryButtonName="No"
        secondaryButtonName="Yes"
        onPrimaryBtnClick={() => {
          setConfirmClose(false)
        }}
        onSecondaryClick={() => {
          setConfirmClose(false);
          onAutomationClose();
        }}
        onClose={()=>{
          setConfirmClose(false);
        }}
      />
    </>
  )
}

export default AutomationBuilder