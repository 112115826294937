//@ts-nocheck
import Modal from '@mui/material/Modal';
import { styled } from '@mui/system';
import { FormControl, Grid } from "@mui/material";
import React from 'react';
import { useForm } from "react-hook-form";
import { AiOutlineClose } from 'react-icons/ai';
import { Box } from '@mui/material';
import {
    ZButton,
    ZLabel
} from "views/commonComponents/commonComponents.styles";
import ZInput from "views/commonComponents/ZTextInput";
import ErrorMessage from 'views/DataField/ErrorMessage';
import {
    Heading, HeadingWrapper
} from "views/workflows/workflowactions/ModalContent.styles";
import { EditABNameButtonWrapper } from '../automationBuilder.styles';
import { IAutomationName } from '../types/ABType';
import { stopPropagate } from 'utils/CommonUtils';


type IEditAutomationModal = {
    isOpen: boolean;
    onClose: () => void;
    payload: IAutomationName;
    onSave: (automationName: string) => void;
}
function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        borderRadius: '0px',
        border: 'none',
        width: '30%',
    };
}

const PaperBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
}));


const EditAutomationModal = ({ onClose, onSave, isOpen, payload }: IEditAutomationModal) => {


    const preLoadedValue = {
        automationName: payload.automationName
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ defaultValues: preLoadedValue });
    const [modalStyle] = React.useState(getModalStyle);

    const onFormSubmit = (data: any) => {
        onSave(data.automationName)
    }

    return (
        <Modal open={isOpen} onClose={() => {
            reset({automationName:preLoadedValue.automationName})
            onClose();
        }}>

            <form onSubmit={stopPropagate(handleSubmit(onFormSubmit))}>
                <PaperBox style={modalStyle}>
                    <HeadingWrapper>
                        <Heading>Name your Automation</Heading>
                        <AiOutlineClose style={{ marginBottom: '19px' }} size="1.5rem" onClick={() => {
                            reset({automationName:preLoadedValue.automationName})
                            onClose()
                            }} />
                    </HeadingWrapper>
                    <div className='flex flex-col justify-start	px-1 md:px-2 pt-4'>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" fullWidth>
                                <ZLabel>
                                    {" "}
                                    Automation Name
                                </ZLabel>
                                <ZInput
                                    placeholder='Ex - New Automation'
                                    register={register}
                                    name="automationName"
                                    validations={{ required: "Automation Name is required" }}
                                />
                                <ErrorMessage errors={errors} fieldName={"automationName"} />

                            </FormControl>
                        </Grid>
                    </div>
                    <EditABNameButtonWrapper>
                        <ZButton
                            type='submit'
                            variant='contained'
                            style={{ marginRight: '10px' }}
                        >
                            Save
                        </ZButton>
                        <ZButton
                            variant='contained'
                            secondary
                            onClick={() => {
                                reset({automationName:preLoadedValue.automationName})
                                onClose();
                            }}
                        >
                            Cancel
                        </ZButton>
                    </EditABNameButtonWrapper>
                </PaperBox>
            </form>

        </Modal>
    )
}

export default EditAutomationModal