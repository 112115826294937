//@ts-nocheck
import React, { useEffect, useState } from "react";
import {
  CurlModal,
  CurlModalContainer,
  CurlModalFooter,
  CurlFooterLeftWrapper,
  CurlFooterRightWrapper,
  CurlModalHeader,
  HeaderContentWrapper,
  ImportCurlHeaderInfoWrapper,
  ImportCurlHeading,
  ImportCurlSentence,
  ImportIconWrapper,
  CurlDocumentationTxt,
  CurlModalBody,
  Heading,
  Text,
  TextSmall,
} from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents";
import { FiBook, FiFlag } from "react-icons/fi";
import {
  googleLogout,
  useGoogleLogin,
  hasGrantedAllScopesGoogle,
} from "@react-oauth/google";
// import FilterModalClose from "assests/FilterModalClose.svg";
import axios from "axios";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { DriveFile } from "./googleForm.types";
import FormsContainers from "./FormsContainers";
import { FcGoogle } from "react-icons/fc";
import Lottie from "lottie-react";
//@ts-ignore
import ZLoader from "assests/loading.json";
import { Loading } from "react-admin";
import { BuilderService } from "providers/data/services/BuilderService";
import notification from "notifications/notifications";
import { useNavigate } from "react-router-dom";
import { CiImport } from "react-icons/ci";
import { Button } from "@mui/material";
import useWidth from "hooks/useWidth";
import { recordRSEvent } from "utils/CommonUtils";

interface IGoogleFormModal {
  openModal: boolean;
  handleClose: () => void;
}

const GoogleFormModal = ({ openModal, handleClose }: IGoogleFormModal) => {
  const [user, setUser] = useState<any>(null);
  const navigate = useNavigate();
  const [forms, setForms] = useState<DriveFile[]>([]);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const login: any = useGoogleLogin({
    onSuccess: (codeResponse: any) => {
      setUser(codeResponse);
    },
    onError: (error: any) => console.log("Login Failed:", error),
    scope:
      "https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/forms.body.readonly",
  });

  useEffect(() => {
    if (user) {
      setLoading(true);
      axios
        .get(
          `https://www.googleapis.com/drive/v3/files?q=mimeType='application/vnd.google-apps.form'`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setForms(res.data.files);
          setLoading(false);
          setStep(3);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [user]);

  const createWorkflow = async (formId: string, token: string) => {
    try {
      setLoading(true);
      const res: any = await BuilderService.createWorkflowFromForm(
        formId,
        token
      );

      notification(
        "success",
        "Workflow created successfully, id: " + res.data.workflowId
      );
      recordRSEvent("GOOGLE_FORM_TO_WORKFLOW_SUCCESS", {
        workflowId: res.data.workflowId,
        formId: formId,
      });
      setLoading(false);
      // push user to workflow page
      navigate(`/workflows/${res.ata.workflowId}/show`);
    } catch (e) {
      console.log(e?.response?.data?.message);
      recordRSEvent("GOOGLE_FORM_TO_WORKFLOW_FAILED", {
        formId: formId,
        error: e?.response?.data?.message,
      });
      setLoading(false);
    }
  };

  const large = useWidth(768);

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    // setProfile(null);
  };

  return (
    <CurlModal open={openModal} onClose={handleClose}>
      <CurlModalContainer height={"670px"} width={"55%"}>
        <CurlModalHeader>
          <HeaderContentWrapper>
            <ImportIconWrapper>
              <CiImport size={"2rem"} strokeWidth={"1"} />
            </ImportIconWrapper>
            {/* <div><img src={FilterModalClose} className='cursor-pointer' onClick={handleClose} /></div> */}
          </HeaderContentWrapper>

          <ImportCurlHeaderInfoWrapper>
            <ImportCurlHeading>
              Convert Google Form to a ZORP workflow
            </ImportCurlHeading>
            <ImportCurlSentence>
              Switch from simple Google forms to powerful mobile apps in just 2
              mins 🚀
            </ImportCurlSentence>
          </ImportCurlHeaderInfoWrapper>
        </CurlModalHeader>
        <div className="mb-16"></div>
        <CurlModalBody>
          {loading ? (
            <Lottie
              animationData={ZLoader}
              loop={true}
              autoplay={true}
              style={{
                height: "80%",
                display: "flex",
                justifyContent: "center",
              }}
              height={"80%"}
            />
          ) : step === 1 ? (
            <>
              <Heading className="mb-8"> Instructions </Heading>
              <Text className="mb-8">
                {" "}
                ZORP will need access to the Google form you want to convert.
                Just connect your Google account and choose the form.{" "}
              </Text>

              <Text>
                All the datafields will be automatically created and configured.
              </Text>

              <br></br>
              <TextSmall>
                Note: Zorp's use and transfer to any other app of information
                received from Google APIs will adhere to{" "}
                <a
                  href="https://developers.google.com/terms/api-services-user-data-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Google API Services User Data Policy
                </a>
                , including the Limited Use requirements.
              </TextSmall>
              {/* <Text as="li">
                {" "}
                All the data fields will be automatically created and configured
                for you automatically
              </Text>
              <Text as="li">
                This step requires you to have access to the form which you must
                provide
              </Text> */}
              <div className="mb-20"></div>
              {/* <>
                {user ? (
                  <ZButton
                    secondary
                    onClick={logOut}
                    variant="contained"
                    fullWidth
                  >
                    Logout
                  </ZButton>
                ) : (
                  <ZButton
                    secondary
                    onClick={login}
                    variant="contained"
                    fullWidth
                    startIcon={<FcGoogle />}
                  >
                    Login with Google
                  </ZButton>
                )}
              </> */}
            </>
          ) : (
            <FormsContainers
              forms={forms}
              token={user.access_token}
              createWorkflow={createWorkflow}
            />
          )}
        </CurlModalBody>

        {step !== 2 && !loading && (
          <CurlModalFooter>
            <CurlFooterLeftWrapper>
              {large ? (
                <>
                  <div className="pr-2">
                    {" "}
                    <FiBook />{" "}
                  </div>
                  <CurlDocumentationTxt>
                    {" "}
                    Read Documentation
                  </CurlDocumentationTxt>
                </>
              ) : (
                <ZButton secondary variant="contained" onClick={handleClose}>
                  Cancel
                </ZButton>
              )}
            </CurlFooterLeftWrapper>

            <CurlFooterRightWrapper>
              {large ? (
                <>
                  <ZButton secondary variant="contained" onClick={handleClose}>
                    Cancel
                  </ZButton>

                  {step === 1 && (
                    <Button
                      // secondary
                      onClick={login}
                      // variant="contained"
                      className="googleLoginButton"
                      disableRipple
                      // sx={{
                      //   gridTemplateColumns: "15% 85% !important",
                      // }}
                    >
                      <div
                        style={{
                          padding: "8px",
                          width: "33px",
                        }}
                      >
                        {step === 1 && <FcGoogle size={"1.5rem"} />}
                      </div>
                      <span
                        style={{
                          padding: "8px",
                        }}
                      >
                        {step === 1 ? "Sign in with Google" : "Create Workflow"}
                      </span>
                    </Button>
                  )}
                </>
              ) : (
                step === 1 && (
                  <Button
                    // secondary
                    onClick={login}
                    // variant="contained"
                    className="googleLoginButton"
                    disableRipple
                    sx={{
                      gridTemplateColumns: "15% 85% !important",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                      }}
                    >
                      {step === 1 && <FcGoogle size={"1.5rem"} />}
                    </div>
                    <span
                      style={{
                        padding: "8px",
                      }}
                    >
                      {step === 1 ? "Sign in with Google" : "Create Workflow"}
                    </span>
                  </Button>
                )
              )}
            </CurlFooterRightWrapper>
          </CurlModalFooter>
        )}
      </CurlModalContainer>
    </CurlModal>
  );
};

export default GoogleFormModal;
