//@ts-nocheck
import { TextField } from "@mui/material";
import {
  DateTimePicker,
  DesktopDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import datafieldlogo from "assests/datafieldlogo.svg";
import ZListOfTextInputComponent from "components/listOfText/ZListOfTextInputComponent";
import SmartInputScriptButton from "components/script-button/SmartInputScriptButton";
import SelectDatafield from "components/select-datafield/SelectDatafield";
import { NUMBER } from "constants/CommonConstants";
import { REMOVE_SCRIPT, REMOVE_SCRIPT_HEADING } from "constants/CommonMessages";
import { setScriptEditorPayload } from "lowcode/state/scriptOpenState";
import getInputType from "lowcode/util/getInputType";
import { IScriptEditorVariableApiPayload } from "providers/data/services/BffService";
import { useState } from "react";
import _, { get } from "lodash";
import { Control, Controller } from "react-hook-form";
import { AiOutlineWarning } from "react-icons/ai";
import { useDispatch } from "react-redux";
import Select from "react-select";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog.js";
import { DataFieldType, IComponentType } from "views/DataField/DataField.types";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import {
  BsLightningCharge,
  BsThreeDotsVertical as ThreeDots,
} from "react-icons/bs";
import {
  Input,
  InputContainer,
  InputWrapper,
  Button,
  IconText,
  IconWrapper,
} from "views/workflows/workflowactions/ModalContent.styles";
import { setSelectedAction } from "../../../reducers/workflowAction";
import { dataFieldPickerUtil } from "../utils/utils";
import { Menu, MenuItem } from "@szhsin/react-menu";

enum type {
  LITERAL = "literal",
  SCRIPT = "script",
}

interface IOptions {
  value: string;
  labe: string;
  data: any;
}

type TextFieldProps = {
  value?: string;
  avoidOverflow?: boolean;
  placeholder: string;
  type?: type;
  step?: string;
  name: string;
  disabled?: boolean;
  register: any;
  options?: IOptions[];
  config?: any;
  isClearable?: boolean;
  isSmartField?: boolean;
  hideDataField?: boolean;
  editorLocation?: string;
  openJSEditor?: any;
  onChangeFunc?: any;
  minWidth?: string;
  valueDataType?: string;
  dataType: DataFieldType | IComponentType;
  control?: Control<any>;
  getValues?: any;
  apiBodyPayload?: IScriptEditorVariableApiPayload;
  clearErrors?: any;
  errors?: any;
  setError?: any;
  scriptButtonOnClick?: () => void;
  isMulti?: boolean;
  width?: string;
};

const booleanOptions = [
  { value: "true", label: "True" },
  { value: "false", label: "False" },
];

const ModalSmartInputTextField = ({
  step,
  type,
  value,
  register,
  options,
  isClearable = false,
  name,
  avoidOverflow = false,
  placeholder,
  isSmartField = false,
  hideDataField = false,
  editorLocation,
  openJSEditor,
  config,
  onChangeFunc,
  minWidth,
  disabled,
  valueDataType, // valueDataType is the prop, for the datatype which will show in the low code editor and the data-field selector bottom txt
  dataType,
  control,
  getValues,
  setError,
  apiBodyPayload,
  clearErrors,
  errors,
  scriptButtonOnClick,
  width,
  isMulti,
}: TextFieldProps) => {
  const dispatch = useDispatch();
  const { selectedAction } = useWorkFlow();

  const locationCheckRegister = register(name);

  function setTimeToNewDate(timeString:string) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes);
    return date;
  }

  const customStyles = {
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      background: "#fff",
      border: "none !important",
      minHeight: "39px",
      height: "39px",
      borderRadius: "8px",
      // boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    }),
    valueContainer: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      height: "31px",
      padding: "0 6px",
    }),
    input: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      height: "31px",
      // marginRight:
      //   isSmartField && hideDataField !== true && type !== "script"
      //     ? "62px"
      //     : "",
      marginTop: "3px",
    }),
    indicatorContainer: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      marginTop: "6px !important",
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    menu: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      zIndex: "9999 !important",
    }),
  };

  const getComponent = (
    dataType: DataFieldType | IComponentType,
    props: any
  ) => {
    switch (dataType) {
      case DataFieldType.LIST:
        return (
          <>
            <Input
              style={{ height: "32px", borderRadius: "0px" }}
              placeholder="Select from Datafield or Script"
              disabled={true}
            />
          </>
        );
      case DataFieldType.TEXT:
        if (isSmartField) {
          return <SelectDatafield {...props} />;
        } else {
          return (
            <Input
              step={step || "any"}
              defaultValue={type === "script" ? "Smart Script" : value}
              placeholder={placeholder}
              style={{ height: "32px", borderRadius: "0px" }}
              disabled={type === "script" || disabled}
              {...locationCheckRegister}
              isSmartInput={type === "script" ? "true" : "false"}
              // two function are called to handle two case:
              /**
               * 1. If the field is a Script enabled field, then the data type to be stored
               * is object.
               * 2. when the field is normal field, the datatype to store is normal string.
               * 3. Config is used as a checker to determine if the field is script enabled or not.
               */
              onChange={(e) => {
                locationCheckRegister.onChange(e);
                const newSelectedAction = _.set(
                  { ...selectedAction },
                  name,
                  e.target.value
                );
                config
                  ? dispatch(
                      setSelectedAction({
                        ...selectedAction,
                        hasChanged: true,
                        [name]: {
                          type: getInputType(e.target.value),
                          value: e.target.value,
                          dataType: NUMBER,
                        },
                      })
                    )
                  : dispatch(
                      setSelectedAction({
                        ...newSelectedAction,
                        hasChanged: true,
                      })
                    );
              }}
              data-testid={name}
            />
          );
        }

      case DataFieldType.NUMBER:
        return <SelectDatafield {...props} />;
      case DataFieldType.BOOLEAN:
        return (
          <div style={{ width: "100%" }}>
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange: fieldOnChange, value, name } }) => (
                <Select
                  menuPortalTarget={document.body}
                  isClearable
                  styles={customStyles}
                  value={booleanOptions.find((c: any) => c.value === value)}
                  isSearchable
                  menuPosition={"fixed"}
                  menuPlacement={"bottom"}
                  onChange={(selectedOption: any) => {
                    fieldOnChange(selectedOption?.value);
                    config
                      ? dispatch(
                          setSelectedAction({
                            ...selectedAction,
                            hasChanged: true,
                            [name]: {
                      type: getInputType(value ?? ""),
                      value: selectedOption?.value,
                      dataType: dataType,
                    },
                          })
                        )
                      : dispatch(
                      setSelectedAction({
                        ...selectedAction,
                        hasChanged: true,
                      [name]: selectedOption?.value,
                    })
                        );
                  }}
                  options={booleanOptions}
                />
              )}
            />
          </div>
        );
      case IComponentType.DROPDOWN:
        return (
          <div style={{ width: "100%" }}>
            <Controller
              // reason we are passing null after || is, when the value set to empty or null then clear the value in screen
              defaultValue={
                options?.find((c: any) => c.value === value) || null
              }
              control={control}
              name={name}
              render={({ field: { onChange: fieldOnChange } }) => (
                <Select
                  menuPortalTarget={document.body}
                  styles={customStyles}
                  isClearable={true}
                  // reason we are passing null after || is, when the value set to empty or null then clear the value in screen
                  value={
                    options?.filter((option: any) =>
                      value?.includes(option.value)
                    ) || []
                  }
                  isSearchable
                  menuPosition={"fixed"}
                  menuPlacement={"bottom"}
                  onChange={(selectedOption: any) => {
                    console.log("selectedOption", selectedOption);
                    fieldOnChange(selectedOption?.value);
                    onChangeFunc(selectedOption);
                  }}
                  options={options}
                  isMulti={isMulti}
                />
              )}
            />
          </div>
        );

      case DataFieldType.DATETIME:
        return (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    value={get(selectedAction, `${name}.value`, null)}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "37px",
                        width: "100%",
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                          outline: "none !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "none !important",
                          outline: "none !important",
                        },
                      },
                      "& .MuiInputAdornment-root": {
                        "& .MuiIconButton-root": {
                          color: "darkblue",
                          // marginRight: "47px !important",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                        border: "none !important",
                        outline: "none !important",
                      },

                      width: "100%",
                    }}
                    onChange={(e) => {
                      onChange(value);
                      const tempSelectedAction = _.cloneDeep(selectedAction);
                      const newSelectedAction = _.set(
                        tempSelectedAction,
                        name,
                        {
                          type: getInputType(e ?? ""),
                          value: e,
                          dataType: dataType,
                        }
                      );
                      config
                        ? dispatch(
                            setSelectedAction({
                              ...newSelectedAction,
                              hasChanged: true,
                            })
                          )
                        : dispatch(
                            setSelectedAction({
                              ...newSelectedAction,
                              hasChanged: true,
                            })
                          );
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </>
        );
      case DataFieldType.ONLY_DATE:
        return (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => (
                  <DesktopDatePicker
                    // sx={{
                    //   '&. MuiFormControl-root':{
                    //     '& .MuiInputBase-root' : {
                    //       height: "37px !important",
                    //     },
                    //   },

                    //   // style root
                    //   // height: "37px",
                    //   borderColor: "#D0D5DD",
                    //   borderRadius: "8px",
                    //   boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    //
                    //   backgroundColor: "white",
                    // }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "37px",
                        width: "100%",
                        
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                          outline: "none !important",

                        },
                      },
                      "& .MuiInputAdornment-root": {
                        "& .MuiIconButton-root": {
                          color: "darkblue",
                          // marginRight: "47px !important",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                        border: "none !important",
                        outline: "none !important",
                      },

                      width: "100%",
                    }}
                    inputFormat="MM/dd/yyyy"
                    isClearable
                    value={get(selectedAction, `${name}.value`, null)}
                    onChange={(e) => {
                      onChange(value);
                      const tempSelectedAction = _.cloneDeep(selectedAction);
                      const newSelectedAction = _.set(
                        tempSelectedAction,
                        name,
                        {
                          type: getInputType(e ?? ""),
                          value: e,
                          dataType: dataType,
                        }
                      );
                      config
                        ? dispatch(
                            setSelectedAction({
                              ...newSelectedAction,
                              hasChanged: true,
                            })
                          )
                        : dispatch(
                            setSelectedAction({
                              ...newSelectedAction,
                              hasChanged: true,
                            })
                          );
                    }}
                    slotProps={{ field: { clearable: true } }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </>
        );
      case DataFieldType.TIME:
        return (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => (
                  <TimePicker
                    value={
                      get(selectedAction, `${name}.value`, null) ?
                      setTimeToNewDate(get(selectedAction, `${name}.value`, null))
                      :
                      null
                      
                    }
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "37px",
                        width: "100%",
                        
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                          outline: "none !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3C69E7",
                          border: "none !important",
                          outline: "none !important",
                        },
                      },
                      "& .MuiInputAdornment-root": {
                        "& .MuiIconButton-root": {
                          color: "darkblue",
                          // marginRight: "47px !important",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },

                      width: "100%",
                    }}
                    onChange={(e) => {
                      onChange(value);
                      const selectedDate = new Date(e);
                      // Get the time
                      const hours = String(selectedDate.getHours()).padStart(2, "0");
                      const minutes = String(selectedDate.getMinutes()).padStart(2, "0");
                      // const seconds = selectedDate.getSeconds();
                      const time = `${hours}:${minutes}`;
                      const tempSelectedAction = _.cloneDeep(selectedAction);
                      const newSelectedAction = _.set(
                        tempSelectedAction,
                        name,
                        {
                          type: getInputType(e ?? ""),
                          value: time,
                          dataType: dataType,
                        }
                      );
                      config
                        ? dispatch(
                            setSelectedAction({
                              ...newSelectedAction,
                              hasChanged: true,
                            })
                          )
                        : dispatch(
                            setSelectedAction({
                              ...newSelectedAction,
                              hasChanged: true,
                            })
                          );
                    }}
                    slotProps={{ field: { clearable: true } }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </>
        );
      case DataFieldType.FILE:
      case DataFieldType.IMAGE:
      case DataFieldType.LIST_OF_TEXT:
        return (
          <div style={{ width: "100%" }}>
            <ZListOfTextInputComponent
              validation={{}}
              name={name}
              getValues={getValues}
              isRequired={false}
              setError={setError}
              clearErrors={clearErrors}
              //@ts-ignore
              control={control}
              errors={errors}
            />
          </div>
        );

      default:
        return <SelectDatafield {...props} />;
    }
  };

  const selectedActionValue = get(selectedAction, name, null);

  const deleteFunction = () => {
    const tempState = { ...selectedAction, hasChanged: true };

    setTimeout(() => {
      dispatch(
        setSelectedAction({
          ...tempState,
          hasChanged: true,
          [name]: {
            type: "literal",
            value: "",
            dataType: NUMBER,
          },
        })
      );
    }, 1);
    dispatch(setSelectedAction(null));
  };

  const [showDelete, setShowDelete] = useState(false);

  return (
    <div style={{ display: "flex", alignItems: "center", gap:"8px", width:"calc(100% - 0px)", overflow:"hidden" }}>
      {/* @ts-ignore */}
      <InputContainer
        hasConfig={config ? true : false}
        isSmartField={isSmartField ? true : false}
        avoidOverflow={avoidOverflow}
        style={{
          position: "relative",
          width: width || "100%",
          gap: "8px",
          height: "40px",
          overflow: "hidden",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          border: "1px solid var(--Gray-300, #D0D5DD)",
          borderRadius: "8px",
          paddingRight: type === "script" ? "0px" : "6px"
        }}
      >
        {isSmartField ? (
          <>
            {type === "script" ? (
              <InputWrapper minWidth={minWidth} avoidOverflow={avoidOverflow}>
                <SelectDatafield
                  handleChange={onChangeFunc}
                  type={"script"}
                  value={value || ""}
                  placeholder={placeholder}
                />
              </InputWrapper>
            ) : value &&
              typeof value === "string" &&
              value?.includes("${") &&
              value?.endsWith("}") &&
              value?.replace("${", "").replace("}", "").length > 0 ? (
              <InputWrapper minWidth={minWidth} avoidOverflow={avoidOverflow}>
                <SelectDatafield
                  handleChange={onChangeFunc}
                  type={dataType == "BOOLEAN" ? "dataField" : "literal"}
                  value={value}
                  placeholder={placeholder}
                />
              </InputWrapper>
            ) : (
              <>
                <InputWrapper minWidth={minWidth} avoidOverflow={avoidOverflow}>
                  {getComponent(dataType, {
                    handleChange: onChangeFunc,
                    type,
                    value,
                    placeholder,
                  })}
                </InputWrapper>
              </>
            )}
            {hideDataField !== true && type !== "script" && (
              // <img
              //
              //   src={datafieldlogo}
              //   alt="fnIcon"
              //   onClick={() =>{
              //     dispatch(setScriptEditorPayload(apiBodyPayload))
              //     dataFieldPickerUtil({
              //       dispatch,
              //       name,
              //       dataType: valueDataType ?? dataType,
              //     })
              //   }

              //   }
              // />
              <div
                onClick={() => {
                  dispatch(setScriptEditorPayload(apiBodyPayload));
                  dataFieldPickerUtil({
                    dispatch,
                    name,
                    dataType: valueDataType ?? dataType,
                  });
                }}
                className="updateDataFieldImg"
                style={{
                  top:
                    dataType === IComponentType.DROPDOWN
                      ? "7px"
                      : dataType === DataFieldType.DATETIME ||
                        dataType === DataFieldType.ONLY_DATE ||
                        dataType === DataFieldType.TIME
                      ? "6px"
                      : "7px",
                }}
              >
                {" "}
                Data{" "}
              </div>
            )}
          </>
        ) : (
          <InputWrapper minWidth={minWidth} avoidOverflow={avoidOverflow}>
            <Input
              step={step || "any"}
              defaultValue={type === "script" ? "Smart Script" : value}
              placeholder={placeholder}
              // style={{ height: "32px", borderRadius: "2px" }}
              disabled={type === "script" || disabled}
              {...locationCheckRegister}
              isSmartInput={type === "script" ? "true" : "false"}
              // two function are called to handle two case:
              /**
               * 1. If the field is a Script enabled field, then the data type to be stored
               * is object.
               * 2. when the field is normal field, the datatype to store is normal string.
               * 3. Config is used as a checker to determine if the field is script enabled or not.
               */
              onChange={(e) => {
                locationCheckRegister.onChange(e);
                config
                  ? dispatch(
                      setSelectedAction({
                        ...selectedAction,
                        hasChanged: true,
                        [name]: {
                          type: getInputType(e.target.value),
                          value: e.target.value,
                          dataType: NUMBER,
                        },
                      })
                    )
                  : dispatch(
                      setSelectedAction({
                        ...selectedAction,
                        hasChanged: true,
                        [name]: e.target.value,
                      })
                    );
              }}
              data-testid={name}
            />
          </InputWrapper>
        )}

        {/* {config && (
          <SmartInputScriptButton
            handleClick={(e) => {
              dispatch(setScriptEditorPayload(apiBodyPayload));
              openJSEditor({
                config,
                editorLocation,
              });
              scriptButtonOnClick?.();
            }}
          />
        )} */}

        <DeletionConfirmationDialog
          id={"removeScript"}
          deletionTitle={REMOVE_SCRIPT_HEADING}
          deletionText={REMOVE_SCRIPT}
          isOpen={showDelete}
          onConfirmDelete={(e: any) => deleteFunction()}
          onClose={() => setShowDelete(false)}
        />
      </InputContainer>
      <Menu
        menuClassName="my-menu"
        menuButton={
          <div>
            <ThreeDots color="#344054" size={"16px"} />
          </div>
        }
        transition
        gap={12}
        align="start"
        menuStyle={{ width: "max-content" }}
      >
        <MenuItem
          onClick={(e) => {
            dispatch(setScriptEditorPayload(apiBodyPayload));
            openJSEditor({
              config,
              editorLocation,
            });
            scriptButtonOnClick?.();
          }}
          className="menuItem-s"
        >
          <IconWrapper>
            <BsLightningCharge color="#344054" size={"1.5rem"} stroke="2" />
          </IconWrapper>
          <IconText> Script </IconText>
        </MenuItem>
      </Menu>
      {get(errors, name)?.message?.length > 0 && (
        <div className="font-semibold text-xs text-red-500 mt-1">
          <AiOutlineWarning
            size={"1rem"}
            color="red"
            style={{ display: "inline", marginRight: "4px" }}
          />{" "}
          {get(errors, name)?.message}
        </div>
      )}
    </div>
  );
};

export default ModalSmartInputTextField;