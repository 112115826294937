//@ts-nocheck
import { TextareaAutosize, TextField } from "@mui/material";
import React, { FunctionComponent, useState, useEffect, memo } from "react";
import { UseFormRegister, FieldErrors } from "react-hook-form";
import { ComponentType } from "../ComponentTypeEnum";
import { IZComponentPropType } from "render-engine/models/ITaskTypeWebBodyRenderModel";
import { get } from "lodash";

import { IZInputInterface } from "./IZInputIInterface";
import { AiOutlineWarning } from "react-icons/ai";
import { Padding } from "@mui/icons-material";
import {
  ColSpan4,
  RedText,
  TaskFormLabelForCreateAndEdit,
} from "render-engine/render.styles";
import {
  setInEditState,
  setTaskData,
} from "render-engine/reducers/show/TaskShowReducer";
import { useDispatch } from "react-redux";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { styled } from "@mui/system";
import useOutsideClick from "views/workflows/hooks/useOutsideClick";

const StyledInput = styled(TextareaAutosize)({
  "&.Mui-focused fieldset": {
    borderColor: "#007aff",
  },
  "& input": {
    padding: "3px 8px !important",
  },
  width: "100%",
});

const StyledNumberInput = styled(TextField)({
  "&.Mui-focused fieldset": {
    borderColor: "#007aff",
  },
  "& input": {
    padding: "3px 8px !important",
  },
  width: "100%",
});

const ZInputComp = ({
  config,
  onChange,
  register,
  errors,
  setValue,
  getValues,
}: any) => {
  // Construct validation

  const { convertDataTypeToString } = useTaskShow();

  const val = convertDataTypeToString(`${config.key}` || "");

  const validation: any = {};
  let validate: any = () => true;

  if (config.isRequired) validation.required = `required`;

  if (config.maxChars) validation.maxLength = config.maxChars;

  if (config.validate) {
    validate = config.validate;
  }

  const dispatch = useDispatch();

  const textFieldRef = React.useRef(null);

  useOutsideClick(textFieldRef, () => {
    handleBlur();
  });

  // if (config.shape.toUpperCase() === 'NUMBER')
  //     validation.pattern = /^\d+$/;

  useEffect(() => {
    if (config.value) {
      const valueToBeSetToReactHookForm = val
      if (config.shape.toUpperCase() === "NUMBER") {
        // set value either val or config.value (as it can have default value)

        setValue(config.key, parseFloat(valueToBeSetToReactHookForm as string));
      } else {
        setValue(config.key, valueToBeSetToReactHookForm);
      }
    }
  }, []);

  let isVisible = config?.isVisible ?? true;

  const registerObject = {
    valueAsNumber: config.shape.toUpperCase() === "NUMBER" ? true : false,
    ...validation,
    validate: validate,
    onChange: (e: any) => {
      setValue(config.key, e.target.value);
    },
  };

  const handleBlur = () => {
    console.log("handleBlur", getValues());
    const curValue = getValues();
    dispatch(setInEditState({ nodeId: config.key, inEditState: false }));
    // KEY CAN BE location.address or location.latitude or location.longitude
    // GET THE MAIN KEY AND SET THE VALUE
    const mainKey = config.key.split(".")[0];
    dispatch(setTaskData({ nodeId: mainKey, data: curValue[`${mainKey}`] }));
    // dispatch(
    //   setTaskData({ nodeId: config.key, data: curValue[`${config.key}`] })
    // )
  };

  const { ref, ...rest } = register(`${config.key}`, registerObject);

  if (isVisible)
    return (
      <>
        <ColSpan4>
          {config?.label?.isVisible && (
            <TaskFormLabelForCreateAndEdit
              className="col-span-1 font-medium"
              fontSize={config?.label?.fontSize}
              color={config?.label?.color}
            >
              {config?.label?.val}
              {config?.isRequired && <RedText>*</RedText>}
            </TaskFormLabelForCreateAndEdit>
          )}

          {config.shape.toLowerCase() === "number" ? (
            <StyledNumberInput
              // autoFocus
              className={`w-full`}
              inputRef={(e) => {
                ref(e);
                // textFieldRef.current = e
                if (e) {
                  e.onwheel = (event) => event.preventDefault(); // Disable wheel event
                }
              }}
              {...rest}
              // multiline={config.shape.toLowerCase() !== "number"}
              type={config.shape.toLowerCase()}
              defaultValue={val || config.value}
              maxRows={config.maxLines}
              {...register(`${config.key}`, registerObject)}
              id={config?.key}
              variant="outlined"
              onBlur={handleBlur}
              inputProps={{
                step: "any",
              }}
              // style={{
              //   width: "100%",
              //   border: "1px solid #c2c9d1",
              //   borderRadius: "4px",
              //   padding: "4px 6px",
              //   height: "27px",
              //   fontSize: "12px",
              // }}
            />
          ) : (
            <StyledInput
              className={`w-full`}
              // autoFocus
              {...rest}
              // multiline={config.shape.toLowerCase() !== "number"}
              minRows={1}
              defaultValue={val || config.value}
              maxRows={config.maxLines}
              ref={textFieldRef}
              id={config?.key}
      
              style={{
                width: "100%",
                border: "1px solid #c2c9d1 !important",
                borderRadius: "4px",
                padding: "4px 6px",
                height: "27px",
                fontSize: "12px",
                
              }}
              {...register(`${config.key}`, registerObject)}
              onBlur={handleBlur}
            />
          )}

          {errors[config.key]?.message?.length > 0 && (
            <div className="font-semibold text-xs text-red-500 mt-1">
              <AiOutlineWarning
                size={"1rem"}
                color="red"
                style={{ display: "inline", marginRight: "4px" }}
              />{" "}
              {errors[config.key]?.message}
            </div>
          )}
          {config?.key?.includes(".") ? (
            get(errors, config.key)?.message && (
              <div className="font-semibold text-xs text-red-500 mt-q">
                <AiOutlineWarning
                  size={"1rem"}
                  color="red"
                  style={{ display: "inline", marginRight: "4px" }}
                />{" "}
                {get(errors, config.key)?.message}
              </div>
            )
          ) : (
            <p></p>
          )}
        </ColSpan4>
      </>
    );
  else return <div></div>;
};

export const ZInputV2 = memo(ZInputComp);
