import { IScriptEditorInclusion, IScriptEditorVariableApiPayload } from "providers/data/services/BffService";
import { useStore } from 'react-redux';
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { ABMetadataTypeEnum, IActionBlock, IAutomationBlocksType, IAutomation } from "views/automationBuilder/types/ABType";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";

interface IData {
  name:string,
  id:string,
  type:string,
  allAutomations:IAutomation[]
}

interface IElement {
  id:string,
  name:string,
  type:string,
  data:IData
  target:string
}

const usePayload = () => {
  const { selectedTransition,selectedAction,selectedDecisionNode,selectedSystemTransition,allScreenElements,selectedNode,dataPicker } = useWorkFlow();
  const { selectedActionBlock, selectedAutomation, metadataType, isEntityAutomation, isCronAutomation, isTeamAutomation, isUserAutomation, isMonitorAutomation, isWebHookAutomation } = useAutomation();

  // If selected in decision Node, get all the source Transition Id and get actionBlocks of all 
  const allActionBlocks: IActionBlock[] = [];
  const store = useStore();

  const getResourceType = () => {
    if(isEntityAutomation) {
      return "TABLE"
    } else {
      return "WORKFLOW"
    }
  }

  const selectedBlockType = selectedAction?.hasOwnProperty('actionType') ? IAutomationBlocksType.ACTION : selectedAction?.hasOwnProperty('conditionType') ? IAutomationBlocksType.CONDITION : IAutomationBlocksType.GUARD;
  const selectedActionBlockId = selectedActionBlock?.actionBlockId;

  const isSelectedActionBlock = (selectedBlockType == IAutomationBlocksType.ACTION);
  const isSelectedConditionBlock =  (selectedBlockType == IAutomationBlocksType.CONDITION);
  const isSelectedGuardBlock =  (selectedBlockType == IAutomationBlocksType.GUARD);

  const selectedActionId = isSelectedActionBlock ? selectedAction?.actionId : isSelectedGuardBlock ? (selectedAction?.tempId || selectedAction?.guardId) : isSelectedConditionBlock ? selectedAction?.conditionId : undefined; // this Id may be guard id or action id or condition id;
  
  // If selected in decision Node, get all the source Transition Id and get actionBlocks of all 

  const checkMetaDataTypeIsDecisionNode = (metadataType == ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_IF_SWITCH || metadataType == ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_ELSE_SWITCH);
  const checkMetaDataTypeIsSmartComponent = (metadataType == ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT || metadataType == ABMetadataTypeEnum.WORKFLOW_SCREEN_ONLOAD);
  const isDataFieldPickerOpen = dataPicker?.isOpened || false;
  if (checkMetaDataTypeIsDecisionNode) {
    allScreenElements.edges.forEach((element:IElement) => {
      if (element?.target == selectedDecisionNode?.nodeId) {
        if (element?.data?.hasOwnProperty("allAutomations") && element?.data.allAutomations?.[0]) {
          allActionBlocks.push(element?.data.allAutomations?.[0]?.actionBlocks)
        }
      }
    })
  }

  const appAPIResponsePayload: IScriptEditorInclusion =
  {
    type: "API_RESPONSE",
    subType: metadataType,
    config: {
      transitionId: selectedTransition?.data?.id,
      selectedSystemTransition: selectedSystemTransition,
      decisionNodeId: selectedDecisionNode?.nodeId,
      decisionSwitchId: selectedDecisionNode?.id,
      actionData: {
        actionType: selectedBlockType,
        selectedActionBlockId,
        selectedActionId: selectedActionId, // this Id may be guard id or action id or condition id
        selectedActionType: selectedAction?.actionType || selectedAction?.guardType || selectedAction?.conditionType,
        actionsList: { actionBlock: (selectedAction?.actionId || selectedAction?.conditionId) ? selectedAutomation?.actionBlocks : [], guards: selectedAction?.guardId ? selectedAutomation?.guards : [] }
      }
    }
  }

  const appComponentPayload: IScriptEditorInclusion = {
    type: "appComponents",
    config: {
      stateId: selectedNode?.id || "",
        ...(store.getState()?.builder?.flutterTaskCardConfig.isTaskHistoryScreen) && {isHistory: true},
        ...(store.getState()?.builder?.flutterTaskCardConfig.isTaskCreate) && {isTaskCreate: true},
    }
  }

  const exclusions = [];

  if (isTeamAutomation || isUserAutomation) {
    exclusions.push("task", "RECORD");
  }  
  
  if (!isEntityAutomation) {
    exclusions.push("RECORD");
  } else {
    exclusions.push("task");
  }

  if (isCronAutomation || isMonitorAutomation || isWebHookAutomation) {
    exclusions.push("task", "RECORD", "loggedInUser")
  }

  const actionConditionPayload: IScriptEditorVariableApiPayload = {
    payload: {
      inclusions: [
        // api Response will add to inclusion only while calling low code editor from AB Builder
        ...((metadataType && !isDataFieldPickerOpen) ? [appAPIResponsePayload] : []),
        // Config is to Show AppComponents in Right Side, while opening low code editor from App Builder -> screen 
        ...((checkMetaDataTypeIsSmartComponent) ? [appComponentPayload] : []),
        ...((isTeamAutomation) ? ["TEAM"] : []),
        ...((isUserAutomation) ? ["USER"] : []),
        ...((isWebHookAutomation) ? ["requestBody"] : []),
      ],
      exclusions: exclusions
    }
  }

  // Pushing data to inclusion - API Response Object
  if(metadataType && !isDataFieldPickerOpen){
    Object.assign(actionConditionPayload?.payload.inclusions?.[0]?.config?.actionData, { allActionBlocks })
  }

  return {
    actionConditionPayload
  }
}

export default usePayload