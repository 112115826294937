//@ts-nocheck
import backIcon from 'assests/Left.svg';
import saveIcon from 'assests/save.svg';
import { AUTOMATION_SAVE, AUTOMATION_SAVE_CONFIRMATION } from 'constants/CommonMessages';
import { useEffect, useState } from 'react';
import { RudderStackAutomationBuilderContext, RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';
import notification from "notifications/notifications";
import { BffService } from 'providers/data/services/BffService';
import { CronService, ICronExecutionType } from 'providers/data/services/CronService';
import { MonitorService } from 'providers/data/services/MonitorService';
import { BsPlayFill } from "react-icons/bs";
import { useDispatch, useSelector, useStore } from 'react-redux';
import { recordRSEvent } from 'utils/CommonUtils';
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import ShowWorkflowErrorButton from 'views/workflows/WorkflowErrors/ShowWorkflowErrorButton';
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import { VerticalAlignWrapper } from '../automationBuilder.styles';
import '../automationbuilder.css';
import useAutomation from '../hooks/useAutomation';
import { closeAutomation } from '../reducer/automationReducer';
import { ABMetadataTypeEnum, OnSaveHandlerFunction } from '../types/ABType';
import ConfirmationDialog from './ConfirmationDialog';


export type IHeaderComponent = {
  handleClose: (showAlertPopup:boolean) => void;
  onSave: OnSaveHandlerFunction
};

export const HeaderComponent = ({ handleClose, onSave }: IHeaderComponent) => {
  const store = useStore();
  const dispatch = useDispatch();

  const { selectedDecisionNode, selectedTransition, selectedNode, selectedAction, accountId } = useWorkFlow();
  const { errors, metadataType, isTaskCreateScreenAutomation, isTaskHistoryScreenAutomation, allAutomations, selectedAutomation, selectedCron, otherConfigs, selectedMonitorId } = useAutomation();
  const { mockData } = useSelector((state: any) => state.scriptEditor);
  const [executeCronLoading, setExecuteCronLoading] = useState<boolean>(false);  
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [automationHeader, setAutomationHeader] = useState<string>();
  const [executeAutomationConfirmation, setExecuteAutomationConfirmation] = useState<boolean>();
  const showRunAutomationBtn = otherConfigs?.showRunAutomation;
  const getAutomationHeaderTitle = ():string => {
    // For Decision Node, Get Decision Node Name
    if (metadataType === ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_ELSE_SWITCH || metadataType === ABMetadataTypeEnum.WORKFLOW_DECISION_NODE_TRANSITION_IF_SWITCH) {
      return selectedDecisionNode?.data?.name + ' - Automation';
    } else if (metadataType === ABMetadataTypeEnum.WORKFLOW_SCREEN_ONLOAD) {
      if (selectedNode?.data?.name) {
        return selectedNode?.data?.name + ' - Onload Automation';
      } else if (isTaskCreateScreenAutomation) {
        return 'Task Create Screen - Onload Automation';
      } else if (isTaskHistoryScreenAutomation) {
        return 'Task History Screen - Onload Automation';
      } else {
        return 'Onload Automation';
      }
    } else if (metadataType === ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT) {
      if (selectedNode?.data?.name) {
        return selectedNode?.data?.name + ' - Screen Component Automations';
      } else if (isTaskCreateScreenAutomation) {
        return 'Task Create Screen - Screen Component Automations';
      } else if (isTaskHistoryScreenAutomation) {
        return 'Task History Screen - Screen Component Automations';
      } else {
        return 'Screen Component Automations';
      }
    } else if (metadataType === ABMetadataTypeEnum.WORKFLOW_SCREEN_TRANSITION) {
      return selectedTransition?.data?.event + ' - Screen Transition Automation';
    }
    else if (metadataType === ABMetadataTypeEnum.WORKFLOW_SYSTEM_TRANSITION) {
      return 'Workflow Events';
    } else if (metadataType === ABMetadataTypeEnum.CRON) {
      return 'Scheduled Automation';
    } else if (metadataType === ABMetadataTypeEnum.MONITOR) {
      return 'Alert Auto-Resolution Automation';
    } else if (metadataType === ABMetadataTypeEnum.TEAM) {
      return 'Team Automation';
    } else if (metadataType === ABMetadataTypeEnum.USER) {
      return 'User Automation';
    } else if (metadataType === ABMetadataTypeEnum.WEBHOOK) {
      return 'Webhook Automation';
    } else {
      return 'Automation';
    }
  }

  const executeAutomation = async () => {
    setExecuteCronLoading(true);

    let executeCronResponse:any; 
    
    if (metadataType == ABMetadataTypeEnum.CRON) {
      executeCronResponse = await CronService.executeCron(
        {
          cronExecutionType: ICronExecutionType.NO_CRON,
          cronPayload: {...selectedAutomation, cronId:selectedCron?.cronId, name:selectedCron?.name}
        }
        );
      } else if (metadataType == ABMetadataTypeEnum.MONITOR) {
        // TODO: replace this with monitor service api
        executeCronResponse = await MonitorService.executeMonitorAutomation(
          {
            monitorId: selectedMonitorId,
            monitorAutomationPayload: {...selectedAutomation, monitorId:selectedMonitorId, name:'no-name'},
            monitorName:'no-name'
          }
          );
      } else if (metadataType == ABMetadataTypeEnum.TEAM || metadataType == ABMetadataTypeEnum.USER) {
        const  testTeamAutomationPayload = {
          automationId: selectedAutomation?.id,
          automationName: selectedAutomation?.name,
          entityType: metadataType,
          entityId: "no-id",
          contextVars: {team: mockData?.team},
          automationPayload: {...selectedAutomation, name:selectedAutomation?.name}
      }
      
        executeCronResponse = await BffService.testTeamUserAutomation(testTeamAutomationPayload);
      }

      setExecuteCronLoading(false);
      if (executeCronResponse?.code === '200') {
        notification('success', 'Automation executed Successfully');
      } else {
        notification('error', executeCronResponse?.data?.message || 'Automation execution failed');
      }
  }

  useEffect(()=>{
    if (metadataType) {
      setAutomationHeader(getAutomationHeaderTitle());
    }
  },[metadataType, isTaskCreateScreenAutomation, isTaskHistoryScreenAutomation, selectedNode?.data?.name, selectedDecisionNode?.data?.name])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', width:"100%" }}>
        <div>
          <ZButton
            secondary
            variant="contained"
            onClick={() => {
              handleClose(true);
            }}
            type="button"
            className="mr-2 pl-10">
            <img style={{ paddingRight: '10px', margin: '0px' }} src={backIcon} alt='fnIcon' />
            Back
          </ZButton>
        </div>
        <VerticalAlignWrapper applyMargin={false}>
          <p style={{fontSize:'14px'}}>
          {automationHeader}
          </p>
        </VerticalAlignWrapper>
        <div style={{display:"flex"}}>
          {
            errors.length > 0 && <ShowWorkflowErrorButton allWorkflowErrors={errors} />
          }
          {
          (selectedAutomation && showRunAutomationBtn) &&
            <ZButton
            className="pl-10 ml-2"
            onClick={async () => {
              setExecuteAutomationConfirmation(true)
            }}
            disabled={errors.length > 0 || allAutomations?.length == 0 || executeCronLoading}
            variant="contained">
            <BsPlayFill size={'20px'}/>
            Test Automation
          </ZButton>
            }

          <ZButton
            className="pl-10 ml-2"
            onClick={() => {
              if(selectedAction?.hasChanged){
                setOpenModal(true);
              }else{
                onSave({ allAutomations: store.getState().automation.allAutomations, dispatch,store,callback: () => {
                  dispatch(closeAutomation())
                  handleClose(false);
                } }) 
              }
            }}
            disabled={errors.length > 0}
            variant="contained">
            <img style={{ paddingRight: '10px', margin: '0px' }} src={saveIcon} alt='fnIcon'/>
            Save Automation
          </ZButton>
        </div>   
             </div>


             <ConfirmationDialog
        id={'automationBuilder'}
        deletionTitle={'Execute Automation'}
        deletionText={`Do you want to Execute ${selectedAutomation?.name} Automation ?`}
        isOpen={!!executeAutomationConfirmation}
        primaryButtonName="Yes"
        variant="primary"
        secondaryButtonName="No"
        onPrimaryBtnClick={async () => {
          recordRSEvent(RudderStackAutomationBuilderEvents.testAutomationBtnClick, {
            context: RudderStackAutomationBuilderContext.automationBuilder
          });
          setExecuteAutomationConfirmation(false);
          await executeAutomation();
        }}
        onSecondaryClick={() => {
          setExecuteAutomationConfirmation(false);
        }}
        onClose={() => {
          setExecuteAutomationConfirmation(false);
        }}
      />

      <ConfirmationDialog
        id={"context_switching"}
        deletionTitle={AUTOMATION_SAVE}
        deletionText={AUTOMATION_SAVE_CONFIRMATION}
        isOpen={openModal}
        primaryButtonName="No"
        secondaryButtonName="Yes"
        onPrimaryBtnClick={() => {
          setOpenModal(false);
        }}
        onSecondaryClick={() => {
          onSave({ allAutomations: store.getState().automation.allAutomations, dispatch })
          dispatch(closeAutomation())
          handleClose(false);
        }}
        onClose={()=>{
          setOpenModal(false);
        }}
      />
    </>
  )
}