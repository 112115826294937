import moment from "moment-timezone";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ZButton
} from "views/commonComponents/commonComponents.styles";
import { Controller, useForm } from "react-hook-form";
import ZInput from "views/commonComponents/ZTextInput";
import { AccountService } from "providers/data/services/AccountService";
import Select from "react-select";
import jwtDecode from "jwt-decode";
import WhiteLabelling from "../whitelabelling/WhiteLabelling";
import { SubHeading } from "../settings.style";
import notification from "notifications/notifications";
import ColorList, { initColor } from "./ThemeColorSelector";
import { getConfig as Config } from '../../../config/envConfig/Config';
import { Button } from "@mui/material";
export const Wrapper = styled.div`
  padding: 20px 0px;
`;

export const Label = styled.p`
  font-weight: ${(props: { large?: boolean; bold?: boolean }) =>
    props?.bold ? "500" : "400"};
  font-size: ${(props: { large?: boolean }) =>
    props?.large ? "14px" : "14px"};
  margin-bottom: 4px;
  color: #475467;
`;

const ResponsiveWrapper = styled.div`
  width: 70%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Info = styled.div`
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    #c2c9d1;
  padding: 20px;
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-style: 14px;
`;

const Divider = styled.hr`
  margin-top: 20px;
  border-width: 0.1px;
  margin-left: 0px !important;
  border: none; /* Remove default border */
  background-color: #EAECF0 !important; /* Set the color of the line */
  height: 1px;
`;

export const Block = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: 30% 70%;
  @media (max-width: 600px) {
    grid-template-columns: 100%;
  }
`;

const Wrapper1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {};

export interface ITimezone {
  value: string;
  label: string;
}

const GeneralSettingEdit = (props: Props) => {
  const [allTimezones, setAllTimezones] = useState<ITimezone[]>([]);

  const [logo, setLogo] = useState<any>(null);

  const [primaryThemeColor, setPrimaryThemeColor] = useState<any>(null);

  const [slackUrl, setSlackUrl] = useState<string | null>(null);

  const [appBarPrimaryColorActive, setAppBarPrimaryColorActive] = useState<any>(null);

  const [slackVerificationToken, setSlackVerificationToken] = useState<any>(null);

  const [ticketTableId, setTicketTableId] = useState<any>(null);

  const { register, control, handleSubmit, setValue } = useForm();

  const currentUser: any = jwtDecode(localStorage.getItem("at") || "");

  const getAccountDetails = async () => {
    //@ts-ignore
    const data: any = await AccountService.getAccount(currentUser.accountId);
    setValue("accountName", data?.data?.accountName || "");
    setValue("timezone", data?.data?.accountConfig?.timezone || "Asia/Kolkata");
    setValue("accountId", data?.data?.accountConfig?.accountId || "");
    setLogo(data?.data?.accountConfig?.logo || "");
    setSlackVerificationToken(data?.data?.accountConfig?.slackVerificationToken);
    setTicketTableId(data?.data?.accountConfig?.ticketTableId);
    setPrimaryThemeColor(data?.data?.accountConfig?.theme?.colors ?? initColor)
    setAppBarPrimaryColorActive(data?.data?.accountConfig?.theme?.appBarPrimaryColorActive?? false)


    const slackRedirectionUrl = Config("slackRedirection.url");

    // Get the current URL
    const currentUrl = window.location.href;
    // Construct the state object with accountId and currentUrl
    const stateObject = {
      currentUrl: currentUrl,
    
      authToken: 'Bearer ' + localStorage.getItem('at')
    };

    const stateParameter = encodeURIComponent(JSON.stringify(stateObject));


    setSlackUrl(
      `https://slack.com/oauth/v2/authorize?state=${stateParameter}&redirect_uri=${slackRedirectionUrl}&client_id=2149933522689.5288293884738&scope=channels:read,chat:write,chat:write.customize,chat:write.public,groups:read,users:read,users:read.email,files:read,files:write&user_scope=`
    );
  };

  const getTimeZoneList = () => {
    const timezonelist = moment.tz
      .names()
      .map((timezone: any) => ({ value: timezone, label: timezone }));
    setAllTimezones([...timezonelist]);
  };

  useEffect(() => {
    getTimeZoneList();
    getAccountDetails();
  }, []);

  const onSubmit = async (data: any) => {
    try {      
      // get the account id
      //@ts-ignore
      const accountId = currentUser?.accountId;
      await AccountService.updateAccount({
        accountId,
        body: {
          ...data,
          logo,
          slackVerificationToken,
          ticketTableId
        },
      });
      notification("success", "Company details updated successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitWithoutPropagation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(onSubmit)(e);
  };

  return currentUser.role !== "admin" ? (<div className="pt-4"> You do not have necessary permissions</div>): (
    <>
      <Wrapper as="form" onSubmit={handleSubmitWithoutPropagation}>
        <Wrapper1>
          <div>
            <SubHeading>Company Settings</SubHeading>

            <Label> Update your company details here </Label>
          </div>
          <div>
            <ZButton className="mr-2" variant="contained" type="submit">
              {" "}
              Save{" "}
            </ZButton>
            <ZButton secondary variant="contained">
              Cancel
            </ZButton>
          </div>
        </Wrapper1>
        <Divider />
        <ResponsiveWrapper>
          <Block>
            <div>
              <Label bold> Company Name</Label>
            </div>
            <ZInput
              register={register}
              name="accountName"
              placeholder="Company Name"
            />
          </Block>

          <Block>
            <div>
              <Label bold> Company Id</Label>
            </div>
            <ZInput
              isReadOnly
              register={register}
              name="accountId"
              placeholder="Company Id"
            />
          </Block>
        </ResponsiveWrapper>
        <Divider />
        <ResponsiveWrapper>
          <WhiteLabelling logo={logo} setLogo={setLogo} />
        </ResponsiveWrapper>
        <Divider />
        <ResponsiveWrapper>
          <Block>
            <Label bold> Timezone </Label>
            <Controller
              name="timezone"
              control={control}
              render={({
                field: { onChange: fieldOnChange, value, name, ref },
              }) => (
                <Select
                  // styles={customStyles}
                  value={allTimezones.find((c: any) => c.value === value)}
                  isClearable
                  isSearchable={true}
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  onChange={(selectedOption: any) => {
                    fieldOnChange(selectedOption?.value);
                  }}
                  options={allTimezones}
                />
              )}
            />
          </Block>
        </ResponsiveWrapper>
        <Divider />
        
        {
        slackUrl && <ResponsiveWrapper>
        <Block>
            <Label bold> Slack Integration</Label>
           {!slackVerificationToken ?<a href={slackUrl}>
              <img
                alt="Add to Slack"
                height="40"
                width="139"
                src="https://platform.slack-edge.com/img/add_to_slack.png"
                srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
              />
            </a>: <Button onClick={()=>{
              setSlackVerificationToken(null);
            }} style={{width:'wrap-content', maxWidth:'250px'}}  size="medium" variant="text">Remove Slack Integration</Button>}
          </Block>
          </ResponsiveWrapper>}
          <Divider />
        <ResponsiveWrapper>
          <Block>
            <Label bold> Theme Color </Label>
            <Controller
              name="theme"
              control={control}
              render={({
                field: { onChange: fieldOnChange, value, name, ref },
              }) => (
                <ColorList
                  onSelect={(color, usePrimaryColor) => {
                    const currThemeColorData = {
                   
                        colors: color,
                        appBarPrimaryColorActive: usePrimaryColor,
                        primaryColor : color.primary
                      
                    };
                    fieldOnChange(currThemeColorData);
                  }}
                  defaultUsePrimaryColor = {appBarPrimaryColorActive}
                  defaultColor={primaryThemeColor}
                  
                />
              )}
            />
          </Block>
        </ResponsiveWrapper>
      </Wrapper>
    </>
  );
};

export default GeneralSettingEdit;
