//@ts-nocheck
import { Modal } from "@mui/base";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import TopBar from "render-engine/TopBar";
import styled from "styled-components";
import ZRelation from "./ZRelation";
import { EntityService } from "providers/data/services/EntityService";
import { UserService } from "providers/data/services/UserService";
import ZModal from "views/commonComponents/ZModal";
import {
  ModalWrapper,
  ZButton,
} from "views/commonComponents/commonComponents.styles";
import {
  CurlModal,
  GPTDiv,
} from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents";
import { IRelationReferringEntityType } from "views/entities/types";
import { isArray, union } from "lodash";
import notification from "notifications/notifications";
import { load } from "redoc/typings/services/SearchWorker.worker";
import ZCheckbox from "views/commonComponents/ZCheckbox";
import { Checkbox, FormControlLabel } from "@mui/material";
import { ColorPalets, ZIconButton2 } from "views/entities/styles";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setTaskData } from "render-engine/reducers/show/TaskShowReducer";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { TaskService } from "views/tasks/services/TaskService";

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Save as SaveIcon } from "assests";
import CloseIcon from "@mui/icons-material/Close";
import { AiOutlinePlus as CreateIcon } from "react-icons/ai";
import { CheckOptionWrapper } from "render-engine/show/CreateSectionModal";
import MEntityTable from "views/entities/tables/MEntityTable";
import AddRecordToRelationTable from "views/entities/tables/AddRecordToRelationTable";

type Props = {
  showModal: boolean;
  setShowModal: any;
  mode: "create" | "edit";
  name?: string;
  config: any;
  control?: any;
  setShowCreateNewRecordModal?: any;
  recordTypeLabel?: string;
  addedRecords?: any;
  setValue?: any;
  setSelectedRecordAfterSelection?: any;
  selectedRecordAfterSelection?: any;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #101828;
  line-height: 24px;
`;

const CrossIcon = styled(CloseIcon)`
  margin-right: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const CreateButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #344054;
  padding: 8px 14px;
  border-radius: 4px;
  border: 1px solid var(--gray-300, #d0d5dd);
  cursor: pointer;
`;

const SaveButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #3c69e7;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 14px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: flex-start;
  // width: 100%;
  gap: 8px;
  border: 1px solid #d0d5dd;
  background: "#FFF";
  padding: 8px 12px;
  align-self: stretch;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

const SearchInput = styled.input`
  width: 100%;
  color: #667085;
  font-size: 13px;
  border: none;
  outline: none;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 12px;
  // width: 100%;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid #eaecf0;
  background: #fff;
  margin-bottom: 12px;
  font-size: 12px;
`;

const Button = styled.button`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  cursor: pointer;
  font-size: 12px;
`;

function debounce<F extends (...args: any[]) => any>(
  func: F,
  delay: number
): (...args: Parameters<F>) => void {
  let timer: NodeJS.Timeout;

  return (...args: Parameters<F>) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
}

const AddRecordToRelationModal = (props: Props) => {
  const {
    showModal,
    setShowModal,
    mode,
    name = "Dummy",
    config,
    setShowCreateNewRecordModal,
    addedRecords = [],
    recordTypeLabel,
    setValue,
    setSelectedRecordAfterSelection,
    selectedRecordAfterSelection
  } = props;
  const [options, setOptions] = React.useState<any>([]);
  const [defaultValues, setDefaultValues] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  //This is used for handling loading state initially, options are not set initially
  //and so filteredOptions array is empty and is rendered showing no record found.
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [initialDataFetched, setInitialDataFetched] =
    React.useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const { convertDataTypeToString, subRecordCreateMode, initTaskData, rowsToDelete,subRecordData } =
    useTaskShow();

  

  const value = convertDataTypeToString(config.key, false, []);
  const initialAddedRecords = subRecordCreateMode ? [] : initTaskData[config.key] || [];
  // const initialAddedRecords = initTaskData[config.key] || [];

  const dispatch = useDispatch();

  const fetchRecordFromTableType = async () => {
    try {
      setLoading(true);
      const res = await EntityService.getRecordFromTableType({
        tableType: config.relationDetails.referringEntityType,
      });

      // fetch the metadata of the entity to which this column has relation
      const displayColResponse = await EntityService.getDisplayColumn(
        {tableType: config.relationDetails.referringEntityType}
      );
      const displayColumn = displayColResponse?.data?.displayColumn?.name || "recordId";
      // form all the options. label should be the value of display column and value should be recordId
      const allOptions = res.data.map((data: any) => {
        return {
          label: data?.data?.[displayColumn] || data.recordId,
          value: data.recordId,
        };
      });

      // def value currently applies only in case of edit.
      let defVal = [];
      // if it is multiselect then default value should array else it should be an object
      if (config?.isMultiselect) {
        defVal = isArray(config?.value)
          ? config?.value?.map((val: any) => {
              return {
                label:
                  res.data.find((data: any) => data.recordId === val)?.data?.[
                    displayColumn
                  ] || val,
                value: val,
              };
            })
          : [];
      } else {
        // if there is no selected value, we will get an empty array. if its empty assign {} to default value
        defVal =
          config?.value?.length > 0
            ? {
                // response only contains the array of recordId. so from record id we fetch record details and refer to the display column
                //@ts-ignore
                label:
                  res?.data?.find(
                    (data: any) => data.recordId === config?.value?.[0]
                  )?.data?.[displayColumn] || config?.value?.[0],
                value: config?.value?.[0],
              }
            : {};
      }

      const optionsThatAreNotAdded = allOptions.filter(
        (option: any) => !value.find((val: any) => val === option.value)
      );

      setDefaultValues(defVal);
      setOptions(optionsThatAreNotAdded);
      setFilteredOptions(optionsThatAreNotAdded);
      setInitialDataFetched(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      notification("error", "Something Went Worng");
      setLoading(false);
    }
  };

  const fetchTaskFromTaskType = async () => {
    try {
      setLoading(true);
      const res = await TaskService.getTasksFromTaskType({
        taskType: config.relationDetails.referringEntityType,
      });
      const allOptions = res.data.tasks.map((data: any) => {
        return {
          label: data.taskId,
          value: data.taskId,
        };
      });
      // def value currently applies only in case of edit.
      let defVal = [];
      // if it is multiselect then default value should array else it should be an object
      if (config?.isMultiselect) {
        defVal = isArray(config?.value)
          ? config?.value?.map((val: any) => {
              return {
                label: val,
                value: val,
              };
            })
          : [];
      } else {
        // if there is no selected value, we will get an empty array. if its empty assign {} to default value
        defVal =
          config?.value?.length > 0
            ? {
                // response only contains the array of recordId. so from record id we fetch record details and refer to the display column
                //@ts-ignore
                label: config?.value?.[0],
                value: config?.value?.[0],
              }
            : {};
      }

      const optionsThatAreNotAdded = allOptions.filter(
        (option: any) => !value.find((val: any) => val === option.value)
      );

      setDefaultValues(defVal);
      setOptions(optionsThatAreNotAdded);
      setFilteredOptions(optionsThatAreNotAdded);
      setLoading(false);
      setInitialDataFetched(true);
    } catch (error) {
      notification("error", "Something Went Worng");
      setLoading(false);
    }
  };

  const fetchAllUser = async () => {
    try {
      setLoading(true);
      const users = await UserService.listUsers({
        filter: {},
        pagination: { page: 1, perPage: 3000 },
      });
      const allOptions = users?.data.users.map((data: any) => {
        return {
          label: data.name,
          value: data.userId,
        };
      });

      // def value currently applies only in case of edit.
      let defVal = [];
      // if it is multiselect then default value should array else it should be an object
      if (config?.isMultiselect) {
        defVal = isArray(config?.value)
          ? config?.value?.map((val: any) => {
              return {
                label: users?.data.users.find(
                  (user: any) => user.userId === val
                )?.name,
                value: val,
              };
            })
          : [];
      } else {
        // if there is no selected value, we will get an empty array. if its empty assign {} to default value
        defVal =
          config?.value?.length > 0
            ? {
                // response only contains the array of recordId. so from record id we fetch record details and refer to the display column
                //@ts-ignore
                label: users?.data.users.find(
                  (user: any) => user.userId === config?.value?.[0]
                )?.name,
                value: config?.value?.[0],
              }
            : {};
      }
      setDefaultValues(defVal);
      setOptions(allOptions);
      setLoading(false);
      setInitialDataFetched(true);
    } catch (error) {
      console.log(error);
      notification("error", "Error while fetching users");
      setLoading(false);
    }
  };



  useEffect(() => {
    if (
      config.relationDetails.referringEntity ===
      IRelationReferringEntityType.TASK
    ) {
      fetchTaskFromTaskType();
    } else if (
      config.relationDetails.referringEntity ===
      IRelationReferringEntityType.CUSTOM
    ) {
      fetchRecordFromTableType();
    } else if (
      config.relationDetails.referringEntity ===
      IRelationReferringEntityType.USER
    ) {
      fetchAllUser();
    }

    // if (!isArray(config.value)) {
    //   config.value = []
    // }
    // setValue(config.key, config?.value);
  }, []);

  useEffect(() => {
    if (initialDataFetched) {
      setLoading(true);

      if (searchTerm.trim() === "") {
        setFilteredOptions(options);
      } else {
        const searchTermLower = searchTerm.toLowerCase();
        const filtered = options.filter(
          (option: any) =>
            option.label.toLowerCase().includes(searchTermLower) ||
            option.value.toLowerCase().includes(searchTermLower)
        );
        setFilteredOptions(filtered);
      }

      setLoading(false);
    }
  }, [searchTerm, options, initialDataFetched]);

  const debounceSetSearchTerm = useMemo(() => {
    return debounce((value) => setSearchTerm(value), 500);
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceSetSearchTerm(e.target.value);
  };

  const onSave = () => {
    console.log("selectedRecordAfterSelection", selectedRecordAfterSelection);
    if(selectedRecordAfterSelection !== null) {
      dispatch(
        setTaskData({
          nodeId: config.key,
          data: selectedRecordAfterSelection,
        })
      );
    }
  }

  const onClose = () => {
    onSave();
    setShowModal(false)
  }

  return (
    <CurlModal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={showModal}
      onClose={onClose}
    >
      <GPTDiv
        style={{
          height: config.relationDetails.referringEntity === "CUSTOM" ? "70vh" : "600px",
          width: config.relationDetails.referringEntity === "CUSTOM" ? "800px" : "400px",
          padding: "16px",
          borderRadius: "4px",
        }}
      >
        {
          // if relationType is CUSTOM then show the table
          config.relationDetails.referringEntity === "CUSTOM" ? (
            <AddRecordToRelationTable
              tableType={config.relationDetails.referringEntityType}
              onNewRecordRecordClick={() => setShowCreateNewRecordModal(true)}
              onRowSelectionClick={(selectedRows: any) => {
                let newValue = [...value];
                let addedValues = selectedRows.map((row: any) => row);
                if (config.relationDetails.cardinality === "ONE") {
                  newValue = addedValues;
                } else {
                  newValue = union(newValue, addedValues);
                }
                // dispatch(
                //   setTaskData({
                //     nodeId: config.key,
                //     data: newValue,
                //   })
                // );
                setSelectedRecordAfterSelection(newValue);
                setValue(config.key, newValue);
              }}
              isSingleSelect={config.relationDetails.cardinality === "ONE"}
              addedRecords={initialAddedRecords}
              formKey={config.key}
              fromShowPage={true}
              recordTypeLabel={recordTypeLabel}
              onSave={onClose}
              tempAddedRecords={selectedRecordAfterSelection}
              columnId={config.key}
              rowsToDelete={rowsToDelete?.[config.key] || []}
            />
          ) : (
            <>
              <Header>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#101828",
                  }}
                >
                  {mode === "create" ? `Add ${name}` : `Add ${name}`}
                  {props.config.relationDetails.cardinality === "ONE" && (
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#344054",
                      }}
                    >
                      {`Select one ${name}`}
                    </p>
                  )}
                </span>

                <ButtonsContainer>
                  {!subRecordCreateMode && (
                    <ZButton
                      variant="contained"
                      secondary
                      type="button"
                      onClick={setShowCreateNewRecordModal}
                      startIcon={<CreateIcon size="14px" />}
                    >
                      Create
                    </ZButton>
                  )}

                  <ZButton
                    variant="contained"
                    type="button"
                    onClick={() => setShowModal(false)}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </ZButton>
                </ButtonsContainer>
              </Header>
              <SearchContainer>
                <SearchIcon style={{ margin: "0px", color: "#667085" }} />
                <SearchInput placeholder="Search..." onChange={handleSearch} />
              </SearchContainer>
              {loading ? (
                <p>Loading...</p>
              ) : filteredOptions?.length > 0 ? (
                <Wrapper>
                  {filteredOptions.map((option: any) => (
                    <ItemContainer key={option.value}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "4px",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <span>{option.label}</span>
                        <CheckOptionWrapper
                          checked={value?.includes(option.value)}
                          onClick={(e) => {
                            props.onChange(e.target.checked);
                            let newValue = [...value];
                            if (e.target.checked) {
                              if (
                                props.config.relationDetails.cardinality ===
                                "ONE"
                              ) {
                                newValue = [option.value];
                              } else {
                                newValue.push(option.value);
                              }
                            } else {
                              newValue.splice(
                                newValue.indexOf(option.value),
                                1
                              );
                            }
                            dispatch(
                              setTaskData({
                                nodeId: config.key,
                                data: newValue,
                              })
                            );
                            setValue(config.key, newValue);
                          }}
                        >
                          <span
                            style={{
                              color: "#344054",
                              lineHeight: "20px",
                              fontWeight: "500",
                              fontSize: "12px",
                            }}
                          >
                            Select
                          </span>
                        </CheckOptionWrapper>
                      </div>
                      <Button>{option.value}</Button>
                    </ItemContainer>
                  ))}
                </Wrapper>
              ) : (
                <p>No Records Found</p>
              )}
            </>
          )
        }
      </GPTDiv>
      {/* </ModalWrapper> */}
    </CurlModal>
  );
};

export default AddRecordToRelationModal;
