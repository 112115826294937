import styled from "styled-components";
import { MdBook, MdGroup, MdEvent } from "react-icons/md";
import { FaSlack } from "react-icons/fa";
import { userDecodedData } from "utils/UserAuth.utils";
import {
  OnboardingLinks,
  RedirectButtonTypes,
} from "views/onboarding/types/OnboardingTypes";
import { recordRSEvent } from "utils/CommonUtils";

const WelcomeContainer = styled.div`
  padding: 16px;
`;

const WelcomeHeader = styled.h1`
  color: #333;
  font-size: 16px;
  margin-bottom: 0.5em;
  font-weight: 500;
`;

const WelcomeSubTitle = styled.p`
  color: #666;
  font-size: 20px;
  margin-bottom: 1em;
`;

const ActionButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;

const ActionButton = styled.button`
  padding: 6px 15px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  border-radius: 4px; /* Increase border-radius for more rounded corners */
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-weight: 500;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: 20px; /* 142.857% */

  &:last-child {
    margin-left: auto;
    margin-right: 0;
  }
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
  font-size: 24px; /* Adjust font-size as needed for larger icons */
`;

const WelcomeComponent = () => {
  const user: any = userDecodedData();
  return (
    <WelcomeContainer>
      <WelcomeHeader>Hey {user.name}, Welcome to Zorp!</WelcomeHeader>
      <WelcomeSubTitle></WelcomeSubTitle>
      <ActionButtonGroup>
        <ActionButton
          onClick={() => {
            recordRSEvent("book_a_session_button_clicked", {
              context: "home_page",
            });
            window.open(
              OnboardingLinks[RedirectButtonTypes.BOOK_A_SESSION],
              "_blank"
            );
          }}
        >
          <Icon>
            <MdEvent color="#344054" />
          </Icon>
          Book a Session with Us
        </ActionButton>
        <ActionButton
          onClick={() => {
            recordRSEvent("join_slack_community_button_clicked", {
              context: "home_page",
            });
            window.open(
              OnboardingLinks[RedirectButtonTypes.SLACK_COMMUNITY],
              "_blank"
            );
          }}
        >
          <Icon>
            <FaSlack color="#344054" />
          </Icon>
          Join Slack Community
        </ActionButton>
        <ActionButton
          onClick={() => {
            recordRSEvent("zorp_documentation_clicked", {
              context: "home_page",
            });
            window.open(
              OnboardingLinks[RedirectButtonTypes.DOCUMENTATION],
              "_blank"
            );
          }}
        >
          <Icon>
            <MdBook color="#344054" />
          </Icon>
          View Documentation
        </ActionButton>
      </ActionButtonGroup>
    </WelcomeContainer>
  );
};

export default WelcomeComponent;