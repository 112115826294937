//@ts-nocheck
import Hidden from "@mui/material/Hidden";
import { useRef } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FilterTrash from "assests/filter_trash.svg";
import dayjs from "dayjs";
import useQuery from "hooks/useQuery";
import notification from "notifications/notifications";
import { BffService } from "providers/data/services/BffService";
import { useEffect, useState } from "react";
import { useListContext } from "react-admin";
import { useFieldArray, useForm } from "react-hook-form";
import { FiFilter } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTaskListFilterInfo } from "render-engine/reducers/show/TaskShowReducer";
import { userDecodedData } from "utils/UserAuth.utils";
import FilterAddModal from "./components/FilterAddModal";
import FilterCTA from "./components/FilterCTA";
import FilterDynamicComponent, {
  FilterComponentType,
} from "./components/FilterDynamicComponent";
import FilterHeader from "./components/FilterHeader";
import { FilterSeparateText } from "./components/FilterStyledComponents";
import ReactFilterSelect from "./components/ReactFilterSelect";
import { IFilterContext } from "./constants/Filter.types";
import {
  defaultFilterOptions,
  ListOfTextFilterOptions,
  timeFields,
} from "./constants/FilterConstants";
import {
  clearFilterStorage,
  getFilter,
  getFilterListValue,
  getFormattedDateValue,
} from "./filterUtils";

interface IFilterModal {
  openModal: boolean;
  handleClose: (filterCount?: number) => void;
  context: IFilterContext;
  urlEndpoint: string;
  filterTitle: string;
  position: number[];
}

interface IUniversalFilter {
  label: string;
  options: any[];
}

type IUserState = { userId: string };

const FilterModal = ({
  openModal,
  handleClose,
  context,
  urlEndpoint,
  filterTitle,
  position,
}: IFilterModal) => {
  const theme = useTheme();
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = useRef(window.innerWidth);
  const { userId = "" } = userDecodedData() as IUserState;
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));
  const medium = useMediaQuery(theme.breakpoints.up("sm"));
  const [filterMeta, setFilterMeta] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [openAddFilter, setOpenAddFilter] = useState(false);

  const { displayedFilters, setFilters } = useListContext();

  const [modalPosition, setModalPosition] = useState([0, 0, 0]); //[X,Y]

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    setError,
  } = useForm<any>({
    defaultValues: {
      filterArray: [],
      includeDeleted: false,
      // includeInActiveWorkflows: false
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "filterArray",
  });

  // Add New Filter
  const addNewFilter = () => {
    append({
      filterKey: "", // filter key
      filterObject: "", // filter key
      filterOptions: [], // filter options: ex: contains, equal etc...
      filterOptionValue: "", // filterOptions selected Value
      filterValue: "", // filter value
      valueDataType: "", // filter key data type
      valueSelectOptions: [], // if the data type is select, then options are store here.
    });
  };

  // Remove Filter
  const removeFilter = (index: number) => {
    remove(index);
  };

  const checkIfContextIsDefined = (curr: string) => {
    // check if curr lies in any of IContext Enums
    const isPresent = Object.values(IFilterContext).includes(
      curr as IFilterContext
    );
    return isPresent;
  };

  useEffect(() => {
    // check in url
    const getQueryParamFromUrl: string | null = query?.get("search");

    if (getQueryParamFromUrl) {
      try {
        const JSONParam = JSON.parse(getQueryParamFromUrl || "{}");
        if (JSONParam) {
          reset(JSONParam);
        }
      } catch (error) {}
    } else {
      const fetchFilterData: any = getFilter(context, userId);
      if (Object.keys(fetchFilterData).length > 0) {
        reset(fetchFilterData);
      }
    }
  }, []);

  useEffect(() => {
    let currContext = "";
    const val = checkIfContextIsDefined(context);
    if (val) {
      currContext = context;
    } else {
      currContext = IFilterContext.TASK;
    }
    const payload = {
      context: currContext,
    };
    setLoading(true);
    BffService.getFilterMeta(payload)
      .then((res: any) => {
        setLoading(false);
        if (res.code == "200") setFilterMeta(res?.data);
        else notification("error", "Please close the filter and open again");
      })
      .catch((err) => {
        setLoading(false);
        notification("error", "Please close the filter and open again");
      });
  }, []);

  if (!displayedFilters.main) return null;

  const onFormSubmit = (data: any) => {
    let breakFunc = false;
    for (let index = 0; index < data.filterArray.length; index++) {
      const fieldData = data.filterArray[index];

      if (fieldData.filterKey == "scheduledFromTime") {
        // check for scheduledToTime
        if (
          !data.filterArray.find(
            (data: any) => data.filterKey == "scheduledToTime"
          )
        ) {
          setError(`filterArray.${index}.filterObject`, {
            type: "manual",
            message: "scheduled To Time is Required",
          });
          breakFunc = true;
          break;
        }
      } else if (fieldData.filterKey == "scheduledToTime") {
        // check for scheduledToTime
        if (
          !data.filterArray.find(
            (data: any) => data.filterKey == "scheduledFromTime"
          )
        ) {
          setError(`filterArray.${index}.filterObject`, {
            type: "manual",
            message: "scheduled From Time is Required",
          });
          breakFunc = true;
          break;
        }
      } else if (fieldData.filterKey == "fromTime") {
        if (!data.filterArray.find((data: any) => data.filterKey == "toTime")) {
          setError(`filterArray.${index}.filterObject`, {
            type: "manual",
            message: "Update To Time is Required",
          });
          breakFunc = true;
          break;
        }
      } else if (fieldData.filterKey == "toTime") {
        if (
          !data.filterArray.find((data: any) => data.filterKey == "fromTime")
        ) {
          setError(`filterArray.${index}.filterObject`, {
            type: "manual",
            message: "Update From Time is Required",
          });
          breakFunc = true;
          break;
        }
      }

      if (!fieldData.filterValue) {
        setError(`filterArray.${index}.filterValue`, {
          type: "manual",
          message: "This is Required",
        });
        breakFunc = true;
        break;
      }
    }

    if (breakFunc) {
      return;
    }

    const universalFilters: any = filterMeta.find(
      (filterData: IUniversalFilter) => filterData.label == "Universal Filters"
    );
    if (data) {
      const filterResultData = getFilterListValue(
        data,
        context,
        universalFilters,
        userId
      );
      if (context == IFilterContext.TASK) {
        //@ts-ignore
        setFilters(filterResultData.fullFilter);
        dispatch(setTaskListFilterInfo({ ...filterResultData.appliedFilter }));
      } else {
        //@ts-ignore
        // for users, alerts, workflow
        setFilters({
          ...filterResultData.systemFilters,
          data: filterResultData.systemFilters,
        });
      }
      if (typeof urlEndpoint == "function") {
        urlEndpoint = urlEndpoint();
      }
      // navigate(`${urlEndpoint}?search=${JSON.stringify(data)}`)

      if (urlEndpoint.includes("?displayedFilters")) {
        // navigate(`${urlEndpoint}&search=${JSON.stringify(data)}`);
        navigate(`${urlEndpoint}&search=${JSON.stringify(data)}`);
      } else {
        navigate(`${urlEndpoint}?search=${JSON.stringify(data)}`);
        navigate(`${urlEndpoint}?search=${JSON.stringify(data)}`);
      }

      if (data?.includeDeleted) {
        handleClose(data?.filterArray.length + 1 || 0);
      } else {
        handleClose(data?.filterArray.length || 0);
      }
    } else {
      handleClose();
    }
  };

  const setValueToFieldArray = (value: any, index: number) => {
    setValue(`filterArray[${index}].filterObject`, value);

    setValue(`filterArray[${index}].filterKey`, value?.value);

    //  filter Option -> ex: contains, equal

    if (value?.type != FilterComponentType.LIST_OF_TEXT) {
      setValue(
        `filterArray[${index}].filterOptions`,
        value?.filterOption || defaultFilterOptions
      );
      setValue(
        `filterArray[${index}].filterOptionValue`,
        value?.filterOption?.[0] || defaultFilterOptions[0]
      );
    } else {
      setValue(
        `filterArray[${index}].filterOptions`,
        value?.filterOption || ListOfTextFilterOptions
      );
      setValue(
        `filterArray[${index}].filterOptionValue`,
        value?.filterOption?.[0] || ListOfTextFilterOptions[0]
      );
    }

    // filter data type
    setValue(`filterArray[${index}].valueDataType`, value?.type);

    // emptying filter value
    if (value?.type == FilterComponentType.DATE) {
      setValue(
        `filterArray[${index}].filterValue`,
        dayjs(getFormattedDateValue(FilterComponentType.DATE, "", value?.value))
      );
    } else {
      setValue(`filterArray[${index}].filterValue`, null);
    }
    if (value?.type == "BOOLEAN") {
      setValue(`filterArray[${index}].valueSelectOptions`, [
        { value: true, label: "true" },
        { value: false, label: "false" },
      ]);
    } else {
      setValue(`filterArray[${index}].valueSelectOptions`, value.struct || []);
    }
  };

  const getReactFilterData = (index: number) => {
    const appliedWorkFlowFilter = watch().filterArray;
    // adding disabled for the applied filter
    const defaultFilterMeta = filterMeta.map((filterData) => {
      filterData.options?.map((data) => {
        const findAppliedFilter = appliedWorkFlowFilter.find(
          (appliedData) => appliedData.filterKey === data.value
        );
        if (findAppliedFilter) {
          data.isDisabled = true;
        } else {
          data.isDisabled = false;
        }
      });

      return filterData;
    });

    if (context != IFilterContext.TASK) {
      return defaultFilterMeta;
    } else {
      const filterAppliedIndex = appliedWorkFlowFilter.findIndex(
        (data) => data.filterKey === "taskTypes"
      );
      const checkWorkFlowFilterApplied = appliedWorkFlowFilter.find(
        (data) => data.filterKey === "taskTypes"
      );
      if (
        checkWorkFlowFilterApplied &&
        filterAppliedIndex >= 0 &&
        index > filterAppliedIndex
      ) {
        let selectedWorkFlowData = appliedWorkFlowFilter
          .find((data) => data.filterKey === "taskTypes")
          ?.filterValue?.map((data) => data.value);

        if (
          !selectedWorkFlowData?.length ||
          selectedWorkFlowData?.length === 0
        ) {
          return defaultFilterMeta;
        }

        selectedWorkFlowData.push("Universal_Filters");
        const filteredData = defaultFilterMeta.filter((data) =>
          selectedWorkFlowData?.includes(data.workFlowId)
        );
        return filteredData;
      } else {
        return defaultFilterMeta;
      }
    }
  };

  return (
    <Modal
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Box
          className="filter-list-datafields"
          sx={{
            position: "absolute" as "absolute",
            bgcolor: "background.paper",
            border: "1px solid #E0E4E8",
            boxShadow:
              "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
            borderRadius: {
              sm: "0px",
              md: "8px",
            },
            padding: "16px",
            overflowY: "auto",
            ...(context == IFilterContext.WORKFLOW && {
              left: {
                sm: "0%",
                md: "105px",
              },
            }),
            right: {
              sm: "0%",
              md: `${windowWidth.current - position[0] - position[3] - 300}px`,
            },
            top: {
              sm: "0%",
              md: `${position[1] + 20}px`,
            },
            width: {
              sm: "100%",
              md: "60%",
            },
            maxHeight: {
              sm: "100%",
              md: "75%",
            },
            height: {
              xs: "100%",
              sm: "100%",
              md: "fit-content",
            },
          }}
        >
          {/* Header */}
          <FilterHeader
            filterTitle={filterTitle}
            onClose={() => {
              handleClose();
            }}
          />

          {/* // body */}
          <div>
            {fields.map((item: any, index) => {
              return (
                <div key={index}>
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: `${!mobile && medium ? "center" : ""}`,
                      marginTop: "15px",
                    }}
                  >
                    {
                      <Hidden xsDown>
                        {index > 0 && (
                          <FilterSeparateText
                            style={{ width: "30px", paddingRight: "8px" }}
                          >
                            and
                          </FilterSeparateText>
                        )}

                        {index == 0 && (
                          <Typography
                            style={{
                              width: "30px",
                              marginTop: "10px",
                              color: "#323233",
                            }}
                            color={"#7E7E7F"}
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            <FiFilter style={{ fontSize: "15px" }} />
                          </Typography>
                        )}
                      </Hidden>
                    }

                    <Grid
                      container
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "5px",
                      }}
                      spacing={{ xs: 1 }}
                    >
                      <Hidden smUp>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            alignContent: "center",
                            marginRight: "20px",
                          }}
                        >
                          {index == 0 && (
                            <Typography
                              style={{
                                width: "30px",
                                color: "#323233",
                                marginLeft: "5px",
                              }}
                              color={"#7E7E7F"}
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <FiFilter
                                style={{ fontSize: "15px", marginLeft: "8px" }}
                              />
                            </Typography>
                          )}

                          {index > 0 && (
                            <FilterSeparateText
                              style={{ width: "30px", marginTop: "5px" }}
                            >
                              and
                            </FilterSeparateText>
                          )}

                          {
                            <img
                              src={FilterTrash}
                              className="cursor-pointer"
                              onClick={() => {
                                removeFilter(index);
                              }}
                            />
                          }
                        </div>
                      </Hidden>

                      <Grid
                        style={{ width: "95%" }}
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                      >
                        <ReactFilterSelect
                          setValue={setValue}
                          styleSelectedValue={context == IFilterContext.TASK}
                          errors={errors}
                          isMulti={false}
                          value={item?.filterObject}
                          isLoading={Loading}
                          name={`filterArray[${index}].filterObject`}
                          control={control}
                          // apply filter here when filter type is task, and after the certain index
                          options={getReactFilterData(index)}
                          onChange={(value) => {
                            setValueToFieldArray(value, index);

                            // when certain fields from the timeFields array are added, then we need to insert some fields
                            // ex: when schedule from is added then we need to insert schedule to similar to updated from
                            timeFields.forEach((timeData) => {
                              if (timeData.label == value?.value) {
                                const checkToTime = watch().filterArray.find(
                                  (data: any) =>
                                    data.filterKey === timeData.toCheck
                                );
                                if (!checkToTime) {
                                  //@ts-ignore
                                  const getToTimeMetaData = filterMeta
                                    ?.find(
                                      (data: any) =>
                                        data.label == "Universal Filters"
                                    )
                                    ?.options?.find(
                                      (data: any) =>
                                        data.value == timeData.toCheck
                                    );
                                  addNewFilter();
                                  setValueToFieldArray(
                                    getToTimeMetaData,
                                    index + 1
                                  );
                                }
                              }
                            });
                          }}
                        />
                      </Grid>

                      <Grid
                        style={{ width: "95%" }}
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                      >
                        {
                          <ReactFilterSelect
                            setValue={setValue}
                            errors={errors}
                            isMulti={false}
                            hideDropdownArrow={false}
                            isDisabled
                            value={watch().filterArray[index]?.filterOptions[0]}
                            noOptionsMessage="Select First Dropdown to see options here"
                            name={`filterArray[${index}].filterOptionValue`}
                            control={control}
                            options={watch().filterArray[index]?.filterOptions}
                            onChange={(optionValue) => {
                              setValue(
                                `filterArray[${index}].filterOptionValue`,
                                optionValue
                              );
                            }}
                          />
                        }
                      </Grid>

                      <Grid
                        style={{ width: "95%" }}
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                      >
                        <FilterDynamicComponent
                          errors={errors}
                          setValue={setValue}
                          filterKey={watch().filterArray[index]?.filterKey}
                          selectOptions={
                            watch().filterArray[index]?.valueSelectOptions
                          }
                          control={control}
                          value={item.filterValue}
                          name={`filterArray[${index}].filterValue`}
                          register={register}
                          index={index}
                          onChange={(value) => {
                            setValue(
                              `filterArray[${index}].filterValue`,
                              value
                            );
                          }}
                          disabled={false}
                          type={
                            watch().filterArray[index]?.valueDataType ||
                            FilterComponentType.TEXT
                          }
                        />
                      </Grid>
                    </Grid>
                    {
                      <Hidden xsDown>
                        <img
                          style={{ marginBottom: "10px" }}
                          src={FilterTrash}
                          className="cursor-pointer"
                          onClick={() => {
                            removeFilter(index);
                          }}
                        />
                      </Hidden>
                    }
                  </Grid>
                </div>
              );
            })}
          </div>

          {/* Footer */}
          <Grid
            sx={{
              marginLeft: {
                sx: "",
                sm: `${fields.length > 0 ? "2.4rem" : ""}`,
              },
              marginRight: {
                sx: "",
                sm: "",
              },
            }}
          >
            <FilterCTA
              clearFilter={() => {
                setFilters({}, []);
                clearFilterStorage(context, userId);
                handleClose(0);
              }}
              showIncludeDeleted={context == IFilterContext.TASK}
              showFilterIcon={fields.length > 0 ? false : true}
              disableSubmitButton={Loading}
              nameList={["includeDeleted", "includeInActiveWorkflows"]}
              control={control}
              addNewFilter={(pos) => {
                const x =
                  pos.current.offsetParent.offsetLeft + pos.current.offsetLeft;
                let y = pos.current.offsetTop;
                let buttonHeight = pos.current.offsetHeight;

                // this condition is to show the add filter modal close the button.
                if (context == IFilterContext.WORKFLOW)
                  y = pos.current.offsetParent.offsetHeight + 50;
                else if (context == IFilterContext.USER) {
                  y = y + 50;
                  buttonHeight += 110;
                } else if (context == IFilterContext.TEAM) {
                  y = y + 50;
                  buttonHeight += 110;
                } else if (context == IFilterContext.ALERT)
                  y = pos.current.offsetParent.offsetHeight + 50;

                setModalPosition([x, y, buttonHeight]);
                // addNewFilter();
                setOpenAddFilter(true);
              }}
              handleClose={() => {
                handleClose();
              }}
            />
          </Grid>

          {openAddFilter && (
            <FilterAddModal
              loading={Loading}
              context={context}
              invokeIndex={fields.length + 1}
              appliedWorkFlowFilter={watch().filterArray}
              position={modalPosition}
              openModal={openAddFilter}
              handleClose={() => {
                setOpenAddFilter(false);
              }}
              filterMeta={filterMeta}
              onSelect={(data) => {
                addNewFilter();
                const index = fields.length;
                setOpenAddFilter(false);
                setValueToFieldArray(data, index);

                // logic is to insert new row for schedule from time and schedule to time, update from time and update to time

                if (context == IFilterContext.TASK) {
                  timeFields.forEach((timeData) => {
                    if (timeData.label == data?.value) {
                      const checkToTime = watch().filterArray.find(
                        (data: any) => data.filterKey === timeData.toCheck
                      );
                      if (!checkToTime) {
                        //@ts-ignore
                        const getToTimeMetaData = filterMeta
                          ?.find(
                            (data: any) => data.label == "Universal Filters"
                          )
                          ?.options?.find(
                            (data: any) => data.value == timeData.toCheck
                          );
                        addNewFilter();
                        setValueToFieldArray(getToTimeMetaData, index + 1);
                      }
                    }
                  });
                }
              }}
            />
          )}
        </Box>
      </form>
    </Modal>
  );
};

export default FilterModal;
