import DateFnsUtils from '@date-io/date-fns';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import DatePicker from '@mui/lab/DatePicker';
import 'date-fns';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import React, { Component, useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { pickupLocationTasks } from 'utils/TasktypeUtils';
import { PageCategoryEnum, recordPageVisit } from '../../config/Rudderstack';
import TeamSelect from '../teams/Teams.js';
import MapTaskList from './MapTaskList';
import MapUserList from './MapUserList';
import iconLocation from './location_marker.png';
import L from 'leaflet';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import './map.css';
import Table from './ArrayToTable.js';
import * as ReactDOM from 'react-dom';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

const section = {
  height: '100%',
  paddingTop: 5,
  backgroundColor: '#fff',
  maxHeight: '100vh',
};

const section2 = {
  height: '100%',
  paddingTop: 5,
  backgroundColor: '#fff',
  maxHeight: '100vh',
  overflow: 'auto',
  paddingLeft: 5,
  paddingRight: 5,
};

const horizontalSection = {
  paddingTop: 5,
  backgroundColor: '#fff',
  maxHeight: '98vh',
  overflowX: 'scroll',
  width: 'calc(75vw - 70px)',
  // display:"flex",
  // flexDirection:"row",
};
let locationIcon = L.icon({
  iconUrl: iconLocation,
  iconSize: [30, 50],
  // shadowUrl: iconShadow,
});


export default function ZorpMap() {
  // const pickupLocationTasks = ['porter_order_pickup_task', 'porter_order_return_task', 'towing_service'];
  const mapRef = React.useRef(null);
  const [refresh, setRefresh] = React.useState(false);
  const [assignedOrUnassignedTask, setAssignedOrUnassignedTask] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedDateStartTime, setSelectedDateStartTime] = React.useState(
    moment(new Date()).startOf('day').toISOString()
  );
  const [selectedDateEndTime, setSelectedDateEndTime] = React.useState(moment(new Date()).endOf('day').toISOString());

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.map, 'map');
  }, []);

  // const[teams, setTeams] = React.useState([]);
  const [selectedTeam, setSelectedTeam] = React.useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedDateStartTime(moment(date).startOf('day').toISOString());
    setSelectedDateEndTime(moment(date).endOf('day').toISOString());
    setSelectedTask(null);
  };

  const handleTeamChange = (sTeam) => {
    setSelectedTeam(sTeam);
  };

  const [selectedTask, setSelectedTask] = React.useState(null);
  const [taskMarkerList, setTaskMarkerList] = React.useState([]);
  const [userMarkerList, setUserMarkerList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [userPolylineList, setUserPolylineList] = React.useState([]);
  const [centerLatitude, setCenterLatitude] = React.useState(12.964283197184669);
  const [centerLongitude, setCenterLongitude] = React.useState(77.58926761828714);

  const handleTaskSelect = (selectedTask) => {
    setSelectedTask(selectedTask);
    let location = pickupLocationTasks.includes(selectedTask?.taskType)
      ? selectedTask?.data[0]?.info?.pickup?.location
      : selectedTask?.data[0]?.info?.customer?.location;
    if (location?.latitude && location?.longitude) {
      setCenterLatitude(location?.latitude);
      setCenterLongitude(location?.longitude);
    }
  };

  const handleUpdate = (assignedOrUnassignedTask) => {
    setAssignedOrUnassignedTask(assignedOrUnassignedTask);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const userPositionsColumns = [
    {
      heading: 'Name',
      property: 'name'
    },
    {
      heading: 'User Id',
      property: 'userId'
    },
    {
      heading: 'Current Tasks',
      property: 'tasks'
    },
    {
      heading: 'Distance (KM)',
      property: 'distance'
    }
  ]

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      let positions = [];

      taskMarkerList?.forEach((marker) => {
        if (marker.props?.position) {
          positions.push(marker.props?.position);
        }
      });

      userMarkerList?.forEach((marker) => {
        if (marker.props?.position) {
          positions.push(marker.props?.position);
        }
      });
      if (positions.length > 0) {
        map.fitBounds(positions);
      }
    }
  }, [taskMarkerList, userMarkerList]); //eslint-disable-line react-hooks/exhaustive-deps

  const Search = (props) => {
    const map =  mapRef.current // access to leaflet map
    const { provider } = props;
   
    useEffect(() => {
      if (map) {
        const searchControl = new GeoSearchControl({
            provider,
            marker: {
              icon: locationIcon,
              draggable: false,
            },
            retainZoomLevel : true,
            showPopup : true,
            classNames: {
              container: "containerClass",
              input : "inputClass",
              resetButton : "resetButtonClass"
            },
            popupFormat: ({ query, result }) => {
              let positions = [];
              userList?.forEach((user) => {
                if (user.userLocation) {
                  positions.push({
                    latitude : user.userLocation.latitude,
                    longitude : user.userLocation.longitude,
                    name : user.name,
                    userId : user.userId,
                    tasks : user?.tasks?.length,
                    distance : (haversineDistance(user.userLocation.latitude, user.userLocation.longitude, result.y, result.x)/1000 * 1.4).toFixed(2)
                  });
                }
              });

              let div = document.createElement('div');
              ReactDOM.render(<Table
                columns={userPositionsColumns}
                data={positions.sort(function(a, b) { return a.distance - b.distance; })}
                propertyAsKey='userId' //The data property to be used as a key
              />, div);
              return "<b>Address : </b> (" + result.y + ", " +  result.x + ") <br/>" + result.label + "<br/></br><b>User Positions : </b><br/>" + div.innerHTML;
            }
        })
        
        map.addControl(searchControl) // this is how you add a control in vanilla leaflet
        return () => map.removeControl(searchControl)
      }
    }, [props])

    return null // don't want anything to show up from this comp
}

function haversineDistance(lat1, lon1, lat2, lon2) {
  function toRad(x) {
    return x * Math.PI / 180;
  }

  let R = 6371000; // meters

  let x1 = lat2 - lat1;
  let dLat = toRad(x1);
  let x2 = lon2 - lon1;
  let dLon = toRad(x2)

  let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;

  return d;
}

  return (
    <Grid direction='row' container spacing={1} style={section} className='flex-section'>
      <Grid container item xs={3}>
        <Grid item xs container style={{ borderRight: '1px solid #141414' }} direction='column'>
          <Grid>
            <div className='flex flex-row  justify-between bg-zorpBlack text-white p-2'>
              <div style={{ float: 'left' }}>
                <h1 className='bg-zorpBlack text-white p-2'>Tasks</h1>
              </div>
              <div style={{ float: 'right', marginLeft: '230px' }}>
                <IconButton onClick={handleRefresh}>
                  <RefreshIcon fontSize='small' style={{ color: 'white' }} />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid style={{ paddingLeft: '5px', paddingRight: '5px', paddingTop: '5px' }}>
            <div className='flex flex-row items-center'>
              <div className='flex flex-row justify-center items-center'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    style={{ width: '100%' }}
                    disableToolbar
                    autoOk={true}
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='dense'
                    id='date-picker-inline'
                    label='Date'
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className='justify-center items-center ml-4'>
                <TeamSelect
                  team={selectedTeam}
                  onChange={handleTeamChange}
                  size='120px'
                  label='Team'
                  variant='standard'
                />
              </div>
            </div>
          </Grid>
          <Grid item xs style={section2} className={'flex-col-scroll'}>
            {/* <MapTaskList onTaskSelect={handleTaskSelect} onTasks={handleTaskLoad}/> */}
            <MapTaskList
              // update={updateFlag}
              refresh={refresh}
              assignedOrUnassignedTask={assignedOrUnassignedTask}
              onTaskSelect={handleTaskSelect}
              setTaskMarkerList={setTaskMarkerList}
              setUserPolylineList={setUserPolylineList}
              selectedDateStartTime={selectedDateStartTime}
              selectedDateEndTime={selectedDateEndTime}
              selectedTeam={selectedTeam}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={9}>
        <Grid item xs container direction='column' spacing={1}>
          <Grid item xs style={{ margin: 0, width: '100%' }}>
            <MapContainer
              refs={mapRef}
              whenCreated={(mapInstance) => {
                mapRef.current = mapInstance;
              }}
              key={[centerLatitude, centerLongitude]}
              center={[centerLatitude, centerLongitude]}
              zoom={11.8}
              scrollWheelZoom={false}
              fullWidth
              fullHeight
            >
              <Search provider={new OpenStreetMapProvider()} />
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
              {taskMarkerList}
              {userPolylineList}
              {userMarkerList}
            </MapContainer>
          </Grid>
          <Grid item style={horizontalSection}>
            <MapUserList
              onChange={handleUpdate}
              refresh={refresh}
              setUserMarkerList={setUserMarkerList}
              setUserList={setUserList}
              setUserPolylineList={setUserPolylineList}
              setTaskMarkerList={setTaskMarkerList}
              sTask={selectedTask}
              selectedTeam={selectedTeam}
              selectedDateStartTime={selectedDateStartTime}
              selectedDateEndTime={selectedDateEndTime}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}