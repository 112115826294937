import { useEffect, useState } from "react";
import {
  CurlModal,
  HeaderContentWrapper,
  CurlModalBody,
  FlexDiv,
  MainHeading,
  GPTModalHeader,
  GPTDiv,
  GPTBodyDiv,
  GPTLabel,
  GPTSingleDiv,
  GPTModalBody,
  ImportCurlSentence,
  GPTNotEnoughCredits,
  TextSmall,
  ColumnFlexDiv,
  CreditRemaining,
  StyledHr,
} from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents";
// import FilterModalClose from "assests/FilterModalClose.svg";
import {
  ZButton,
  ZTextFieldArea,
} from "views/commonComponents/commonComponents.styles";
//@ts-ignore
import { BuilderService } from "providers/data/services/BuilderService";
import notification from "notifications/notifications";
import { useRefresh } from "react-admin";
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineLeft,
  AiOutlinePlus,
  AiOutlineThunderbolt,
  AiOutlineDown,
} from "react-icons/ai";
import { InfoText, Label } from "views/DataField/DataField.styles";
import { useForm, useFieldArray } from "react-hook-form";
import ZInput from "views/commonComponents/ZTextInput";
import { TextareaAutosize } from "@mui/base";
import { RiDeleteBinLine } from "react-icons/ri";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import { recordRSEvent } from "utils/CommonUtils";
import {
  setShowWorkflowConfigModal,
  setShowPreviewModal,
} from "../reducers/workflowAction";
import { useDispatch } from "react-redux";
import useWorkFlow from "../hooks/useWorkFlow";
import Preview from "views/preview/Preview";
import { useNavigate } from "react-router-dom";
import { FiBook } from "react-icons/fi";
import Select from "react-select";
import { Menu, MenuItem } from "@mui/material";
import { BsChevronCompactDown } from "react-icons/bs";
import styled from "styled-components";
import useWidth from "hooks/useWidth";
import GPTIntroComponent from "./GPTIntroComponent";
import { ZIconButton2 } from "views/entities/styles";

interface IModalProps {
  openModal: boolean;
  handleClose: () => void;
}

interface AISamplePrompt {
  title: string;
  subTitle: string;
  workflowName: string;
  workflowDescription: string;
  screens: AISamplePromptScreen[];
}

interface AISamplePromptScreen {
  screenName: string;
  screenDescription: string;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; // to take the full height of the viewport
`;

// Define your styled components
const Card = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
`;

const CardText = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #333;
`;

const CardTextDetails = styled.p`
  font-size: 13px;
  font-weight: 300;
  color: #333;
`;

const CardButton = styled.button`
  border: 1px solid #ccc; // thin gray border
  padding: 10px 20px;
  color: #333; // adjust text color as needed
  background-color: ${(props) => props.color};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => "#0056b3"};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
`;

const ZorpGPTNew = ({ openModal, handleClose }: IModalProps) => {
  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(1);
  const [workflows, setWorkflows] = useState<any[]>([]);

  const { showPreviewModal } = useWorkFlow();

  const [prompt, setPrompt] = useState("");
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [samplePrompts, setSamplePrompts] = useState<AISamplePrompt[]>([]);
  const refresh = useRefresh();
  const largeScreen = useWidth();
  const [showGPTIntro, setShowGPTIntro] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      workflowName: "",
      workflowDescription: "",
      screens: [{ screenDescription: "", screenName: "" }],
    },
  });

  const dispatch = useDispatch();

  const publishAsPreview = (workflow: any) => {
    setLoading(true);
    recordRSEvent("publish_button_click", {
      context: "builder_general",
      workflow_id: workflow?.workflowId,
      workflow_name: workflow?.workflowName,
    });
    // dispatch(setShowWorkflowConfigModal(false));
    BuilderService.publishAsPreview(workflow?.workflowId)
      .then((res) => {
        setLoading(false);
        const at = localStorage.getItem("at");
        // window.open(`https://zorp-dev.web.app/#/?at=${at}&preview=true`, "_blank");
        dispatch(setShowPreviewModal(true));
        localStorage.setItem("isPreview", "true");
        localStorage.setItem("previewWorkflowId", workflow?.workflowId);
      })
      .catch((err) => {
        console.error("Error", err);
        recordRSEvent("publish_event_fail", {
          context: "builder_general",
          workflow_id: workflow?.workflowId,
          workflow_name: workflow?.workflowName,
        });
        setLoading(false);
        notification("error", "Error while publishing as preview");
      });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const watchFields = watch("screens");
  const watchWorkflowName = watch("workflowName");
  const watchWorkflowDescription = watch("workflowDescription");

  const navigate = useNavigate();

  const getInfo = (): any => {
    setLoading(true);
    BuilderService.fetchUsageInfoForGPT()
      .then((res: any) => {
        if (res?.code === "200") {
          setRemainingCredits(res.data.totalCredits);
          setSamplePrompts(res.data.samplePrompt);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // Fetch Usage Info and Sample prompt from Builder Service
  useEffect(() => {
    getInfo();
    localStorage.removeItem("redirectUrl");
    if (localStorage.getItem("gptIntroShown") !== "false")
      setShowGPTIntro(true);
  }, []);

  const { fields, append, remove, update, swap } = useFieldArray({
    control,
    name: "screens",
  });

  const setGPTFormConfig = (data: AISamplePrompt) => {
    setValue("workflowName", data.workflowName);
    setValue("workflowDescription", data.workflowDescription);
    remove();
    setValue("screens", data.screens);
  };

  const addScreen = () => {
    append({ screenDescription: "", screenName: "" });
  };

  const removeScreen = (index: number) => {
    remove(index);
  };

  const createWorkflow = async (data: any) => {
    // form the gpt string
    const workflowPrompt = `
      Create a workflow with name ${
        data.workflowName
      } which does the following: ${data.workflowDescription}
      The screen details are as follows: ${data.screens.map(
        (screen: any, index: number) => {
          return `Screen-${index} : ${screen.screenDescription} captures ${screen.screenName}`;
        }
      )}
    `;
    try {
      setLoading(true);
      const res: any = await BuilderService.createWorkflowFromGPT(
        workflowPrompt
      );
      if (res?.data?.freeGptCreditsLeft)
        setRemainingCredits(res?.data?.freeGptCreditsLeft);
      if (!res) {
        setLoading(false);
        // notification("error", "The workflow could not be created from the description provided. Please try again. ");
        return;
      }

      if (res.code !== "200") {
        setLoading(false);
        console.log("error ", res);
        notification("error", res.message);
        return;
      }

      // get all keys for res?.data
      const keys = Object.keys(res?.data);

      // since only workflow is generated, the length of keys will be 1. The key is the generated workflow id
      if (keys.length === 0) {
        setLoading(false);
        notification("error", "Something went wrong");
        return;
      }

      // get the workflow id
      const workflowId = keys[0];

      // set workflow info to state variable
      setWorkflows([
        {
          ...res?.data?.[workflowId],
        },
      ]);

      // move to step 2
      setStep(2);

      notification("success", "Workflows created successfully");
      setLoading(false);
      refresh();
      // handleClose();
      // push user to workflow page
      //   navigate(`/workflows/${res.data.workflowId}/show`);
    } catch (e) {
      console.log(e);
      setLoading(false);
      notification("error", "Something went wrong");
    }
  };

  const handlePreviewClick = async () => {
    await publishAsPreview(workflows[0]);
  };

  const moveUp = (index: number) => {
    if (index === 0) {
      return;
    }
    swap(index, index - 1);
  };

  const moveDown = (index: number) => {
    if (index === workflows.length - 1) {
      return;
    }
    swap(index, index + 1);
  };

  const handleEditClick = (workflow: any) => {
    navigate(`/workflows/${workflow.workflowId}/show`);
  };

  const generateButton = () => {
    return (
      <ZButton
        type="submit"
        variant="contained"
        startIcon={<AiOutlineThunderbolt />}
        disabled={
          showGPTIntro ||
          loading ||
          remainingCredits <= 0 ||
          workflows?.length > 0
        }
      >
        {loading ? "Generating..." : "Generate"}
      </ZButton>
    );
  };

  return (
    <>
      <CurlModal open={openModal} onClose={handleClose}>
        <GPTDiv onSubmit={handleSubmit(createWorkflow)}>
          {loading ? (
            <LoadingScreen />
          ) : (
            <>
              <GPTModalHeader>
                <HeaderContentWrapper>
                  <div>
                    <FlexDiv>
                      <ZIconButton2 onClick={handleClose} className="mr-2">
                        <AiOutlineLeft />
                      </ZIconButton2>
                      <ColumnFlexDiv>
                        <MainHeading>
                          {" "}
                          {largeScreen
                            ? `ZORP GPT - Create your Mobile workflow`
                            : `ZORP GPT`}{" "}
                        </MainHeading>
                        <TextSmall>
                          {" "}
                          Provide your workflow details screen by screen or use
                          one of the sample prompts to get started{" "}
                        </TextSmall>
                      </ColumnFlexDiv>
                    </FlexDiv>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <CreditRemaining>
                      Credits Remaining: {remainingCredits}{" "}
                    </CreditRemaining>
                    <ZButton
                      secondary
                      variant="contained"
                      id="demo-customized-button"
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      disableElevation
                      disabled={
                        showGPTIntro || loading || remainingCredits <= 0
                      }
                      onClick={handleClick}
                      endIcon={<AiOutlineDown size="1rem" />}
                    >
                      Sample Prompts
                    </ZButton>
                    <Menu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                    >
                      {samplePrompts?.map((prompt) => {
                        return (
                          <MenuItem
                            onClick={() => {
                              setGPTFormConfig(prompt);
                              handleCloseMenu();
                            }}
                            disableRipple
                          >
                            <div
                              style={{
                                padding: "8px",
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  color: "#3054B9",
                                }}
                              >
                                {prompt.title}{" "}
                              </p>
                              <p
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#3054B9",
                                }}
                              >
                                {prompt.subTitle}
                              </p>
                            </div>
                          </MenuItem>
                        );
                      })}
                    </Menu>
                    &nbsp;&nbsp;
                    {generateButton()}
                  </div>
                </HeaderContentWrapper>

                {remainingCredits <= 0 && (
                  <GPTNotEnoughCredits>
                    You dont have enough credits to generate workflow with Zorp
                    AI. Contact us at{" "}
                    <u>
                      <a href="mailto: support@zorp.one">support@zorp.one</a>
                    </u>{" "}
                    if you need additional credits
                  </GPTNotEnoughCredits>
                )}
              </GPTModalHeader>
              {showGPTIntro && (
                <GPTIntroComponent
                  onContinue={() => setShowGPTIntro(false)}
                ></GPTIntroComponent>
              )}
              {!showGPTIntro && (
                <GPTModalBody>
                  {step === 1 ? (
                    <>
                      <GPTBodyDiv>
                        <GPTSingleDiv
                          style={{
                            borderRight: "1px solid #EAECF0",
                            padding: "14px",
                          }}
                        >
                          <div>
                            <GPTLabel> Workflow Name </GPTLabel>
                            <ZInput
                              placeholder="Enter Workflow Name"
                              maxLength={30}
                              register={register}
                              name="workflowName"
                              errors={errors}
                              validations={{
                                required: "Workflow Name is required",
                              }}
                            />
                            <ImportCurlSentence style={{ float: "right" }}>
                              {" "}
                              {watchWorkflowName?.length || 0} / 30{" "}
                            </ImportCurlSentence>
                            {
                              // show error if any
                              errors.workflowName && (
                                <p className="text-red-500">
                                  {errors.workflowName.message}
                                </p>
                              )
                            }
                            <GPTLabel className="mt-3">
                              {" "}
                              Workflow Description{" "}
                            </GPTLabel>
                            <TextareaAutosize
                              placeholder="Describe your workflow in detail"
                              {...register("workflowDescription", {
                                required: "Workflow Description is required",
                              })}
                              minRows={18}
                              maxRows={largeScreen ? 20 : 5}
                              maxLength={500}
                              className="w-full h-32 p-2 border border-gray-300 rounded-md"
                            />
                            <ImportCurlSentence style={{ float: "right" }}>
                              {" "}
                              {watchWorkflowDescription?.length || 0} / 500{" "}
                            </ImportCurlSentence>
                            {/* show error if any */}
                            {errors.workflowDescription && (
                              <p className="text-red-500">
                                {errors.workflowDescription.message}
                              </p>
                            )}
                          </div>
                        </GPTSingleDiv>
                        {largeScreen ? <></> : <StyledHr></StyledHr>}
                        <GPTSingleDiv
                          style={{
                            backgroundColor: "#FCFCFD",
                            padding: "0px 14px 14px 14px",
                            height: "96% !important",
                            overflow: "auto",
                          }}
                        >
                          <FlexDiv
                            style={{
                              justifyContent: "space-between",
                              position: "sticky",
                              top: "0",
                              padding: "10px 0px 20px 0px",
                              backgroundColor: "#FCFCFD",
                            }}
                          >
                            <GPTLabel> Describe Screens </GPTLabel>
                            <GPTLabel
                              style={{
                                color: "#3054B9",
                                fontWeight: 600,
                              }}
                              className="cursor-pointer"
                              onClick={addScreen}
                            >
                              {" "}
                              <FlexDiv style={{ gap: "8px" }}>
                                <AiOutlinePlus />
                                Add screen
                              </FlexDiv>
                            </GPTLabel>
                          </FlexDiv>
                          <div>
                            {fields.map((item, index) => {
                              return (
                                <>
                                  <FlexDiv
                                    className="mb-1"
                                    style={{
                                      justifyContent: "space-between",
                                      borderTop: "2px solid #EAECF0",
                                      paddingTop: "12px",
                                    }}
                                    key={item.id || index}
                                  >
                                    <GPTLabel> Screen {index + 1}</GPTLabel>
                                    <FlexDiv style={{ gap: "8px" }}>
                                      {
                                        // show move up if index is > 0
                                        index > 0 && (
                                          <AiOutlineArrowUp
                                            className="cursor-pointer"
                                            onClick={() => moveUp(index)}
                                          />
                                        )
                                      }
                                      {
                                        // show move down if index is < length - 1
                                        index < fields.length - 1 && (
                                          <AiOutlineArrowDown
                                            className="cursor-pointer"
                                            onClick={() => moveDown(index)}
                                          />
                                        )
                                      }
                                      {
                                        // show delete only if there are more than 1 screens
                                        fields.length > 1 && (
                                          <RiDeleteBinLine
                                            size={"1.5rem"}
                                            className="cursor-pointer"
                                            onClick={() => removeScreen(index)}
                                          />
                                        )
                                      }
                                    </FlexDiv>
                                  </FlexDiv>
                                  <div>
                                    <ZTextFieldArea
                                      placeholder="Provide Screen Name"
                                      {...register(
                                        `screens.${index}.screenName`,
                                        {
                                          required: "Screen Name is required",
                                        }
                                      )}
                                      minRows={1}
                                      maxRows={1}
                                      maxLength={50}
                                      className="w-full h-32 mt-1 p-2 border border-gray-300 rounded-md"
                                    />
                                    <FlexDiv
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        {/* show error if any */}
                                        {errors?.screens &&
                                          errors?.screens?.[index] && (
                                            <p className="text-red-500">
                                              {
                                                errors?.screens?.[index]
                                                  ?.screenDescription?.message
                                              }
                                            </p>
                                          )}
                                      </div>
                                      <ImportCurlSentence className="mb-2">
                                        {" "}
                                        {watchFields?.[index]?.screenDescription
                                          ?.length || 0}{" "}
                                        / 50{" "}
                                      </ImportCurlSentence>
                                    </FlexDiv>
                                  </div>

                                  <ZTextFieldArea
                                    placeholder="Describe what inputs you would like to capture in this screen. Separate inputs with comma. You can also provide any additional screen information here."
                                    {...register(
                                      `screens.${index}.screenDescription`,
                                      {
                                        required: "Screen Input is required",
                                      }
                                    )}
                                    minRows={4}
                                    maxRows={5}
                                    maxLength={500}
                                    className="w-full h-32 p-2 mt-2 border border-gray-300 rounded-md"
                                  />
                                  <FlexDiv
                                    style={{
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {/* show error if any */}
                                    <div>
                                      {errors.screens &&
                                        errors.screens[index] && (
                                          <p className="text-red-500">
                                            {
                                              errors?.screens?.[index]
                                                ?.screenName?.message
                                            }
                                          </p>
                                        )}
                                    </div>
                                    <ImportCurlSentence as="div">
                                      {" "}
                                      {watchFields?.[index]?.screenName
                                        ?.length || 0}{" "}
                                      / 500
                                    </ImportCurlSentence>
                                  </FlexDiv>
                                </>
                              );
                            })}
                          </div>
                        </GPTSingleDiv>
                      </GPTBodyDiv>
                    </>
                  ) : (
                    <div
                      style={{
                        padding: "12px",
                      }}
                    >
                      {workflows.map((workflow, index) => {
                        return (
                          <div>
                            <Container>
                              <Card>
                                <CardText>
                                  Your workflow has been generated
                                  <CardTextDetails>
                                    You can now preview the app with the
                                    workflow that you have generated or edit the
                                    workflow itself.
                                  </CardTextDetails>
                                </CardText>
                                <ButtonContainer>
                                  {" "}
                                  <ZButton
                                    fullWidth
                                    secondary
                                    variant="contained"
                                    onClick={() => handleEditClick(workflow)}
                                  >
                                    {largeScreen ? "Edit Workflow" : ""}
                                  </ZButton>{" "}
                                  <ZButton
                                    fullWidth
                                    variant="contained"
                                    onClick={handlePreviewClick}
                                  >
                                    Preview App
                                  </ZButton>
                                </ButtonContainer>
                              </Card>
                            </Container>
                            {/* <div
                            style={{
                              padding: "10px",
                              border: "1px solid #E5E5E5",
                              borderRadius: "8px",
                              display: "flex",
                              marginBottom: "10px",
                              fontSize: "20px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Congrats! Your workflow has been created
                            successfully. Click on Preview button to see the
                            workflow.
                          </div>
                          <div
                            style={{
                              padding: "10px",
                              border: "1px solid #E5E5E5",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              // onClick={handlePreviewClick}
                              key={index}
                            >
                              <InfoText>
                                {" "}
                                Workflow ID : {workflow.workflowId}{" "}
                              </InfoText>
                              <InfoText>
                                {" "}
                                Workflow Name : {workflow.workflowName}{" "}
                              </InfoText>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                              }}
                            >
                              <ZButton
                                variant="contained"
                                secondary
                                onClick={handlePreviewClick}
                              >
                                Preview
                              </ZButton>
                              <ZButton
                                variant="contained"
                                secondary
                                onClick={() => handleEditClick(workflow)}
                              >
                                Edit
                              </ZButton>
                            </div>
                          </div> */}
                          </div>
                        );
                      })}

                      {/* <ZButton
                      secondary
                      variant="contained"
                      onClick={() => {
                        setStep(1);
                        setWorkflows([]);
                        getInfo();
                      }}
                    >
                      {" "}
                      Back{" "}
                    </ZButton> */}
                    </div>
                  )}
                </GPTModalBody>
              )}
            </>
          )}
        </GPTDiv>
      </CurlModal>
      {showPreviewModal && <Preview />}
    </>
  );
};

export default ZorpGPTNew;
