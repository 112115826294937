//@ts-nocheck
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from 'react';
import { Controller } from "react-hook-form";
import { FaCalendarAlt } from "react-icons/fa";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { MonitorTextField } from "../Monitor.styles";
interface IDateComponent {
  control: any;
  value: dayjs.Dayjs;
  dateOnChange: (dateValue:any)=> void;
  name: string;
}

const DateComponent = ({control, value, dateOnChange, name}:IDateComponent) => {
  return (
    <Controller
    control={control}
    name={name}
    render={() => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          value={value}
          slotProps={{ textField: { size: "small",fullWidth:true } }}
          components={{
            OpenPickerIcon: () => <FaCalendarAlt size={15} />,
          }}
          PopperProps={{
            sx: {
              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  color: "#ffffff !important",
                },
              },
              "& .css-3eghsz-PrivatePickersYear-button": {
                "&.Mui-selected": {
                  color: "#ffffff !important",
                },
              },
            },
          }}
          inputFormat="DD/MM/YYYY"
          onChange={dateOnChange}
          renderInput={(params) => (
            <MonitorTextField
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    )}
  />
  )
}

// export default DateComponent
export default React.memo(DateComponent);