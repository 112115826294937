import { FormControl } from '@mui/material';
import React, { useEffect } from 'react';
import { TaskService } from '../services/TaskService';
import Select from 'react-select';


const TaskType = (props) => {
    const [taskTypes, setTaskTypes] = React.useState([]);
    const [selectedTaskType, setSelectedTaskType] = React.useState({});
    const [disabled, setDisabled] = React.useState(true);
    const [size, setSize] = React.useState("100%");
    const previewWorkflowId = localStorage.getItem('previewWorkflowId');

    useEffect(() => {
        setDisabled(props.disabled);
        setSelectedTaskType(props)
        // console.log(">>> selectedTaskType",selectedTaskType)
        switch (props.size) {
            case "small":
                setSize("25%");
                break;
            case "medium":
                setSize("50%");
                break;
            case "full":
                setSize("100%");
                break;
            default:
                setSize("100%");
                break;
        }
    }, [props.type, props.size]);


    //ASK: why there was prop.type in useEffect
    useEffect(() => {

        const taskTypesRes = TaskService.getTaskTypes(null,true)
        taskTypesRes.then((res) => {
            
            // check if current task is part of it, if not add 
            const isTaskTypeNotPresent = (res?.data?.filter(tt => (tt.taskType === props.type)).length === 0);
            if (isTaskTypeNotPresent) {
                res?.data.push({
                    taskType: props.type,
                    taskTypeDisplayName: props.type
                });
            }
            
            let tempTaskType = res?.data?.map(tt => ({...tt, label: tt.taskTypeDisplayName,value:tt.taskType}))

            if(previewWorkflowId) {
                // remove all task types whose label is not previewWorkflowId
                const selctedArr = tempTaskType.filter(tt => tt.taskType === previewWorkflowId)
                tempTaskType = [...selctedArr]
            }
            setTaskTypes([...tempTaskType]);
            setSelectedTaskType(props || '')
        })
    }, []);


    const handleChange = (event) => {
        console.log(">>>> e", event)
        setSelectedTaskType(event);
        props.onChange(event);
    };

    return (
        <FormControl style={{ minWidth: `${size}` }}>
            <Select
                // value={taskTypes.find((c) => c.value === previewWorkflowId)}
                isDisabled={disabled}
                onChange={handleChange}
                
                // style={{ minWidth: "300px" }}
                options={taskTypes}
                isSearchable
            />
               
            
            {/* <FormHelperText>Select task type</FormHelperText> */}
        </FormControl>
    )

}

export default TaskType;