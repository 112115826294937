//@ts-nocheck
import { FunctionComponent, useEffect } from "react";
import { Controller } from "react-hook-form";
import { IZComponentPropType } from "render-engine/models/ITaskTypeWebBodyRenderModel";
import Select from "react-select";
import {
  ColSpan4,
  RedText,
  TaskFormLabelForCreateAndEdit,
} from "render-engine/render.styles";
import { useDispatch } from "react-redux";
import {
  setInEditState,
  setTaskData,
} from "render-engine/reducers/show/TaskShowReducer";
import useTaskShow from "views/workflows/hooks/useTaskShow";

const ZBooleanV2: FunctionComponent<IZComponentPropType> = ({
  config,
  onChange,
  control,
  setValue,
  getValues,
}) => {
  const { convertDataTypeToString } = useTaskShow();
  const options = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];

  const val = convertDataTypeToString(`${config.key}` || "");
  const dispatch = useDispatch();

  useEffect(() => {
    const initialValue = val || config.value;
    const valueToBeSetToReactHookForm = initialValue ?? true;
    setValue(config.key, valueToBeSetToReactHookForm);
  }, []);

  const customStyles = {
    container: (provided) => ({ ...provided, width: "100%" }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#101828",
        fontSize: "12px",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        border: "1px solid #D0D5DD",
        fontSize: "12px",
        borderRadius: "6px",
        backgroundColor: "none", // change background color of the pill
      };
    },
    multiValueLabel: (styles) => {
      return {
        ...styles,
        fontSize: "12px",
        color: "#101828", // change text color inside the pill
      };
    },
    singleValue: (provided) => ({
      ...provided,
      fontSize: "12px", // added fontSize here
      marginRight: "3px",
      marginLeft: "0px",
      boxSizing: "border-box",
      marginBottom: "0px",
    }),
    control: (provided) => ({
      ...provided,
      minHeight: "27px",
      height: "27px",
      // padding: "4px 6px", // added padding here
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "2px 2px 3px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      marginTop: "3px",
      marginBottom: "3px",
    }),
  };

  const handleBlur = () => {
    console.log("on blur", getValues());
    dispatch(setInEditState({ nodeId: config.key, inEditState: false }));
    // dispatch(
    //   setTaskData({ nodeId: config.key, data: getValues()[`${config.key}`] })
    // )
  };

  const isVisible = config?.isVisible || true;

  return (
    <>
      {isVisible && (
        <ColSpan4>
          {config?.label?.isVisible && (
            <TaskFormLabelForCreateAndEdit
              fontSize={config?.label?.fontSize ? config.label.fontSize : 14}
              color={config?.label?.color ? config.label.color : "black"}
              className="col-span-1 font-medium"
              style={{ padding: "20px 0px 4px 0px" }}
            >
              {config?.label?.val}
              {config?.isRequired && <RedText>*</RedText>}
            </TaskFormLabelForCreateAndEdit>
          )}
          <Controller
            name={config?.key}
            defaultValue={true}
            control={control}
            // rules={{
            //   required: config?.isRequired ? config.isRequired : false,
            // }}
            render={({ field: { onChange: fieldOnChange, value } }) => (
              <Select
                styles={customStyles}
                value={options.find((c) => c.value === value)}
                // isClearable
                isSearchable={false}
                className="react-dropdown"
                classNamePrefix="dropdown"
                onBlur={handleBlur}
                onChange={(selectedOption: any) => {
                  fieldOnChange(selectedOption?.value);
                  onChange(config.key, selectedOption?.value);
                  dispatch(
                    setTaskData({
                      nodeId: config.key,
                      data: getValues()[`${config.key}`],
                    })
                  );
                }}
                options={options}
              />
            )}
          />
        </ColSpan4>
      )}
    </>
  );
};

export default ZBooleanV2;
