export enum NodeTypes {
    "DECISION_NODE" = "zorpDecisionNode",
    "STATE_NODE" = "zorpState",
    "TRANSITION" = "zorpEdge"
} 


export type ZElements = {
    nodes: any[],
    edges: any[]
}