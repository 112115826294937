//@ts-nocheck
import { ZButton } from "views/commonComponents/commonComponents.styles"
import { ZActionButton } from "render-engine/render.styles"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { TabPanelProps } from "@mui/lab"
import { AiFileRIcon, AutomationRIcon, ClockRIcon, ReplyRIcon } from "assests"
import { ComponentType } from "components/ComponentTypeEnum"
import { RiArrowDropDownLine as DropDown } from "react-icons/ri"
import React, { memo, useEffect, useState } from "react"
import { userDecodedData } from "utils/UserAuth.utils";
import {
  IRecordAction,
  IRecordRenderConfigRow,
  IRecordRenderConfigSection,
  IRecordRenderConfigTab,
} from "./models/ITaskTypeWebRenderModel";
import {
  BlockLabel,
  BlockDiv,
  BlockContainer,
  BlockHeader,
} from "./render.styles";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import {
  initRecordCreateMetaData,
  initRecordShowMetaData,
} from "./reducers/show/TaskShowReducer";
import { BuilderService } from "providers/data/services/BuilderService";
import styled, { useTheme } from "styled-components"
import { AiOutlinePlus, AiOutlineSetting } from "react-icons/ai"
import { ZComponentV2 } from "components/common/ZComponentV2"
import { BsThreeDotsVertical } from "react-icons/bs"
import { CurlModal } from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents"
import CreateTabModal from "./show/CreateTabModal"
import CreateSectionModal from "./show/CreateSectionModal"
import { Menu, MenuItem } from "@szhsin/react-menu"
import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"
import { FaChevronDown } from "react-icons/fa"
import { IButtonActionType } from "views/entities/types"
import { EntityService } from "providers/data/services/EntityService"
import { useParams } from "react-router-dom"
import { isString } from "lodash"
import notification from "notifications/notifications"
import { TbExternalLink } from "react-icons/tb"
import useTaskShow from "views/workflows/hooks/useTaskShow"
import { BiEdit } from "react-icons/bi"
import useQuery from "hooks/useQuery"
import { useNavigate } from "react-router-dom"
import { initTaskCreateMetaData, initTaskShowMetaData } from "./reducers/show/TaskShowReducer"
import { useDispatch } from "react-redux"
import { IoMdArrowDown, IoMdArrowUp } from "react-icons/io"
import { Flex } from "utils/DeletionConfirmationDialog"
import LoadingScreen from "views/LoadingScreen/LoadingScreen"
import useAccessPermissions from "hooks/useAccessPermissions"
import { ResourceTypeConstant } from "views/entities/permissions/types"
import DynamicTable from "./DynamicTable"

type Props = {
  renderConfig: IRecordRenderConfigTab[];
  componentParams: any;
  setShowComments?: (showComments: boolean) => void;
  setShowRecordLogs: any;
  setShowSummary?: any;
  setHideInfoPanel: any;
  entityCategory?: string;
  entityId?: string;
};

const GridContainer = styled.div<{ newRow: any; row: any }>`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: ${(props) =>
    props.newRow?.[0]?.type === "RELATION_SHOW" &&
    (props.row?.[0]?.shape !== "USER" && props.row?.[0]?.shape !== "TEAM")
      ? `repeat(1, minmax(0, 1fr))`
      : props.newRow?.[0]?.type === ComponentType.LIST
      ? `repeat(1, 1fr)`
      : props.newRow?.[0]?.type === "RELATION" &&
      (props.row?.[0]?.shape !== "USER" && props.row?.[0]?.shape !== "TEAM")
      ? `repeat(1, 1fr)`
      : `repeat(2, 1fr)`};
  gap: 1rem 0.25rem;
  margin-bottom: 1rem;
  align-items: baseline;

  @media (min-width: 768px) {
    grid-template-columns: ${(props) =>
      props.newRow?.[0]?.type === "RELATION_SHOW" &&
      (props.row?.[0]?.shape !== "USER" && props.row?.[0]?.shape !== "TEAM")
        ? `repeat(1, minmax(0, 1fr))`
        : props.newRow?.[0]?.type === ComponentType.LIST
        ? `repeat(1, 1fr)`
        : props.newRow?.[0]?.type === "RELATION" &&
        (props.row?.[0]?.shape !== "USER" && props.row?.[0]?.shape !== "TEAM")
        ? `repeat(1, 1fr)`
        : `repeat(${props.newRow?.length * 2 || 0}, 1fr)`};
    gap: 1rem 7rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 1024px) {
    grid-template-columns: ${(props) =>
      props.newRow?.[0]?.type === "RELATION_SHOW" &&
      (props.row?.[0]?.shape !== "USER" && props.row?.[0]?.shape !== "TEAM")
        ? `repeat(1, minmax(0, 1fr))`
        : props.newRow?.[0]?.type === ComponentType.LIST
        ? `repeat(1, minmax(0, 1fr))`
        : props.newRow?.[0]?.type === "RELATION" &&
        (props.row?.[0]?.shape !== "USER" && props.row?.[0]?.shape !== "TEAM")
        ? `repeat(1, minmax(0, 1fr))`
        : `repeat(${props.newRow?.length * 4 || 0}, 1fr)`};
    gap: 1rem 4rem;
    margin-bottom: 1rem;
  }
`;

const IconText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #344054;
`;

const StyledButton = styled.button`
  display: flex;
  padding: 2px 10px;
  align-items: center;
  border-radius: 16px;
  border: 1.5px solid #3c69e7;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconWrapper = styled.div`
  padding: 4px;
  border-radius: 4px;
  // on hover
  &:hover {
    background: #f2f4f7;
  }
  margin-top: 2px;
  background: ${(props:any) => props.selected ? "#f2f4f7" : "transparent"};
`;

function CustomTabPanel(props: TabPanelProps) {
  const { entityCategory, children, value, index, ...other } = props;
  const mode = window.location.href.includes("create")
    ? "create"
    : window.location.href.includes("transit")
    ? "transit"
    : "show";

  let height = "";
  if (entityCategory === "TASK") {
    if (mode === "show") height = "calc(100vh - 140px)";
    else height = "calc(100vh - 225px)";
  } else {
    height = "calc(100vh - 108px)";
  }

  return (
    <div
      role="tabpanel"
      //   hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <div
        style={{
          padding: "16px",
          height,
          overflow: "auto",
        }}
      >
        <div>{children}</div>
      </div>
    </div>
  );
}

const ShowPageTabsComp = ({
  renderConfig,
  componentParams,
  setShowComments,
  setShowRecordLogs,
  setShowSummary,
  setHideInfoPanel,
  entityCategory = "TABLE",
  entityId,
}: Props) => {
  const [sections, setSections] = useState(null);
  const [showCreateTabModal, setShowCreateTabModal] = useState(false);
  const [showCreateSectionModal, setShowCreateSectionModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const maxTabsToShow = 4;
  const { accountId = "" } = userDecodedData();
  const [tabModified, setTabModified] = useState(false);
  const [editTabDetails, setEditTabDetails] = useState(null);
  const [editSectionDetails, setEditSectionDetails] = useState(null);
  const { isDataEdited, taskShowLayoutMetadata } = useTaskShow();
  const [sectionType, setSectionType] = useState();
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(() => {
    const tabId = query.get("tabId");
    const isSectionPresent = renderConfig?.find(
      (tab) => tab?.tabId === tabId
    )?.sections;
    if (tabId && isSectionPresent) {
      setSections(renderConfig?.find((tab) => tab?.tabId === tabId)?.sections);
      return tabId;
    } else {
      setSections(renderConfig?.[0]?.sections);
      return renderConfig?.[0]?.tabId;
    }
  });
  const { recordId, tableType, taskId, taskType } = useParams();

  const accessPermissions = useAccessPermissions({ resourceType: ResourceTypeConstant.TABLE })

  useEffect(() => {
    // if (accessPermissions.add_comment && setShowComments) {
    //   setShowComments(true)
    // }
    // else if(setShowComments) {
    //   setShowComments(false)
    // }
  }, [accessPermissions])

  const onMenuItemClick = async (action: IRecordAction) => {
    try {
      if (action.action.value === IButtonActionType.OPEN_URL) {
        if (isString(action.value)) {
          window.open(action.value, "_blank");
        } else {
          notification("error", "Error", "Invalid URL");
        }
      } else {
        const data = {
          recordId,
          tableType,
          buttonId: action.key,
        };
        await EntityService.executeEntityColumnAutomation(data);
        notification("success", "Success", "Action executed successfully");
        window.location.reload();
      }
    } catch (error) {
      notification("error", "Error", "Error while executing action");
    }
  };

  const theme = useTheme();

  const handleTabChange = (
    event: React.SyntheticEvent | null,
    newValue: string | null,
    tabId?: string
  ) => {
    setLoading(true);
    const newTabValue = tabId || newValue;
    if (!newTabValue) return;

    setValue(newTabValue);
    // get the tab from the renderConfig.tabs
    const tab = renderConfig?.find((tab) => tab?.tabId === newTabValue);
    if (tab) {
      setSections([]);
      setTimeout(() => {
        setSections(tab?.sections);
        const mode = window.location.href.includes("create")
          ? "create"
          : "show";
        const resource = window.location.href.includes("task")
          ? "task"
          : "table";
        if (resource === "table") {
          if (mode === "show") {
            navigate(
              `/table/${tableType}/show/${recordId}?tabId=${newTabValue}`
            );
          } else {
            navigate(`/table/create/${tableType}?tabId=${newTabValue}`);
          }
        } else {
          if (mode === "show") {
            navigate(`/task/${taskType}/show/${taskId}?tabId=${newTabValue}`);
          }
        }

        setLoading(false);
      }, 1);
    } else {
      setSections([]);
    }
    setLoading(false);
  };

  const handleEditTab = (tabDetails: any) => {
    setTabModified(true);
    setEditTabDetails(tabDetails);
    setShowCreateTabModal(true);
  };

  const handleEditSection = (sectionDetails: any) => {
    setEditSectionDetails(sectionDetails);
    if (!accessPermissions.manage_section) return;
    setShowCreateSectionModal(true);
    setSectionType("SECTION");
  };

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const renderDropdownButton = () => (
    <Flex
      style={{ cursor: "pointer", paddingBottom: "9.8px" }}
      onClick={() => setShowDropdown(!showDropdown)}
    >
      <StyledButton>{renderConfig.length - maxTabsToShow} More</StyledButton>
      <DropDown />
    </Flex>
  );

  const onSectionMove = async (
    sectionId: string,
    direction: string,
    currIndex: string
  ) => {
    setLoading(true);
    const tab = renderConfig?.find((tab) => tab?.tabId === value);
    let sections = tab?.sections;
    if (sections) {
      sections = [...sections]; // create a copy of the sections array
      const sectionIndex = sections.findIndex(
        (section) => section?.sectionId === sectionId
      );
      if (sectionIndex > -1) {
        // move the section
        if (direction === "UP") {
          // check if sectionIndex - 1 is sectionType: COMMENT
          if (sections[sectionIndex - 1]?.sectionType === "COMMENT") {
            // check if sectionIndex-1 is not the first section
            if (sectionIndex - 1 === 0) {
              return;
            }
            // ignore the Comment section and replace sectionIndex with sectionIndex - 2
            const sectionToMove = sections[sectionIndex];
            sections.splice(sectionIndex, 1);
            sections.splice(sectionIndex - 2, 0, sectionToMove);
          } else {
            // move up
            const sectionToMove = sections[sectionIndex];
            sections.splice(sectionIndex, 1);
            sections.splice(sectionIndex - 1, 0, sectionToMove);
          }
        } else {
          // check if sectionIndex + 1 is sectionType: COMMENT
          if (sections[sectionIndex + 1]?.sectionType === "COMMENT") {
            // check if sectionIndex+1 is not the last section
            if (sectionIndex + 1 === sections.length - 1) {
              return;
            }
            // ignore the Comment section and replace sectionIndex with sectionIndex + 2
            const sectionToMove = sections[sectionIndex];
            sections.splice(sectionIndex, 1);
            sections.splice(sectionIndex + 2, 0, sectionToMove);
          } else {
            // move down
            const sectionToMove = sections[sectionIndex];
            sections.splice(sectionIndex, 1);
            sections.splice(sectionIndex + 1, 0, sectionToMove);
          }
        }
      }
      //update the tab
      const data = {
        ...tab,
        sections,
      };
      // update the tab in the renderConfig
      const newRenderConfig = [...renderConfig];
      newRenderConfig[currIndex] = data;
      // update taskShowLayoutMetadata
      const newTaskShowLayoutMetadata = {
        ...taskShowLayoutMetadata,
      };
      // get this tab
      const tabInTaskShowLayoutMetadata = newTaskShowLayoutMetadata?.tabs?.find(
        (tab) => tab?.tabId === value
      );
      if (tabInTaskShowLayoutMetadata) {
        let sections = [...tabInTaskShowLayoutMetadata?.sections];

        // create a map of section ids to their indices in data.sections
        const sectionOrder = new Map(
          data.sections.map((section, index) => [section.sectionId, index])
        );

        // sort the sections
        sections.sort((a, b) => {
          const aIndex = sectionOrder.get(a.sectionId);
          const bIndex = sectionOrder.get(b.sectionId);

          // if a section is not in data.sections, assign it a high index so it comes last
          if (aIndex === undefined) return 1;
          if (bIndex === undefined) return -1;

          // compare the indices to determine the order
          return aIndex - bIndex;
        });

        const newTab = { ...tabInTaskShowLayoutMetadata, sections };

        // find the index of tabInTaskShowLayoutMetadata in newTaskShowLayoutMetadata.tabs
        const newTabs = newTaskShowLayoutMetadata.tabs.map((tab) =>
          tab.tabId === tabInTaskShowLayoutMetadata.tabId ? newTab : tab
        );
        if (resource === "table") {
          if (mode === "show") {
            // replace the old tab with the new one
            newTaskShowLayoutMetadata.tabs = newTabs;
            await EntityService.updateTableLayout({
              tableType,
              mode: "show",
              layout: newTaskShowLayoutMetadata,
            });
            // notification("success", "Success", "Section updated successfully")
            dispatch(
              initRecordShowMetaData({
                tableType,
                recordId,
              })
            );
          } else if (mode === "create") {
            // replace the old tab with the new one
            newTaskShowLayoutMetadata.tabs = newTabs;
            await EntityService.updateTableLayout({
              tableType,
              mode: "create",
              layout: newTaskShowLayoutMetadata,
            });
            // notification("success", "Success", "Section updated successfully")
            dispatch(
              initRecordCreateMetaData({
                tableType,
              })
            );
          }
        } else {
          if (mode === "show") {
            // replace the old tab with the new one
            newTaskShowLayoutMetadata.tabs = newTabs;
            await BuilderService.updateTaskRenderLayout({
              taskType,
              mode: "show",
              layout: newTaskShowLayoutMetadata,
            });
            // notification("success", "Success", "Section updated successfully")
            dispatch(
              initTaskShowMetaData({
                taskId,
              })
            );
          } else if (mode === "create") {
            // replace the old tab with the new one
            newTaskShowLayoutMetadata.tabs = newTabs;
            await BuilderService.updateTaskRenderLayout({
              taskType,
              mode: "create",
              layout: newTaskShowLayoutMetadata,
            });
            // notification("success", "Success", "Section updated successfully")
            dispatch(
              initTaskCreateMetaData({
                taskType,
              })
            );
          }
        }
      }

      setLoading(false);
    }
  };

  const dataEdited = isDataEdited();

  const sectionsWithouComment = sections?.filter(
    (section) => section.sectionType !== "COMMENT"
  );

  // const handleCreateTab = () => {
  //   setTabModified(false)
  //   setShowCreateTabModal(true)
  //   setEditTabDetails(null)
  // }

  const style = {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "11px",
    minHeight: "43px",

    "&.Mui-selected": {
      color: `${theme.color.primary} !important`,
    },
  };

  const onEditSectionRelationClick = (sectionDetails: any) => {
    setSectionType("RELATION");
    setEditSectionDetails(sectionDetails);
    if (!accessPermissions.manage_section) return;
    setShowCreateSectionModal(true);
  };

  const mode = window.location.href.includes("create")
    ? "create"
    : window.location.href.includes("transit")
    ? "transit"
    : "show";
  const resource = window.location.href.includes("task") ? "task" : "table";

  const filteredSections = sections?.filter(
    (section) => section.sectionType !== "COMMENT"
  );

  return (
    renderConfig && (
      <>
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <div
              style={{
                borderBottom: 1,
                borderColor: "divider",
              }}
              className="showPageTabs"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  borderBottom: "1px solid #E0E0E0",
                }}
              >
                <Tabs
                  value={value}
                  aria-label="basic tabs example"
                  onChange={handleTabChange}
                  TabIndicatorProps={{
                    style: { background: theme.color.primary },
                  }}
                  sx={{
                    marginLeft: "24px",
                  }}
                >
                  {renderConfig
                    ?.slice(0, maxTabsToShow)
                    .map((tab: IRecordRenderConfigTab) => (
                      <Tab
                        key={tab.tabId}
                        value={tab.tabId}
                        sx={style}
                        disableRipple
                        label={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{
                                marginRight: "10px",
                                textTransform: "none",
                              }}
                            >
                              {tab.label}
                            </span>
                            {mode !== "transit" &&
                              accessPermissions.manage_section && (
                                <AiOutlineSetting
                                  size={"12px"}
                                  color={theme.color.primary as string}
                                  onClick={() => {
                                    if (!accessPermissions.manage_section)
                                      return;
                                    handleEditTab(tab);
                                  }}
                                />
                              )}
                          </div>
                        }
                      />
                    ))}

                  {mode !== "transit" && accessPermissions.manage_section && (
                    <IconWrapper
                      style={{ display: "grid", placeItems: "center" }}
                      onClick={() => setShowCreateTabModal(true)}
                    >
                      <AiOutlinePlus size="12px" strokeWidth={2} />
                    </IconWrapper>
                  )}
                </Tabs>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                    padding: "0px 24px",
                  }}
                >
                  {renderConfig?.length > maxTabsToShow && (
                    <>
                      <Menu
                        menuClassName="my-menu"
                        direction="bottom"
                        menuButton={renderDropdownButton()}
                        transition
                        gap={12}
                        align="start"
                        menuStyle={{ width: "max-content" }}
                      >
                        {renderConfig
                          ?.slice(maxTabsToShow)
                          .map((tab, index) => (
                            <MenuItem key={tab.tabId}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: "10px",
                                    textTransform: "none",
                                  }}
                                  onClick={() =>
                                    handleTabChange(null, null, tab.tabId)
                                  }
                                >
                                  {tab.label}
                                </span>
                                <AiOutlineSetting
                                  size={"12px"}
                                  color={theme.color.primary as string}
                                  onClick={() => handleEditTab(tab)}
                                />
                              </div>
                            </MenuItem>
                          ))}
                      </Menu>
                    </>
                  )}
                  {mode === "show" && (
                    resource === "task" || (
                      resource === "table" && accessPermissions.add_comment
                    ))
                  && (
                    <>
                      {setShowComments && (
                        <IconWrapper
                          onClick={() => {
                            if(resource === "table" && !accessPermissions.add_comment) return;
                            setShowComments &&
                              setShowComments((prev: any) => !prev);
                            setShowSummary && setShowSummary(false);
                            setShowRecordLogs && setShowRecordLogs(false);
                            setHideInfoPanel && setHideInfoPanel(false);
                          }}
                          
                        >
                          <ReplyRIcon />
                        </IconWrapper>
                      )}

                      <IconWrapper
                        onClick={() => {
                          setShowComments && setShowComments(false);
                          setShowSummary && setShowSummary(false);
                          setShowRecordLogs &&
                            setShowRecordLogs(true);
                          setHideInfoPanel && setHideInfoPanel(false);
                        }}
                        
                      >
                        <ClockRIcon />
                      </IconWrapper>
                      {
                        resource === "task" && (taskType === "1wls9v" || taskType === "sxyn3q") && (
                          <IconWrapper
                            onClick={() => {
                              setShowRecordLogs &&
                                setShowRecordLogs(false);
                              setHideInfoPanel && setHideInfoPanel(false);
                              setShowComments && setShowComments(false);
                              setShowSummary && setShowSummary(true);
                            }}
                            
                          >
                          <AiFileRIcon />
                          </IconWrapper>
                        )
                      }
                      
                    </>
                  )}
                </div>
              </div>

              {
                // const filteredSections = sections?.filter((section) => section.sectionType !== "COMMENT")
                sections && (
                  <CustomTabPanel
                    sx={{ height: "90%" }}
                    value={value}
                    entityCategory={entityCategory}
                  >
                    {tableType === 'yccpj1' && accountId === 'sed4qz' && <DynamicTable />}
                    {sections
                      ?.filter((section) => section.sectionType !== "COMMENT")
                      ?.map(
                        (
                          section: IRecordRenderConfigSection,
                          sectionIndex: number
                        ) =>
                          section?.sectionType === "COMMENT" ? (
                            <> </>
                          ) : (
                            <BlockContainer>
                              {section?.sectionType === "RELATION" ? (
                                <></>
                              ) : (
                                <BlockHeader>
                                  <BlockLabel hidden={false}>
                                    {section?.label}
                                    {mode !== "transit" && (
                                      <BiEdit
                                        style={{
                                          display: "inline",
                                          marginLeft: "8px",
                                        }}
                                        onClick={() =>
                                          handleEditSection(section)
                                        }
                                        size="14px"
                                        color="#667085"
                                      />
                                    )}
                                  </BlockLabel>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      gap: "8px",
                                    }}
                                  >
                                    {section?.actions?.length > 1 ? (
                                      <Menu
                                        menuClassName="my-menu"
                                        active
                                        direction="bottom"
                                        menuButton={
                                          <ZButton
                                            endIcon={
                                              <FaChevronDown size={"1rem"} />
                                            }
                                            secondary
                                            variant="contained"
                                          >
                                            {" "}
                                            Actions{" "}
                                          </ZButton>
                                        }
                                        transition
                                        // direction="left"
                                        gap={12}
                                        align="end"
                                      >
                                        {section?.actions?.map(
                                          (action: IRecordAction) => {
                                            return (
                                              <MenuItem
                                                disabled={dataEdited}
                                                onClick={() =>
                                                  onMenuItemClick(action)
                                                }
                                                className="menuItem-s"
                                              >
                                                <IconWrapper>
                                                  {action.action.value ===
                                                  IButtonActionType.OPEN_URL ? (
                                                    <TbExternalLink
                                                      size="1.5rem"
                                                      color="#334053"
                                                    />
                                                  ) : (
                                                    <AutomationRIcon color="#667085" />
                                                  )}
                                                </IconWrapper>
                                                <IconText>
                                                  {" "}
                                                  {action.displayName}{" "}
                                                </IconText>
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                      </Menu>
                                    ) : (
                                      section?.actions?.length === 1 && (
                                        <ZButton
                                          // endIcon={<FaChevronDown size={"1rem"} />}
                                          secondary
                                          variant="contained"
                                          onClick={() =>
                                            onMenuItemClick(
                                              section?.actions?.[0]
                                            )
                                          }
                                        >
                                          {section?.actions?.[0]?.displayName ||
                                            "Action"}
                                        </ZButton>
                                      )
                                    )}
                                    {mode !== "transit" && (
                                      <Menu
                                        menuClassName="my-menu"
                                        active
                                        direction="bottom"
                                        menuButton={
                                          <IconWrapper>
                                            <BsThreeDotsVertical
                                              size="12px"
                                              strokeWidth={1}
                                            />
                                          </IconWrapper>
                                        }
                                        transition
                                        // direction="left"
                                        gap={12}
                                        align="start"
                                      >
                                        <MenuItem
                                          onClick={() =>
                                            onSectionMove(
                                              section?.sectionId,
                                              "UP",
                                              sectionIndex
                                            )
                                          }
                                          className="menuItem-s"
                                          disabled={sectionIndex === 0}
                                        >
                                          <IconWrapper>
                                            <IoMdArrowUp size="14px" />
                                          </IconWrapper>
                                          <IconText> Move Up </IconText>
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            onSectionMove(
                                              section?.sectionId,
                                              "DOWN",
                                              sectionIndex
                                            )
                                          }
                                          className="menuItem-s"
                                          disabled={
                                            sectionIndex ===
                                            filteredSections?.length - 1
                                          }
                                        >
                                          <IconWrapper>
                                            <IoMdArrowDown size="14px" />
                                          </IconWrapper>
                                          <IconText> Move Down </IconText>
                                        </MenuItem>
                                      </Menu>
                                    )}
                                  </div>
                                </BlockHeader>
                              )}

                              <BlockDiv
                                padding={
                                  section?.sectionType === "RELATION"
                                    ? "0px"
                                    : "16px"
                                }
                              >
                                {section?.rows?.map(
                                  (row: IRecordRenderConfigRow[]) => {
                                    const newRow = [...row];

                                    // add an empty node if row length is odd
                                    newRow.length % 2 !== 0 &&
                                      newRow.push({
                                        type: ComponentType.EMPTY,
                                        label: "",
                                        isActive: false,
                                        isVisible: false,
                                        rowId: "",
                                        nodes: [],
                                        isDeleted: false,
                                      });

                                    // if (row?.[0]?.type === COMMENT) RETURN <> </> else return the below

                                    return (
                                      <GridContainer row={row} newRow={newRow}>
                                        {
                                          // if row length is odd, add one empty node

                                          newRow?.map((node: any) => {
                                            return (
                                              <ZComponentV2
                                                config={{
                                                  ...node,
                                                  section: section,
                                                  sectionIndex: sectionIndex,
                                                  isFirst: sectionIndex === 0,
                                                  isLast:
                                                    sectionIndex ===
                                                    filteredSections?.length -
                                                      1,
                                                  onSectionMove: (
                                                    dir: string
                                                  ) =>
                                                    onSectionMove(
                                                      section?.sectionId,
                                                      dir,
                                                      sectionIndex
                                                    ),
                                                  setShowCreateSectionModal:
                                                    () =>
                                                      onEditSectionRelationClick(
                                                        section
                                                      ),
                                                }}
                                                {...componentParams}
                                              />
                                            );
                                          })
                                        }
                                      </GridContainer>
                                    );
                                  }
                                )}
                              </BlockDiv>
                            </BlockContainer>
                          )
                      )}
                    {mode !== "transit" && accessPermissions.manage_section && (
                      <Menu
                        menuClassName="my-menu"
                        direction="bottom"
                        menuButton={
                          <ZActionButton> + Add Section </ZActionButton>
                        }
                        transition
                        // direction="left"
                        gap={12}
                        align="start"
                      >
                        <MenuItem
                          onClick={() => {
                            if (!accessPermissions.manage_section) return;
                            setSectionType("SECTION");
                            setShowCreateSectionModal(true);
                          }}
                          className="menuItem-s"
                        >
                          <IconWrapper>
                            <AiOutlinePlus size="12px" />
                          </IconWrapper>
                          <IconText> Add section </IconText>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setSectionType("RELATION");
                            setShowCreateSectionModal(true);
                          }}
                          className="menuItem-s"
                        >
                          <IconWrapper>
                            <AiOutlinePlus size="12px" />
                          </IconWrapper>
                          <IconText>
                            {resource === "table" ? "Add relation" : "Add list"}
                          </IconText>
                        </MenuItem>
                      </Menu>
                    )}
                  </CustomTabPanel>
                )
              }
            </div>
            {showCreateTabModal && (
              <CurlModal
                open={showCreateTabModal}
                onClose={() => {
                  setEditTabDetails(null);
                  setShowCreateTabModal(false);
                }}
              >
                <CreateTabModal
                  editTabDetails={editTabDetails}
                  onCancel={() => {
                    setEditTabDetails(null);
                    setShowCreateTabModal(false);
                  }}
                />
              </CurlModal>
            )}
            {showCreateSectionModal && accessPermissions.manage_section && (
              <CurlModal
                open={showCreateSectionModal}
                onClose={() => {
                  setEditSectionDetails(null);
                  setShowCreateSectionModal(false);
                }}
              >
                <CreateSectionModal
                  tabId={value}
                  onCancel={() => {
                    setEditTabDetails(null);
                    setEditSectionDetails(null);
                    setShowCreateSectionModal(false);
                  }}
                  editSectionDetail={editSectionDetails}
                  sectionType={sectionType}
                />
              </CurlModal>
            )}
          </>
        )}
        {/* <div style={{ width: "100%" }}> */}
      </>
    )
  );
};

export const ShowPageTabs = memo(ShowPageTabsComp);