//@ts-nocheck
import { ResourceContextProvider } from "react-admin";
import TaskList from "./TaskList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "views/reducer";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import {useLocation} from 'react-router-dom'

const FilteredTaskList = () => {
  const dispatch = useDispatch();
  //@ts-ignore
  const [loading, setLoading] = useState(true)
  const {search} = useLocation()
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }, [search]);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <ResourceContextProvider value="tasks">
          <TaskList
            basePath="/tasks"
            hasCreate={true}
            hasEdit={true}
            hasList={true}
            hasShow={true}
            permissions={{}}
          />
        </ResourceContextProvider>
      )}
    </>
  );
};

export default FilteredTaskList;
