import React from 'react'
import { TaskFormInput, TaskFormLabelForShow } from 'render-engine/render.styles'
import styled from 'styled-components'

type Props = {
    label: string,
    value: string
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem; /* space-x-4 in Tailwind CSS */
  padding-right: 0.5rem; /* pr-2 in Tailwind CSS */
`;

const UserDisplayBlock = ({label,value}: Props) => {
  return (
    <div >
          <TaskFormLabelForShow>{label}</TaskFormLabelForShow>
          <StyledDiv>
            <TaskFormInput>  {value} </TaskFormInput>
          </StyledDiv>
        </div>
  )
}

export default UserDisplayBlock