import { IAutomation } from "@zorp/common-libs-js/dist/common/types/types";
import { Permission, PermissionConfiguration, PermissionsListType } from "./permissions/types";

export interface IInitState {
  entities: ITable[] | undefined;
  selectedEntity: ITable | null;
  showCreateEntityModal: boolean;
  entityPermissions: Permission[];
  finalEntityPermissions: Permission[];
  permissionConfiguration: PermissionConfiguration[];
  showColumnCreationModal: boolean;
  savedViewChanges: savedViewChanges;
  selectedViewId: string;
  activeCreateColumn: any;
  selectedRow: any[];
  activeEditColumn: any;
  rowsAfterAnyOps: [];
  selectedEntityMetadata: IselectedMetadata | null;
  entityAutomations: any[];
  selectedActionColumn: IColdDefsInternal | null;
  selectedEntityData?: {
    rowDefs: IRowDefs[];
    colDefs: IColDefs[];
  } | null;
  showEntityEditModal: boolean;
  showTableAppConfigCard: boolean;
}

export type savedViewChanges = {
  [viewId: string]: ViewState
}

export type IselectedMetadata = {
  tableMetadata: ITableAutomations | null;
  customFields: customFieldType[];
  permissions: PermissionsListType[];
}

export type customFieldType = {
  name: string;
  type: string;
  isCreateTimeField: boolean;
  displayName: string;
  otherDetails?: {
    isDisplayColumn?: boolean
  }
  relationDetails?: {
    cardinality?: "ONE" | "MANY";
    referringEntity?: string;
    referringEntityType?: string;
    manageRelatedEntities?: boolean;
    createReferenceColumnInTarget?: boolean;
    relaredEntities?: {columns: string[]; tableType: string}[]
  }
  description?: string;
  isRequired?: boolean;
  isDeleted?: boolean;
}

export type ViewState = {
  columnFilters?: { id: string, value: unknown }[],
  sorting?: any[],
  columnFilterFns?: {
    [id: string]: string
  },
  pagination?: {
    pageIndex: number,
    pageSize: number
  },
  columnPinning: { left: string[], right: string[] },
  columnVisibility?: {
    [id: string]: boolean
  },
  columnOrdering?: string[],
  globalFilter?: string,
  showGlobalFilter?: boolean,
  showColumnFilters?: boolean
}

export interface ITableAutomations {
  automations: {
    systemAutomation: {
      uiAutomations: IAutomation[];
      serverAutomations: any[];
    };
    actions: {
      [key: string]: {
        uiAutomations: IAutomation[];
        serverAutomations: any[];
      };
    };
  };
  mockData?: any;
  recordTypeLabel?: string
}

export enum TableRSEvents {
  ADD_NEW_TABLE_DROPDOWN_OPTION_CLICKED = "ADD_NEW_TABLE_DROPDOWN_OPTION_CLICKED",
  ADD_NEW_TABLE_BUTTON_CLICKED = "ADD_NEW_TABLE_BUTTON_CLICKED",
  ADD_NEW_TABLE_MODAL_CLOSE = "ADD_NEW_TABLE_MODAL_CLOSE",
  ADD_NEW_TABLE_MODAL_SUBMIT = "ADD_NEW_TABLE_MODAL_SUBMIT",
  CREATE_NEW_TABLE_SUCCESS = "CREATE_NEW_TABLE_SUCCESS",
  CREATE_NEW_TABLE_FAILURE = "CREATE_NEW_TABLE_FAILURE",
  ADD_NEW_RECORD_BUTTON_CLICKED = "ADD_NEW_RECORD_BUTTON_CLICKED",
  ADD_NEW_RECORD_MODAL_CLOSE = "ADD_NEW_RECORD_MODAL_CLOSE",
  ADD_NEW_RECORD_MODAL_SUBMIT = "ADD_NEW_RECORD_MODAL_SUBMIT",
  CREATE_NEW_RECORD_SUCCESS = "CREATE_NEW_RECORD_SUCCESS",
  CREATE_NEW_RECORD_FAILURE = "CREATE_NEW_RECORD_FAILURE",
  CREATE_NEW_COLUMN_BUTTON_CLICKED = "CREATE_NEW_COLUMN_BUTTON_CLICKED",
  CREATE_NEW_COLUMN_MODAL_CLOSE = "CREATE_NEW_COLUMN_MODAL_CLOSE",
  CREATE_NEW_COLUMN_MODAL_SUBMIT = "CREATE_NEW_COLUMN_MODAL_SUBMIT",
  CREATE_NEW_COLUMN_SUCCESS = "CREATE_NEW_COLUMN_SUCCESS",
  CREATE_NEW_COLUMN_FAILURE = "CREATE_NEW_COLUMN_FAILURE",
  EDIT_COLUMN_BUTTON_CLICKED = "EDIT_COLUMN_BUTTON_CLICKED",
  TABLE_ROW_CLICK = "TABLE_ROW_CLICK",
  COLUMN_FILTER_CHANGE_BUTTON_CLICKED = "COLUMN_FILTER_CHANGE_BUTTON_CLICKED",
  TABLE_AUTOMATION_BUTTON_CLICKED = "TABLE_AUTOMATION_BUTTON_CLICKED",
  DELETE_SINGLE_RECORD_BUTTON_CLICKED = "DELETE_SINGLE_RECORD_BUTTON_CLICKED",
  DELETE_SINGLE_RECORD_SUCCESS = "DELETE_SINGLE_RECORD_SUCCESS",
  DELETE_SINGLE_RECORD_FAILURE = "DELETE_SINGLE_RECORD_FAILURE",
  EDIT_SINGLE_RECORD_BUTTON_CLICKED = "EDIT_SINGLE_RECORD_BUTTON_CLICKED",
  EDIT_SINGLE_RECORD_SUCCESS = "EDIT_SINGLE_RECORD_SUCCESS",
  EDIT_SINGLE_RECORD_FAILURE = "EDIT_SINGLE_RECORD_FAILURE",
  BULK_IMPORT_BUTTON_CLICKED = "BULK_IMPORT_BUTTON_CLICKED",
  BULK_IMPORT_RECORDS_SUCCESS = "BULK_IMPORT_BUTTON_SUCCESS",
  BULK_IMPORT_RECORDS_FAILURE = "BULK_IMPORT_BUTTON_FAILURE",
  BULK_IMPORT_RECORDS_CANCEL = "BULK_IMPORT_BUTTON_CANCEL",
  BULK_DELETE_RECORDS_BUTTON_CLICKED = "BULK_DELETE_RECORDS_BUTTON_CLICKED",
  BULK_DELETE_RECORDS_SUCCESS = "BULK_DELETE_RECORDS_SUCCESS",
  BULK_DELETE_RECORDS_FAILURE = "BULK_DELETE_RECORDS_FAILURE",
  BULK_DELETE_RECORDS_CANCEL = "BULK_DELETE_RECORDS_CANCEL",
  ROW_LEVEL_AUTOMATION_BUTTON_CLICKED = "ROW_LEVEL_AUTOMATION_BUTTON_CLICKED",
  ROW_LEVEL_AUTOMATION_BUTTON_SUCCESS = "ROW_LEVEL_AUTOMATION_BUTTON_CLICKED_SUCCESS",
  ROW_LEVEL_AUTOMATION_BUTTON_FAILURE = "ROW_LEVEL_AUTOMATION_BUTTON_CLICKED_FAILURE",
  DOWNLOAD_TEMPLATE_CSV_BUTTON_CLICKED = "DOWNLOAD_TEMPLATE_CSV_BUTTON_CLICKED",
  EXPORT_AS_CSV_BUTTON_CLICKED = "EXPORT_AS_CSV_BUTTON_CLICKED",
  DELETE_TABLE = "DELETE_TABLE",
  DELETE_COLUMN_SUCCESS = "DELETE_COLUMN_SUCCESS",
  DELETE_COLUMN_FAILURE = "DELETE_COLUMN_FAILURE",
}

export enum TablePermissionRsEvents {
  // Share Resource
  SHARE_RESOURCE_BUTTON_CLICKED = "SHARE_RESOURCE_BUTTON_CLICKED",
  SHARE_RESOURCE_ACL_PERMISSIONS_fETCHED_BUTTON_CLICKED = "SHARE_RESOURCE_ACL_PERMISSIONS_fETCHED_BUTTON_CLICKED",
  SHARE_RESOURCE_ACL_PERMISSIONS_fETCHED_SUCCESS = "SHARE_RESOURCE_ACL_PERMISSIONS_fETCHED_SUCCESS",
  SHARE_RESOURCE_ACL_PERMISSIONS_fETCHED_FAILURE = "SHARE_RESOURCE_ACL_PERMISSIONS_fETCHED_FAILURE",
  SHARE_RESOURCE_MODAL_CLOSE = "SHARE_RESOURCE_MODAL_CLOSE",
  SHARE_RESOURCE_PERMISSION_LEVEL_CHANGE = "SHARE_RESOURCE_PERMISSION_LEVEL_CHANGE",
  SHARE_RESOURCE_SELECTED_USERS_VALUE_CHANGED = "SHARE_RESOURCE_SELECTED_USERS_VALUE_CHANGED",
  SHARE_RESOURCE_EDIT_MODE_SAVE_BUTTON_CLICKED = "SHARE_RESOURCE_EDIT_MODE_SAVE_BUTTON_CLICKED",
  SHARE_RESOURCE_CONFIRM_BUTTON_CLICKED = "SHARE_RESOURCE_CONFIRM_BUTTON_CLICKED", //If user tries to delete his own permissions then confirm button appears.
  SHARE_RESOURCE_CANCEL_BUTTON_CLICKED = "SHARE_RESOURCE_CANCEL_BUTTON_CLICKED",
  SHARE_RESOURCE_SAVE_BUTTON_CLICKED = "SHARE_RESOURCE_SUBMIT_BUTTON_CLICKED",
  SHARE_RESOURCE_SUCCESS = "SHARE_RESOURCE_SUCCESS",
  SHARE_RESOURCE_FAILURE = "SHARE_RESOURCE_FAILURE",
  SHARE_RESOURCE_MANAGE_ACCESS_BUTTON_CLICKED = "SHARE_RESOURCE_MANAGE_ACCESS_BUTTON_CLICKED",

  //Manage Permissions Modal
  MANAGE_PERMISSIONS_CLOSE_BUTTON_CLICKED = "MANAGE_PERMISSIONS_CLOSE_BUTTON_CLICKED",
  OPEN_MANAGE_PERMISSIONS_TAB_BUTTON_CLICKED = "MANAGE_ACCESS_TAB_CHANGED_BUTTON_CLICKED",
  OPEN_MANAGE_ACCESS_TAB_BUTTON_CLICKED = "OPEN_MANAGE_ACCESS_TAB_BUTTON_CLICKED",

  // Manage Access Tab
  MANAGE_ACCESS_EDIT_PERMISSION_LEVEL_CHANGE = "MANAGE_ACCESS_EDIT_PERMISSION_LEVEL_CHANGE",//WE CAN PASS FROM WHICH PERMISSION LEVEL TO WHICH PERMISSION LEVEL IT CHANGED
  MANAGE_ACCESS_PERMISSION_LEVEL_CHANGE_MERGED = "MANAGE_ACCESS_PERMISSION_LEVEL_CHANGE_MERGED", //It contains the merged options
  MANAGE_ACCESS_ADD_USER_BUTTON_CLICKED = "MANAGE_ACCESS_ADD_USER_BUTTON_CLICKED",
  MANAGE_ACCESS_EDIT_PERMISSION_BUTTON_CLICKED = "MANAGE_ACCESS_EDIT_PERMISSION_CLICKED",
  SHARE_RESOURCE_OPENED_IN_EDIT_MODE = "SHARE_RESOURCE_OPENED_IN_EDIT_MODE",
  MANAGE_ACCESS_DELETE_PERMISSION_BUTTON_CLICKED = "MANAGE_ACCESS_DELETE_PERMISSION_CLICKED",//WE CAN PASS WHICH PERMISSION WE DELETED
  MANAGE_ACCESS_DELETE_OWN_PERMISSIONS_TRIGGERED = "MANAGE_ACCESS_DELETE_OWN_PERMISSIONS_TRIGGERED",
  MANAGE_ACCESS_SAVE_BUTTON_CLICKED = "MANAGE_ACCESS_SAVE_BUTTON_CLICKED",
  MANAGE_ACCESS_CANCEL_BUTTON_CLICKED = "MANAGE_ACCESS_TAB_CANCEL_BUTTON_CLICKED",
  MANAGE_ACCESS_SUCCESS = "MANAGE_ACCESS_SUCCESS",
  MANAGE_ACCESS_FAILURE = "MANAGE_ACCESS_FAILURE",

  // Manage Permissions Tab
  UNCHECKED_PERMISSION_CLICKED = "UNCHECKED_PERMISSION_CLICKED",
  UNCHECKED_PERMISSION_SUCCESS = "UNCHECKED_PERMISSION_SUCCESS",
  UNCHECKED_PERMISSION_FAILURE = "UNCHECKED_PERMISSION_FAILURE",
  CHECKED_PERMISSION_CLICKED = "CHECKED_PERMISSION_CLICKED", //ADD ALL THE DEPENDENT PERMISSION WHICH ARE ALSO CHECKED DUE TO THIS
  CANCEL_PERMISSION_CHANGES_BUTTON_CLICKED = "CANCEL_PERMISSION_CHANGES_BUTTON_CLICKED",
  MANAGE_PERMISSIONS_CHANGING_OWN_PERMISSIONS_TRIGGERED = "MANAGE_PERMISSIONS_CHANGING_OWN_PERMISSIONS_TRIGGERED",
  SAVE_PERMISSION_CHANGES_BUTTON_CLICKED = "SAVE_PERMISSION_CHANGES_BUTTON_CLICKED",
  SAVE_PERMISSION_CHANGES_SUCCESSFUL = "SAVE_PERMISSION_CHANGES_SUCCESSFUL",
  SAVE_PERMISSION_CHANGES_FAILURE = "SAVE_PERMISSION_CHANGES_FAILURE",

  // Configured Permission
  ADD_NEW_PERMISSION_BUTTON_CLICKED = "ADD_NEW_PERMISSION_BUTTON_CLICKED",
  ADD_NEW_PERMISSION_NAME_SAVE_BUTTON_CLICKED = "ADD_NEW_PERMISSION_NAME_SAVE_BUTTON_CLICKED",
  ADD_NEW_PERMISSION_SUCCESSFUL = "ADD_NEW_PERMISSION_SUCCESSFUL",
  ADD_NEW_PERMISSION_FAILURE = "ADD_NEW_PERMISSION_FAILURE",
  MANAGE_PERMISSIONS_SELECTED_PERMISSION_CHANGED = "MANAGE_PERMISSIONS_SELECTED_PERMISSION_CHANGED",
  ADD_NEW_PERMISSION_NAME_CANCEL_BUTTON_CLICKED = "ADD_NEW_PERMISSION_NAME_CANCEL_BUTTON_CLICKED",
  SELECTED_PERMISSION_CONFIG_CHANGED = "SELECTED_PERMISSION_CONFIG_CHANGED",
}

export enum ColumnPermissionsRsEvents {
  ADD_COLUMN_PERMISSIONS_BUTTON_CLICKED = "COLUMN_PERMISSIONS_BUTTON_CLICKED",
  COLUMN_PERMISSIONS_SAVE_BUTTON_CLICKED = "SAVE_COLUMN_PERMISSIONS_BUTTON_CLICKED",
  COLUMN_PERMISSIONS_CANCEL_BUTTON_CLICKED = "CANCEL_COLUMN_PERMISSIONS_BUTTON_CLICKED",
  COLUMN_PERMISSIONS_FETCH_BUTTON_CLICKED = "COLUMN_PERMISSIONS_FETCH_BUTTON_CLICKED",
  COLUMN_PERMISSIONS_FETCH_SUCCESS = "COLUMN_PERMISSIONS_FETCH_SUCCESS",
  COLUMN_PERMISSIONS_FETCH_FAILURE = "COLUMN_PERMISSIONS_FETCH_FAILURE",
  COLUMN_PERMISSIONS_SAVE_SUCCESS = "SAVE_COLUMN_PERMISSIONS_SUCCESSFUL",
  COLUMN_PERMISSIONS_SAVE_FAILURE = "SAVE_COLUMN_PERMISSIONS_FAILURE",
  COLUMN_PERMISSION_CLOSE_MODAL_BUTTON_CLICKED = "CLOSE_COLUMN_PERMISSION_MODAL_BUTTON_CLICKED",
}

export enum TaskRSEvents {
  ADD_NEW_TASK_DROPDOWN_OPTION_CLICKED = "ADD_NEW_TASK_DROPDOWN_OPTION_CLICKED",
  ADD_NEW_TASK_BUTTON_CLICKED = "ADD_NEW_TASK_BUTTON_CLICKED",
  ADD_NEW_TASK_MODAL_CLOSE = "ADD_NEW_TASK_MODAL_CLOSE",
  DELETE_SINGLE_TASK_BUTTON_CLICKED = "DELETE_SINGLE_TASK_BUTTON_CLICKED",
}

export enum ViewsRSEvents {
  ADD_NEW_VIEW_BUTTON_CLICKED = "ADD_NEW_VIEW_BUTTON_CLICKED",
  CREATE_NEW_VIEW_BUTTON_CLICKED = "CREATE_NEW_VIEW_BUTTON_CLICKED",
  CREATE_VIEW_SUCCESS = "CREATE_VIEW_SUCCESS",
  CREATE_VIEW_FAILURE = "CREATE_VIEW_FAILURE",
  CREATE_UPDATE_VIEW_MODAL_CLOSED = "CREATE_UPDATE_VIEW_MODAL_CLOSE",
  DELETE_VIEW_BUTTON_CLICKED = "DELETE_VIEW_BUTTON_CLICKED",
  DELETE_VIEW_SUCCESS = "DELETE_VIEW_SUCCESS",
  DELETE_VIEW_FAILURE = "DELETE_VIEW_FAILURE",
  UPDATE_VIEW_BUTTON_CLICKED = "UPDATE_VIEW_BUTTON_CLICKED",
  UPDATE_VIEW_SUCCESS = "UPDATE_VIEW_SUCCESS",
  UPDATE_VIEW_FAILURE = "UPDATE_VIEW_FAILURE",
  RESET_VIEW_BUTTON_CLICKED = "RESET_VIEW_BUTTON_CLICKED",
  TABLE_STATE_CHANGE_DETECTED = "TABLE_STATE_CHANGE_DETECTED",
  TABLE_STATE_CHANGE_NOT_DETECTED = "TABLE_STATE_CHANGE_NOT_DETECTED",
  SAVE_THIS_VIEW_BUTTON_CLICKED = "SAVE_THIS_VIEW_BUTTON_CLICKED",
  SAVE_THIS_VIEW_SUCCESS = "SAVE_THIS_VIEW_SUCCESS",
  SAVE_THIS_VIEW_FAILURE = "SAVE_THIS_VIEW_FAILURE",
  SWITCHED_TO_DIFFERENT_VIEW = "SWITCHED_TO_DIFFERENT_VIEW",
  URL_UPDATE_FOR_NEW_VIEW = "URL_UPDATE_FOR_NEW_VIEW",
  DUPLICATE_VIEW_BUTTON_CLICKED = "DUPLICATE_VIEW_BUTTON_CLICKED",
  DUPLICATE_VIEW_SUCCESS = "DUPLICATE_VIEW_SUCCESS",
  DUPLICATE_VIEW_FAILURE = "DUPLICATE_VIEW_FAILURE",
  CREATE_OR_UPDATE_VIEW_INVALID_INPUTS = "CREATE_OR_UPDATE_VIEW_INVALID_INPUTS",
}

export interface ITable {
  tableType: string;
  tableDisplayName: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  entityDef?: any;
  defaultView?: string;
}

// "type": "BUTTON",
//                             "dataType": "BUTTON",
//                             "name": "Button",
//                             "displayName": "Button UI",
//                             "buttonLabel": "Recall",
//                             "properties": {
//                                 "properties": {
//                                     "type": "Secondary Outlined"
//                                 }
//                             }
export interface IColDefs {
  field: string;
  label: string;
  id: string;
  isVisible?: boolean
  data: any;
  props: any | string;
  colDef: IColdDefsInternal;
  type?: string;
}

export enum IButtonActionType {
  RUN_AUTOMATION = "RUN_AUTOMATION",
  OPEN_URL = "OPEN_URL",

}

export interface IColdDefsInternal {
  type: string;
  dataType: string;
  displayName: string;
  name: string;
  buttonLabel?: string;
  properties: any;
  uiAutomations: IAutomation[];
  serverAutomations: any[];
  displayColumn?: string;
  relationDetails?: any;
}

// accountId: "x4bkmy";
//   createdOn: "2023-06-20T09:38:19.543Z";
//   data: { entityType: "f35x09"; data: { Age: 124 } };
//   entityId: "bkpkjd";
//   entityType: "f35x09";
//   isDeleted: false;
//   updatedOn: "2023-06-20T12:45:09.012Z";
//   version: 1;
//   _id: "6491738bc2c5452c8ef6326f";

// covert to interface
export interface IRowDefs {
  accountId: string;
  createdOn: Date;
  data: {
    tableType: string;
    data: {
      [key: string]: any;
    };
  };
  recordId: string;
  tableType: string;
  isDeleted: boolean;
  tableName: string;
  updatedOn: Date;
  version: number;
  _id: string;
}

export interface IListingView {
  accountId: string;
  tableType: string;
  isActive: boolean;
  mode: string;
  version: number;
  _id: string;
  body: IListingBody;
}

export interface IListingBody {
  colDef: { headers: IColDefs[] };
  rowDef: IRowDefs[];
}

export enum IRelationReferringEntityType {
  CUSTOM = "CUSTOM",
  TASK = "TASK",
  USER = "USER",
  TEAM = "TEAM",
}
