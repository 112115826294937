//@ts-nocheck
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';
import { TEXT } from "constants/CommonConstants";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import { useJSEditor } from "lowcode/hooks/useJSEditor";
import { EditorScriptLocation } from "lowcode/state/types/types";
import getInputType from "lowcode/util/getInputType";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { removeBraces } from "utils/CommonUtils";
import { getLowCodeEditorDefaultReturnText, getLowCodeEditorText } from "utils/Utils";
import { DataFieldType, IComponentType } from "views/DataField/DataField.types";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import usePayload from "views/automationBuilder/hooks/usePayload";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import { Heading, HeadingWrapper, Label } from "views/workflows/workflowactions/ModalContent.styles";
import IsActive from "views/workflows/workflowactions/api/components/IsActive";
import ModalSmartInputTextField from "views/workflows/workflowactions/api/components/ModalSmartInputTextField";
import { IIsTempIdUniqueContext, ValidLocation, getActionNameForTempId, saveAction } from "views/workflows/workflowactions/api/utils/utils";
import { setSelectedAction } from "../../../reducers/workflowAction";
import DatafieldSelector from "../../DatafieldSelector";
import InputTempId from 'views/automationBuilder/components/InputTempId';
import { generateTempId, isTempIdUnique } from "../utils/utils";

import ErrorMessageInput from "../components/ErrorMessageInput";
const ComponentVariableTree = () => {
    const dispatch = useDispatch();

    const [exitOnError, setExitOnError] = useState(false);
    const [selectedTeamId, setSelectedTeamId] = useState<string>('');
    const [isEditing, setIsEditing] = useState(false);
    const [tempIdChanged, setTempIdChanged] = useState(true);
    const [userIdDropdownValue, setUserIdDropdownValue] = useState<DataFieldType | IComponentType>(IComponentType.DROPDOWN);

    const { selectedActionBlock, selectedAutomation, clearRightSide, teamsList, usersList } = useAutomation();
    const { openJSEditorLayout, scriptEditorOpen } = useJSEditor();
    const { selectedAction, getScriptValue, dataPicker } = useWorkFlow();
    const { actionConditionPayload } = usePayload();
    const [currTempIdVal, setCurrTempIdVal] = useState(selectedAction?.tempId || "");
    const actionExecutionScriptConfig = {
        UPDATE_TEAM_USERID: {
            script:
                getScriptValue(EditorScriptLocation.UPDATE_TEAM_USERID) || getLowCodeEditorText(EditorScriptLocation.UPDATE_TEAM_USERID, DataFieldType.TEXT, `return ${getLowCodeEditorDefaultReturnText(DataFieldType.TEXT)}`),
            dataType: TEXT
        },
        UPDATE_TEAM_TEAMIDS: {
            script:
                getScriptValue(EditorScriptLocation.UPDATE_TEAM_TEAMIDS) || getLowCodeEditorText(EditorScriptLocation.UPDATE_TEAM_TEAMIDS, DataFieldType.LIST_OF_TEXT, `return ${getLowCodeEditorDefaultReturnText(DataFieldType.LIST_OF_TEXT)}`),
            dataType: DataFieldType.LIST_OF_TEXT
        },
        errorMessageConfig: {
            script:
                getScriptValue(EditorScriptLocation.ERROR_MESSAGE) || getLowCodeEditorText(EditorScriptLocation.ERROR_MESSAGE, DataFieldType.TEXT, `return ${getLowCodeEditorDefaultReturnText(DataFieldType.TEXT)}`),
            dataType: DataFieldType.TEXT
        }
    };

    useEffect(() => {
        setExitOnError(selectedAction?.exitOnError ?? true)
    }, [selectedAction?.exitOnError]);

    useEffect(() => {
        if (selectedAction?.[EditorScriptLocation.UPDATE_TEAM_TEAMIDS]?.value) {
            setSelectedTeamId(selectedAction?.[EditorScriptLocation.UPDATE_TEAM_TEAMIDS].value);
        }
    }, [])

    useEffect(() => {
        if (selectedAction?.[EditorScriptLocation.UPDATE_TEAM_TEAMIDS]?.value?.includes('${') && selectedAction?.[EditorScriptLocation.UPDATE_TEAM_TEAMIDS].value?.endsWith('}') || selectedAction?.[EditorScriptLocation.UPDATE_TEAM_TEAMIDS]?.type == 'script') {
            setUserIdDropdownValue(DataFieldType.TEXT);
        } else {
            setUserIdDropdownValue(IComponentType.DROPDOWN);
        }
    }, [selectedAction[EditorScriptLocation.UPDATE_TEAM_TEAMIDS]?.value, selectedAction[EditorScriptLocation.UPDATE_TEAM_TEAMIDS]?.value])


    const handleSave = async (data, event) => {
        const actionName = selectedAction?.actionName;
        let tempActionId: string = selectedAction?.tempId || data?.tempId;
        if (!isEditing) {
            tempActionId = currTempIdVal.length === 0 ? (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId) : currTempIdVal;
            dispatch(setSelectedAction({ ...selectedAction, tempId: currTempIdVal }));
        }

        let isTempIdChanged = tempIdChanged;
        if (tempActionId === (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId)) {
            setTempIdChanged(false);
            isTempIdChanged = false;
        }

        if (isTempIdChanged && !isTempIdUnique(tempActionId, selectedAutomation)) {
            setError("tempId", {
                type: "manual",
                message: "This ID already exists. Please choose a different one.",
            });
            return;
        }

        const userId = selectedAction[EditorScriptLocation.UPDATE_TEAM_USERID];
        // value is TeamId
        const value = selectedAction[EditorScriptLocation.UPDATE_TEAM_TEAMIDS];
        const errorMessage = selectedAction[EditorScriptLocation.ERROR_MESSAGE];
        const isActive = selectedAction?.isActive ?? true;

        if (!value?.value) {
            setError(EditorScriptLocation.UPDATE_TEAM_TEAMIDS, {
                type: "manual",
                message: "This is a required field",
            });
            return;
        }

        const payload = {
            actionName,
            actionId: tempActionId,
            tempId: tempActionId,
            userId: Object.keys(userId || {}).length == 0 ? null : userId, // here userId is optional, so when the userId returns empty object , we change it to null
            value, // teamId
            isActive,
            actionType: selectedAction.actionType,
            errorMessage,
            exitOnError
        }

        saveAction({
            payload,
            location: ValidLocation.AUTOMATION,
            dispatch,
            selectedAction,
            selectedAutomation,
            selectedActionBlock
        });


        clearRightSide();
    };

    const updateTeamActionDefaultValue = {
        actionName: selectedAction?.actionName,
        tempId: generateTempId(selectedAction, selectedAutomation, getActionNameForTempId(selectedAction), "action"),
        [EditorScriptLocation.UPDATE_TEAM_TEAMIDS]: selectedAction?.[EditorScriptLocation.UPDATE_TEAM_TEAMIDS],
        [EditorScriptLocation.UPDATE_TEAM_USERID]: selectedAction?.[EditorScriptLocation.UPDATE_TEAM_USERID],
        isActive: selectedAction?.isActive ?? true,
    }

    const { register, handleSubmit, control, setValue, setError, clearErrors, getValues, formState: { errors } } = useForm({ defaultValues: updateTeamActionDefaultValue });


    const handleErrorMessageChange = (e) => {
        dispatch(
            setSelectedAction({
                ...selectedAction,
                hasChanged: true,
                [EditorScriptLocation.ERROR_MESSAGE]: {
                    type: getInputType(e.target.value),
                    value: removeBraces(e.target.value),
                    dataType: TEXT,
                },
            })
        );
    };

    const handleTempActionValChange = (name, value) => {
        setCurrTempIdVal(value);
    }

    const handleEditClick = () => {
        setIsEditing(true);
        setTempIdChanged(true);
    };

    const handleTickClick = () => {
        setIsEditing(false);
    };

    const setTempIdError = (error) => {
        if (error) {
            setError("tempId", { type: "manual", message: error });
        } else {
            clearErrors("tempId");
        }
    };

    // get width of the div, and pass this to the 
    let getElementHTML = document.getElementById('update-team') as HTMLElement;
    const getErrorMessageWidth = getElementHTML?.offsetWidth - 58;

    return (
        <>
            <form onSubmit={handleSubmit(handleSave)} style={{ display: 'flex', flexDirection: 'column', height: '100%' }} id='update-team'>
                <HeadingWrapper>
                    <Heading>
                        {" "}
                        Reassign This Task To a Different User or Team
                    </Heading>
                    <IsActive control={control} closeOnClick={clearRightSide} />
                </HeadingWrapper>
                <Label> Name</Label>
                <ModalSmartInputTextField
                    value={selectedAction?.actionName}
                    control={control}
                    placeholder="Action Name"
                    register={register}
                    errors={errors}
                    name="actionName"
                />

                <InputTempId
                    label="Action Id"
                    onChange={handleTempActionValChange}
                    register={register}
                    requiredErrorMessage="Action Id is required"
                    errors={errors}
                    isReadOnly={!isEditing}
                    handleTickClick={handleTickClick}
                    getValues={getValues}
                    setTempIdError={setTempIdError}
                    handleEditClick={handleEditClick}
                    isEditing={isEditing}
                    name="tempId"
                />


                <Label className='mt-2'>Team</Label>
                <ModalSmartInputTextField
                    type={selectedAction[EditorScriptLocation.UPDATE_TEAM_TEAMIDS]?.type}
                    dataType={IComponentType.DROPDOWN}
                    valueDataType={DataFieldType.TEXT}
                    isSmartField={true}
                    control={control}
                    hideDataField={true}
                    minWidth='95%'
                    avoidOverflow={false}
                    errors={errors}
                    options={teamsList}
                    register={register}
                    getValues={getValues}
                    onChangeFunc={(options: any) => {

                        setSelectedTeamId(options.value);

                        dispatch(setSelectedAction({
                            ...selectedAction,
                            [EditorScriptLocation.UPDATE_TEAM_TEAMIDS]: {
                                type: 'literal',
                                value: options.value,
                                dataType: DataFieldType.TEXT
                            },
                            [EditorScriptLocation.UPDATE_TEAM_USERID]: {}
                        }));

                        // clearing the user Selected Value
                        setValue(EditorScriptLocation.UPDATE_TEAM_USERID, "");

                    }}
                    setError={setError}
                    clearErrors={clearErrors}
                    apiBodyPayload={actionConditionPayload}
                    value={selectedAction[EditorScriptLocation.UPDATE_TEAM_TEAMIDS]?.value}
                    placeholder="Enter TeamId's"
                    openJSEditor={openJSEditorLayout}
                    name={EditorScriptLocation.UPDATE_TEAM_TEAMIDS}
                    config={actionExecutionScriptConfig.UPDATE_TEAM_TEAMIDS}
                    editorLocation={EditorScriptLocation.UPDATE_TEAM_TEAMIDS}
                />




                <Label className='mt-2'>User</Label>
                <ModalSmartInputTextField
                    type={selectedAction[EditorScriptLocation.UPDATE_TEAM_USERID]?.type || 'literal'}
                    dataType={userIdDropdownValue}
                    valueDataType={DataFieldType.TEXT}
                    isSmartField={true}
                    minWidth='95%'
                    control={control}
                    hideDataField={true}
                    step="any"
                    avoidOverflow={false}
                    isClearable
                    errors={errors}
                    options={usersList?.filter((userListData) => userListData.data.teamIds == selectedTeamId)}
                    onChangeFunc={(options: any) => {
                        if (options) {
                            dispatch(setSelectedAction({
                                ...selectedAction,
                                [EditorScriptLocation.UPDATE_TEAM_USERID]: {
                                    type: 'literal',
                                    value: options.value,
                                    dataType: DataFieldType.TEXT
                                }
                            }));
                        } else {
                            setValue(EditorScriptLocation.UPDATE_TEAM_USERID, "");
                            dispatch(setSelectedAction({
                                ...selectedAction,
                                [EditorScriptLocation.UPDATE_TEAM_USERID]: null
                            }));
                        }
                    }}
                    register={register}
                    getValues={getValues}
                    setError={setError}
                    clearErrors={clearErrors}
                    apiBodyPayload={actionConditionPayload}
                    value={selectedAction[EditorScriptLocation.UPDATE_TEAM_USERID]?.value}
                    placeholder="Enter UserId"
                    openJSEditor={openJSEditorLayout}
                    name={EditorScriptLocation.UPDATE_TEAM_USERID}
                    config={actionExecutionScriptConfig.UPDATE_TEAM_USERID}
                    editorLocation={EditorScriptLocation.UPDATE_TEAM_USERID}
                />



                <ErrorMessageInput
                    label={`Error Message to be shown if the Reassign fails`}
                    onChangeFunc={handleErrorMessageChange}
                    register={register}
                    errors={errors}
                    width={getErrorMessageWidth ? getErrorMessageWidth + 'px' : '90%'}
                    apiBodyPayload={actionConditionPayload}
                    openJSEditorLayout={openJSEditorLayout}
                    config={actionExecutionScriptConfig.errorMessageConfig}
                />

                <div style={{ marginTop: '10px', padding: '11px' }}>
                    <FormControlLabel
                        control={
                            <input
                                type="checkbox"
                                style={{ height: '13px', width: '13px' }}
                                checked={exitOnError}
                                onChange={(value) => {
                                    setExitOnError((currentValue) => !currentValue)
                                }}
                                value="one"
                            />
                        }
                        label={<Typography variant='h6' style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>Exit Transition On Error</Typography>}
                    />
                </div>

                {dataPicker?.isOpened && <DatafieldSelector setValue={setValue} />}
                {scriptEditorOpen && <ZJSEditorLayout />}
            </form>

        </>
    )
}

export const UpdateTeamAction = React.memo(ComponentVariableTree);