import React from "react";
import styled from "styled-components";
import { InputProps, SwitchProps } from "./interfaces/ZSwitch.types";
import { Label as TextLabel } from "views/workflows/workflowactions/ModalContent.styles";

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  label > button {
    border: none !important;
  }
`;

const SwicthLabel = styled(TextLabel)`
  color: var(--Gray-700, #344054);

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const Switch = styled.div<SwitchProps>`
  position: relative;
  width: ${(props) => (props.size === "small" ? "48px" : "")};
  height: ${(props) => (props.size === "small" ? "21px" : "")};
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "")};

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: ${(props) => (props.size === "small" ? "18px" : "")};
    height: ${(props) => (props.size === "small" ? "18px" : "")};
    border-radius: 35px;
    top: 50%;
    left: ${(props) => (props.checked ? "-9px" : "2px")};
    background: white;
    transform: translate(0px, -50%);
  }
`;

const Input = styled.input<InputProps>`
  opacity: 0;
  position: absolute;
  border: none;
  &:checked + ${Switch} {
    background-color: ${(props) => {
      return props.disabled
        ? "#F0F2F3"
        : props.variant === "secondary"
        ? "#616569 !important"
        : props.variant === "tertiary"
        ? `${props.theme.color.primary} !important`
        : props.variant === "danger"
        ? "#ffffff !important"
        : "#ffffff !important";
    }};

    &:before {
      transform: translate(29px, -50%);
      width: ${(props) => (props.width ? props.width : "18px")};
      height: ${(props) => (props.height ? props.height : "18px")};
      left: ${(props) => (props.left ? props.left : "-9px")};
    }
  }
    &:not(:checked) + ${Switch} {
      &:before {
        width: ${(props) => (props.width ? props.width : "18px")};
        height: ${(props) => (props.height ? props.height : "18px")};
        left: 2px;
      }
    }
`;

type Props = {
  checked: boolean;
  onChange: any;
  size?: "small" | "big";
  inputProps?: any;
  inputStyle?: any;
  switchStyle?: any;
  label?: string;
  variant?: "secondary" | "tertiary" | "danger";
  disabled?: boolean;
  onClick?: any;
  labelDirection?: "left" | "right";
};

const ZSwitch = ({
  checked,
  disabled = false,
  onChange,
  size = "small",
  inputProps,
  label,
  inputStyle,
  switchStyle,
  onClick = () => {},
  variant = "tertiary",
  labelDirection = "right",
}: Props) => {
  return (
    <Label>
      {labelDirection === "left" && (
        <SwicthLabel isInline={"inline"}> {label} </SwicthLabel>
      )}
      <Input
        disabled={disabled}
        variant={variant}
        checked={checked}
        type="checkbox"
        {...inputProps}
        onClick={onClick}
        onChange={onChange}
        style={inputStyle}
      />
      <Switch style={switchStyle} disabled={disabled} checked={checked} size={size} />
      {labelDirection === "right" && (
        <SwicthLabel isInline={"inline"}> {label} </SwicthLabel>
      )}
    </Label>
  );
};

export default ZSwitch;
