import { FunctionComponent, useEffect, useState } from "react";
import { ZComponent } from "../components/common/ZComponent";
import {
  ITaskTypeWebBodyBlockRenderModel,
  IRenderComponentParams,
} from "./models/ITaskTypeWebBodyRenderModel";
import { ComponentType } from "components/ComponentTypeEnum";
import {
  BlockContainer,
  BlockDiv,
  BlockHeader,
  BlockLabel,
} from "./render.styles";
import { ZComment } from "components/comment/ZComment";
import { ZTabList } from "../../src/views/commonComponents/commonComponents.styles";
import { Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import RecordLogStepper from "logs/RecordLogStepper";

export const Render: FunctionComponent<{
  renderConfig: ITaskTypeWebBodyBlockRenderModel[] | undefined;
  componentParams: IRenderComponentParams;
  entityCategory?: string;
  entityId?: string;
  entityType?: string;
  showComments?: boolean;
  mode?: string;
}> = ({
  renderConfig,
  componentParams,
  entityCategory,
  entityId,
  entityType,
  showComments = true,
  mode,
}) => {
  const [isCommentEnabled, setIsCommentEnabled] = useState(false);
  const [commentNode, setCommentNode] = useState(null);
  const [updatedRenderConfig, setUpdatedRenderConfig] = useState(renderConfig);
  const [selectedOption, setSelectedOption] = useState<"logs" | "comments">(
    "comments"
  );

  // check if there is block with type COMMENT,
  // if yes, set the
  useEffect(() => {
    let newRenderConfig = [...(renderConfig || [])]; // creating a new array to avoid mutation
    newRenderConfig?.forEach((block, blockIndex) => {
      const isVisible = block?.isVisible ?? true;
      if (isVisible) {
        block?.rows?.forEach((row: any) => {
          row?.columns?.forEach((column: any) => {
            column?.nodes?.forEach((node: any) => {
              if (node?.type === ComponentType.COMMENT) {
                setIsCommentEnabled(true);
                setCommentNode({
                  ...node,
                  entityCategory,
                  entityType,
                  entityId,
                });
                newRenderConfig.splice(blockIndex, 1); // removing the block from the new array
              }
            });
          });
        });
      }
    });
    setUpdatedRenderConfig(newRenderConfig); // setting the state with the new array
  }, [renderConfig, entityType, entityId]);

  const handleOptionChange = (option: "logs" | "comments") => {
    setSelectedOption(option);
  };

  return (
    <>
      <div className="grid grid-cols-4" style={{ height: "100%" }}>
        <div
          className={`${
            isCommentEnabled && showComments ? "col-span-3" : "col-span-4"
          }`}
          style={{
            
            overflowY: "auto",
            maxHeight: "90vh",
          }}
        >
          {updatedRenderConfig?.map((block: any, blockIndex: number) => {
            const isVisible =
              mode === "show" && entityType && blockIndex === 0
                ? false
                : block?.isVisible ?? true;
            return (
              isVisible && (
                <BlockContainer>
                  <BlockHeader>
                    <BlockLabel hidden={!block?.label?.isVisible}>
                      {block?.label?.val}
                    </BlockLabel>
                  </BlockHeader>
                  <BlockDiv>
                    {block?.rows?.map((row: any) => {
                      const className =
                        row?.columns[0]?.nodes[0]?.type === "RELATION_SHOW"
                          ? `grid grid-rows-1 grid-cols-1`
                          : row?.columns[0]?.nodes[0]?.type ===
                              ComponentType.LIST ||
                            row?.columns[0]?.nodes[0]?.shape === "UPLOAD" ||
                            row?.columns[0]?.nodes[0]?.type ===
                              ComponentType.IMAGEVIEWER ||
                            row?.columns[0]?.nodes[0]?.type ===
                              ComponentType["LOCATION-DISPLAY"]
                          ? `grid grid-rows-1 grid-cols-1`
                          : `grid grid-rows-1 lg:grid-cols-${
                              row?.columns?.length * 4 || 0
                            } md:grid-cols-${
                              row?.columns?.length * 2 || 0
                            } sm:grid-cols-2 lg:gap-x-16 md:gap-x-28 sm:gap-x-4 sm:gap-y-8 md:gap-y-4 lg:mb-8 items-baseline md:mb-2`;
                      return (
                        <div className={className}>
                          {row?.columns?.map((column: any) => {
                            return column?.nodes?.map((node: any) => {
                              return (
                                <ZComponent
                                  config={node}
                                  {...componentParams}
                                />
                              );
                            });
                          })}
                        </div>
                      );
                    })}
                  </BlockDiv>
                </BlockContainer>
              )
            );
          })}
        </div>

        {isCommentEnabled && showComments && (
          <div
            style={{
              borderLeft: "1px solid #eaecf0",
              paddingLeft: "0px",
              height: "100%",
            }}
            className="col-span-1"
          >
            <TabContext value={selectedOption}>
              <div
                style={{
                  marginBottom: "0px",
                  display: "flex",
                  paddingLeft: "10px",
                  columnGap: "20px",
                  borderBottom: "1px solid rgb(234, 236, 240)",
                }}
              >
                <ZTabList
                  sx={{
                    marginLeft: "0px",
                  }}
                  onChange={(event, newValue) => handleOptionChange(newValue)}
                >
                  <Tabs
                    value={selectedOption}
                    aria-label="basic tabs example"
                    style={{ gap: "20px" }}
                  >
                    <Tab label="Comments" value="comments" />
                    <Tab label="Logs" value="logs" />
                  </Tabs>
                </ZTabList>
              </div>

              <TabPanel
                value="comments"
                style={{
                  padding: "12px !important",
                  marginLeft: "-6px",
                  marginTop: "-6px",
                }}
              >
                <BlockDiv
                  style={{
                    border: "none",
                    boxShadow: "none",
                    padding: "16px",
                  }}
                >
                  <ZComment config={commentNode}></ZComment>
                </BlockDiv>
              </TabPanel>

              <TabPanel
                value="logs"
                style={{
                  padding: "12px !important",
                  marginLeft: "-6px",
                  marginTop: "-6px",
                }}
              >
                <BlockDiv
                  style={{
                    border: "none",
                    boxShadow: "none",
                    padding: "0px",
                  }}
                >
                  <RecordLogStepper recordId={entityId || ""} />
                </BlockDiv>
              </TabPanel>
            </TabContext>
          </div>
        )}
      </div>
    </>
  );
};
