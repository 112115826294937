//@ts-nocheck
import { FunctionComponent, useState } from "react"
import { UseFormRegister, FieldErrors, UseFormSetValue } from "react-hook-form"
import { useEffect } from "react"
import _ from "lodash"
import { TaskFormLabelForShow } from "render-engine/render.styles"
import ZShowImage from "./ZShowImage"
import { ZComponent } from "components/common/ZComponent"

export const ZImageViewer: FunctionComponent<{
  config: any
  onChange: Function
  register: UseFormRegister<any>
  setValue: UseFormSetValue<any>
  errors: FieldErrors<any>
}> = (props) => {
  // If there is an incoming value, store it in redux store (this is akin to default value)

  const { config, onChange, register, errors, setValue } = props

  useEffect(() => {
    if (config.value) setValue(config.key, config.value)
  }, [])

  const transformConfigValue = () => {
    // in case string is sent by the config, transform it
    if (_.isString(config.value)) {
      // With the current shortcoming on the backend config generator, the list of images comes as "['http://img1', 'http://img2'..]"
      // Handling it on the frontend until we have a better solution
      if (config.value.startsWith("[")) {
        return (
          (config.value?.substring(1, config.value?.length - 1) || "") as string
        )
          .split(",")
          .map((val: string, index: number) => {
            return {
              url: val?.substring(1, val?.length - 1),
              title: `Image ${index + 1}`,
            }
          })
      }
      // else it would be a string joined by ","
      else
        return ((config.value || "") as string)
          .split(",")
          .map((val: string, index: number) => {
            return {
              url: val,
              title: `Image ${index + 1}`,
            }
          })
    }
    // in case of valid array format
    else if (_.isArray(config.value))
      return config.value?.map((val: string, index: number) => {
        return {
          url: val,
          title: `Image ${index + 1}`,
        }
      })
  }

  const handleDoubleClick = () => {
    setEditState((prev) => !prev)
  }

  const linksArray = transformConfigValue() || []

  const [editState, setEditState] = useState<boolean>(false)

  return (
    <>
      {editState ? (
        <ZComponent {...props} config={config.editConfig} />
      ) : (
        <div className="overflow-auto" style={{ marginBottom: "10px" }}>
          <TaskFormLabelForShow style={{ color:"#667085", padding: "20px 0px 4px 0px" }}>
            {config?.label?.val}
          </TaskFormLabelForShow>
          <ZShowImage linksArray={linksArray} />
          
          <p style={{
            fontSize:"12px"
          }}> Upload more </p>

        </div>
      )}
      {/* <p onClick={handleDoubleClick}> Edit </p> */}
      {/* <div className="col-span-4">
        <ZComponent {...props} config={config.editConfig} />
      </div> */}
    </>
  )
}
