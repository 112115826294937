import React, { useEffect } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useNavigate } from 'react-router-dom';
import { ColSpan4, OldLabel, RedText, TaskFormBody, TaskFormInput } from 'render-engine/render.styles';
import { recordRSEvent } from 'utils/CommonUtils';
import { getFullDateHour } from 'utils/DateFunctions.utils';
import { PageCategoryEnum, recordPageVisit } from '../../config/Rudderstack';
import notification from '../../notifications/notifications';
import authProvider from '../../providers/auth/authProvider';
import { RoleService } from '../../providers/data/services/RoleService';
import RoleAcl from './RoleAcl';
import TopBar from 'render-engine/TopBar';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2rem; /* gap-x-8 in Tailwind CSS */

  @media (min-width: 640px) {
    grid-template-columns: repeat(8, 1fr); /* sm:grid-cols-8 in Tailwind CSS */
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 0.25rem; /* px-1 in Tailwind CSS */
  padding-right: 0.25rem; /* px-1 in Tailwind CSS */

  @media (min-width: 768px) {
    padding-left: 0.5rem; /* md:px-2 in Tailwind CSS */
    padding-right: 0.5rem; /* md:px-2 in Tailwind CSS */
  }
`;

const StyledDivFlex = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem; /* rounded-xl in Tailwind CSS */
`;

const RoleEdit = () => {
  const [name, setName] = React.useState('');
  const [roleId, setRoleId] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [createdOn, setCreatedOn] = React.useState('');
  const {id} = useParams();

  const [acl, setAcl] = React.useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.role, 'role_edit');
  }, []);

  useEffect(() => {
    const data = RoleService.getRole(id);
    data
      .then((res) => {
        const role = res?.data;
        setName(role?.name);
        setRoleId(role?.roleId);
        setDescription(role?.description);
        setCreatedOn(getFullDateHour(role?.createdOn));
      })
      .catch((err) => {
        notification('error', 'Error while fetching role details');
        console.log(err);
      });
  }, [id]);

  const handleNameChange = (val) => setName(val);

  const handleDescriptionChange = (val) => setDescription(val);

  const handleAclChange = (val) => setAcl(val);

  const cancelEdit = () => {
    notification('info', 'Role edit cancelled!');
    navigate(`/roles/${id}/show`);
  };

  const constructRoleAclPayload = () => {
    return {
      role: roleId,
      resourceActions: acl,
    };
  };

  const editRole = () => {
    const rolePayLoad = {
      name: name,
      description: description,
    };

    RoleService.updateRole(roleId, rolePayLoad)
      .then((res) => {
        // console.log(res);
        if (res?.code && res?.code.indexOf('200') < 0) {
          // Error
          if (res?.code === '401') {
            notification('error', res.message);
            recordRSEvent('editrole_event_fail', {
              context: 'role_listing',
              role_id: roleId,
              role_name: rolePayLoad.name,
              error: res.message,
            });
            authProvider.logout();
          } else {
            recordRSEvent('editrole_event_fail', {
              context: 'role_listing',
              role_id: roleId,
              role_name: rolePayLoad.name,
              error: res.message,
            });
            notification('error', res.message);
          }
        } else {
          const roleAclDetails = constructRoleAclPayload();
          RoleService.updateRoleAcl(roleAclDetails)
            .then((res) => {
              // console.log(res);
              if (res?.code && res?.code.indexOf('200') < 0) {
                // Error
                if (res?.code === '401') {
                  notification('error', res.message);
                  recordRSEvent('editrole_event_fail', {
                    context: 'role_listing',
                    role_id: roleId,
                    role_name: rolePayLoad.name,
                    error: res.message,
                  });
                  authProvider.logout();
                } else {
                  notification('error', res.message);
                  recordRSEvent('editrole_event_fail', {
                    context: 'role_listing',
                    role_id: roleId,
                    role_name: rolePayLoad.name,
                    error: res.message,
                  });
                }
              } else {
                notification('success', res.message);
                recordRSEvent('editrole_event_sucess', {
                  context: 'role_listing',
                  role_id: roleId,
                  role_name: rolePayLoad.name,
                });
                navigate('/settings/roles');
              }
            })
            .catch((err) => {
              console.log('Error', err);
              // notification("error", "Role could not be saved!");
            });
        }
      })
      .catch((err) => {
        console.log('Error', err);
        // notification("error", "Role could not be saved!");
      });
  };

  return (
    
      <ValidatorForm onSubmit={editRole} onError={(errors) => console.log(errors)}>
        
          <TaskFormBody>
          {/* {hasPermission("role", "edit") && <TopBar title="Edit Role" onCancelText={"Cancel"} onSaveText="Edit" onSave={() => {}} onCancel={cancelEdit}  />} */}
            <TopBar title="Edit Role" onCancelText={"Cancel"} onSaveText="Edit" onSave={() => {}} onCancel={cancelEdit}  />
            <div className='contentWrapper'>
              <GridContainer>
                <ColSpan4>
                  <div
                    className="col-span-1 font-medium"
                    style={{ padding: "20px 0px 4px 0px" }}
                  >
                    Role
                  </div>
                  <TaskFormInput>{roleId}</TaskFormInput>
                </ColSpan4>
                <ColSpan4>
                  <OldLabel>
                      Name <RedText>*</RedText>
                  </OldLabel>
                  <TextValidator
                    autoComplete={false}
                    value={name}
                    variant='outlined'
                    style={{ minWidth: '300px', maxWidth: '300px' }}
                    size='medium'
                    className='inc-t0 oid-wd'
                    onChange={(event) => handleNameChange(event.target.value)}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  />
                </ColSpan4>
              </GridContainer>

              <GridContainer>
                <ColSpan4>
                  <OldLabel>
                      Description
                  </OldLabel>
                  <TextValidator
                    autoComplete={false}
                    value={description}
                    variant='outlined'
                    style={{ minWidth: '300px', maxWidth: '300px' }}
                    size='medium'
                    className='inc-t0 oid-wd'
                    onChange={(event) => handleDescriptionChange(event.target.value)}
                    // validators={['required']}
                    // errorMessages={['This field is required']}
                  />
                </ColSpan4>
                <ColSpan4>
                    <OldLabel>
                        Created On
                    </OldLabel>
                    <TaskFormInput>{createdOn}</TaskFormInput>
                </ColSpan4>
              </GridContainer>
              

              <StyledDiv>
               <StyledDivFlex>
                  
                  <div class='pt-10 mb-4'>
                    <RoleAcl roleId={id} readOnly={false} onChange={handleAclChange} />
                  </div>
                  
                 
                </StyledDivFlex>
              </StyledDiv>
            </div>
          </TaskFormBody>
        
      </ValidatorForm>
    
  );
};

export default RoleEdit;
