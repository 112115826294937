//@ts-nocheck
import React, { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getMiddleXAxisPoint, getNextPoint } from "./edgeUtils";

import {
  EdgeProps,
  EdgeText,
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
  useNodes,
} from "react-flow-renderer";
import { setDeleteIconOnTransition } from "views/workflows/reducers/workflowAction";
import useWorkFlow from "./hooks/useWorkFlow";
// @ts-ignore

const ZEdgeText = styled(EdgeText)`
  &:hover {
  }
`;

const ZorpTransitionEdge = (edgeProps: any) => {
  const {
    id,
    source,
    target,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    arrowHeadType,
    markerEndId,
  } = edgeProps;
  const nodes = useNodes();
  // const allTransitions = useStoreState((state) => state.edges)

  const dispatch = useDispatch();

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const { selectedTransition } = useWorkFlow();
  const sourceNode = useMemo(
    () => nodes.find((n) => n.id === source),
    [source, nodes]
  );
  const targetNode = useMemo(
    () => nodes.find((n) => n.id === target),
    [target, nodes]
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  // If this function return true, we show an edge with different path, so that it doesn't overlap with the node or another transition
  const showCurvedEdge = () => {
    let checkIfThisAnReverseEdge = false;
    allTransitions.forEach((transition) => {
      if (transition.source === target && transition.target === source) {
        checkIfThisAnReverseEdge = true;
      }
    });

    return checkIfThisAnReverseEdge;
  };

  let edgePath = "";
  if (source !== target) {
    edgePath = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetPosition,
      targetX,
      targetY,
    });
  } else {
    const radiusX = (sourceX - targetX) * 0.7;
    const radiusY = 150;
    edgePath = `M ${sourceX} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${targetX} ${targetY}`;
  }

  const markerEnd = getMarkerEnd("arrowclosed", markerEndId);
  console.log("markerEnd", markerEnd);

  let hasError = data?.errors?.length > 0 ? true : false;
  data?.actions?.forEach((item: any) => {
    if (item?.errors?.length > 0) {
      hasError = true;
    }
  });

  data?.guards?.forEach((item: any) => {
    if (item?.errors?.length > 0) {
      hasError = true;
    }
  });

  return (
    <>
      <defs>
        <marker
          id="react-flow__arrowclosed"
          markerWidth="5"
          markerHeight="5"
          refX="5"
          refY="1.7"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d="M0,0 L0,5 L7.5,2.5 z" fill={`${data?.errors?.length > 0 ? "#FF2D55" : "#999999"}`} />
        </marker>
      </defs>
      <path
        style={{
          strokeWidth: "3px",
          stroke: `${data?.errors?.length > 0 ? "#FF2D55" : "#999999"}`,
        }}
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {sourceNode.type !== "zorpDecisionNode" && (
        <>
          <ZEdgeText
            x={source === target ? edgeCenterX + 120 : edgeCenterX}
            y={source === target ? edgeCenterY - 120 : edgeCenterY}
            // label={data?.errors?.length > 0 ? "Error" : data.event}
            // label={hasError ? "Error" : data.event}
            label={data.event === "" ? "Error" : data.event}
            labelStyle={{
              fontSize: 12,
              fill: "#fff",
              padding: "10px 15px 10px 15px",
              borderRadius: "0",
            }}
            // labelShowBg
            labelBgStyle={{
              // fill: data?.errors?.length > 0 ? "red" : '#3E3E3E',
              fill: hasError
                ? "#FF2D55"
                : selectedTransition?.data?.id === data.id
                ? "#007AFF"
                : "#3E3E3E",
              color: "#fff",
              fillOpacity: 0.9,
            }}
            //         labelBgPadding: [8, 4],
            // labelBgBorderRadius: 4,
            // labelBgStyle: { fill: '#FFCC00', color: '#fff', fillOpacity: 0.7 },
            labelBgPadding={[15, 8]}
            labelBgBorderRadius={8}
            // onMouseEnter={(e) => onHover(e, 'enter')}
            // onMouseLeave={(e) => onHover(e, 'leave')}
            // onClick={(e) => window.alert('clicked')}
          />
        </>
      )}
    </>
  );
};

export default React.memo(ZorpTransitionEdge);
