//@ts-nocheck
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import selectColumnMonitor from "assests/select_column_monitor.svg";
import React from 'react';
import { Controller } from "react-hook-form";
import { MdKeyboardArrowDown } from "react-icons/md";
import Select, { components } from "react-select";
import styled from "styled-components";
import { SelectedOptionValue } from "../Monitor.styles";



const { Option } = components;
interface ISelectComponent {
  options: any;
  onChange: (value: any) => void;
  name: string;
  control: any;
  value: any;
  defaultValue?: any;
  placeholder: string;
  showSelectIcon?:boolean;
  showRightOption?:boolean;
}

const selectComponentStyle = {
  control: (base) => ({
    ...base,
    width: '95%',
    'min-height': '34px',
    minHeight: '34px',
    borderRadius: '4px',
    alignContent: 'center',
    alignItems: 'center',
    fontFamily: 'inter',
    fontSize: '12px',
  }),
  singleValue: (styles) => {
    return {
      ...styles,
      ...{
        color: "#2E2E2E",
        borderRadius: '4px',
        width: 'fit-content',
        paddingLeft: '4px',
        paddingRight: '10px',
        fontFamily: 'inter',
        fontSize: '12px',
      }
    }
  },
  option: (provided, state) => ({
    ...provided,
    paddingTop: '6px',
    color: state.data.disabled == true ? '#C7C7C7' : "black",
    paddingBottom: '6px',
    fontWeight: 400,
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    fontSize: ' 12px',
    fontFamily: 'inter',
    backgroundColor: state.isSelected ? "#D6DCEF" : "white",
    marginTop: '2px',
    "&:hover": {
      color: state.data.disabled == true ? '#C7C7C7' : "black",
      backgroundColor: state.data.disabled == true ? "#FFFFFF" : "#F0F0F0"
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '12px',
    fontFamily: 'inter',
    color: '#667085', // Change placeholder text color to red
  }),
  menuPortal: provided => ({ ...provided, zIndex: 999 }),
  menu: provided => ({ ...provided, zIndex: 999, color: '#7E7E7F' })
}

// options - value | label
const SelectComponent = ({ options, onChange, name, control, value, defaultValue, placeholder = '', showSelectIcon= false, showRightOption = false }: ISelectComponent) => {
  const DropdownIndicator = () => (
    <MdKeyboardArrowDown size={'20px'}
      style={{ height: '20px', width: '20px', marginRight: '7px' }}
    />
  );

  const IconOption = (props: any) => {
    const {
      data: { label },
    } = props;
    console.log('data',props?.data);
    return (
      <Option {...props}>
        <Tooltip
          style={{ zIndex: 999999 }}
          title={
            <Typography
              style={{ zIndex: 999999 }}
            >
              {props?.data.disabled ? `${props?.data.originalDataType} is not supported` : `${props?.data.originalDataType}`}
            </Typography>
          }
          placement="right" // This controls the placement of the tooltip
        >
          <div className="flex items-center gap-2">
            <span>{label}</span>
          </div>
        </Tooltip>
      </Option>
    );
  }

  const SingleValue = ({ children, ...props }: any) => (
    <>
      <components.SingleValue {...props} style={{ display: 'flex' }} >
        <div style={{ display: 'flex', alignItems:'center' }}>
          {
            showSelectIcon &&
          <img src={selectColumnMonitor} style={{ marginRight: '8px' }} />
          }
          
          <div> 
          <SelectedOptionValue>
          {props.data.label}
          </SelectedOptionValue>
          </div>
        </div>
      </components.SingleValue>
    </>
  )

  return (
    <>
      {(value || options) &&
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          value={value}
          render={({ field: { ref, ...field } }) => (
            <Select
              options={options}
              isDisabled={options.length === 0}
              isOptionDisabled={(option) => option.disabled}
              defaultValue={defaultValue}
              placeholder={placeholder}
              styles={
                selectComponentStyle
              }
              value={value}
              menuPortalTarget={document.body}
              components={{ 
                IndicatorSeparator: () => null, 
                DropdownIndicator, 
                SingleValue, 
                // IconOption is only used to show the disabled field in the dorpdown
                ...(showRightOption ? { Option: IconOption } : {})
               }}
              onChange={(value) => {
                onChange(value);
              }}
            />
          )}
        />
      }
    </>
  )
}

// export default SelectComponent;
export default React.memo(SelectComponent);