import styled from "styled-components";

export enum IDirection {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

interface IChronosInfoSection {
  title: string;
  content: string;
  section: string;
  direction: IDirection;
  icon: any;
}

const ChronosInfoSection = ({
  title,
  content,
  section,
  direction,
  icon,
}: IChronosInfoSection) => {
  const Section1 = styled.div`
    grid-area: ${section};
    display: flex;
    padding-left: ${direction == IDirection.LEFT ? "50px" : "96px"};
    padding-right: ${direction == IDirection.RIGHT ? "50px" : "96px"};
    align-items: center;
    flex-direction: column;
  `;
  const SectionTitle = styled.h3`
    display: block;
    text-align: left;
    width: 100%;
    color: var(--gray-900, #101828);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  `;

  const SectionContent = styled.div`
    color: var(--gray-600, #475467);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    display: block;
    text-align: left;
  `;

  return (
    <div className="">
      <Section1>
        <div style={{ width: "100%",display: "flex", marginBottom: "10px" }}>
          <img width="48" height="48" src={icon} />
        </div>
        <SectionTitle>{title}</SectionTitle>
        <br></br>
        <SectionContent>{content}</SectionContent>
      </Section1>
    </div>
  );
};

export default ChronosInfoSection;