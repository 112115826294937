import { Card } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { getConfig } from "config/envConfig/Config";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { useRedirect } from "react-admin";
import styled from "styled-components";

const Header = styled.div`
  display: flex;
  justify-content: end;
  padding: 12px;
`;

//define FWebFrameProps
interface FWebFrameProps {
  dashboardflutterWebUrl: string;
}

const FWebFrame = (props: any) => {
  const { taskId } = useParams();

  console.log(taskId);
  const flutterWebUrl = getConfig("appPreviewUrl");
  const at = localStorage.getItem("at");
  const url = `${flutterWebUrl}/#/task?taskId=${taskId}&at=${at}&from=cc_task_details`;

  const redirect = useRedirect();

  const handleBackClick = () => {
    // go back to prev page
    redirect(`/tasks/${taskId}/show`);
  };

  return (
    <Card>
      <div>
        <Header>
          <ZButton onClick={handleBackClick} secondary variant="contained">
            {" "}
            Cancel{" "}
          </ZButton>
        </Header>
        <iframe
          title="iframe"
          src={url}
          width="100%"
          height={window.screen.height * 0.75}
          allow="camera https://zorp-app.web.app; microphone https://zorp-app.web.app; geolocation"
        ></iframe>
      </div>
    </Card>
  );
};

export default FWebFrame;
