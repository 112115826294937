import { styled as MuiStyled } from '@mui/system';
import { Box } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import styled from "styled-components";

interface IHeaderText {
    hover?: boolean;
}

const HeaderText = styled.p<IHeaderText>`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const ColumnValue = styled.p<IHeaderText>`
  color: ${(props) => (props.hover ?   `var(--Gray-600, #243F8B)` :  `black`)};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
`;

const ActiveText = styled.p`
  color: var(--Success-700, #027a48);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
`;

const DisabledText = styled.p`
  color: var(--Success-700, #b54708);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
`;

// Define the styled SearchBox component
const HeaderContainer = MuiStyled(Box)({
    backgroundColor: '#F9FAFB',
    border: '1px solid var(--Gray-200, #EAECF0)',
    borderRight: '0px solid var(--Gray-200, #EAECF0)',
    padding: '12px 24px'
});

// Define an interface for the component's props
interface BodyContainerProps {
    hover?: boolean;
}

// Adjust the style definition function to correctly type its parameter
const BodyContainer = MuiStyled(Box)<BodyContainerProps>(({ theme, ...props }) => ({
    border: '1px solid var(--Gray-200, #EAECF0)',
    borderRight: '0px solid var(--Gray-200, #EAECF0)',
    borderTop: '0px solid var(--Gray-200, #EAECF0)',
    padding: '12px 24px',
    backgroundColor: props?.hover ? '#ECF3FF' : '', // Use props.hover here
}));


const DataRow = MuiStyled(Box)({
    '&:hover': {
        border: '0px solid var(--Gray-200, #EAECF0)',
        borderRight: '0px solid var(--Gray-200, #EAECF0)',
        backgroundColor: '#ECF3FF'
    }
});

interface IMonitorTable {
    monitorList: any;
    onClick: (data: any) => void;
}

const MonitorTable = ({ monitorList, onClick }: IMonitorTable) => {
    const monitorId = monitorList?.[0]?.monitorId;
    const [isHovered, setIsHovered] = useState<number | undefined>();
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        if (!selectedId && monitorId) {
            setSelectedId(monitorId)
            setIsHovered(monitorId)
        }
    }, [selectedId, monitorId])

    return (
        <Box>

            {/* Header   */}
            <Box display={'flex'} width={'100%'}>
                <HeaderContainer width={'20%'}>
                    <HeaderText>ID</HeaderText>
                </HeaderContainer>
                <HeaderContainer width={'60%'}>
                    <HeaderText>Name</HeaderText>
                </HeaderContainer>
                <HeaderContainer width={'20%'} textAlign={'center'}>
                    <HeaderText>Status</HeaderText>
                </HeaderContainer>
            </Box>


            <Box>
                {
                    monitorList?.map((data: any, index:number) => {
                        return (
                            <>
                                <DataRow
                                key={index}
                                    height={'44px'}
                                    onClick={() => {
                                        onClick(data)
                                        setSelectedId(data.monitorId)
                                    }}
                                    onMouseEnter={() => {
                                        setIsHovered(data.monitorId)
                                    }}
                                    onMouseLeave={() => setIsHovered(undefined)}
                                    display={'flex'} width={'100%'} className='pointer'>
                                    <BodyContainer hover={isHovered == data.monitorId || selectedId == data.monitorId} width={'20%'}>
                                        <ColumnValue hover={isHovered == data.monitorId || selectedId == data.monitorId}>{data.monitorId}</ColumnValue>
                                    </BodyContainer>
                                    <BodyContainer hover={isHovered == data.monitorId || selectedId == data.monitorId} width={'60%'}>
                                        <ColumnValue hover={isHovered == data.monitorId || selectedId == data.monitorId}>{data.monitorName}</ColumnValue>
                                    </BodyContainer>
                                    <BodyContainer hover={isHovered == data.monitorId || selectedId == data.monitorId} width={'20%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        {
                                            data.isActive ? <>
                                                <Box
                                                    padding={'4px 12px'}
                                                    borderRadius={'4px'}
                                                    border={'1.5px solid var(--Success-600, #039855)'}
                                                > <ActiveText>Active</ActiveText> </Box>
                                            </>
                                                :
                                                <>
                                                    <Box
                                                        padding={'4px 12px'}
                                                        borderRadius={'4px'}
                                                        border={'1.5px solid var(--Success-600, #DC6803)'}
                                                    > <DisabledText>Disabled</DisabledText> </Box>
                                                </>}
                                    </BodyContainer>
                                </DataRow>
                            </>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export  default memo(MonitorTable);