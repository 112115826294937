//@ts-nocheck
import styled from "styled-components";
import {
  Heading,
  HeadingWrapper,
} from "views/workflows/workflowactions/ModalContent.styles";

export const Image = styled.img`
  margin: 25px auto 0px auto;
  width: ${(props: { main?: boolean }) => (props?.main ? "95%" : "100%")};
  @media (max-height: 700px) {
    width: ${(props: { main?: boolean }) => (props?.main ? "81%" : "100%")};
  }
`;

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

export const Text = styled.p`
  font-size: ${(props: {
    large?: boolean;
    medium?: boolean;
    secondary?: boolean;
    left?: boolean;
    link?: boolean;
  }) => (props.large ? "18px" : props.medium ? "16px" : "14px")};
  font-weight: 500;
  margin-bottom: 20px !important;
  text-align: ${(props: { left?: boolean }) =>
    props.left ? "left" : "center"};
  color: ${(props: { secondary?: boolean; link?: boolean }) =>
    props.secondary ? "#333333" : props.link ? `${props.theme.color.primary}` : ""};
  svg {
    display: inline;
  }
  padding: ${(props: {
    large?: boolean;
    medium?: boolean;
    secondary?: boolean;
    left?: boolean;
    link?: boolean;
    banner?: boolean;
  }) => (props.banner ? "25px" : "")};
  color: ${(props: {
    large?: boolean;
    medium?: boolean;
    secondary?: boolean;
    left?: boolean;
    link?: boolean;
    banner?: boolean;
  }) => (props.banner ? "#333333" : "")};
`;

export const OnboardingContainer = styled.div`
  position: relative;
  max-width: 750px;
  height: 730px;
  overflow: auto;
  background-color: white;
  padding: 15px;
  border: 1px solid #c2c9d1;
  /* border-radius: 4px; */
  @media (max-height: 700px) {
    height: 95vh;
  }
`;

export const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 35% 65%;
  padding: 20px;
  gap: 24px;
`;
export const HomeContainer = styled.div`
  padding: 32px 100px;
  @media ${device.tablet} {
    padding: 10px;
  }
`;

export const ChecklistDiv = styled.div`
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    #c2c9d1;
  border-radius: 8px;
  padding: 20px;

  hr {
    border-color: #c2c9d1;
    margin-bottom: 10px;
  }

  .MuiLinearProgress-root {
    height: 7px;
    border-radius: 3px;
    background-color: #c2c9d1;

    .MuiLinearProgress-bar1Determinate {
      background-color: #3cc13b;
    }
  }
`;

export const HomeapageHero = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 4rem;
  margin-bottom: 20px;
`;

export const ButtonContainerForHomePage = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;
  button {
    width: 95% !important;
  }
  @media ${device.tablet} {
    grid-template-columns: 100%;
    button {
      width: 100% !important;
    }
  }
`;

export const FaqContainer = styled.div`
  margin-top: 20px;
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const Iframe = styled.iframe`
  height: 100%;
  width: 100%;
  border-radius: 8px;

  @media ${device.tablet} {
    height: 300px;
  }
`;

export const OnboardingButtonContainer = styled.div`
  
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;

    @media (max-width: 550px) {
      display: grid;
      right: 0;
      width: 100%;
      padding: 10px;
    }

    @media (max-height: 700px) {
    bottom: ${(props:{makeBottom?:boolean}) => props?.makeBottom ? "15px" : "10px"};
    }

    @media (max-height: 650px) {
      position: relative;
      margin-top: 15px;
      display: grid;
      /* display: flex; */
      /* float: right; */
    }
`;

export const CalloutDiv = styled.div`
  background: ${(props) => `linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    ${props.theme.color.primary}`};

  border: ${(props) => `1px solid ${props.theme.color.primary}`};
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;

  p {
    font-weight: 500;
    font-size: 14px;
  }
`;

export const OnbaordingHeadingWrapper = styled(HeadingWrapper)`
  padding: 0px 10px 15px 10px;
`;

export const OnboardingHeading = styled(Heading)`
  margin-bottom: 0px;
`;

export const Flex = styled.div`
 display: flex;
 justify-content: center;
`
