import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import React from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getFullDate } from 'utils/DateFunctions.utils';
import { PageCategoryEnum, recordPageVisit } from '../../../../config/Rudderstack';
import notification from '../../../../notifications/notifications';
import authProvider from '../../../../providers/auth/authProvider';
import { UserService } from '../../../../providers/data/services/UserService';
import { TaskService } from '../../services/TaskService';
import { SchedulerModifyOptions } from './SchedulerModifyOptions';
import ZorpCalendarTask from './ZorpCalendarTask';
import ZorpCustomToolbar from './ZorpCalendarToolbar';

// import Typography from '@material-ui/core/Typography';
const DragAndDropCalendar = withDragAndDrop(Calendar);

class Scheduler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      open: false,
      editEvent: null,
      drivers: [],
      dialogOpen: false,
    };
    this.fetchEvents = this.fetchEvents.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.move = this.move.bind(this);
    this.eventPropGetter = this.eventPropGetter.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    // this.refetch = this.refetch.bind(this);
    // this.toolTip = this.toolTip.bind(this);

    recordPageVisit(PageCategoryEnum.calendar, 'scheduler');
  }

  fetchUsers(teamId) {
    const filters = {};
    if (teamId) filters['teamIds'] = [teamId];

    UserService.listUsers({ filter: filters, pagination: { page: 1, perPage: 3000 } })
      .then((response) => {
        var i = 0;
        const drivers = [{ resourceId: '1', resourceTitle: 'Unassigned Tasks' }];
        // console.log("Res Drivers", res.data);
        for (i = 0; i < response?.data?.users?.length; i++) {
          const item = response?.data?.users[i];
          const driver = {
            resourceId: item.userId,
            resourceTitle: item.name,
            teamIds: item.teamIds,
          };
          drivers.push(driver);
        }

        // console.log("Drivers", drivers);
        this.setState({
          ...this.state,
          drivers: drivers,
        });
        // setUsers(response?.data?.users);
      })
      .catch((error) => {
        notification('error', 'Error while fetching users for team');
      });
    // UserService
    //   .userSelect()
    //   .then((res) => {
    //     var i = 0;
    //     const drivers = [
    //       { resourceId: "1", resourceTitle: "Unassigned Tasks" },
    //     ];
    //     // console.log("Res Drivers", res.data);
    //     for (i = 0; i < res?.data?.users?.length; i++) {
    //       const item = res?.data?.users[i];
    //       const driver = {
    //         resourceId: item.userId,
    //         resourceTitle: item.name,
    //       };
    //       drivers.push(driver);
    //     }

    //     // console.log("Drivers", drivers);
    //     this.setState({
    //       ...this.state,
    //       drivers: drivers,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  fetchEvents(start = '', end = '') {
    TaskService.getDateTasks(start, end)
      .then((res) => {
        const eventData = [];
        var i = 0;
        for (i = 0; i < res?.data?.tasks?.length; i++) {
          const item = res?.data?.tasks[i];
          var taskType = '';
          taskType = item?.taskType;
          // if (taskType === "order_management_task") taskType = "OMT";
          // if (taskType === "inventory_pickup_task") taskType = "IPT";
          // if (taskType === "demo_order_management_task") taskType = "DOMT";
          // if (taskType === "field_visit_task") taskType = "FVT";
          // if (taskType === "pickup_and_drop_task") taskType = "PDT";

          var title = item.taskId + ': ' + item?.taskTypeDisplayName;
          const event = {
            id: item.taskId,
            title: title,
            start: new Date(item?.scheduledSlot?.from),
            end: new Date(item?.scheduledSlot?.to),
            resourceId: item?.userId ? item?.userId : '1',
            toolTip: title,
            taskInTerminalState: item?.taskInTerminalState,
            taskStatusName: item?.taskStatusName,
            taskType: item?.taskType,
            taskName: item?.taskTypeDisplayName,
            customerName: item?.data?.orderInfo[0]?.customer?.name,
            orders: item?.data?.orderInfo[0].orderId,
            productCount: item?.data?.products?.length,
            teamIds: item.teamIds,
          };

          eventData.push(event);
        }

        this.setState({
          ...this.state,
          startDate: start,
          endDate: end,
          events: eventData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  refetch = (teamId, dt) => {
    const start = moment(dt).startOf('day').toISOString();
    const end = moment(dt).endOf('day').toISOString();
    document.getElementById('root').setAttribute('style', 'overflow-x: scroll !important;');
    this.fetchEvents(start, end);
    this.fetchUsers(teamId);
  };

  componentDidMount() {
    this.refetch();
  }

  handleDialogOpen = () => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleClick = (props) => {
    this.setState(
      {
        editEvent: props,
      },
      () => {
        if (
          this.state.editEvent?.taskType === 'inventory_pickup_task' ||
          this.state.editEvent?.taskType === 'field_visit_task' ||
          this.state.editEvent?.taskType === 'pickup_and_drop_task' ||
          this.state.editEvent?.taskInTerminalState
        ) {
          this.setState({
            ...this.state,
            dialogOpen: true,
          });
        } else {
          this.setState({
            ...this.state,
            open: true,
          });
        }
      }
    );
    // setOpen(true);
  };

  handleClose = () => {
    var date = this.state.editEvent?.start;
    var start = moment(date).startOf('day').toISOString();
    var end = moment(date).endOf('day').toISOString();
    this.fetchEvents(start, end);
    this.setState({
      open: false,
    });
  };

  navigate = (date) => {
    var start = moment(date).startOf('day').toISOString();
    var end = moment(date).endOf('day').toISOString();
    this.fetchEvents(start, end);
    this.fetchUsers();
  };

  move = ({ event, start, end, resourceId, isAllDay: droppedOnAllDaySlot }) => {
    if (
      event.taskType === 'inventory_pickup_task' ||
      event.taskType === 'field_visit_task' ||
      event.taskType === 'pickup_and_drop_task' ||
      event.taskInTerminalState
    ) {
      notification('info', 'Task is not editable');
      return;
    }
    const events = this.state.events;
    const idx = events.indexOf(event);
    const initialDriver = event?.resourceId;
    const driver = resourceId;
    if (!(initialDriver === '1' && driver === '1')) {
      if (initialDriver !== driver) {
        // var assignEvent = {
        //   taskId: event?.id,
        // };
        // if (driver === "1") {
        //   // UnAssign Event!
        //   console.log("Unassign - 1");
        //   assignEvent = {
        //     ...assignEvent,
        //     unassign: true,
        //     assign: false,
        //     event: "UNASSIGN",
        //   };
        // } else {
        //   if (initialDriver === "1") {
        //     assignEvent = {
        //       ...assignEvent,
        //       unassign: false,
        //       assign: true,
        //       event: "ASSIGN",
        //       userId: driver,
        //     };
        //   }

        //   if (initialDriver !== "1") {
        //     // console.log("Unassign - 4")
        //     assignEvent = {
        //       ...assignEvent,
        //       unassign: true,
        //       assign: true,
        //       event: "ASSIGN",
        //       userId: driver,
        //     };
        //   }
        // }

        // TaskService.assign(assignEvent)
        //   .then((res) => {
        //     if (res.code !== "200") {
        //       // Error
        //       if (res.code === "401") {
        //         notification("error", res.message);
        //         authProvider.logout();
        //       } else {
        //         notification("error", res.message);
        //       }
        //     } else {
        //       notification("success", res.message);
        //       // const updatedEvent = { ...event, resourceId };
        //       // const nextEvents = [...events];
        //       // nextEvents.splice(idx, 1, updatedEvent);
        //       // this.setState({
        //       //   ...this.state,
        //       //   events: nextEvents,
        //       // });
        //       var start = moment(event?.start).startOf("day").toISOString();
        //       var end = moment(event?.start).endOf("day").toISOString();
        //       this.fetchEvents(start, end);
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
        this.state.drivers
          .filter((driver) => driver.resourceId === resourceId)
          .map((driver) => {
            const assignedUser = resourceId === '1' ? null : resourceId;
            const assignedTeam = resourceId === '1' ? event.teamIds : driver.teamIds;
            TaskService.updateTeamUser(event.id, assignedUser, assignedTeam)
              .then((res) => {
                if (res.code !== '200') {
                  // Error
                  if (res.code === '401') {
                    notification('error', res.message);
                    authProvider.logout();
                  } else {
                    notification('error', res.message);
                  }
                } else {
                  notification('success', res.message);
                  // const updatedEvent = { ...event, resourceId };
                  // const nextEvents = [...events];
                  // nextEvents.splice(idx, 1, updatedEvent);
                  // this.setState({
                  //   ...this.state,
                  //   events: nextEvents,
                  // });
                  var start = moment(event?.start).startOf('day').toISOString();
                  var end = moment(event?.start).endOf('day').toISOString();
                  this.fetchEvents(start, end);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          });
      }
    }
  };

  eventPropGetter = (props) => {
    // console.log("Prop Getter", props);
    if (props?.taskInTerminalState)
      return {
        style: {
          backgroundColor: '#FFC0AE',
          border: 'solid 1px #ECF6FE',
          fontSize: '12px',
        },
      };

    if (!props?.taskInTerminalState && moment(props?.end).isBefore(moment())) {
      return {
        style: {
          backgroundColor: '#FF4242',
          border: 'solid 1px #ECF6FE',
          fontSize: '12px',
        },
      };
    }

    return {
      style: {
        backgroundColor: '#FF7750',
        border: 'solid 1px #ECF6FE',
        fontSize: '12px',
      },
    };
  };

  render() {
    return (
      <>
        <div className='scheduleCalenderClass' id='calendarid'>
          <DragAndDropCalendar
            localizer={momentLocalizer(moment)}
            views={['day', 'week']}
            // onView={e => { console.log ("****45"); this.refetch(e) } }
            onNavigate={this.navigate}
            events={this.state.events}
            onEventDrop={this.move}
            resources={this.state.drivers}
            resourceIdAccessor='resourceId'
            resourceTitleAccessor='resourceTitle'
            onDoubleClickEvent={this.handleClick}
            eventPropGetter={this.eventPropGetter}
            tooltipAccessor={null}
            components={{ event: ZorpCalendarTask, toolbar: ZorpCustomToolbar(this.refetch) }}
            defaultView={Views.DAY}
            selectable={false}
            step={30}
            // min={min}
            // timeslots={1}
            showMultiDayTimes={true}
            defaultDate={moment()}
          />
        </div>
        {this.state.open && (
          // <TaskEditModal
          //   event={this.state.editEvent}
          //   onClose={this.handleClose}
          // ></TaskEditModal>
          <SchedulerModifyOptions event={this.state.editEvent} onClose={this.handleClose}></SchedulerModifyOptions>
        )}
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {this.state.editEvent?.id + ' - ' + this.state.editEvent?.taskName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Start Time - {getFullDate(this.state.editEvent?.start)}
              <br />
              End Time - {getFullDate(this.state.editEvent?.end)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color='primary' autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Scroll({ReactDOM.findDOMNode(<Scheduler/>).getElementsByClassName('rbc-time-header')})} */}
      </>
    );
  }
}

export default Scheduler;
