import SingleError from "./SingleError";
import useWorkFlow from "../hooks/useWorkFlow";
import styled from "styled-components"
import { AiOutlineClose } from "react-icons/ai";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import useOutsideClick from "../hooks/useOutsideClick";
import { useRef } from "react";

const Container = styled.div`
  background-color:white;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 9px;
`

const Heading = styled.div`
  font-size: 14px;
  font-weight:600;
  display: flex;
  justify-content: space-between;
`

const Info = styled.p`
  font-size: 12px;
  font-weight:400;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px !important;
`

const StyledDiv = styled.div`
  height: auto; /* h-auto in Tailwind CSS */
  width: 26rem; /* Overridden by inline style */
  position: absolute; /* absolute in Tailwind CSS */
  z-index: 10; /* z-10 in Tailwind CSS */
  top: 8rem; /* Overridden by inline style */
  border: 1px solid rgba(194, 201, 209, 1); /* From inline style */
`;

const SelectNodeDropdown = ({allErrors,handleClose}:{allErrors:any, handleClose:Function}) => {

  const impactRef = useRef(null); //Ref of your parent div
  useOutsideClick(impactRef, () => handleClose()); //Change my dropdown state to close when clicked outside

  return (
    <StyledDiv>
      
      <div ref={impactRef}>
        <Container>
          <div>
            <Heading> 
              {allErrors.length} {allErrors.length === 1 ? "Issue" : "Issues"} Detected 
              <AiOutlineClose onClick={() => handleClose()} />
            </Heading>
          </div>
          <Info>Click on any of the issues below to review them.</Info>
          <hr/>
        </Container> 
        <ul
          style={{backgroundColor:"white",textAlign:"left",margin:"0", maxHeight:"16rem",overflowY:"auto"}}
        >
          {allErrors.map((error:any) => (
            <SingleError
              info={error.message}
              location={error.location}
              payload={error.payload}
              key={Math.random()}
              />
          ))} 
        </ul>
      </div>
     

    </StyledDiv>
  );
};

export default SelectNodeDropdown;
