//@ts-nocheck
import  { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import jwt_decode from "jwt-decode";
import { recordEvent } from "../../../config/Rudderstack";
import { getEnv } from "utils/CommonUtils";

const SetupStatus = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);

  const notifyPaymentSetupActivity = (status) => {
    let jwtDecoded = jwt_decode(localStorage.getItem("at"));
    recordEvent("Payment Method Add", {
      env: getEnv(),
      status: status,
      user: `${jwtDecoded.name} (${jwtDecoded.userId})`,
      accountId: jwtDecoded.accountId,
      timestamp: new Date().toISOString(),
    });
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    // const clientSecret = new URLSearchParams(window.location.search).get(
    //   'setup_intent_client_secret'
    // );

    // const clientSecret = new URLSearchParams(window.location.hash.split("?")[1]).get(
    //     'setup_intent_client_secret'
    // );

    const clientSecret = localStorage.getItem("setup_intent_client_secret");

    if (!clientSecret) {
      return;
    }
    localStorage.removeItem("setup_intent_client_secret");
    localStorage.removeItem("setup_intent");
    localStorage.removeItem("redirect_status");

    // Retrieve the SetupIntent
    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      // Inspect the SetupIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (setupIntent.status) {
        case "succeeded":
          setMessage("Success! Your payment method has been saved.");
          break;

        case "processing":
          setMessage(
            "Processing payment details. We'll update you when processing is complete."
          );
          break;

        case "requires_payment_method":
          // Navigate your user back to your payment page to attempt collecting
          // payment again
          setMessage(
            "Failed to process payment details. Please try another payment method."
          );
          break;
        default:
      }
      notifyPaymentSetupActivity(setupIntent.status);
    });
  }, [stripe]);

  return <div>{message}</div>;
};

export default SetupStatus;
