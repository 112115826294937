//@ts-nocheck
import { Box, Button, ButtonGroup } from "@mui/material";
import { find, forOwn, isEmpty, sortBy, uniqBy } from "lodash";
import { FunctionComponent, useEffect, useState, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { IZChoiceInterface } from "../../components/choice/IZChoiceInterface";
import { ZChoice } from "../../components/choice/ZChoice";
import { ComponentType } from "../../components/ComponentTypeEnum";
import { ZEmpty } from "../../components/empty/ZEmpty";
import notification from "../../notifications/notifications";
import { TaskRenderService } from "../../providers/data/services/TaskRenderService";
import "../../styles/App.css";
import LoadingScreen from "../../views/LoadingScreen/LoadingScreen";
import { setLoading } from "../../views/reducer/index";
import { TaskService } from "../../views/tasks/services/TaskService";
import { ITaskTypeWebRenderModel } from "../models/ITaskTypeWebRenderModel";
import {
  cancelTaskCreation,
  cleanTaskCreation,
  initTaskCreation,
} from "../reducers/create";
import { Render } from "../Render";
import { getComponentParams } from "../RenderUtils";
import { modifyFormDataForBackend, recordRSEvent } from "utils/CommonUtils";
import { Link, useQuery } from "react-admin";
import { resetListInfo } from "../reducers/listInfo";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { queryByTestId } from "@testing-library/react";
import useOnboarding from "views/onboarding/hooks/useOnboarding";
import { OnboardingUtil } from "views/onboarding/OnboardingUtil";
import { OnboardingStep } from "views/onboarding/types/OnboardingTypes";
import Callout from "views/onboarding/components/Callout";
import {
  BlockContainer,
  BlockDiv,
  TaskFormBody,
} from "render-engine/render.styles";
import TopBar from "render-engine/TopBar";
import { initTaskCreateMetaData } from "render-engine/reducers/show/TaskShowReducer";
import useTaskCreation from "views/workflows/hooks/useTaskCreation";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { RenderV2 } from "render-engine/RenderV2";
import { useParams } from "react-router-dom";

export const CreateTask: FunctionComponent<{
  isCreate?: boolean;
  taskId?: string;
}> = ({ isCreate = false, taskId }) => {
  const store = useStore();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createTaskForm = useForm();
  const { isBeingOnboarded } = useOnboarding();

  const taskIdFromRedux = useSelector(
    (state: any) => state.taskCreation?.taskType
  );
  const taskIdFromPreview = localStorage.getItem("previewWorkflowId");
  const { taskType: taskTypeFromParams } = useParams();
  const taskType =
    taskTypeFromParams || taskId || taskIdFromRedux || taskIdFromPreview;

  const [taskTypes, setTaskTypes] = useState<any>([]);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [renderConfig, setRenderConfig] = useState<ITaskTypeWebRenderModel>();
  const { taskRenderDetails, taskShowLayoutMetadata } = useTaskShow();
  const { taskData, isDataEdited } = useTaskShow();

  //@ts-ignore
  const { loading } = useSelector((state) => state.loading);

  // Fetch the config for CC
  useEffect(() => {
    if (taskType) {
      dispatch(initTaskCreateMetaData({ taskType }));
    }
  }, [taskType]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fech the list of task types on the first load.
  useEffect(() => {
    TaskService.getTaskTypes(false, true).then((res: any) => {
      let tt: any = {};
      res?.data?.forEach((t: any) => {
        tt[t.taskType] = {
          label: t.taskTypeDisplayName,
          value: t.taskType,
          legacy: true,
        };
      });
      TaskRenderService.listRenderConfigs(true)
        .then((res) => {
          if (res?.code && res?.code.indexOf("200") < 0) {
            if (res?.code === "401") {
              notification("error", res.message);
            } else {
              notification("error", res.message);
            }
          } else {
            res?.data?.forEach((t: any) => {
              tt[t.taskType] = {
                label: t.taskTypeDisplayName,
                value: t.taskType,
                legacy: false,
              };
            });
          }
          let ttNew: any = [];
          forOwn(tt, function (value, key) {
            ttNew.push(value);
          });
          ttNew = uniqBy(sortBy(tt, ["label"]), "value");
          setTaskTypes(ttNew);
        })
        .catch((err) => {
          console.error("Error", err);
        });
    });
  }, []);

  useEffect(() => {
    dispatch(initTaskCreation({}));
    dispatch(resetListInfo());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //CHanges made for the value to get stored in react hook formState
  // Changes made in ZSlot and ZDateTime.
  // TODO: aDD 1 hourse to ZSlot "to" time.
  //TODO: pREFILL THE A;READY ENTERED DATA IN CASE OF EDIT. oNE CAN BE TO USE
  // useEffect in each component and call setValue.
  const createTask = (formData: any) => {
    dispatch(setLoading(true));

    const data = modifyFormDataForBackend(taskData);
    console.error("****", data);
    const taskCreationPayload: any = {
      // taskType: store.getState().taskCreation.taskType,
      // userId: store.getState().taskCreation.userId,
      // teamIds: [store.getState().taskCreation.teamIds],
      taskType: taskType,
      userId: formData.userId || "",
      teamIds: [formData.teamIds],
      scheduledSlot: formData.scheduledSlot,
      mode: "single",
      channel: "Dashboard",
      data: {
        ...data,
        taskType: undefined,
        userId: undefined,
        teamIds: undefined,
        scheduledSlot: undefined,
      },
    };
    // const omitFieldsInData: Array<string> = ['scheduledSlot', 'taskType', 'teamIds', "userId"];
    // for (let key of Object.keys(store.getState().taskCreation)) {
    //   // Skip the omit fields for data
    //   if (!omitFieldsInData.includes(key)) taskCreationPayload.data[key] = store.getState().taskCreation[key];
    // }

    TaskService.createTask(taskCreationPayload)
      .then((res: any) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
          setDisableCreateButton(false);
          createTaskForm.reset();
          // Error
          if (res?.code === "401") {
            recordRSEvent("create_event_fail", {
              context: "single_task_creation",
              taskType: taskType,
            });
          } else if (res.code === "600") {
            recordRSEvent("create_event_fail", {
              context: "single_task_creation",
              taskType: taskType,
            });
            notification("error", res?.message || "Something went wrong!");
          } else {
            recordRSEvent("create_event_fail", {
              context: "single_task_creation",
              taskType: taskType,
            });
          }
        } else {
          setDisableCreateButton(false);
          notification("success", res.message);
          dispatch(cleanTaskCreation());
          recordRSEvent("create_event_success", {
            context: "single_task_creation",
            taskType: res.data.taskType,
            taskId: res.data.taskId,
          });
          if (isBeingOnboarded) {
            OnboardingUtil.start({
              step: OnboardingStep.EXECUTE_TASK,
              dispatch,
              steps: store.getState()?.userOnboarding?.onboarding?.steps || [],
            });
            OnboardingUtil.updateOnboardingDataToBackend(
              OnboardingStep.CREATE_TASK,
              dispatch,
              store.getState().userOnboarding?.onboarding?.steps || []
            );
            navigate("/home");
          } else {
            navigate(`/task/${taskType}/show/${res.data.taskId}`);
          }
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        setDisableCreateButton(false);
        createTaskForm.reset();
        console.error("Error", err);
        dispatch(setLoading(false));
        recordRSEvent("create_event_fail", {
          context: "single_task_creation",
          taskType: taskType,
        });
        notification("error", "Task cannot be created!");
      });
  };

  const cancelTask = () => {
    dispatch(cancelTaskCreation({}));
    recordRSEvent("cancel_button_click", {
      context: "single_task_creation",
      taskType: taskType,
    });
    notification("info", "Task creation cancelled!");
    dispatch(resetListInfo());
    navigate("/tasks");
  };

  const defaultFn = () => {};

  const taskTypeInputConfig: IZChoiceInterface = {
    nodeId: `node_taskType`,
    key: "taskType",
    type: ComponentType.CHOICE,
    shape: "DROPDOWN",
    label: {
      color: "#E74C3C",
      fontSize: 14,
      isVisible: true,
      val: "Workflow",
    },
    // options: [
    //     { "label": "Dog walk", "value": "furritas_dog_walk" },
    //     { "label": "Road Side Service", "value": "road_side_service" }
    // ],
    options: taskTypes,
    onValidate: defaultFn,
    onSave: defaultFn,
    required: true,
    value: taskType,
    disabled: taskIdFromPreview ? true : false,
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <FormProvider {...createTaskForm}>
          <form
            style={{ height: "94vh", overflow: "hidden" }}
            onSubmit={createTaskForm.handleSubmit(createTask)}
          >
            {/* {createTaskForm.getValues()?.taskType && ( */}
            <TopBar
              heading="Create Task"
              onCancelText={"Cancel"}
              onSaveText="Create"
              onSave={() => {}}
              onCancel={cancelTask}
            />
            {/* )} */}
            <TaskFormBody key="taskCreate" className={`grid`}>
              {/* <div className='flex font-bold text-3xl justify-center pb-8'>Create Task</div> */}
              {/* <hr style={{ marginBottom: '3rem' }} /> */}
              <div className="contentWrapper">
                <div style={{ marginTop: "10px" }}>
                  <Callout
                    text={
                      <p>
                        Task can be created only for already published
                        workflows. Create{" "}
                        <Link className="linkTitle" to="/workflows/create">
                          new workflow
                        </Link>
                      </p>
                    }
                  />
                </div>
                {/* <BlockContainer>
                  <BlockDiv
                    key="taskType"
                    className={"grid grid-rows-1 lg:grid-cols-8"}
                  >
                    <ZChoice
                      config={taskTypeInputConfig}
                      {...getComponentParams(dispatch, createTaskForm)}
                    />
                    <ZEmpty />
                  </BlockDiv>
                </BlockContainer> */}
                {!isEmpty(taskShowLayoutMetadata) && (
                  <RenderV2
                    componentParams={getComponentParams(
                      dispatch,
                      createTaskForm
                    )}
                    entityCategory={"TASK"}
                    entityType={""}
                    entityId={undefined}
                    showComments={false}
                    mode={"create"}
                    setShowComments={() => {}}
                  ></RenderV2>
                )}

                {/* {
                  createTaskForm.getValues()?.taskType && (
                    <Box py={8}>
                      <ZButton type='submit' disabled={disableCreateButton} variant='contained'>
                        Create
                      </ZButton>
                      <ZButton variant='contained' onClick={cancelTask} secondary className='ml-2'>
                        Cancel
                      </ZButton>
                    </Box>
                  )
                } */}
              </div>
            </TaskFormBody>
          </form>
        </FormProvider>
      )}
    </>
  );
};
