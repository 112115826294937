import React, { Component } from 'react';
import T from 'prop-types';
import { FieldTitle } from 'react-admin';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FilterModalClose from "assests/FilterModalClose.svg";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { debounce } from 'lodash';

const InputContainer = styled('div')(({ theme }) => ({
  background: '#FFF',
  zIndex: 1000,
  paddingTop: '8px',
  marginBottom: '8px',
  width: '100%',
  position: 'sticky',
  top: 0,
}));

const Input = styled('input')({
  display: 'flex',
  padding: '8px 12px',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  width: '90%',
  fontSize: '12px',
  borderRadius: '4px',
  margin: 0,
  marginLeft: '6px',
  border: '1px solid #D0D5DD',
  background: '#FFF',
  zIndex: 1000,
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
});

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '0px 12px',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: '444px',
    maxWidth: '444px',
    overflowX: 'scroll',
  },
}));

const CheckBoxStyled = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&.Mui-checked': {
    color: '#3054B9',
  },
}));

const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  '& .MuiFormControlLabel-label': {
    marginLeft: 0,
    width: '100%',
  },
}));

class TableColumnSelectionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
    };
    this.debouncedSearch = debounce(this.handleSearch, 300);
  }

  onColumnClicked = ({ target: { value: columnName } }) => {
    this.props.onColumnClicked(columnName);
  };

  handleSearch = (value) => {
    this.setState({ searchTerm: value });
  };

  filterAndSortColumns = () => {
    const { columns } = this.props;
    const { searchTerm } = this.state;

    const filteredColumns = columns
      .filter(({ label }) => label.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => a.label.localeCompare(b.label));

    return filteredColumns;
  };

  handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    this.setState({ searchTerm }, () => {
      this.filterAndSortColumns();
    });
  };

  render() {
    const { onClose, resource, selection } = this.props;
    const filteredAndSortedColumns = this.filterAndSortColumns();

    return (
      <StyledDialog aria-labelledby="ra-columns-dialog-title" onClose={onClose} open>
        <Grid container justifyContent="space-between">
          <DialogTitle id="ra-columns-dialog-title">Table Columns</DialogTitle>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              <img src={FilterModalClose} alt="Close" />
            </Button>
          </DialogActions>
        </Grid>

        <StyledDialogContent>
          <InputContainer>
            <Input autoFocus placeholder='Search Columns' onChange={this.handleSearchChange} />
          </InputContainer>
          <FormGroup>
            {filteredAndSortedColumns.map(({ source, label }) => (
              <FormControlLabelStyled
                key={source}
                control={
                  <CheckBoxStyled
                    checked={!!selection[source]}
                    onChange={this.onColumnClicked}
                    value={source}
                  />
                }
                label={<FieldTitle label={label} source={source} resource={resource} />}
              />
            ))}
          </FormGroup>
        </StyledDialogContent>
      </StyledDialog>
    );
  }
}

TableColumnSelectionDialog.propTypes = {
  columns: T.arrayOf(
    T.shape({
      label: T.string,
      source: T.string.isRequired,
    }),
  ).isRequired,
  selection: T.object,
  onClose: T.func,
  resource: T.string,
};

export default TableColumnSelectionDialog;
