//@ts-nocheck
import axios from "axios";
import { permissionLevels } from "constants/CommonConstants";
import { json2csv } from 'json-2-csv';
import { isPlainObject } from "lodash";
import jwt_decode from "jwt-decode";
import { capitalize, cloneDeep, isObject, isString } from "lodash";
import moment from "moment";
import { ZorpResponse } from "providers/data/models/ZorpResponse";
import { AccountService } from "providers/data/services/AccountService";
import { TeamService } from "providers/data/services/TeamService";
import { UserService } from "providers/data/services/UserService";
import { PermissionConfiguration, ResourceTypeConstant } from "views/entities/permissions/types";

import { ViewState } from "views/entities/types";
import { Color } from "views/settings/GeneralSetting/ThemeColorSelector";
import { recordEvent } from "../config/Rudderstack";




export const getEnv = () => {
  return process.env["REACT_APP_ENVIRONMENT"];
};

export const repeatElement = (element: any, n: number) => {
  return Array(n).fill(element);
};

/*
 * @param name name of the event
 * @param payload payload of the data to be sent across
 */
export const recordRSEvent = (name: string, payload: any = {}): void => {
  let accountId: string | undefined = undefined;
  let userId: string | undefined = undefined;
  let decoded: any | undefined = undefined;

  const at = localStorage.getItem("at");
  const accountName = localStorage.getItem("accountName");
  if (at) {
    decoded = jwt_decode(at);
  }
  if (decoded) {
    accountId = decoded.accountId;
    userId = decoded?.userId;
  }

  const deviceType = getDeviceType();

  let commonData: {} = {
    timestamp: new Date().toISOString(),
    account_id: accountId,
    account_name: accountName || "",
    deviceType,
    userId
  };
  payload = { ...payload, ...commonData };
  recordEvent(name, payload);
};

export const checkElementsCollectLocation = (element: any) => {
  if (element?.data && element.data.hasOwnProperty("collectLocation")) {
    return element;
  } else {
    const updatedElements = element;
    return {
      ...updatedElements,
      data: { ...updatedElements.data, collectLocation: true },
    };
  }
};

export const isUniquePermissionName = (baseName: string, configs: PermissionConfiguration[]) => {
  if (!configs.some(config => config.permissionSetName.toLowerCase() === baseName.toLowerCase())) {
    return true;
  }
  return false;
};

export const getPermissionLevels = (resourceType: string, permissionConfiguration: PermissionConfiguration[]) => {
  if (resourceType === ResourceTypeConstant.TABLE) {
    const permissionLevelList = permissionConfiguration.map((permission) => {
      return { value: permission.permissionSetName, label: permission.permissionSetName.charAt(0).toUpperCase() + permission.permissionSetName.slice(1) };
    });
    if (permissionLevelList) return permissionLevelList;
    else return permissionLevels;
  }
  return [];
}

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

//remove duplicate values from array
export const removeDuplicates = (arr: any[]) => {
  return arr.filter((elem, index, self) => index === self.indexOf(elem));
};

export const removeBraces = (str: string) => {
  return !!str?.length && str.replace(/[#]/g, "");
};

export const getAllApiIndexes = (data: any[], checkFlag: string) => {
  return data?.reduce((acc, curr, index) => {
    if (curr?.actionId === checkFlag) {
      acc.push(index);
    }
    return acc;
  }, []);
};

export const getTextBeforeSpacing = (str: string) => {
  return str.split(" ")?.[0];
};

export const storeGlobalVariablesData = (arrayData: any) => {
  if (isObject(arrayData)) {
    Object.entries(arrayData).forEach(([key, value]) => {
      // @ts-ignore
      window[key] = arrayData[key];
    });
  }
};

export enum OnboardingIdsForComponent {
  TABLE_SELECT_DROPDOWN = "table-select-dropdown",
  CREATE_NEW_TABLE_BUTTON = "create-new-table-button",
  CREATE_NEW_TABLE_MODAL_CREATE_BUTTON = "create-new-table-modal-create-button",
  CONFIGURE_TABLE_AUTOMATION_BUTTON = "configure-automation-button",
  CREATE_NEW_TABLE_RECORD_BUTTON = "create-new-table-record-button",
  CREATE_NEW_TABLE_RECORD_CREATE_BUTTON = "create-new-table-record-create-button",
  CREATE_NEW_COLUMN_BUTTON = "create-new-column-button",
  CREATE_NEW_COLUMN_MODAL_CREATE_BUTTON = "create-new-column-modal-create-button",
  EDIT_COLUMN_MODAL_BUTTON = "edit-column-modal-update-button",
  EDIT_COLUMN_BUTTON = "edit-column-button",
  DELETE_TABLE_RECORD_BUTTON = "delete-table-record-button",
}
export function stopPropagate(callback: () => void) {
  return (e: { stopPropagation: () => void; preventDefault: () => void }) => {
    e?.stopPropagation();
    e?.preventDefault();
    callback();
  };
}

export const themeColors: string[] = [
  "#3C69E7", // #3054B9
  "#3054B9",
  "#3E4784",
  "#444CE7",
  "#525252", // 111111
  "#475467",
  "#BA24D5",
  "#7839EE", // DD04A8
  "#099250", // 008F8A
  "#E04F16", // D54402
  "#CA8504", // BB8B00
  "#E31B54", // DC053C
];

export const getAppPrimaryColor = (primaryColor: string) => {
  if (primaryColor === "#3054B9") {
    return "#3C69E7";
  } else if (primaryColor === "#111111") {
    return "#525252";
  } else if (primaryColor === "#DD04A8") {
    return "#7839EE";
  } else if (primaryColor === "#008F8A") {
    return "#099250";
  } else if (primaryColor === "#D54402") {
    return "#E04F16";
  } else if (primaryColor === "#BB8B00") {
    return "#CA8504";
  } else if (primaryColor === "#DC053C") {
    return "#E31B54";
  }
  return "#3C69E7";
};

export const listOfValueColorPallet: Color[] = [
  {
    primary: "#C01048",
    secondary: "#FFF1F3",
    tertiary: "#FCE8EA",
  },
  {
    primary: "#3054B9",
    secondary: "#ECF3FF",
    tertiary: "#E0E9FF",
  },
  {
    primary: "#A15C07",
    secondary: "#FEF7C3",
    tertiary: "#FEF0A6",
  },
  {
    primary: "#B93815",
    secondary: "#FDEAD7",
    tertiary: "#FCDCC6",
  },
  {
    primary: "#344054",
    secondary: "#EAECF0",
    tertiary: "#E0E3E8",
  },
  {
    primary: "#087443",
    secondary: "#D3F8DF",
    tertiary: "#C2F0D0",
  },
  {
    primary: "#6927DA",
    secondary: "#ECE9FE",
    tertiary: "#E2E0FD",
  },
  {
    primary: "#9F1AB1",
    secondary: "#FBE8FF",
    tertiary: "#F6DFFC",
  },
  {
    primary: "#3C69E7",
    secondary: "#E6F0FF",
    tertiary: "#D0E0FF",
  },
];

// Reverse the key value pair of complimentColor
export const complimentColor: any = {
  "#C01048": "#FFF1F3",
  "#3054B9": "#ECF3FF",
  "#A15C07": "#FEF7C3",
  "#B93815": "#FDEAD7",
  "#344054": "#EAECF0",
  "#087443": "#D3F8DF",
  "#6927DA": "#ECE9FE",
  "#9F1AB1": "#FBE8FF",
};

export const urlRegex = new RegExp(
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
);

export const getRunTimeVariableData = (
  variableTreeData: any,
  mockData: any
) => {
  const DuplicateVariableTreeData = cloneDeep(variableTreeData);
  Object.assign(DuplicateVariableTreeData, { executionContext: {} });
  const mockDataArray = JSON.parse(JSON.stringify(mockData));
  const runTimeVariableArray: any = {};

  Object.keys(mockDataArray).forEach((mockVariable) => {
    Object.keys(DuplicateVariableTreeData).forEach((variableData) => {
      if (variableData.includes(mockVariable)) {
        if (mockVariable == "appComponents") {
          if (!runTimeVariableArray.hasOwnProperty("appComponents")) {
            runTimeVariableArray.appComponents = {};
          }
          DuplicateVariableTreeData.appComponents.forEach(
            (mockAppComponentsData: any) => {
              runTimeVariableArray.appComponents[mockAppComponentsData.id] =
                mockDataArray[mockVariable][mockAppComponentsData.id];
            }
          );
        } else {
          runTimeVariableArray[mockVariable] = mockDataArray[mockVariable];
        }
      }
    });
  });

  return runTimeVariableArray;
};

export const clearGlobalVariablesData = (arrayData: any) => {
  if (isObject(arrayData)) {
    Object.entries(arrayData).forEach(([key, value]) => {
      // @ts-ignore
      delete window[key];
    });
  }
};

//clear all global variables
export const clearGlobalVariables = (arrayData: any[]) => {
  if (Array.isArray(arrayData)) {
    arrayData.forEach((element: any) => {
      if (isObject(element)) {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            // @ts-ignore
            delete window[key];
          }
        }
      } else {
        delete window[element];
      }
    });
  }
};

export const getModifiedData = (data: any[], element: any) => {
  const dataObject = {};
  if (Array.isArray(data)) {
    data.forEach((key: string) => {
      if (isObject(key)) {
        // @ts-ignore
        for (const item in key) {
          // @ts-ignore
          if (key.hasOwnProperty(item)) {
            // @ts-ignore
            dataObject[item] = key[item];
          }
        }
      } else {
        // @ts-ignore
        dataObject[key] = element[key] || {};
      }
    });

    // // @ts-ignore
    // dataObject.task = element?.task;
    // // @ts-ignore
    // dataObject.loggedInUser = element?.loggedInUser;
    return dataObject;
  }
};

//in a given object of objects, remove the objects where there keys are not equal to task and loggedInUser
export const removeUnwantedData = (data: any[]) => {
  if (Array.isArray(data)) {
    return data.reduce((acc, curr) => {
      if (isObject(curr)) {
        for (const key in curr) {
          if (curr.hasOwnProperty(key)) {
            if (key === "task" || key === "loggedInUser") {
              // @ts-ignore
              acc[key] = curr[key];
            }
          }
        }
      } else {
        acc[curr] = acc[curr] || {};
      }
      return acc;
    }, {});
  }
};

//in string with comma separated values, return an array of values
export const getArrayFromString = (str: string) => {
  if (isString(str)) {
    return str.split(",");
  }
};

export const getManagementPath = (path: string) => {
  const managementPath = path.split("/");
  const mainContext =
    managementPath[1].slice(-1) === "s"
      ? capitalize(
        managementPath[1]?.substring(0, managementPath[1]?.length - 1)
      )
      : capitalize(managementPath[1]);

  if (
    (managementPath.length === 3 || managementPath.length === 2) &&
    managementPath.includes("home")
  ) {
    return `Home`;
  } else if (
    (managementPath.length === 3 || managementPath.length === 2) &&
    managementPath.includes("document")
  ) {
    return `API Documentation`;
  } else if (
    managementPath.length === 2 &&
    !mainContext?.toLocaleLowerCase()?.startsWith("chrono")
  ) {
    return `${mainContext} Management`;
  } else if (managementPath.includes("entity")) {
    return ``;
  } else if (
    managementPath.length === 3 &&
    !managementPath.includes("create") &&
    mainContext !== "Setting"
  ) {
    return `Update ${managementPath[2]} - ${mainContext}`;
  } else if (
    managementPath.length === 3 &&
    !managementPath.includes("create") &&
    mainContext === "Setting"
  ) {
    return `${capitalize(managementPath[2])} Management`;
  } else if (managementPath.length === 3 && managementPath.includes("create")) {
    return `Create ${mainContext}`;
  } else if (managementPath.length === 4 && !managementPath.includes("show")) {
    return `Show ${managementPath[2]} `;
  } else if (managementPath.length === 4 && managementPath.includes("show")) {
    return `${mainContext} Details - ${managementPath[2]} `;
  }
};

//given location pathname return true if it includes 'workflows', 'create', and 'show'
export const isWorkflowPath = (path: string) => {
  const pathArray = path.split("/");
  if (
    pathArray.includes("workflows") &&
    (pathArray.includes("create") || pathArray.includes("show"))
  ) {
    return true;
  }
  return false;
};

//function to return a string with ellipses, add ellipses after given number of characters
export const getStringEllipsis = (str: string, n: number) => {
  return str.length > n ? str.substring(0, n - 1) + "..." : str;
};

export const getDeviceType = () => {
  try {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  } catch (error) {
    return "mobile";
  }
};

/**
 * This utility function can be called when we need to send (email) communication to user
 * when workflow was created from smaller devices (phone/mobile)
 */
export const communicateToUserOnOpeningWorkflowFromPhone = () => {
  if (getDeviceType() === "mobile") {
    const at = localStorage.getItem("at");
    if (at) {
      const decoded: any = jwt_decode(at);
      if (decoded) {
        UserService.getUser(decoded?.userId).then(
          (response: ZorpResponse | undefined) => {
            if (response?.data?.email)
              return axios.post(
                "https://n8n.zorp.one/webhook/e1a57c9e-7710-4f80-8dba-d044129e47b1",
                response?.data
              );
          }
        );
      }
    }
    // axios.post('https://n8n.zorp.one/webhook/e1a57c9e-7710-4f80-8dba-d044129e47b1', jwt_decode(localStorage.getItem('at') || ""))
  }
};

export const getTemplateIdFromNewWorkflow = () => {
  if (process.env["REACT_APP_ENVIRONMENT"] === "prod") {
    return "wiv4et";
  } else {
    return "nrui14";
  }
};

export const getAccountId = () => {
  const at = jwt_decode(localStorage.getItem("at") || "");
  //@ts-ignore
  return at?.accountId;
};

export const getUserInfo = () => {
  if (localStorage.getItem("at"))
    return jwt_decode(localStorage.getItem("at") || "");
  else
    return {};
};

export const getAccountDetails = async () => {
  const accountId = getAccountId();
  const res: any = await AccountService.getAccount(accountId);
  return res?.data;
};

/**
 * To remove lat,long, address key if the value is empty
 * To send null if LOT/List value is []
 * @param data
 */

export const modifyFormDataForBackend = (data: any) => {
  const dataKeys = Object.keys(data);
  // loop through dataKeys and check if the value is an object
  dataKeys.forEach((key) => {
    if (data[key] && isPlainObject(data[key])) {
      // get all the keys of the object
      const objectKeys = Object.keys(data[key]);
      // check if ojectKey has a value called address, latitude and longitude
      if (
        objectKeys.includes("address") &&
        objectKeys.includes("latitude") &&
        objectKeys.includes("longitude")
      ) {
        // check if the value of address, latitude and longitude is undefined
        if (
          (data[key].address === undefined || data[key].address === "") &&
          data[key].latitude === undefined &&
          data[key].longitude === undefined
        ) {
          // if yes, then delete the key from data
          delete data[key];
        }
      }
    }

    if (Array.isArray(data[key])) {
      if (data[key].length === 0) {
        data[key] = null;
      }
    }
  });

  return data;
};

/**
 * This TypeScript function extracts the file extension from a given URL.
 * @param {string} url - The `url` parameter is a string representing the URL of a file.
 * @returns The function `getFileExtension` takes a string parameter `url` and returns the file
 * extension of the URL. The file extension is extracted by splitting the URL string by "." and
 * returning the last element of the resulting array. Therefore, the function returns a string
 * representing the file extension.
 */
export const getFileExtension = (url: string) => {
  if(!isString(url)) return ""
  const extension = url?.split(".");
  const format = extension[extension.length - 1];
  return format;
};

export const checkIfUrlIsOfImage = (url: string) => {
  const format = getFileExtension(url);
  if (["png", "gif", "jpeg", "jpg"].includes(format)) {
    return true;
  }
};

export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

/**
 * This function returns a base URL based on the environment variable "REACT_APP_ENVIRONMENT".
 * @returns The function `getBaseUrl` returns a URL based on the value of the `REACT_APP_ENVIRONMENT`
 * environment variable. If the value is "prod", it returns "https://app.zorp.one". If the value is
 * "dev", it returns "https://controlcenter-ab.dev.zorp.one". Otherwise, it returns
 * "http://localhost:3000".
 */
export const getBaseUrl = () => {
  if (process.env["REACT_APP_ENVIRONMENT"] === "prod") {
    return "https://app.zorp.one";
  } else if (process.env["REACT_APP_ENVIRONMENT"] === "dev") {
    return "https://controlcenter-ab.dev.zorp.one";
  } else {
    return "http://localhost:3000";
  }
};

/* The above code is defining a constant object named "device" that contains media query strings for
different device sizes. The media query strings are defined using the "max-width" property and the
values are obtained from a separate "size" object that is not shown in the code snippet. These media
query strings can be used in CSS to apply different styles based on the device size. */
export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

/**
 * This function extracts the company name from the current URL and returns it in lowercase.
 * @returns the extracted company name from the current URL in lowercase.
 */
export const extractCompanyName = () => {
  // get the current URL
  let url = window.location.href;

  // Remove the protocol prefix if present
  url = url.replace(/^(https?:\/\/)?/, "");

  // Remove URL parameters if present
  url = url.split("?")[0];

  // Split the URL by dots and extract the company name
  let parts = url.split(".");

  // Check for "www" prefix
  if (parts[0] === "www") {
    parts = parts.slice(1);
  }

  // Check for subdomains
  // if (parts.length > 2) {
  //   parts = parts.slice(parts.length - 2);
  // }

  // Extract the company name
  let company = parts[0];

  // Convert to lowercase
  company = company.toLowerCase();

  return company;
};

/* The above code is a TypeScript module that exports a function called `isWhitelabelledUrl`. This
function checks if the current URL belongs to a whitelabelled company or not. It does this by first
extracting the company name from the URL using the `extractCompanyName` function (which is not shown
in the code snippet). If the company name contains "localhost", the function returns true. If the
company name is not in the `nonWhitelabelledUrl` array, the function returns true. Otherwise, it
returns false. */

const nonWhitelabelledUrl = [
  "app",
  "controlcenter-ab",
  "controlcenter",
  "localhost:3000",
  "dashboard",
  "preview",
  "bude"
];

export const isWhitelabelledUrl = () => {
  const companyName = extractCompanyName();
  if (companyName.includes("localhost")) {
    return false;
  }
  if (companyName && !nonWhitelabelledUrl.includes(companyName)) {
    return true;
  }
  return false;
};

// WRITE A FUNCTION THAT TAKE A STRING AND RETURN A STRING WITH WITH NO SPACE AND NO SPECIAL CHARACTERS. REPLACE SPACE WITH UNDERSCORE
export const removeSpecialCharacters = (str: string) => {
  // trim str and replace all special characters with underscore
  return str.trim().replace(/[^a-zA-Z0-9]/g, "_");
};

export const convertJson2CSV = (data: { data: any; headers: string[] }) => {
  const { data: jsonData, headers } = data;
  const csv = json2csv(jsonData);
  return csv;
};

export const checkActiveUrl = (url: string) => {
  const currentUrl = window.location.href;
  if (currentUrl.includes(url)) {
    return true;
  }
  return false;
};

/**
 * accountId
: 
"1"
ancestorTeamIds
: 
["uh34q0"]
createdOn
: 
"2022-01-05T06:33:19.318+00:00"
data
: 
null
description
: 
"demo team"
isActive
: 
true
isBillingTeam
: 
null
name
: 
"demo team"
parentTeam
: 
{teamId: "9ga1e9", accountId: "1", createdOn: "2021-09-05T19:45:27.683+00:00",…}
parentTeamId
: 
"9ga1e9"
teamId
: 
"2hb8ss"
updatedOn
: 
"2022-01-05T06:33:19.318+00:00"
 */

export interface TeamInfo {
}

export const getTeamInfoFromTeamId = (teamId: string): undefined | TeamInfo => {
};
export function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 24, height: 24, fontSize: "12px"
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')?.[1]?.[0] || ''}`,
  };
}

export function extractDependencies(permissionsList: { [key: string]: { [key: string]: any } }): { [key: string]: string[] } {
  const dependenciesMap: { [key: string]: string[] } = {};

  Object.values(permissionsList).forEach(category => {
    Object.values(category).forEach(permission => {
      dependenciesMap[permission.id] = permission.dependencies;
    });
  });

  return dependenciesMap;
}

export function generateDefaultPermissions(permissionsList: { [key: string]: { [key: string]: any } }): { [key: string]: boolean } {
  const defaultPermissions: { [key: string]: boolean } = {};

  Object.values(permissionsList).forEach(category => {
    Object.values(category).forEach(permission => {
      defaultPermissions[permission.id] = false;
    });
  });

  return defaultPermissions;
}

function stringToColor(string: string) {
  // if string is undefined, set it to John Doe
  string = string || "John Doe";
  let hash = 0;
  let i;


  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

interface CurrentTableState extends ViewState {
  columnFilters: {
    id: string;
    value: unknown;
  }[]
}

export const getViewsObj = (payload?: ViewState) => {
  if (!payload) {
    return;
  }
  let currentTableState: CurrentTableState = {
    sorting: payload.sorting || [],
    pagination: payload.pagination || { pageIndex: 0, pageSize: 10 },
    columnPinning: payload.columnPinning || { left: ['mrt-row-select'], right: ["showRecord", "addRow"] },
    columnFilters: payload.columnFilters || [],
    columnFilterFns: payload.columnFilterFns || {},
    globalFilter: payload.globalFilter || '',
    showColumnFilters: payload.showColumnFilters ? true : false,
    showGlobalFilter: payload.showGlobalFilter ? true : false,
  };

  if (payload.columnVisibility) {
    currentTableState.columnVisibility = payload.columnVisibility;
  }

  if (payload.columnOrdering && Array.isArray(payload.columnOrdering) && (payload.columnOrdering.length > 0)) {
    currentTableState.columnOrdering = payload.columnOrdering.filter(field => field !== 'comments');
  }

  if (payload.columnFilterFns) {
    currentTableState.columnFilterFns = payload.columnFilterFns
  }

  if (payload.pagination) {
    currentTableState.pagination = payload.pagination
  }

  return currentTableState;
}

export const deduplicateArray = (array: any[]) => {
  return Array.from(new Set(array));
};

export const getCreateViewPayload = (fields: any[], payload?: any) => {
  const columnOrdering: string[] = deduplicateArray((payload && payload.columnOrdering) || ['mrt-row-select', 'recordId', 'createdOn', 'updatedOn', ...fields.map(field => field.name).filter(name => name !== 'comments')]);
  const sorting: any[] = (payload && payload.sorting) || [];
  const columnVisibility: any = (payload && payload.columnVisibility) || undefined;
  const columnFilters: any[] = (payload && payload.columnFilters) || [];
  const globalFilter: string = (payload && payload.globalFilter) || '';
  const pagination: { pageIndex: number, pageSize: number } = (payload && payload.pagination) || { pageIndex: 0, pageSize: 10 };
  const columnPinning: { left: string[], right: string[] } = (payload && payload.columnPinning) || { left: ['mrt-row-select'], right: ["showRecord", "addRow"] };

  let initialColumnFilterFns: { [id: string]: string } = { ...((payload && payload.columnFilterFns) || {}) };

const columnFilterFns = columnFilters.reduce((acc, filter: { id: string; value: unknown }) => {
  acc[filter.id] = initialColumnFilterFns[filter.id] || 'contains';
  return acc;
}, initialColumnFilterFns);

  return {
    columnOrdering,
    sorting,
    columnVisibility,
    columnFilters,
    columnFilterFns,
    globalFilter,
    pagination,
    columnPinning,
  }
}


export const getTablePropsLSKey = ({ type, tableId, viewId }: {
  type: string,
  tableId: string,
  viewId?: string
}) => {
  /**\
   * columnFilterFnsKey
   * columnPinningKey
   * columnFilterKey
   * columnVisibilityKey
   * densityKey
   * globalFilterKey
   * showGlobalFilterKey
   * showColumnFiltersKey
   * sortingKey
   * paginationKey
   * columnOrderingKey
   */
  switch (type) {
    case "columnFilterFnsKey":
      if (viewId) {
        return `mrt_columnFilterFns_table_${tableId}_${viewId}`
      } else {
        return `mrt_columnFilterFns_table_${tableId}`
      }

    case "columnPinningKey":
      if (viewId) {
        return `mrt_columnPinning_table_${tableId}_${viewId}`
      } else {
        return `mrt_columnPinning_table_${tableId}`
      }
    case "columnFilterKey":
      if (viewId) {
        return `mrt_columnFilters_table_${tableId}_${viewId}`
      } else {
        return `mrt_columnFilters_table_${tableId}`
      }
    case "columnVisibilityKey":
      if (viewId) {
        return `mrt_columnVisibility_table_${tableId}_${viewId}`
      } else {
        return `mrt_columnVisibility_table_${tableId}`
      }
    case "densityKey":
      if (viewId) {
        return `mrt_density_table_${tableId}_${viewId}`
      } else {
        return `mrt_density_table_${tableId}`
      }
    case "globalFilterKey":
      if (viewId) {
        return `mrt_globalFilter_table_${tableId}_${viewId}`
      } else {
        return `mrt_globalFilter_table_${tableId}`
      }
    case "showGlobalFilterKey":
      if (viewId) {
        return `mrt_showGlobalFilter_table_${tableId}_${viewId}`
      } else {
        return `mrt_showGlobalFilter_table_${tableId}`
      }
    case "showColumnFiltersKey":
      if (viewId) {
        return `mrt_showColumnFilters_table_${tableId}_${viewId}`
      } else {
        return `mrt_showColumnFilters_table_${tableId}`
      }
    case "sortingKey":
      if (viewId) {
        return `mrt_sorting_table_${tableId}_${viewId}`
      } else {
        return `mrt_sorting_table_${tableId}`
      }
    case "paginationKey":
      if (viewId) {
        return `mrt_pagination_table_${tableId}_${viewId}`
      } else {
        return `mrt_pagination_table_${tableId}`
      }
    case "columnOrderingKey":
      if (viewId) {
        return `mrt_columnOrdering_table_${tableId}_${viewId}`
      } else {
        return `mrt_columnOrdering_table_${tableId}`
      }
    default:
      return `${type}-${tableId}-${viewId}`
  }

}

export const getPlaceholderText = (dataType: string): string => {
  switch (dataType) {
    case "TEXT":
      return "Add text";
    case "NUMBER":
      return "Add a value";
    case "DATETIME":
      return "DD-MMM-YYYY hh:mm";
    case "RELATION":
      return "Add a relation";
    case "BOOLEAN":
      return "Choose an option";
    case "BUTTON":
      return "Not applicable";
    case "IMAGE":
      return "Add an image";
    case "LIST_OF_TEXT":
      return "Choose an item";
    case "FILE":
      return "Add a file";
    case "LOCATION":
      return "Add a location";
    case "COMPUTED_COLUMN":
      return "Computation Failed";
    case "USER":
      return "Select a user";
      case "TEAM":
        return "Select a team";
    default:
      return "Add text";
  }
}

export function formatDate(date?: string) {
  if (!date) return ""
  const now = moment();
  const inputDate = moment(date);
  const diffMinutes = now.diff(inputDate, 'minutes');
  const diffHours = now.diff(inputDate, 'hours');
  const diffDays = now.diff(inputDate, 'days');

  if (diffMinutes < 1) {
    return 'Now';
  } else if (diffMinutes < 60) {
    return `at ${inputDate.format('hh:mm a')}`;
  } else if (diffHours < 4) {
    return `at ${inputDate.format('hh:mm a')}`;
  } else if (diffHours < 24) {
    return 'Today';
  } else if (diffDays <= 1) {
    return 'Yesterday';
  } else if (diffDays <= 30) {
    return `${diffDays} days ago`;
  } else if (now.year() === inputDate.year()) {
    return inputDate.format('MMMM');
  } else {
    return inputDate.format('YYYY');
  }
}

export const truncateString = (str: string | any, num: number): string => {
  if (str && typeof str === 'string' && str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};


export const timeStampToReadableFormat = (isoTimestamp: string) => {
  const date = moment(isoTimestamp);
  // const formatStructure = "h:mm A, MMM D";
  const formatStructure = "h:mm A";
  const formattedDate = date.format(formatStructure);
  return formattedDate;
}
//2023-12-05T04:17:35.980Z
/**
 * convert above date to readable format using moment
 */
export const convertDateToReadableFormat = (date: string) => {
  return moment(date).format("DD MMM YYYY hh:mm A");
}

export function convertDatafieldNameToReadableFormat(input: string) {
  // Replace underscores with spaces and convert to lowercase
  return input.replace(/_/g, ' ').toLowerCase();
}

export const getUserByUserId = async (userId:string) => {
  try {
    const res = await UserService.getUser(userId);
    return res?.data;
  } catch (error) {
    return null;
  }
}

export const getTeamByTeamId = async (teamId:string) => {
  try {
    const res = await TeamService.getTeam(teamId);
    return res?.data;
  } catch (error) {
    return null;
  }}
/**
 * write a function to check if the function is a valid url
 */
export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}