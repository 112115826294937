import styled from "styled-components"
import { recordRSEvent } from "utils/CommonUtils"
const Container = styled.div`
    display:flex;
    border: "1px solid rgba(0,0,0,0.1)";
    border-radius: "4px";
    min-height:9vh;
    background-color:white;
    padding:10px;
    padding-bottom:0px;
    &:hover {
     cursor:pointer;
  }
`

const IconContainer = styled.div`
    height:83%;
    background-color:#f0f2f3;
    display:flex;
    align-items:center;
    padding:10px;
    border-radius:4px;
`

const Details = styled.div`
    margin-left:8px;
`

const Heading = styled.h2`
    font-size:14px;
    line-height:16px;
    font-weight:600;
    margin: 0px !important;
`

const Info = styled.p`
    font-size:12px;
    font-weight:400;
    line-height:16px;
    color:#808080;
`

type PropTypes = {
    icon:any,
    info:string,
    heading:string,
    nodeType:string,
    addNodeOnClick:any
}


const DropdownItem = ({icon,info,heading,nodeType,addNodeOnClick}:PropTypes) => {

    const onDragStart = (event:any, nodeType:string) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };
  return (
    <li> 
        <Container 
        // onDragStart={(event) => {
        //     onDragStart(event, nodeType)
        //     }} draggable
        onClick={(event) =>{
            //@ts-ignore
            const reactFlowDiv = document.getElementsByClassName("reactflow-wrapper")[0]
            addNodeOnClick(event,reactFlowDiv,nodeType)
            recordRSEvent("addnode_button_click",{
                context:"builder_general",
                node_type:nodeType
            })
        }}  
        >
            <IconContainer>
                {icon}
            </IconContainer>
            <Details>
                <Heading> {heading} </Heading>
                <Info> 
                    {info}
                </Info>
            </Details>
        </Container>
    </li>
  )
}

export default DropdownItem