//@ts-nocheck
import styled from "styled-components"


export const OnboardingContainer = styled.div`
  
  position: relative;
  max-width: 68vw;
  overflow: auto;
  background-color: white;
  padding: 15px;
  border: 1px solid #c2c9d1;
  /* border-radius: 4px; */
  @media (max-height: 700px) {
    height: 95vh;
    /* width: 100vw; */
  }
  @media (max-width: 600px) {
    height: 70%;
    max-width: 100%;
  }
`;

export const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 35% 65%;
  padding: 20px;
  gap: 24px;
`

export const Image = styled.img`
    margin-top: 10px;
`

export const Text = styled.p`
    font-size:  ${(props:{large?:boolean, medium?:boolean,secondary?: boolean,left?:boolean, link?:boolean}) => props.large ? "18px" : props.medium ? "16px" : "14px"};
    font-weight: 600;
    margin-bottom: 20px !important;
    text-align: ${(props:{left?:boolean}) => props.left ? "left" : "center"};
    color: ${(props:{secondary?: boolean, link?:boolean}) => props.secondary ? "rgba(0, 0, 0, 0.5)" : props.link ? props.theme.color.primary : ""};
    svg {
      display: inline;
    }
`

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;

    @media (max-width: 550px) {
      display: grid;
      right: 0;
      width: 100%;
      padding: 10px;
    }

    @media (max-height: 700px) {
    bottom: ${(props:{makeBottom?:boolean}) => props?.makeBottom ? "15px" : "10px"};
    }

    @media (max-height: 650px) {
      position: relative;
      margin-top: 15px;
      display: grid;
      /* display: flex; */
      /* float: right; */
    }
`