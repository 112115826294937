import { Box, Button, Divider, FormControl, FormLabel, Link, TextField } from '@mui/material';
import logo from 'assests/darklogo.svg';
import { PageCategoryEnum, recordPageVisit } from 'config/Rudderstack';
import useQuery from 'hooks/useQuery';
import useSessionStorage from 'hooks/useSessionStorage';
import { isEmpty } from 'lodash';
import notification from 'notifications/notifications';
import authProvider from 'providers/auth/authProvider';
import { AccountService } from 'providers/data/services/AccountService';
import { UserService } from 'providers/data/services/UserService.ts';
import { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
// import GoogleLogin from 'react-google-login';
import { Navigate, useNavigate } from 'react-router-dom';
import { extractCompanyName, recordRSEvent } from 'utils/CommonUtils';
import { userDecodedData } from 'utils/UserAuth.utils';
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import LoadingScreen from 'views/LoadingScreen/LoadingScreen';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

/**
 * The SignUp function is a React component that renders a sign-up form with options to sign up with
 * Google or enter a work email, and redirects to an account setup page upon successful sign-up.
 * @returns The component `SignUp` is being returned.
 */
const SignUp = () => {
  const notify = useNotify();

  const clientId = '835132187391-ad60oo7mp97hmrs540sd3hj71e2lgh4o.apps.googleusercontent.com';
  const navigate = useNavigate();
  const query = useQuery();

  // const [templateId, setTemplateId] = useSessionStorage('templateId', '');
  // const [solutionId, setSolutionId] = useSessionStorage('solutionId', '');
  const [templateId, setTemplateId] = useSessionStorage("templateId", query.get("templateId") ||  localStorage.getItem("templateId") || "");
  const [solutionId, setSolutionId] = useSessionStorage("solutionId", query.get("solutionId") ||  localStorage.getItem("solutionId") || "");
  const [redirectUrl, setRedirectUrl] = useSessionStorage("redirectUrl", query.get("redirectUrl") ||  localStorage.getItem("redirectUrl") || "");
 
  const [logoImage, setLogoImage] = useState('');

  const getCompanyLogo = async () => {
      const companyName = extractCompanyName()
      try {
        const res = await AccountService.getCompanyLogo(companyName);
        if (res?.data?.logo) {
          setLogoImage(res.data.logo);
        }
      } catch (error) {
        console.log(error)
      }
  } 

  // if templateId exists

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.signup, 'signup_page');
    getCompanyLogo()
  }, []);

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const onSuccess = (response) => {
    setLoading(true);
    const profile = jwt_decode(response.credential);
    const { email } = profile
    UserService.userSignup({ email, verified: true})
      .then((res) => {
        if (!res || (res?.code && res?.code.indexOf('200') < 0)) {
          setLoading(false);
          if (res?.code === '401') {
            notification('error', res?.message);
            recordRSEvent("signup_event_fail", {
              context:"signup",
              email,
              error:res?.message
            })
            authProvider.logout();
          } else {
            recordRSEvent("signup_event_fail", {
              context:"signup",
              email
            })
            notification('error', res?.message);
          }
        } else {
          setLoading(false);
          // localStorage.setItem('gaAT', response.accessToken);
          recordRSEvent("signup_event_success", {
            context:"signup",
            email
          })
          navigate(`/account-setup/${email}?templateId=${templateId}&solutionId=${solutionId}&redirectUrl=${redirectUrl}`);

        }
      })
      .catch((err) => {
        console.log('errror--', err);
        notification('error', err?.message || 'You dont have permissions to control center');
        setLoading(false);
        recordRSEvent("signup_event_fail", {
          context:"signup",
          email,
          error:err?.message
        })
      });
  };

  const onError = (response) => {
    recordRSEvent("signup_event_fail", {
      context:"signup",
      email
    })
    // notification('error', 'There is something wrong, please try again later');
  };

  if (!isEmpty(userDecodedData())) {
    return (
      <Navigate
        to={{
          pathname: '/',
        }}
      />
    );
  }

  const submit = (e) => {
    recordRSEvent('signup_button_click', {
      context: 'signup',
      email
    });
    setLoading(true);
    e.preventDefault();
    UserService.userSignup({ email: email })
      .then((res) => {
        if (!res || (res?.code && res?.code.indexOf('200') < 0)) {
          setLoading(false);
          if (res?.code === '401') {
            notification('error', res.message);
            authProvider.logout();
            recordRSEvent("signup_event_fail", {
              context:"signup",
              email
            })
          } else {
            recordRSEvent("signup_event_fail", {
              context:"signup",
              email
            })
            notification('error', res.message);
          }
        } else {
          setLoading(false);
          recordRSEvent("signup_event_success", {
            context:"signup",
            email
          })
          navigate(`/awaiting-verification/${email}`);
          // navigate(`/account-setup/${email}?id=6302675977d4957971481b44&templateId=${templateId}`);
        }
      })
      .catch((err) => {
        notification('error', err);
        // notify(err?.message);
        recordRSEvent("signup_event_fail", {
          context:"signup",
          email
        })
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className='signup'>
          <div className='signupInner'>
            <div className='signupImg'>
              <img src={logoImage || logo} alt='' />
            </div>
            <h2 className='signupTitle'>Sign Up </h2>
            {/* <GoogleLogin
              className={`socialLoginButton googleLoginButtonLogin`}
              onSuccess={onSuccess}
              onFailure={onError}
              clientId={clientId}
              
            >
              Sign Up with Google
            </GoogleLogin> */}
            <GoogleLogin
              className="googleLoginButtonLogin"
              onSuccess={onSuccess}
              onFailure={onError}
              size='large'
              theme='filled_blue'
              width='320px'
            />

            <Divider sx={{ mt: 3, mb: 3, color: 'black' }} className='signup-divider'>
              OR
            </Divider>
            <form className='signupForm' onSubmit={submit}>
            <Box sx={{mt: 5,width:'320px' }}>
                <FormControl variant='standard' fullWidth>
                  <FormLabel
                    sx={{
                      fontFamily: "'Inter', sans-serif",
                      color: 'rgba(0,0,0,0.8)',
                      mb: 1,
                      fontWeight: 'bold',
                      fontSize: 'small',
                    }}
                    htmlFor='my-input'
                  >
                    Enter Work E-Mail
                  </FormLabel>
                  <TextField
                    id='my-input'
                    aria-describedby='my-helper-text'
                    size='small'
                    required
                    type={'email'}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
              </Box>

              <ZButton
                sx={{ boxShadow: 'none', borderRadius: '4px', backgroundColor: '#3e66fb', mt: 3, mb: 5 }}
                variant='contained'
                fullWidth
                type='submit'
              >
                Sign Up
              </ZButton>
            </form>
            <p style={{ fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>
              Already have an account?{' '}
              <Link style={{ fontWeight: '600', color: '#3e66fb' }} href={`/#/login?templateId=${templateId}&solutionId=${solutionId}&redirectUrl=${redirectUrl}`} underline='hover'>
                Login
              </Link>
            </p>
            {/* <p className='copyright'>© Zorp 2022 </p> */}
          </div>
        </div>
      )}
    </>
  );
};

export default SignUp;
