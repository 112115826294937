//@ts-nocheck
import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import {
  RudderStackAutomationBuilderContext,
  RudderStackAutomationBuilderEvents,
} from "constants/RudderStackConstants";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { recordRSEvent } from "utils/CommonUtils";
import { generateId } from "utils/Utils";
import { DataFieldType } from "views/DataField/DataField.types";
import {
  Heading,
  HeadingWrapper,
  Label,
} from "views/workflows/workflowactions/ModalContent.styles";
import IsActive from "views/workflows/workflowactions/api/components/IsActive";
import ModalSmartInputTextField from "views/workflows/workflowactions/api/components/ModalSmartInputTextField";
import { ABSelectWrapper, ABText } from "../automationBuilder.styles";
import useAutomation from "../hooks/useAutomation";
import {
  addTriggerInAutomation,
  updateTriggerInSelectedAutomation,
} from "../reducer/automationReducer";
import { ABMetadataTypeEnum } from "../types/ABType";
import ABSelect from "./AppComponents/ABSelect";

interface ITimezone {
  id: string;
  name: string;
}

const defaultTimeZone = {
  id: "Asia/Kolkata",
  name: "Asia/Kolkata",
}

const CronTrigger = () => {
  const optionLabel = (option: any) => option.name;
  const optionId = (option: any) => option.id;
  const [allTimezones, setAllTimezones] = useState<ITimezone[]>([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState<ITimezone>(defaultTimeZone);
  const {
    trigger,
    selectedTrigger,
    metadataType,
    selectedAutomation,
    clearRightSide,
  } = useAutomation();
  const dispatch = useDispatch();

  useEffect(() => {
    recordPageVisit(
      PageCategoryEnum.automationBuilder,
      RudderStackAutomationBuilderEvents.smartComponentTriggerOnLoad
    );
  }, []);

  const getTimeZoneList = () => {
    const timezonelist = moment.tz
      .names()
      .map((timezone: any) => ({ id: timezone, name: timezone }));
    setAllTimezones([...timezonelist]);
  };

  // For Smart Component Automation
const onFormSubmit = (data:FieldValues) => {

    const eventData = {
      label: "Cron Trigger",
      description: "Cron Trigger",
      trigger: data?.trigger,
      timezone: selectedTimeZone,
    };

    // Add New Trigger to selected Automation
    const triggerId = generateId(6);
    if (!selectedTrigger) {
      const newTriggerData = {
        triggerId,
        errors: [],
        event: eventData,
        metadataType,
      };

      dispatch(
        addTriggerInAutomation({
          automationId: selectedAutomation.id,
          action: newTriggerData,
        })
      );
    } else {
      // Update Existing Trigger
      dispatch(
        updateTriggerInSelectedAutomation({
          ...selectedTrigger,
          event: eventData,
        })
      );
    }

    recordRSEvent(
      RudderStackAutomationBuilderEvents.smartComponentTriggerSaveClick,
      {
        context: RudderStackAutomationBuilderContext.automationBuilderTrigger,
        automationId: selectedAutomation.id,
        triggerId: !selectedTrigger ? triggerId : selectedTrigger.triggerId,
      }
    );

    clearRightSide();
  };

  useEffect(() => {
      if (selectedTrigger?.event?.timezone) {
        setSelectedTimeZone(selectedTrigger?.event?.timezone);
      }
  }, [selectedTrigger]);

  useEffect(() => {
    getTimeZoneList();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm();

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        {metadataType == ABMetadataTypeEnum.CRON && (
          <>
            <ABText>
              Triggers are events that occur to let the user proceed to the next
              step. You can use triggers to initiate an automation.
            </ABText>
            <HeadingWrapper>
              <Heading> Triggers </Heading>
              <IsActive closeOnClick={clearRightSide} />
            </HeadingWrapper>
          </>
        )}

        {/* For AB Smart Component */}
        {trigger?.components && (
          <>
            <Label> Cron Expression </Label>
            <ModalSmartInputTextField
              value={selectedTrigger?.event?.trigger}
              placeholder="Cron Expression"
              register={register}
              dataType={DataFieldType.TEXT}
              control={control}
              errors={errors}
              name="trigger"
            />
          </>
        )}

        <ABSelectWrapper>
          <ABSelect
            options={allTimezones}
            optionLabel={optionLabel}
            optionId={optionId}
            isClearable={false}
            placeHolder={"Timezone"}
            label={"Timezone"}
            errors={errors}
            errorMessageName={"value"}
            defaultValue={
              selectedTimeZone || defaultTimeZone
            }
            onClickFunction={(optionValue) => {
              setSelectedTimeZone(optionValue)
            }}
          />
        </ABSelectWrapper>
      </form>
    </>
  );
};

export default CronTrigger;
