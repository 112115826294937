import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import FilterModal from "filter/FilterModal";
import { filterTitle } from "filter/constants/FilterConstants";
import { clearFilterStorage, getFilterCount } from "filter/filterUtils";
import useApplyFilter from "filter/hooks/useApplyFilter";
import jsonExport from "jsonexport/dist";
import { initMockData } from "lowcode/state/scriptEditorState";
import { IFilterContext } from "providers/data/services/BffService";
import { useEffect, useState } from "react";
import {
  CreateButton,
  Datagrid,
  downloadCSV,
  ExportButton,
  List,
  TextField,
  TopToolbar,
  useListContext,
} from "react-admin";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import {
  getAccountId,
  recordRSEvent
} from "utils/CommonUtils";
import { userDecodedData } from "utils/UserAuth.utils";
import { teamAutomationSaveHandler } from "views/AccountAutomation/TeamAutomationSaveHandler";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import {
  closeAutomation
} from "views/automationBuilder/reducer/automationReducer";
import { ABMetadataTypeEnum } from 'views/automationBuilder/types/ABType';
import ListPagination from "views/commonComponents/ListPagination";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { Label } from "views/settings/GeneralSetting/GeneralSettingEdit";
import { SubHeading } from "views/settings/settings.style";
import FilterButton from "views/users/components/FilterButton";
import ConfigureAutomation from "views/workflows/ConfigureAutomation";
import { TeamHierarchy } from "./TeamHierarchy";
import { AccountService } from 'providers/data/services/AccountService';
import styled from "styled-components";
import { getClasses } from "styles/tableStyles";
import { useNavigate } from "react-router-dom";
import { AutomationsIcon } from "assests";

const DialogContentStyled = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem; /* px-6 in Tailwind CSS */
  padding-right: 1.5rem; /* px-6 in Tailwind CSS */
`;

const userSearchContext = IFilterContext.TEAM;
const TeamListActions = ({
}) => {

  const  {
    displayedFilters,
    hideFilter,
    setFilters,
    showFilter,
  } = useListContext();

  const theme = useTheme()
  const classes = getClasses({theme});
  const accountId = getAccountId();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { clearRightSide } = useAutomation();
  const [openTeamAutomationBuilder, setOpenTeamAutomationBuilder] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const [prevAutomation, setPrevAutomation] = useState([]);
  const handleClose = () => setOpen(false);
  const [modalPosition, setModalPosition] = useState([0, 0, 0]); //[X,Y]
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const getFilterValue = useApplyFilter(IFilterContext.TEAM, setFilters);
  const [filterCount, setFilterCount] = useState(getFilterValue.filterCount || 0);
  const { userId = "" } = userDecodedData();
  const navigate = useNavigate();

  useEffect(() => {
    if (getFilterValue?.filterCount >= 0) {
      setFilterCount(getFilterValue.filterCount);
    }
  }, [getFilterValue.filterCount]);

  useEffect(() => {
    setFilterCount(getFilterCount(IFilterContext.TEAM, userId));
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchTeamAutomationUIFormat = async () => {
      try {
        const response = await AccountService.getAccount(accountId);
        if (isMounted) {
          setPrevAutomation(response?.data?.accountConfig?.teamAutomationUiFormat || []);
        }
      } catch (error) {
        console.error('Error fetching team automation UI format:', error);
      }
    };

    fetchTeamAutomationUIFormat();

    return () => {
      isMounted = false;
    };
  }, [accountId]);

  return (
    <>
      <TopToolbar className="workflow-toolbar setting-bar">
        <div>
          <SubHeading>
            Team Management
          </SubHeading>
          <Label
            >
              {" "}
              Manage your teams or create new team here{" "}
            </Label>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >

          <ZButton
            secondary
            style={{ padding: "5px 12px", color:'#344054', fontWeight:600, fontSize:'12px', marginRight:'8px'}}
            variant="contained"
            fontSize={14}
            startIcon={AutomationsIcon}
            onClick={()=>{
              dispatch(initMockData({taskTypeId: accountId, type:'TEAM'}));
              setOpenTeamAutomationBuilder(true);
            }}>
              Automations
          </ZButton>

        {(true || filterCount >= 0) && (
          <FilterButton
            filterCount={filterCount}
            onRemoveFilterClick={() => {
              setFilters({}, []);
              clearFilterStorage(userSearchContext, userId);

              setFilterCount(0);

              navigate({
                pathname: "/settings/teams",
                search: `?search=`,
              });
            }}
            onOpenFilterClick={(pos) => {
              const x =
                pos.current.offsetParent.offsetLeft + pos.current.offsetLeft;
              const y =
                pos.current.offsetParent.offsetParent.offsetTop +
                pos.current.offsetTop +
                pos.current.offsetHeight;
              const buttonHeight = pos.current.offsetHeight;
              const buttonWidth = pos.current.offsetWidth;
              setModalPosition([x, y, buttonHeight, buttonWidth]);
              setOpenFilterModal(true);
              showFilter("main");
            }}
          />
        )}

        {displayedFilters?.main && openFilterModal && (
          <FilterModal
            position={modalPosition}
            filterTitle={filterTitle}
            urlEndpoint={"/settings/teams"}
            context={userSearchContext}
            openModal={openFilterModal}
            handleClose={(appliedFilterCount) => {
              setOpenFilterModal(false);
              hideFilter("main");
              if (appliedFilterCount >= 0) {
                setFilterCount(appliedFilterCount);
              }
            }}
          />
        )}

          <ExportButton
            style={{
              border: "1px solid #c2c9d1",
              color: "#344054",
              fontSize: "1rem",
              fontWeight: "600",
              backgroundColor: "#fff",
              marginRight: "8px",
              padding: "6px 16px",
            }} 
            onClick={() =>
              recordRSEvent("export_icon_click", {
                context: "team_listing",
              })
            }
          />
          <ZButton
            onClick={() => {
              recordRSEvent("teamhirearchy_icon_click", {
                context: "team_listing",
              });
              handleClickOpen();
            }}
            startIcon={<AccountTreeIcon />}
            secondary
            variant="contained"
          >
            Team Hierarchy
          </ZButton>
          <CreateButton
            
            basepath="/teams"
            style={{
              ...classes.createButton,
              marginLeft: "8px",
            }}
            variant="contained"
            onClick={() => {
              recordRSEvent("newteam_button_click", {
                context: "team_listing",
              });
            }}
            label="Add Team"
          />
        </div>
      </TopToolbar>

      <ConfigureAutomation
        metaDataType={ABMetadataTypeEnum.TEAM}
        prevAutomations={prevAutomation}
        onSaveHandler={({allAutomations, dispatch})=>{
          teamAutomationSaveHandler({allAutomations, dispatch, callback :()=>{
            setOpenTeamAutomationBuilder(false);
          }})
          setPrevAutomation(allAutomations);
        }}
        onAutomationClose={() => {
          clearRightSide();
          dispatch(closeAutomation());
          setOpenTeamAutomationBuilder(false);
        }}
        otherConfigs={{
          hideGuardBlock: false,
          hideTriggerConfigureButton: false,
          showRunAutomation:true
        }}
        openAutomationBuilder={openTeamAutomationBuilder}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        className="team_hierarchy"
      >
        <DialogTitle id="form-dialog-title">
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
           Team Hierarchy
            </div>
            <div>
            
            <AiOutlineClose onClick={handleClose}/>
            </div>
          </div>
        </DialogTitle>
        <DialogContentStyled>
          <TeamHierarchy />
        </DialogContentStyled>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const TeamsList = (props) => {
  const theme = useTheme()
  const classes = getClasses({theme});
  const { userId = "", accountId = "" } = userDecodedData();

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.team, "team_list");
  }, [props]);

/**
   * Exports the provided teams data to a CSV file for download.
   * @example
   * exportTeamsData(teamsData)
   * @param {Array} teamsData - An array of team objects containing information to be exported.
   * @returns {void} No return value.
   * @description
   *   - Uses the recordRSEvent function to track the export event.
   *   - Maps the teamsData array to create a new array with only the necessary team information.
   *   - Uses the jsonExport function to convert the data to CSV format.
   *   - Uses the downloadCSV function to initiate the download of the CSV file.
   */
  const customizeUserExportField = (teamsData) => {
    recordRSEvent("export_grid", {
      context: "team_export_grid",
      userId,
    });

    const teamsForExport = teamsData.map((team) => {
      const { id, ...teamForExport } = team;
      return teamForExport;
    });

    jsonExport(
      teamsForExport,
      {
        headers: [
          "teamId",
          "name",
          "description",
          "parentTeamId",
          "parentTeam",
          "parentTeamIdWithName",
        ],
      },
      (err, csv) => {
        downloadCSV(csv, "Teams"); // download team_Data.csv file
      }
    );
  };

  return (
    <>
      <List
        {...props}
        pagination={<ListPagination />}
        exporter={customizeUserExportField}
        actions={<TeamListActions />}
        classes={{ main: classes.main }}
      >
        <div style={{ overflowX: "auto",boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)", }}>
          <Datagrid
            classes={{
              headerCell: classes.headerCell,
              headerRow: classes.headerRow,
              rowEven: classes.rowEven,
              rowOdd: classes.rowOdd,
              rowCell: classes.rowCell,
              table: classes.table,
            }}
            sx={{
              ...classes
            }}
            rowClick="show"
          >
            <TextField
              sortable={false}
              textAlign="left"
              label="Team Id"
              source="id"
            />
            <TextField sortable={false} textAlign="left" source="name" />
            <TextField
              sortable={false}
              textAlign="left"
              label="Description"
              source="description"
            />
            <TextField
              sortable={false}
              textAlign="left"
              label="Parent Team"
              source="parentTeamIdWithName"
            />
          </Datagrid>
        </div>
      </List>
    </>
  );
};
