import { ChevronLeft } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as TF,
} from "@mui/material";
import exportList from "assests/exportList.svg";
import FilterModal from "filter/FilterModal";
import { IFilterContext } from "filter/constants/Filter.types";
import { filterTitle } from "filter/constants/FilterConstants";
import { clearFilterStorage, getFilterCount } from "filter/filterUtils";
import useApplyFilter from "filter/hooks/useApplyFilter";
import useWidth from "hooks/useWidth";
import jsonExport from "jsonexport/dist";
import { isEmpty } from "lodash";
import { AccountService } from 'providers/data/services/AccountService';
import React, { useEffect, useState } from "react";
import {
  BooleanField,
  CreateButton,
  Datagrid,
  downloadCSV,
  EditButton,
  ExportButton,
  FunctionField,
  List,
  Show,
  ShowController,
  TextField,
  TopToolbar,
  useListContext,
  useRedirect,
  useRefresh
} from "react-admin";
import { AiOutlineExpandAlt } from "react-icons/ai";
import styled, { useTheme } from 'styled-components';
import { useDispatch } from "react-redux";

import Select from "react-select";
import { TaskFormBody } from "render-engine/render.styles";
import { initMockData } from "lowcode/state/scriptEditorState";
import {
  getAccountId,
  recordRSEvent
} from "utils/CommonUtils";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog";
import { userDecodedData } from "utils/UserAuth.utils";
import { userAutomationSaveHandler } from "views/AccountAutomation/UserAutomationSaveHandler";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import {
  closeAutomation
} from "views/automationBuilder/reducer/automationReducer";
import { ABMetadataTypeEnum } from 'views/automationBuilder/types/ABType';
import ListPagination from "views/commonComponents/ListPagination";
import {
  ZButton,
  ZIconButton,
} from "views/commonComponents/commonComponents.styles";
import { ZIconButton2 } from "views/entities/styles";
import { Label } from "views/settings/GeneralSetting/GeneralSettingEdit";
import { SubHeading } from "views/settings/settings.style";
import ConfigureAutomation from "views/workflows/ConfigureAutomation";
import { PageCategoryEnum, recordPageVisit } from "../../config/Rudderstack";
import notification from "../../notifications/notifications";
import { hasPermission } from "../../providers/auth/authUtils";
import { UserService } from "../../providers/data/services/UserService";
import { getClasses } from "../../styles/tableStyles";
import UserDisplayBlock from "./UserDisplayBlock";
import UserEditForm from "./UserEditForm";
import FilterButton from "./components/FilterButton";
import { useNavigate } from 'react-router-dom';
import { AutomationsIcon } from 'assests';
import {useParams} from "react-router-dom";


const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 1.5rem; /* mt-6 in Tailwind CSS */
  column-gap: 2rem; /* gap-x-8 in Tailwind CSS */

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr); /* sm:grid-cols-2 in Tailwind CSS */
  }
`;

const DialogContentStyled = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem; /* px-6 in Tailwind CSS */
  padding-right: 1.5rem; /* px-6 in Tailwind CSS */
`;


const userSearchContext = IFilterContext.USER;

const UserShowActions = ({ basePath, data, resource }) => {
  const [open, setOpen] = React.useState(false);
  const [passwd, setPasswd] = React.useState("");
  const [rePasswd, setRePasswd] = React.useState("");
  const [openDeletionConfirmation, setOpenDeletionConfirmation] =
    React.useState(false);

  const navigate = useNavigate();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handlePasswordClickOpen = () => {
    setOpen(true);
  };

  const handleDeleteUserClick = () => {
    setOpenDeletionConfirmation(true);
  };

  const deleteUser = (userId) => {
    UserService.deactivateUser(userId, true)
      .then((res) => {
        if (res.code === "200") {
          recordRSEvent("deleteuser_button_click", {
            context: "user_delete",
            user_id: data.id,
            user_name: data?.name,
            user_phone: data?.phoneNum,
            user_role: data.role,
            user_team: data.team,
          });
          notification("success", res?.message);
          redirect(`/settings/users`);
          refresh();
        } else {
          notification("error", res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const handleClose = () => {
    setPasswd("");
    setRePasswd("");
    setOpen(false);
  };
  const handleFinalChange = () => {
    if (passwd && passwd === rePasswd) {
      var taskPayLoad = {
        userId: data?.id,
        password: passwd,
      };
      recordRSEvent("changepassword_button_click", {
        context: "user_creation",
        user_id: data.id,
        user_name: data?.name,
        user_phone: data?.phoneNum,
        user_role: data.role,
        user_team: data.team,
      });
      const change = UserService.changePasswd(taskPayLoad);
      change
        .then((res) => {
          if (res?.code === "200") {
            notification("success", res?.message);
            recordRSEvent("changepassword_event_success", {
              context: "user_password_change",
              user_id: data.id,
              user_name: data?.name,
              user_phone: data?.phoneNum,
              user_role: data.role,
              user_team: data.team,
            });
          }
          else if (res?.data) {
            res?.data?.[0]?.split(",")?.map((errMsg) => {
              notification("error", errMsg);
            });
            
            recordRSEvent("changepassword_event_failed", {
              context: "user_password_change",
              user_id: data.id,
              user_name: data?.name,
              user_phone: data?.phoneNum,
              user_role: data.role,
              user_team: data.team,
            });
          }
          setOpen(false);
          setPasswd("");
          setRePasswd("");
        })
        .catch((err) => {
          notification("error", err?.data);
          setOpen(false);
          setPasswd("");
          setRePasswd("");
          recordRSEvent("changepassword_event_fail", {
            context: "user_creation",
            user_id: data.id,
            user_name: data?.name,
            user_phone: data?.phoneNum,
            user_role: data.role,
            user_team: data.team,
          });
        });
    }
  };

  const largeScreen = useWidth();

  return (
    <>
      <TopToolbar className="tool-bar">
        <div>
          {largeScreen ? (
            <ZButton
              variant="contained"
              secondary
              onClick={() => navigate("/settings/users")}
              startIcon={
                <ChevronLeft
                  className="svg_icons_l"
                  style={{ backgroundColor: "transparent", marginRight: 0 }}
                />
              }
            >
              Back{" "}
            </ZButton>
          ) : (
            <ZIconButton2
              style={{
                padding: "0px 0px 0px 8px",
              }}
              onClick={() => {
                navigate("/templates");
              }}
            >
              <ChevronLeft
                className="svg_icons_l"
                style={{ backgroundColor: "transparent", marginRight: 0 }}
              />
            </ZIconButton2>
          )}
        </div>
        <div>
          {(hasPermission("user", "update") ||
            hasPermission("user", "edit")) && (
            <EditButton
              style={{
                border: "1px solid #c2c9d1",
                color: "#344054",
                fontSize: "1rem",
                fontWeight: "600",
                backgroundColor: "#ffffff",
                marginRight: "8px",
                padding: "6px 16px",
                borderRadius: "4px",
              }}
              basepath={basePath}
              style={{
                margin: "0px 8px 0px 0px",
              }}
              record={data}
            />
          )}
          {largeScreen ? (
           ((hasPermission("user", "update") ||
           hasPermission("user", "edit")) && <ZButton
              variant="contained"
              secondary
              onClick={handlePasswordClickOpen}
              style={{
                margin: "0px 8px 0px 0px",
              }}
              startIcon={
                <VpnKeyIcon
                  className="svg_icons_l"
                  style={{ backgroundColor: "transparent", marginRight: 0 }}
                />
              }
            >
              Change Password
            </ZButton>)
          ) : (
            (hasPermission("user", "update") ||
            hasPermission("user", "edit")) && (<ZIconButton2 onClick={handlePasswordClickOpen} className="mr-2">
              <VpnKeyIcon
                className="svg_icons_l"
                style={{ backgroundColor: "transparent", marginRight: 0 }}
              />
            </ZIconButton2>)
          )}
          {largeScreen ? (
            <ZButton
              variant="contained"
              danger
              onClick={handleDeleteUserClick}
              startIcon={<DeleteIcon />}
            >
              Delete
            </ZButton>
          ) : (
            <ZIconButton
              danger
              variant="contained"
              onClick={handleDeleteUserClick}
            >
              <DeleteIcon />
            </ZIconButton>
          )}
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          
        </div> */}
      </TopToolbar>

      {openDeletionConfirmation && (
        <DeletionConfirmationDialog
          deletionTitle="Delete User"
          deletionText="If you decide to continue, Deleted user will not be accessible anymore, and not cannot be revived"
          id={data.id}
          isOpen={openDeletionConfirmation}
          onConfirmDelete={() => deleteUser(data.id)}
          onClose={() => setOpenDeletionConfirmation(false)}
        ></DeletionConfirmationDialog>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Change Password - {data?.id}
        </DialogTitle>
        <DialogContentStyled>
          <TF
            autoFocus
            variant="outlined"
            value={passwd}
            margin="dense"
            id="name"
            type="password"
            label="New Password"
            onChange={(e) => setPasswd(e.target.value)}
          />
          <TF
            error={passwd !== rePasswd}
            variant="outlined"
            value={rePasswd}
            margin="dense"
            type="password"
            id="name"
            helperText={``}
            label="Re-Type Password"
            onChange={(e) => setRePasswd(e.target.value)}
          />
          <div style={{ fontSize: '10px' }}>The password must be between 8 and 30 characters in length.</div>
          <div style={{ fontSize: '10px' }}>The password must not contain the username.</div>
          <div style={{ fontSize: '10px' }}>The password must not include any whitespace characters.</div>
          <div style={{ fontSize: '10px' }}>The password must contain at least one uppercase letter.</div>
          <div style={{ fontSize: '10px' }}>The password must contain at least one lowercase letter.</div>
          <div style={{ fontSize: '10px' }}>The password must include at least one digit.</div>
          <div style={{ fontSize: '10px' }}>The password must have at least one special character (e.g., !, @, #).</div>
          <div style={{ fontSize: '10px' }}>The password must not contain sequences of three or more consecutive alphabetical characters.</div>
          <div style={{ fontSize: '10px' }}>The password must not contain sequences of three or more consecutive numerical characters.</div>
          <div style={{ fontSize: '10px' }}>The password must not include sequences of three or more consecutive characters that appear in a line on a QWERTY keyboard.</div>
        </DialogContentStyled>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFinalChange} color="primary">
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UserListActions = ({}) => {
  const  {
    displayedFilters,
    hideFilter,
    setFilters,
    showFilter,
  } = useListContext();
  const theme = useTheme()
  const classes = getClasses({theme});
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const getFilterValue = useApplyFilter(IFilterContext.USER, setFilters);
  const [openUserAutomationBuilder, setOpenUserAutomationBuilder] = useState(false);
  const [prevAutomation, setPrevAutomation] = useState([]);
  const dispatch = useDispatch();
  const accountId = getAccountId();
  const [filterCount, setFilterCount] = useState(
    getFilterValue.filterCount || 0
  );
  const [modalPosition, setModalPosition] = useState([0, 0, 0]); //[X,Y]
  const { userId = "" } = userDecodedData();
  const navigate = useNavigate();
  const { clearRightSide } = useAutomation();

  const fetchUserAutomationUIFormat = async () => {
    try {
      const getUserAutomationUIFormat = await AccountService.getAccount(accountId);
      setPrevAutomation(getUserAutomationUIFormat?.data?.accountConfig?.userAutomationUiFormat || []);
    } catch (error) {
      console.error("Failed to fetch user automation UI format", error);
    }
  };

  useEffect(() => {
    if (getFilterValue?.filterCount >= 0) {
      setFilterCount(getFilterValue.filterCount);
    }
  }, [getFilterValue.filterCount]);

  useEffect(() => {
    setFilterCount(getFilterCount(IFilterContext.USER, userId));
  }, []);

  useEffect(() => {
    fetchUserAutomationUIFormat();
  }, [accountId]);

  return (
    <TopToolbar className="workflow-toolbar setting-bar">
      <div>
        <SubHeading>User Management</SubHeading>
        <Label> Manage your users or create new user here </Label>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
          <ZButton
            secondary
            style={{ padding: "5px 12px", color:'#344054', fontWeight:600, fontSize:'12px', marginRight:'8px'}}
            variant="contained"
            fontSize={14}
            startIcon={AutomationsIcon}
            onClick={()=>{
              dispatch(initMockData({taskTypeId: accountId, type:'USER'}));
              setOpenUserAutomationBuilder(true);
            }}>
              Automations
          </ZButton>

        {(true || filterCount >= 0) && (
          <FilterButton
            filterCount={filterCount}
            onRemoveFilterClick={() => {
              setFilters({}, []);
              clearFilterStorage(userSearchContext, userId);

              setFilterCount(0);

              navigate({
                pathname: "/settings/users",
                search: `?search=`,
              });
            }}
            onOpenFilterClick={(pos) => {
              const x =
                pos.current.offsetParent.offsetLeft + pos.current.offsetLeft;
              const y =
                pos.current.offsetParent.offsetParent.offsetTop +
                pos.current.offsetTop +
                pos.current.offsetHeight;
              const buttonHeight = pos.current.offsetHeight;
              const buttonWidth = pos.current.offsetWidth;
              setModalPosition([x, y, buttonHeight, buttonWidth]);
              setOpenFilterModal(true);
              showFilter("main");
            }}
          />
        )}

        {displayedFilters?.main && openFilterModal && (
          <FilterModal
            position={modalPosition}
            filterTitle={filterTitle}
            urlEndpoint={"/settings/users"}
            context={userSearchContext}
            openModal={openFilterModal}
            handleClose={(appliedFilterCount) => {
              setOpenFilterModal(false);
              hideFilter("main");
              if (appliedFilterCount >= 0) {
                setFilterCount(appliedFilterCount);
              }
            }}
          />
        )}

        <ExportButton
          onClick={() =>
            recordRSEvent("export_button_click", {
              context: "user_listing",
            })
          }
          icon={<img src={exportList} />}
          style={{
            border: "1px solid #c2c9d1",
            color: "#344054",
            fontSize: "1rem",
            fontWeight: "600",
            backgroundColor: "#fff",
            marginRight: "8px",
            padding: "6px 16px",
            width: "87px",
          }}
        />
        {hasPermission("user", "create") && (
          <CreateButton
            
            basePath="/users"
            variant="contained"
            style={{
              ...classes.createButton,
            }}
            label="Add User"
            onClick={() => {
              recordRSEvent("newuser_button_click", {
                context: "user_listing",
              });
            }}
          />
        )}
      </div>

      <ConfigureAutomation
        metaDataType={ABMetadataTypeEnum.USER}
        prevAutomations={prevAutomation}
        onSaveHandler={({allAutomations, dispatch})=>{
          userAutomationSaveHandler({allAutomations, dispatch, callback :()=>{
            setOpenUserAutomationBuilder(false);
          }})
          setPrevAutomation(allAutomations);
        }}
        onAutomationClose={() => {
          clearRightSide();
          dispatch(closeAutomation());
          setOpenUserAutomationBuilder(false);
        }}
        otherConfigs={{
          hideGuardBlock: false,
          hideTriggerConfigureButton: false,
          showRunAutomation:true
        }}
        openAutomationBuilder={openUserAutomationBuilder}
      />

    </TopToolbar>
  );
};

export const UserList = (props) => {
  const theme = useTheme()
  const classes = getClasses({theme});
  const { userId = "" } = userDecodedData();
  const navigate = useNavigate();

  const customizeUserExportField = (usersData) => {
    recordRSEvent("export_grid", {
      context: "user_export_grid",
      userId,
    });

    const usersForExport = usersData.map((user) => {
      const { id, teams, ...userForExport } = user;
      return userForExport;
    });

    jsonExport(
      usersForExport,
      {
        headers: [
          "userId",
          "name",
          "phone",
          "email",
          "teamIds",
          "role",
          "isActive",
          "status",
        ],
      },
      (err, csv) => {
        downloadCSV(csv, "Users"); // download user_Data.csv file
      }
    );
  };

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.user, "user_list");
  }, [props]);

  const openUserViewPage = (e) => {
    // First we are getting nearest tr tag from the table and we are getting first value from the row, which is userId
    const userId = e.target
      .closest("tr")
      .querySelector("td:first-child").textContent;
    navigate(`/users/${userId}/show`);
  };
  return (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        actions={<UserListActions />}
        pagination={<ListPagination />}
        exporter={customizeUserExportField}
        // classes={{ main: classes.main }}
      >
        <div
          style={{
            overflowX: "auto",
            boxShadow:
              "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
          }}
        >
          <Datagrid
            classes={{
              headerCell: classes.headerCell,
              headerRow: classes.headerRow,
              rowEven: classes.rowEven,
              rowOdd: classes.rowOdd,
              rowCell: classes.rowCell,
              table: classes.table,
            }}
            sx={{
              ...classes
            }}
            onDoubleClick={openUserViewPage}
            key="id"
            rowClick="show"
          >
            <TextField
              sortable={false}
              textAlign="left"
              label="User Id"
              source="id"
            />
            <TextField sortable={false} textAlign="left" source="name" />
            <TextField
              sortable={false}
              textAlign="left"
              label="Phone Number"
              source="phone"
            />
            <TextField
              sortable={false}
              textAlign="left"
              label="Role"
              source="role"
            />
            <TextField
              sortable={false}
              textAlign="left"
              label="Team"
              source="teamsInfo"
            />
            <TextField
              sortable={false}
              textAlign="left"
              label="Status"
              source="status"
            />
            <BooleanField
              sortable={false}
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
              }}
              TrueIcon={CheckCircleIcon}
              FalseIcon={CancelIcon}
              label="Active"
              source="isActive"
            />
            <FunctionField
              label="Open"
              render={(record) => {
                return (
                  <div
                    style={{ marginLeft: "1.4rem", cursor: "pointer" }}
                    onClick={openUserViewPage}
                  >
                    <AiOutlineExpandAlt size={20} />
                  </div>
                );
              }}
            />
          </Datagrid>
        </div>
      </List>
    </>
  );
};

const UserDetails = (props) => {
  
  let allTeams = [];
  props?.teams?.map((team) =>
    allTeams.push(`${team.name} (${team?.teamId})`)
  );
  const [userRoleName, setUserRoleName] = useState("");

  const getRolesInformation = () => {
    // we store the role information to local storage while login, and here we are fetching the role.
    // note : after login if we add any any role we are not storing those information, need to logout and login again
    const getRolesData = localStorage.getItem("roles");
    if (getRolesData) {
      const rolesJSONData = JSON.parse(getRolesData);

      const roleInformation = rolesJSONData.find((roleData) => {
        return (
          roleData.roleId == (props?.roleId || props?.role)
        );
      });
      setUserRoleName(roleInformation?.name);
    }
  };

  

  useEffect(() => {
    getRolesInformation();
  }, []);

  return (
    <TaskFormBody>
      <h3 style={{ padding: "10px 0px" }}>
        {" "}
        User Details{" "}
      </h3>
      <hr />
      <GridContainer>
        <UserDisplayBlock label="User Id" value={props?.userId} />
        <UserDisplayBlock label="User Name" value={props?.name} />
        <UserDisplayBlock
          label="Role"
          value={
            userRoleName
              ? `${userRoleName} (${props?.role})`
              : props?.role
          }
        />
        <UserDisplayBlock label="Created On" value={props?.createdOn} />
        <UserDisplayBlock label="Phone No" value={props?.phoneNum} />

        <UserDisplayBlock label="Status" value={props?.status} />

        <UserDisplayBlock
          label="Active Status"
          value={props?.activeStatus}
        />

        <UserDisplayBlock label="Teams" value={allTeams?.join(",")} />

        <UserDisplayBlock label="Email" value={props?.email} />

        <UserDisplayBlock
          label="External Id"
          value={props?.externalId}
        />

        <UserDisplayBlock
          label="App Version"
          value={props?.record?.appVersion || "-"}
        />

        <UserDisplayBlock
          label="Battery Level"
          value={props?.record?.batteryLevel || "-"}
        />

        <UserDisplayBlock
          label="Battery Level"
          value={props?.record?.batteryStatus || "-"}
        />        
      </GridContainer>
    </TaskFormBody>
  );
};

export const UserShow = () => {
  useEffect(() => {
    recordPageVisit(PageCategoryEnum.user, "user_details");
  }, []);
  const {id} = useParams()
  const [userInfo, setUserInfo] = useState({});
  const getUserInfo = () => {
    UserService.getUser(id)
      .then((res) => {
        if (res.code === "200") {
          setUserInfo(res.data);
          return res.data;
        } else {
          notification("error", res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };
  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <div className="showTaskParentDiv">
      {
        isEmpty(userInfo) ? <div>Loading...</div> : (
          <ShowController component="div">
        {() => {
          return (
            <Show  actions={<UserShowActions />} {...userInfo}>
              <div className="contentWrapper">
                <div>
                  <UserDetails {...userInfo} />
                </div>
                <div style={{
                  padding:"0px 8px"
                }}>
                  <UserSwitches {...userInfo} />
                </div>
              </div>
            </Show>
          );
        }}
      </ShowController>
        )
      }
      
    </div>
  );
};

const UserSwitches = (props) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  // console.log("Switches Props", props);
  const [id, setId] = React.useState(props?.id);
  const [status, setStatus] = React.useState(props?.status);
  const [active, setActive] = React.useState(props?.isActive);
  const [statusDisabled, setStatusDisabled] = React.useState(true);
  const [activeDisabled, setActiveDisabled] = React.useState(true);

  const statusOptions = [
    { value: "ONLINE", label: "ONLINE" },
    { value: "OFFLINE", label: "OFFLINE" },
  ];

  const activeStatusOptions = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  const handleActiveChange = (e) => {
    var s = props?.isActive;
    if (e.value !== s) {
      setActive(e.value);
      setActiveDisabled(false);
    } else {
      setActive(e.value);
      setActiveDisabled(true);
    }
  };

  const handleStatusChange = (e) => {
    recordRSEvent("changestatus_button_clicked", {
      context: "user_creation",
      user_id: id,
      user_status: status,
      user_active: active,
    });
    if (e.value !== props?.status) {
      setStatus(e.value);
      setStatusDisabled(false);
    } else {
      setStatus(e.value);
      setStatusDisabled(true);
    }
  };

  const statusChange = () => {
    // Logic to connect with the API to change the status.
    var user = {
      id: id,
      status: status,
    };

    UserService.updateStatus(user)
      .then((res) => {
        if (res.code === "200") {
          notification("success", res?.message);
          redirect(`/users/${id}/show`);
          refresh();
        } else {
          notification("error", res?.message);
          redirect(`/users/${id}/show`);
          refresh();
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const activeChange = () => {
    // Logic to connect with the API to change the Active Status.
    var user = {
      ...props,
      userId: id,
      isActive: active,
    };
    recordRSEvent("changeactive_button_clicked", {
      context: "user_creation",
      user_id: id,
      user_status: status,
      user_active: active,
    });
    if (active) {
      UserService.userUpdate(user)
        .then((res) => {
          if (res.code === "200") {
            notification("success", res?.message);
            redirect(`/users/${id}/show`);
            refresh();
          } else {
            notification("error", res?.message);
            redirect(`/users/${id}/show`);
            refresh();
          }
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    } else if (!active) {
      UserService.deactivateUser(id, false)
        .then((res) => {
          if (res.code === "200") {
            notification("success", res?.message);
            redirect(`/users/${id}/show`);
            refresh();
          } else {
            notification("error", res?.message);
            redirect(`/users/${id}/show`);
            refresh();
          }
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    }
  };

  return (
    <TaskFormBody style={{ height: "20vh" }}>
      <GridContainer>
        <GridContainer>
          <div>
            <Select
              value={statusOptions.find((c) => c.value === status)}
              className="react-dropdown"
              classNamePrefix="dropdown"
              onChange={(e) => handleStatusChange(e)}
              options={statusOptions}
            />
          </div>
          <div>
            <ZButton
              onClick={statusChange}
              disabled={statusDisabled}
              variant="contained"
            >
              {" "}
              Change Status{" "}
            </ZButton>
          </div>
        </GridContainer>
        <GridContainer>
          <div>
            <Select
              value={activeStatusOptions.find((c) => c.value === active)}
              className="react-dropdown"
              classNamePrefix="dropdown"
              onChange={(e) => handleActiveChange(e)}
              options={activeStatusOptions}
            />
          </div>
          <div>
            <ZButton
              onClick={activeChange}
              disabled={activeDisabled}
              variant="contained"
            >
              Change Active Status
            </ZButton>
          </div>
        </GridContainer>
      </GridContainer>
    </TaskFormBody>
  );
};

export const UserEdit = () => {
  useEffect(() => {
    recordPageVisit(PageCategoryEnum.user, "user_edit");
  }, []);

  return <UserEditForm  />;
};
