//@ts-nocheck
import { Box } from '@mui/material'
import React, { useState } from 'react'
import styled from "styled-components";
import AutomationConfigurationIcon from 'assests/automation_configuration_icon.svg';
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import ActionDeleteIcon from 'assests/action_delete_icon.svg';
import { IActionType } from '@zorp/common-libs-js/dist/monitor';
import { useDispatch } from "react-redux";
import ConfigureAutomation from "views/workflows/ConfigureAutomation";
import useMonitor from '../reducer/useMonitor';
import { IAutomation } from "@zorp/common-libs-js/dist/common/types/types";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { ABMetadataTypeEnum } from "views/automationBuilder/types/ABType";
import {
  closeAutomation
} from "views/automationBuilder/reducer/automationReducer";
import { useTheme } from 'styled-components';
import bolt from 'assests/bolt.svg';
import { setSelectedMonitor } from '../reducer/MonitorReducer';
import { recordRSEvent } from 'utils/CommonUtils';
import { RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';
import { styled as MuiStyle } from '@mui/material/styles';
import { initMockData } from "lowcode/state/scriptEditorState";
const ConfigureActionText = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

interface IAlertAutomation {
  fields: any;
  onRemove: (index: number) => void;
}

const AlertAutomation = ({ fields, onRemove }: IAlertAutomation) => {
  const { monitorInterfaceContext, selectedMonitor } = useMonitor();
  const dispatch = useDispatch();
  const theme = useTheme()
  const [openMonitorAutomation, setOpenMonitorAutomation] = useState(false);
  const { clearRightSide, selectedMonitorId } = useAutomation();
  // const fieldsIndex = fields.findIndex((data: any) => (data.actionId == monitorInterfaceContext?.monitorComponentsData?.selectedActionDetails?.actionId && data.actionType == IActionType.AUTOMATION));
  const fieldsIndex = fields.findIndex((data: any) => (data.actionType == IActionType.AUTOMATION));
  return (
    <>
      <Box width={'100%'} padding={'24px'} borderRadius={'4px'} border={'1px solid var(--Gray-200, #EAECF0)'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}>
        <Box width={'100%'} alignItems={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'}>

          <Box
            onClick={() => {
              dispatch(initMockData({taskTypeId:selectedMonitorId, type: ABMetadataTypeEnum.MONITOR}));
              setOpenMonitorAutomation(true);
            }}
            className='pointer' width={'100%'} height={'80px'} borderRadius={'4px'}
            flexDirection={'column'}
            alignItems={'center'} display={'flex'} justifyContent={'center'} border={'1px solid var(--Gray-300, #D0D5DD)'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}>
            <Box display={'flex'} gap={'8px'}>
              <img style={{fontSize:'12px'}} src={AutomationConfigurationIcon} alt='Icon' />
              <ConfigureActionText>Click here to configure Auto Resolution</ConfigureActionText>
            </Box>

            {
              selectedMonitor?.monitorAutomation?.[0]?.id &&
              <div style={{
                justifyContent: 'center',
                display: 'flex',
                backgroundColor: '#f6f7fe',
                border: `1px solid ${theme.color.primary}`,
                width: '90%',
                marginTop: '10px',
                borderRadius: '4px',
                marginLeft: '10px',
                marginRight: '10px',
                padding: '0.5rem 1.6rem',
                fontSize:'12px',
                color:'black',
                fontFamily:'Inter'
              }}>
                <img style={{ marginRight: '15px' }} src={bolt} alt='fnIcon' />
                There is an Active Auto Resolution
              </div>
            }


          </Box>

          <ZButton
            onClick={() => {
              dispatch(setSelectedMonitor({ ...selectedMonitor, monitorAutomation: [] }));
              onRemove(fieldsIndex);
              recordRSEvent(RudderStackAutomationBuilderEvents.automationDelete, {
                context: RudderStackAutomationBuilderEvents.monitorContext
              });
            }}
            style={{ padding: '0px', fontFamily: 'Inter', marginTop: '16px', borderRadius: '4px', height: '40px', color: '#B42318', fontSize: '12px', fontWeight: 500, backgroundColor: '#fff', textTransform: 'none' }}
            sx={{ height: '40px' }}
            variant="text">
            <img style={{ paddingRight: '8px' }} src={ActionDeleteIcon} alt='Icon' />
            Delete</ZButton>
        </Box>
      </Box>

      <ConfigureAutomation
        metaDataType={ABMetadataTypeEnum.MONITOR}
        prevAutomations={selectedMonitor?.monitorAutomation || [] as IAutomation[]}
        automationSelected={selectedMonitor?.monitorAutomation?.[0]?.id || ''}
        onSaveHandler={(automationData) => {
          dispatch(setSelectedMonitor({ ...selectedMonitor, monitorAutomation: automationData?.allAutomations }));
          clearRightSide();
          dispatch(closeAutomation());
          setOpenMonitorAutomation(false);
        }}
        onAutomationClose={() => {
          clearRightSide();
          dispatch(closeAutomation());
          setOpenMonitorAutomation(false);
        }}
        otherConfigs={{
          allowOnlySingleAutomation: true,
          hideGuardBlock: false,
          hideTriggerConfigureButton: false,
          hideTrigger: true,
          showRunAutomation: true
        }}
        openAutomationBuilder={openMonitorAutomation}
      />
    </>
  )
}

// export default AlertAutomation;
export default React.memo(AlertAutomation);