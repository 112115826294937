import React from 'react';
import ZInput from "../../commonComponents/ZTextInput";
import {
  InputContainer,
  Label,
} from "../../workflows/workflowactions/ModalContent.styles";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
// import IconButton from "@mui/material/IconButton";
import { UseFormRegister } from 'react-hook-form';
import { isTempIdUnique } from "views/workflows/workflowactions/api/utils/utils";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";

interface InputTempIdProps {
  label: "Action Id" | "Validation Id" | "Condition Id" | "Trigger Id";
  onChange: any;
  isReadOnly?: boolean;
  handleTickClick?: () => void;
  register: UseFormRegister<any>;
  handleEditClick?: () => void;
  setTempIdError: (error: string | null) => void;
  isEditing?: boolean;
  name?: string | any;
  requiredErrorMessage: string;
  errors?: {
    tempId?: {
      type: string;
      message: string;
    };
  }
  getValues?: any;
}


const InputTempId: React.FC<InputTempIdProps> = ({
  label,
  onChange,
  register,
  isReadOnly,
  handleTickClick,
  handleEditClick,
  isEditing,
  name,
  errors,
  requiredErrorMessage,
  setTempIdError,
  getValues,
}) => {
  const referenceIdText = label.charAt(0).toUpperCase() + label.slice(1);

  const { selectedAutomation } = useAutomation();

  const {
    selectedAction,
  } = useWorkFlow();

  const enhancedHandleTickClick = () => {
    if (handleTickClick) {
      const currentVal = getValues(name);

      if (!currentVal || currentVal.trim() === '') {
        setTempIdError(requiredErrorMessage);
        return;
      }

      if (currentVal.length < 3) {
        setTempIdError(`${referenceIdText} must have more than 3 characters`);
        return;
      }

      if (currentVal.length > 25) {
        setTempIdError(`${referenceIdText} must have less than 25 characters`);
        return;
      }

      if (!/^[a-zA-Z0-9_]+$/.test(currentVal)) {
        setTempIdError("Only letters, numbers, and underscore (_) are allowed");
        return;
      }

      setTempIdError(null);

      if (currentVal === (selectedAction.actionId || selectedAction.guardId || selectedAction.conditionId)) {
        setTempIdError(null);
        handleTickClick();
        return;
      }
      else if (!isTempIdUnique(currentVal, selectedAutomation)) {
        setTempIdError("This ID already exists. Please choose a different one.");
        return false;
      }

      setTempIdError(null);
      handleTickClick();
    }
  };

  return (
    <div>
      <Label style={{ marginTop: "20px" }}>{label}</Label>
      <InputContainer>
        <ZInput
          onChange={onChange}
          register={register}
          isReadOnly={!isEditing || isReadOnly}
          name={name}
        />
        {isEditing ? (
          // <IconButton onClick={handleTickClick} edge="end">
          <CheckIcon
            onClick={enhancedHandleTickClick}
            style={{
              padding: "4px",
              marginLeft: "5px",
              marginRight: "0px",
            }}
          />
          // {/* </IconButton> */}
        ) : (
          // <IconButton onClick={handleEditClick} edge="end">
          <EditIcon
            onClick={handleEditClick}
            style={{
              padding: "4px",
              marginLeft: "5px",
              marginRight: "0px",
            }}
          />
          // </IconButton>
        )}
      </InputContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          width: "80%",
          color: "red",
          marginTop: "2px",
          fontSize: "12px",
        }}
      >
        {errors?.tempId?.message}
      </div>
      {isEditing && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "80%",
            color: "#8A8A8A",
            marginTop: "2px",
            fontSize: "12px",
          }}
        >
          <span>*</span>
          <span>
            &nbsp;Altering the {referenceIdText} would require updating all the references for the {label.split(" ")[0].toLowerCase()}.
          </span>
        </div>
      )}
    </div>
  );
};

export default InputTempId;