import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IActionType } from "@zorp/common-libs-js/dist/monitor";
import addChannelIcon from 'assests/add_channel_icon.svg';
import AutomationConfigurationIcon from 'assests/automation_zorp.svg';
import ChannelIconAlert from 'assests/channel_icon_alert.svg';
import EmailActionIcon from 'assests/email_zorp.svg';
import SlackActionIcon from 'assests/slack_action_icon.svg';
import TicketActionIcon from 'assests/ticket_create.svg';
import React, { ReactElement, useEffect, useState } from 'react';
import { useFieldArray } from "react-hook-form";
import { useDispatch } from "react-redux";
import useAccount from "views/layouts/reducer/useAccount";
import styled from "styled-components";
import { generateId } from 'utils/Utils';
import CollapsableComponent from '../MonitorInterfaceComponents/CollapsableComponent';
import { IScreenContext, setMonitorInterfaceContext } from '../reducer/MonitorReducer';
import useMonitor from '../reducer/useMonitor';
import SelectedRightSideAction from './SelectedRightSideAction';
import { recordRSEvent } from 'utils/CommonUtils';
import { RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';


const ContextMenuText = styled.p`
color: black;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: normal;
`;

interface IAlertMeSection {
  control: any;
  setValue: any;
  watch: any;
  errors: any;
  register: any;
}

interface IActionMenuItem {
  onMenuItemClick: () => void;
  img: ReactElement;
  menuText: string;
  selected?: boolean;
}

export interface IActionDetails {
  actionId: string;
  actionType: IActionType;
} 

const getStyles =  (props:any) => {
  return {
    menuItem: {
      width: '205px',
      padding: '8px 2px',
      marginTop: '4px',
      backgroundColor: props.selected ? 'var(--Gray-100, #F2F4F7)' : '',
      borderRadius: props.selected ? '6px' : '',
      color: props.selected ? '#101828' : '#667085',
      '&:hover': {
        backgroundColor: '#F2F4F7', // Change this color to whatever you prefer for the hover state
        borderRadius: '6px',
      }
    }
  }
}


export const ActionMenuItem = ({ onMenuItemClick, img, menuText, selected = false }: IActionMenuItem) => {
  const classes = getStyles({ selected }); 
  return (
    <MenuItem onClick={onMenuItemClick} sx={classes.menuItem}>
      {img}
      <ContextMenuText>{menuText}</ContextMenuText>
    </MenuItem>
  )
}

const AlertMeSection = ({ control, setValue, watch, errors, register }: IAlertMeSection) => {
  const dispatch = useDispatch();
  const { account } = useAccount();
  const { monitorInterfaceContext } = useMonitor();
  const isMonitorInEditMode = monitorInterfaceContext?.monitorScreenContext == IScreenContext.EDIT;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const totalActionLength = Object.keys(IActionType).length;
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { fields, append, remove } = useFieldArray({
    name: "actionData",
    control
  });

  const addActions = (actiontype: IActionType) => {
    const generatedActionId = generateId(6);
    switch (actiontype) {
      case IActionType.EMAIL:
        recordRSEvent(RudderStackAutomationBuilderEvents.emailActionAdd, {
          context: RudderStackAutomationBuilderEvents.monitorContext
        });
        append({
          actionType: IActionType.EMAIL,
          actionId: generatedActionId,
          label: "Email",
          isActive: true,
          payload: {
            userEmail: account.primaryContactEmail,
            mailSubject: '',
            mailBody: '',
            attachCSV: false
          }
        })
        dispatch(setMonitorInterfaceContext({
          ...monitorInterfaceContext, // Spread the previous state to retain other values
          monitorComponentsData: {
            ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
            selectedActionDetails: { actionId: generatedActionId, actionType: IActionType.EMAIL }
          }
        }));
        break;
      case IActionType.SLACK:
        recordRSEvent(RudderStackAutomationBuilderEvents.slackActionAdd, {
          context: RudderStackAutomationBuilderEvents.monitorContext
        });
        append({
          actionType: IActionType.SLACK,
          actionId: generatedActionId,
          label: "Slack",
          isActive: true,
          payload: {
            message: "",
            channelData: [],
            attachCSV: false
          }
        })

        dispatch(setMonitorInterfaceContext({
          ...monitorInterfaceContext, // Spread the previous state to retain other values
          monitorComponentsData: {
            ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
            selectedActionDetails: { actionId: generatedActionId, actionType: IActionType.SLACK }
          }
        }));
        break;
      case IActionType.TICKET:
        recordRSEvent(RudderStackAutomationBuilderEvents.ticketActionAdd, {
          context: RudderStackAutomationBuilderEvents.monitorContext
        });
        append({
          actionType: IActionType.TICKET,
          actionId: generatedActionId,
          label: "Ticket Creation",
          isActive: true,
          payload: {
            userEmail: '',
            priority: '',
            ticketNotes: '',
            attachCSV: false
          }
        })
        dispatch(setMonitorInterfaceContext({
          ...monitorInterfaceContext, // Spread the previous state to retain other values
          monitorComponentsData: {
            ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
            selectedActionDetails: { actionId: generatedActionId, actionType: IActionType.TICKET }
          }
        }));
        break;
      case IActionType.AUTOMATION:
        recordRSEvent(RudderStackAutomationBuilderEvents.automationAdd, {
          context: RudderStackAutomationBuilderEvents.monitorContext
        });
        append({
          actionType: IActionType.AUTOMATION,
          actionId: generatedActionId,
          label: "Auto Resolution"
        })
        dispatch(setMonitorInterfaceContext({
          ...monitorInterfaceContext, // Spread the previous state to retain other values
          monitorComponentsData: {
            ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
            selectedActionDetails: { actionId: generatedActionId, actionType: IActionType.AUTOMATION }
          }
        }));
        break;

      default:
        break;
    }
    handleClose();
  }

  useEffect(()=>{
      if (isMonitorInEditMode && fields.length > 0) {
        setTimeout(()=>{
          dispatch(setMonitorInterfaceContext({
            ...monitorInterfaceContext, // Spread the previous state to retain other values
            monitorComponentsData: {
              ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
              //@ts-ignore
              selectedActionDetails: { actionId: fields?.[0]?.actionId, actionType: fields?.[0]?.actionType }
            }
          }));
        },100)

      }
  },[isMonitorInEditMode])

  return (
    <>
      <CollapsableComponent
        imgIcon={<img style={{ paddingRight: '8px' }} src={ChannelIconAlert} alt='Icon' />}
        color='#DC6803'
        title='Alert me through'
        collapseState={!monitorInterfaceContext?.monitorComponentsData?.isChannelSectionHidden} onClick={() => {
          dispatch(setMonitorInterfaceContext({
            ...monitorInterfaceContext, // Spread the previous state to retain other values
            monitorComponentsData: {
              ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
              isChannelSectionHidden: !monitorInterfaceContext?.monitorComponentsData?.isChannelSectionHidden // Set your desired property
            }
          }));
        }} />
      {
        monitorInterfaceContext?.monitorComponentsData?.isChannelSectionHidden &&
        <>
          <Box marginTop={'24px'} padding={'24px'} style={{ border: '1px solid #D0D5DD' }} width={'900px'} borderRadius={'4px'} display={'flex'} gap={'24px'}>
            <Box width={'270px'}>
              <div>
                {
                  fields.map((data: any) => {
                    let LabelPreFixIconImg;
                    switch (data.actionType) {
                      case IActionType.AUTOMATION:
                        LabelPreFixIconImg = <img style={{ paddingRight: '12px', paddingLeft: '12px' }}  width="42" height="42" src={AutomationConfigurationIcon} alt='Icon' />;
                        break;

                      case IActionType.SLACK:
                        LabelPreFixIconImg = <img style={{ paddingRight: '12px', paddingLeft: '12px' }} width="42" height="42" src={SlackActionIcon} alt='Icon' />;
                        break;

                      case IActionType.EMAIL:
                        LabelPreFixIconImg = <img style={{ paddingRight: '12px', paddingLeft: '12px' }} width="42" height="42" src={EmailActionIcon} alt='Icon' />;
                        break;
                      case IActionType.TICKET:
                        LabelPreFixIconImg = <img style={{ paddingRight: '12px', paddingLeft: '12px' }} width="42" height="42" src={TicketActionIcon} alt='Icon' />;
                        break;

                      default:
                        LabelPreFixIconImg = <img style={{ paddingRight: '12px', paddingLeft: '12px' }} width="42" height="42" src={AutomationConfigurationIcon} alt='Icon' />;
                        break;
                    }
                    return (
                      <ActionMenuItem
                        menuText={data.label || data.actionType}
                        img={LabelPreFixIconImg}
                        //@ts-ignore
                        selected={data.actionId == (monitorInterfaceContext?.monitorComponentsData?.selectedActionDetails?.actionId || fields?.[0]?.actionId)}
                        onMenuItemClick={() => {
                          dispatch(setMonitorInterfaceContext({
                            ...monitorInterfaceContext, // Spread the previous state to retain other values
                            monitorComponentsData: {
                              ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
                              selectedActionDetails: { actionId: data.actionId, actionType: data.actionType }
                            }
                          }));
                        }}
                      />
                    )
                  })
                }
                {fields?.length != totalActionLength && 
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  style={{
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: 500,
                    color: '#3054B9',
                    justifyContent: 'flex-start',
                    padding: '8px 12px',
                    textTransform: 'none'
                  }}>
                  <img style={{ paddingRight: '8px', width:'12px' }} src={addChannelIcon} alt='Icon' />
                  Add Channel
                </Button>}

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  style={{
                    boxShadow: 'none'
                  }}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0
                }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >

                  {
                    !fields.find((data: any) => data.actionType == IActionType.AUTOMATION) &&
                    <ActionMenuItem
                      menuText='Auto Resolution'
                      img={<img style={{ paddingRight: '12px', paddingLeft: '12px' }} src={AutomationConfigurationIcon} alt='Icon' />}
                      onMenuItemClick={() => {
                        addActions(IActionType.AUTOMATION)
                      }}
                    />
                  }

                  {
                    !fields.find((data: any) => data.actionType == IActionType.EMAIL) &&
                    <ActionMenuItem
                      menuText='Email'
                      img={<img style={{ paddingRight: '12px', paddingLeft: '12px' }} src={EmailActionIcon} alt='Icon' />}
                      onMenuItemClick={() => {
                        addActions(IActionType.EMAIL)
                      }}
                    />
                  }

                  {
                    !fields.find((data: any) => data.actionType == IActionType.TICKET) &&
                    <ActionMenuItem
                      menuText='Ticket Creation'
                      img={<img style={{ paddingRight: '12px', paddingLeft: '12px' }} src={TicketActionIcon} alt='Icon' />}
                      onMenuItemClick={() => {
                        addActions(IActionType.TICKET)
                      }}
                    />
                  }

                  {
                    !fields.find((data: any) => data.actionType == IActionType.SLACK) &&
                    <ActionMenuItem
                      menuText='Slack'
                      img={<img style={{ paddingRight: '12px', paddingLeft: '12px' }} src={SlackActionIcon} alt='Icon' />}
                      onMenuItemClick={() => {
                        addActions(IActionType.SLACK)
                      }}
                    />
                  }

                </Menu>
              </div>
            </Box>

            <SelectedRightSideAction
              fields={fields}
              control={control}
              errors={errors}
              remove={remove}
              setValue={setValue}
              watch={watch}
              register={register}
            />

          </Box>
        </>
      }
    </>
  )
}

export default React.memo(AlertMeSection);