import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { RudderStackAutomationBuilderEvents } from "constants/RudderStackConstants";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { recordRSEvent } from "utils/CommonUtils";
import { IShopifyTemplate } from "./ShopifyMonitor";
import MonitorTemplateFooter from "./monitorTemplate/MonitorTemplateFooter";

const Card = styled.div`
  background-color: white;
  display: inline-block;
  padding: 20px;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CardTitle = styled.h1`
  /* display: inline-block; */
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const CardContent = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
const CardType = styled.p`
  font-size: 12px;
  margin-top: 10px !important;
  background-color: #f2f4f7;
  padding: 0px 0px 0px 0px;
  border-radius: 2px;
  cursor: pointer;
`;
const AlertContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
const CheckboxTitle = styled.p`
  font-size: 12px;
  color: #3054b9;
  margin-right: 5px !important;
  font-weight: 400;
`;
const CardHeader = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: -8px;
  font-size:12px;
  font-family:Inter;
`;

interface IShopifyMonitorCard {
  template: IShopifyTemplate;
  onExecuteTemplate: () => void;
  onFineTuneTemplate: () => void;
  onOpenMonitorPage: () => void;
  onAlertMeClick: () => void; 
  monitorTestingLoading: boolean;
  createMonitorLoading: boolean;
  showCustomizeBlueBeeBtn?:boolean;
}

const ShopifyMonitorCard = ({
  template,
  onExecuteTemplate,
  onAlertMeClick,
  monitorTestingLoading,
  createMonitorLoading,
  onOpenMonitorPage,
  onFineTuneTemplate,
  showCustomizeBlueBeeBtn
}: IShopifyMonitorCard) => {
  const [executeTemplateId, setExecuteTemplateId] = useState<string>("");
  const [isCreateMonitorLoading, setIsCreateMonitorLoading] = useState<boolean>(false);

  useEffect(() => {
    if (executeTemplateId && template.templateId) {
      if (createMonitorLoading && executeTemplateId === template.templateId) {
        setIsCreateMonitorLoading(true);
      } else {
        setIsCreateMonitorLoading(false);
      }
    } else {
      setIsCreateMonitorLoading(false);
    }
  }, [
    executeTemplateId,
    template.templateId,
    monitorTestingLoading,
    createMonitorLoading,
  ]);

  return (
    <Card style={{
      width:'100%',
      marginBottom:'16px'
    }}>
      <CardHeader>
        <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M20.1666 7.32996V10.9966M9.39575 5.03829H6.23325C4.69311 5.03829 3.92303 5.03829 3.33478 5.33802C2.81733 5.60167 2.39663 6.02237 2.13298 6.53982C1.83325 7.12807 1.83325 7.89815 1.83325 9.43829L1.83325 10.5383C1.83325 11.3925 1.83325 11.8196 1.97281 12.1565C2.15888 12.6058 2.51578 12.9627 2.965 13.1487C3.30191 13.2883 3.72903 13.2883 4.58325 13.2883V17.1841C4.58325 17.3969 4.58325 17.5034 4.59208 17.593C4.67781 18.4634 5.36647 19.1521 6.23689 19.2378C6.32652 19.2466 6.43293 19.2466 6.64575 19.2466C6.85858 19.2466 6.96499 19.2466 7.05462 19.2378C7.92504 19.1521 8.6137 18.4634 8.69943 17.593C8.70825 17.5034 8.70825 17.3969 8.70825 17.1841V13.2883H9.39575C11.015 13.2883 12.9957 14.1563 14.5239 14.9893C15.4153 15.4753 15.8611 15.7183 16.153 15.6825C16.4237 15.6493 16.6284 15.5278 16.7871 15.306C16.9583 15.0668 16.9583 14.5881 16.9583 13.6309V4.69572C16.9583 3.73845 16.9583 3.25982 16.7871 3.0206C16.6284 2.79878 16.4237 2.67723 16.153 2.64407C15.8611 2.60832 15.4153 2.85131 14.5239 3.33728C12.9957 4.17031 11.015 5.03829 9.39575 5.03829Z"
            stroke="#344054"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        </div>
        <Tooltip  title={<Typography style={{fontSize:'12px', fontFamily:'Inter'}}>{template.title}</Typography>}>
        <span>
          <Typography sx={{fontWeight:'500', color:'black', fontStyle:'normal',fontSize:'12px', fontFamily:'Inter'}} noWrap>{template.title}</Typography>
        </span>
      </Tooltip>


      </CardHeader>

      <Tooltip title={<Typography style={{fontSize:'12px', fontFamily:'Inter'}}>{template.description}</Typography>}>
        <span>
          <Typography sx={{fontWeight:'400', color:'black', fontStyle:'normal',fontSize:'12px', fontFamily:'Inter'}} noWrap>{template.description}</Typography>
        </span>
      </Tooltip>

      <MonitorTemplateFooter
        isCreateMonitorLoading={isCreateMonitorLoading}
        onOpenMonitorPage={onOpenMonitorPage}
        onAlertMeClick={onAlertMeClick}
        showCustomizeBlueBeeBtn={showCustomizeBlueBeeBtn}
        onFineTuneClick={() => {
          recordRSEvent(RudderStackAutomationBuilderEvents.customize_with_blueblee, {
            context: RudderStackAutomationBuilderEvents.monitorContext
          });
          // Fine Tune Monitor
          onFineTuneTemplate();
        }}
      
        onExecuteClick={() => {
          setExecuteTemplateId(template.templateId);
          onExecuteTemplate();
        }}
      />
    </Card>
  );
};

export default ShopifyMonitorCard;
