
import React from 'react'
import clsx from 'clsx'
import Teams from '../../../teams/Teams'

const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
}

const ZorpCustomToolbar = (refetch) => {
    return class ZorpCalendarToolbar extends React.Component{            
        render() {
            let {
                localizer: { messages },
                label,
            } = this.props
    
            return (
                <div className="rbc-toolbar">
                    <span className="rbc-btn-group">
                        <button
                            type="button"
                            onClick={this.navigate.bind(null, navigate.TODAY)}
                        >
                            {messages.today}
                        </button>
                        <button
                            type="button"
                            onClick={this.navigate.bind(null, navigate.PREVIOUS)}
                        >
                            {messages.previous}
                        </button>
                        <button
                            type="button"
                            onClick={this.navigate.bind(null, navigate.NEXT)}
                        >
                            {messages.next}
                        </button>
                    </span>
    
                    <span className="rbc-toolbar-label">{label}</span>
    
                    <div style={{ "paddingRight": "100px", width: "300px" }}>
                        {/* <Link href="javascript:;" onClick={this.onFilterTeamClick} underline="always">Filter Team</Link> */}
                        <Teams
                            id="team"
                            onChange={(teamId) => refetch(teamId, this.props.date)}
                            disabled={false}
                            team={""}
                            size="full"
                            defaultLabel="Filter Users By Team"
                        // validators={["parentTeamIdCheck"]}
                        // errorMessages={["This field is required"]}
                        />
                    </div>
                    <span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span>
                </div>
            )
        }
    
        navigate = action => {
            this.props.onNavigate(action)
        }
    
        view = view => {
            this.props.onView(view)
        }
    
        viewNamesGroup(messages) {
            let viewNames = this.props.views
            const view = this.props.view
    
            if (viewNames.length > 1) {
                return viewNames.map(name => (
                    <button
                        type="button"
                        key={name}
                        className={clsx({ 'rbc-active': view === name })}
                        onClick={this.view.bind(null, name)}
                    >
                        {messages[name]}
                    </button>
                ))
            }
        }
    }
}


// ZorpCalendarToolbar.propTypes = {
//     view: PropTypes.string.isRequired,
//     views: PropTypes.arrayOf(PropTypes.string).isRequired,
//     label: PropTypes.node.isRequired,
//     localizer: PropTypes.object,
//     onNavigate: PropTypes.func.isRequired,
//     onView: PropTypes.func.isRequired,
// }

export default ZorpCustomToolbar;