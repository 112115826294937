//@ts-nocheck
import { FunctionComponent, memo, useState } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import {
  TaskFormInput,
  TaskFormLabelForShow,
  ZTextValueWrapper,
  ZTextWrapper,
} from "render-engine/render.styles";
import { IZTextInterface } from "./IZTextInterface";
import { useTheme } from "styled-components";
import {
  complimentColor,
  getPlaceholderText,
  getTeamInfoFromTeamId,
} from "utils/CommonUtils";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { ZInput } from "../input/ZInput";
import { cloneDeep, isEmpty } from "lodash";
import { ZComponent } from "components/common/ZComponent";
import { useDispatch } from "react-redux";
import { setInEditState } from "render-engine/reducers/show/TaskShowReducer";
import { ZComponentV2 } from "components/common/ZComponentV2";
import ListOfValueBadges from "views/entities/tables/ListOfValueBadges";
import moment from "moment";
import useAccessPermissions from "hooks/useAccessPermissions";
import { ResourceTypeConstant } from "views/entities/permissions/types";
import { DataType } from "ajv/dist/compile/validate/dataType";
import { DataFieldType } from "views/DataField/DataField.types";

export const ZTextComponent: FunctionComponent<{
  config: IZTextInterface;
  onChange: Function;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
}> = (props) => {
  const { config, onChange, register, errors } = props;
  const { taskData, inEditState, convertDataTypeToString, hasKeyValueEdited } =
    useTaskShow();
  const accessPermissions = useAccessPermissions({
    resourceType: ResourceTypeConstant.TABLE,
  });
  const urlRegex = new RegExp(
    /(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
  );
  const dispatch = useDispatch();
  let val = convertDataTypeToString(
    `${config.key}` || "",
    config.dataType === DataFieldType.LINKS ? false : true
  );
  if (config.dataType === "DATE") {
    if (typeof val === "string" && val.trim() === "") {
      val = "Add date";
    } else if (typeof val === "string") {
      val = moment(val).format("DD MMM YYYY hh:mm A");
    } else {
      if (!val) val = "Add date";
      else val = moment(val.toString()).format("DD MMM YYYY hh:mm A");
    }
  }

  if (config.dataType === DataFieldType.ONLY_DATE) {
    if (typeof val === "string" && val.trim() === "") {
      val = "Add date";
    } else if (typeof val === "string") {
      val = moment(val).format("DD MMM YYYY");
    } else {
      if (!val) val = "Add date";
      else val = moment(val.toString()).format("DD MMM YYYY");
    }
  }

  if (config.dataType === DataFieldType.LINKS) {
    // if value is empty string, undefined or empty array, set it to "Add link"
    if (
      typeof val === "string" &&
      (val.trim() === "" || val === "undefined")
    ) {
      val = "Add link";
    } else if (Array.isArray(val) && val.length === 0) {
      val = "Add link";
    }
  }

  // if(config.dataType === DataFieldType.TIME) {
  //   if (typeof val === "string" && val.trim() === "") {
  //     val = "Add time";
  //   }
  //   else if (typeof val === "string") {
  //     val = val
  //   }
  //   else {
  //     if (!val) val = "Add time";
  //     else val = val
  //   }
  // }

  if (config?.key === "teamIds") {
    const teamInfo = getTeamInfoFromTeamId(val);
    if (teamInfo) {
      val = `${teamInfo.name} - ${val}`;
    }
  }

  if (config?.key === "taskStatusName") {
    val = config.value?.val ?? "";
  }
  if (config?.dataType === "COMPUTED") {
    val = config.value?.val ?? "";
  }
  let allText = isNaN(val)
    ? typeof val === "string"
      ? val?.split(" ")
      : val || []
    : [val];
  let modifiedText = "";

  const theme = useTheme();

  if (val === "Add date") {
    modifiedText = `<span class="disableTextField"> ${val} </span>`;
  } else {
    allText?.forEach((word, index) => {
      if (urlRegex.test(word)) {
        if (word.includes("http")) {
          modifiedText += ` <u><a style={{color:"blue"}} href="${word}" target="_blank">${word}</a></u>`;
        } else {
          modifiedText += ` <u><a style={{color:"blue"}} href="//${word}" target="_blank">${word}</a></u>`;
        }
      } else {
        modifiedText += ` ${word}`;
      }
    });

    // check if modifiedText is empty
    if (modifiedText.trim() === "" && config?.key !== "taskStatusName") {
      const placeholderText = getPlaceholderText(config?.dataType || "TEXT");
      modifiedText = `<span class="disableTextField"> ${placeholderText} </span>`;
    }
    // replace "\n" with <br/>
    modifiedText = modifiedText.replace(/\n/g, "<br/>");
  }

  const handleDoubleClick = () => {
    const resource = window.location.href.includes("table") ? "table" : "task";
    if (resource === "table" && (!accessPermissions.edit_record || props.config.isReadOnly)) return;
    const nodeId = config.key;
    dispatch(setInEditState({ nodeId: nodeId, inEditState: true }));
  };

  const editConfig = cloneDeep(config);
  const isKeyChanged = hasKeyValueEdited(config.key);

  return (
    <>
      {inEditState?.[config.key] ? (
        <>
          {/* set edit state to false when user clicks outside */}

          <ZComponentV2 {...props} config={editConfig.editConfig} />
        </>
      ) : (
        <ZTextWrapper onClick={handleDoubleClick}>
          <TaskFormLabelForShow
            fontSize={config.label?.fontSize}
            color={config?.label?.color || "#6f6f6f"}
        >
            {config?.label?.val}
          </TaskFormLabelForShow>

          <ZTextValueWrapper
            onDoubleClick={handleDoubleClick}
            isKeyChanged={isKeyChanged}
          >
            {config?.key === "taskStatusName" ? (
              <TaskFormInput
                dangerouslySetInnerHTML={{ __html: modifiedText }}
                color={config?.value?.color || "white"}
                backgroundColor={theme.color.primary}
              />
            ) : !isEmpty(config?.otherConfig?.color) ? (
              val ? (
                typeof val === "string" &&
                val.split(",").map((word, index) => {
                  return (
                    <ListOfValueBadges
                      value={word}
                      backgroundColor={
                        complimentColor[
                          config?.otherConfig?.color?.[word.trim()]
                        ] || "#F2F4F7"
                      }
                      width="fit-content"
                      padding="4px"
                      color={
                        config?.otherConfig?.color?.[word.trim()] || "#344054"
                      }
                    />
                  );
                })
              ) : (
                <TaskFormInput
                  dangerouslySetInnerHTML={{ __html: modifiedText }}
                />
              )
            ) : (
              <TaskFormInput
                dangerouslySetInnerHTML={{ __html: modifiedText }}
              />
            )}
          </ZTextValueWrapper>
        </ZTextWrapper>
      )}
    </>
  );
};

export const ZTextV2 = memo(ZTextComponent);
