/**
 * The handleInfo is an array which contains id of each node present. When we click on
 * add handle, a new handle with id one greater than previous node's id is added. SO if
 * previous hadle was a, this will be "b" and so on..
 *
 * useNodeInternals is used as we are updating handle which is quite specific to one node.
 *
 */
import React, { useEffect } from 'react';
import { Handle, useUpdateNodeInternals, useEdges } from 'react-flow-renderer';
import '../css/flow.css';
import styled, { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import DeletionConfirmationDialog from 'utils/DeletionConfirmationDialog.js';
import {
  updateHandlerCount,
  rearrangeHandles,
  setSelectedDecisionNode,
  setSelectedTransition,
  deleteNode,
  setShowUpdateDecisionNodeNameModal,
  setShowWorkflowConfigModal,
} from 'views/workflows/reducers/workflowAction';
import { ProgramaticTooltip } from 'views/workflows/Tootltips/ProgramaticTooltip';
import Typography from '@mui/material/Typography';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import notification from 'notifications/notifications';
import { TiArrowShuffle } from 'react-icons/ti';
import { HiOutlineArrowSmDown } from 'react-icons/hi';
import { HiOutlineArrowSmUp } from 'react-icons/hi';
import { BiEdit } from 'react-icons/bi';
import useWorkflow from 'views/workflows/hooks/useWorkFlow';

export const ZNodeContainer = styled.div`
  width: 20vw;
  border: ${(props) => (props.variant === 'error' ? '2px solid red' : '2px solid #3CC13B;')};
  background-color: white;
  padding: 20px;
  border-radius: 8px;

  hr {
    margin-top: 10px;
  }
`;

export const DetailContainer = styled.div`
  margin-right: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
export const IconContainer = styled.div`
  /* margin-left: 0.8rem; */
  display: inline;
  background-color: #3cc13b;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }

  svg {
    display: inline !important;
  }

  span {
    margin-left: 4px;
  }
`;
export const NameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 6rem;
  color: black;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 13px;

  span {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
  }
`;

const IfBlock = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  border: ${(props) => (props.variant === 'if' ? ' 1px solid #C2C9D1' : '')};
  border-radius: 4px;
  padding: ${(props) => (props.variant === 'if' ? '10px' : '')};
`;

const RearrangeHandleContainer = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    font-size: 12px;
    font-weight: 600;
    color: #808080;
    margin-bottom: 10px;
  }
`;

const Button = styled.div`
  svg {
    display: inline;
  }

  span {
    margin-left: 8px;
  }

  padding: 8px;
  /* border: 1px solid #C2C9D1; */
  border-radius: 4px;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  border: ${(props) =>
    props.variant === 'primary'
      ? '1px solid #C2C9D1'
      : props.variant === 'danger'
      ? '1px solid #F03738'
      : '1px solid #C2C9D1'};
  text-align: ${(props) => (props.action === 'yes' ? 'center' : '')};
  color: ${(props) => (props.variant === 'danger' ? 'white' : props.action === 'yes' ? 'white' : '#616569')};
  background-color: ${(props) =>
    props.variant === 'primary' ? props.theme.color.primary : props.variant === 'danger' ? '#F03738' : '#F2F2F2'};
  &:hover {
    cursor: ${(props) => (props.action === 'yes' ? 'pointer' : '')};
  }
`;

const CustomHandle = ({ idx, nodeId, id, ...props }) => {
  return <Handle id={id} {...props} />;
};

const ZorpDecisionNode = (props) => {
  const dispatch = useDispatch();
  const { data } = props;

  const edges = useEdges();
  const { showErrorTooltip } = useWorkflow();
  const [showDelete, setShowDelete] = React.useState(false);

  /* Validation to make sure that each handle has only one outgoing edge  */
  const isValidConnection = (info) => {
    const foundIncomingEdge =
      edges.filter((edge) => edge.source === data.id && edge.sourceHandle === info.sourceHandle) || [];
    if (foundIncomingEdge.length === 0) {
      return true;
    } else {
      notification('error', 'Cannot have more than one outgoing edge from choice node.');
      return false;
    }
  };

  /* Function to add a new handle */
  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(updateHandlerCount(data.id));
    updateNodeInternals(data.id);
  };

  const deleteDecisionNode = () => {
    dispatch(
      deleteNode({
        id: props.id,
        name: props.name,
        type: props.type,
        data: data,
      })
    );

    dispatch(setSelectedDecisionNode(null));
  };

  /* deletePossible will decide whether the switch could be deleted or not.
    for exmple, It is must to have an else switch, so we can hide the delete button for it.
    Also a switch must have atleast one switch condition, so if there is only IF condition, we
    can disable the delete button.
  */
  const handleSwitchSelect = (e, info, deletePossible) => {
    e.stopPropagation();
    dispatch(setSelectedTransition(null));
    dispatch(setShowWorkflowConfigModal(false));
    dispatch(setSelectedDecisionNode(null));
    setTimeout(() => {
      dispatch(setSelectedDecisionNode({ ...info, deletePossible, nodeId: data.id }));
    }, 100);
  };

  

  const updateNodeInternals = useUpdateNodeInternals();
  const theme = useTheme()
  useEffect(() => {
    updateNodeInternals(data.id);
  }, [data, updateNodeInternals]);

  return (
    <ZNodeContainer variant={data?.errors?.length > 0 ? 'error' : ''}>
      <CustomHandle
        type='target'
        id='#'
        position='left'
        style={{
          height: '44px',
          width: '14px',
          top: '36px',
          marginLeft: '-7px',
          backgroundColor: 'rgb(62, 102, 251)',
          borderRadius: '4px',
          border: `1px solid ${theme.color.primary}`,
        }}
      />
      <NameContainer>
        <div>
          <IconContainer>
            {' '}
            <TiArrowShuffle color={'white'} size={'1.5rem'} display='inline' />{' '}
          </IconContainer>{' '}
          <span> {data.name} </span>{' '}
          <BiEdit
            onClick={() => {
              dispatch(
                setShowUpdateDecisionNodeNameModal({
                  nodeId: data.id,
                  name: data.name,
                })
              );
            }}
            style={{ display: 'inline' }}
            size={'1.4rem'}
            color='#616569'
            display={'inline'}
            cursor={'pointer'}
          />
        </div>
        {data?.errors?.length > 0 && (
          <ProgramaticTooltip
            open={showErrorTooltip.choiceNode === props.id}
            title={
              <>
                <Typography color='inherit'>
                  {data?.errors?.map((item) => (
                    <li> {item} </li>
                  ))}
                </Typography>
              </>
            }
          >
            <div>
              <BsFillInfoCircleFill style={{ marginLeft: '8px' }} size={'1.4rem'} color='#FF2D55' />
            </div>
          </ProgramaticTooltip>
        )}
      </NameContainer>
      <hr />
      {/* <DecisionInfo nodeId={data.id} /> 
        Traversing only till second last idx as the last idx has else statement
      */}

      {data?.handleInfo?.slice(0, data?.handleInfo.length - 1)?.map((info, i) => (
        <>
          <IfBlock variant='if'>
            <RearrangeHandleContainer>
              <p> If </p>
              <div>
                {i !== 0 && (
                  <button
                    onClick={() =>
                      dispatch(
                        rearrangeHandles({
                          step: -1,
                          nodeId: data.id,
                          idx: i,
                        })
                      )
                    }
                  >
                    <HiOutlineArrowSmUp color={'#616569'} size={'1.3rem'} />
                  </button>
                )}

                {i !== data.handleInfo.length - 2 && (
                  <button
                    onClick={() => dispatch(rearrangeHandles({ step: 1, nodeId: data.id, idx: i }))}
                    style={{ marginLeft: '10px' }}
                  >
                    <HiOutlineArrowSmDown color={'#616569'} size={'1.3rem'} />
                  </button>
                )}
              </div>
            </RearrangeHandleContainer>

            <Button
              onClick={(e) => {
                handleSwitchSelect(e, info, data?.handleInfo?.slice(0, data?.handleInfo.length - 1).length > 1);
              }}
              variant={info?.errors ? 'danger' : 'secondary'}
            >
              {' '}
              <BiEdit
                onClick={(e) => {
                  handleSwitchSelect(e, info, data?.handleInfo?.slice(0, data?.handleInfo.length - 1).length > 1);
                }}
                size={'1.4rem'}
                color='#616569'
                display={'inline'}
                cursor={'pointer'}
              />
              <span>{info?.data?.name || 'Configure Switch'} </span>
            </Button>
          </IfBlock>

          <CustomHandle
            type='source'
            id={info.id}
            isValidConnection={isValidConnection}
            position='right'
            style={{
              height: '84px',
              width: '14px',
              top: `${114 + i * 100}px`,
              marginRight: '-9px',
              backgroundColor: theme.color.primary,
              borderRadius: '4px',
              border: `1px solid ${theme.color.primary}`,
            }}
          />
          {i === data?.handleInfo?.length - 2 && (
            <>
              <Button action='yes' variant='primary' onClick={(e) => handleClick(e)}>
                {' '}
                Add Switch{' '}
              </Button>
              <hr />
            </>
          )}
        </>
      ))}
      <IfBlock variant='else'>
        <Button
          variant='secondary'
          onClick={(e) => {
            handleSwitchSelect(
              e,
              data.handleInfo[data?.handleInfo?.length - 1],
              data?.handleInfo?.slice(0, data?.handleInfo?.length - 1)?.length > 1
            );
          }}
        >
          {' '}
          <BiEdit
            onClick={(e) => {
              handleSwitchSelect(
                e,
                data.handleInfo[data?.handleInfo?.length - 1],
                data?.handleInfo?.slice(0, data?.handleInfo?.length - 1)?.length > 1
              );
            }}
            size={'1.4rem'}
            color='#616569'
            display={'inline'}
            cursor={'pointer'}
          />
          <span>{data.handleInfo[data?.handleInfo?.length - 1].data.name || 'Configure Switch'} </span>
        </Button>

        <CustomHandle
          type='source'
          id='b'
          isValidConnection={isValidConnection}
          position='right'
          style={{
            height: '44px',
            width: '14px',
            top: `${data.handleInfo.length * 100 + 47}px`,
            marginRight: '-9px',
            backgroundColor:theme.color.primary,
            borderRadius: '4px',
            border: `1px solid ${theme.color.primary}`,
          }}
        />
      </IfBlock>

      <Button variant='danger' action='yes' onClick={() => setShowDelete(true)}>
        {' '}
        Delete{' '}
      </Button>
      <DeletionConfirmationDialog
        id={'descisionNode'}
        deletionTitle={'Delete Decision Node'}
        deletionText={'Do you want to delete the decision node?'}
        isOpen={showDelete}
        onConfirmDelete={() => deleteDecisionNode()}
        onClose={() => setShowDelete(false)}
      />
    </ZNodeContainer>
  );
};

export default React.memo(ZorpDecisionNode);


