//@ts-nocheck
import { BffService } from "providers/data/services/BffService";
import { useState, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import _ from "lodash";
import {
  HomePageComponentProps,
  createComponent,
} from "./HomePageComponentFactory";
import WelcomeComponent from "./components/homepage/Header";
import { BiPaintRoll } from "react-icons/bi";

// Define a type for the component props
interface LayoutProps {
  components: { [key: string]: HomePageComponentProps };
  layouts: any;
  breakpoints?: any;
  cols?: any;
  rowHeight?: number;
  margin?: [number, number];
}

const ResponsiveGridLayout = WidthProvider(Responsive);

const MyGridComponent = () => {
  const [layoutConfig, setLayoutConfig] = useState<LayoutProps>({
    components: {},
    layouts: {},
    breakpoints: {},
    cols: {},
  });

  const [layoutComponents, setLayoutComponents] = useState({});

  useEffect(() => {
    BffService.getHomeScreenConfig().then((res: any) => {
      const width = window.innerWidth;
      let bp = "lg";
      const breakpoints = res?.data?.breakpoints ?? {};
      const sortedBreakpoints = Object.keys(breakpoints).sort(
        (a, b) => breakpoints[b] - breakpoints[a]
      );

      for (let i = 0; i < sortedBreakpoints.length; i++) {
        const breakpoint = sortedBreakpoints[i];
        if (width >= res?.data?.breakpoints[breakpoint]) {
          bp = breakpoint;
          break;
        }
      }
      const components: any = {};
      res?.data?.layouts[bp]?.forEach((c: any) => {
        components[c.i] = res?.data?.components[c.i];
      });

      setLayoutComponents(components);
      setLayoutConfig(res?.data);
    });
  }, []);

  const onBreakpointChange = (newBreakpoint: any) => {
    console.log("onBreakpointChange", newBreakpoint);
  };

  return (
    <>
      <WelcomeComponent />
      {layoutConfig?.layouts?.lg && (
        <ResponsiveGridLayout
          className="layout"
          layouts={layoutConfig?.layouts}
          breakpoints={layoutConfig?.breakpoints}
          cols={layoutConfig?.cols}
          onBreakpointChange={onBreakpointChange}
          rowHeight={layoutConfig?.rowHeight || 200}
          margin={layoutConfig?.margin || [15, 25]}
          isDraggable={false}
          isRearrangeable={false}
          isResizable={false}
        >
          {Object.keys(layoutConfig?.components || {}).map((componentKey) => {
            const component = layoutConfig.components[componentKey];
            // check if componentKey is in layoutComponents
            if ((layoutComponents as { [key: string]: any })[componentKey]) {
              return <div key={componentKey}>{createComponent(component)}</div>;
            }
          })}
        </ResponsiveGridLayout>
      )}
    </>
  );
};

export default MyGridComponent;