//@ts-nocheck
import Box from '@mui/material/Box';
import bookIcon from 'assests/alert_template_library.svg';
import BlueBeeIcon from "assests/chat_bluebee.svg";
import dbIcon from 'assests/zorp_db_icon.svg';
import { PageCategoryEnum, recordPageVisit } from 'config/Rudderstack';
import { RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';
import Lottie from "lottie-react";
import notification from "notifications/notifications";
import { ZorpResponse } from "providers/data/models/ZorpResponse";
import { ICreateTemplateFromPromptPayload, MonitorService } from "providers/data/services/MonitorService";
import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { recordRSEvent, timeStampToReadableFormat } from "utils/CommonUtils";
import { generateId } from "utils/Utils";
import ZLogoSmall from "../../views/layouts/Logo.svg";
import useAccount from "../layouts/reducer/useAccount";
import DatasourceModal from "./DatasourceModal";
import MonitorCreateConfirmModal from "./MonitorCreateConfirmModal";
import CreateFromScratchBtn, { IMonitorCreateBtnContext } from './MonitorListComponents/CreateFromScratchBtn';
import NoTemplates from "./NoTemplates";
import ShopifyMonitorCardsContainer from "./ShopifyMonitorCardsContainer";
import TemplateEditModal from "./TemplateEditModal";
import DatasourceListComponent from "./datasourceSelector/DatasourceListComponent";
import MonitorConversationAICard from "./monitorConversation/MonitorConversationAICard";
import MonitorConversationFooter from "./monitorConversation/MonitorConversationFooter";
import MonitorHeaderDetails from "./monitorConversation/MonitorHeaderDetails";
import MonitorPromptExamples, { IMonitorPromptData } from "./monitorConversation/MonitorPromptExamples";
import { IDatasourceCategory, IScreenContext, setDatasourceModalContext, setIsConfirmMonitorModalOpen, setIsTemplateGeneratingFromPrompt, setMonitorCreateFromTemplatePayload, setSelectedDatasource } from "./reducer/MonitorReducer";
import useMonitor from "./reducer/useMonitor";
import TemplateGeneratingAnimation from "./template_generating.json";
import { HeadingText } from './Monitor.styles';


// did not follow figma file
const Heading = styled.h1`
  font-size: 26px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
  padding: 20px 0 0 0;
`;

const SubHeading = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin: 10px 0 !important;
`;

interface IMonitorParams {
  ongoingTs: number;
  lastTs: string;
  count: string | null;
  ongoingSync: boolean;
}

export enum IConversationType {
  User = "user",
  AI_TEMPLATE = "ai_template",
  AI_CONVERSATION = "ai_conversation"
}

type Frequency = {
  detail: string;
  cronExpression: string;
};

export type IConversationItem = {
  type: IConversationType;
  time: string;
  data: {
    message?: string;
    monitorName?: string;
    sqlQuery?: string;
    queryDescription?: string;
    monitorDescription?: string;
    frequency?: Frequency;
    emailSubject?: string;
    emailBody?: string;
  };
};

const ShopifyMonitorWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
`;

const ShopifyContainer = styled.section`
  margin: 24px;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
`;

const MonitorPromptExamplesWrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 220px);
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const MonitorPromptChatWrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 250px);
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  padding-right:8px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: hidden;
`;

const MiddleSectionFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 90%;
`;

const ConverSationWrapper = styled.div`
  width: 35%;
  background-color: #FCFCFD;
  border-left: 1px solid #eaecf0;
  display: flex;
  flex-direction: column;
  height: 97vh; /* Full height of the viewport */
  justify-content: space-between; /* Spaces out the children */

  @media (max-width: 1600px) {
    width: 40%; // Width for small devices (tablets)
  }

  @media (max-width: 1200px) {
    width: 50%; // Width for medium devices (desktops)
  }
`;

const UserConvoWrapper = styled.div`
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  color:black;
  font-size:12px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #eaecf0;
  background-color: #fff;
  margin-left: ${(props: { type: IConversationType }) => props.type === IConversationType.User ? "28px" : "0px"};};
  margin-right: ${(props: { type: IConversationType }) => props.type === IConversationType.User ? "0px" : "28px"};};
  margin-bottom: 24px;
`;

const UserConvoText = styled.p`
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
`;

const UserConvoProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size:12px;
  color:black;
  margin-bottom: 6px;
  justify-content: ${(props: { type: IConversationType }) => props.type === IConversationType.User ? "flex-end" : "flex-start"};};
`;

const DataSourceText = styled.p`
color: black;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
`

export interface IShopifyTemplate {
  title: string;
  description: string;
  tags: string[];
  templateId: string;
  sqlQuery: string;
  category: string;
  queryDescription: string;
  emailSubject: string;
  emailBody: string;
  // will add this below params in the template in the cc, instead of getting from ai api
  accountEmail?: string;
  selectedDataSource?: IDatasourceListInterface;
  datasourceId?: string;
  cronExpression?: string;
}


interface IShopifyMonitor {
  gotoMonitor: () => void;
}

export interface IDatasourceListInterface {
  value: string;
  label: string;
  datasourceId:string;
  datasourceType: string;
  displayName: string;
  databaseVersion: string;
  password: string;
  name: string;
  host: string;
  port: number;
  username: string;
  ssl: boolean;
  datasourceCategory: string;
};

const IAcmeCorpSamplePrompt: IMonitorPromptData[] = [
  {
    title: "Canceled Orders without Refund",
    description:
      "Checks for orders with phone numbers that don't match the expected format, potentially indicating data entry errors.",
  },
  {
    title: "Large Orders",
    description:
      "Checks for orders with phone numbers that don't match the expected format, potentially indicating data entry errors.",
  },
  {
    title: "Unusual Order Item Count",
    description:
      "Detects delivery records with an abnormal number of items.",
  },
];

const IShopifyMonitorTemplates: IMonitorPromptData[] = [
  {
    title: "Inventory Stock Out Alert",
    description:
      "Generates an alert when any product's inventory level reaches zero, indicating a stockout situation. Monitoring stockouts is crucial for maintaining sales continuity and optimizing inventory replenishment strategies.",
  },
  {
    title: "Large Order Alert",
    description:
      "Generates an alert for orders that exceed a predefined total value, signaling either a high-value sales opportunity or the need for further review to prevent potential fraud.",
  },
  {
    title: "High-Volume Return Alert",
    description:
      "Notifies the store when there is an unusual increase in the number of product returns within a specific timeframe, suggesting potential issues with product quality or customer expectations.",
  }
]

const ShopifyMonitor = ({
  gotoMonitor
}: IShopifyMonitor) => {
  const dispatch = useDispatch();
  const { isConfirmMonitorModalOpen, selectedDatasource, monitorCreateFromTemplatePayload, datasourceModalContext } = useMonitor();
  const [createMonitorLoading, setCreateMonitorLoading] = useState(false);
  const [templates, setTemplates] = useState<IShopifyTemplate[]>([]);
  const { datasourceListData } = useMonitor();
  const [conversationLoading, setconversationLoading] = useState(false);
  const [conversationStarted, setConversationStarted] = useState<boolean>(false);
  const [disableConversationSendButton, setDisableConversationSendButton] = useState<boolean>(false);
  const [conversationArray, setConversationArray] = useState<IConversationItem[]>([]);
  const [conversationThreadId, setConversationThreadId] = useState<string>('');
  const [isChatFineTune, setIsChatFineTune] = useState<boolean>(false);
  const [monitorCreateConfirmOpen, setMonitorCreateConfirmOpen] = useState(false);
  const { account } = useAccount();
  const isShopifyDatasource = selectedDatasource?.datasourceCategory == IDatasourceCategory.SHOPIFY;
  const isZorpSampleDatasource = selectedDatasource?.datasourceCategory == IDatasourceCategory.ZORP_SAMPLE_DATASOURCE;

  const handleCreateMonitorFromTemplateClick = (template: IShopifyTemplate) => {
    dispatch(setMonitorCreateFromTemplatePayload(undefined));
    console.log("template: ", template);
    // { templateIds: templateId, payloadType, templatePayload, actionEmailId }
    // Call Monitor service to create a new monitor(s)
    setCreateMonitorLoading(true);
    const monitorPayload = {
      actionEmailId: template.accountEmail,
      payloadType: 'PAYLOAD',
      templatePayload: template
    }
    MonitorService.createMonitorsFromTemplateIds(monitorPayload)
      .then((response: ZorpResponse) => {
        setCreateMonitorLoading(false);
        if (response.code !== "200") {
          notification(
            "error",
            response?.message || "Error in creating monitors, pls try again"
          );
          return;
        }
        gotoMonitor();
        dispatch(setIsConfirmMonitorModalOpen(false));
        // setMonitorCreateConfirmOpen(true);
        notification("success", "Alert created successfully");
      })
      .catch((error: any) => {
        setCreateMonitorLoading(false);
        console.log("error: ", error);
        notification("error", "Error in creating monitors, pls try again");
      });
  };

  const fetchMonitorTemplates = async (datasourceId?: string) => {
    try {
      const datasourceDetails: any = datasourceListData.find(
        (data: any) => data.datasourceId === datasourceId
      );
      let templateCategory;

      if (datasourceDetails?.datasourceCategory == "Shopify" || datasourceDetails?.datasourceCategory == IDatasourceCategory.SHOPIFY) {
        templateCategory = IDatasourceCategory.SHOPIFY;
      } else if ((datasourceDetails?.datasourceCategory == IDatasourceCategory.ZORP_SAMPLE_DATASOURCE)) {
        templateCategory = "zorp_sample";
      } else if (datasourceDetails?.datasourceCategory == IDatasourceCategory.ZORP_DEFAULT_CLICKHOUSE) {
        templateCategory = "zorp";
      } else {
        templateCategory = "user_datasource";
      }

      const variables: { category: string; datasourceId?: string } = {
        category: templateCategory
      }
      if (datasourceId) {
        variables.datasourceId = datasourceId;
      }
      const templateData = await MonitorService.executeGQL({
        query:
          "query GetTemplates($category: String!, $datasourceId: String) { getTemplates(category: $category, datasourceId: $datasourceId) { templates {title tags templateId description emailSubject emailBody sqlQuery queryDescription status} } }",
        variables,
      });
      setTemplates(templateData?.data?.getTemplates?.templates || []);
    } catch (err: any) {
      notification("error", err?.message || "Error in fetching templates");
    }
  };


  const sendMessage = async (sendMessagePayload: ICreateTemplateFromPromptPayload) => {
    dispatch(setIsTemplateGeneratingFromPrompt(true));
    const createTemplateFromPromptData = await MonitorService.createTemplateFromPrompt(sendMessagePayload);
    setconversationLoading(false);
    dispatch(setIsTemplateGeneratingFromPrompt(false));
    setDisableConversationSendButton(false);

    if (createTemplateFromPromptData?.data?.messageData.monitorName) {
      const templateData = createTemplateFromPromptData?.data;
      const promptData: IConversationItem = {
        type: IConversationType.AI_TEMPLATE,
        time: new Date().toISOString(),
        data: {
          message: templateData.messageData.message,
          monitorName: templateData.messageData.monitorName,
          monitorDescription: templateData.messageData.monitorDescription,
          queryDescription: templateData.messageData?.queryDescription,
          sqlQuery: templateData.messageData.sqlQuery,
          emailSubject: templateData.messageData.emailSubject,
          emailBody: templateData.messageData.emailBody,
        },
      }

      setConversationArray(currentArray => [...currentArray, promptData]);
    } else {
      const promptData: IConversationItem = {
        type: IConversationType.AI_CONVERSATION,
        time: new Date().toISOString(),
        data: {
          message: createTemplateFromPromptData?.data?.messageData.message,
        },
      }
      setConversationArray(currentArray => [...currentArray, promptData]);
    }

  }

  const triggerSendMessageFun = async (promptMessage: string) => {
    setConversationStarted(true)
    setDisableConversationSendButton(true);
    setconversationLoading(true);

    const promptData: IConversationItem = {
      type: IConversationType.User,
      time: new Date().toISOString(),
      data: {
        message: promptMessage
      },
    }

    const checkIfLastConversationIsAITemplate = !!conversationArray.reverse().find(item => item.type === IConversationType.AI_TEMPLATE)

    setConversationArray(currentArray => [...currentArray, promptData]);

    const fineTunedTemplate = conversationArray.reverse().find(item => item.type === IConversationType.AI_TEMPLATE) || conversationArray[conversationArray.length - 1];

    const sendMessagePayload: ICreateTemplateFromPromptPayload = {
      threadId: conversationArray.length == 0 ? generateId(6) : conversationThreadId,
      datasourceId: selectedDatasource?.datasourceId,
      datasourceDisplayName: selectedDatasource.displayName,
      isFineTune: isChatFineTune,
      checkIfLastConversationIsAITemplate,
      conversationType: IConversationType.User,
      messageData: {
        ...((isChatFineTune || checkIfLastConversationIsAITemplate) ? fineTunedTemplate?.data : {}),
        message: promptMessage,
      }
    }

    await sendMessage(sendMessagePayload);
  }

  const closeConversation = async () => {
    const closeConversationPayload = {
      threadId: conversationThreadId,
      datasourceId: selectedDatasource?.datasourceId
    }

    const closeConversationData = await MonitorService.closeConversation(closeConversationPayload);
    console.log("closeConversationData: ", closeConversationData);
  }

  const fetchAllConversations = async (datasourceId: string) => {
    const conversations = await MonitorService.fetchAllConversations(datasourceId);
    if (conversations?.data.length > 0) {
      setConversationStarted(true);
      setConversationThreadId(conversations?.data[0].threadId);
      const conversationArray: any = [];
      conversations?.data.forEach((data: any) => {
        const conversationData: IConversationItem = {
          type: data.conversationType,
          time: data.createdAt,
          data: {
            message: data.messageData?.message,
            monitorName: data.messageData?.monitorName,
            sqlQuery: data.messageData?.sqlQuery,
            monitorDescription: data.messageData?.monitorDescription,
            queryDescription: data.messageData?.queryDescription,
            emailSubject: data.messageData?.emailSubject,
            emailBody: data.messageData?.emailBody,
          },
        }
        conversationArray.push(conversationData);
      })
      setConversationArray(conversationArray);
    } else {
      setConversationStarted(false);
      setConversationArray([]);
      setConversationThreadId(generateId(6));
    }
  }

  useEffect(() => {
    if (monitorCreateFromTemplatePayload?.title) {
      handleCreateMonitorFromTemplateClick(monitorCreateFromTemplatePayload)
    }
  }, [monitorCreateFromTemplatePayload?.title]);

  useEffect(() => {
    if ((selectedDatasource?.datasourceId && datasourceListData?.length > 0)) { 
      fetchMonitorTemplates(selectedDatasource?.datasourceId);
      fetchAllConversations(selectedDatasource?.datasourceId);
    }
  }, [selectedDatasource?.datasourceId, datasourceListData?.length]);

  const clearConversation = async () => {
    setConversationArray([]);
    setConversationStarted(false);
    setconversationLoading(false);
    setDisableConversationSendButton(false);
    await closeConversation();
  }

  useEffect(()=>{
    recordPageVisit(
        RudderStackAutomationBuilderEvents.monitorContext,
        PageCategoryEnum.templateList
      );
},[])

  return (
    <>
      <ShopifyMonitorWrapper>
        <PageWrapper>
          <ShopifyContainer>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <HeadingText>Activate Alerts</HeadingText>
              <CreateFromScratchBtn
              context={IMonitorCreateBtnContext.MONITOR}
              />
            </Box>

            <Box display={'flex'} gap={'12px'} alignItems={'center'} margin={'16px 32px 16px 0px'} paddingBottom={'8px'}>
<img  src={dbIcon} alt='Icon' />
            <DataSourceText>Select your data source</DataSourceText>
</Box>

            <DatasourceListComponent
              onEditDatasource={(datasourceValue: any) => {
                console.log('datasourceValue', datasourceValue);
              }}
              datasource={datasourceListData}
              retoolCreatedDataSourceId={
                selectedDatasource as IDatasourceListInterface
              }
              gotoDataSourceAdd={() => {
                dispatch(setDatasourceModalContext({ datasourceContext: IScreenContext.CREATE }));
              }}
              onDatasourceSelect={(datasourceValue: IDatasourceListInterface) => {
                dispatch(setSelectedDatasource(datasourceValue))
              }}
            />

            {
              datasourceListData?.length > 0 && (

<Box display={'flex'} gap={'12px'} alignItems={'center'} margin={'16px 16px 16px 0px'} paddingBottom={'8px'}>
<img  src={bookIcon} alt='Icon' />
<DataSourceText>Our Bluebee AI have generated these suggestions specifically for you. Click on "Alert Me" to setup alerts</DataSourceText>
</Box>
      
              )}

            {templates?.length > 0 ? (
              <ShopifyMonitorCardsContainer
                selectedDataSource={selectedDatasource as IDatasourceListInterface}
                createMonitorLoading={createMonitorLoading}
                fineTuneMonitorOnClick={async (template: IShopifyTemplate) => {
                  setConversationArray([]);
                  setconversationLoading(false);
                  setDisableConversationSendButton(false);
                  // setSelectedPrompt('');
                  await closeConversation();

                  setConversationStarted(true)
                  const promptData: IConversationItem = {
                    type: IConversationType.AI_TEMPLATE,
                    time: new Date().toISOString(),
                    data: {
                      message: "What needs to be customized here?",
                      monitorName: template.title,
                      monitorDescription: template.description,
                      queryDescription: template.queryDescription,
                      sqlQuery: template.sqlQuery,
                      emailSubject: template.emailSubject,
                      emailBody: template.emailBody,
                    },
                  }
                  setIsChatFineTune(true);
                  setConversationArray(currentArray => [...currentArray, promptData]);

                }}
                onOpenMonitorPage={gotoMonitor}
                templates={templates || []}
                accountEmail={account?.primaryContactEmail}
              />
            ) : (
              datasourceListData?.length > 0 &&
              <NoTemplates accountEmail={account?.primaryContactEmail} />
            )}
          </ShopifyContainer>


          {
            datasourceListData?.length > 0 && (
              <ConverSationWrapper>
                <MonitorHeaderDetails
                  hideBackBtn={!conversationStarted}
                  onClickGoBack={clearConversation} />
                <MiddleSectionFooterWrapper>
                  {(!conversationStarted && (isZorpSampleDatasource || isShopifyDatasource)) && (
                    <MonitorPromptExamplesWrapper>
                      <MonitorPromptExamples
                        onTemplateClick={async (promptTitle) => {
                          setConversationStarted(true);
                          await triggerSendMessageFun(promptTitle)
                        }}
                        promptExamples={isZorpSampleDatasource ? IAcmeCorpSamplePrompt : isShopifyDatasource ? IShopifyMonitorTemplates : []}
                      />
                    </MonitorPromptExamplesWrapper>
                  )}

                  {conversationStarted && (
                    <MonitorPromptChatWrapper>
                      {conversationArray.map((conversationData: IConversationItem) => {
                        return (
                          <>
                            {conversationData.type === IConversationType.User && (
                              <>

                                <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '28px' }}>

                                  <UserConvoText>
                                    {timeStampToReadableFormat(conversationData.time)}
                                  </UserConvoText>


                                  <UserConvoProfileWrapper type={IConversationType.User}>
                                    <UserConvoText>
                                      {account?.accountName}
                                    </UserConvoText>
                                    <img
                                      src={account?.accountConfig?.logo || ZLogoSmall}
                                      alt="user_profile"
                                      style={{ width: "23px", height: "23px" }}
                                    />
                                  </UserConvoProfileWrapper>

                                </div>

                                <UserConvoWrapper type={IConversationType.User}>
                                  <p
                                  style={{
                                    fontSize:'12px',
                                    color:'black'
                                  }}
                                  >{conversationData?.data?.message}</p>
                                </UserConvoWrapper>
                              </>
                            )}

                            {conversationData.type === IConversationType.AI_TEMPLATE && (
                              <>
                                <MonitorConversationAICard
                                  
                                  messageData={conversationData}
                                />
                              </>
                            )}

                            {conversationData.type === IConversationType.AI_CONVERSATION && (
                              <>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '28px' }}>
                                  <UserConvoProfileWrapper
                                    type={IConversationType.AI_CONVERSATION}
                                  >
                                    <UserConvoText>
                                      {"BlueBee"}
                                    </UserConvoText>
                                    <img
                                      src={BlueBeeIcon}
                                      alt="user_profile"
                                      style={{ width: "23px", height: "23px" }}
                                    />
                                  </UserConvoProfileWrapper>


                                  <UserConvoText>
                                    {timeStampToReadableFormat(conversationData.time)}
                                  </UserConvoText>

                                </div>

                                <UserConvoWrapper type={IConversationType.AI_CONVERSATION}>
                                  <p>{conversationData?.data?.message}</p>
                                </UserConvoWrapper>

                              </>
                            )}
                          </>
                        );
                      }
                      )}
                    </MonitorPromptChatWrapper>
                  )}
                  {
                    (conversationLoading && conversationStarted) &&
                    <Lottie
                      loop={true}
                      height={20}
                      style={{ height: "16px", width: "38px", marginLeft: '16px' }}
                      autoplay={true}
                      animationData={TemplateGeneratingAnimation}
                    />
                  }

                </MiddleSectionFooterWrapper>
                <MonitorConversationFooter
                  disableSendButton={disableConversationSendButton}
                  onSendClick={async (promptText) => {
                    await triggerSendMessageFun(promptText)
                    recordRSEvent(RudderStackAutomationBuilderEvents.aiCoversationMessage, {
                      context: RudderStackAutomationBuilderEvents.monitorContext
                    });
                  }}
                />
              </ConverSationWrapper>)
          }
        </PageWrapper>
      </ShopifyMonitorWrapper>
      {
        isConfirmMonitorModalOpen && <TemplateEditModal />
      }

      {
        datasourceModalContext?.datasourceContext && <DatasourceModal/>
      }

      <MonitorCreateConfirmModal
        onManageMonitor={gotoMonitor}
        openModal={monitorCreateConfirmOpen}
        handleClose={() => {
          setMonitorCreateConfirmOpen(false)
        }}
      />
    </>
  );
};
export default memo(ShopifyMonitor);