//@ts-nocheck
import { useEffect, useState } from "react";
import FileUpload from "components/dropzone/FileUpload";
import { FunctionComponent } from "react";
import {
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
  UseFormGetValues,
  useFieldArray,
  Control,
  UseFormSetError,
  UseFormClearErrors,
} from "react-hook-form";
import { IZUploader } from "./IZUploader";
import { AiOutlinePlus, AiOutlineWarning } from "react-icons/ai";
import ZShowImage, { ImageBox } from "components/ZImageViewer/ZShowImage";
import _ from "lodash";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { Modal } from "@mui/material";
import styled from "styled-components";
import { ComponentType } from "components/ComponentTypeEnum";
import { useDispatch } from "react-redux";
import { setTaskData } from "render-engine/reducers/show/TaskShowReducer";
import {
  BlockLabel,
  ColSpan4,
  TaskFormLabelForShow,
  ZActionButton,
} from "render-engine/render.styles";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import FileUploadV2 from "components/dropzone/FileUploadV2";

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
`;

export const ZUploaderSignV2: FunctionComponent<{
  config: IZUploader;
  onChange: Function;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  isOpenedAsSubfield: boolean;
  control: Control;
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
}> = ({
  config,
  onChange,
  register,
  getValues,
  errors,
  isOpenedAsSubfield,
  control,
  setValue,
  setError,
  clearErrors,
}) => {
  // Construct validation

  const { convertDataTypeToString } = useTaskShow();
  const [alreadyUploadedSign, setAlreadyUploadedSign] = useState<string[]>([]);

  // Make an entry for this config in the react hook form context.
  // Here the control is coming from the parent form
  // Parent for in case isOpenedAsSubfield === true is CreateSubFieldModal
  // else it is from CreateTask/EditTask
  

  const dispatch = useDispatch();

  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const val = convertDataTypeToString(`${config.key}` || "", false, "");
  // config.key : []

  // this function will be called if the field is opened as a subfile for any list.
  // we are appending the link to the form array
  const updateLocalStateWhenOpenedAsSubfield = (
    key: string,
    value: string
  ): void => {
    // setUploadedImage((prev: string[]) => [...prev, value]);
    setValue(config.key, value[0]);
    setAlreadyUploadedSign((prev) => [...prev, value[0]]);
    dispatch(setTaskData({ nodeId: config.key, data: value[0] }));
  };

  const removeFiles = (idx: number) => {
    setAlreadyUploadedSign([]);
    setValue(config.key, "");
    dispatch(setTaskData({ nodeId: config.key, data: "" }));
  };

  const handleChange = updateLocalStateWhenOpenedAsSubfield;
  // ADD ONcHANGE for loading already created image in the array.
  useEffect(() => {
    const valueToBeSetToReactHookForm = val || config.value;
    // if (!isOpenedAsSubfield) onChange(config.key, config.value);
    setValue(config.key, [valueToBeSetToReactHookForm]);
  }, []);


  // const linksArrayBefore = getValues()[config.key]
  // linksArrayBefore?.forEach((link:string,index:number) => {
  //   linksArrayBefore[index] = {url:link, title:`Image ${index + 1}`}
  // })

  useEffect(() => {
    const curVal = val || config.value;
    //TODO: REMOVE THIS ONCE SIGNATURE RESPONSE IS FIXED FROM BFF
    //@ts-ignore
    if(curVal === [] || curVal === ""){
        setValue(config.key, "")
    } else{
        setValue(config.key, "")
        if(Array.isArray(curVal)){
            //@ts-ignore
            setAlreadyUploadedSign((prev) => [...prev, ...curVal])
            setValue(config.key, curVal[0])
        } else {
            //@ts-ignore
            setAlreadyUploadedSign((prev) => [...prev, curVal])
            setValue(config.key, curVal)
        }
    } 
   
  }, []);

  const linksArray = _.cloneDeep([val])
  linksArray?.forEach((val:string,index:number) => {
    linksArray[index] = {
      url: val,
      title: `Image ${index}`
    }})

  

  return (
    <ColSpan4>
      <HeadingContainer>
        <TaskFormLabelForShow
          fontSize={config.label?.fontSize}
          color={config?.label?.color}
          style={{ marginBottom: "0px" }}
        >
          {config?.label?.val}
        </TaskFormLabelForShow>
      </HeadingContainer>
      <div style={{ overflow: "auto" }}>
        <ZShowImage
          uploadComponent={
            <FileUploadV2
              config={config}
              onChange={handleChange}
              alreadyUploadedImages={alreadyUploadedSign}
              isOpenedAsSubfield={isOpenedAsSubfield}
              removeFiles={removeFiles}
            >
              <ZActionButton
                className="mt-1"
                // onClick={() => setShowUploadModal(true)}
              >
                + Add {config?.label?.val}
              </ZActionButton>
            </FileUploadV2>
          }
          deleteFunc={removeFiles}
          linksArray={linksArray || []}
        />
      </div>
      {errors[config.key]?.message && (
        <div className="font-semibold text-xs text-red-500 mt-1">
          <AiOutlineWarning
            size={"1rem"}
            color="red"
            style={{ display: "inline", marginRight: "4px" }}
          />{" "}
          {errors[config.key]?.message}
        </div>
      )}
      <Modal open={showUploadModal} onClose={() => setShowUploadModal(false)}>
        <div className="newwwwww">
          <FileUpload
            config={config}
            onChange={handleChange}
            alreadyUploadedImages={alreadyUploadedSign}
            isOpenedAsSubfield={isOpenedAsSubfield}
            removeFiles={removeFiles}
          />
        </div>
      </Modal>
      </ColSpan4>
  );
};
