import React from "react";
import TimeSlotSelection from "../../../components/TimeSlotSelection";
import { Modal,Paper } from '@mui/material';
import { styled } from '@mui/system';
import { ValidatorForm } from "react-material-ui-form-validator";
import { TaskService } from "../../../services/TaskService";
import notification from "../../../../../notifications/notifications";
import { recordRSEvent } from "utils/CommonUtils";
import { DialogActions, DialogTitle, Grid } from "@mui/material";
import {
    ZButton,
} from "views/commonComponents/commonComponents.styles";
import moment from 'moment';

function getModalStyle() {
    const top = 50;
    const left = 50;
    const preview = localStorage.getItem("isPreview") || false
    return {
        top: `${top}%`,
        left: `${left}%`,
        padding: '0px',
        transform: `translate(-${top}%, -${left}%)`,
        borderRadius: "8px",
        border: "none",
        width: preview ? "70%" : '30%',
    };
}

const PaperComp = styled(Paper)(({ theme }) => ({
    position: 'absolute',
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
}));

export const ModifyScheduleSlot = ({ taskId, scheduledFrom, scheduledTo, onClose, taskType }) => {
    const [modalStyle] = React.useState(getModalStyle);

    const [deliverySlot, setDeliverySlot] = React.useState({
        fromDateTime: scheduledFrom,
        toDateTime: scheduledTo,
    });


    const handleDeliverySlotChange = (slot) => {
        setDeliverySlot(slot)
    };

    const preview = localStorage.getItem("isPreview") || false

    const handleSubmit = (e) => {
        TaskService
            .updateScheduledSlot(taskId, moment(deliverySlot.fromDateTime), moment(deliverySlot.toDateTime))
            .then((res) => {
                if (res && res?.code !== "200") {
                    // Error
                    notification("error", res?.message);
                    recordRSEvent("changeslot_event_fail", {
                        context: "task_details",
                        taskId,
                        taskType
                    });
                } else if (res) {
                    recordRSEvent("changeslot_event_success", {
                        context: "task_details",
                        taskId,
                        taskType
                    });
                    if(!preview) {
                        window.location.reload(false);
                    }
                    
                    notification("success", res.message);
                    window.parent.postMessage(
                        {
                          source: "zorp_web_preview",
                          name:"schedule_task",
                          taskId
                        }, "*");
                    onClose();
                }
            })
            .catch((err) => {
                // notification("error", "Task Transition Failed");
                onClose();
                recordRSEvent("changeslot_event_fail", {
                    context: "task_details",
                    taskId,
                    taskType
                })
                console.error(err);
            });
    }

    return (
        <Modal
            open={true}
            onClose={() => onClose(true)}
            width={500}
            >
            <ValidatorForm
                onSubmit={(e) => handleSubmit(e)}
                onError={(errors) => console.log(errors)}>
                <PaperComp style={modalStyle}>
                    <DialogTitle className="dialog__title" id='alert-dialog-title'>Change Slot</DialogTitle>
                    <div style={{ marginLeft: '24px', marginRight: '24px' }} className="flex flex-col justify-start">
                        <div className="flex flex-col border rounded-xl">
                            <TimeSlotSelection
                                slots={deliverySlot}
                                setDeliverySlot={setDeliverySlot}
                                 allowPast={true}
                                />
                        </div>
                    </div>
                    <DialogActions>

                        <Grid style={{ marginRight: '14px', marginTop: '10px' }}>
                            <ZButton
                                secondary
                                variant="contained"
                                onClick={() => onClose(true)}
                                type="button"
                                className="mr-2">
                                Cancel
                            </ZButton>

                            <ZButton variant="contained" type="submit">
                                Change
                            </ZButton>
                        </Grid>
                    </DialogActions>
                </PaperComp>
            </ValidatorForm>

        </Modal>
    );
}