// @ts-nocheck
import classnames from "classnames";
import jwt_decode from "jwt-decode";
import lodashGet from "lodash/get";
import * as React from "react";
import { MdDashboard } from "react-icons/md";
import { TbNotes } from "react-icons/tb";
import MonitorSvg from "assests/monitor_ticket.svg";
import { useDispatch, useSelector } from "react-redux";
import { hasPermission } from "../../providers/auth/authUtils";
import MenuItemLink from "./MenuItemLink";
import useZMenu from "./reducer/useMenu";
import _ from "lodash";
import useAccount from "./reducer/useAccount";
import ZLogo from "assests/zorplogo.svg";
import ZLogoSmall from "./Logo.svg";
import useAccessPermissions from "hooks/useAccessPermissions";
import { ResourceTypeConstant } from "views/entities/permissions/types";
import { useSidebarState } from "react-admin";
import { IEmbedLink, IEmbedLinkType } from "./reducer/menuSlice";
import useEntity from "views/entities/hooks/useEntity";
import { FaTicketAlt } from "react-icons/fa";
import { ITable } from "views/entities/types";
import { MdMap } from "react-icons/md";
import CreateEntityModal from "views/entities/modals/CreateEntityModal";
import { useMediaQuery, useTheme } from "@mui/material";
import ZTableIcon from "assests/icons/ZTableIcon";
import { ZHeader } from "views/commonComponents/commonComponents.styles";
import ZHomeIcon from "assests/icons/ZHome";
import ZChronos from "assests/icons/ZChronos";
import ZWorkflows from "assests/icons/ZWorkflows";
import ZTaskIcon from "assests/icons/ZTaskIcon";
import ZAlertIcon from "assests/icons/ZAlertIcon";
import ZMonitorIcon from "assests/icons/ZMonitorIcon";
import ZReportIcon from "assests/icons/ZReportIcon";
import ZFeedbackIcon from "assests/icons/ZFeedbackIcon";
import ZAppIcon from "assests/icons/ZAppIcon";
import ZDocIcon from "assests/icons/ZDocIcon";
import ZSettingsIcon from "assests/icons/ZSettingsIcon";
import ZLogOutIcon from "assests/icons/ZLogOutIcon";
import ZProjects from "assests/icons/ZProjects";
import ZMap from "assests/icons/ZMap";
import ZScheduler from "assests/icons/ZScheduler";

//New ICONS FOR CMM
import ZWorkOrder from "assests/icons/ZWorkOrder";
import ZInventory from "assests/icons/ZInventory";
import ZLocations from "assests/icons/ZLocations";
import ZAssets from "assests/icons/ZAssets";
import ZPurchaseOrder from "assests/icons/ZPurchaseOrder";
import ZChecklists from "assests/icons/ZCheckLists";
import ZPreventiveMaintenance from "assests/icons/ZPreventiveMaintenance";
import ZNewReports from "assests/icons/ZNewReports";
import ZTickets from "assests/icons/ZTickets";

export const MENU_WIDTH = 250;
export const CLOSED_MENU_WIDTH = 80;

function getStyles({theme, props}:{theme:any,props:any}) {
  return {
    main: {
      display: "flex",
      paddingTop: "16px",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "0em !important",
      height: "100% !important",
      overflow: "auto",
      [theme.breakpoints.only("xs")]: {
        marginTop: 0,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "1.5em",
      },
      background: "#F2F4F7", // SidebarBG
      // background: (props) => props.zTheme.color.primary,
      // height:"100% !important"
    },
    borderMenu: {
      borderRight: "0.1rem solid rgba(0, 0, 0, 0.04)!important",
    },
    open: {
      width: (props) =>
        lodashGet(
          theme,
          "menu.width",
          props.isMobile ? CLOSED_MENU_WIDTH : MENU_WIDTH
        ),
    },
    closed: {
      width: lodashGet(theme, "menu.closedWidth", CLOSED_MENU_WIDTH),
    },
  };
}



const Menu = (props) => {
  const [links, setLinks] = React.useState<IEmbedLink[]>([]);
  const [supportLinks, setSupportLinks] = React.useState<IEmbedLink[]>([]);
  const { embedLinks } = useZMenu();
  const [preview, setPreview] = React.useState(false);
  const { whitelabelling, account } = useAccount();
  const {
    allEntities,
    showCreateEntityModal,
    showEditEntityModal,
  } = useEntity();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const accessPermissions = useAccessPermissions({
    resourceType: ResourceTypeConstant.TABLE,
  });

  const toggleSidebar = () => {
    dispatch(setSidebarVisibility(!open));
  };

  const ticketRelatedEmbeddedLinks = [
    {
      to: "/monitor",
      key: "Manage Alerts",
      menuId: "/monitor",
      isSupportType: false,
      type: "GENERIC",
      isEmbed: true,
      icon: <ZAlertIcon size={16} color="currentColor" />,
      embedUrl: "/monitor",
      isInternal: true,
      data: { type: "TICKET_TABLE", label: "Tickets" },
    },
  ].concat(
    embedLinks?.filter(
      (embedData: any) =>
        embedData?.key === "Tickets" || embedData?.key === "Ticket"
    )
  );

  const reportsRelatedEmbeddedLinks = embedLinks?.filter(
    (embedData: any) =>
      embedData?.key != "Tickets" &&
      embedData?.key != "Ticket" &&
      embedData?.key != "Table" &&
      embedData?.type != "DROPDOWN"
  );
  // console.log(
  //   "ticketRelatedEmbeddedLinks",
  //   JSON.stringify(ticketRelatedEmbeddedLinks)
  // );
  const { className, dense, hasDashboard, onMenuClick, logout, ...rest } =
    props;

  const dropdownItems = embedLinks?.filter(
    (embedData: any) => embedData?.type === IEmbedLinkType.DROPDOWN
  );

  const classes = getStyles({theme, props:{...props}});
  const [open, setOpen] = useSidebarState();

  function checkAccountPermissions(checkAndOr: "AND" | "OR", decodedToken: any, ...args: string[]) {
    if(checkAndOr === "AND") {
      let allPermissions = true
      for(let i=0;i<args.length;i++) {
        if((decodedToken?.accountId !== args[i] && allPermissions) === false) {
          return false;
        }
      }  
      return true;
  } else {
    for(let i=0;i<args.length;i++) {
      let allPermissions = false
      if((decodedToken?.accountId === args[i] || allPermissions) === true) {
        return true;
      }
    }
    return false;
  }
}

  React.useEffect(() => {
    if (localStorage.getItem("at")) {
      let menuItems: IEmbedLink[] = [];
      const supportLinkItems: IEmbedLink[] = [];

      /**
       * For non-self serve customers, show the scheduler and map screens. For self serve customers, don't
       **/
      const decodedToken = jwt_decode(localStorage.getItem("at"));

      const scheduleMenuAccountList = ["zorp", "1"];
      const mapMenuAccountList = ["zorp", "1", "rescue", "w5xp6b", "ciessd", "vf5jkz"];
      const reportsMenuAccountList = [
        "zorp",
        "1",
        "porter",
        "rescue",
        "furritas",
        "w5xp6b",
        "dnz6w4",
      ];
      const pmMenuAccountList = ["zorp", "1", "qcya8w"];
      const customReports = ["uiovjs"];
      if (whitelabelling?.isWhitelabelled === false) {
        menuItems.push({
          to: "/home",
          key: "Home",
          icon: <ZHomeIcon size={16} color="currentColor" />,
          menuId: "homeMenu",
          isSupportType: false,
          isEmbed: false,
          isInternal: true,
          embedUrl: "",
          type: IEmbedLinkType.GENERIC,
        });
      }

      // if (hasPermission("alert", "list") || hasPermission("alert", "view"))
      //   menuItems.push({
      //     to: "/alerts",
      //     key: "Alerts",
      //     icon: bellIcon,
      //     menuId: "alertsMenu",
      //     isSupportType: false,
      //     isEmbed: false,
      //     isInternal: true,
      //     embedUrl: "",
      //     type: IEmbedLinkType.GENERIC,
      //   });

      if (hasPermission("reports", "read") && checkAccountPermissions("AND", decodedToken, "jctp1d", "b70k9d", "gu4mx1")) {
        menuItems.push({
          to: "/chronos",
          key: "Chronos",
          icon: <ZChronos size={16} color="currentColor" />,
          menuId: "chronosMenu",
          isSupportType: false,
          isEmbed: false,
          isInternal: true,
          embedUrl: "",
          type: IEmbedLinkType.GENERIC,
        });
      }

      if (hasPermission("workflow", "view") && checkAccountPermissions("AND", decodedToken, "jctp1d", "b70k9d", "gu4mx1")) {
        if (decodedToken?.accountId === "e1s2v8") {
          if (decodedToken.role === "admin")
            menuItems.push({
              to: "/workflows",
              key: "Workflows",
              icon: <ZWorkflows size={16} color="currentColor" />,
              menuId: "settingsMenu",
              isSupportType: false,
              isEmbed: false,
              isInternal: true,
              embedUrl: "",
              type: IEmbedLinkType.GENERIC,
            });
        } else {
          menuItems.push({
            to: "/workflows",
            key: "Workflows",
            icon: <ZWorkflows color="currentColor" />,
            menuId: "settingsMenu",
            isSupportType: false,
            isEmbed: false,
            isInternal: true,
            embedUrl: "",
            type: IEmbedLinkType.GENERIC,
          });
        }
      }

      if (hasPermission("task", "list") || hasPermission("task", "view"))
        menuItems.push({
          to: "/tasks",
          key: `${(checkAccountPermissions("OR", decodedToken, "jctp1d", "b70k9d")) ? 'Workorder Tasks' : decodedToken?.accountId === "gu4mx1" ? 'Assessment Tasks' : 'Tasks'}`,
          icon: <ZTaskIcon color="currentColor" />,
          menuId: "tasksMenu",
          isSupportType: false,
          isEmbed: false,
          isInternal: true,
          embedUrl: "",
          type: IEmbedLinkType.GENERIC,
        });

      if (
        (hasPermission("task", "transit") || hasPermission("task", "edit")) &&
        scheduleMenuAccountList.includes(decodedToken?.accountId)
      )
        menuItems.push({
          to: "/schedule",
          key: "Scheduler",
          icon: <ZScheduler color="currentColor" />,
          menuId: "schedulerMenu",
          isSupportType: false,
          isEmbed: false,
          isInternal: true,
          embedUrl: "",
          type: IEmbedLinkType.GENERIC,
        });

      if (
        (hasPermission("task", "transit") || hasPermission("task", "edit")) &&
        mapMenuAccountList.includes(decodedToken?.accountId)
      )
        menuItems.push({
          to: "/map",
          key: "Map",
          icon: <ZMap color="currentColor" />,
          menuId: "mapMenu",
          isSupportType: false,
          isEmbed: false,
          isInternal: true,
          embedUrl: "",
          type: IEmbedLinkType.GENERIC,
        });

      if (pmMenuAccountList.includes(decodedToken?.accountId))
        menuItems.push({
          to: "/holder",
          key: "Projects",
          icon: <ZProjects color="currentColor" />,
          menuId: "mapMenu",
          isSupportType: false,
          isEmbed: false,
          isInternal: true,
          embedUrl: "",
          type: IEmbedLinkType.GENERIC,
        });

      if (customReports.includes(decodedToken?.accountId)) {
        menuItems.push({
          to: "/customreport",
          key: "Trips",
          icon: <ZProjects color="currentColor" />,
          menuId: "mapMenu",
          isSupportType: false,
          isEmbed: false,
          isInternal: true,
          embedUrl: "",
          type: IEmbedLinkType.GENERIC,
        });
      }

      //NEW CMM ACCOUNTS
      const accountConfig = {
        jctp1d: {
          workOrder: { tableType: 'vyo8gh', viewType: 'qrea8b' },
          preventiveMaintenance: { tableType: 'cjqi3p', viewType: 'w0y0u7' },
          checklists: { tableType: 'v6t9x1', viewType: 'swwzf3' },
          questions: { tableType: 'dt46ml', viewType: 'h7kjd4' },
          assets: { tableType: 'ome94j', viewType: 'uyine5' },
          inventory: { tableType: 'y0l4hb', viewType: 'bobfzk' },
          vendors: { tableType: 'k84e2z', viewType: 's5x9vz' },
          purchaseOrder: { tableType: 'xrlcc5', viewType: 'fuf5ur' },
          locations: { tableType: 'ja9e6i', viewType: 'juwl8v' },
          tickets: { tableType: 'sh96dj', viewType: 'dbk2lx' },
        },
        b70k9d: {
          workOrder: { tableType: 'wkkjh3', viewType: 'w0ttdp' },
          preventiveMaintenance: { tableType: 'gw0is0', viewType: 'ibvcyn' },
          checklists: { tableType: 'w4zehs', viewType: 'wttos5' },
          questions: { tableType: 'z4clw8', viewType: 'y6xy46' },
          assets: { tableType: 'wuncvx', viewType: 'em4umr' },
          inventory: { tableType: 'ycb3ka', viewType: 'g7irgn' },
          vendors: { tableType: 'kxw2jw', viewType: 'q52zgq' },
          purchaseOrder: { tableType: 'nfqxeg', viewType: 'yjqkyy' },
          locations: { tableType: 'q5i696', viewType: 'f286ww' },
          tickets: { tableType: 'ssgfbx', viewType: 'm2wn8q' },
        },
        gu4mx1: {
          workOrderAssessment: { tableType: 'krjpvp', viewType: 'xma89c' },
          preventiveMaintenanceScheduling: { tableType: 'tyzqy2', viewType: 'ln7w2x' },
          assessmentchecklists: { tableType: 'dtnnn1', viewType: 'benhze' },
          // questions: { tableType: 'o9u3yi', viewType: 'tlpv8n' },
          // assets: { tableType: 'braw36', viewType: 'zu12pf' },
          // inventory: { tableType: 'ak0tq7', viewType: 'p88i6k' },
          // vendors: { tableType: 'j5t82k', viewType: 'wnf0yo' },
          // purchaseOrder: { tableType: 'jod6l2', viewType: 'tax2el' },
          // locations: { tableType: 'pr5isz', viewType: 'iij3n2' },
          // tickets: { tableType: 'rd8hry', viewType: 'kqul43' },
          resources: { tableType: 'now4b5', viewType: 'qwbco4' },
        }
        
      };


      const accountId = decodedToken?.accountId;
     
      if (accountConfig[accountId]) {
        const accountTables = accountConfig[accountId];

        const menuItemsConfig = [
          { key: "Work Orders", icon: <ZWorkOrder color="currentColor" />, type: 'workOrder' },
          { key: "Preventive Maintenance", icon: <ZPreventiveMaintenance color="currentColor" />, type: 'preventiveMaintenance' },
          { key: "Workflow Tasks Checklist", icon: <ZChecklists color="currentColor" />, type: 'checklists' },
          { key: "Questions", icon: <ZTaskIcon color="currentColor" />, type: 'questions' },
          { key: "Asset Management", icon: <ZAssets color="currentColor" />, type: 'assets' },
          { key: "Inventory", icon: <ZInventory color="currentColor" />, type: 'inventory' },
          { key: "Vendors", icon: <ZTableIcon color="currentColor" />, type: 'vendors' },
          { key: "Purchase Order", icon: <ZPurchaseOrder color="currentColor" />, type: 'purchaseOrder' },
          { key: "Locations", icon: <ZLocations color="currentColor" />, type: 'locations' },
          { key: "Tickets", icon: <ZTickets color="currentColor" />, type: 'tickets' },
          { key: "Assessments", icon: <ZWorkOrder color="currentColor" />, type: 'workOrderAssessment'},
          { key: "Scheduling", icon: <ZPreventiveMaintenance color="currentColor" />, type: 'preventiveMaintenanceScheduling'},
          { key: "Checklist", icon: <ZChecklists color="currentColor" />, type: 'assessmentchecklists' },
          { key: "Resources", icon: <ZTickets color="currentColor" />, type: 'resources'},
        ];

        menuItemsConfig.forEach(item => {
          const tableType = accountTables[item.type]?.tableType;
          const viewType = accountTables[item.type]?.viewType;

          if (tableType && viewType) {
            menuItems.push({
              to: `/table/${tableType}/view/${viewType}`,
              key: item.key,
              icon: item.icon,
              menuId: `${item.type}Menu`,
              isSupportType: false,
              isEmbed: false,
              isInternal: true,
              embedUrl: "",
              type: IEmbedLinkType.GENERIC,
              data: {
                tableType,
                tableDisplayName: item.key,
                defaultViewId: viewType,
              },
            });
          }
        });
      }
    
      dropdownItems?.forEach((item: any) => {
        menuItems.push({
          to: item?.to,
          key: item?.key,
          icon: item?.icon,
          menuId: item?.menuId,
          isSupportType: item?.isSupportType || false,
          isEmbed: item?.isEmbed || false,
          isInternal: item?.isInternal || true,
          embedUrl: item?.embedUrl || "",
          type: IEmbedLinkType.DROPDOWN,
          allowCreate: false,
          dropdownItems: item?.links?.map((link: any) => {
            return {
              to: link?.to,
              key: link?.key,
              icon: link?.icon,
              menuId: link?.menuId,
              isSupportType: link?.isSupportType || false,
              isEmbed: link?.isEmbed || false,
              isInternal: link?.isInternal || true,
              embedUrl: link?.embedUrl || "",
              type: link?.type,
              data: link?.data || {},
            };
          }),
          data: item?.data,
        });
      });
      // menuItems.push({
      //   to: "/table",
      //   key: "Tables",
      //   icon: <ZTableIcon size={16} />,
      //   menuId: "entityMenu",
      //   isSupportType: false,
      //   isEmbed: false,
      //   isInternal: true,
      //   embedUrl: "",
      //   type: IEmbedLinkType.DROPDOWN,
      //   dropdownItems: (allEntities || []).map((entity: ITable) => {
      //     return {
      //       to: `/table/${entity.tableType}`,
      //       key: entity.tableDisplayName,
      //       icon: <ZTableIcon size={16} color="currentColor" />,
      //       menuId: "entityMenu",
      //       isSupportType: false,
      //       isEmbed: true,
      //       isInternal: true,
      //       embedUrl: "",
      //       type: IEmbedLinkType.GENERIC,
      //       data: {
      //         tableType: entity.tableType,
      //         tableDisplayName: entity.tableDisplayName,
      //       },
      //     };
      //   }),
      // });
      /**
       * !(
          decodedToken?.accountId === "rah170" &&
          decodedToken?.role !== "admin"
        ) || !(
          decodedToken?.accountId === "fafdw1" &&
          decodedToken?.role !== "admin"
        )
       */
      if (
        decodedToken?.accountId === "rah170" &&
          decodedToken?.role !== "admin"
      ) {

      } else if ( decodedToken?.accountId === "fafdw1" &&
      decodedToken?.role !== "admin"){

      } else if(
        decodedToken?.accountId === "sed4qz" &&
        decodedToken?.role !== "admin"
      ) {

      } 
      else if (
        decodedToken?.accountId === "jctp1d"
      ){ 

      }
      else if (decodedToken?.accountId === "b70k9d"

){

      }
      else if (
        decodedToken?.accountId === "gu4mx1"
      ){

      }
      else {
        menuItems.push({
          to: "/table",
          key: "Tables",
          icon: <ZTableIcon size={16} />,
          menuId: "entityMenu",
          isSupportType: false,
          isEmbed: false,
          isInternal: true,
          embedUrl: "",
          type: IEmbedLinkType.DROPDOWN,
          dropdownItems: (allEntities || []).map((entity: ITable) => {
            return {
              to: entity?.defaultView ? `/table/${entity.tableType}/view/${entity?.defaultView}`: `/table/${entity.tableType}`,
              key: entity.tableDisplayName,
              icon: <ZTableIcon size={16} color="currentColor" />,
              menuId: "entityMenu",
              isSupportType: false,
              isEmbed: true,
              isInternal: true,
              embedUrl: "",
              type: IEmbedLinkType.GENERIC,
              data: {
                tableType: entity.tableType,
                tableDisplayName: entity.tableDisplayName,
                defaultViewId: entity?.defaultView || undefined,
              },
          };
          }),
        });
      }
      

      // Item for reports with dropdown
      menuItems.push({
        to: "/monitor",
        key: "Alerts",
        icon: <ZMonitorIcon size={16} color="currentColor" />,
        menuId: "alerts",
        isSupportType: false,
        isEmbed: false,
        isInternal: true,
        embedUrl: "",
        type: IEmbedLinkType.DROPDOWN,
        dropdownItems: ticketRelatedEmbeddedLinks,
      });

      if (hasPermission("reports", "read"))
        menuItems.push({
          to: "/reports",
          key: "Reports",
          icon: <ZReportIcon size={16} color="currentColor" />,
          menuId: "reports",
          isSupportType: false,
          isEmbed: false,
          isInternal: true,
          embedUrl: "",
          type: IEmbedLinkType.DROPDOWN,
          dropdownItems: reportsRelatedEmbeddedLinks,
        });
      
        if (hasPermission("workflow", "view") && decodedToken?.accountId === "jctp1d") {
          if (decodedToken?.accountId === "e1s2v8") {
            if (decodedToken.role === "admin")
              menuItems.push({
                to: "/workflows",
                key: "Workflows",
                icon: <ZWorkflows size={16} color="currentColor" />,
                menuId: "settingsMenu",
                isSupportType: false,
                isEmbed: false,
                isInternal: true,
                embedUrl: "",
                type: IEmbedLinkType.GENERIC,
              });
          } else {
            menuItems.push({
              to: "/workflows",
              key: "Workflows",
              icon: <ZWorkflows color="currentColor" />,
              menuId: "settingsMenu",
              isSupportType: false,
              isEmbed: false,
              isInternal: true,
              embedUrl: "",
              type: IEmbedLinkType.GENERIC,
            });
          }
        }

      // if (
      //   (hasPermission('reports', 'read') || hasPermission('reports', 'view')) &&
      //   reportsMenuAccountList.includes(decodedToken?.accountId) &&
      //   !!dashboardUrl?.length
      // )
      // menuItems.push(['/reports', 'Reports', <AssessmentIcon size={'2.2rem'} />, 'reportsMenu', false]);

      // add all embedLinks to menuItems
      // if (embedLinks.length > 0) {
      //   menuItems = _.concat(menuItems, embedLinks);
      // }

      if (decodedToken?.accountId !== "xau5km" && decodedToken?.accountId !== "jctp1d") {
        supportLinkItems.push({
          to: "https://www.zorp.one/app-download",
          key: "Feedback",
          icon: <ZFeedbackIcon size={16} color="currentColor" />,
          menuId: "feedbackMenu",
          isSupportType: true,
          isEmbed: false,
          isInternal: false,
          embedUrl: "",
          type: IEmbedLinkType.GENERIC,
        });
      }

      if (
        whitelabelling?.isWhitelabelled === false &&
        decodedToken?.accountId !== "xau5km"
        && decodedToken?.accountId !== "jctp1d"
      ) {
        supportLinkItems.push({
          to: "https://www.zorp.one/app-download",
          key: "App",
          icon: <ZAppIcon size={16} color="currentColor" />,
          menuId: "appMenu",
          isSupportType: true,
          isEmbed: false,
          isInternal: false,
          embedUrl: "",
          type: IEmbedLinkType.GENERIC,
        });
        supportLinkItems.push({
          to: "https://docs.zorp.one/",
          key: "Docs",
          icon: <ZDocIcon size={16} color="currentColor" />,
          menuId: "docsMenu",
          isSupportType: true,
          isEmbed: false,
          isInternal: false,
          embedUrl: "",
          type: IEmbedLinkType.GENERIC,
        });
      }

      supportLinkItems.push({
        to: "/settings/general",
        key: "Settings",
        icon: <ZSettingsIcon size={16} color="currentColor" />,
        menuId: "settingsMenuAndLogout",
        isSupportType: false,
        isEmbed: false,
        isInternal: true,
        embedUrl: "",
        type: IEmbedLinkType.GENERIC,
      });

      supportLinkItems.push({
        to: "/logout",
        key: "Logout",
        icon: <ZLogOutIcon size={16} color="currentColor" />,
        menuId: "logoutIcon",
        isSupportType: false,
        isEmbed: false,
        isInternal: false,
        embedUrl: "",
        type: IEmbedLinkType.GENERIC,
      });

      setLinks([...menuItems]);

      setSupportLinks(supportLinkItems);
    }
  }, [props, embedLinks, allEntities]);

  React.useEffect(() => {
    // collect preview from url
    // http://localhost:3000/#/tasks/${taskId}/show/?preview=true
    const preview = localStorage.getItem("isPreview");
    setPreview(preview);
    if (preview) {
      const ele = document.querySelectorAll(".MuiDrawer-docked");
      ele.forEach((element) => {
        element.style.display = "none";
      });
    }
  }, []);

  return preview === true ? (
    <></>
  ) : (
    <div
      // className={classnames(
      //   classes.main,
      //   classes.borderMenu,
      //   {
      //     [classes.open]: open,
      //     [classes.closed]: !open,
      //   },
      //   className
      // )}
      style={{
        ...classes.main,
        ...classes.borderMenu,
        ...(open ? classes.open : classes.closed),
        height:"100%"
      }}
      {...rest}
    >
      <div>
        <div
          style={{
            padding: "0px 16px",
            marginBottom: "12px",
            paddingLeft: "16px",
          }}
          // onClick={toggleSidebar}
        >
          {isMobile ? (
            <img
              src={account?.accountConfig?.logo || ZLogoSmall}
              alt="Zorp"
              style={{ width: "100%", height: "auto" }}
            />
          ) : open ? (
            <img
              src={account?.accountConfig?.logo || ZLogoSmall}
              alt="Zorp"
              style={{ width: "auto", maxHeight: "35px" }}
            />
          ) : (
            <img
              src={account?.accountConfig?.logo || ZLogoSmall}
              alt="Zorp"
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </div>
        <div
          style={{
            padding: "0px 12px",
          }}
          className="test"
        >
          {links.map((link: IEmbedLink) => {
            return (
              <MenuItemLink
                {...props}
                key={link.key}
                to={link.to}
                icon={link.icon}
                text={link.key}
                onClick={onMenuClick}
                sidebarIsOpen={true}
                menuId={link.menuId}
                isSupportType={link.isSupportType}
                isEmbed={link.isEmbed}
                embedUrl={link.embedUrl}
                isInternal={link.isInternal}
                type={link.type}
                dropdownItems={link?.dropdownItems || []}
                classes={classes}
                allowCreate={link.allowCreate}
                data = {link.data}
              />
            );
          })}
        </div>
      </div>
      <div
        style={{
          padding: "0px 12px 24px 12px",
        }}
        className="test2"
      >
        {supportLinks.map((link) => {
          return (
            <MenuItemLink
              {...props}
              key={link.key}
              to={link.to}
              icon={link.icon}
              text={link.key}
              onClick={link.menuId === "logoutIcon" ? () => {} : onMenuClick}
              sidebarIsOpen={true}
              menuId={link.menuId}
              isSupportType={link.isSupportType}
              isEmbed={link.isEmbed}
              embedUrl={link.embedUrl}
              isInternal={link.isInternal}
              type={link.type}
              dropdownItems={link?.dropdownItems || []}
              classes={classes}
            />
          );
        })}
      </div>
      {showCreateEntityModal && <CreateEntityModal />}
      {showEditEntityModal && <CreateEntityModal />}
    </div>
  );
};

// export default Menu;
export default React.memo(Menu);
