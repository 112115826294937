//@ts-nocheck
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import { Box } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  communicateToUserOnOpeningWorkflowFromPhone,
  getDeviceType,
  getTemplateIdFromNewWorkflow,
  recordRSEvent,
} from "utils/CommonUtils";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import {
  BookASessionWithZorpExpert,
  CreateFromScratch,
  SuggestATemplate,
  ViewVideoTutorials,
} from "views/workflow-templates/constants/WTConstants";
import { WTLHSidebarCOM } from "../../workflow-template.styles";
import WTTemplatesTree from "./WTTemplatesTree";
import { ZorpResponse } from "providers/data/models/ZorpResponse";
import { TaskFlowService } from "providers/data/services/TaskFlowService";
import { FcGoogle } from "react-icons/fc";
import { BsStars } from "react-icons/bs";
import GoogleFormModal from "views/workflows/automateWorkflow/Gforms/GoogleFormModal";
import { useState } from "react";
import { AiOutlineRobot } from "react-icons/ai";
import ZorpGPT from "views/workflows/automateWorkflow/ZorpGPT";

const WTLHSidebar = () => {
  const navigate = useNavigate();
  const [showGoogleModal, setShowGoogleModal] = useState(false);
  const [showGPTModal, setShowGPTModal] = useState(false);

  const handleVideoChange = () => {
    recordRSEvent(ViewVideoTutorials.name, ViewVideoTutorials);
    window.open(
      "https://www.youtube.com/channel/UCNDY7jiOh7uOJWWRDnLE9Vw/videos",
      "_blank"
    );
  };

  const handleSuggestTemplate = () => {
    recordRSEvent(SuggestATemplate.name, SuggestATemplate);
    window.open("https://forms.gle/zZxaKxAPKU7H164A7", "_blank");
  };

  const handleClick = async () => {
    try {
      const templateId = getTemplateIdFromNewWorkflow();
      const data: any = await TaskFlowService.publishTemplateAsWorkflow(
        templateId
      );
      recordRSEvent(CreateFromScratch.name, CreateFromScratch);
      communicateToUserOnOpeningWorkflowFromPhone();
      navigate(`/workflows/${data.data}/show`);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <WTLHSidebarCOM display="flex" justifyContent="space-between">
      <Box p={2}>
        <ZButton
          variant="contained"
          special
          fullWidth
          className="mb-2"
          startIcon={
            <FcGoogle
              size={"1.2rem"}
              style={{ background: "none" }}
              className="svg_icons_l"
            />
          }
          endIcon={
            <BsStars
              size={"1rem"}
              style={{ background: "none" }}
              className="svg_icons_l"
            />
          }
          onClick={() => setShowGoogleModal(true)}
        >
          Google Forms to App
        </ZButton>
        <ZButton
          variant="contained"
          className="mb-2"
          fullWidth
          startIcon={
            <AddOutlinedIcon
              className="svg_icons_l"
              style={{
                backgroundColor: "transparent",
                color: "#fff",
                marginRight: 0,
              }}
            />
          }
          onClick={handleClick}
        >
          Create from Scratch
        </ZButton>
        <ZButton
          fullWidth
          variant="contained"
          className="mb-2"
          special
          startIcon={
            <AiOutlineRobot
              style={{ background: "transparent" }}
              className="svg_icons_l"
            />
          }
          onClick={() => setShowGPTModal(true)}
        >
          Zorp GPT
        </ZButton>
        <ZButton
          variant="contained"
          secondary
          className="mb-2"
          fullWidth
          startIcon={
            <HelpOutlineOutlinedIcon
              className="svg_icons_l"
              style={{
                backgroundColor: "transparent",
                color: "#616569",
                marginRight: 0,
              }}
            />
          }
          onClick={() => {
            recordRSEvent(
              BookASessionWithZorpExpert.name,
              BookASessionWithZorpExpert
            );
            window.open("https://www.zorp.one/book-a-free-demo", "_blank");
          }}
        >
          Book a Session with a Zorp Expert
        </ZButton>

        <hr />
      </Box>

      <WTTemplatesTree />

      <Box p={2} mb={5}>
        <hr style={{ marginBottom: "3rem" }} />
        <ZButton
          variant="contained"
          secondary
          className="mb-2"
          fullWidth
          startIcon={
            <OndemandVideoOutlinedIcon
              className="svg_icons_l"
              style={{ backgroundColor: "transparent", color: "#616569" }}
            />
          }
          onClick={handleVideoChange}
        >
          View Video Tutorials
        </ZButton>
        <ZButton
          variant="contained"
          secondary
          className="mb-2"
          fullWidth
          startIcon={
            <MailOutlinedIcon
              className="svg_icons_l"
              style={{ backgroundColor: "transparent", color: "#616569" }}
            />
          }
          onClick={handleSuggestTemplate}
        >
          Suggest a Template
        </ZButton>
      </Box>

      {showGoogleModal && (
        <GoogleFormModal
          handleClose={() => setShowGoogleModal(false)}
          openModal={showGoogleModal}
        />
      )}

      {showGPTModal && (
        <ZorpGPT
          handleClose={() => setShowGPTModal(false)}
          openModal={showGPTModal}
        />
      )}
    </WTLHSidebarCOM>
  );
};

export default WTLHSidebar;
