// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
    position: relative;
    height : 100%;
    width: 100%;
}
  
.map {
    z-index: 0;
    height : 700px;
    width: 100%;
}
  
.filter-overlay {
    position: absolute;
    top: 60px;
    right: 40px;
    z-index: 1;
    background-color: white;
    padding: 3px;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.refresh-overlay {
    position: absolute;
    top: 70px;
    right: 250px;
    z-index: 1;
    background-color: white;
    padding: 1px;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.userPositionsTableClass {
    border: 1.5px solid grey;
    padding: 8px;
  }
  .divClass {
    height: 300px;
    width: 500px;
    overflow: scroll;
  }`, "",{"version":3,"sources":["webpack://./src/views/zmap/zmap.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,UAAU;IACV,cAAc;IACd,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,UAAU;IACV,uBAAuB;IACvB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,UAAU;IACV,uBAAuB;IACvB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,YAAY;EACd;EACA;IACE,aAAa;IACb,YAAY;IACZ,gBAAgB;EAClB","sourcesContent":[".map-container {\n    position: relative;\n    height : 100%;\n    width: 100%;\n}\n  \n.map {\n    z-index: 0;\n    height : 700px;\n    width: 100%;\n}\n  \n.filter-overlay {\n    position: absolute;\n    top: 60px;\n    right: 40px;\n    z-index: 1;\n    background-color: white;\n    padding: 3px;\n    border: 1px solid #ccc;\n    border-radius: 2px;\n}\n\n.refresh-overlay {\n    position: absolute;\n    top: 70px;\n    right: 250px;\n    z-index: 1;\n    background-color: white;\n    padding: 1px;\n    border: 1px solid #ccc;\n    border-radius: 2px;\n}\n\n.userPositionsTableClass {\n    border: 1.5px solid grey;\n    padding: 8px;\n  }\n  .divClass {\n    height: 300px;\n    width: 500px;\n    overflow: scroll;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
