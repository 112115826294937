//@ts-nocheck
import ZSelect from "views/commonComponents/ZSelect";
import { Label, Input } from "../DataField.styles";
import ErrorMessage from "../ErrorMessage";
import { Control, UseFormRegister } from "react-hook-form";
import ZInput from "views/commonComponents/ZTextInput";
import { closeAutomation } from "views/automationBuilder/reducer/automationReducer";
import { ABMetadataTypeEnum } from "views/automationBuilder/types/ABType";
import { saveHandlerForButton } from "views/entities/entityAutomation/saveHandler";
import ConfigureAutomation from "views/workflows/ConfigureAutomation";
import { useDispatch, useStore } from "react-redux";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { useState } from "react";
import useEntity from "views/entities/hooks/useEntity";
import { IButtonActionType } from "views/entities/types";
import { TEXT } from "constants/CommonConstants";
import { useJSEditor } from "lowcode/hooks/useJSEditor";
import { initMockData } from "lowcode/state/scriptEditorState";
import { setScriptEditorPayload } from "lowcode/state/scriptOpenState";
import { EditorScriptLocation } from "lowcode/state/types/types";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import {
  getLowCodeEditorText,
  getLowCodeEditorDefaultReturnText,
} from "utils/Utils";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import usePayload from "views/automationBuilder/hooks/usePayload";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import { DataFieldType } from "../DataField.types";

type NumberTypeProp = {
  control: Control<any>;
  register: UseFormRegister<any>;
  errors: any;
  defaultAction?: string;
  defaultButton?: string;
};

const ButtonAutomation = ({
  control,
  register,
  errors,
  defaultAction = IButtonActionType.RUN_AUTOMATION,
  defaultButton = "primaryOutlined",
}: NumberTypeProp) => {
  const dispatch = useDispatch();
  const { clearRightSide } = useAutomation();
  const { activeEditColumn, getScriptValueForEntity, selectedEntity } = useEntity();

  const [openAB, setOpenAB] = useState(false);
  const store = useStore();
  const { actionConditionPayload } = usePayload();

  const editorConfig = {
    script:
    getScriptValueForEntity(EditorScriptLocation.BUTTON_AUTOMATION_URL) ||
      getLowCodeEditorText(
        EditorScriptLocation.PUSH_NOTIFICATION_MESSAGE,
        DataFieldType.TEXT,
        `return ${getLowCodeEditorDefaultReturnText(DataFieldType.TEXT)}`
      ),
    dataType: TEXT,
  };

  const { openJSEditorLayout, scriptEditorOpen, dataType } = useJSEditor();

  return (
    <>
      <Label style={{ marginBottom: "4px" }}>Button Label </Label>
      <ZInput
        register={register}
        name="buttonLabel"
        validations={{
          required: "Button Label is required",
        }}
      />
      <ErrorMessage errors={errors} fieldName={"buttonLabel"} />

      <Label style={{ marginBottom: "8px", marginTop: "12px" }}>Action</Label>
      <ZSelect
        control={control}
        name="action"
        defaultValue={defaultAction}
        options={[
          { label: "Open URL", value: IButtonActionType.OPEN_URL },
          { label: "Run Automations", value: IButtonActionType.RUN_AUTOMATION },
        ]}
      />
      <ErrorMessage errors={errors} fieldName={"action"} />

      <Label style={{ marginBottom: "8px", marginTop: "12px" }}>
        Button Style
      </Label>
      <ZSelect
        control={control}
        name="button"
        defaultValue={defaultButton}
        options={[
          { label: "Primary Outlined", value: "primaryOutlined" },
          { label: "Primary Contained ", value: "primarySecondary" },
          { label: "Secondary Outlined", value: "secondaryOutlined" },
          { label: "Secondary Contained", value: "secondaryContained" },
        ]}
      />

      <ErrorMessage errors={errors} fieldName={"button"} />

      {activeEditColumn?.name &&
        activeEditColumn?.action?.value ===
          IButtonActionType.RUN_AUTOMATION && (
          <ZButton
            secondary
            fullWidth
            variant="contained"
            className="mt-2"
            onClick={() => {
              dispatch(initMockData({taskTypeId: selectedEntity?.tableType as string, type:"entity"}));
              setOpenAB(true)
            }}
          >
            {" "}
            Configure Automation{" "}
          </ZButton>
        )}

      {activeEditColumn?.name &&
        activeEditColumn?.action?.value === IButtonActionType.OPEN_URL && (
          <ZButton
            secondary
            fullWidth
            variant="contained"
            className="mt-2"
            onClick={(e) => {
              dispatch(setScriptEditorPayload(actionConditionPayload));
              openJSEditorLayout({
                config: editorConfig,
                editorLocation: EditorScriptLocation.BUTTON_AUTOMATION_URL,
              });
            }}
          >
            {" "}
            Configure URL{" "}
          </ZButton>
        )}
      <ConfigureAutomation
        metaDataType={ABMetadataTypeEnum.BUTTON_AUTOMATION}
        prevAutomations={
          store?.getState()?.entity?.selectedActionColumn?.uiAutomations || []
        }
        onSaveHandler={saveHandlerForButton}
        onAutomationClose={() => {
          clearRightSide();
          dispatch(closeAutomation());
          setOpenAB(false);
        }}
        otherConfigs={{
          allowOnlySingleAutomation: true,
          hideTriggerConfigureButton: false,
          preFillTrigger: true,
          // hideActionBlock: hideActionBlock
        }}
        openAutomationBuilder={openAB}
      />
      {scriptEditorOpen && <ZJSEditorLayout />}
    </>
  );
};

export default ButtonAutomation;
