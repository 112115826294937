// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-15{
    padding: 15px;
}

.pr-10{
    padding-right: 10px;
}

.pl-10{
    padding-left:10px
}

.pr-100{
    padding-right: 100px;
}

.pr-10{
    padding-right: 10px;
}

.m-0{
    margin: 0px;
}

.mb-0{
    margin-bottom: 0px;
}

.w-80p{
    width: 80%;
}


.bg-blue{   
    background-color: #f6f7fe; 
    border: 1px dotted #9eb2fc;
}


`, "",{"version":3,"sources":["webpack://./src/views/automationBuilder/automationbuilder.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;AACJ;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;;AAGA;IACI,yBAAyB;IACzB,0BAA0B;AAC9B","sourcesContent":[".p-15{\n    padding: 15px;\n}\n\n.pr-10{\n    padding-right: 10px;\n}\n\n.pl-10{\n    padding-left:10px\n}\n\n.pr-100{\n    padding-right: 100px;\n}\n\n.pr-10{\n    padding-right: 10px;\n}\n\n.m-0{\n    margin: 0px;\n}\n\n.mb-0{\n    margin-bottom: 0px;\n}\n\n.w-80p{\n    width: 80%;\n}\n\n\n.bg-blue{   \n    background-color: #f6f7fe; \n    border: 1px dotted #9eb2fc;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
