import { loader } from "@monaco-editor/react";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import AnamoliesIcon from "assests/anamolies_icon.svg";
import notification from "notifications/notifications";
import {
  IRunQueryPayload,
  MonitorService,
} from "providers/data/services/MonitorService";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { FaPlay } from "react-icons/fa";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import ConfirmationDialog from "views/automationBuilder/components/ConfirmationDialog";
import { IQueryGeneratedType } from "../MonitorInterface";
import CollapsableComponent from "../MonitorInterfaceComponents/CollapsableComponent";
import { MonitorITable } from "../MonitorInterfaceComponents/MonitorITable";
import QueryBuilder from "../queryBuilder/QueryBuilder";
import {
  IScreenContext,
  setMonitorInterfaceContext,
} from "../reducer/MonitorReducer";
import useMonitor from "../reducer/useMonitor";
import MonitorEditor from "./MonitorEditor";

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
    min-width: 400px;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    `
);

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    padding: 20px 12px;
    border-radius: 12px;
    opacity: 0.6;
    `
);

const Tab = styled(BaseTab)`
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  width: fit-content;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #fff;
  }

  // &:focus {
  //   color: red;
  //   background-color: #F5F8FF;
  //   outline: 1px solid #F5F8FF;
  // }

  &.${tabClasses.selected} {
    background-color: #f5f8ff;
    color: #3054b9;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const QueryExecutionErrorMessage = styled.p`
  color: var(--Error-700, #b42318);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
`;

interface IDatasourceAnamoliesSection {
  watch: any;
  trigger: any;
  queryBuilderFetchColumns: any;
  setValue: any;
  control: any;
  clearQueryBuilderValue: () => void;
  onQueryBuilderSubmit: (queryBuilderOutputData: any) => void;
  queryBuilderInputDataComponent: any;
  changeToQueryBuilder: boolean;
  setError: any;
  setFocus: any;
}

const DatasourceAnamoliesSection = ({
  watch,
  trigger,
  queryBuilderFetchColumns,
  setValue,
  control,
  onQueryBuilderSubmit,
  queryBuilderInputDataComponent = {},
  clearQueryBuilderValue,
  changeToQueryBuilder,
  setError,
  setFocus,
}: IDatasourceAnamoliesSection) => {
  const dispatch = useDispatch();
  const [queryResultErrorMessage, setQueryResultErrorMessage] =
    useState<string>("");
  const { monitorInterfaceContext } = useMonitor();
  const isMonitorInEditMode =
    monitorInterfaceContext?.monitorScreenContext == IScreenContext.EDIT;
  const [queryOutputTableData, setQueryOutputTableData] = useState<any[]>([]);
  const [isQueryOutputLoading, setIsQueryOutputLoading] =
    useState<boolean>(false);
  const [queryExecutionTime, setQueryExecutionTime] = useState<string>("");
  const [isBtnClicked, setIsBtnClicked] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<IQueryGeneratedType>(
    watch()?.query_generated_type
  );
  const [defaultEditorValue, setDefaultEditorValue] = useState<string>(
    watch()?.query || ""
  );
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [toSQLTabChangeConfirmation, setToSQLTabChangeConfirmation] =
    useState<boolean>(false);
  const tableName = watch()?.tableName;

  loader.init().then((monaco) => {
    monaco.editor.defineTheme("myTheme", {
      base: "vs",
      inherit: true,
      rules: [],
      colors: {
        "editor.background": "#FAFBFF",
        "editor.lineNumber.foreground": "#3054B9",
        "editorLineNumber.foreground": "#3054B9",
        "editor.lineNumber.background": "#3054B9",
        "editorLineNumber.background": "#3054B9",
        "editor.border": "#3054B9",
      },
    });
  });
  function handleEditorChange(value: string) {
    // here is the current value
    setDefaultEditorValue(value as string);
    setValue("query", value);
  }

  const OnQueryBuilderOutput = async (queryBuilderOutputData: any) => {
    setIsBtnClicked(false);
    setIsQueryOutputLoading(true);

    const conditionalDataMap = queryBuilderOutputData?.filterDropDownData?.map(
      (data:any) => {
        return {
          betweenValue: data.betweenValue.value,
          column: data.column.value,
          dataType: data.column.dataType,
          operator: data.operator.value,
          value: data.value,
        };
      }
    );

    // const dbTypeObj = datasource_dropdown.data.find((data) => data.datasourceId == datasource_dropdown.value)?.datasourceType;

    const output = {
      dbType: "postgres", // ||  dbTypeObj,
      dbName: tableName?.value?.split(".")?.[1] || tableName?.split(".")?.[1],
      conditionData: conditionalDataMap || [],
      orderByData: [],
      selectData: [], // query_builder_custom_component?.model?.selectFieldsOutput.map((data)=> {return {name:data.value}})
    };

    const queryBuilderData = await MonitorService.getQueryFromBuilder(output);
    if (queryBuilderData?.code == "200") {
      setValue("query", queryBuilderData?.data);

      await executeQueryAPI(
        queryBuilderData?.data as string,
        (watch()?.datasourceId?.value as string) ||
          (monitorInterfaceContext?.monitorData?.datasourceId as string)
      );
    } else {
      setQueryResultErrorMessage(
        (queryBuilderData?.message as string) ||
          "Error in fetching query for the given condition"
      );
    }
  };

  const runQuery = async () => {
    setIsEdit(false);
    dispatch(
      setMonitorInterfaceContext({
        ...monitorInterfaceContext, // Spread the previous state to retain other values
        monitorComponentsData: {
          ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
          isdDatasourceSectionHidden: true,
        },
      })
    );

    setTimeout(async () => {
      if (tabValue == IQueryGeneratedType.SQL) {
        const formValidation = await trigger(["datasourceId"]);
        if (!formValidation) {
          return;
        }

        setIsQueryOutputLoading(true);

        await executeQueryAPI(
          defaultEditorValue,
          watch()?.datasourceId?.value as string
        );
      } else if (tabValue == IQueryGeneratedType.QUERY_BUILDER) {
        // get sql query from the query builder
        const formValidation = await trigger(["datasourceId", "tableName"]);
        if (!formValidation) {
          return;
        }

        setIsBtnClicked(true);
      }
    }, 100);

    dispatch(
      setMonitorInterfaceContext({
        ...monitorInterfaceContext, // Spread the previous state to retain other values
        monitorComponentsData: {
          ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
          isChannelSectionHidden: true,
        },
      })
    );
  };

  const executeQueryAPI = async (query: string, datasourceId: string) => {
    const runQueryPayload: IRunQueryPayload = {
      datasourceId: datasourceId,
      query: query,
      type: "SELECT",
    };
    const queryOutput = await MonitorService.runQuery(runQueryPayload);
    setIsQueryOutputLoading(false);

    if (queryOutput.code != "200") {
      setQueryResultErrorMessage(queryOutput.message);
    } else {
      setQueryResultErrorMessage("");
      setQueryOutputTableData(queryOutput?.data?.queryResponse || []);
      setShowTable(true);
      setQueryExecutionTime(
        Math.floor(queryOutput?.data?.executionTime) + " ms"
      );

      dispatch(
        setMonitorInterfaceContext({
          ...monitorInterfaceContext, // Spread the previous state to retain other values
          monitorComponentsData: {
            ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
            tablesColumnsList: Object.keys(
              queryOutput?.data?.queryResponse?.[0] || {}
            ),
            isDisableCreateBulkAlert:
              queryOutput?.data?.queryResponse?.length > 25 ? true : false,
          },
        })
      );

      if (queryOutput?.data?.queryResponse?.length > 25) {
        setValue("createBulkAlert", false);
        setValue("avoidDuplicate", false);
        setValue("primaryColumnField", undefined);
      }
    }
  };

  useEffect(() => {
    if (!defaultEditorValue) {
      const sqlQuery = `select * from ${tableName?.value} limit 10`;
      setDefaultEditorValue(sqlQuery as string);
      setValue("query", sqlQuery);
    }
  }, [monitorInterfaceContext?.monitorComponentsData?.selectedTableName]);

  useEffect(() => {
    // don't chaange the tab if the monitor is in edit mode
    if (changeToQueryBuilder && !isMonitorInEditMode) {
      setTabValue(IQueryGeneratedType.QUERY_BUILDER as IQueryGeneratedType);
      setValue("query_generated_type", IQueryGeneratedType.QUERY_BUILDER);
    }
  }, [changeToQueryBuilder]);

  useEffect(() => {
    // this code is to populate the values in the query builder
    if (monitorInterfaceContext?.monitorScreenContext == IScreenContext.EDIT) {
      setTimeout(() => {
        setIsEdit(true);
        runQuery();
      }, 100);
    }
  }, []);

  return (
    <>
      <CollapsableComponent
        imgIcon={
          <img style={{ paddingRight: "8px" }} src={AnamoliesIcon} alt="Icon" />
        }
        color="#3054B9"
        hideArrow
        title="Look out for these anomalies"
        collapseState={
          !monitorInterfaceContext?.monitorComponentsData
            ?.isConditionSectionHidden
        }
        onClick={() => {
          // dispatch(setMonitorInterfaceContext({
          //   ...monitorInterfaceContext, // Spread the previous state to retain other values
          //   monitorComponentsData: {
          //     ...monitorInterfaceContext?.monitorComponentsData, // Spread the previous monitorComponentsData
          //     isConditionSectionHidden: !monitorInterfaceContext?.monitorComponentsData?.isConditionSectionHidden // Set your desired property
          //   }
          // }));
        }}
      />

      {monitorInterfaceContext?.monitorComponentsData
        ?.isConditionSectionHidden && (
        <>
          <Box
            marginTop={"24px"}
            padding={"24px 16px"}
            style={{ border: "1px solid #D0D5DD" }}
            width={"900px"}
            borderRadius={"4px"}
          >
            <Box>
              <Controller
                name="query_generated_type"
                control={control}
                render={({ field }) => (
                  <>
                    <Tabs
                      //@ts-ignore
                      onChange={(
                        event: React.SyntheticEvent,
                        newValue: string
                      ) => {
                        if (newValue == IQueryGeneratedType.SQL) {
                          setToSQLTabChangeConfirmation(true);
                        } else if (
                          newValue == IQueryGeneratedType.QUERY_BUILDER
                        ) {
                          if (tableName) {
                            setTabValue(newValue as IQueryGeneratedType);
                            field.onChange(newValue);
                          } else {
                            setFocus("tableName");
                            setError("tableName", {
                              type: "manual",
                              message: "Please select a table",
                            });
                          }
                        } else {
                          setTabValue(newValue as IQueryGeneratedType);
                          field.onChange(newValue);
                        }
                      }}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <TabsList>
                        <Tab
                          style={{ fontSize: "12px" }}
                          value={IQueryGeneratedType.QUERY_BUILDER}
                        >
                          Query Builder
                        </Tab>
                        <Tab
                          style={{ fontSize: "12px" }}
                          value={IQueryGeneratedType.SQL}
                        >
                          SQL Query
                        </Tab>
                        {/* <Tab value={IQueryGeneratedType.AI_QUERY_BUILDER}>Text-To-Query</Tab> */}
                      </TabsList>
                      <TabPanel
                        value={IQueryGeneratedType.QUERY_BUILDER}
                        style={{ margin: "0px", padding: "0px", opacity: 1 }}
                      >
                        <Box
                          height={"auto"}
                          minHeight={"150px"}
                          maxHeight={"450px"}
                          width="100%"
                        >
                          {tableName ? (
                            <QueryBuilder
                              onQueryBuilderOutput={async (
                                queryBuilderOutputData: any
                              ) => {
                                OnQueryBuilderOutput(queryBuilderOutputData);
                                onQueryBuilderSubmit(queryBuilderOutputData);
                              }}
                              model={{
                                isBtnClicked,
                                columnsData: queryBuilderFetchColumns,
                                outputData:
                                  queryBuilderInputDataComponent || {},
                                isEdit: isEdit,
                                selectFieldsOutput: [],
                              }}
                            />
                          ) : (
                            <>
                              <Box
                                height={"400px"}
                                width="100%"
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <p>No Table Selected</p>
                              </Box>
                            </>
                          )}
                        </Box>
                      </TabPanel>
                      <TabPanel
                        value={IQueryGeneratedType.SQL}
                        style={{ margin: "0px", padding: "0px", opacity: 1 }}
                      >
                        <Box
                          height={"150px"}
                          style={{
                            padding: "0px",
                            margin: "0px",
                            border: "1px solid #EAECF0",
                          }}
                          borderRadius={"8px"}
                        >
                          <MonitorEditor
                            defaultEditorValue={defaultEditorValue}
                            onchange={(value: string) => {
                              handleEditorChange(value);
                            }}
                          />
                        </Box>
                      </TabPanel>
                      <TabPanel value={IQueryGeneratedType.CONVERSATIONAL}>
                        <Box>Coming Soon</Box>
                      </TabPanel>
                    </Tabs>

                    <ConfirmationDialog
                      id={"tab_switch_confirmation"}
                      deletionTitle={"Are you sure you want to go to SQL Tab?"}
                      deletionText={`You may lose your changes that you have made to Query Builder.`}
                      isOpen={toSQLTabChangeConfirmation}
                      primaryButtonName="Yes"
                      variant="primary"
                      secondaryButtonName="No"
                      onPrimaryBtnClick={() => {
                        setTabValue(
                          IQueryGeneratedType.SQL as IQueryGeneratedType
                        );
                        field.onChange(IQueryGeneratedType.SQL);
                        clearQueryBuilderValue();
                        setToSQLTabChangeConfirmation(false);
                      }}
                      onSecondaryClick={() => {
                        setToSQLTabChangeConfirmation(false);
                      }}
                      onClose={() => {
                        setToSQLTabChangeConfirmation(false);
                      }}
                    />
                  </>
                )}
              />

              <Box
                display={"flex"}
                gap={"12px"}
                width={"100%"}
                justifyContent={
                  queryResultErrorMessage ? "space-between" : "flex-end"
                }
                alignItems={"center"}
                marginTop={"16px"}
              >
                {queryResultErrorMessage && (
                  <Box
                    width={"75%"}
                    onClick={() => {
                      navigator.clipboard.writeText(queryResultErrorMessage);
                      notification(
                        "success",
                        "Error messge copied to clipboard"
                      );
                    }}
                  >
                    <Box
                      width={"fit-content"}
                      className="pointer"
                      padding={"8px 12px"}
                      borderRadius={"4px"}
                      border={"1px solid var(--Error-400, #F97066);"}
                    >
                      <QueryExecutionErrorMessage>
                        {queryResultErrorMessage}{" "}
                      </QueryExecutionErrorMessage>
                    </Box>
                  </Box>
                )}

                <LoadingButton
                  color="secondary"
                  onClick={async () => {
                    if (
                      tabValue == IQueryGeneratedType.SQL &&
                      !defaultEditorValue
                    ) {
                      notification("error", "SQL Query is empty");
                    } else {
                      await runQuery();
                    }
                  }}
                  loading={isQueryOutputLoading}
                  disabled={isQueryOutputLoading}
                  loadingPosition="start"
                  startIcon={<FaPlay size={"15px"} fontSize={"15px"} />}
                  style={{
                    borderRadius: "4px",
                    height: "36px",
                    color: "#3C69E7",
                    fontSize: "12px",
                    fontWeight: "normal",
                    backgroundColor: "#fff",
                    boxShadow: "0px 0px 0px 0px rgba(16, 24, 40, 0.05)",
                    border: "1px solid var(--Gray-300, #3C69E7)",
                    textTransform: "none",
                  }}
                  sx={{ height: "36px" }}
                  variant="contained"
                >
                  Run Query
                </LoadingButton>
              </Box>
            </Box>
          </Box>

          {
            <>
              {!queryResultErrorMessage && showTable && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  maxHeight={"280px"}
                  height={"250px"}
                >
                  <MonitorITable
                    executionTime={queryExecutionTime}
                    showTotalCount={true}
                    tableData={queryOutputTableData}
                    headerTopic="What we found"
                  />
                </Box>
              )}
            </>
          }
        </>
      )}
    </>
  );
};

export default React.memo(DatasourceAnamoliesSection);
