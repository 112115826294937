import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import notification from "../../notifications/notifications";
import authProvider from "../../providers/auth/authProvider";
import { TeamService } from "../../providers/data/services/TeamService";
import { TeamValidator } from "../tasks/components/Validator";
import { recordPageVisit, PageCategoryEnum } from "../../config/Rudderstack";
import { ColSpan4, HelperText, OldLabel, RedText, TaskFormBody } from "render-engine/render.styles";
import { Tooltip, Typography } from "@mui/material";
import { BsInfoCircleFill } from "react-icons/bs";
import {useNavigate} from "react-router-dom"
import TopBar from "render-engine/TopBar";
import {useParams} from "react-router-dom"
import styled from "styled-components";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2rem; /* gap-x-8 in Tailwind CSS */

  @media (min-width: 640px) {
    grid-template-columns: repeat(8, 1fr); /* sm:grid-cols-8 in Tailwind CSS */
  }
`;

const TeamEdit = () => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [parentTeamId, setParentTeamId] = React.useState("");
  const navigate = useNavigate();
  const {id} = useParams()

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.team, "team_details");
  }, [id]);

  useEffect(() => {
    ValidatorForm.addValidationRule("parentTeamIdCheck", (value) => {
      if (parentTeamId && parentTeamId.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  });

  const handleParentTeamIdChange = (parentId) => setParentTeamId(parentId);

  useEffect(() => {
    TeamService.getTeam(id)
      .then((res) => {
        const team = res?.data[0];
        setName(team?.name);
        setDescription(team?.description);
        setParentTeamId(team?.parentTeamId);
      })
      .catch((err) => {
        notification("error", "Error while fetching team details");
        console.log(err);
      });
  }, [id]);

  const handleNameChange = (val) => {
    if (val?.trim()?.length === 0) setName(val?.trim());
    else setName(val);
  };
  const handleDescriptionChange = (val) => {
    if (val?.trim()?.length === 0) setDescription(val?.trim());
    else setDescription(val);
  };

  const cancelEdit = () => {
    notification("info", "Team edit cancelled!");
    navigate(`/teams/${id}/show`);
  };

  const editTeam = () => {
    const teamPayLoad = {
      name: name,
      description: description,
      parentTeamId: parentTeamId,
    };

    TeamService.updateTeam(id, teamPayLoad)
      .then((res) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
          // Error
          if (res?.code === "401") {
            notification("error", res.message);
            authProvider.logout();
          } else {
            notification("error", res.message);
          }
        } else {
          notification(
            "success",
            `Team with id ${id} edited successfully!`
          );
          navigate(`/teams/${res.data.teamId}/show`);
        }
      })
      .catch((err) => {
        console.log("Error", err);
        notification("error", "Team cannot be edited!");
      });
  };

  return (
    <ValidatorForm
      onSubmit={editTeam}
      onError={(errors) => console.log(errors)}
    >
      <TaskFormBody>
        <TopBar
          title="Edit Team"
          onCancelText={"Cancel"}
          onSaveText="Edit"
          onSave={() => {}}
          onCancel={cancelEdit}
        />
        <div className="contentWrapper">
          <GridContainer>
            <ColSpan4>
              <OldLabel>
                Name <RedText>*</RedText>
              </OldLabel>
              <TextValidator
                autoComplete={false}
                value={name}
                variant="outlined"
                size="medium"
                className="inc-t0 oid-wd"
                style={{ width: "100%" }}
                onChange={(event) => handleNameChange(event.target.value)}
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </ColSpan4>

            <ColSpan4>
              <OldLabel>
                Description
              </OldLabel>
              <TextValidator
                autoComplete={false}
                value={description}
                variant="outlined"
                size="medium"
                className="inc-t0 oid-wd"
                style={{ width: "100%" }}
                onChange={(event) =>
                  handleDescriptionChange(event.target.value)
                }
                validators={["required"]}
                errorMessages={["This field is required"]}                
              />
            </ColSpan4>
          </GridContainer>

          <GridContainer>
            <ColSpan4>
              <OldLabel>
                Parent Team
              </OldLabel>
              {parentTeamId && (
                <TeamValidator
                  id="parentTeamField"
                  onChange={handleParentTeamIdChange}
                  disabled={false}
                  team={parentTeamId}
                  donotShowTeamId={id}
                  // validators={["parentTeamIdCheck"]}
                  // errorMessages={["This field is required"]}
                />
              )}
              {!parentTeamId && (
                <HelperText as="span">
                  Parent team cannot be changed for root level team
                  &nbsp;&nbsp;
                  <Tooltip
                    title={<Typography color="inherit">Root level team cannot have any parent team. In Zorp, the root level team is always fixed. You can only change the name and description for root team</Typography>}
                  >
                    <div style={{ display: "inline" }}>
                      <BsInfoCircleFill
                        size={"1.0rem"}
                        color="#1976d2"
                        style={{
                          display: "inline",
                          marginRight: "1.4rem",
                          marginTop: 2,
                        }}
                      />
                    </div>
                  </Tooltip>
                </HelperText>
              )}
            </ColSpan4>
            <div></div>
          </GridContainer>
        </div>
      </TaskFormBody>
    </ValidatorForm>
  );
};

export default TeamEdit;
