import React from "react";

type Props = {};

const ZAlertIcon = ({
  color,
  width,
  height,
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3327 6.66538V9.99871M8.54101 4.58204H5.66602C4.26588 4.58204 3.56582 4.58204 3.03104 4.85452C2.56063 5.09421 2.17818 5.47666 1.9385 5.94706C1.66601 6.48184 1.66601 7.18191 1.66602 8.58204L1.66602 9.58204C1.66602 10.3586 1.66602 10.7469 1.79289 11.0532C1.96204 11.4616 2.2865 11.786 2.69488 11.9552C3.00116 12.082 3.38945 12.082 4.16602 12.082V15.6237C4.16602 15.8172 4.16602 15.9139 4.17404 15.9954C4.25198 16.7867 4.87803 17.4127 5.66932 17.4907C5.75081 17.4987 5.84754 17.4987 6.04102 17.4987C6.23449 17.4987 6.33123 17.4987 6.41271 17.4907C7.20401 17.4127 7.83006 16.7867 7.90799 15.9954C7.91602 15.9139 7.91602 15.8172 7.91602 15.6237V12.082H8.54102C10.013 12.082 11.8137 12.8711 13.2029 13.6284C14.0134 14.0702 14.4186 14.2911 14.684 14.2586C14.9301 14.2285 15.1162 14.118 15.2604 13.9163C15.416 13.6988 15.416 13.2637 15.416 12.3935V4.27061C15.416 3.40037 15.416 2.96525 15.2604 2.74778C15.1162 2.54612 14.9301 2.43562 14.684 2.40548C14.4186 2.37297 14.0134 2.59387 13.2029 3.03567C11.8137 3.79297 10.013 4.58204 8.54101 4.58204Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ZAlertIcon;
