import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
import Grid from "@mui/material/Grid";
import ShopifyMonitorCard from "./ShopifyMonitorCard";
import { MonitorService } from "providers/data/services/MonitorService";
import { IDatasourceListInterface, IShopifyTemplate, IShopifyTemplates } from "./ShopifyMonitor";
import { useState } from "react";
import { setConfirmMonitorModalData, setIsConfirmMonitorModalOpen } from "./reducer/MonitorReducer";
import { useDispatch } from "react-redux";
// import { styled } from "@mui/material/styles";
import styled from "styled-components"


const StyledGrid = styled.div`
  width: 100%;
  height: calc(100vh - 40%);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface IShopifyMonitorCardsContainer {
  templates: IShopifyTemplates;
  selectedDataSource:IDatasourceListInterface;
  createMonitorLoading: boolean;
  fineTuneMonitorOnClick: (template: IShopifyTemplate) => void;
  onOpenMonitorPage: () => void;
  accountEmail: string;
}

const ShopifyMonitorCardsContainer = ({ templates, createMonitorLoading, fineTuneMonitorOnClick, onOpenMonitorPage, selectedDataSource, accountEmail }: IShopifyMonitorCardsContainer) => {
  const [executeMonitorLoading, setExecuteMonitorLoading] = useState(false);
  const dispatch = useDispatch();

  const executeTemplateMonitor = async (template: IShopifyTemplate) => {
    setExecuteMonitorLoading(true);
    const executeMonitorResult = await MonitorService.executeTemplateMonitor(template.templateId);
    setExecuteMonitorLoading(false);
  }
  return (
      <StyledGrid>
        <Grid container spacing={2}>
        {templates.map((template: IShopifyTemplate, index:number) => {
          const templateData = {...template, accountEmail, selectedDataSource};
          return (
            <>
              <Grid item sm={12} md={6} xs={12} key={index}>
                <ShopifyMonitorCard
                onAlertMeClick={()=>{
                  dispatch(setConfirmMonitorModalData(template));
                  dispatch(setIsConfirmMonitorModalOpen(true));
                }}  
                  showCustomizeBlueBeeBtn
                  onOpenMonitorPage={onOpenMonitorPage}
                
                  onFineTuneTemplate={() => {
                    fineTuneMonitorOnClick(templateData);
                  }}
                  monitorTestingLoading={executeMonitorLoading}
                  createMonitorLoading={createMonitorLoading}
                  template={templateData}
                  onExecuteTemplate={async () => {
                    await executeTemplateMonitor(templateData);
                  }}
                />
              </Grid>
            </>
          );
        })}
        </Grid>
      </StyledGrid>
  );
}

export default ShopifyMonitorCardsContainer;