//@ts-nocheck
import React from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";

interface InformationProps {
  title?: string;
  subTitle?: string;
  imageUrl?: string;
  videoUrl?: string;
  description?: string;
  emphasis?: boolean;
}

const Container = styled.div<{ emphasis?: boolean }>`
  position: relative;
  background: var(--Base-White, #fff);
  border: 1px solid var(--Gray-200, #eaecf0);
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: 20px;
  overflow: hidden;
  height: 100%;
`;

const ImageBackground = styled.div<{ imageUrl?: string }>`
  width: 100%;
  height: 100%;
  background-image: ${(props) =>
    props.imageUrl ? `url(${props.imageUrl})` : "none"};
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  filter: brightness(100%);
`;

const Content = styled.div`
  position: relative;
  padding: 20px;
  z-index: 1; /* Ensure content appears on top of the background image */
`;

const Title = styled.h2`
  color: var(--Base-White, #fff);

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const SubTitle = styled.h4`
  color: var(--Base-White, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const Description = styled.p`
  color: #ffffff !important;
  /* Text xs/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  padding-top: 8px;
`;

const InformationComponent: React.FC<InformationProps> = ({
  title,
  subTitle,
  imageUrl,
  videoUrl,
  description,
  emphasis = false,
}) => {
  title = DOMPurify.sanitize(title);
  subTitle = DOMPurify.sanitize(subTitle);
  description = DOMPurify.sanitize(description, { ADD_ATTR: ["target"] });

  return (
    <Container emphasis={emphasis}>
      <ImageBackground imageUrl={imageUrl} />
      <Content>
        {title && <Title>{title}</Title>}
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        {description && (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </Content>
    </Container>
  );
};

export default InformationComponent;