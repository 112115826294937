import React, { useState, memo } from 'react'
import styled from 'styled-components'
import { GoShareAndroid as ShareIcon } from "react-icons/go";
import ShareResourceModal from '../permissions/ShareResourceModal';
import useEntity from '../hooks/useEntity';
import { Permission, PermissionConfiguration, ResourceTypeConstant } from '../permissions/types';
import useAccessPermissions from 'hooks/useAccessPermissions';
import notification from 'notifications/notifications';
// import { Avatar } from '@mui/material';
// import { stringAvatar } from 'utils/CommonUtils';
import { EntityService } from 'providers/data/services/EntityService';
import { useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import ManagePermissionsModal from '../permissions/ManagePermissionsModal';
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import CircularProgress from '@mui/material/CircularProgress';
import { TablePermissionRsEvents } from 'views/entities/types';
import { recordRSEvent } from 'utils/CommonUtils';
import { setEntityPermissions, setFinalEntityPermissions, setpermissionConfiguration } from 'views/entities/reducers/entityReducers';
import magicWand from "assests/magic_wand.svg";
import tableShare from "assests/table_share.svg";
import { RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';

const HeaderContainer = styled.div`
  padding: 8px 16px 2px 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const HeaderComponent = styled.div`
  display: flex;
  width: max-content;
  flex-direction: column;
  font-family: 'Inter';
`

const Text = styled.div<{ $cursor?: string; $color?: string; $size?: string; $weight?: string; lineHeight?: string }>`
  font-size: ${({ $size }) => $size || '14px'};
  font-weight: ${({ $weight }) => $weight || '400'};
  line-height: ${({ lineHeight }) => lineHeight || '20px'};
  color: ${({ $color }) => $color || '#475467'};
  cursor: ${({ $cursor }) => $cursor || 'auto'};
`

const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`

// const AvatarContainer = styled.div`
//   display: flex;
// `

type props = {
  name: string;
  description?: string;
  handleConfigureAlert: () => void;
}

const TableHeader = ({ name, description, handleConfigureAlert }: props) => {

  const [showResourceShareModal, setShowResourceShareModal] = useState(false);
  const { permissionConfiguration, selectedEntity } = useEntity();
  const accessPermissions = useAccessPermissions({ resourceType: ResourceTypeConstant.TABLE });
  const [isLoading, setIsLoading] = useState(false);
  const [showPermissionsModal, setShowPermissionsModal] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleShareClick = async () => {
    recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_BUTTON_CLICKED);
    recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_ACL_PERMISSIONS_fETCHED_BUTTON_CLICKED);
    if (accessPermissions.update_table_acl) {
      await fetchAndSetTablePermissions();
      setShowResourceShareModal(true);
    } else {
      notification("error", "You do not have permissison to share the resource")
      recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_ACL_PERMISSIONS_fETCHED_FAILURE);
    }
  }

  const fetchAndSetTablePermissions = async () => {
    setIsLoading(true);
    try {
      const response = await EntityService.getEntityPermissions(selectedEntity?.tableType || "");
      const tablePermissions = response?.result?.data?.tablePermissions;

      if (tablePermissions) {
        recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_ACL_PERMISSIONS_fETCHED_SUCCESS, {
          permissions: tablePermissions
        });
        const uniquePermissionSetNames = new Set<string>();
        const permissionConfiguration = tablePermissions.reduce((config: PermissionConfiguration[], permission: Permission) => {
          if (!uniquePermissionSetNames.has(permission.permissionSetName)) {
            uniquePermissionSetNames.add(permission.permissionSetName);
            config.push({
              permissionId: permission.permissionId,
              permissionSetName: permission.permissionSetName,
              permissions: permission.permissions,
            });
          }
          return config;
        }, []);
        dispatch(setEntityPermissions(tablePermissions));
        dispatch(setFinalEntityPermissions(tablePermissions));
        dispatch(setpermissionConfiguration(permissionConfiguration));
      }
    } catch (error) {
      console.error("Failed to fetch table permissions", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // const getSharedUsers = () => {
  //   let sharedUsers: string[] = [];
  //   entityPermissions.forEach(permission => {
  //     permission.or.roleIds.forEach(role => {
  //       sharedUsers.push(role.name)
  //     });
  //     permission.or.userIds.forEach(user => {
  //       sharedUsers.push(user.name)
  //     });
  //     permission.or.teamIds.forEach(team => {
  //       sharedUsers.push(team.name)
  //     })
  //   })
  //   return sharedUsers;
  // }

  // const sharedUsers = getSharedUsers();
  // const usersToShow = sharedUsers.slice(0, 5);
  // const extraCount = sharedUsers.length > 5 ? `+${sharedUsers.length - 5}` : null;

  return (
    <>
      <HeaderContainer>
        <HeaderComponent>
          <Text $color="#101828" $size="14px" $weight="600">
            {name}
          </Text>
          <Text $color="#667085" $size="12px" $weight="500">
            {description ? description : ""}
          </Text>
        </HeaderComponent>
        <ShareContainer>
          <ZButton
            secondary
            style={{ padding: "5px 12px",height:'30px', color:'#344054', fontWeight:600, fontSize:'12px'}}
            variant="contained"
            fontSize={14}
            onClick={handleShareClick}
            startIcon={<img src={tableShare}/>}
          >
            Share
          </ZButton>
        </ShareContainer>
      </HeaderContainer>
      {showResourceShareModal && (
        <ShareResourceModal
          initialPermisionLevel={{
            label: permissionConfiguration[0].permissionSetName,
            value: permissionConfiguration[0].permissionSetName,
          }}
          context="create"
          setShowPermissionsModal={setShowPermissionsModal}
          resourceType={ResourceTypeConstant.TABLE}
          initialSelectedOptions={[]}
          onClose={() => setShowResourceShareModal(false)}
        />
      )}
      {showPermissionsModal && (
        <ManagePermissionsModal
          onClose={() => {
            setShowPermissionsModal(false);
          }}
          resourceType={ResourceTypeConstant.TABLE}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default memo(TableHeader);