//@ts-nocheck
import { Grid, TextField } from "@mui/material";
import React, { FunctionComponent, useState, useEffect } from "react";
import {
  UseFormRegister,
  FieldErrors,
  useForm,
  Control,
  Controller,
  UseFormSetValue,
  UseFormGetValues,
} from "react-hook-form";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ComponentType } from "../ComponentTypeEnum";
import { IZSlotInterface } from "./IZSlotInterface";
import { DateValidator } from "../../views/tasks/components/Validator";
import { isAfter } from "date-fns";
import { ZEmpty } from "../empty/ZEmpty";
import { ColSpan4 } from "render-engine/render.styles";

export const ZSlot: FunctionComponent<{
  config: IZSlotInterface;
  onChange: Function;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  control: Control;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
}> = ({ config, onChange, register, errors, control, setValue, getValues }) => {
  // const { handleSubmit, setError, formState: { errors } } = useForm();

  const [fromValue, setFromValue] = React.useState(
    config?.value?.from ? new Date(config?.value?.from) : new Date()
  );
  const [toValue, setToValue] = React.useState(
    config?.value?.to ? new Date(config?.value?.to) : new Date()
  );

  useEffect(() => {
    setValue(`${config.key}`, {
      from: config.value ? new Date(config.value?.from) : new Date(),
      to: config?.value?.to ? new Date(config?.value?.to) : new Date(),
    });
  }, []);
  //React Hook Form for Validation
  // Construct validation
  const validation: any = {};

  const validate = () => {
    if (fromValue && toValue) {
      if (fromValue?.getDate() > toValue?.getDate()) {
        return "Start time should be less than End time";
      }
      // else {
      //     if (fromValue?.getHours() > toValue?.getHours()) {
      //         return "End value should be greater than start value";
      //     }
      //     else {
      //         if (fromValue?.getMinutes() > toValue?.getMinutes()) {
      //             return "End value should be greater than start value";
      //         }
      //     }
      // }
    } else if (fromValue && !toValue) {
      return "Enter the end value";
    } else if (!fromValue && toValue) {
      return "Enter the start Value";
    } else {
      return "required";
    }
  };

  // If there is an incoming value, store it in redux store (this is akin to default value)
  useEffect(() => {
    if (config?.value) {
      setFromValue(new Date(config.value.from));
      setToValue(new Date(config.value.to));
    }
  }, []);

  const validateToDate = (value: Date) => {
    //@ts-ignore
    let fromDate = getValues()["scheduledSlot"]?.from;

    if (fromDate) {
      return value && new Date(value) > new Date(fromDate)
        ? true
        : "Scheduled Slot End Date-Time should be greater than Scheduled Slot Start Date-Time";
    } else {
      return "Scheduled Slot End Date-Time should be greater than Scheduled Slot Start Date-Time";
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {config.shape.toLowerCase() === "date" && (
          <Grid container>
            <div className="bt-row">
              <div className="bt-col-md-2">
                <div className="bt-row form-group">
                  <div className="bt-col-md-4 mb-2">
                    <strong>Start Date-Time:</strong>
                  </div>
                  <div className="bt-col-md-8">
                    <Controller
                      control={control}
                      name={`${config.key}.from`}
                      rules={{ validate }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DesktopDatePicker
                          //   {...register(`${config.key}.from`, {
                          //     validate: validate,
                          //   })}
                          inputFormat="MM/dd/yyyy"
                          // defaultValue={fromValue}
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      )}
                    />

                    <div className="font-semibold text-xs text-red-500">
                      {errors[config.key]?.from?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bt-col-md-6">
                <div className="bt-row form-group">
                  <div className="bt-col-md-4 mb-1">
                    <strong>End Date-Time:</strong>
                  </div>
                  <div className="bt-col-md-8">
                    <Controller
                      control={control}
                      name={`${config.key}.to`}
                      rules={{ validate }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DesktopDatePicker
                          {...register(`${config.key}.to`, {
                            validate: validate,
                          })}
                          inputFormat="MM/dd/yyyy"
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      )}
                    />

                    <div className="font-semibold text-xs text-red-500">
                      {errors[config.key]?.to?.message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        )}
        {config.shape.toLowerCase() === "time" && (
          <Grid container>
            <div className="bt-row">
              <div className="bt-col-md-6">
                <div className="bt-row">
                  <div className="bt-col-md-4">
                    <strong>Start Date-Time:</strong>
                  </div>
                  <div className="bt-col-md-8">
                    <TimePicker
                      {...register(`${config.key}.from`, {
                        validate: validate,
                      })}
                      // inputFormat="MM/dd/yyyy"
                      value={fromValue}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                    <div className="font-semibold text-xs text-red-500">
                      {errors[config.key]?.from?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bt-col-md-6">
                <div className="bt-row">
                  <div className="bt-col-md-4">
                    <strong>End Date-Time:</strong>
                  </div>
                  <div className="bt-col-md-8">
                    <TimePicker
                      {...register(`${config.key}.to`, {
                        validate: validate,
                      })}
                      value={toValue}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                    <div className="font-semibold text-xs text-red-500">
                      {errors[config.key]?.to?.message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {errors[config.key]?.message?.length > 0 &&
              toValue &&
              fromValue && (
                <div className="font-semibold text-xs text-red-500">
                  {errors[config.key]?.message}
                </div>
              )}
          </Grid>
        )}
        {config.shape.toLowerCase() === "datetime" && (
          <>
            <ColSpan4>
              <div className="col-span-1 font-medium mb-1 pt-4">
                Start Date-Time
              </div>
              <Controller
                control={control}
                name={`${config.key}.from`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DateTimePicker
                    // inputFormat="MM/DD/yyyy"
                    value={value}
                    sx={{
                      width: "100%",
                      backgroundColor: "white",
                    }}
                    // minDateTime={new Date()}
                    onChange={onChange}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                )}
              />
              <div className="mt-1" />
              <div className="font-semibold text-xs text-red-500">
                {errors[config.key]?.from?.message}
              </div>
            </ColSpan4>

            {/* <ZEmpty></ZEmpty> */}

            <ColSpan4>
              <div className="col-span-1 font-medium mb-1 pt-4">
                End Date-Time
              </div>
              <Controller
                control={control}
                name={`${config.key}.to`}
                rules={{
                  required: "This field is required",
                  validate: validateToDate,
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DateTimePicker
                    // inputFormat="MM/DD/yyyy"
                    value={value}
                    // minDateTime={new Date()}
                    onChange={onChange}
                    sx={{
                      width: "100%",
                      backgroundColor: "white",
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                )}
              />
               <div className="mt-1" />
              <div className="font-semibold text-xs text-red-500">
                {errors[config.key]?.to?.message}
              </div>
            </ColSpan4>
          </>
        )}
      </LocalizationProvider>
    </>
  );
};
