import React, { useEffect } from "react";
import useEntity from "views/entities/hooks/useEntity";
import styled from "styled-components";
import Pills from "./AutomationBuilder/Pills";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import DynamicForm from "./AutomationBuilder";
import { FormControlLabel, Modal } from "@mui/material";
import {
  HeadingWrapper,
  InputContainer,
} from "views/workflows/workflowactions/ModalContent.styles";
import IsActive from "views/workflows/workflowactions/api/components/IsActive";
import useAutomation from "../hooks/useAutomation";
import { useForm } from "react-hook-form";
import {
  ValidLocation,
  generateTempId,
  getActionNameForTempId,
  isTempIdUnique,
  saveAction,
} from "views/workflows/workflowactions/api/utils/utils";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  clearScriptEditor,
} from "lowcode/state/scriptOpenState";
import {
  setApiResponse,
  setSelectedAction,
} from "views/workflows/reducers/workflowAction";
import { EditorScriptLocation } from "lowcode/state/types/types";
import ZInput from "views/commonComponents/ZTextInput";
import InputTempId from "./InputTempId";
import _, { cloneDeep } from "lodash";
import { BffService } from "providers/data/services/BffService";
import notification from "notifications/notifications";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { Typography } from "@mui/material";
import {
  JsonView,
  allExpanded,
  defaultStyles,
} from "react-json-view-lite";
import { updateMockDataValue } from "../utils/util";
import {
  ZorpTableActionIds,
  ZorpTaskActionIds,
} from "@zorp/common-libs-js/dist/common/types/types";
import moment from "moment";
import { PiCubeBold } from "react-icons/pi";
import ErrorHandlingModal from "views/workflows/workflowactions/api/APIAction/ErrorHandlingModal";
import { getLowCodeEditorText } from "utils/Utils";
import { BOOLEAN } from "constants/CommonConstants";
import { LuSettings2 } from "react-icons/lu";
import usePayload from "../hooks/usePayload";
import { BiCopy } from "react-icons/bi";
import { IoPlayOutline } from "react-icons/io5";
import ErrorBoundaryComp from "views/NotFound/ErrorBoundaryComp";
import { ErrorBoundary } from "react-error-boundary";
import { sendCCErrorAlertToSlack } from "utils/SlackController";
import { stopPropagate } from "utils/CommonUtils";

const Heading = styled.div`
  color: rgba(0, 0, 0, 0.8);

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const Subheading = styled.div`
  color: #000;

  /* Text xs/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  margin-bottom: 8px;
`;

const Title = styled.div`
  color: var(--Gray-500, #667085);

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  margin-bottom: 6px;
`;

const Wrapper = styled.div``;

const Label = styled.div`
  color: var(--Gray-700, #344054);
  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

type Props = {};

function fallbackRender({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: any;
}) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <ErrorBoundaryComp
      message="error"
      resetErrorBoundary={resetErrorBoundary}
    />
  );
}

const ZorpAutomation = (props: Props) => {
  const { selectedEntityMetadata } = useEntity();
  const { selectedAction, selectedTransition, getScriptValue, taskTypeId } =
    useWorkFlow();
  const {
    clearRightSide,
    selectedActionBlock,
    selectedAutomation,
    isEntityAutomation,
    isCronAutomation,
    isWebHookAutomation,
    isMonitorAutomation,
    isTeamAutomation,
    isUserAutomation,
    selectedCron,
    selectedWebhook,
    selectedMonitorId
  } = useAutomation();
  const { actionConditionPayload } = usePayload();
  const [exitOnError, setExitOnError] = React.useState(false);
  const [openErrorHandlingModal, setOpenErrorHandlingModal] =
    React.useState(false);
  const defaultTmpId =
    selectedAction.tempId ||
    generateTempId(
      selectedAction,
      selectedAutomation,
      getActionNameForTempId(selectedAction),
      "action"
    );
  const {
    control,
    handleSubmit,
    setError,
    register,
    trigger,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      actionName: selectedAction?.actionName ?? "",
      tempId: generateTempId(
        selectedAction,
        selectedAutomation,
        getActionNameForTempId(selectedAction),
        "action"
      ),

      // tempId: generateTempId(
      //   selectedAction,
      //   selectedAutomation,
      //   selectedAction?.actionName,
      //   "action"
      // ),

      isActive: selectedAction?.isActive ?? true,
      retries: selectedAction?.retries || 3,
      delay: selectedAction?.delay || 2000,
      exponentialFactor: selectedAction?.exponentialFactor || 2,
      isRetryEnabled:
        selectedAction?.isRetryEnabled === true
          ? "retryEnabled"
          : "retryDisabled",
      exponential:
        selectedAction?.exponential === undefined ||
        selectedAction?.exponential === false
          ? "uniform"
          : "exponential",

      // shouldRetry: selectedAction?.shouldRetry || false,
      [EditorScriptLocation.API_ACTION_SHOULD_RETRY]: selectedAction[
        EditorScriptLocation.API_ACTION_SHOULD_RETRY
      ] || {
        type: "script",
        dataType: BOOLEAN,
        value: `if(response_${defaultTmpId}?.status === 500) { return true } return false`,
      },
    },
  });
  const [isEditing, setIsEditing] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});
  const store = useStore();
  const [currTempIdVal, setCurrTempIdVal] = React.useState(
    selectedAction?.tempId || ""
  );
  const { mockData } = useSelector((state: any) => state.scriptEditor);
  const [tempIdChanged, setTempIdChanged] = React.useState(true);

  const dispatch = useDispatch();

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSave = async (data: any) => {
    clearErrors();
    let tempActionId: string = selectedAction?.tempId || data?.tempId;
    if (!isEditing) {
      tempActionId =
        currTempIdVal.length === 0
          ? selectedAction?.actionId ||
            selectedAction?.guardId ||
            selectedAction?.conditionId
          : currTempIdVal;
      dispatch(setSelectedAction({ ...selectedAction, tempId: currTempIdVal }));
    }

    let isTempIdChanged = tempIdChanged;
    if (
      tempActionId ===
      (selectedAction?.actionId ||
        selectedAction?.guardId ||
        selectedAction?.conditionId)
    ) {
      setTempIdChanged(false);
      isTempIdChanged = false;
    }

    if (isTempIdChanged && !isTempIdUnique(tempActionId, selectedAutomation)) {
      setError("tempId", {
        type: "manual",
        message: "This ID already exists. Please choose a different one.",
      });
      return;
    }

    //If tempId is changed then only validations are added.
    if (
      (selectedAction?.guardId &&
        selectedAction?.tempId !== selectedAction?.guardId) ||
      (selectedAction?.actionId &&
        selectedAction?.tempId !== selectedAction?.actionId)
    ) {
      if (!isTempIdUnique(tempActionId, selectedAutomation)) {
        setError("tempId", {
          type: "manual",
          message: "This ID already exists. Please choose a different one.",
        });
        return;
      }
    }

    if (selectedAction?.actionType === ZorpTaskActionIds.CREATE_ZORP_TASK) {
      if (!selectedAction?.parameters?.taskType) {
        setError("parameters.taskType", {
          type: "manual",
          message: "Tasktype is required",
        });
        return;
      }

      // if (!selectedAction?.parameters?.scheduledSlotFrom) {
      //   setError("parameters.scheduledSlotFrom", {
      //     type: "manual",
      //     message: "Scheduled Slot From is required",
      //   });
      //   return;
      // }

      // if (!selectedAction?.parameters?.scheduledSlotTo) {
      //   setError("parameters.scheduledSlotTo", {
      //     type: "manual",
      //     message: "Scheduled Slot To is required",
      //   });
      //   return;
      // }

      // scheduledSlotFrom should be less than scheduledSlotTo

      if (
        moment(selectedAction?.parameters?.scheduledSlotFrom).isAfter(
          moment(selectedAction?.parameters?.scheduledSlotTo)
        )
      ) {
        setError("parameters.scheduledSlotFrom", {
          type: "manual",
          message: "Scheduled Slot From should be less than Scheduled Slot To",
        });
        return;
      }

      // teamIds must be an array and greater than 0
      if (
        !selectedAction?.parameters?.teamIds ||
        selectedAction?.parameters?.teamIds.length === 0
      ) {
        setError("parameters.teamIds", {
          type: "manual",
          message: "Team Ids is required",
        });
        return;
      }
    }

    if (selectedAction?.actionType === ZorpTaskActionIds.UPDATE_ZORP_TASK) {
      if (!selectedAction?.parameters?.taskType) {
        setError("parameters.taskType", {
          type: "manual",
          message: "Tasktype is required",
        });
        return;
      }

      if (!selectedAction?.parameters?.taskId) {
        setError("parameters.taskId", {
          type: "manual",
          message: "Task Id is required",
        });
        return;
      }
    }

    if (selectedAction?.actionType === ZorpTaskActionIds.SEARCH_ZORP_TASK) {
      if (!selectedAction?.parameters?.taskId) {
        setError("parameters.taskId", {
          type: "manual",
          message: "Task Id is required",
        });
        return;
      }
    }

    if (selectedAction?.actionType === ZorpTaskActionIds.DELETE_ZORP_TASK) {
      if (!selectedAction?.parameters?.taskId) {
        setError("parameters.taskId", {
          type: "manual",
          message: "Task id is required",
        });
        return;
      }
    }

    if (selectedAction?.actionType === ZorpTaskActionIds.TRANSIT_ZORP_TASK) {
      if (!selectedAction?.parameters?.taskType) {
        setError("parameters.taskType", {
          type: "manual",
          message: "Task type is required",
        });
        return;
      }

      if (!selectedAction?.parameters?.taskId) {
        setError("parameters.taskId", {
          type: "manual",
          message: "Task Id is required",
        });
        return;
      }

      if (!selectedAction?.parameters?.event) {
        setError("parameters.event", {
          type: "manual",
          message: "Transition is required",
        });
        return;
      }
    }

    if (selectedAction?.actionType === ZorpTaskActionIds.UPDATE_SLOT) {
      if (!selectedAction?.parameters?.taskId) {
        setError("parameters.taskId", {
          type: "manual",
          message: "Task Id is required",
        });
        return;
      }

      if (!selectedAction?.parameters?.scheduledSlotFrom) {
        setError("parameters.scheduledSlotFrom", {
          type: "manual",
          message: "Scheduled Slot From is required",
        });
        return;
      }

      if (!selectedAction?.parameters?.scheduledSlotTo) {
        setError("parameters.scheduledSlotTo", {
          type: "manual",
          message: "Scheduled Slot To is required",
        });
        return;
      }

      // scheduledSlotFrom should be less than scheduledSlotTo

      if (
        moment(selectedAction?.parameters?.scheduledSlotFrom).isAfter(
          moment(selectedAction?.parameters?.scheduledSlotTo)
        )
      ) {
        setError("parameters.scheduledSlotFrom", {
          type: "manual",
          message: "Scheduled Slot From should be less than Scheduled Slot To",
        });
        return;
      }
    }

    if (selectedAction?.actionType === ZorpTableActionIds.CREATE_ZORP_RECORD) {
      if (!selectedAction?.parameters?.tableType) {
        setError("parameters.tableType", {
          type: "manual",
          message: "Table Type is required",
        });
        return;
      }
    }

    if (selectedAction?.actionType === ZorpTableActionIds.UPDATE_ZORP_RECORD) {
      if (!selectedAction?.parameters?.tableType) {
        setError("parameters.tableType", {
          type: "manual",
          message: "Table Type is required",
        });
        return;
      }

      if (!selectedAction?.parameters?.recordId) {
        setError("parameters.recordId", {
          type: "manual",
          message: "Record Id is required",
        });
        return;
      }
    }

    if (selectedAction?.actionType === ZorpTableActionIds.SEARCH_ZORP_RECORD) {
      if (!selectedAction?.parameters?.tableTypes) {
        setError("parameters.tableTypes", {
          type: "manual",
          message: "Table Type is required",
        });
        return;
      }

      /**
       * check for filters.
       * if filters is empty, then no need to check for filter values.
       * if filters is not empty, then check for filter values.
       * if filter[index].id is empty, then show error.
       * if filter[index].filterType is empty, then show error.
       */
      if (
        selectedAction?.parameters?.filters &&
        selectedAction?.parameters?.filters.length > 0
      ) {
        selectedAction?.parameters?.filters.forEach(
          (filter: any, index: number) => {
            if (!filter.id) {
              setError(`parameters.filters[${index}].id`, {
                type: "manual",
                message: "Filter Id is required",
              });
            }

            if (!filter.filterType) {
              setError(`parameters.filters[${index}].filterType`, {
                type: "manual",
                message: "Filter Type is required",
              });
            }
          }
        );
      }
    }

    let actionData = {};

    actionData = {
      transitionId: selectedTransition?.id,
      actionId: tempActionId,
      tempId: tempActionId,
      actionName: data.actionName,
      isActive: selectedAction?.isActive ?? true,
      actionType: selectedAction.actionType,
      parameters: selectedAction.parameters,
      [EditorScriptLocation.ERROR_MESSAGE]:
        selectedAction[EditorScriptLocation.ERROR_MESSAGE],
      exitOnError: exitOnError,
      retries: parseInt(selectedAction?.retries) || 3,
      delay: parseInt(selectedAction?.delay) || 2000,
      exponential: data.exponential === "exponential" ? true : false,
      isRetryEnabled: data.isRetryEnabled === "retryEnabled" ? true : false,
      exponentialFactor: parseInt(selectedAction?.exponentialFactor) || 2,

      // shouldRetry: selectedAction?.shouldRetry || false,
      [EditorScriptLocation.API_ACTION_SHOULD_RETRY]: selectedAction[
        EditorScriptLocation.API_ACTION_SHOULD_RETRY
      ] || {
        type: "script",
        dataType: BOOLEAN,
        value: `if(response_${tempActionId}?.status === 500) { return true } return false`,
      },
    };

    saveAction({
      payload: actionData,
      location: ValidLocation.AUTOMATION,
      dispatch,
      selectedAction,
      selectedAutomation,
      selectedActionBlock,
    });

    dispatch(clearScriptEditor());
    dispatch(setApiResponse({}));

    // Removing right side config
    clearRightSide();

    // event?.stopPropagation();
    // event?.preventDefault();
  };

  const handleCopyClick = (data: any) => {
    navigator.clipboard.writeText(data).then(
      function () {
        notification("success", "Success", "Copied to clipboard");
      },
      function (err) {
        notification("error", "Error", "Failed to copy to clipboard");
      }
    );
  };

  const handleTempActionValChange = (name, value) => {
    setCurrTempIdVal(value);
  };

  const updateIdAndDispatch = async (oldId, newId) => {
    const mockDataPayload = cloneDeep(mockData);
    if (!oldId || !newId) {
      setIsEditing(false);
      return;
    } else if (oldId === newId) {
      setIsEditing(false);
      return;
    }
    mockDataPayload[`response_${newId}`] = mockDataPayload[`response_${oldId}`];
    await updateMockDataValue(store, dispatch, mockDataPayload);
    const tmpSelectedAction = _.cloneDeep(selectedAction);
    tmpSelectedAction["tempId"] = newId;
    dispatch(setSelectedAction(tmpSelectedAction));
  };

  const handleTickClick = async () => {
    //trigger is used here to trigger the errors associated
    //with temp id when the tick button is clicked.
    const isTempIdValid = await trigger("tempId");
    if (!isTempIdValid) return;
    let isTempIdChanged = true;
    if (
      currTempIdVal === selectedAction.tempId ||
      currTempIdVal === selectedAction.tempId
    ) {
      isTempIdChanged = false;
    }

    if (
      (selectedAction?.guardId || selectedAction?.actionId) &&
      isTempIdChanged
    ) {
      dispatch(setSelectedAction({ ...selectedAction, tempId: currTempIdVal }));
      if (
        isTempIdChanged &&
        !isTempIdUnique(currTempIdVal, selectedAutomation)
      ) {
        setError(
          "tempId",
          {
            type: "manual",
            message: "This ID already exists. Please choose a different one.",
          },
          { shouldFocus: true }
        );
        return;
      }
      setIsEditing(false);
      try {
        const oldTempId = selectedAction?.tempId
          ? selectedAction?.tempId
          : selectedAction?.guardId;
        if (selectedAction?.guardId) {
          await updateIdAndDispatch(oldTempId, currTempIdVal);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsEditing(false);
    }
  };

  const handleValChange = (name: any, value: any) => {
    dispatch(
      setSelectedAction({ ...selectedAction, [name]: value, hasChanged: true })
    );
  };

  const handleTest = async () => {
    const resourceType = isCronAutomation
      ? "CRON"
      : isWebHookAutomation
      ? "WEBHOOK"
      : isMonitorAutomation
      ? "MONITOR"
      : isTeamAutomation
      ? "TEAM"
      : isUserAutomation
      ? "USER"
      : isEntityAutomation
      ? "TABLE"
      : "TASK";
    if (!resourceType)
      return notification("error", "Error", "Resource type not found");
    try {
      const res = await BffService.testAutomation({
        action: {
          ...selectedAction,
          [EditorScriptLocation.API_ACTION_SHOULD_RETRY]: selectedAction[
            EditorScriptLocation.API_ACTION_SHOULD_RETRY
          ] || {
            type: "script",
            dataType: BOOLEAN,
            value: `if(response_${defaultTmpId}?.status === 500) { return true } return false`,
          },
        },
        resourceId: isCronAutomation
          ? selectedCron?.cronId
          : isWebHookAutomation
          ? selectedWebhook?.webhookId
          : isMonitorAutomation
          ? selectedMonitorId
          : isEntityAutomation
          ? selectedEntityMetadata?.tableMetadata?.tableType
          : taskTypeId,
        resourceType: isEntityAutomation
          ? "TABLE"
          : isCronAutomation
          ? "CRON"
          : isWebHookAutomation
          ? "WEBHOOK"
          : isMonitorAutomation
          ? "MONITOR"
          : "TASK",
      });
      setData(res?.data || {});
      const mockDataPayload = cloneDeep(mockData);
      mockDataPayload[`response_${selectedAction?.actionId}`] = res?.data || {};
      if (!["400", "500"].includes(String(res?.code))) {
        updateMockDataValue(store, dispatch, mockDataPayload);
      }
      setOpen(true);
    } catch (error: any) {
      notification("error", "Error", error.message);
      setData({});
    }
  };

  const ShowCurrentActionPayload = cloneDeep(actionConditionPayload);
  if (ShowCurrentActionPayload.payload.inclusions.length > 0) {
    if (ShowCurrentActionPayload.payload.inclusions[0].config) {
      ShowCurrentActionPayload.payload.inclusions[0].config.showCurrentAction =
        true;
      ShowCurrentActionPayload.payload.inclusions[0].config.actionName =
        selectedAction?.actionName || selectedAction?.guardName;
    }
  }

  const actionExecutionScriptConfig = {
    ShouldRetry: {
      script:
        getScriptValue(EditorScriptLocation.API_ACTION_SHOULD_RETRY) ||
        getLowCodeEditorText(
          EditorScriptLocation.API_ACTION_SHOULD_RETRY,
          BOOLEAN,
          `if(response_${defaultTmpId}?.status === 500) { return true } return false`
        ),
      dataType: BOOLEAN,
    },
  };

  const setTempIdError = (error:any) => {
    if (error) {
      setError("tempId", { type: "manual", message: error });
    } else {
      clearErrors("tempId");
    }
  };

  const sendSlackNotification = async (error: Error, info: { componentStack: string }) => {
    try {
      await sendCCErrorAlertToSlack({
        endpoint: `Error in Automation Builder for ${selectedAction?.actionName} with error message ${error.message}`,
        code: 0,
        message: JSON.stringify(info),
        type: "ERROR"

      })
    } catch (error) {
      console.log(error);
    } 
  }


  useEffect(() => {
    setExitOnError(selectedAction?.exitOnError ?? true);
  }, [selectedAction?.exitOnError]);

  return (
    <ErrorBoundary
    fallbackRender={fallbackRender}
    onError={sendSlackNotification}
  >
    <Wrapper as="form" onSubmit={stopPropagate(handleSubmit(handleSave))}>
      <HeadingWrapper>
        <ZButton
          type="button"
          secondary
          onClick={handleTest}
          variant="contained"
          startIcon={<IoPlayOutline />}
        >
          {" "}
          Test{" "}
        </ZButton>
        <div style={{ marginBottom: "8px" }}></div>
        <IsActive control={control} closeOnClick={clearRightSide} />
      </HeadingWrapper>
      <Heading>{selectedAction?.actionName}</Heading>
      <Subheading>{selectedAction?.description}</Subheading>
      <Title>Available Fields In Trigger Record</Title>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          marginBottom: "12px",
          marginTop: "8px",
        }}
      >
        {selectedEntityMetadata?.customFields
          ?.filter(
            (field) => field.type !== "BUTTON" && field.type !== "COMMENT"
          )
          .map((field) => {
            return (
              <Pills key={field.name}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <PiCubeBold />
                  {field.displayName}
                </div>
              </Pills>
            );
          })}
      </div>
      <Label> Name</Label>

      <InputContainer>
        <ZInput
          onChange={handleValChange}
          register={register}
          validations={{
            required: "Action name is required",
          }}
          name="actionName"
          placeholder="Name the Action"
        />
      </InputContainer>
      {errors && errors.actionName && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {errors.actionName.message}
        </span>
      )}
      <InputTempId
        label={"Action Id"}
        onChange={handleTempActionValChange}
        register={register}
        requiredErrorMessage={"Action Id is required"}
        errors={errors}
        isReadOnly={!isEditing}
        handleTickClick={handleTickClick}
        handleEditClick={handleEditClick}
        isEditing={isEditing}
        name="tempId"
        getValues={getValues}
        setTempIdError={setTempIdError}
      />
      <div style={{ marginTop: "8px" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          margin: "8px 0px",
        }}
      >
        <div
          onClick={() => setOpenErrorHandlingModal(true)}
          style={{
            color: "#3e66fb",
            fontWeight: 500,
            cursor: "pointer",
          }}
        >
          <LuSettings2
            style={{
              display: "inline",
            }}
          />{" "}
          Error Handling
        </div>
        <FormControlLabel
          control={
            <input
              type="checkbox"
              style={{ height: "18px", width: "18px" }}
              checked={exitOnError}
              onChange={(value) => {
                setExitOnError((currentValue) => !currentValue);
              }}
              value="one"
            />
          }
          label={
            <Typography
              variant="h6"
              style={{
                color: "#808080",
                fontSize: "14px",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            >
              Exit Transition On Error
            </Typography>
          }
          sx={{
            marginLeft: "0px",
          }}
        />
      </div>
      <div style={{ marginBottom: "8px" }} />
      <DynamicForm
        control={control}
        register={register}
        errors={errors}
        actionType={selectedAction?.actionType}
        clearErrors={clearErrors}
        setValue={setValue}
        getValues={getValues}
      />
      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "white",
              width: "60vw",
              height: "70vh",
              padding: "20px",
              overflow: "hidden",
              borderRadius: "8px",
            }}
          >
            <div>
              <Heading> Test Results </Heading>
              <Title> Action Name: {selectedAction.actionName}</Title>
              <div style={{ marginBottom: "20px" }}></div>
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "calc(70vh - 100px)", // Adjust this value as needed
              }}
            >
              <JsonView
                // style={{ overflow: "auto", height: '100%' }}
                style={{
                  ...defaultStyles,
                  container:"zorpJSONViewer"
                  
                }}
                shouldExpandNode={allExpanded}
                // enableClipboard={async (data)=>{
                //     await navigator.clipboard.writeText(JSON.stringify(data.src));
                // }}
                data={data || {}}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px",
              }}
            >
              <ZButton
                onClick={() => handleCopyClick(JSON.stringify(data, null, 2))}
                variant="contained"
                secondary
                startIcon={<BiCopy />}
              >
                {" "}
                Copy{" "}
              </ZButton>
              <ZButton
                onClick={() => setOpen(false)}
                variant="contained"
                primary
              >
                {" "}
                Close{" "}
              </ZButton>
            </div>
          </div>
        </Modal>
      )}
      {openErrorHandlingModal && (
        <ErrorHandlingModal
          openErrorHandlingModal={openErrorHandlingModal}
          handleModalClose={() => {
            setOpenErrorHandlingModal(false);
          }}
          register={register}
          handleValChange={handleValChange}
          actionExecutionScriptConfig={actionExecutionScriptConfig}
          selectedAction={selectedAction}
          ShowCurrentActionPayload={ShowCurrentActionPayload}
          control={control}
          getValues={getValues}
          watch={watch}
        />
      )}
    </Wrapper>
    </ErrorBoundary>
  );

};

export default ZorpAutomation;
