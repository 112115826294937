import React, { useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Select from 'react-select';
import { UserService } from '../../providers/data/services/UserService';
import { TeamValidator } from '../tasks/components/Validator';
import styled from 'styled-components';

export default function UserSelection(props) {
  const [users, setUsers] = useState([]);
  const [usersAll, setUsersAll] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [user, setUser] = useState(props?.driver);
  const [teamId, setTeamId] = useState(props.teamIds ? props.teamIds[0] : null);

  useEffect(() => {
    ValidatorForm.addValidationRule('TeamIdCheck', (value) => {
      if (teamId && teamId.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    selectUsers(teamId);
  }, [usersAll]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user === '1') setUser(null);
    UserService.userSelect(props?.id)
      .then((res) => {
        var i = 0;
        const drivers_array = [];
        for (i = 0; i < res?.data?.users?.length; i++) {
          const item = res?.data?.users[i];
          const driver_single = {
            id: item.userId,
            name: item.name,
            teamIds: item.teamIds,
          };
          drivers_array.push(driver_single);
        }

        // setDrivers(drivers_array);
        setUsersAll(drivers_array);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleTeamIdChange = (tId) => {
    setTeamId(tId);
    setUser(null);
    props.onChange(tId, null);

    selectUsers(tId);
  };

  const selectUsers = (tId) => {
    // filter the users based on selected team
    if (tId) {
      let users = [];
      usersAll.filter((d) => d.teamIds.includes(tId)).map((d) => users.push(d));
      setUsers(users);
    } else setUsers(usersAll);
  };

  const handleUserChange = (event) => {
    setUser(event?.value);
    props.onChange(teamId, event?.value);
  };

  const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;


  const usersList = React.useMemo(
    () => users.filter((option) => containsText(option?.name, searchText)),
    [searchText, users] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const finalUsersList = usersList.map((team) => {
    return {
      value: team?.id,
      label: team?.name,
    };
  });

  const Wrapper = styled.div`
    width: 100%;
    max-width: 24rem; /* w-96 in Tailwind CSS */
    align-items: center; /* items-center in Tailwind CSS */
    justify-content: flex-start; /* justify-start in Tailwind CSS */
    margin-left: 0.25rem; /* md:mx-1 in Tailwind CSS */
    margin-right: 0.25rem; /* md:mx-1 in Tailwind CSS */
    border-radius: 0.375rem; /* rounded-md in Tailwind CSS */
    padding-top: 1rem; /* py-4 in Tailwind CSS */
    padding-bottom: 1rem; /* py-4 in Tailwind CSS */
    padding-left: 1rem; /* px-4 in Tailwind CSS */
    padding-right: 1rem; /* px-4 in Tailwind CSS */
  `


const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  width: 100%;

  @media (min-width: 768px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid; /* default border width and style in Tailwind CSS */
  border-radius: 0.75rem; /* rounded-xl in Tailwind CSS */
`;



const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;



const StyledH1 = styled.h1`
  font-weight: bold; /* font-bold in Tailwind CSS */
  text-align: left; /* text-left in Tailwind CSS */
`;



const UserSelectionDiv = styled.div`
  width: 100%;
  justify-content: flex-end; /* justify-end in Tailwind CSS */
  display: flex; /* Required for justify-content to work */
`;
 

  return (
    <ValidatorForm
      onError={(errors) => console.log(errors)}
    >
      <Main>
        <Container>
          <InnerContainer className='flex flex-col md:flex-row'>
            <Wrapper>
              <div>
                <StyledH1 className='font-bold text-left'>Team</StyledH1>
              </div>
              <UserSelectionDiv  className='user-selection'>
                <TeamValidator
                  id='teamField'
                  onChange={handleTeamIdChange}
                  disabled={false}
                  team={teamId}
                  size='full'
                />
              </UserSelectionDiv>
            </Wrapper>
          </InnerContainer>
          <div className='flex flex-col md:flex-row'>
            <div className='w-full items-center justify-start md:mx-1 rounded-md py-4 px-4'>
              <div>
                <h1 className='font-bold text-left'>User</h1>
              </div>
              <div className='w-full justify-end user-selection'>
                {
                  (user || finalUsersList) &&
                  <>
                    <Select
                      defaultValue={finalUsersList.find((c) => c.value === user)}
                      value={finalUsersList.find((c) => c.value === user) ?? null}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      menuPortalTarget={document.body}
                      isSearchable
                      isClearable
                      placeholder='Select User'
                      name="select-user"
                      onChange={handleUserChange}
                      menuPosition={"fixed"}
                      menuPlacement={"bottom"}
                      options={finalUsersList}
                    />
                  </>
                }

              </div>
            </div>
          </div>
        </Container>
      </Main>
    </ValidatorForm>
  );
}
