import { Link } from '@mui/material';
import { useRecordContext } from 'react-admin';

const CustomUrlField = ( props ) =>
{
    const { source, target, rel, text } = props;
    const record = useRecordContext(props);
    const value = record && record[source];
    let textVal = record && record[text];

    if (value == null) {
        return null;
    }

    if (textVal) {
        textVal = textVal.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
    }

    return (
        <Link style={{color: "black"}} href={`#/${value}`} target={target} rel={rel}>
            {textVal}
        </Link>
    );
};

CustomUrlField.defaultProps = {
    addLabel: true,
};

export default CustomUrlField;
