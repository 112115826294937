import { ThemeProvider } from "@mui/material/styles";
import { Box, FormControl, Link, FormLabel, TextField } from "@mui/material";
import { useState, useRef, FunctionComponent, useEffect } from "react";
import theme from "styles/theme";
import { isEmpty } from "lodash";
import {
  getAccountDetails,
  isWhitelabelledUrl,
  recordRSEvent,
} from "utils/CommonUtils";
import notification from "notifications/notifications";
import { ZButton } from "../../../views/commonComponents/commonComponents.styles";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import { useNavigate, Navigate } from "react-router-dom";
import { UserService } from "providers/data/services/UserService";
import {
  fetchAndStoreAccountName,
  userDecodedData,
} from "utils/UserAuth.utils";
import { useParams } from "react-router-dom";
import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import authProvider from "providers/auth/authProvider";
import { useDispatch } from "react-redux";
import { resetMenu, setTheme } from "../reducer/menuSlice";
import useQuery from "hooks/useQuery";
import useSessionStorage from "hooks/useSessionStorage";
import { useRedirect, useRefresh } from "react-admin";
import { TaskFlowService } from "providers/data/services/TaskFlowService";
import { setPostPublish } from "views/workflows/reducers/workflowAction";
import { useLocation } from "react-router-dom";
const logo: string = require("../../../assests/darklogo.svg").default;

const FPVerifyOTP: FunctionComponent = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const timerSeconds = 30;
  const Ref: any = useRef(null);

  const dispatch = useDispatch();

  const [otpCode, setOTPCode] = useState("");
  const [timer, setTimer] = useState("00:30");

  const [loading, setLoading] = useState(false);
  const [showResetButton, setShowResetButton] = useState(true);

  const query = useQuery();

  const refresh = useRefresh();

  const redirect = useRedirect();

  const [redirectUrl, setRedirectUrl] = useSessionStorage(
    "redirectUrl",
    query.get("redirectUrl") || localStorage.getItem("redirectUrl") || ""
  );

  const [templateId, setTemplateId] = useSessionStorage(
    "templateId",
    query.get("templateId") || localStorage.getItem("templateId") || ""
  );

  const clearTimer = (seconds: Date) => {
    setTimer("00:30");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(String(seconds));
    }, 1000);
    Ref.current = id;
  };

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.verifyOTP, "verify_otp");
  }, []);

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + timerSeconds);
    return deadline;
  };

  const getTimeRemaining = (time: string) => {
    const total = Date.parse(time) - Date.parse(String(new Date()));
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const resentOTP = () => {
    if (isFromLogin) {
      const body = { userId: userId, password: password };
      const headers = {
        "Content-Type": "application/json",
      };
      UserService.login(userId, body, headers)
        .then((res) => {
          if (res?.code == "200") {
            notification("success", "OTP Sent to your E-mail or Phone Number");
          } else {
            notification("error", res?.message);
          }
        })
        .catch((err) => {
          notification("error", "Error while generating OTP");
        });
    } else if (isFromGoogleLogin) {
      const googleEmail = location.state?.googleEmail;
      const googleIdToken = location.state?.googleIdToken;
      UserService.loginWithGoogleOAuth(googleEmail, googleIdToken)
        .then((res) => {
          if (res?.code == "200") {
            notification("success", "OTP Sent to your E-mail or Phone Number");
          } else {
            notification("error", res?.message);
          }
        })
        .catch((err) => {
          notification("error", "Error while generating OTP");
        });
    } else {
      UserService.generateOTP(userId)
        .then((res) => {
          if (res?.code == "202") {
            notification("success", "OTP Sent to your E-mail or Phone Number");
          } else {
            notification("error", res?.message);
          }
        })
        .catch((err) => {
          notification("error", "Error while generating OTP");
        });
    }
  };

  const startTimer = (time: string) => {
    let { total, minutes, seconds } = getTimeRemaining(time);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
    if (total == 0) {
      setShowResetButton(true);
    }
  };

  const onClickResetTimer = () => {
    resentOTP();
    setShowResetButton(false);
    clearTimer(getDeadTime());
  };

  const publishTemplateWorkflow = async (id: any) => {
    if (id) {
      try {
        const data = await TaskFlowService.publishTemplateAsWorkflow(id);
        await TaskFlowService.publishWorkflow(data?.data);
        localStorage.removeItem("templateId");
        return data?.data;
      } catch (error) {
        console.log(">>>>>>error", { error });
        notification("error", "workflow publish failed");
      }
    }
  };

  if (!isEmpty(userDecodedData())) {
    return (
      <Navigate
        to={{
          pathname: "/",
        }}
      />
    );
  }

  const submit = (e: React.SyntheticEvent) => {
    recordRSEvent("reset_password_click", {
      context: isFromLogin ? "login_otp" : "forgot_password",
      userId: userId,
    });
    setLoading(true);
    e.preventDefault();
    if (otpCode.length == 6) {
      if (isFromLogin) {
        authProvider
          .validateLoginOTP({ userId: userId, otpCode: otpCode })
          .then(async (res) => {
            setLoading(false);
            dispatch(resetMenu());

            // window.location.reload();
            if (redirectUrl) {
              redirect(redirectUrl as string);
            } else if (templateId) {
              publishTemplateWorkflow(templateId)
                .then((publishedWorkflowId) => {
                  // setTemplateId("", true)
                  refresh();
                  redirect(`/workflows/${publishedWorkflowId}/show`);
                  dispatch(
                    setPostPublish({
                      postedWorkflow: "Workflow Created from template",
                      setPostPublish: true,
                      postedWorkflowId: publishedWorkflowId,
                    })
                  );
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              if (isWhitelabelledUrl()) {
                redirect("/tasks");
              } else {
                redirect("/table");
              }
            }

            // fetch account name and store in local storage
            await fetchAndStoreAccountName();

            const accountConfig = await getAccountDetails();
            dispatch(
              setTheme({
                ...accountConfig?.accountConfig?.theme,
              })
            );

            recordRSEvent("signin_event_success", {
              context: "signin",
              userId,
            });
          })
          .catch(() => {
            recordRSEvent("login_otp_verification_fail", {
              context: "login_otp",
              userId: userId,
            });
            setLoading(false);
            notification("error", "Error in verifying OTP");
          });
      } else if (isFromGoogleLogin) {
        const googleEmail = location.state?.googleEmail;
        authProvider
          .validateLoginOTP({ userId: googleEmail, otpCode: otpCode })
          .then(async (res) => {
            setLoading(false);
            dispatch(resetMenu());

            // window.location.reload();
            if (redirectUrl) {
              redirect(redirectUrl as string);
            } else if (templateId) {
              publishTemplateWorkflow(templateId)
                .then((publishedWorkflowId) => {
                  // setTemplateId("", true)
                  refresh();
                  redirect(`/workflows/${publishedWorkflowId}/show`);
                  dispatch(
                    setPostPublish({
                      postedWorkflow: "Workflow Created from template",
                      setPostPublish: true,
                      postedWorkflowId: publishedWorkflowId,
                    })
                  );
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              if (isWhitelabelledUrl()) {
                redirect("/tasks");
              } else {
                redirect("/table");
              }
            }

            // fetch account name and store in local storage
            await fetchAndStoreAccountName();

            const accountConfig = await getAccountDetails();
            dispatch(
              setTheme({
                ...accountConfig?.accountConfig?.theme,
              })
            );

            recordRSEvent("signin_event_success", {
              context: "signin",
              googleEmail,
            });
          })
          .catch(() => {
            recordRSEvent("login_otp_verification_fail", {
              context: "login_otp",
              userId: googleEmail,
            });
            setLoading(false);
            notification("error", "Error in verifying OTP");
          });
      } else {
        UserService.validateOTP(userId, otpCode)
          .then((res) => {
            setLoading(false);
            recordRSEvent("reset_password_success", {
              context: "forgot_password",
              userId: userId,
            });
            if (res?.code == "200") {
              notification("success", "OTP verified");
              navigate({
                pathname: `/change-password/${userId}/${otpCode}`,
              });
            } else {
              notification("error", res?.message);
            }
          })
          .catch(() => {
            recordRSEvent("reset_password_fail", {
              context: "forgot_password",
              userId: userId,
            });
            setLoading(false);
            notification("error", "Error in verifying OTP");
          });
      }
    } else {
      setLoading(false);
      notification("error", "Enter Valid OTP");
    }
  };

  // Check if the navigation was from the login page
  const isFromLogin = location.state?.fromLogin;
  const isFromGoogleLogin = location.state?.fromGoogleLogIn;
  const password = location.state?.password;

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <ThemeProvider theme={theme}>
            <div className="login">
              <div className="loginInner">
                <div className="loginImg">
                  <img src={logo} alt="" />
                </div>
                <h2 className="loginTitle">
                  {" "}
                  {isFromLogin || isFromGoogleLogin
                    ? "Login"
                    : "Reset Password"}
                </h2>
                <form className="loginForm" onSubmit={submit}>
                  <Box sx={{ mt: 5, width: "320px" }}>
                    <FormControl variant="standard" fullWidth>
                      <FormLabel
                        sx={{
                          fontFamily: "'Inter', sans-serif",
                          mb: 1,
                          color: "rgba(0,0,0,0.8)",
                          fontWeight: "bold",
                          fontSize: "small",
                        }}
                        htmlFor="my-input"
                      >
                        Enter the OTP sent
                      </FormLabel>
                      <TextField
                        id="otp-input"
                        aria-describedby="Enter the 6 digit OTP"
                        size="small"
                        required
                        onChange={(e) => setOTPCode(e.target.value)}
                      />
                    </FormControl>
                  </Box>
                  <ZButton
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{ mt: 4, mb: 4 }}
                  >
                    {isFromLogin || isFromGoogleLogin
                      ? "Login"
                      : "Reset Password"}
                  </ZButton>
                </form>
              </div>
              {!showResetButton ? (
                <p
                  style={{
                    fontWeight: "500",
                    fontFamily: "'Inter', sans-serif",
                  }}
                >
                  Resend OTP in{" "}
                  <span style={{ fontWeight: "600", color: "#3e66fb" }}>
                    {timer}s
                  </span>
                </p>
              ) : (
                <p
                  style={{
                    fontWeight: "500",
                    fontFamily: "'Inter', sans-serif",
                  }}
                >
                  Didn't receive the OTP?{" "}
                  <Link
                    style={{ fontWeight: "600", color: "#3e66fb" }}
                    component="button"
                    onClick={onClickResetTimer}
                    underline="none"
                  >
                    Request Again
                  </Link>
                </p>
              )}
            </div>
          </ThemeProvider>
        </>
      )}
    </>
  );
};

export default FPVerifyOTP;
