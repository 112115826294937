import React from "react";

type Props = {};

const ZScheduler = ({
    color,
    width="16",
    height="16",
  }: {
    color?: string;
    width?: string;
    height?: string;
  }) => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0834 15.8346L13.7501 17.5013L17.5001 13.7513M18.321 10.4595C18.3292 10.3078 18.3334 10.1551 18.3334 10.0013C18.3334 5.39893 14.6025 1.66797 10.0001 1.66797C5.39771 1.66797 1.66675 5.39893 1.66675 10.0013C1.66675 14.5308 5.28051 18.2163 9.78216 18.3318M10.0001 5.0013V10.0013L13.1154 11.559"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ZScheduler;
