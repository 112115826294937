//@ts-nocheck
import React, { useState } from 'react'
import { EntityReference, Permission, ResourceTypeList } from '../types'
import styled from 'styled-components';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import useEntity from 'views/entities/hooks/useEntity';
import { getEntityMetadata, setEntityPermissions, setFinalEntityPermissions } from 'views/entities/reducers/entityReducers';
import { FiEdit2 as EditIcon } from "react-icons/fi";
import { TrashRIcon as DeleteIcon } from 'assests';
import notification from 'notifications/notifications';
import { getPermissionLevels, recordRSEvent } from 'utils/CommonUtils';
import ShareResourceModal from '../ShareResourceModal';
import { EntityService } from 'providers/data/services/EntityService';
import { OptionType } from '../types';
import { TbUsers as TeamIcon } from "react-icons/tb";
import { HiOutlineKey as RoleIcon } from "react-icons/hi";
import { FiUser as UserIcon } from "react-icons/fi";
import DeletionConfirmationDialog from 'utils/DeletionConfirmationDialog';
import { TablePermissionRsEvents } from 'views/entities/types';

const customStyles = {
    control: (provided: any) => ({
        ...provided,
        border: 0,
        padding: 0,
        boxShadow: 'none',
        width: 'max-content',
        minWidth: '100%',
        position: 'relative',
    }),
    menu: (provided: any) => ({
        ...provided,
        width: 'max-content',
        position: 'absolute',
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: 'rgb(48, 84, 185)',
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        color: 'rgb(48, 84, 185)',
        padding: '0',
        transform: 'scale(0.8)',
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: '0',
        fontSize: '12px',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: 'rgb(48, 84, 185)',
    })
};

const Container = styled.div`
    width: 100%
`
const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 4fr 2fr 0.5fr 0.5fr;
    border-bottom: 1px solid var(--Gray-200, #EAECF0);
    background: var(--Gray-50, #F9FAFB);
    text-align: left;
`

const TableHeading = styled.div`
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding: 24px;
    color: #475467;
`
const EntityContainer = styled.span`
    font-family: Inter;
    border-radius: 4px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    display: flex;
    padding: 3px 4px;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    gap: 3px;
`
const TableData = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 12px;
    padding: 16px 24px;
`

const TableRow = styled.div`
    display: grid;
    grid-template-columns: 4fr 2fr 0.5fr 0.5fr;
    place-items: baseline;
    border-bottom: 1px solid var(--Gray-200, #EAECF0);
    color: #344054;
`

const TableDropDown = styled.div`
    display: flex;
    gap: 5px;
    padding: 14px 24px;
`

const PermissionDisplayContainer = styled.div`
    display: block;
    height: 50vh;
    overflow-y: auto;
`

const TableCell = styled.div`
    display: flex;
    padding: 14px 24px;
`

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 0px 24px 24px 24px;
`

const IconContainer = styled.span`
    display: flex;
    font-size: 16px;
    padding: 2.667px;
    justify-content: center;
    align-items: center;
`

const Button = styled.button<{ $color: string; $background: string; $borderColor: string; }>`
    color: ${props => props.$color};
    background: ${props => props.$background};
    border: 1px solid ${props => props.$borderColor};
    font-size: 14px;
    padding: 8px 14px;
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`

type Props = {
    resourceType: ResourceTypeList;
    isEdited: boolean;
    setIsEdited: (isEdited: boolean) => void;
}

function ManageAccessTab({ resourceType, isEdited, setIsEdited }: Props) {

    const dispatch = useDispatch();
    const { entityPermissions, permissionConfiguration, selectedEntity, finalEntityPermissions } = useEntity();
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [context, setContext] = useState<"create" | "update" | "updateNew">('update');
    const [selectedSetOptions, setSelectedSetOptions] = useState<OptionType[]>([]);
    const [selectedPermissionLevel, setSelectedPermissionLevel] = useState<{ value: string; label: string; }>({ value: '', label: '' });
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [ownPermissionIndex, setOwnPermissionIndex] = useState<any>(null);

    const handlePermissionLevelChange = (selectedOption: any, actionMeta: any, index: number) => {
        const selectedPermissions = permissionConfiguration.find((permission) => permission.permissionSetName.toLowerCase() === selectedOption.value.toLowerCase())?.permissions;
        if (!selectedPermissions || selectedOption.value.toLowerCase() === entityPermissions[index].permissionSetName.toLowerCase()) {
            return;
        }
        setIsEdited(true);
        recordRSEvent(TablePermissionRsEvents.MANAGE_ACCESS_EDIT_PERMISSION_LEVEL_CHANGE, {
            resourceType: resourceType,
            permissionLevelInitial: entityPermissions[index].permissionSetName,
            permissionLevelFinal: selectedOption.value
        })
        const updatedPermissions = entityPermissions.map((permission, idx) => {
            if (idx === index) {
                return {
                    ...permission,
                    or: {
                        ...permission.or,
                        roleIds: [],
                        userIds: [],
                        teamIds: [],
                    },
                    and: {
                        ...permission.and,
                        roleIds: [],
                        userIds: [],
                        teamIds: [],
                    }
                };
            }
            if (permission.permissionSetName.toLowerCase() === selectedOption.value.toLowerCase()) {
                const mergeAndDeduplicate = (array1: EntityReference[], array2: EntityReference[]) => {
                    const combined = [...array1, ...array2];
                    const uniqueIds = new Set(combined.map(item => item.id));
                    return Array.from(uniqueIds).map(id => combined.find(item => item.id === id));
                };
                return {
                    ...permission,
                    or: {
                        ...permission.or,
                        roleIds: mergeAndDeduplicate(permission.or.roleIds || [], entityPermissions[index].or.roleIds || []),
                        userIds: mergeAndDeduplicate(permission.or.userIds || [], entityPermissions[index].or.userIds || []),
                        teamIds: mergeAndDeduplicate(permission.or.teamIds || [], entityPermissions[index].or.teamIds || []),
                    },
                    and: {
                        ...permission.and,
                        roleIds: [],
                        userIds: [],
                        teamIds: [],
                    }
                };
            }
            return permission;
        });
        recordRSEvent(TablePermissionRsEvents.MANAGE_ACCESS_PERMISSION_LEVEL_CHANGE_MERGED, {
            permissionsAfterMerge: updatedPermissions
        })
        dispatch(setEntityPermissions(updatedPermissions));
    };

    const handleEditPermission = (index: number) => {
        const permission = entityPermissions[index];
        setContext('update');

        const selectedOptions = [
            ...permission.or.roleIds.map(role => ({
                label: role.name,
                value: role.id,
                type: 'role',
            })),
            ...permission.or.userIds.map(user => ({
                label: user.name,
                value: user.id,
                type: 'user',
            })),
            ...permission.or.teamIds.map(team => ({
                label: team.name,
                value: team.id,
                type: 'team',
            })),
        ];
        setSelectedPermissionLevel({ value: permission.permissionSetName, label: permission.permissionSetName });
        setSelectedSetOptions(selectedOptions);
        recordRSEvent(TablePermissionRsEvents.MANAGE_ACCESS_EDIT_PERMISSION_BUTTON_CLICKED, {
            resourceType: resourceType,
            selectedOptions: selectedOptions,
            selectedPermissionLevel: permission.permissionSetName
        })
        setShowShareModal(true);
        recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_OPENED_IN_EDIT_MODE)
    }

    const handleSave = async () => {

        const at = localStorage.getItem('at');
        const decoded: any = !!at?.length ? jwtDecode(at) : null;

        let updatedPermission = entityPermissions.map(perm => {
            if (perm.permissionSetName.toLowerCase() === 'creator' && !perm.or.roleIds.some(role => role.id === 'admin')) {
                return {
                    ...perm,
                    or: {
                        ...perm.or,
                        roleIds: [...perm.or.roleIds, { id: 'admin', name: 'Admin' }],
                    },
                };
            }
            return perm;
        });

        const payload = {
            tablePermissions: updatedPermission.map(permission => ({
                ...permission,
                or: {
                    ...permission.or,
                    roleIds: permission.or.roleIds.map(roleId => roleId.id),
                    userIds: permission.or.userIds.map(userId => userId.id),
                    teamIds: permission.or.teamIds.map(teamId => teamId.id),
                }
            })),
            tableType: selectedEntity?.tableType,
            accountId: decoded?.accountId,
            notifyUsers: false
        };

        recordRSEvent(TablePermissionRsEvents.MANAGE_ACCESS_SAVE_BUTTON_CLICKED, {
            resourceType: resourceType,
            payload: payload
        })

        try {
            const response = await EntityService.updateEntityPermissions(payload);
            if (response) {
                dispatch(setFinalEntityPermissions(updatedPermission));
                dispatch(setEntityPermissions(updatedPermission));
                dispatch(getEntityMetadata(selectedEntity?.tableType || ""));
                recordRSEvent(TablePermissionRsEvents.MANAGE_ACCESS_SUCCESS, {
                    resourceType: resourceType,
                    response: response,
                })
                notification("success", "Permissions saved successfully");
                setIsEdited(false);
            } else {
                recordRSEvent(TablePermissionRsEvents.MANAGE_ACCESS_FAILURE, {
                    message: 'API call failed',
                })
                notification("error", "Error while deleting permissions");
            }
        } catch (error) {
            recordRSEvent(TablePermissionRsEvents.MANAGE_ACCESS_FAILURE, {
                message: 'API call failed',
                error: error
            })
            console.error(error);
            notification("error", "Error while deleting permissions");
        }
    }

    const handleCancel = () => {
        dispatch(setEntityPermissions(finalEntityPermissions));
        recordRSEvent(TablePermissionRsEvents.MANAGE_ACCESS_CANCEL_BUTTON_CLICKED, {
            resourceType: resourceType,
            valueOfPermissionAfterCancel: finalEntityPermissions,
        })
        setIsEdited(false);
    }

    const handleDeletePermission = (index: number) => {

        const at = localStorage.getItem('at');
        const decoded: any = !!at?.length ? jwtDecode(at) : null;

        if (entityPermissions[index].or.roleIds.find(role => role.id === decoded?.role) ||
            entityPermissions[index].or.userIds.find(user => user.id === decoded?.userId) ||
            entityPermissions[index].or.teamIds.some(team => decoded.teamIds.includes(team.id))) {
            setConfirmDelete(true);
            setOwnPermissionIndex(index);
            recordRSEvent(TablePermissionRsEvents.MANAGE_ACCESS_DELETE_OWN_PERMISSIONS_TRIGGERED, {
                resourceType: resourceType,
                permissionHavingCurrentUser: entityPermissions[index]
            })
            return;
        }
        else {
            proceedWithDelete(index);
        }
    };

    const proceedWithDelete = (index: number) => {

        const isAdminRolePresent = entityPermissions[index].or.roleIds.some(roleId => roleId.id === "admin");
        const updatedPermissions = entityPermissions.map((permission, idx) => {
            if (idx === index) {

                return {
                    ...permission,
                    or: {
                        ...permission.or,
                        userIds: [],
                        teamIds: [],
                        roleIds: (isAdminRolePresent && selectedPermissionLevel?.value?.toLowerCase() === "creator") ? permission.or.roleIds
                            .filter(roleId => roleId.id === "admin") : [],
                    },
                    and: {
                        ...permission.and,
                        userIds: [],
                        teamIds: [],
                        roleIds: [],
                    }
                };
            }
            return permission;
        });
        setIsEdited(true);
        recordRSEvent(TablePermissionRsEvents.MANAGE_ACCESS_DELETE_PERMISSION_BUTTON_CLICKED, {
            resourceType: resourceType,
            permissionToBeDeleted: entityPermissions[index],
            newPermissionObject: updatedPermissions
        })
        dispatch(setEntityPermissions(updatedPermissions));
    }

    const handleAddUser = () => {
        setSelectedSetOptions([])
        setSelectedPermissionLevel({ value: permissionConfiguration[0].permissionSetName, label: permissionConfiguration[0].permissionSetName })
        setContext('updateNew')
        recordRSEvent(TablePermissionRsEvents.MANAGE_ACCESS_ADD_USER_BUTTON_CLICKED, {
            resourceType: resourceType,
            context: 'updateNew',
            selectedOptions: selectedSetOptions,
            selectedPermissionLevel: selectedPermissionLevel
        })
        setShowShareModal(true)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Container>
                <TableHeader>
                    <TableHeading>Users</TableHeading>
                    <TableHeading>Permission</TableHeading>
                    <TableHeading style={{ padding: "24px 16px 24px 24px" }}></TableHeading>
                </TableHeader>
                <PermissionDisplayContainer>
                    {entityPermissions.map((permission: Permission, index) => {
                        return (
                            (permission.or.userIds.length > 0 || permission.or.teamIds.length > 0 || permission.or.roleIds.length > 0) && (
                                <TableRow key={permission.permissionSetName}>
                                    <TableData>
                                        {permission.or.teamIds.length > 0 && permission.or.teamIds.map(team => <EntityContainer key={team.id}><IconContainer><TeamIcon /></IconContainer>{team.name}</EntityContainer>)}
                                        {permission.or.userIds.length > 0 && permission.or.userIds.map(user => <EntityContainer key={user.id}><IconContainer><UserIcon /></IconContainer>{user.name}</EntityContainer>)}
                                        {permission.or.roleIds.length > 0 && permission.or.roleIds.map(role => <EntityContainer key={role.id}><IconContainer><RoleIcon /></IconContainer>{role.name}</EntityContainer>)}
                                    </TableData>
                                    <TableDropDown>
                                        <Select
                                            options={getPermissionLevels(resourceType, permissionConfiguration)}
                                            onChange={(e) => handlePermissionLevelChange(e, null, index)}
                                            isSearchable={false}
                                            menuPortalTarget={document.body}
                                            styles={{ ...customStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            placeholder={permission.permissionSetName}
                                        />
                                    </TableDropDown>
                                    <TableCell onClick={() => handleDeletePermission(index)} style={{ cursor: "pointer", padding: "24px 16px" }}>
                                        <DeleteIcon color="#475467" />
                                    </TableCell>
                                    <TableCell onClick={() => handleEditPermission(index)} style={{ cursor: "pointer", padding: "24px 16px" }}>
                                        <EditIcon />
                                    </TableCell>
                                </TableRow>
                            )
                        );
                    })}

                </PermissionDisplayContainer>
                {showShareModal && (
                    <ShareResourceModal
                        context={context}
                        initialSelectedOptions={selectedSetOptions}
                        initialPermisionLevel={selectedPermissionLevel}
                        resourceType={resourceType}
                        onClose={() => setShowShareModal(false)}
                    />
                )}
            </Container>
            <ButtonContainer>
                <Button $color='#344054' $background='#FFF' $borderColor='#344054' onClick={handleAddUser}> Add Collaborators </Button>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
                    {isEdited && <Button $color='#344054' $background='#fff' $borderColor='#344054' onClick={handleCancel}> Cancel </Button>}
                    {isEdited && <Button $color='#FFF' $background='#3054B9' $borderColor='#3054B9' onClick={handleSave}> Save </Button>}
                </div>
            </ButtonContainer>
            <DeletionConfirmationDialog
                id={"manage-access-dialog"}
                deletionTitle='Delete Permission'
                deletionText='You are about to delete your own permission. This action cannot be undone.'
                isOpen={confirmDelete}
                onConfirmDelete={() => {
                    if (ownPermissionIndex !== null) {
                        proceedWithDelete(ownPermissionIndex);
                    }
                    setConfirmDelete(false);
                }}
                onClose={() => {
                    setConfirmDelete(false);
                    setOwnPermissionIndex(null);
                }}
            />

        </div>
    )
}

export default ManageAccessTab
