import {
  type MRT_Icons,
} from 'material-react-table';
import { AiOutlineFilter } from 'react-icons/ai';
import { FaRegListAlt } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import { Drag } from "assests"
import { TiPinOutline } from "react-icons/ti";

const CustomIcons: Partial<MRT_Icons> = {
  FilterListIcon: (props: any) => < AiOutlineFilter color = { "#344054"} size = { '1.5rem'} { ...props } />,
  ViewColumnIcon: (props: any) => < FaRegListAlt color = { "#344054"} size = { '1.5rem'} { ...props } />,
  SearchIcon: (props: any) => < BsSearch color = { "#344054"} size = { '1.5rem'} { ...props } />,
  SearchOffIcon: (props: any) => < BsSearch color = { "#344054"} size = { '1.5rem'} { ...props } />,
  FilterAltIcon: (props: any) => < AiOutlineFilter color={"#344054"} size={'1.5rem'} {...props} />,
  VisibilityOffIcon: (props: any) => < FaRegListAlt color = { "#344054"} size = { '1.5rem'} { ...props } />,
  FilterListOffIcon: (prps: any) => < MdOutlineFilterAltOff color = { "#344054"} size = { '1.5rem'} { ...prps } />,
  DragHandleIcon: (props: any) => < Drag color = { "#344054"} size = { '1.5rem'} { ...props } />,
  PushPinIcon: (props: any) => < TiPinOutline color = { "#344054"} size = { '1.5rem'} { ...props } />,
};

export default CustomIcons;