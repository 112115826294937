//@ts-nocheck
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { RedocStandalone } from 'redoc';
import { recordRSEvent } from 'utils/CommonUtils';
import { ZLabel } from 'views/commonComponents/commonComponents.styles';
import notification from '../../notifications/notifications';
import { EntityService } from 'providers/data/services/EntityService';
import { useTheme } from 'styled-components';

interface TableDropdownValueType {
    value: string;
    label: string;
}

export const TableDocument: FunctionComponent = () => {
    const { tableType } = useParams();
    const [openAPISchemaJson, setOpenAPISchemaJson] = useState();
    const [tableTypes, setTableTypes] = useState<{ label: string; value: string; }[]>([]);
    const [selectedTableType, setSelectedTableType] = useState<TableDropdownValueType | undefined>({ value: "", label: "" });

    const handleChange = (value: TableDropdownValueType) => {
        recordRSEvent('get_table_api_documentation', {
            context: 'table_api_documentation',
            tableType: value.value,
        });
        setSelectedTableType(value);
    };
    useEffect(() => {
        EntityService.getEntities()
            .then((res) => {
                if (res && Array.isArray(res)) {
                    const tableTypeList = res.map((entity) => ({
                        label: entity.tableDisplayName || '', // Use a default value if tableDisplayName is missing
                        value: entity.tableType || '', // Use a default value if tableType is missing
                    }));
                    setTableTypes(tableTypeList);
                } else {
                    // Handle the case where the response or data is missing or not an array
                    console.error('Invalid or missing data in the entity list response:', res);
                    notification('error', 'Invalid entity list response');
                }
            })
            .catch((error) => {
                // Handle any network or API request error
                console.error('Error while fetching entity list:', error);
                notification('error', 'Network or API request error');
            });
    }, []);

    useEffect(() => {
        if (!tableType) {
            setSelectedTableType(tableTypes[0]);
        } else {
            const workflowType = tableTypes.find((value: TableDropdownValueType) => value.value == tableType);
            setSelectedTableType(workflowType);
        }
    }, [tableTypes])

    useEffect(() => {
        if (selectedTableType?.value) {
            const tableOpenApiResponse = EntityService.getTableAPIDocumentation(selectedTableType.value);
            tableOpenApiResponse.then((res) => {
                if (res?.code == '200') {
                    setOpenAPISchemaJson(res?.data?.staticJson)
                } else {
                    notification('error', 'Error while fetching API Documentation');
                }
            })
                .catch((err) => {
                    notification('error', 'Error while fetching API Documentation');
                });
        }
    }, [selectedTableType?.value])

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
        }),
        menu: (base: any) => ({
            ...base,
            borderRadius: 0,
            opacity: 1,
            zIndex: 100,
            marginTop: 0
        }),
        menuList: (base: any) => ({
            ...base,
            opacity: 1,
            zIndex: 100,
            padding: 0
        })
    };
    const theme = useTheme()
    return (
        <>
            <Grid className='doc-left-margin' style={{ display: 'flex', alignContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                <Grid style={{ width: 250 }}>
                    <Box sx={{ minWidth: 120, marginBottom: 1 }}>
                        <ZLabel sx={{ mb: 0.5 }}>Workflow</ZLabel>
                    </Box>
                    {
                        selectedTableType &&
                        <ReactSelect defaultValue={selectedTableType} onChange={(value: any) => { handleChange(value) }} styles={customStyles} options={tableTypes} />
                    }
                </Grid>
            </Grid>
            <RedocStandalone
                specUrl={openAPISchemaJson}
                options={{
                    downloadFileName: 'Zorp_Tables_API_Collection',
                    unstable_ignoreMimeParameters: false,
                    hideDownloadButton: false,
                    nativeScrollbars: false,
                    theme: { colors: { primary: { main: theme.color.primary } } }, labels: {
                        download: "Download Postman Collection",
                        downloadSpecification: "Postman Collection"
                    },
                }}
            />
        </>
    )
}