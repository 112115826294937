import React from "react";
import { useForm } from "react-hook-form";
import ZInput from "views/commonComponents/ZTextInput";
import styled from "styled-components";
import { CurlModal } from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents";
import { ColorPalets, Flex, Text } from "../styles";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { useDispatch } from "react-redux";
import { getEntityMetadata, initEntities, setSelectEntity, setShowCreateEntityModal, setShowEntityEditModal } from "../reducers/entityReducers";
import { AiOutlinePlus } from "react-icons/ai";
import { EntityService } from "providers/data/services/EntityService";
import notification from "notifications/notifications";
import { OnboardingIdsForComponent } from "utils/CommonUtils";
import ErrorMessage from "views/DataField/ErrorMessage";
import useQuery from "hooks/useQuery";
import { useNavigate } from "react-router-dom";
import useEntity from "../hooks/useEntity";

const Box = styled.div`
  background: #ffffff;
  display: flex;
  padding: 24px;
  flex-direction: column;
  width: ${(props: { width: string }) => props.width || "350px"};
  border-radius: 4px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
`;

const FlexContainer = styled(Flex)`
  gap: 1.5rem !important;
  margin-top: 2.5rem !important;
` 
const TextContainer = styled(Text)`
  margin-top: ${(props: { marginTop?: string }) => props.marginTop || "2.5rem"};
`

type Props = {};

const CreateEntityModal = (props: Props) => {
  const { showEditEntityModal, selectedEntity, selectedViewId, selectedEntityMetadata, recordTypeLabel } = useEntity()
  const { register, handleSubmit, formState: { errors } } = useForm(
    {
      defaultValues: {
        tableDisplayName: showEditEntityModal ? selectedEntity?.tableDisplayName : "",
        tableDescription: showEditEntityModal ? selectedEntity?.description : "",
        recordTypeLabel: showEditEntityModal ? recordTypeLabel ? recordTypeLabel : selectedEntity?.tableDisplayName : "",
      }
    }
  );
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const query = useQuery()
  
  const onSubmit = async (formInput: any) => {
    console.log(formInput)
    setLoading(true)
    try {
      if (showEditEntityModal) {
        if (!selectedEntity) return;
        const payload: { tableType: string, tableDisplayName: string, tableDescription: string, defaultView: string, recordTypeLabel?: string } = {
          tableType: selectedEntity?.tableType,
          tableDisplayName: formInput.tableDisplayName,
          tableDescription: formInput.tableDescription,
          defaultView: selectedEntity?.defaultView || "",
          recordTypeLabel: formInput?.recordTypeLabel || recordTypeLabel || selectedEntity?.tableDisplayName,
        }
        const res = await EntityService.updateTableName(payload)
        dispatch(initEntities())
        notification("success", "Table Updated Successfully")
        if (selectedEntity) localStorage.setItem("tableType", selectedEntity?.tableType)
        dispatch(setSelectEntity({ tableType: res.data.tableType, tableDisplayName: formInput.tableDisplayName, defaultView: res.data.defaultView }))
        dispatch(getEntityMetadata(selectedEntity?.tableType));
        navigate(`/table/${selectedEntity?.tableType}/view/${selectedViewId}`)
        setLoading(false)
        
        onModalClose()
      } else {
        const data = await EntityService.createEntity({ ...formInput })
        notification("success", "Table Created Successfully")
        localStorage.setItem("tableType", data?.data?.tableType)
        dispatch(setSelectEntity({ ...data?.data }))
        navigate(`/table/${data?.data?.tableType}/view/${data?.data?.defaultView}`)
        setLoading(false)
        onModalClose()
      }
    } catch (error: any) {
      notification("error", error?.response?.data?.message || "Something went wrong");
      setLoading(false)
    }
  }



  const onModalClose = () => {
    dispatch(setShowCreateEntityModal(false));
    dispatch(setShowEntityEditModal(false));
  };

  return (
    <CurlModal open={true} onClose={onModalClose}>
      <Box width="480px" as="form" onSubmit={handleSubmit(onSubmit)}>
        <TextContainer marginTop="0px" fontSize="18px" fontWeight="600" color={ColorPalets.gray700}>
          {showEditEntityModal ? "Rename table" : "Create New Table"}
        </TextContainer>

        <TextContainer marginTop="4px" fontSize="14px" fontWeight="400" color={ColorPalets.gray600}> {showEditEntityModal ? "Change the name of your table" : "Set a name for your new table"}</TextContainer>

        <TextContainer marginTop="2.5rem !important" fontSize="14px" fontWeight="400" color={ColorPalets.gray600}>
          Table Name
        </TextContainer>
        <ZInput
          autoFocus={true}
          register={register}
          name={"tableDisplayName"}
          placeholder="Table Name"
          validations={{
            required: "Table Name is required",
            maxLength: {
              value: 50,
              message: "Table Name should be less than 50 characters",
            }
          }}
        />
        <ErrorMessage errors={errors} fieldName="tableDisplayName" />
        <TextContainer marginTop="1.5rem !important" fontSize="14px" fontWeight="400" color={ColorPalets.gray600}>
          What should each record be called?
        </TextContainer>
        <ZInput
          register={register}
          name={"recordTypeLabel"}
          placeholder="Enter record name ie: Customer, Product..."
        />
        <ErrorMessage errors={errors} fieldName="recordTypeLabel" />
        <TextContainer marginTop="1.5rem !important" fontSize="14px" fontWeight="400" color={ColorPalets.gray600}>
          Description
        </TextContainer>
        <ZInput
          register={register}
          name={"tableDescription"}
          placeholder="Enter a description for internal reference"
          validations={{
            maxLength: {
              value: 250,
              message: "Description should be less than 250 characters",
            }
          }}
        />
        <ErrorMessage errors={errors} fieldName="tableDescription" />
        <FlexContainer>
          <ZButton type="button" onClick={onModalClose} sx={{ width: "50%" }} secondary variant="contained"> Cancel </ZButton>
          <ZButton id={OnboardingIdsForComponent.CREATE_NEW_TABLE_MODAL_CREATE_BUTTON} disabled={loading} type="submit" startIcon={
            <AiOutlinePlus size="1.5rem" />
          } sx={{ width: "50%" }} variant="contained">
            {showEditEntityModal ? loading ? "Renaming..." : "Rename" : loading ? "Creating..." : "Create"}
          </ZButton>

        </FlexContainer>
      </Box>
    </CurlModal>
  );
};

export default CreateEntityModal;