import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import TextField from '@mui/material/TextField';
import { styled as MuiStyled } from '@mui/system';
import { useDispatch, useSelector, useStore } from "react-redux";
import { getValueFromJson } from "utils/Utils";
import _, { cloneDeep } from "lodash";
import { LoadingButton } from '@mui/lab';
import { updateMockDataValue } from "views/automationBuilder/utils/util";

interface ISingleMockDataUpdateModal {
    openModal: string;
    handleClose: () => void;
}

const CurlModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  background: #ffffff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  position: absolute;
  top: 40%;
  left: 40%;
  border-radius: 8px;
`;

const CurlModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const CurlModalBody = styled.div`
  width: 100%;
  padding: 16px;
`;

const CurlBodyText = styled.p`
  color: #344054;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 6px;
`;

const CurlBodyLabel = styled.p`
color: #475467;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
margin-top: 6px;
`;

// Define the styled SearchBox component
export const MockDataTextField = MuiStyled(TextField)({
    // General styles
    backgroundColor: '#fff',
    width: '324px',
    // Styles for the input element
    '& .MuiInputBase-input': {
        WebkitTextFillColor: "black", // Color of the text
        fontWeight: 400, // Font weight
        fontFamily: 'Inter',
        fontSize: '14px !important', // Setting the font size to 12px
    },

    // Styles for the disabled state
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "black", // Color of the text in disabled state
        fontWeight: 400, // Font weight in disabled state
        fontSize: '12px !important', // Ensure font size is consistent in disabled state
    },

    // Styles for the root of the InputBase component
    '& .MuiInputBase-root': {
        height: '36px',
        padding: '8px 8px',
    },

    // Styles for the fieldset
    '& fieldset': {
        borderRadius: '4px',
    },
});

const SingleMockDataUpdateModal = ({ openModal, handleClose }: ISingleMockDataUpdateModal) => {
    const store = useStore();
    const dispatch = useDispatch();
    const [isMockDataUpdating, setIsMockDataUpdating] = useState(false);
    const { mockData } = useSelector((state) => state.scriptEditor);
    const mockDataValue = getValueFromJson(mockData, openModal);
    const [text, setText] = useState(mockDataValue.value);
    const textFieldRef = useRef(null);

    useEffect(() => {
        if (openModal) {
            setTimeout(() => {
                textFieldRef?.current?.focus();
            }, 150);
        }
    }, [openModal, textFieldRef]);

    const handleKeyDown = (e:any) => {
        if (e.key === 'Enter') {
            submitMockData();
        }
    }

    const submitMockData = async () => {
        setIsMockDataUpdating(true)
        const copiedMockData = cloneDeep(mockData);
        try {
            _.set(copiedMockData, openModal, text);
            await updateMockDataValue(store, dispatch, copiedMockData);
            setIsMockDataUpdating(false)
            handleClose();
        } catch (error) {
            setIsMockDataUpdating(false)
            console.error('Error updating Test data value', error);
        }
    }

    return (
        <CurlModal
            open={!!openModal}
            onClose={handleClose}>
            <CurlModalContainer>
                <CurlModalBody>
                    <CurlBodyText>Current Test Data Value</CurlBodyText>
                    <div style={{
                        display: 'flex',
                        gap: '8px'
                    }}>
                        <div style={{
                            height: '36px',
                        }}>
                            <MockDataTextField
                                placeholder={mockDataValue.value}
                                onChange={(e) => {
                                    setText(e.target.value)
                                }}
                                value={text}
                                inputRef={textFieldRef}
                                onKeyDown={handleKeyDown}
                            />
                        </div>

                        <LoadingButton
                    onClick={async (e) => {
                        submitMockData();
                    }}
                  loading={isMockDataUpdating}
                  disabled={isMockDataUpdating}
                  loadingPosition="start"
                  style={{ 
                    height: '36px',
                    cursor: isMockDataUpdating ? 'no-drop' : 'pointer',
                    boxShadow: 'none',
                    color: '#fff',
                    backgroundColor:'#3C69E7',
                    textTransform: 'none',
                    fontWeight: 600,
                    fontSize: '14px'
                  }}
                  sx={{ height: '36px' }}
                  variant="contained"
                >
                  Save
                </LoadingButton>
                    </div>
                    <div style={{
                        width: '396px'
                    }}>
                    <CurlBodyLabel>Setting Test data value for variable '{openModal}'</CurlBodyLabel>
                    </div>
                </CurlModalBody>
            </CurlModalContainer>
        </CurlModal>
    )
}

export default SingleMockDataUpdateModal;