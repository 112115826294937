//@ts-nocheck
import React, {
  cloneElement,
  createElement,
  useState,
  useCallback,
  memo
} from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IEmbedLink, setSelectedEmbedLink } from "./reducer/menuSlice";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkActiveUrl } from "utils/CommonUtils";
import { hasPermission } from "providers/auth/authUtils";
import {
  setSelectEntity,
  setShowCreateEntityModal,
} from "views/entities/reducers/entityReducers";
import { BsChevronDown } from "react-icons/bs";
import { useParams } from "react-router-dom";
import usePorterPermissions from "hooks/usePorterPermissions";
import { TableIcon } from "assests";
import ZTableIcon from "assests/icons/ZTableIcon";
import ZAlertIcon from "assests/icons/ZAlertIcon";
import useAccessPermissions from "hooks/useAccessPermissions";
import { ResourceTypeConstant } from "views/entities/permissions/types";
import useEntity from "views/entities/hooks/useEntity";

interface CustomMenuItemProps {
  active: boolean;
}

const CustomMenuItem = styled.div<CustomMenuItemProps>`
  &:hover {
    background-color: ${(props: any) =>
      props.active
        ? props.theme.color.primary // Active state color
        : "#EAECF0"}; // Non-hover, non-active state color
    border-radius: 4px;
    cursor: pointer;
  }
  background-color: ${(props: any) =>
    props.active
      ? props.theme.color.primary // Active state color
      : "#F2F4F7"};
  color: ${(props: any) =>
    props.active
      ? "#F2F4F7" // Active state color
      : "#1D2939"};
  border-radius: 4px;
  padding: 0px 0px 0px 6px; // list main padding
`;

const DropdownSingleMenuItem = styled.div`
  width: 100%;
  display: flex;
  padding: 6px 8px; // list child padding
  gap: 6px;
  align-items: center;
  justify-content: flex-start;
  div {
    font-weight: 500;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

type Props = {
  menuId: string;
  icon: any;
  text: string;
  dropdownItems: IEmbedLink[];
  classes: any;
  menuItemClass?: any;
  onClick: any;
  open: boolean;
  isMobile?: boolean;
  allowCreate?: boolean;
};

const MenuItemWithDropdown = ({
  menuId,
  icon,
  text,
  dropdownItems,
  classes,
  menuItemClass,
  onClick,
  open,
  isMobile,
  allowCreate = true,
}: Props) => {
  const [shoWDropdown, setShowDropdown] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const porterPermissions = usePorterPermissions();
  const { selectedEntity } = useEntity();

  const activeTableType = useCallback((url: string) => {
    const currentUrl = window.location.href;
    let host = currentUrl.split("/view")[0];
    if (host.includes("/create")) {
      host = host.replace("/create", "");
    }
    const toBeCheckedUrl = url.split("/view")[0];
    if (host.includes(toBeCheckedUrl)) {
      return true;
    }
    return false;
  }, [])

  const handleMenuTap = useCallback(
    (e, isEmbed, to, embedUrl, data) => {
      const isShowPage = window.location.href.includes("/show");
      const isTableSelected = window.location.href.includes("/table");
      if (
        isTableSelected &&
        selectedEntity?.tableType === data?.tableType &&
        !isShowPage
      )
        return;
      if (embedUrl) {
        dispatch(setSelectedEmbedLink(embedUrl));
        navigate(to);
      } else {
        dispatch(
          setSelectEntity({
            tableType: data.tableType,
            tableDisplayName: data.tableDisplayName,
            defaultViewId: data.defaultViewId,
          })
        );
        navigate(to);
        // set tableType to local storage
        localStorage.setItem("tableType", data.tableType);
      }
      // localStorage.setItem('reportUrl', to);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuId, icon, dispatch]
  );

  return (
    <div
      style={{
        padding: isMobile || !open ? "0px 4px 0px 4px" : "0px 4px 0px 4px", // List Item Padding
        cursor: "pointer",
      }}
    >
      {isMobile ? (
        <div style={{ width: "100%", color: "white" }}>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {menuId === "alertsMenu" ? (
              <></>
            ) : // check if icon is a react element
            typeof icon === "object" ? (
              cloneElement(icon)
            ) : (
              { icon }
            )}
          </div>

          <div
            style={{
              marginTop: "0.5rem",
              fontSize: 13,
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {text}
          </div>
        </div>
      ) : open ? (
        <>
          <div>
            <DropdownSingleMenuItem
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <div>
                    {menuId === "alertsMenu" ? (
                      <></>
                    ) : // check if icon is a react element
                    typeof icon === "object" ? (
                      <BsChevronDown size={"8px"} />
                    ) : (
                      <BsChevronDown size={"8px"} />
                    )}
                  </div>

                  <div style={{ fontSize: "12px", fontWeight: "600" }}>
                    {text}
                  </div>
                </div>
                <div>
                  {allowCreate &&
                    (menuId === "entityMenu"
                      ? hasPermission("zorptables", "create")
                        ? true
                        : false
                      : true) &&
                    porterPermissions.checkPermissionsForPorter(
                      "dummy",
                      "create_table"
                    ) && (
                      <AiOutlinePlus
                        onClick={(e) => {
                          e.preventDefault();
                          if (menuId === "reports") {
                          navigate("/chronos");
                          } else if (menuId === "monitors" || menuId === "alerts") {
                          navigate("/monitor");
                          } else {
                            dispatch(setShowCreateEntityModal(true));
                          }
                        }}
                        size="16px"
                      />
                    )}
                </div>
              </div>
            </DropdownSingleMenuItem>
            <div>
              {shoWDropdown &&
                dropdownItems.map((item: IEmbedLink) => (
                  <CustomMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMenuTap(
                        e,
                        item.isEmbed,
                        item.to,
                        item.embedUrl,
                        item.data
                      );
                    }}
                    active={activeTableType(item.to)}
                  >
                    <DropdownSingleMenuItem key={item.embedUrl}>
                      <div>
                        {typeof item?.icon === "object"
                          ? cloneElement(item?.icon)
                          : createElement(item?.icon, {
                              size: "16px",
                            })}
                      </div>
                      <div
                        style={{
                          width: '90%',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                        title={item.key}
                      >
                        <span 
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            lineHeight: "16px",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >{item.key}</span>    
                      </div>
                    </DropdownSingleMenuItem>
                  </CustomMenuItem>
                ))}
            </div>
          </div>
        </>
      ) : (
        <div style={{ width: "100%" }}>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {menuId === "alertsMenu" ? (
              <></>
            ) : // check if icon is a react element
            typeof icon === "object" ? (
              <ZTableIcon /> // This line of code hinders the sidebar menu icons for tables, alerts, and BI when sidebar is minimized
            ) : (
              <ZAlertIcon />
            )}
          </div>

          <div
            style={{
              marginTop: "0.5rem",
              fontSize: 12,
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {text}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(MenuItemWithDropdown);
