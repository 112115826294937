import { TextField } from "@mui/material";
import React, { FunctionComponent, useEffect } from "react";
import {
  UseFormRegister,
  FieldErrors,
  Control,
  UseFormSetValue,
  useFieldArray,
  UseFormGetValues,
  useForm,
  UseFormSetError,
  UseFormClearErrors,
} from "react-hook-form";
import { IZLinks } from "./IZLinks";
import { Info } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "views/DataField/DataField.styles";
import InfoIcon from "views/workflows/Info/InfoIcon";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { RiAddBoxLine } from "react-icons/ri";
import { AiOutlineWarning } from "react-icons/ai";
import { ColSpan4, TaskFormLabelForCreateAndEdit } from "render-engine/render.styles";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { useDispatch } from "react-redux";
import { setTaskData } from "render-engine/reducers/show/TaskShowReducer";
import SingleChip from "components/listOfText/SingleChip";
import { isValidUrl } from "utils/CommonUtils";
import notification from "notifications/notifications";
import { options } from "joi";

export const ZLinks: FunctionComponent<{
  config: IZLinks;
  onChange: Function;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  control: Control;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
}> = ({
  config,
  onChange,
  register,
  errors,
  setValue,
  getValues,
  control,
  setError,
  clearErrors,
}) => {
  // Construct validation
  const validation: any = {};

  const {
    register: localRegister,
    setValue: localSetValue,
    getValues: localGetValues,
    handleSubmit: localHandleSubmit,
  } = useForm();

  const { convertDataTypeToString } = useTaskShow();
  const dispatch = useDispatch();
  // const [firstLoad, setFirstLoad] = useState(true);

  // useEffect(() => {
  //   setFirstLoad(false);
  // }, []);

  const value = convertDataTypeToString(config.key, false, []);

  const { append, remove } = useFieldArray({
    control,
    name: config.key,
  });

  if (config.isRequired) validation.required = `required`;

  if (config.maxChars) validation.maxLength = config.maxChars;

  // if (config.shape.toUpperCase() === 'NUMBER')
  //     validation.pattern = /^\d+$/;

  useEffect(() => {
    if (Array.isArray(config?.value)) {
      //@ts-ignore
      config?.value.forEach((item: string) => append(item));
    }
    // validateField();
  }, []);

  let isVisible = true;
  if (config.hasOwnProperty("isVisible")) {
    if (
      (typeof config.isVisible === "string" ||
        config.isVisible instanceof String) &&
      config.isVisible.toLowerCase().trim() === "false"
    )
      isVisible = false;
    else if (typeof config.isVisible === "boolean")
      isVisible = config.isVisible;
    else if (typeof config.isVisible === "function")
      isVisible =
        config.isVisible() === "true" || config.isVisible() === true
          ? true
          : false;
  }

  const urlRegex = new RegExp(
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );

  const addItem = () => {
    const optionToAdd = localGetValues()[config.key];
    if (optionToAdd && !urlRegex.test(optionToAdd)) {
      notification("error", "Invalid URL", "Please enter a valid URL");
      return;
    }
    if (optionToAdd) {
      append(optionToAdd);
      const newValue = [...value, optionToAdd];
      dispatch(
        setTaskData({
          nodeId: config.key,
          data: newValue,
        })
      );
    }
    localSetValue(config.key, "");
  };

  const deleteItem = (index: number) => {
    remove(index);
    dispatch(
      setTaskData({
        nodeId: config.key,
        data: value.filter((item: string, i: number) => i !== index),
      })
    );
    setTimeout(() => {
      validateField();
    }, 1000);
  };

  const handleSubmitWithoutPropagation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    localHandleSubmit(addItem)();

    /**
     * We are using timoeout to update the error message in realtime
     * firstly, RHF state will get filled with the value and then the error message will be updated
     */
    setTimeout(() => {
      validateField();
    }, 1000);
  };

  const tooltipContent = ["Type single value and press enter or click on ADD"];

  /**
   * function to validate if there is any value in the LOT field
   */
  const validateField = () => {
    if (config.isRequired && !getValues()[config.key]?.length) {
      setError(config.key, {
        type: "manual",
        message: "This is a required field",
      });
    } else {
      clearErrors(config.key);
    }
  };

  if (isVisible)
    return (
      <>
         <ColSpan4 as="form">
          {config?.label?.isVisible && (
            <TaskFormLabelForCreateAndEdit
              fontSize={config?.label?.fontSize || 14}
              color={config?.label?.color || "black"}
              className="col-span-1 font-medium"
            >
              {config?.label?.val}
              {config?.isRequired && <span className="text-red-500">*</span>}
            </TaskFormLabelForCreateAndEdit>
          )}
          <div className="grid">
            <div style={{width:"100%"}}>
              <TextField
                className="w-full"
                // multiline={config.shape.toLowerCase() !== "number"}
                // type={config.shape.toLowerCase()}
                // defaultValue={config.value}
                type={"text"}
                sx={{ backgroundColor: "white" }}
                {...localRegister(`${config.key}`, validation)}
                id={config?.key}
                variant="outlined"
                placeholder="Enter your link"
                onBlur={validateField}
                inputProps={{
                  step: "any",
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ padding: "0px" }}
                      type="submit"
                      onClick={(e) => handleSubmitWithoutPropagation(e)}
                      className="list_of_text_add_button"
                    >
                      <RiAddBoxLine
                        // type="submit"
                        // variant="contained"
                        size={"2rem"}
                        color="#cccccc"
                      />
                    </IconButton>
                  ),
                }}
              />

              {errors[config.key]?.message?.length > 0 && (
                <div className="font-semibold text-xs text-red-500 mt-1">
                  <AiOutlineWarning
                    size={"1rem"}
                    color="red"
                    style={{ display: "inline", marginRight: "4px" }}
                  />{" "}
                  {errors[config.key]?.message}
                </div>
              )}
            </div>
          </div>
          <div className="mt-2">
            {value?.map((item: string, index: number) => (
              <SingleChip
                index={index}
                handleDelete={deleteItem}
                label={item}
                key={index}
              />
            ))}
          </div>
        </ColSpan4>
      </>
    );
  else return <div></div>;
};
