import React from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import ZEmptyAsset from "./ZEmptyAsset.json";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { useNavigate } from "react-router-dom";
import {
  getCreatePath,
  getEmptyScreenMessage,
  ZResources,
} from "./emptyScreenUtils";

const Quote = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;

  p {
    span {
      font-size: 14px;
      font-weight: 600;
      color: black;
    }
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
    width: 25%;
    text-align: center;
    margin-top: 1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

const Image = styled.div`
  height: inherit;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

  div > svg {
    height: 50vh !important;
  }
`;

const EmptyScreen = (props: any) => {
  const navigate = useNavigate();
  return (
    <div>
      <Image>
        <Lottie
          style={{ display: "flex", justifyContent: "center" }}
          loop={true}
          autoplay={true}
          animationData={ZEmptyAsset}
        />
      </Image>
      <Quote>
        <p>
          <span>{getEmptyScreenMessage(props.basePath)}</span>
        </p>
      </Quote>
      <ButtonContainer>
        <ZButton variant="contained" onClick={
          () => {
            if(props.basePath === ZResources.TASK) { 
              props.callback()
            } else {
              navigate(getCreatePath(props.basePath))
            }
            
            }}>
            Create
        </ZButton>
      </ButtonContainer>
    </div>
  );
};

export default EmptyScreen;
