import notification from "notifications/notifications";
import { EntityService } from "providers/data/services/EntityService";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ZInput from "views/commonComponents/ZTextInput";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import useTaskShow from "views/workflows/hooks/useTaskShow";
import { GPTDiv } from "views/workflows/workflowactions/api/APIAction/ImportCurl/CurlStyledComponents";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  initRecordCreateMetaData,
  initRecordShowMetaData,
  initTaskCreateMetaData,
  initTaskShowMetaData,
} from "render-engine/reducers/show/TaskShowReducer";
import { isEmpty } from "lodash";
import SelectTeamDropdown from "./SelectTeamDropdown";
import { TeamService } from "providers/data/services/TeamService";
import SelectRoleDropdown from "./SelectRoleDropdown";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog";
import { TrashRIcon } from "assests";
import { useNavigate } from "react-router-dom";
import { BuilderService } from "providers/data/services/BuilderService";
import useTaskCreation from "views/workflows/hooks/useTaskCreation";
// import { Close } from '@mui/icons-material';
// import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

type Props = {
  editTabDetails: any;
  onCancel: () => void;
};

const Wrapper = styled(GPTDiv)`
  padding: 16px 24px;
  height: auto;
  width: 32vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Heading = styled.div`
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 16px;
`;

const Label = styled.div`
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 8px;
`;

// const ZContainInput = styled.div`
//   border-radius: 8px;
//   border: 1px solid #D0D5DD;
//   background: #FFF;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 8px 14px;
// `

// const SelectAccess = styled(Label)`
//   margin-top: 16px;
//   margin-bottom: 8px;
// `;

// const DropdownWrapper = styled.div`
//   margin-bottom: 16px;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
// `;

// const DropdownList = styled.div`
//   width: 100%;
//   border: 1px solid #ccc;
//   border-radius: 8px;
//   margin-top: 8px;
//   background: #fff;
//   z-index: 1;
// `;

// const DropdownItem = styled.div`
//   padding: 8px;
//   cursor: pointer;
//   font-size: 12px;
//   color: #344054;
// `;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;

// const ZTagButton = styled(ZButton)`
//   border-radius: 6px !important;
//   border: 1px solid #D0D5DD !important;
//   padding: 4px 8px !important;
//   background: #FFF !important;
//   color: #344054 !important;
//   text-align: center !important;
//   font-size: 12px !important;
//   font-weight: 500 !important;
//   `;

const CancelButton = styled(ZButton)`
  margin-right: 8px;
  background-color: #ffffff !important;
  color: #344054 !important;
  border: 1px solid #d0d5dd !important;
  padding: 7px 14px !important;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CreateTabModal = ({ editTabDetails, onCancel }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      label: editTabDetails?.label || "",
    },
  });
  const { taskShowLayoutMetadata } = useTaskShow();
  const { taskType: taskTypeFromHook } = useTaskCreation({});
  const { recordId, tableType, taskType, taskId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState([]);
  const [teams, setTeams] = useState<any>([]);
  const [showDelete, setShowDelete] = useState(false);
  const [dropdownItems, setDropdownItems] = useState([
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 4",
  ]);

  const fetchTeams = async () => {
    try {
      const res = await TeamService.listTeams({
        pagination: {
          page: 1,
          perPage: 100,
        },
        filter: {},
      });
      setTeams(res?.data?.teams || []);
    } catch (error) {
      console.log("error", error);
      setTeams([]);
    }
  };

  const handleDelete = async () => {
    const mode = window.location.href.includes("create") ? "create" : "show";
    const resource = window.location.href.includes("task") ? "task" : "table";
    try {
      const newTabs = taskShowLayoutMetadata.tabs.filter(
        (tab: any) => tab.tabId !== editTabDetails.tabId
      );
      const newTaskShowLayoutMetadata = {
        ...taskShowLayoutMetadata,
        tabs: newTabs,
      };
      if (resource === "task") {
        await BuilderService.updateTaskRenderLayout({
          taskType: taskTypeFromHook || taskType,
          mode,
          layout: newTaskShowLayoutMetadata,
        });
        notification("success", "Success", "Tab deleted successfully");
        onCancel();
        if (mode === "show") {
          dispatch(
            initTaskShowMetaData({
              taskId,
            })
          );
        } else {
          dispatch(
            initTaskCreateMetaData({
              taskType: taskTypeFromHook || taskType,
            })
          );
        }
        if (mode === "create") {
          navigate(`/task/${tableType}/create`);
        } else {
          navigate(`/task/${taskType}/show/${taskId}`);
        }
      } else {
        await EntityService.updateTableLayout({
          tableType,
          mode,
          layout: newTaskShowLayoutMetadata,
        });
        notification("success", "Success", "Tab deleted successfully");
        onCancel();
        if (mode === "show") {
          dispatch(
            initRecordShowMetaData({
              tableType,
              recordId,
            })
          );
        } else {
          dispatch(
            initRecordCreateMetaData({
              tableType,
            })
          );
        }
        if (mode === "create") {
          navigate(`/table/create/${tableType}`);
        } else {
          navigate(`/table/${tableType}/show/${recordId}`);
        }
      }
    } catch (error) {
      console.log(error);
      notification("error", "Error", "Error while deleting tab");
    }
  };

  const onSubmit = async (data: any) => {
    const mode = window.location.href.includes("create") ? "create" : "show";
    const resource = window.location.href.includes("task") ? "task" : "table";
    try {
      const newTab = {
        label: data.label,
        allowedTeams: data.allowedTeams.map((team: any) => team.value),
        isDeleted: false,
        isDefault: false,
        sections: [],
      };

      // check if editTabDetails is present
      if (editTabDetails) {
        // clone taskShowLayoutMetadata
        const tempMetadata = { ...taskShowLayoutMetadata };
        // edit tab
        const newTabs = tempMetadata.tabs.map((tab: any) => {
          if (tab.tabId === editTabDetails.tabId) {
            const newTab = { ...tab };
            newTab.label = data.label;
            newTab.allowedTeams = data.allowedTeams.map(
              (team: any) => team.value
            );
            return newTab;
          }
          return tab;
        });
        const newTaskShowLayoutMetadata = {
          ...tempMetadata,
          tabs: newTabs,
        };
        if (resource === "table") {
          await EntityService.updateTableLayout({
            tableType,
            mode,
            layout: newTaskShowLayoutMetadata,
          });
          notification("success", "Success", "Tab created successfully");
          onCancel();
          if (mode === "show") {
            dispatch(
              initRecordShowMetaData({
                tableType,
                recordId,
              })
            );
          } else {
            dispatch(
              initRecordCreateMetaData({
                tableType,
              })
            );
          }
          if (mode === "create") {
            navigate(`/table/create/${tableType}`);
          } else {
            navigate(`/table/${tableType}/show/${recordId}`);
          }
          return;
        } else if (resource === "task") {
          await BuilderService.updateTaskRenderLayout({
            taskType: taskTypeFromHook || taskType,
            mode,
            layout: newTaskShowLayoutMetadata,
          });
          notification("success", "Success", "Tab created successfully");
          onCancel();
          if (mode === "show") {
            dispatch(
              initTaskShowMetaData({
                taskId,
              })
            );
          } else {
            dispatch(
              initTaskCreateMetaData({
                taskType: taskTypeFromHook || taskType,
              })
            );
          }
          if (mode === "create") {
            navigate(`/task/${taskType}/create`);
          } else {
            navigate(`/task/${taskType}/show/${taskId}`);
          }
          return;
        }
      }

      const newTabs = [...taskShowLayoutMetadata.tabs, newTab];
      const newTaskShowLayoutMetadata = {
        ...taskShowLayoutMetadata,
        tabs: newTabs,
      };
      if (resource === "table") {
        await EntityService.updateTableLayout({
          tableType,
          mode,
          layout: newTaskShowLayoutMetadata,
        });
        notification("success", "Success", "Tab created successfully");
        onCancel();
        if (mode === "show") {
          dispatch(
            initRecordShowMetaData({
              tableType,
              recordId,
            })
          );
        } else {
          dispatch(
            initRecordCreateMetaData({
              tableType,
            })
          );
        }
      } else {
        await BuilderService.updateTaskRenderLayout({
          taskType,
          mode,
          layout: newTaskShowLayoutMetadata,
        });
        notification("success", "Success", "Tab created successfully");
        onCancel();
        if (mode === "show") {
          dispatch(
            initTaskShowMetaData({
              taskId,
            })
          );
        } else {
          dispatch(
            initTaskCreateMetaData({
              taskType,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
      notification("error", "Error", "Error while creating tab");
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  // Adding and removing values from dropdown.

  // const handleAddValue = (value) => {
  //   setSelectedValues([...selectedValues, value]);
  //   setDropdownItems(dropdownItems.filter((item) => item !== value));
  // };

  // const handleRemoveValue = (value) => {
  //   setSelectedValues(selectedValues.filter((item) => item !== value));
  //   setDropdownItems([...dropdownItems, value]);
  // };

  return (
    <Wrapper>
      <Container>
        <Heading>
          {!isEmpty(editTabDetails) ? "Manage tab" : "Create new tab"}
        </Heading>

        {/* <Label>Roles</Label>
          <SelectRoleDropdown defaultValue={editTabDetails?.allowedRoles || []} control={control}  roles={roles} name="allowedRoles" /> */}
        <Label>Name</Label>
        <ZInput
          autoFocus
          validations={{
            required: "Name is required",
            minLength: {
              value: 3,
              message: "Name should be at least 3 characters",
            },
          }}
          placeholder="Name"
          name="label"
          register={register}
        />
        {errors.label && (
          <div className="mt-1" style={{ fontSize: "12px", color: "red" }}>
            {errors.label.message}
          </div>
        )}
        <Label className="mt-2">Tab Visible for</Label>
        <SelectTeamDropdown
          defaultValue={editTabDetails?.allowedTeams || []}
          control={control}
          teams={teams}
          name="allowedTeams"
        />

        {/* Conditional drop down menu, selecting an item will add it to the input field.*/}
        {/* <SelectAccess>Who can view:</SelectAccess>
        <DropdownWrapper>
          {selectedValues.length > 0 && (
            <ZContainInput>
              <div style={{ gap: '8px', display: 'flex', flexWrap: 'wrap' }}>
                {selectedValues.map((value) => (
                  <ZTagButton variant="contained" onClick={() => handleRemoveValue(value)}>
                    {value} <Close style={{ fontSize: '16px', color: '#98A2B3', marginRight: '0px' }} />
                  </ZTagButton>
                ))}
              </div>
              <ArrowDropDownIcon style={{ color: "#3C69E7", width: "16px", height: "16px" }} />
            </ZContainInput>
          )}
          {dropdownItems.length > 0 && (
            <DropdownList>
              {dropdownItems.map((item) => (
                <DropdownItem key={item} onClick={() => handleAddValue(item)}>
                  {item}
                </DropdownItem>
              ))}
            </DropdownList>
          )}
              </DropdownWrapper>*/}
        <div style={{ marginBottom: "32px" }} />
      </Container>

      <ActionsWrapper>
        <div>
          {!isEmpty(editTabDetails) && !editTabDetails.isDefault && (
            <div onClick={() => setShowDelete(true)}>
              <TrashRIcon color="#B42318" />
            </div>
          )}
        </div>
        <Flex style={{ gap: '8px'}}>
          <div>
            <ZButton secondary variant="contained" onClick={onCancel}>
              {" "}
              Cancel{" "}
            </ZButton>
          </div>
          <ZButton
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            // style={{
            //   background: "#3C69E7",
            //   border: "#3C69E7",
            //   boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            //   padding: "8px 14px",
            //   cursor: "pointer",
            // }}
          >
            Save
          </ZButton>
        </Flex>
      </ActionsWrapper>
      <DeletionConfirmationDialog
        id={"subfieldRow"}
        deletionTitle={"Delete this tab?"}
        deletionText={"Do you want to delete this tab? This cannot be undone."}
        isOpen={showDelete}
        onConfirmDelete={() => handleDelete()}
        onClose={() => setShowDelete(false)}
      />
    </Wrapper>
  );
};

export default CreateTabModal;
