import React from "react";
import { ZDropdown } from "./commonComponents.styles";
import { Controller } from "react-hook-form";
import { HookFieldPropType } from "./interfaces/hookComponents.types";
import Select from "react-select";

const ZSelect = ({
  control,
  name,
  options,
  defaultValue,
  styles = {},
  components = {},
  required,
}: HookFieldPropType) => {

  const customStyles = {
    container: (provided) => ({ ...provided, width: "100%", marginTop: "12px"}),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "black",
        fontSize: "14px",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        border: "1px solid #D0D5DD",
        fontSize: "12px",
        borderRadius: "6px",
        backgroundColor: "none", // change background color of the pill
      };
    },
    multiValueLabel: (styles) => {
      return {
        ...styles,
        fontSize: "12px",
        color: "#101828", // change text color inside the pill
      };
    },
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px", // added fontSize here
      marginRight: "3px",
      marginLeft: "0px",
      boxSizing: "border-box",
      marginBottom: "0px",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "14px !important",
    }),

  };

  return (
    <Controller
      render={({ field }) => (
        <>
          {/* <ZDropdown
          styles={styles}
          {...field}
          className='react-select'
          classNamePrefix='react-select'
          placeholder=''
          options={options}
          components={components}
          // onChange={(prop:any) => {
          //   field.onChange(prop.value);
          // }}
          // value={field?.value?.value}
          // defaultValue={defaultValue}
        /> */}
          <Select
            {...field}
            styles={customStyles}
            className="react-select"
            classNamePrefix="react-select"
            placeholder=""
            options={options}
            components={components}
            // onChange={(prop:any) => {
            //   field.onChange(prop.value);
            // }}
            // value={field?.value?.value}
            // defaultValue={defaultValue}
          />
        </>
      )}
      name={name}
      control={control}
      rules={required ? { required: "This field is required" } : {}}
    />
  );
};

export default ZSelect;
