import * as React from 'react';
import { Fragment } from 'react';
import { Button, CircularProgress } from '@mui/material';
import {
    useListContext,
    useRefresh,
    useUnselectAll,
} from 'react-admin';
import { ModifyTeamUser } from '../screens/task/edit/ModifyTeamUser';

const BulkUpadateTeamActionButton = () => {
    const {
        resource,
        selectedIds
    } = useListContext();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll();
    const [executing, setExecuting] = React.useState(false);
    const [openTeamUserChange, setOpenTeamUserChange] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false)
    // const [updateMany, { loading }] = useUpdateMany(
    //     'posts',
    //     selectedIds,
    //     { views: 0 },
    //     {
    //         onSuccess: () => {
    //             refresh();
    //             notify('Posts updated');
    //             unselectAll('posts');
    //         },
    //         onFailure: error => notify('Error: posts not updated', { type: 'warning' }),
    //     }
    // );

    // React.useEffect(() => {
    //     alert("S :" + selectedIds);
    //     setSelIds(selectedIds);

    // }, [selectedIds]);


    const callBack = () => {
        unselectAll(resource);
        refresh();
        setExecuting(false);
    }

    const handleUpdateTeamUserClick = () => {
        setOpenTeamUserChange(true);
        setExecuting(true);
    }


    const closeModification = () => {
        setOpenTeamUserChange(false);
        setDialogOpen(false);
    }

    return (
        <Fragment>
            <Button label="Change Team/User" disabled={executing} onClick={handleUpdateTeamUserClick}>
                {executing ? <CircularProgress size={14} /> : 'Change Team/User'}
            </Button>
            {openTeamUserChange && <ModifyTeamUser
                      taskId={Object.values(selectedIds)}
                      onClose={closeModification}
                      callBack={callBack}
                  />}
        </Fragment>
    );
}

export default BulkUpadateTeamActionButton;