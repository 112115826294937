//@ts-nocheck
import { TextField } from "@mui/material"
import React, { FunctionComponent, useState, useEffect } from "react"
import { UseFormRegister, FieldErrors } from "react-hook-form"
import { ComponentType } from "../ComponentTypeEnum"
import TextareaAutosize from "@mui/material/TextareaAutosize"
import { IZTextAreaInterface } from "./IZTextAreaInterface"
import { IZComponentPropType } from "render-engine/models/ITaskTypeWebBodyRenderModel"
import _, { get } from "lodash"
import { AiOutlineWarning } from "react-icons/ai"
import { ColSpan4, HelperText } from "render-engine/render.styles"
import {
  setInEditState,
  setTaskData,
} from "render-engine/reducers/show/TaskShowReducer"
import { useDispatch } from "react-redux"

export const ZTextArea: FunctionComponent<IZComponentPropType> = ({
  config,
  onChange,
  register,
  errors,
  setValue,
  getValues,
}) => {
  // Construct validation
  const dispatch = useDispatch()
  const validation: any = {}
  let validate: any = () => {}
  if (config.required) validation.required = `required`

  if (config.maxChars) validation.maxLength = config.maxChars

  if (config?.validate) {
    validate = config.validate
  }

  // const handleBlur = () => {
  //   console.log("handleBlur", getValues())
  //   const curValue = getValues()
  //   dispatch(setInEditState({ nodeId: config.key, inEditState: false }))
  //   // key can be location.address or location.latitude or location.longitude
  //   // get the main key and set the value
  //   const mainKey = config.key.split(".")[0]
  //   dispatch(setTaskData({ nodeId: mainKey, data: get(curValue, mainKey) }))
  //   // dispatch(
  //   //   setTaskData({ nodeId: config.key, data: get(curValue, config.key) })
  //   // )
  // }

  useEffect(() => {
    if (config.value) {
      setValue(config.key, config.value)
    }
  }, [])

  return (
    <>
      <ColSpan4>
        <HelperText>
          {" "}
          You can provide either address or latitude and longitude{" "}
        </HelperText>
        <ColSpan4>
          {config?.label?.isVisible && (
            <div className="col-span-1 font-medium mb-1 mt-2">
              {config?.label?.val}{" "}
            </div>
          )}

          <TextareaAutosize
            aria-label=""
            placeholder=""
            {...register(`${config.key}`, { validate })}
            // minRows={config.maxLines}
            // maxRows={config.maxLines}
            style={{
              width: "100%",
              border: "1px solid #c2c9d1",
              borderRadius: "4px",
              padding: "6px 8px",
            }}
            defaultValue={config.value}
            // onBlur={handleBlur}
          />
          {/* <TextField className="w-full" type={config.shape.toLowerCase()} defaultValue={config.value} maxRows={config.maxLines} {...register(`${config.nodeId}`, validation)} id={config?.nodeId} variant="outlined" onChange={handleChange} /> */}
          {errors[config.key]?.message?.length > 0 && (
            <div className="font-semibold text-xs text-red-500 mt-1">
              <AiOutlineWarning
                size={"1rem"}
                color="red"
                style={{ display: "inline", marginRight: "4px" }}
              />{" "}
              {errors[config.key]?.message}
            </div>
          )}
          {config?.key?.includes(".") ? (
            get(errors, config.key)?.message && (
              <div className="font-semibold text-xs text-red-500 mt-1">
                <AiOutlineWarning
                  size={"1rem"}
                  color="red"
                  style={{ display: "inline", marginRight: "4px" }}
                />{" "}
                {get(errors, config.key)?.message}
              </div>
            )
          ) : (
            <p></p>
          )}
        </ColSpan4>
        </ColSpan4>
    </>
  )
}
