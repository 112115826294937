//@ts-nocheck
// This will render If Configure Workflow Settings is opened in Right Side
import { useJSEditor } from 'lowcode/hooks/useJSEditor';
import { setScriptEditorPayload } from 'lowcode/state/scriptOpenState';
import { ZJSEditorLayout } from 'lowcode/ZJSEditorLayout';
import { useCallback, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useForm } from 'react-hook-form';
import { AiOutlineDown, AiOutlineLeft } from 'react-icons/ai';
import { useDispatch, useStore } from 'react-redux';
import styled from 'styled-components';
import usePayload from "views/automationBuilder/hooks/usePayload";
import { recordRSEvent } from 'utils/CommonUtils';
import { getLowCodeEditorDefaultReturnText, getLowCodeEditorText } from 'utils/Utils';
import { ZButton } from 'views/commonComponents/commonComponents.styles';
import { SystemTransitionLabel } from 'views/DataField/DataField.styles';
import ErrorMessage from 'views/DataField/ErrorMessage';
import useWorkflow from 'views/workflows/hooks/useWorkFlow';
import {
  openTaskCardConfig, setAppTaskCreateConfig,
  setFlutterTaskCardConfig, setTaskTypeNameDescription
} from 'views/workflows/reducers/workflowAction';
import AppTaskCreateLabel from './AppTaskCreateLabel';
import { ABMetadataTypeEnum } from 'views/automationBuilder/types/ABType';
import { closeAutomation, setTaskCreateScreenAutomation, setTaskHistoryScreenAutomation } from 'views/automationBuilder/reducer/automationReducer';
import { saveOnLoadAutomation } from 'views/automationBuilder/saveHandlers/saveOnLoadAutomation';
import ConfigureAutomation from '../ConfigureAutomation';
import { saveSmartComponentAutomation } from 'views/automationBuilder/saveHandlers/saveSmartComponentAutomation';
import useAutomation from 'views/automationBuilder/hooks/useAutomation';


export interface IShowTaskCardArgs {
  isHistory: boolean,
  isTaskCreate: boolean,
  isTaskHistoryScreen: boolean,
}

const Container = styled.div`
  background-color: white;
  border-bottom: 0px;
  padding: 20px;
  padding-top: 0px;
`;

const Label = styled.p`
  margin-top: 20px !important;
  font-size: 12px;
  font-weight: 600;
`;

const Input = styled.input`
  margin-top: 16px;
  border: 1px solid #e5e5e5;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
`;

const Textarea = styled.textarea`
  margin-top: 16px;
  border: 1px solid #e5e5e5;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

export default function ConfigureWorkflowModal() {
  const dispatch = useDispatch();
  const [showSubMenu, setShowSubMenu] = useState(true);
  const { taskTypeName, taskTypeDescription, taskTypeId, appTaskCreateConfig, showWorkflowConfigModal, selectedNode } = useWorkflow();
  const { openJSEditorLayout, scriptEditorOpen } = useJSEditor();

  const [openABForSmartComponentScreen, setOpenABForSmartComponentScreen] = useState(false);
  const [openABForOnLoadScreen, setOpenABForOnLoadScreen] = useState(false);
  const [prevAutomationSCData, setPrevAutomationSCData] = useState([]);
  const [metaDataType, setMetaDataType] = useState<ABMetadataTypeEnum>(ABMetadataTypeEnum.WORKFLOW_SCREEN_ONLOAD);
  const [automationId, setAutomationId] = useState('');
  const { actionConditionPayload } = usePayload();
  const { clearRightSide } = useAutomation();

  const store = useStore()
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm(
    {
      defaultValues: {
        appTaskCreateConfig,
        taskTypeName,
        taskTypeDescription
      }
    }
  );


  const handleClose = () => {
    setShowSubMenu(false);
  };

  const onSubmit = (data: any) => {
    dispatch(
      setTaskTypeNameDescription({
        taskTypeName: data.taskTypeName,
        taskTypeDescription: data.taskTypeDescription,
      })
    );

    dispatch(setAppTaskCreateConfig(data.appTaskCreateConfig));
    handleClose();
    recordRSEvent('workflow_config_update_success', {
      config: 'builder_general',
      workflow_name: taskTypeName,
      workflow_id: taskTypeId,
    });
  };

  const openSettings = (e: any, { isHistory, isTaskCreate, isTaskHistoryScreen }: IShowTaskCardArgs) => {
    e.preventDefault();
    handleClose();
    dispatch(
      openTaskCardConfig({
        show: true,
        isHistory,
        isTaskCreate,
        isTaskHistoryScreen
      })
    );
    dispatch(setFlutterTaskCardConfig({ isHistory, isTaskCreate, isTaskHistoryScreen }))
  };

  const onMessageReceivedFromIframe = useCallback((event) => {
    if (event?.data?.source === 'zorp_web' && event?.data?.name === 'open_jsScriptEditor') {
      if (!store.getState()?.builder?.workflow?.selectedNode && store.getState()?.builder?.showWorkflowConfigModal) {
        if (store.getState()?.builder?.flutterTaskCardConfig.isTaskCreate) {
          // This will execute while opening low code editor from workflow configure modal -> Task Create Config
          const apiPayloadData = cloneDeep(actionConditionPayload);
          apiPayloadData.payload.exclusions.push('task');
          apiPayloadData.payload.inclusions.push({
            type: "appComponents",
            config: {
              isTaskCreate: true
            }
          })
          dispatch(setScriptEditorPayload(apiPayloadData));
        } else if (store.getState()?.builder?.flutterTaskCardConfig.isTaskHistoryScreen) {
          // Task History Screen
          const apiPayloadData = cloneDeep(actionConditionPayload);
          apiPayloadData.payload.inclusions.push({
            type: "appComponents",
            config: {
              isHistory: true
            }
          })
          dispatch(setScriptEditorPayload(apiPayloadData));
        } else {
          const apiPayloadData = cloneDeep(actionConditionPayload);
          apiPayloadData.payload.inclusions = [];
          apiPayloadData.payload.inclusions.push('task');
          apiPayloadData.payload.exclusions = [];
          apiPayloadData.payload.exclusions.push('RECORD');
          dispatch(setScriptEditorPayload(apiPayloadData));
        }
      }

      const editorConfig = {
        script: event?.data.payload?.['script'] || getLowCodeEditorText(event?.data.payload?.['editorLocation'], event?.data.payload?.['dataType'], `return ${getLowCodeEditorDefaultReturnText(event?.data.payload?.["dataType"])}`),
        dataType: event?.data.payload?.['dataType'],
        key: event?.data.payload?.['key'],
      };

      openJSEditorLayout({
        config: editorConfig,
        editorLocation: event?.data.payload?.['editorLocation'],
      });
    } else if (event?.data?.source === 'zorp_web' && event?.data?.name === 'exit_fullscreen') {

      dispatch(
        openTaskCardConfig({
          show: false,
          isHistory: false,
          isTaskCreate: false
        })
      );

      dispatch(setFlutterTaskCardConfig({ isHistory: false, isTaskCreate: false, isTaskHistoryScreen: false }));
    } else if (event?.data?.source === "zorp_web" && event?.data?.name === "launch_automation_builder") {
      setMetaDataType(ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT);
      dispatch(setTaskCreateScreenAutomation(event.data?.data?.isTaskCreateScreenAutomation || false));
      dispatch(setTaskHistoryScreenAutomation(event.data?.data?.isTaskHistoryScreenAutomation || false));
      // when user click Automation list, we need to pass selectedAutomationId
      setAutomationId(event.data?.data?.selectedAutomationId || '');
      // set prev automation data
        setPrevAutomationSCData(event.data?.data?.automations?.length > 0 ? event.data?.data?.automations : []);

      // TODO: Open automation builder for smart components 
      setOpenABForSmartComponentScreen(true);
    } else if (event?.data?.source === "zorp_web" && event?.data?.name === "launch_onload_builder") {
      setMetaDataType(ABMetadataTypeEnum.WORKFLOW_SCREEN_ONLOAD);
      dispatch(setTaskCreateScreenAutomation(event.data?.data?.isTaskCreateScreenAutomation || false));
      dispatch(setTaskHistoryScreenAutomation(event.data?.data?.isTaskHistoryScreenAutomation || false));
      // when user click Automation list, we need to pass selectedAutomationId
      setAutomationId(event.data?.data?.selectedAutomationId || '');

      // set prev automation data
      setPrevAutomationSCData(event.data?.data?.automations?.length > 0 ? event.data?.data?.automations : []);

      // TODO: Open automation builder for smart components 
      setOpenABForOnLoadScreen(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <SystemTransitionLabel onClick={() => setShowSubMenu((prev) => !prev)}>
            <div>Workflow Settings</div>
            <div>{showSubMenu ? <AiOutlineDown /> : <AiOutlineLeft />}</div>
          </SystemTransitionLabel>
          <div style={{ display: showSubMenu ? '' : 'none' }}>
            <Label> Workflow Name </Label>
            <Input
              defaultValue={taskTypeName}
              placeholder='Enter a name for your workflow'
              // {...register('taskTypeName', taskTypeNameSchema)}
              onChange={(e: any) => {
                dispatch(
                  setTaskTypeNameDescription({
                    taskTypeName: e.target.value,
                    taskTypeDescription: taskTypeDescription,
                  })
                );
                dispatch(setAppTaskCreateConfig({
                  ...appTaskCreateConfig,
                  displayName: e.target.value
                }));
              }}
            />
            <ErrorMessage errors={errors} fieldName={'taskTypeName'} />

            <Label> Workflow Description </Label>
            {/* @ts-ignore */}
            <Textarea
              defaultValue={taskTypeDescription}
              placeholder='Enter the workflow description'
              // @ts-ignore
              rows='5'
              // onChange={(e) => setDescription(e.target.value)}
              // {...register('taskTypeDescription')}
              onChange={(e: any) => {
                dispatch(
                  setTaskTypeNameDescription({
                    taskTypeName: taskTypeName,
                    taskTypeDescription: e.target.value,
                  })
                );
              }}
            />
          </div>
          <AppTaskCreateLabel setValue={setValue} register={register} openSettings={openSettings} dispatch={dispatch} appTaskCreateConfig={appTaskCreateConfig} />


          {/* @ts-ignore */}
          <ZButton
            onClick={(e) => {
              openSettings(e, { isHistory: false, isTaskCreate: false, isTaskHistoryScreen: false });
            }}
            // @ts-ignore
            secondary
            fullWidth
            variant='contained'
            style={{ marginTop: "10px" }}
            type="button"
          >
            {' '}
            Task Card Settings{' '}
          </ZButton>
          <ZButton
            onClick={(e: any) => {
              openSettings(e, { isHistory: true, isTaskCreate: false, isTaskHistoryScreen: false });
            }}
            secondary
            fullWidth
            variant='contained'
            style={{ marginTop: "10px" }}
            type="button"
          >
            {' '}
            History Card Settings{' '}
          </ZButton>
          <ZButton
            onClick={(e: any) => {
              openSettings(e, { isHistory: false, isTaskCreate: false, isTaskHistoryScreen: true });
            }}
            secondary
            fullWidth
            variant='contained'
            style={{ marginTop: "10px" }}
            type="button"
          >
            {' '}
            Task History Screen{' '}
          </ZButton>
        </Container>
      </form>
      {scriptEditorOpen && <ZJSEditorLayout />}

            {/* Smart Components AB for Task Create AB */}
      <ConfigureAutomation
        metaDataType={metaDataType}
        prevAutomations={prevAutomationSCData}
        stateId={''}
        onSaveHandler={(data) => {
          saveSmartComponentAutomation({ ...data, stateId: '' });
        }}
        onAutomationClose={() => {
          clearRightSide();
          dispatch(closeAutomation());
          setOpenABForSmartComponentScreen(false);
        }}
        automationSelected={automationId}

        otherConfigs={{
          hideGuardBlock: false
        }}
        openAutomationBuilder={openABForSmartComponentScreen}
      />

      {/* OnLoad AB for Task Create AB */}
      <ConfigureAutomation
        metaDataType={metaDataType}
        prevAutomations={prevAutomationSCData}
        stateId={''}
        onSaveHandler={(data) => {
          saveOnLoadAutomation({ ...data, stateId: '' });
        }}
        onAutomationClose={() => {
          clearRightSide();
          dispatch(closeAutomation());
          setOpenABForOnLoadScreen(false);
        }}
        automationSelected={automationId}
        otherConfigs={{
          hideGuardBlock: true,
          allowOnlySingleAutomation: true,
          hideTriggerConfigureButton: false,
          preFillTrigger: true
        }}
        openAutomationBuilder={openABForOnLoadScreen}
      />
    </>
  );
}
