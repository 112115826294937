import React from "react";

type Props = {};

const ZLocations = ({
  color,
  width,
  height,
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 4.5L7.875 7.875M4.5 4.5H2.25L1.5 2.25L2.25 1.5L4.5 2.25V4.5ZM14.4442 2.05576L12.4735 4.02647C12.1765 4.32348 12.028 4.47199 11.9724 4.64324C11.9234 4.79387 11.9234 4.95613 11.9724 5.10676C12.028 5.27801 12.1765 5.42652 12.4735 5.72353L12.6515 5.90147C12.9485 6.19848 13.097 6.34699 13.2682 6.40263C13.4189 6.45158 13.5811 6.45158 13.7318 6.40263C13.903 6.34699 14.0515 6.19848 14.3485 5.90147L16.192 4.05804C16.3905 4.54116 16.5 5.0703 16.5 5.625C16.5 7.90317 14.6532 9.75 12.375 9.75C12.1003 9.75 11.832 9.72316 11.5723 9.67195C11.2077 9.60004 11.0254 9.56409 10.9149 9.5751C10.7974 9.58681 10.7395 9.60443 10.6354 9.66014C10.5375 9.71254 10.4392 9.81077 10.2428 10.0072L4.875 15.375C4.25368 15.9963 3.24632 15.9963 2.625 15.375C2.00368 14.7537 2.00368 13.7463 2.625 13.125L7.99277 7.75723C8.18923 7.56077 8.28746 7.46254 8.33986 7.36462C8.39557 7.26051 8.41319 7.2026 8.4249 7.08511C8.43591 6.97459 8.39996 6.79229 8.32805 6.42768C8.27684 6.16804 8.25 5.89966 8.25 5.625C8.25 3.34683 10.0968 1.5 12.375 1.5C13.1291 1.5 13.836 1.70237 14.4442 2.05576ZM9.00004 11.25L13.125 15.3749C13.7463 15.9962 14.7537 15.9962 15.375 15.3749C15.9963 14.7536 15.9963 13.7462 15.375 13.1249L11.9815 9.73147C11.7413 9.70874 11.507 9.6654 11.2806 9.60325C10.9888 9.52316 10.6687 9.58129 10.4547 9.79525L9.00004 11.25Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ZLocations;
