//@ts-nocheck
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ITaskTypeWebRenderModel } from "render-engine/models/ITaskTypeWebRenderModel";
import { recordRSEvent } from "utils/CommonUtils";
import { PageCategoryEnum, recordPageVisit } from "../../config/Rudderstack";
import notification from "../../notifications/notifications";
import { TaskRenderService } from "../../providers/data/services/TaskRenderService";
import LoadingScreen from "../../views/LoadingScreen/LoadingScreen";
import { setLoading } from "../../views/reducer/index";
import { TaskService } from "../../views/tasks/services/TaskService";
import { Render } from "../Render";
import { getComponentParamsEdit } from "../RenderUtils";
import {
  cancelTaskEdit,
  setValue,
  initEditTask,
} from "render-engine/reducers/edit";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { TaskFormBody } from "render-engine/render.styles";
import TopBar from "render-engine/TopBar";
import { isPlainObject } from "lodash";

const EditTask = (props: any) => {
  const store = useStore();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editTaskForm = useForm();

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.task, "task_edit");
  }, [props]);

  const { loading } = useSelector((store: any) => store.loading);

  const [disableEditButton, setDisableEditButton] = useState(false);
  const [renderConfig, setRenderConfig] = useState<ITaskTypeWebRenderModel>();

  // Fetch the config for CC
  useEffect(() => {
    dispatch(initEditTask(props.record.id));
    dispatch(setValue({ key: "taskId", value: props.record.id }));
    TaskRenderService.fetchRenderConfigForTaskId(
      props.record.id,
      "edit",
      undefined
    )
      .then((res) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
          if (res?.code === "401") {
            notification("error", res.message);
          } else {
            notification("error", res.message);
          }
        } else if (res?.data == null)
          navigate(`/tasks/${props.record.id}?legacy=true`);
        else {
          setRenderConfig(res?.data);
        }
      })
      .catch((err) => {
        console.error("Error", err);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Task Edit Final Function
  const submitTaskEdit = (data: any) => {
    /**
     * Removing location dataType key if the address, latitude and longitude are undefined
     */
    // loop through all the keys of data
    const dataKeys = Object.keys(data);
    // loop through dataKeys and check if the value is an object
    dataKeys.forEach((key) => {
      if (isPlainObject(data[key])) {
        // get all the keys of the object
        const objectKeys = Object.keys(data[key]);
        // check if ojectKey has a value called address, latitude and longitude
        if (
          objectKeys.includes("address") &&
          objectKeys.includes("latitude") &&
          objectKeys.includes("longitude")
        ) {
          // check if the value of address, latitude and longitude is undefined
          if (
            (data[key].address === undefined || data[key].address === "") &&
            data[key].latitude === undefined &&
            data[key].longitude === undefined
          ) {
            // if yes, then delete the key from data
            delete data[key];
          }
        }
      }
    });

    dispatch(setLoading(true));
    const taskEditPayload: any = {
      taskId: props.record.id,
      taskType: store.getState().taskEdit.taskType,
      teamIds: store.getState().taskEdit.teamIds,
      scheduledSlot: store.getState().taskEdit.scheduledSlot,
      data: data,
      mode: "single",
      channel: "CC",
    };

    // const omitFieldsInData: Array<string> = ['scheduledSlot', 'taskType', 'teamIds'];
    // for (let key of Object.keys(store.getState().taskEdit)) {
    //   // Skip the omit fields for data
    //   if (!omitFieldsInData.includes(key)) taskEditPayload.data[key] = store.getState().taskEdit[key];
    // }

    TaskService.updateTask(taskEditPayload)
      .then((res: any) => {
        if (res?.code) {
          if (res?.code.indexOf("200") < 0) {
            setDisableEditButton(false);
            editTaskForm.reset();
            // Error
            recordRSEvent("save_event_fail", {
              context: "task_edit",
              taskId: props.record.id,
              taskType: taskEditPayload.taskType,
            });
            notification("error", res.message);
          } else {
            notification("success", "Task Edited successfully");
            setDisableEditButton(false);
            recordRSEvent("save_event_success", {
              context: "task_edit",
              taskId: props.record.id,
              taskType: taskEditPayload.taskType,
            });
            // notification('success', res.message);
            dispatch(cancelTaskEdit());
            const preview = localStorage.getItem("isPreview");
            if (preview) {
              //@ts-ignore
              window.parent.postMessage(
                {
                  source: "zorp_web_preview",
                  name: "edit_task",
                  taskId: props?.data?.id,
                },
                "*"
              );
              navigate(`/tasks/${props.record.id}/show/?preview=true`);
            } else navigate(`/tasks/${props.record.id}/show`);
            // localStorage.removeItem("isPreview");
          }
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        setDisableEditButton(false);
        editTaskForm.reset();
        console.error("Error", err);
        dispatch(setLoading(false));
        recordRSEvent("save_event_fail", {
          context: "task_edit",
          taskId: props.record.id,
          taskType: taskEditPayload.taskType,
        });
        notification("error", "Task cannot be edited!");
      });
  };

  const cancelEdit = () => {
    notification("info", "Task edit cancelled!");
    dispatch(cancelTaskEdit());
    const preview = localStorage.getItem("isPreview");
    if (!preview) navigate(`/tasks/${props.record.id}/show`);
    else navigate(`/tasks/${props.record.id}/show?preview=true`);
    dispatch(cancelTaskEdit());
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <FormProvider {...editTaskForm}>
          <form
            style={{ height: "94vh", overflow: "hidden" }}
            onSubmit={editTaskForm.handleSubmit(submitTaskEdit)}
          >
            <TopBar
              heading="Edit Task"
              onCancelText={"Cancel"}
              onSaveText="Update"
              onSave={() => {}}
              onCancel={cancelEdit}
            />
            <TaskFormBody key="taskCreate" className={`grid`}>
              <div className="contentWrapper-edit">
                <Render
                  renderConfig={renderConfig?.body?.blocks}
                  mode={renderConfig?.mode}
                  componentParams={getComponentParamsEdit(
                    dispatch,
                    editTaskForm
                  )}
                />

                <div className="pt-16">
                  {/* <ButtonGroup size='small' variant='outlined'> */}
                  {/* <ZButton
                    type="submit"
                    disabled={disableEditButton}
                    variant="contained"
                    style={{ marginRight: "10px" }}
                  >
                    Save
                  </ZButton>
                  <ZButton variant="contained" onClick={cancelEdit} secondary>
                    Cancel
                  </ZButton> */}
                  {/* </ButtonGroup> */}
                </div>
              </div>
            </TaskFormBody>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default EditTask;
