import { Box, Tab, Tabs, Typography } from "@mui/material";
import ScriptButton from "components/script-button/ScriptButton";
import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import { BOOLEAN, OBJECT, TEXT } from "constants/CommonConstants";
import {
  RudderStackAutomationBuilderContext,
  RudderStackAutomationBuilderEvents,
} from "constants/RudderStackConstants";
import _, { cloneDeep, isEmpty } from "lodash";
import { DefaultJSONDisabled } from "lowcode/constants/Snippets";
import { ZJSEditor } from "lowcode/editor/ZJSEditor";
import { useJSEditor } from "lowcode/hooks/useJSEditor.tsx";
import {
  clearScriptEditor,
  clearSelectedLocationScript,
  setScriptEditorPayload,
} from "lowcode/state/scriptOpenState";
import { EditorScriptLocation } from "lowcode/state/types/types";
import { ZJSEditorLayout } from "lowcode/ZJSEditorLayout";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useStore } from "react-redux";
import { recordRSEvent, removeBraces, stopPropagate } from "utils/CommonUtils";
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog.js";
import InputTempId from "views/automationBuilder/components/InputTempId";
import { DataFieldType } from "views/DataField/DataField.types";
import ModalSmartInputTextField from "views/workflows/workflowactions/api/components/ModalSmartInputTextField";
import DatafieldSelector from "../../DatafieldSelector";
import { generateTempId, getActionNameForTempId, IIsTempIdUniqueContext, isTempIdUnique } from "../utils/utils";
import { FormControlLabel } from "@mui/material";
import getInputType from "lowcode/util/getInputType";
import notification from "notifications/notifications";
import { LuSettings2 } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import {
  getLowCodeEditorDefaultReturnText,
  getLowCodeEditorText,
} from "utils/Utils";
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import usePayload from "views/automationBuilder/hooks/usePayload";
import { updateMockDataValue } from "views/automationBuilder/utils/util";
import {
  ZButton,
  ZDropdown,
} from "views/commonComponents/commonComponents.styles";
import ZInput from "views/commonComponents/ZTextInput";
import { Button } from "views/DataField/DataField.styles";
import ErrorMessage from "views/DataField/ErrorMessage";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import { testAPI } from "views/workflows/workflowactions/api/APIAction/ApiActionTestUtils";
import IsActive from "views/workflows/workflowactions/api/components/IsActive";
import {
  saveAction,
  saveGuard,
  ValidLocation,
} from "views/workflows/workflowactions/api/utils/utils";
import {
  APIFlexDiv,
  Heading,
  HeadingWrapper,
  InputContainer,
  Label,
  URLInfoWrapper,
} from "views/workflows/workflowactions/ModalContent.styles";
import {
  setApiResponse,
  setSelectedAction,
} from "../../../reducers/workflowAction";
import ErrorMessageInput from "../components/ErrorMessageInput";
import ExecuteCondition from "../components/ExecuteCondition";
import Params from "../components/Tabs/Params";
import "./APIAction.css";
import ApiTestWrapper from "./ApiTestWrapper";
import ErrorHandlingModal from "./ErrorHandlingModal";
import ImportCurlModal from "./ImportCurl/ImportCurlModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RestApiOptions = [
  { value: "GET", label: "GET" },
  { value: "POST", label: "POST" },
  { value: "PUT", label: "PUT" },
  { value: "PATCH", label: "PATCH" },
  { value: "DELETE", label: "DELETE" },
];

const APIAction = ({ type }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [tempIdChanged, setTempIdChanged] = useState(true);
  const [exitOnError, setExitOnError] = useState(true);
  const [openCurlModal, setOpenCurlModal] = useState(false);
  const [openErrorHandlingModal, setOpenErrorHandlingModal] = useState(false);
  const {
    openJSEditorLayout,
    scriptEditorOpen,
    DefaultJSON,
    openJSEditor,
    userClearedScript,
  } = useJSEditor();
  const {
    selectedTransition,
    selectedAction,
    getScriptValue,
    getLiteralValue,
    apiResponse,
    dataPicker
  } = useWorkFlow();

  const [currTempIdVal, setCurrTempIdVal] = useState(selectedAction?.tempId || "");
  const { actionConditionPayload } = usePayload();
  const { selectedActionBlock, selectedAutomation, clearRightSide, decisionSwitchAutomation} = useAutomation();

  const ShowCurrentActionPayload = cloneDeep(actionConditionPayload);
  if (ShowCurrentActionPayload.payload.inclusions.length > 0) {
    if (ShowCurrentActionPayload.payload.inclusions[0].config) {
      ShowCurrentActionPayload.payload.inclusions[0].config.showCurrentAction = true;
      ShowCurrentActionPayload.payload.inclusions[0].config.actionName =
        selectedAction?.actionName || selectedAction?.guardName;
    }
  }
  //  Hiding the Error Message for All the Decision Node Switches API Guard
  const hideErrorMessageLogic = !(decisionSwitchAutomation && type == "guard");

  const [tabIndex, setTabIndex] = useState(0);
  const [apiBody, setApiBody] = useState(
    selectedAction?.api_action_json_body?.value
  );
  const { mockData } = useSelector((state) => state.scriptEditor);
  const [clearScript, setClearScript] = useState(false);
  /**
   * 1. check if header is present
   * 2. Check the type
   * 3. If string, do what was done before
   * 4. if object, make an array.
   */
  const makeHeadersFromBackendToUICompatible = (headers) => {
    let newHeaders = [];
    if (!headers) {
      return [];
    }
    if (typeof headers === "string") {
      // in older api structure, the header in string format with the separation of ###
      // Ex:  "headers" : "Authorization:Bearer key4FhgdvSpSl9Fqz###Content-type:application/json"
      // here we are converting the above format to array
      const headerArray = headers
        ?.split("###")
        ?.filter((header) => header && header.trim().length > 0);

      headerArray?.forEach((header, index) => {
        const headerKeyValue = {
          key: header.split(":")[0],
          value: {
            type: "literal",
            value: header.split(":")[1],
            dataType: DataFieldType.TEXT,
          },
        };

        newHeaders.push(headerKeyValue);
      });
    } else {
      if (Array.isArray(headers)) {
        newHeaders = headers;
      } else {
        Object.keys(headers).forEach((key) => {
          newHeaders.push({
            key,
            value: {
              type: "literal",
              value: headers[key][0],
              dataType: DataFieldType.TEXT,
            },
          });
        });
      }
    }
    if (newHeaders.length === 0) {
      const selectedActionTmpData = {
        ...selectedAction,
        headers: [
          {
            key: "",
            value: {
              type: "literal",
              value: "",
              dataType: DataFieldType.TEXT,
            },
          },
        ],
      };
      dispatch(setSelectedAction(selectedActionTmpData));

      return [
        {
          key: "",
          value: {
            type: "literal",
            value: "",
            dataType: DataFieldType.TEXT,
          },
        },
      ];
    }
    return newHeaders;
  };

  const handleURLChange = (e) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        [EditorScriptLocation.AB_API_ACTION_URL]: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: TEXT,
        },
      })
    );
  };

  const handleShouldRetryChange = (e) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        [EditorScriptLocation.API_ACTION_SHOULD_RETRY]: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: BOOLEAN,
        },
      })
    );
  } 

  // previously our URL type is url then we changed to object, so if some url come as string, then here we are making to
  const defaultURLValue =
    typeof selectedAction[EditorScriptLocation.AB_API_ACTION_URL] == "object"
      ? selectedAction[EditorScriptLocation.AB_API_ACTION_URL]
      : {
          type: "literal",
          value: selectedAction[EditorScriptLocation.AB_API_ACTION_URL],
          dataType: TEXT,
        };

  const newHeadersData = makeHeadersFromBackendToUICompatible(
    selectedAction?.headers || {}
  );

  const defaultName = selectedAction?.actionName || selectedAction?.guardName
  
  const defaultTmpId =  selectedAction.tempId || generateTempId(selectedAction, selectedAutomation, getActionNameForTempId(selectedAction), type)

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    trigger, //added for validation in handle tick
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
  apiDescription: defaultName,
  tempId:defaultTmpId,
  method: selectedAction?.method || "GET",
  url: defaultURLValue,
  isActive: selectedAction?.isActive ?? true,
  headers: newHeadersData,
  retries: selectedAction?.retries || 3,
  delay: selectedAction?.delay || 2000,
  exponentialFactor: selectedAction?.exponentialFactor || 2,
  isRetryEnabled:  selectedAction?.isRetryEnabled === true ? "retryEnabled" : "retryDisabled",
  exponential: (selectedAction?.exponential === undefined || selectedAction?.exponential === false) ? "uniform" : "exponential" ,
},
  });

  const handleTabChange = (index, newIndex) => {
    setTabIndex(newIndex);

    // when tab is changed from Body to others, store the data to redux
    // Reason : This is implemented because api body is vanishing when closing or changing tab or opening low code editor
    if (newIndex !== 1) {
      if (
        selectedAction?.api_action_json_body?.type == "literal" ||
        selectedAction?.api_action_json_body?.type == undefined
      ) {
        const apiBodyData = {
          api_action_json_body: {
            type: "literal",
            value:
              typeof apiBody == "string" ? apiBody : JSON.stringify(apiBody),
            dataType: OBJECT,
          },
        };

        const tmpSelectedAction = _.cloneDeep(selectedAction);
        Object.assign(tmpSelectedAction, apiBodyData);
        dispatch(setSelectedAction(tmpSelectedAction));
      }
    }

    if (newIndex === 1) {
      openJSEditor({
        config: {
          script: !!getLiteralValue(EditorScriptLocation.API_ACTION_JSON_BODY)
            ?.length
            ? getLiteralValue(EditorScriptLocation.API_ACTION_JSON_BODY)
            : !!getScriptValue(EditorScriptLocation.API_ACTION_JSON_BODY)
                ?.length
            ? DefaultJSONDisabled
            : DefaultJSON,
          type: OBJECT,
        },
        editorLocation: EditorScriptLocation.API_ACTION_JSON_BODY,
        isEditorDisabled: !!getScriptValue(
          EditorScriptLocation.API_ACTION_JSON_BODY
        )?.length,
        defaultEditorLanguage: getScriptValue(
          EditorScriptLocation.API_ACTION_JSON_BODY
        )
          ? "javascript"
          : "json",
      });
    }
  };

  useEffect(() => {
    setExitOnError(selectedAction?.exitOnError ?? true);
  }, [selectedAction?.exitOnError]);

  useEffect(() => {
    recordPageVisit(
      PageCategoryEnum.automationBuilder,
      RudderStackAutomationBuilderEvents.apiActionOnLoad
    );
  }, []);

  useEffect(() => {
    // here we are converting, api header old format to the new format and setting in selected Action
    if (
      !Array.isArray(selectedAction.headers) &&
      (typeof selectedAction.headers == "object" ||
        typeof selectedAction.headers == "string") &&
      Object.keys(selectedAction.headers).length > 0 &&
      !selectedAction.newStructure
    ) {
      const headersNewStructure = makeHeadersFromBackendToUICompatible(
        selectedAction.headers
      );

      let selectedActionTmpData = {
        ...selectedAction,
        hasChanged: true,
        newStructure: true,
        headers: headersNewStructure,
      };

      dispatch(setSelectedAction(selectedActionTmpData));
    }
  }, [selectedAction.headers]);


  /** 
   *This useEffect is used to set the guard id as temp id when the action component is 
    rendered for the first time so that guard Id is initially set to the either 
    the id which is already present in the action or with the newly created temp id
  */
  // useEffect(() => {
  //   const tmpSelectedAction = _.cloneDeep(selectedAction);
  //   if (tmpSelectedAction.guardId) {
  //     tmpSelectedAction.guardId = generateTempId(selectedAction, selectedAutomation, getActionNameForTempId(selectedAction), type);
  //     dispatch(setSelectedAction(tmpSelectedAction));
  //   }
  //   else if (tmpSelectedAction.actionId) {
  //     tmpSelectedAction.actionId = generateTempId(selectedAction, selectedAutomation, getActionNameForTempId(selectedAction), type);
  //     dispatch(setSelectedAction(tmpSelectedAction));
  //   }
  // }, []);

  const getHeaderValues = () => {
    let tmpSelectedActionData = cloneDeep(selectedAction);
    tmpSelectedActionData.headers.forEach((headerData, index) => {
      headerData.key = getValues().headers[index].key;
    });

    // Removing the headers with empty key value while saving
    // this scenario will occures when the header length is 1 and the key value is empty
    tmpSelectedActionData.headers = tmpSelectedActionData?.headers?.filter(
      (item) => item.key !== ""
    );

    return tmpSelectedActionData.headers;
  };

  const handleSave = async (data, event) => {
    const mockDataPayload = cloneDeep(mockData);
    const { apiDescription, method } = data;

    if(selectedAction?.actionId && !isEditing) {
      updateIdAndDispatch(selectedAction?.tempId, currTempIdVal);
    }

    let tempActionId = selectedAction?.tempId || data?.tempId;
    if (!isEditing) {
      tempActionId = currTempIdVal.length === 0 ? (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId) : currTempIdVal;
      dispatch(setSelectedAction({ ...selectedAction, tempId: currTempIdVal }));
    }

    let isTempIdChanged = tempIdChanged;
    if (tempActionId === (selectedAction?.actionId || selectedAction?.guardId || selectedAction?.conditionId)) {
      setTempIdChanged(false);
      isTempIdChanged = false;
    }

    //If tempId is changed then only validations are added.
    if((selectedAction?.guardId && selectedAction?.tempId !== selectedAction?.guardId) ||
       (selectedAction?.actionId && (selectedAction?.tempId !== selectedAction?.actionId))) {
        if (isTempIdChanged && !isTempIdUnique(tempActionId, selectedAutomation, type === "action" ? IIsTempIdUniqueContext.ACTION : IIsTempIdUniqueContext.GUARD)) {
        setError("tempId", {
        type: "manual",
        message: "This ID already exists. Please choose a different one.",
        });
        return;
      }
    }

    const headers = getHeaderValues();
    recordRSEvent(RudderStackAutomationBuilderEvents.apiActionSaveClick, {
      context: RudderStackAutomationBuilderContext.automationBuilderAction,
      transition_id: selectedTransition?.data.id,
      action_type: selectedAction?.actionType,
      action_id: tempActionId,
      action_url: defaultURLValue,
      action_method: method,
      action_name: selectedAction.actionName,
      action_body: apiBody,
      action_header: selectedAction.headers,
      automationId: selectedAutomation.id,
    });

    if (
      type === "guard" &&
      !selectedAction[
        EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE
      ]?.value
    ) {
      notification("error", "Expression is Mandatory");
      return;
    }

    if (!selectedAction[EditorScriptLocation.AB_API_ACTION_URL]?.value) {
      setError(EditorScriptLocation.AB_API_ACTION_URL, {
        type: "manual",
        message: "This is a required field",
      });
      return;
    }

    if (hideErrorMessageLogic) {
      if (
        type === "guard" &&
        !selectedAction[EditorScriptLocation.ERROR_MESSAGE]?.value
      ) {
        setError(EditorScriptLocation.ERROR_MESSAGE, {
          type: "manual",
          message: "This is a required field",
        });
        return;
      }
    }
    let errorInHeaders = false;
    headers.forEach((header, index) => {
      // don't  show the error message, when the header length is 1
      if (!header.value.value && !errorInHeaders && index !== 0) {
        errorInHeaders = true;
        notification("error", "Please Fill All Headers Value");
      }
    });

    if (errorInHeaders) {
      return;
    }

    let actionData = {};
    if (type === "action") {
      actionData = {
        transitionId: selectedTransition?.id,
        actionId: tempActionId,
        tempId: tempActionId,
        actionName: apiDescription,
        method: method,
        isActive: selectedAction?.isActive ?? true,
        actionType: selectedAction.actionType,
        url: defaultURLValue,
        headers: headers,
        api_action_json_body: {
          type:
            userClearedScript?.state ||
            !getScriptValue(EditorScriptLocation.API_ACTION_JSON_BODY)?.length
              ? "literal"
              : "script",
          value:
            userClearedScript?.state ||
            !getScriptValue(EditorScriptLocation.API_ACTION_JSON_BODY)?.length
              ? apiBody
              : getScriptValue(EditorScriptLocation.API_ACTION_JSON_BODY),
          dataType: OBJECT,
        },
        [EditorScriptLocation.ERROR_MESSAGE]:
          selectedAction[EditorScriptLocation.ERROR_MESSAGE],
        exitOnError: exitOnError,
        retries: parseInt(selectedAction?.retries) || 3,
        delay: parseInt(selectedAction?.delay) || 2000,
        exponential: data.exponential === "exponential" ? true : false,
        isRetryEnabled: data.isRetryEnabled === "retryEnabled" ? true : false,
        exponentialFactor: parseInt(selectedAction?.exponentialFactor) || 2,
        
        // shouldRetry: selectedAction?.shouldRetry || false,
        [EditorScriptLocation.API_ACTION_SHOULD_RETRY] : selectedAction[EditorScriptLocation.API_ACTION_SHOULD_RETRY] || {
          type: "script",
          dataType: BOOLEAN,
          value:`if(response_${tempActionId}?.status === 500) { return true } return false` ,

        } ,
      };
    } else if (type === "guard") {
      // REMINDER: guard is not used in the current version
      // TODO: add location for api guard json body
      actionData = {
        transitionId: selectedTransition?.id,
        guardId: tempActionId,
        tempId: tempActionId,
        guardName: apiDescription,
        isActive: selectedAction?.isActive ?? true,
        guardType: selectedAction.guardType,
        method: method,
        url: defaultURLValue,
        headers: headers,
        api_action_json_body: {
          type:
            userClearedScript?.state ||
            !getScriptValue(EditorScriptLocation.API_ACTION_JSON_BODY)?.length
              ? "literal"
              : "script",
          value:
            userClearedScript?.state ||
            !getScriptValue(EditorScriptLocation.API_ACTION_JSON_BODY)?.length
              ? apiBody
              : getScriptValue(EditorScriptLocation.API_ACTION_JSON_BODY),
          dataType: OBJECT,
        },
        [EditorScriptLocation.ERROR_MESSAGE]:
          selectedAction[EditorScriptLocation.ERROR_MESSAGE],
        [EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE]:
          selectedAction[
            EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE
          ],
        [EditorScriptLocation.EXPRESSION_GUARD_CONDITION]:
          selectedAction[EditorScriptLocation.EXPRESSION_GUARD_CONDITION],
        retries: parseInt(selectedAction?.retries) || 3,
        delay: parseInt(selectedAction?.delay) || 2000,
        exponential: data.exponential === "exponential" ? true : false,
        isRetryEnabled: data.isRetryEnabled === "retryEnabled" ? true : false,
        exponentialFactor: parseInt(selectedAction?.exponentialFactor) || 2,
        
        // shouldRetry: selectedAction?.shouldRetry || false,
        [EditorScriptLocation.API_ACTION_SHOULD_RETRY] : selectedAction[EditorScriptLocation.API_ACTION_SHOULD_RETRY] || {
          type: "script",
          dataType: BOOLEAN,
          value:`if(response_${tempActionId}?.status === 500) { return true } return false` ,

        } ,

      };
    }

    if (isEmpty(mockData?.[`response_${tempActionId}`])) {
    mockDataPayload[`response_${tempActionId}`] = {
      headers:{},
      body: {},
      status: 200
    }
      await updateMockDataValue(store, dispatch, mockDataPayload);
    }


    if (type === "action") {
      // This check is for deciding which dispatch function to call as these actions
      // would be stored at different places. If we have decisionNode selected, we call
      // updateActionInSwitch() else we call updateAction()

      saveAction({
        payload: actionData,
        location: ValidLocation.AUTOMATION,
        dispatch,
        selectedAction,
        selectedAutomation,
        selectedActionBlock,
      });
    } else if (type === "guard") {
      saveGuard({
        payload: actionData,
        location: ValidLocation.AUTOMATION,
        dispatch,
        selectedAction,
      });
    }
    dispatch(clearScriptEditor());
    dispatch(setApiResponse({}));

    // Removing right side config
    clearRightSide();

    // event?.stopPropagation();
    // event?.preventDefault();
  };

  const handleErrorMessageChange = (e) => {
    dispatch(
      setSelectedAction({
        ...selectedAction,
        hasChanged: true,
        [EditorScriptLocation.ERROR_MESSAGE]: {
          type: getInputType(e.target.value),
          value: removeBraces(e.target.value),
          dataType: TEXT,
        },
      })
    );
  };

  // even if getScriptValue(EditorScriptLocation.ACTION_CONDITION_CHECK) === "", it gives return true
  const actionExecutionScriptConfig = {
    ApiActionJsonBody: {
      script:
        getScriptValue(EditorScriptLocation.API_ACTION_JSON_BODY) ||
        getScriptValue(EditorScriptLocation.BODY) ||
        getLowCodeEditorText(
          EditorScriptLocation.API_ACTION_JSON_BODY,
          OBJECT,
          `return ${getLowCodeEditorDefaultReturnText(OBJECT)}`
        ),
      dataType: OBJECT,
    },
    ConditionCheck: {
      // Write Conditional Expression (Optional)
      script:
        getScriptValue(EditorScriptLocation.EXPRESSION_GUARD_CONDITION) ||
        getLowCodeEditorText(
          EditorScriptLocation.EXPRESSION_GUARD_CONDITION,
          BOOLEAN,
          `return ${getLowCodeEditorDefaultReturnText(BOOLEAN)}`
        ),
      dataType: BOOLEAN,
    },
    ExecuteExpression: {
      // Write Expression to Execute
      script:
        getScriptValue(
          EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE
        ) ||
        getLowCodeEditorText(
          EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE,
          BOOLEAN,
          `return ${getLowCodeEditorDefaultReturnText(BOOLEAN)}`
        ),
      dataType: BOOLEAN,
    },
    APIURL: {
      script:
        getScriptValue(EditorScriptLocation.AB_API_ACTION_URL) ||
        getLowCodeEditorText(
          EditorScriptLocation.AB_API_ACTION_URL,
          TEXT,
          `return ${getLowCodeEditorDefaultReturnText("URL")}`
        ),
      dataType: TEXT,
    },
    ShouldRetry: {
      script:
      getScriptValue(EditorScriptLocation.API_ACTION_SHOULD_RETRY) ||
      getLowCodeEditorText(
        EditorScriptLocation.API_ACTION_SHOULD_RETRY,
        BOOLEAN,
        `if(response_${defaultTmpId}?.status === 500) { return true } return false`
      ),
    dataType: BOOLEAN,
    },
    API_ERROR_MESSAGE: {
      script:
        getScriptValue(EditorScriptLocation.ERROR_MESSAGE) ||
        getLowCodeEditorText(
          EditorScriptLocation.ERROR_MESSAGE,
          TEXT,
          `return ${getLowCodeEditorDefaultReturnText(TEXT)}`
        ),
      dataType: TEXT,
    },
  };

  const updateAPIActionMockData = async () => {
    const mockDataPayload = cloneDeep(mockData);
    const id = selectedAction?.actionId || selectedAction?.guardId;
    mockDataPayload[`response_${id}`] = {
      headers:{},
      body: apiResponse?.data,
      status: apiResponse?.status
    }
    await updateMockDataValue(store, dispatch, mockDataPayload);
  }

  const handleValChange = (name, value) => {
    dispatch(
      setSelectedAction({ ...selectedAction, [name]: value, hasChanged: true })
    );
  };
  

  const handleTempActionValChange = (name, value) => {
      setCurrTempIdVal(value);
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const updateIdAndDispatch = async (oldId, newId) => {
    const mockDataPayload = cloneDeep(mockData);
      if(!oldId || !newId) {
        setIsEditing(false);
          return;
      }
      else if(oldId === newId) {
        setIsEditing(false);
          return;
      };
      mockDataPayload[`response_${newId}`] = mockDataPayload[`response_${oldId}`];
      await updateMockDataValue(store, dispatch, mockDataPayload);
      const tmpSelectedAction = _.cloneDeep(selectedAction);
      tmpSelectedAction["tempId"] = newId;
      dispatch(setSelectedAction(tmpSelectedAction));
  };

  /** 
   *This code is for handling the tick icon click.
    Validations are added to the tick icon also as in the handleSave.
    On editing the id and clicking the tick button, test data is updated with new Id.
  */  
  const handleTickClick = async() => {   

    //trigger is used here to trigger the errors associated 
    //with temp id when the tick button is clicked.
    const isTempIdValid = await trigger("tempId");
    if (!isTempIdValid) return;
    let isTempIdChanged = true;
    if(currTempIdVal === selectedAction.tempId || currTempIdVal === selectedAction.tempId) {
       isTempIdChanged = false;
    }

    if((selectedAction?.guardId || selectedAction?.actionId) && isTempIdChanged) {
        dispatch(setSelectedAction({ ...selectedAction, tempId: currTempIdVal }));
      if (isTempIdChanged && !isTempIdUnique(currTempIdVal, selectedAutomation, type === "action" ? IIsTempIdUniqueContext.ACTION : IIsTempIdUniqueContext.GUARD)) {
        setError("tempId", {
        type: "manual",
        message: "This ID already exists. Please choose a different one.",
        }, {shouldFocus: true});
        return;
      }
      setIsEditing(false);
      try {  
        const oldTempId = selectedAction?.tempId ? selectedAction?.tempId : (selectedAction?.guardId); 
        if (selectedAction?.guardId) {
          await updateIdAndDispatch(oldTempId, currTempIdVal);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const setTempIdError = (error) => {
    if (error) {
      setError("tempId", { type: "manual", message: error });
    } else {
      clearErrors("tempId");
    }
  };

  const tempIdLabel = type === "guard" ? "Validation Id" : `${type.charAt(0).toUpperCase() + type.slice(1)} Id`;

  return (
    <>
      <form
        onSubmit={stopPropagate(handleSubmit(handleSave))}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ overflowY: "auto", overflowX: "hidden" }}>
          <HeadingWrapper>
            <Heading>
              {" "}
              {type === "guard" ? "API Validation" : "API Action"}{" "}
            </Heading>
            <IsActive control={control} closeOnClick={clearRightSide} />
          </HeadingWrapper>
          <Label> Name</Label>
          <InputContainer>
            <ZInput
              onChange={handleValChange}
              register={register}
              validations={{
                required: "API Name is required",
              }}
              name="apiDescription"
              placeholder="Name the Action"
            />
          </InputContainer>
          <InputTempId
            label={tempIdLabel}
            onChange={handleTempActionValChange}
            register={register}
            requiredErrorMessage = {type === "guard" ? "Validation Id is required" : "Action Id is required"}
            errors={errors}
            isReadOnly={!isEditing}
            handleTickClick={handleTickClick}
            handleEditClick={handleEditClick}
            getValues = {getValues}
            setTempIdError={setTempIdError}
            isEditing={isEditing}
            name="tempId"
          />
          <ErrorMessage errors={errors} fieldName={"apiDescription"} />

          {type === "guard" && (
            <>
              <ExecuteCondition
                showExpressionActive={
                  selectedAction[
                    EditorScriptLocation.EXPRESSION_GUARD_CONDITION
                  ]?.value
                    ? true
                    : false
                }
                conditionLabel="Write Conditional Expression (Optional)"
                handleClick={() => {
                  if (
                    selectedAction?.api_action_json_body?.type == "literal" ||
                    selectedAction?.api_action_json_body?.type == undefined
                  ) {
                    const apiBodyData = {
                      api_action_json_body: {
                        type: "literal",
                        value: apiBody,
                        dataType: OBJECT,
                      },
                    };

                    const tmpSelectedAction = _.cloneDeep(selectedAction);
                    Object.assign(tmpSelectedAction, apiBodyData);
                    dispatch(setSelectedAction(tmpSelectedAction));
                  }

                  dispatch(setScriptEditorPayload(actionConditionPayload));
                  openJSEditorLayout({
                    config: actionExecutionScriptConfig.ConditionCheck,
                    editorLocation:
                      EditorScriptLocation.EXPRESSION_GUARD_CONDITION,
                  });
                }}
              />

              <ExecuteCondition
                showExpressionActive={
                  selectedAction[
                    EditorScriptLocation
                      .EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE
                  ]?.value
                    ? true
                    : false
                }
                conditionLabel="Write Expression to Execute"
                handleClick={() => {

                  if (
                    selectedAction?.api_action_json_body?.type == "literal" ||
                    selectedAction?.api_action_json_body?.type == undefined
                  ) {
                    const apiBodyData = {
                      api_action_json_body: {
                        type: "literal",
                        value: apiBody,
                        dataType: OBJECT,
                      },
                    };

                    const tmpSelectedAction = _.cloneDeep(selectedAction);
                    Object.assign(tmpSelectedAction, apiBodyData);
                    dispatch(setSelectedAction(tmpSelectedAction));
                  }

                  dispatch(setScriptEditorPayload(ShowCurrentActionPayload));
                  openJSEditorLayout({
                    config: actionExecutionScriptConfig.ExecuteExpression,
                    editorLocation:
                      EditorScriptLocation.EXPRESSION_CONDITION_EXPRESSION_TO_VALIDATE,
                  });
                }}
              />
            </>
          )}

          <div>
            <URLInfoWrapper>
              <div>
                <Label style={{ margin: "11px" }}> Method </Label>
                <InputContainer>
                  <Controller
                    name={"method"}
                    control={control}
                    render={({ field }) => (
                      <ZDropdown
                        control={control}
                        name={"method"}
                        options={RestApiOptions}
                        defaultValue={{ value: "GET", label: "GET" }}
                        value={RestApiOptions.find(
                          (c) => c.value === field.value
                        )}
                        onChange={(val) => {
                          field.onChange(val.value);
                          dispatch(
                            setSelectedAction({
                              ...selectedAction,
                              hasChanged: true,
                              method: val.value,
                            })
                          );
                        }}
                      />
                    )}
                  />
                </InputContainer>
                <ErrorMessage errors={errors} fieldName={"method"} />
              </div>

              <APIFlexDiv>
                <Label style={{ margin: "11px", paddingBottom: "2px" }}>
                  {" "}
                  Enter URL{" "}
                </Label>
                <ModalSmartInputTextField
                  type={
                    selectedAction[EditorScriptLocation.AB_API_ACTION_URL]
                      ?.type || "literal"
                  }
                  dataType={DataFieldType.TEXT}
                  control={control}
                  step="any"
                  avoidOverflow
                  minWidth="100%"
                  errors={errors}
                  onChangeFunc={handleURLChange}
                  scriptButtonOnClick={() => {
                    if (
                      selectedAction?.api_action_json_body?.type == "literal" ||
                      selectedAction?.api_action_json_body?.type == undefined
                    ) {
                      const apiBodyData = {
                        api_action_json_body: {
                          type: "literal",
                          value: apiBody,
                          dataType: OBJECT,
                        },
                      };

                      const tmpSelectedAction = _.cloneDeep(selectedAction);
                      Object.assign(tmpSelectedAction, apiBodyData);
                      dispatch(setSelectedAction(tmpSelectedAction));
                    }
                  }}
                  isSmartField={true}
                  apiBodyPayload={actionConditionPayload}
                  value={
                    selectedAction[EditorScriptLocation.AB_API_ACTION_URL]
                      ?.value ||
                    (typeof selectedAction[
                      EditorScriptLocation.AB_API_ACTION_URL
                    ] == "string"
                      ? selectedAction[EditorScriptLocation.AB_API_ACTION_URL]
                      : "")
                  }
                  placeholder="Enter URL"
                  register={register}
                  openJSEditor={openJSEditorLayout}
                  name="url"
                  config={actionExecutionScriptConfig.APIURL}
                  editorLocation={EditorScriptLocation.AB_API_ACTION_URL}
                />
              </APIFlexDiv>
            </URLInfoWrapper>
          </div>

          {/* Hiding the Error Message for All the Decision Node Switches API Guard */}
          {hideErrorMessageLogic && (
            <ErrorMessageInput
              label={`Error Message to be shown if the ${
                type == "guard" ? "validation" : "action"
              } fails`}
              onChangeFunc={handleErrorMessageChange}
              register={register}
              errors={errors}
              openJSEditorLayout={openJSEditorLayout}
              apiBodyPayload={ShowCurrentActionPayload}
              config={actionExecutionScriptConfig.API_ERROR_MESSAGE}
              scriptButtonOnClick={() => {
                if (
                  selectedAction?.api_action_json_body?.type == "literal" ||
                  selectedAction?.api_action_json_body?.type == undefined
                ) {
                  const apiBodyData = {
                    api_action_json_body: {
                      type: "literal",
                      value: apiBody,
                      dataType: OBJECT,
                    },
                  };

                  const tmpSelectedAction = _.cloneDeep(selectedAction);
                  Object.assign(tmpSelectedAction, apiBodyData);
                  dispatch(setSelectedAction(tmpSelectedAction));
                }
              }}
            />
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            
              <div style={{ padding: "11px", display:"flex", gap:'16px' }}>
                <div
                  onClick={
                    () => setOpenErrorHandlingModal(true)
                  }
                  style={{
                    color:"#3e66fb",
                    fontWeight:500,
                    cursor:"pointer",
                  }}
                > 
                <LuSettings2 style={{
                  display:"inline"
                }} /> Error Handling 
                </div>
                <FormControlLabel
                  control={
                    <input
                      type="checkbox"
                      style={{ height: "18px", width: "18px" }}
                      checked={exitOnError}
                      onChange={(value) => {
                        setExitOnError((currentValue) => !currentValue);
                      }}
                      value="one"
                    />
                  }
                  label={
                    <Typography
                      variant="h6"
                      style={{
                        color: "#808080",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Exit Transition On Error
                    </Typography>
                  }
                />
                
              
              </div>
              
       
            <div></div>
  
            <Button
              type="button"
              style={{
                height: "32px",
                width: "166px",
                height: "40px",
                background: "#FFFFFF",
                border: "1px solid #D0D5DD",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                borderRadius: "4px",
              }}
              onClick={() => {
                setOpenCurlModal(true);
              }}
            >
              Import Curl
            </Button>
          </div>

          <Box
            sx={{
              width: "100%",
              paddingBottom: "12px",
            }}
          >
            <Tabs
              TabIndicatorProps={{ style: { background: "#1976d2" } }}
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab
                style={{
                  color: "color: rgba(0, 0, 0, 0.5);",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
                label="HEADERS"
                disableRipple
                disableTouchRipple
                {...a11yProps(0)}
              />
              <Tab
                style={{
                  color: "color: rgba(0, 0, 0, 0.5);",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
                label="BODY"
                {...a11yProps(1)}
                disableRipple
                disableTouchRipple
              />
              <Tab
                style={{
                  color: "color: rgba(0, 0, 0, 0.5);",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
                label="Action Response"
                disableRipple
                disableTouchRipple
                {...a11yProps(2)}
              />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              {Array.isArray(selectedAction.headers) && (
                <Params
                  setError={setError}
                  clearErrors={clearErrors}
                  getValues={getValues}
                  register={register}
                  control={control}
                  errors={errors}
                />
              )}
            </TabPanel>
            <TabPanel
              style={{ borderTop: "2px solid  #e5e5e5" }}
              value={tabIndex}
              index={1}
            >
              <Box
                display="flex"
                alignItems="end"
                marginLeft={1}
                marginTop={2}
                marginBottom={2}
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="end" marginLeft={1}>
                  <Label>API Body</Label>
                  <ScriptButton
                    handleClick={() => {
                      dispatch(setScriptEditorPayload(actionConditionPayload));
                      if (
                        selectedAction?.api_action_json_body?.type ==
                          "literal" ||
                        selectedAction?.api_action_json_body?.type == undefined
                      ) {
                        const apiBodyData = {
                          api_action_json_body: {
                            type: "literal",
                            value:
                              typeof apiBody != "string"
                                ? JSON.stringify(apiBody)
                                : apiBody,
                            dataType: OBJECT,
                          },
                        };

                        const tmpSelectedAction = _.cloneDeep(selectedAction);
                        Object.assign(tmpSelectedAction, apiBodyData);
                        dispatch(setSelectedAction(tmpSelectedAction));
                      }
                      openJSEditorLayout({
                        isEditorDisabled: false,
                        config: actionExecutionScriptConfig.ApiActionJsonBody,
                        editorLocation:
                          EditorScriptLocation.API_ACTION_JSON_BODY,
                      });
                    }}
                    isInline={true}
                    isVisible={true}
                  />
                </Box>
                {!!getScriptValue(EditorScriptLocation.API_ACTION_JSON_BODY)
                  ?.length && (
                  <Button
                    type="button"
                    onClick={() => {
                      setClearScript(true);
                    }}
                  >
                    <span>X</span> Clear the Script
                  </Button>
                )}
              </Box>

              <ZJSEditor isAPIBody={true} setJsScript={setApiBody} />
            </TabPanel>
            <TabPanel
              style={{ padding: "10px", borderTop: "2px solid  #e5e5e5" }}
              value={tabIndex}
              index={2}
            >
              <ApiTestWrapper />
            </TabPanel>
          </Box>
        </div>
        {scriptEditorOpen && <ZJSEditorLayout />}

        <div style={{ display: "flex", bottom: "0", marginBottom: "10px" }}>
          {tabIndex === 2 && (
            <>
              <ZButton
                type="button"
                variant="contained"
                style={{ marginLeft: "20px" }}
                onClick={() =>
                  testAPI({
                    method: getValues().method,
                    url: selectedAction[EditorScriptLocation.AB_API_ACTION_URL],
                    mockData,
                    headers: getHeaderValues(),
                    body: selectedAction.api_action_json_body,
                    dispatch,
                  })
                }
              >
                Test Action
              </ZButton>
              <ZButton
                variant="contained"
                type="button"
                secondary
                className="ml-2"
                disabled={!!apiResponse?.data === false}
                onClick={updateAPIActionMockData}
              >
                Update Test data
              </ZButton>
            </>
          )}
        </div>
      </form>
      {dataPicker?.isOpened && <DatafieldSelector setValue={setValue} />}
      {
        openErrorHandlingModal && (
          <ErrorHandlingModal
            openErrorHandlingModal={openErrorHandlingModal}
            handleModalClose={() => {
              setOpenErrorHandlingModal(false);
            }}
            register={register}
            handleValChange={handleValChange}
            actionExecutionScriptConfig={actionExecutionScriptConfig}
            selectedAction={selectedAction}
            ShowCurrentActionPayload={ShowCurrentActionPayload}
            control={control}
            getValues={getValues}
            watch={watch}
           
          />        
        )
      }

      {openCurlModal && (
        <ImportCurlModal
          openModal={openCurlModal}
          handleClose={() => {
            setOpenCurlModal(false);
          }}
          onImport={(jsonData) => {
            const tempAction = { ...selectedAction };
            setTimeout(() => {
              dispatch(
                setSelectedAction({
                  ...tempAction,
                  hasChanged: true,
                  [EditorScriptLocation.AB_API_ACTION_URL]: {
                    type: "literal",
                    value: `${jsonData.url}`,
                    dataType: TEXT,
                  },
                  [EditorScriptLocation.API_ACTION_JSON_BODY]: {
                    type: "literal",
                    value: JSON.stringify(jsonData.body),
                    dataType: "OBJECT",
                  },
                  method: jsonData.method,
                  headers: jsonData.headers,
                })
              );
            }, 1);
            dispatch(setSelectedAction(null));
            setOpenCurlModal(false);
          }}
        />
      )}
      
      <DeletionConfirmationDialog
        id={"api_action_script_clear"}
        deletionTitle={"Clear the script"}
        deletionText={"Are you sure, you want to clear the script ?"}
        isOpen={clearScript}
        onConfirmDelete={() => {
          const tmpSelectedAction = _.cloneDeep(selectedAction);
          delete tmpSelectedAction.api_action_json_body;
          dispatch(setSelectedAction(tmpSelectedAction));
          dispatch(
            clearSelectedLocationScript({
              state: true,
              editorLocation: EditorScriptLocation.API_ACTION_JSON_BODY,
            })
          );
          setClearScript(false);
        }}
        onClose={() => {
          setClearScript(false);
        }}
      />
    </>
  );
};
export default APIAction;
