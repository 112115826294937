import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import useWorkflow from "views/workflows/hooks/useWorkFlow";
import { useForm } from "react-hook-form";
import { updateDecisionNodeName,setShowUpdateDecisionNodeNameModal } from 'views/workflows/reducers/workflowAction';
import ErrorMessage from "views/DataField/ErrorMessage"
import { recordRSEvent } from "utils/CommonUtils";
import { taskTypeNameSchema } from "views/workflows/ConfigureWorkflow/ConfigureWorkflowValidationSchema";
import {Button} from "views/DataField/DataField.styles"


const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 33vw;
  background-color: white;
  border: 1px solid #e5e5e5;
  padding: 20px;
`;

const Heading = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px !important;
`;

const Label = styled.p`
  margin-top: 20px !important; 
  font-size: 14px;
  font-weight: 600;
`;

const Input = styled.input`
  margin-top: 16px;
  border: 1px solid #e5e5e5;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;



export default function UpdateDecisionNodeNameModal() {
    const dispatch = useDispatch()
  const { showUpdateDecisionNodeModal } = useWorkflow();
  const { handleSubmit,register,formState:{errors} } = useForm(); 

  const handleClose = () => {
      dispatch(setShowUpdateDecisionNodeNameModal(null)) 
  }
  
  const onSubmit = (data:any) => {
    dispatch(updateDecisionNodeName({ nodeId: showUpdateDecisionNodeModal.nodeId, name:data.name }))
    handleClose()
    recordRSEvent("decision_node_name_updated",{
      config:"builder_general",
      decision_node_name:data.newName,
    })
}

  return (
    <div>
      <Modal
        open={showUpdateDecisionNodeModal}
        onClose={handleClose}
        aria-labelledby="configure-workflow"
        aria-describedby="configure-your-workflow-attributes"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Heading> Update Node Name </Heading>
            <hr />
            <Label> Decision Node Name </Label>
            <Input
              defaultValue={showUpdateDecisionNodeModal.name}
              placeholder="Enter a name for your decision node"
              {...register("name",taskTypeNameSchema)}
            />
            <ErrorMessage errors={errors} fieldName={'name'} />

            <ButtonContainer>
              {/* @ts-ignore */}
              <Button onClick={handleClose} variant="secondary">
                {" "}
                Cancel{" "} 
              </Button>
              {/* @ts-ignore */}
              <Button type="submit" variant="primary"> Update </Button>
              
            </ButtonContainer>
          </Container>
        </form>
      </Modal>
    </div>
  );
}
