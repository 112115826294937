// import { stringify } from 'query-string';
import { isEmpty, isString, orderBy } from 'lodash';
import { getDateFirstFormat, getFullDate, getLocalizedTime } from 'utils/DateFunctions.utils';
import { filterObjects, replaceArray } from 'utils/WorkFlow.utils.ts';
import { CommunicationService } from './services/CommunicationService';
import { RoleService } from './services/RoleService';
import { TaskFlowService } from './services/TaskFlowService';
import { TaskService } from './services/TaskService';
import { TeamService } from './services/TeamService';
import { UserService } from './services/UserService';

const dataProvider = {
  getList: (resource, params) => {
    if (resource === 'alerts') {
      return CommunicationService.listAlerts(params).then((response) => {
        response?.data?.alerts?.forEach((alert) => {
          alert.id = alert?.alertId;
          alert.teamsInfo = alert?.teams.map((team) => team.name + ' (' + team?.teamId + ')').join(',');
          alert.createdOn = getFullDate(alert?.createdOn, '-');
          if (alert.entity && alert.entityId) {
            let value = '';
            if (alert.entity === 'TASK') {
              value = `tasks/${alert.entityId}/show`;
            } else if (alert.entity === 'USER') {
              value = `users/${alert.entityId}/show`;
            } else if (alert.entity === 'TEAM') {
              value = `teams/${alert.entityId}/show`;
            }
            alert.entityUrl = value;
            alert.entityText = alert.entity + ' (' + alert.entityId + ')';
          }
        });
        return {
          data: response.data.alerts,
          total: response.data.count,
        };
      });
    }

    if (resource === 'users') {
      return UserService.listUsers(params).then((response) => {
        response?.data?.users?.forEach((user) => {
          user.id = user?.userId;
          var phone = user?.phone?.num ? user?.phone?.num : '';
          var countryCode = user?.phone?.countryCode ? user?.phone?.countryCode + '-' : '';
          user.phone = countryCode + phone;
          var s = user.role;

          s = s.split('_').join(' ');
          user.roleId = user.role;
          user.role = RoleService.findRoleFromLocalStorage(user.role);
          user.teamsInfo = user?.teams?.map((team) => team.name + ' (' + team?.teamId + ')').join(',');
        });
        return {
          data: response.data.users,
          total: response.data.count,
        };
      });
    }

    if (resource === 'teams') {
      return TeamService.listTeams(params).then((response) => {
        response?.data?.teams?.map((team) => {
          team.id = team?.teamId;
          team.parentTeamIdWithName = team?.parentTeam
            ? team?.parentTeam?.name + ' (' + team?.parentTeam?.teamId + ')'
            : '';
        });
        return {
          data: response.data.teams,
          total: response.data.count,
        };
      });
    }

    if (resource === 'roles') {
      return RoleService.listRoles()
        .then((response) => {
          response?.data?.map((role) => {
            role.id = role?.roleId;
            return role;
          });
          return {
            data: response?.data,
            total: response?.data?.length,
          };
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (resource === 'workflows') {
      const workflows = [];
      return TaskFlowService.listDraftWorkflows()
        .then((response) => {
          response?.data?.forEach((workflow) => {
            const flow = {};
            flow.id = flow.taskType = workflow.taskType;
            flow.status = 'Draft';
            flow.taskTypeDisplayName = workflow.taskTypeDisplayName;
            flow.updatedOn = workflow.updatedOn;
            flow.publishedBy = workflow?.updatedBy;
            flow.updatedBy = workflow?.updatedBy;
            workflows.push(flow);
          });
          return TaskFlowService.listActiveWorkflows(params)
            .then((response) => {
              response?.data?.forEach((flow) => {
                const index = workflows.findIndex((w) => w.id === flow?.taskType);
                if (index === -1) {
                  flow.id = flow?.taskType;
                  flow.status = flow?.isActive ? 'Active' : 'Inactive';
                  flow.updatedOn = flow?.updatedOn;
                  flow.publishedBy = flow?.updatedBy;
                  workflows.push(flow);
                }
                // This happens if there is a draft workflow for an already published active workflow
                else if (index > -1){
                    const workflow = workflows[index];
                    workflow.isActiveDraft = true;
                }

              });
              if (!isEmpty(params.filter)) {
                replaceArray(workflows, filterObjects(workflows, 'status', params.filter));
              }
              return {
                data: orderBy(workflows, ['updatedOn'], ['asc']).reverse(),
                total: workflows.length,
              };
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (resource === 'tasks') {
      return TaskService.listTasks(params).then((response) => {
        if (response && response.code === '200') {
          const tasks = response?.data?.tasks;
          tasks?.map((task) => {
            task.id = task?.taskId;

            // team names
            task.teamsInfo = task?.teams?.map((team) => team.name + ' (' + team?.teamId + ')').join(',');

            // Order Ids
            var orderIds = [];
            var totalProducts = 0;
            if (task?.data) {
              if (task?.data instanceof Array) {
                task?.data?.map((order) => {
                  orderIds.push(order?.orderId || order?.info?.orderId);
                });
              } else {
                if (task?.data?.orderInfo) orderIds.push(task?.data?.orderInfo[0]?.orderId);
              }
            }

            if (task?.data && task.taskType !== 'field_visit_task' && task.taskType !== 'pickup_and_drop_task') {
              Array.isArray(task?.data) &&
                task?.data?.map((order) => {
                  if (order?.products?.PICKUP) totalProducts = totalProducts + order?.products?.PICKUP?.length;
                  if (order?.products?.PICKUP) totalProducts = totalProducts + order?.products?.PICKUP?.length;
                  if (order?.products?.PICKUP) totalProducts = totalProducts + order?.products?.PICKUP?.length;
                });
            }

            if (orderIds.length > 1) task.orderIds = orderIds[0] + ' +' + (orderIds.length - 1).toString() + 'more';
            else {
              task.orderIds = orderIds[0];
            }

            var slot = 'NA';
            if (task && task.scheduledSlot && task?.scheduledSlot?.from && task?.scheduledSlot?.to) {
              const dtFrom = getDateFirstFormat(task?.scheduledSlot?.from);
              const sltFrom = getLocalizedTime(task?.scheduledSlot?.from);
              const sltTo = getLocalizedTime(task?.scheduledSlot?.to);
              slot = `${dtFrom} , ${sltFrom} - ${sltTo}`;
            }

            task.slot = slot;

            // Task Type Name Slice
            var taskTypeDisplayName = task?.taskTypeDisplayName;
            // task.taskTypeDisplayName = taskTypeDisplayName.slice(0, taskTypeDisplayName.length - 5);

            // Created Date
            if (task && task.createdOn) {
              const createdDate = getFullDate(task.createdOn, '-');
              task.createdOn = createdDate;
              const updatedDate = getFullDate(task.updatedOn, '-');
              task.updatedOn = updatedDate;
            }

            // Customer Details
            if (task.data) {
              if (task.data instanceof Array) {
                let customerDetails = {};
                task.data.map((order) => (customerDetails.name = order.info?.customer?.name));
                task.data.map((order) => (customerDetails.address = order.info?.customer?.location?.address));
                task.customerDetails = customerDetails;

                let pickupDetails = {};
                task.data.map((order) => (pickupDetails.name = order.info?.pickup?.name));
                task.data.map((order) => (pickupDetails.address = order.info?.pickup?.location?.address));
                task.pickupDetails = pickupDetails;
              } else {
                let customerDetails = {};
                customerDetails.name = task.data.orderInfo ? task.data.orderInfo[0]?.customer?.name : '';
                customerDetails.address = task.data.orderInfo
                  ? task.data.orderInfo[0]?.customer?.location?.address
                  : '';
                task.customerDetails = customerDetails;

                let pickupDetails = {};
                pickupDetails.name = task.data.orderInfo ? task.data.orderInfo[0]?.pickup?.name : '';
                pickupDetails.address = task.data.orderInfo ? task.data.orderInfo[0]?.pickup?.location?.address : '';
                task.pickupDetails = pickupDetails;
              }
            }
          });
          return {
            data: tasks,
            total: response.data.count,
            // total: 100000
          };
        }
      });
    }
  },

  getOne: (resource, params) => {
    if (resource === 'users') {
      return UserService.getUser(params.id).then((response) => {
        response.data.id = response.data.userId;
        var teams = response?.data?.teamIds;
        response.data.team = teams.join(', ');
        var role = response?.data?.role;
        role = role.split('_').join(' ');
        // response.data.role = role.charAt(0).toUpperCase() + role.slice(1);
        var cc = '';
        if (response?.data?.phone?.countryCode) cc = response?.data?.phone?.countryCode;
        response.data.phoneNum = cc + '-' + (response?.data?.phone?.num || '');
        if (response?.data?.isActive) response.data.activeStatus = 'Active';
        else response.data.activeStatus = 'InActive';
        var updatedOnDate = response?.data?.updatedOn;
        var createdOnDate = response?.data?.createdOn;
        response.data.updatedOn = getFullDate(updatedOnDate, '-');
        response.data.createdOn = getFullDate(createdOnDate, '-');

        return { data: response.data };
      });
    }

    if (resource === 'teams') {
      return TeamService.getTeam(params.id).then((response) => {
        let team = response.data[0];
        team.createdOn = getFullDate(team.createdOn, '-');
        team.id = response.data[0].teamId;
        return { data: team };
      });
    }

    if (resource === 'roles') {
      
        return RoleService.getRole(params.id).then((response) => {
          let role = response.data;
          role.createdOn = getFullDate(role.createdOn, '-');
          role.id = response.data.roleId;
          return { data: role };
        });
    }

    if (resource === 'alerts') {
      return CommunicationService.getAlert(params.id).then((response) => {
        let alert = response?.data;
        alert.id = alert.alertId;
        alert.createdOn = getFullDate(alert.createdOn, '-');
        // team names
        alert.teamsInfo = alert?.teams.map((team) => team.name + ' (' + team?.teamId + ')').join(',');
        return { data: alert };
      });
    }

    if (resource === 'tasks') {
      return TaskService.getTask(params.id).then((response) => {
        const task = response?.data;
        task.id = task.taskId;

        // team names
        task.teamsInfo = task?.teams?.map((team) => team.name + ' (' + team?.teamId + ')').join(',');

        var start = 'NA';
        var end = 'NA';
        if (task && task.scheduledSlot && task?.scheduledSlot?.from && task?.scheduledSlot?.to) {
          const dtFrom = getDateFirstFormat(task?.scheduledSlot?.from);
          const dtTo = getDateFirstFormat(task?.scheduledSlot?.to);
          const sltFrom = getLocalizedTime(task.scheduledSlot.from);
          const sltTo = getLocalizedTime(task.scheduledSlot.to);
          start = dtFrom + ' ' + sltFrom;
          end = dtTo + ' ' + sltTo;
          task.start = start;
          task.end = end;
        }

        task.pickup = task?.data?.orderInfo ? task?.data?.orderInfo[0]?.pickup : undefined;
        task.drop = task?.data?.orderInfo ? task?.data?.orderInfo[0].customer : undefined;
        task.complaint = task?.data?.orderInfo ? task?.data?.orderInfo[0]?.complaint : undefined;
        var orderIds = [];
        task?.data?.orderInfo?.map((info) => {
          orderIds.push(info.orderId);
        });
        task.orderId = orderIds.join(', ');
        task.mapCenter = [task?.drop?.location?.latitude, task?.drop?.location?.longitude];

        // task.customerName = task?.data?.orderInfo[0]?.customer?.name;

        // task.location = task?.data?.orderInfo[0]?.customer?.location?.address;
        // var countryCode = task?.data?.orderInfo[0]?.customer?.phone?.countryCode ? task?.data?.orderInfo[0]?.customer?.phone?.countryCode + "-" : "";
        // var phone = task?.data?.orderInfo[0]?.customer?.phone?.num ? task?.data?.orderInfo[0]?.customer?.phone?.num : ""

        // if (task?.data?.orderInfo[0]?.customer?.location?.latitude &&
        //     task?.data?.orderInfo[0]?.customer?.location?.longitude) {
        //     task.lat = task?.data?.orderInfo[0]?.customer?.location?.latitude;
        //     task.long = task?.data?.orderInfo[0]?.customer?.location?.longitude;
        // }

        // if (phone)
        //     task.phone = countryCode + phone;

        return { data: task };
      });
    }
  },

  create: (resource, params) => {
    if (resource === 'users') {
      let data = { ...params.data, teamIds: [1, 2, 3] };
      return UserService.createUser(data).then((response) => {
        response.data.id = response.data.userId;
        return { data: response.data };
      });
    }
  },

  update: (resource, params) => {
    return UserService.userUpdate(params.data).then((response) => {
      response.data.id = response.data.userId;
      return { data: response.data };
      // return {data : {...params.data}};
    });
  },

  // delete: (resource, params) => {
  //   if (resource === 'tasks') {
  //     TaskService.deleteTask(params.id).then((response) => {
  //       return { data: response };
  //     });
  //   }
  // },
};

export default dataProvider;
