import trigger from "assests/trigger.svg";
import { CONTEXT_SWITCHING, CONTEXT_SWITCHING_CONFIRMATION } from "constants/CommonMessages";
import { useState } from "react";
import { BiCopy, BiCopyAlt } from "react-icons/bi";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import useAction from "views/actions/useAction";
import useAutomation from 'views/automationBuilder/hooks/useAutomation';
import { ZButton } from "views/commonComponents/commonComponents.styles";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import InfoIcon from "views/workflows/Info/InfoIcon";
import { ABDisabledText, ABDisabledTextWrapper, ActionInnerContent, TriggerBody, UnSelectedText, VerticalAlignWrapper } from "../automationBuilder.styles";
import { setSelectedActionBlock } from "../reducer/automationReducer";
import { ABMetadataTypeEnum, IActionBlock, IConfiguration } from "../types/ABType";
import { getActionIcon, setSelectedFunction } from "../utils/util";
import ConfirmationDialog from "./ConfirmationDialog";

type Props = {
  valueSelected: Boolean;
  buttonTitle: string;
  placeHolderText: string;
  onDelete: () => void;
  payload: any;
  showArrow?: boolean;
  showDelete?: boolean;
  showConfigure?: boolean;
  actionBlocksCount?: number;
  actionBlockIndex?: number;
  rowIndex: number;
  listLength: number;
  type: IConfiguration,
  actionBlock?: IActionBlock;
  name: string
  copyAction?: () => void;
  rearrangeUp?: () => void;
  addNewBlock?: () => void;
  rearrangeDown?: () => void;
  moveToAboveActionBlock?: () => void;
  moveToBelowActionBlock?: () => void;
  errors?: string[]
};

const BlockContent = ({
  valueSelected,
  buttonTitle,
  placeHolderText,
  showArrow,
  showDelete = true,
  showConfigure = true,
  actionBlocksCount,
  actionBlockIndex,
  onDelete,
  payload,
  rowIndex,
  addNewBlock,
  copyAction,
  rearrangeUp,
  rearrangeDown,
  listLength,
  type,
  actionBlock,
  moveToAboveActionBlock,
  moveToBelowActionBlock,
  errors = [],
  name
}: Props) => {

  const dispatch = useDispatch();
  const { selectedConfiguration, selectedTrigger, selectedActionBlock, checkConfigureOpenInRight, otherConfigs, metadataType } = useAutomation();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { selectedAction } = useWorkFlow();
  const { copiedAction } = useAction();

  const changeConfigurationInRight = () => {

    // this logic is to avoid the clicking the same action, or condition which is on right side, that will leads to data loss.
    if ((type === 'action' && (payload?.actionId !== selectedAction?.actionId || !payload?.actionId) ) || (type === 'condition' && (payload?.conditionId !== selectedAction?.conditionId || !payload?.conditionId)) || (type === 'guard' && (payload?.guardId !== selectedAction?.guardId || !payload?.guardId)) || (type === 'trigger' && (payload?.triggerId !== selectedTrigger?.triggerId || !payload?.triggerId))) {
      dispatch(setSelectedActionBlock({ actionBlock: actionBlock }));
      setSelectedFunction({ dispatch, type, payload });
    }
  }

  const handleClick = () => {
    if (checkConfigureOpenInRight && selectedAction?.hasChanged) {
      setOpenModal(true);
    } else {
      changeConfigurationInRight();
    }
  }

  const ClickNewBlock = () => {
    if (checkConfigureOpenInRight && selectedAction?.hasChanged) {
      setOpenModal(true);
    } else {
      addNewBlock?.();
    }
  }

  // prefill trigger data during screen transition and decision node
  const preFillTrigger = otherConfigs?.preFillTrigger;

  // this to change the block border color to blue, when it is Selected.
  // logic to check whether block is selected or not.

  let selected = false;
  if (type == "trigger") {
    selected = (selectedConfiguration == type) && (selectedTrigger?.triggerId == payload?.triggerId);
  } else if (type == "guard") {
    selected = (selectedConfiguration == type) && (selectedAction?.guardId == payload?.guardId);
  } else if (type == "action") {
    selected = (selectedConfiguration == type) && (selectedAction?.actionId == payload?.actionId) && (actionBlock?.actionBlockId == selectedActionBlock?.actionBlockId);
  } else if (type == "condition") {
    selected = (selectedConfiguration == type) && (selectedAction?.conditionId == payload?.conditionId) && (actionBlock?.actionBlockId == selectedActionBlock?.actionBlockId);
  }

  return (
    <>
      {valueSelected && (
        <TriggerBody NotSelected={true} className={(type == "trigger" && preFillTrigger) ? 'disabledImg' : 'pointer'} onClick={() => {
          if (!(type == "trigger" && preFillTrigger)) {
            handleClick();
          }
        }} filledState={valueSelected} selected={selected} errors={errors?.length > 0}>
          <ActionInnerContent>
            {/* <img
              style={{ height: "16px", marginRight: "10px" }}
              src={trigger}
              alt="fnIcon"
            /> */}
            {payload?.actionType ? (
              <div
                style={{
                  marginRight:"10px"
                }}
              >
                {getActionIcon(payload?.actionType)}
              </div>
            ) : (
              <img
                style={{ height: "16px", marginRight: "10px" }}
                src={trigger}
                alt="fnIcon"
              />
            )}

            
            { // show this when the condition or validation or the action, active is false
              (payload?.isActive == false) &&
              <ABDisabledTextWrapper>
                <ABDisabledText>DISABLED</ABDisabledText>
              </ABDisabledTextWrapper>
            }

            <p>
              {name}
              {errors?.length > 0 && (
                <span style={{ marginLeft: '5px' }}>
                  <InfoIcon type="error" item={errors} />
                </span>
              )}
            </p>

          </ActionInnerContent>
          <VerticalAlignWrapper applyMargin={false}>
            {
              showConfigure &&
              <ZButton
                secondary
                variant="contained"
                onClick={handleClick}
                type="button"
                className="mr-2 pl-10"
              >
                {buttonTitle}
              </ZButton>
            }

            {/* Move Actions Above inside the same block */}
            {(rowIndex !== 0 && showArrow) && (
              <HiOutlineArrowSmUp
                color={"#616569"}
                size={"1.3rem"}
                onClick={(e) => {
                  e.stopPropagation();
                  rearrangeUp?.()
                }}
                className="pointer ml-1 mr-3"
              />
            )}

            {/* Move Action to the next action block above */}
            {((actionBlocksCount > 1 && rowIndex == 0 && actionBlockIndex != 0 && actionBlockIndex < actionBlocksCount)) && (
              <HiOutlineArrowSmUp
                color={"#616569"}
                size={"1.3rem"}
                onClick={(e) => {
                  e.stopPropagation();
                  // Move one action block above
                  moveToAboveActionBlock?.();
                }}
                className="pointer ml-1 mr-3"
              />
            )}


            {/* Move Actions below inside the same block */}
            {(rowIndex !== listLength - 1 && showArrow) && (
              <HiOutlineArrowSmDown
                color={"#616569"}
                size={"1.3rem"}
                onClick={(e) => {
                  e.stopPropagation();
                  rearrangeDown?.()
                }}
                className="pointer ml-1 mr-3"
              />
            )}

            {/* Move Action to the below action block */}
            {(actionBlocksCount > 1 && (actionBlockIndex < actionBlocksCount && actionBlockIndex != actionBlocksCount - 1) && rowIndex == listLength - 1) && (
              <HiOutlineArrowSmDown
                color={"#616569"}
                size={"1.3rem"}
                onClick={(e) => {
                  e.stopPropagation();
                  // Move one action block below
                  moveToBelowActionBlock?.();
                }}
                className="pointer ml-1 mr-3"
              />
            )}


            {
              showDelete &&
              <RiDeleteBin6Line
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
                color="#606569"
                className="pointer ml-1 mr-3"
              />
            }
            {/* For Trigger show the copy button only for smart and onload automation */}
            {
              ((type == 'trigger' && (metadataType === ABMetadataTypeEnum.WORKFLOW_SCREEN_SMART_COMPONENT)) || type === 'condition' || type === 'guard' || type === 'action') &&
              <>
                {
                  (copiedAction?.originalId && (copiedAction?.originalId === payload?.triggerId || copiedAction?.originalId === payload?.guardId || copiedAction?.originalId === payload?.actionId || copiedAction?.originalId === payload?.conditionId)) ?
                    <BiCopyAlt
                      color="#606569"
                      onClick={(e) => {
                        e.stopPropagation();
                        copyAction?.();
                      }} 
                    />
                    :
                    <BiCopy color="#606569" onClick={(e) => {
                      e.stopPropagation();
                      copyAction?.();
                    }} />

                }
              </>
            }


          </VerticalAlignWrapper>
        </TriggerBody>
      )}

      {!valueSelected && (

        <TriggerBody NotSelected={false} className='pointer' onClick={ClickNewBlock} errors={false} selected={selected} filledState={valueSelected}>
          <ActionInnerContent >
            <UnSelectedText>{placeHolderText}</UnSelectedText>
          </ActionInnerContent>
        </TriggerBody>

      )}

      <ConfirmationDialog
        id={"context_switching"}
        deletionTitle={CONTEXT_SWITCHING}
        deletionText={CONTEXT_SWITCHING_CONFIRMATION}
        isOpen={openModal}
        primaryButtonName="No"
        secondaryButtonName="Yes"
        onPrimaryBtnClick={() => {
          setOpenModal(false);
        }}
        onSecondaryClick={() => {
          setOpenModal(false);
          changeConfigurationInRight();
        }}
        onClose={()=>{
          setOpenModal(false);
        }}
      />

    </>
  );
};

export default BlockContent;
