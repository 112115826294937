import parse from "@bany/curl-to-json";
import FilterModalClose from "assests/FilterModalClose.svg";
import CurlDocumentation from "assests/curl_documentation.svg";
import CurlIcon from "assests/import_curl.svg";
import notification from "notifications/notifications";
import { useState } from "react";
import { DataFieldType } from "views/DataField/DataField.types";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { CurlBodyText, CurlBodyWrapper, CurlDocumentationTxt, CurlFooterLeftWrapper, CurlFooterRightWrapper, CurlModal, CurlModalBody, CurlModalContainer, CurlModalFooter, CurlModalHeader, CurlTextArea, HeaderContentWrapper, ImportCurlHeaderInfoWrapper, ImportCurlHeading, ImportCurlSentence, ImportIconWrapper } from './CurlStyledComponents';

interface IImportCurlModal {
    openModal: boolean;
    handleClose: () => void;
    onImport: (jsonStructure: any) => void; 
}

const ImportCurlModal = ({ openModal, handleClose, onImport }: IImportCurlModal) => {
    const [curl, setCurl] = useState<string>('');

    const convertCurlToJson = async (curlData:string) => {
        var curlImportData:any = parse(curlData);

        // if the validation success then
        if (curlImportData.url) {

                // if the validation is valid
                    try {
                        const data = {
                            method: curlImportData.method,
                            url: curlImportData.url,
                            headers: Object.keys(curlImportData.header || {}).map((data:any) => {
                                const valueData = {
                                        type: 'literal',
                                        value: curlImportData?.header[data],
                                        dataType: DataFieldType.TEXT,
                                      }

                                return { key:data, value: valueData };
                            }),
                            body: curlImportData.data || {},
                        }
                        onImport(data)
                    } catch (e) {
                        notification("error", 'Enter Valid curl command');
                    }
              
        } else {
            // if the validation fails then
            notification("error", 'Enter Valid curl command');
        }

    }

    return (
        <CurlModal
            open={openModal}
            onClose={handleClose}>
            <CurlModalContainer>
                <CurlModalHeader>

                    <HeaderContentWrapper>
                        <ImportIconWrapper>
                            <img src={CurlIcon} />
                        </ImportIconWrapper>
                        <div><img src={FilterModalClose} className='cursor-pointer' onClick={handleClose} /></div>
                    </HeaderContentWrapper>


                    <ImportCurlHeaderInfoWrapper>
                        <ImportCurlHeading>Import Curl</ImportCurlHeading>
                        <ImportCurlSentence>Paste the Curl String in the input area below</ImportCurlSentence>
                    </ImportCurlHeaderInfoWrapper>

                </CurlModalHeader>



                <CurlModalBody>
                    <CurlBodyWrapper>
                        <CurlBodyText>Enter Curl String</CurlBodyText>
                        <CurlTextArea style={{ overflow: 'auto', width:"97%" }} placeholder="Enter Curl String here" value={curl} onChange={(e) => {
                            setCurl(e.target.value);
                        }} />
                    </CurlBodyWrapper>
                </CurlModalBody>



                <CurlModalFooter>
                    <CurlFooterLeftWrapper>
                        <div className="pr-2" ><img src={CurlDocumentation} /></div>
                        <CurlDocumentationTxt onClick={()=>{
                                window.open(
                                    'https://docs.zorp.one/product-docs/workflow-builder/automations/actions/rest-api-action#import-curl',
                                    '_blank' // <- This is what makes it open in a new window.
                                  );
                                  
                        }}> Read Documentation</CurlDocumentationTxt>
                    </CurlFooterLeftWrapper>

                    <CurlFooterRightWrapper>

                        <ZButton variant="contained" secondary onClick={handleClose}>
                            Cancel
                        </ZButton>

                        <ZButton variant="contained" 
                        // primary
                            
                            onClick={() => {
                                convertCurlToJson(curl);
                            }}>
                            Import
                        </ZButton>

                    </CurlFooterRightWrapper>
                </CurlModalFooter>
            </CurlModalContainer>
        </CurlModal>
    )
}

export default ImportCurlModal