import React, { useState, useRef, useEffect, memo } from 'react';
import { InfoTooltip as Tooltip } from "views/workflows/Tootltips/InfoTooltip";
import RelationCellBadge from './RelationCellBadge';

type RelationCellWithTooltipProps = {
    relationContent: any;
    values: any[];
};

const RelationCellWithTooltip: React.FC<RelationCellWithTooltipProps> = ({ relationContent, values }) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const cellRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (cellRef.current) {
            setIsOverflowing(cellRef.current.scrollWidth > cellRef.current.clientWidth);
        }
    }, []);

    const redirectRelationClick = (relation: any) => {
            const relationType = relation?.taskType ? "task" : "table";
            const relationTypeId = relation?.taskType ? relation?.taskType : relation?.tableType;
            const relationId = relation?.taskId ? relation?.taskId : relation?.recordId;
            window.open(`/#/${relationType}/${relationTypeId}/show/${relationId}`, '_blank');
    }

    const relationContainer = (
        <div ref={cellRef} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100%' }}>
            {relationContent?.map((relation: string, index: number) => (
                <RelationCellBadge
                    key={index}
                    //@ts-ignore
                    width='max-content'
                    value={values[index] || relation?.recordId}
                    backgroundColor={"#F2F4F7"}
                    color={"#3054B9"}
                    onClick={() => redirectRelationClick(relation)}
                />
            ))}
        </div>
    );

    return (
        isOverflowing ? (
            <Tooltip title={values.join(', ')} arrow>
                {relationContainer}
            </Tooltip>
        ) : (
            relationContainer
        )
    );
};

export default memo(RelationCellWithTooltip);