import DateFnsUtils from '@date-io/date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DatePicker from '@mui/lab/DatePicker';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import L from 'leaflet';
import moment from 'moment';
import { AssignmentService } from 'providers/data/services/AssignmentService';
import React, { useEffect, useRef } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import Select from 'react-select';
import { DataFieldType } from 'views/DataField/DataField.types';
import { TaskService } from '../../providers/data/services/TaskService';
import TaskTypeSelect from '../tasks/components/Tasktype.js';
import { TaskService as TaskService2 } from '../tasks/services/TaskService';
import TeamSelect from '../teams/Teams.js';
import Table from './ArrayToTable';
import locationIconPath from './location_marker.png';
import userIdleIconPath from './user_idle.svg';
import userBusyIconPath from './user_busy.svg';
import './zmap.css';
import { getFullDateHour } from 'utils/DateFunctions.utils';
import RefreshIcon from '@mui/icons-material/Refresh';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const locationIcon = L.icon({
    iconUrl: locationIconPath,
    iconSize: [30, 35]
});

const userIdleIcon = L.icon({
    iconUrl: userIdleIconPath,
    iconSize: [18, 18]
});
const userBusyIcon = L.icon({
    iconUrl: userBusyIconPath,
    iconSize: [18, 18]
});

const GetUserPopupContent = ({user}) => {

    return (
        <div>
            User Id -{' '}
            <b>
                <i>{user.userId}</i>
            </b>
            <br />
            User Name -{' '}
            <b>
                <i>{user.name}</i>
            </b>
            <br />
            Current No. Tasks -{' '}
            <b>
                <i>{user?.tasks?.length}</i>
            </b>
            <br />
            Last Updated At -{' '}
            <b>
                <i>{user.userLocation ? getFullDateHour(new Date(user.userLocation.ts).toISOString()) : 'Unknown'}</i>
            </b>
        </div>
    );
}

const GetTaskPopupContent = ({users, taskAndCoordinatesPair, selectedLocationDataField, selectedTeam}) => {
    const handleAssignButtonClick = (userId, taskId) => {
        console.log("UI : " + userId  + " Task : " + taskId);
    }

    function haversineDistance(lat1, lon1, lat2, lon2) {
        function toRad(x) {
            return x * Math.PI / 180;
        }

        let R = 6371000; // meters

        let x1 = lat2 - lat1;
        let dLat = toRad(x1);
        let x2 = lon2 - lon1;
        let dLon = toRad(x2)

        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = R * c;

        return d;
    }

    const userPositionsColumns = [
        {
            heading: 'User',
            property: 'userInfo'
        },
        {
            heading: 'Current Tasks',
            property: 'tasks'

        },
        {
            heading: 'Distance (KM)',
            property: 'distance'
        },
        {
            heading: 'Assign',
            property: 'assignInfo'
        }
    ]
    let positions = [];
    users?.forEach((user) => {
        if (user.userLocation) {
            positions.push({
                latitude: user.userLocation.latitude,
                longitude: user.userLocation.longitude,
                userInfo: user.name + "(" + user.userId + ")",
                tasks: user?.tasks?.length,
                distance: (haversineDistance(user.userLocation.latitude, user.userLocation.longitude, taskAndCoordinatesPair.coordinates[0], taskAndCoordinatesPair.coordinates[1]) / 1000 * 1.4).toFixed(2),
                assignInfo: <Button variant="contained" onClick={()=>{
                    handleAssignButtonClick(user.userId, taskAndCoordinatesPair.task.taskId)
                }}>Assign</Button>
            });
        }
    });
    // let div = document.createElement('div');
    // ReactDOM.render(<Table
    //     columns={userPositionsColumns}
    //     data={positions.sort(function(a, b) { return a.distance - b.distance; })}
    //     propertyAsKey='userId' //The data property to be used as a key
    // />, div);


    return (<div>
        <b>{selectedLocationDataField} Address : </b> {taskAndCoordinatesPair.coordinates[0]}  ,  {taskAndCoordinatesPair.coordinates[1]}
        <br />
        {taskAndCoordinatesPair['task'].data[selectedLocationDataField].address}
        <br /><br />
        <b> User Positions : </b>

        <br />
        <Table
            columns={userPositionsColumns}
            data={positions.sort(function (a, b) { return a.distance - b.distance; })}
            propertyAsKey='userId' //The data property to be used as a key
        />
    </div>);
}


const ZMap = () => {
    const mapRef = useRef(null);
    // const markersRef = useRef([]);

    const [selectedTeam, setSelectedTeam] = React.useState('');
    const [selectedTaskType, setSelectedTaskType] = React.useState('');
    const [selectedLocationDataField, setSelectedLocationDataField] = React.useState('');
    const [locationDataFields, setLocationDataFields] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [selectedDateStartTime, setSelectedDateStartTime] = React.useState(moment(new Date()).startOf('day').toISOString());
    const [selectedDateEndTime, setSelectedDateEndTime] = React.useState(moment(new Date()).endOf('day').toISOString());
    // const [tasks, setTasks] = React.useState([]);
    // const [users, setUsers] = React.useState([]);
    // const [expanded, setExpanded] = React.useState(true);
    const [taskAndCoordinates, setTaskAndCoordinates] = React.useState([]);
    const [userAndCoordinates, setUserAndCoordinates] = React.useState([]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setSelectedDateStartTime(moment(date).startOf('day').toISOString());
        setSelectedDateEndTime(moment(date).endOf('day').toISOString());
    };

    const handleTeamChange = (sTeam) => {
        setSelectedTeam(sTeam);
    };

    const handleTaskTypeChange = (sTaskType) => {
        setSelectedTaskType(sTaskType.taskType);
    };

    const handleLocationDataFieldChange = (sLocationDataField) => {
        setSelectedLocationDataField(sLocationDataField.value);
    };

    const handleFilterButtonClick = async () => {
        // Tasks coordinates
        let tasks = selectedLocationDataField ? await fetchTasks() : [];
        const taskAndCoordinates = tasks.filter(entry => entry.data && entry.data[selectedLocationDataField]).map(entry => { return { task: entry, coordinates: [entry.data[selectedLocationDataField]?.latitude, entry.data[selectedLocationDataField]?.longitude] } });
        setTaskAndCoordinates(taskAndCoordinates);

        // User coordinates
        let users = await fetchUsers();
        const userAndCoordinates = users.filter(entry => entry.userLocation).map(entry => { return { user: entry, coordinates: [entry.userLocation?.latitude, entry.userLocation?.longitude] } });
        setUserAndCoordinates(userAndCoordinates);

       
        if (mapRef.current) {
            const tCoordinates = taskAndCoordinates.map(e => e.coordinates);
            const uCoordinates = userAndCoordinates.map(e => e.coordinates);
            if (tCoordinates?.length > 0 || uCoordinates?.length > 0) {
                const bounds = L.latLngBounds(...tCoordinates, ...uCoordinates);
                try {
                    mapRef.current.fitBounds(bounds);
                } catch(err) {
                    console.log("MAP ERROR : " + err);
                }
            }
        }
    };

    useEffect(() => {
        if (mapRef.current){
            handleFilterButtonClick();
        }
    }, [mapRef.current]);
   
    const fetchTasks = async () => {
        let params = {};
        let filter = {};
        let pagination = {};
        let sort = {};
        // sort.field = 'scheduledSlot.from';
        // sort.order = 'ASC';
        pagination.page = 1;
        pagination.perPage = 1000;
        filter.userId = "NONE";
        filter.excludeDeleted = true;
        filter.scheduledFromTime = selectedDateStartTime;
        filter.scheduledToTime = selectedDateEndTime;
        filter.teamIds = selectedTeam ? [selectedTeam] : undefined;
        filter.taskTypes = selectedTaskType ? [selectedTaskType] : undefined;
        params.filter = filter;
        params.pagination = pagination;
        params.sort = sort;

        let tasks = [];
        const response = await TaskService.listTasks(params);
        if (response && response.code === '200') {
            tasks = response?.data?.tasks;
        }

        return tasks;
    }

    const fetchUsers = async () => {
        var teamIds = selectedTeam ? [selectedTeam] : undefined;
        let payload = {};
        payload = {
            teamIds: teamIds,
            from: selectedDateStartTime,
            to: selectedDateEndTime,
        };

        let users = [];
        const response = await AssignmentService.getTeamUsers(payload);
        if (response && response.code === '200') {
            users = response?.data;
        }

        return users;
    }

    useEffect(() => {
        async function fetchLocationDataFieldList() {
            try {
                const response = await TaskService2.getTaskTypeDataFields(selectedTaskType);
                let locDataFields = [];
                if (response?.data) {
                    locDataFields = response?.data[0]?.customFields.filter(entry => entry.type === DataFieldType.LOCATION).map(entry => { return { label: entry.name, value: entry.name } });
                }
                setLocationDataFields(locDataFields);
            } catch (error) {
                console.error("** res something went wrong", error);
            }
        };
        fetchLocationDataFieldList();
    }, [selectedTaskType]);

    // useEffect(() => {
    // if (!mapRef.current) {
    //     // Create a map instance
    //     const map = L.map('map').setView([51.505, -0.09], 13);

    //     // Create tile layer using OpenStreetMap
    //     L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //     attribution: 'Map data © OpenStreetMap contributors'
    //     }).addTo(map);

    //     mapRef.current = map;
    // }

    // const map = mapRef.current;

    // // Remove existing markers
    // markersRef.current.forEach(marker => {
    //     map.removeLayer(marker);
    // });

    // async function renderMarkers () {
    //     // Tasks coordinates
    //     let tasks = selectedLocationDataField ? await fetchTasks() : [];
    //     const taskAndCoordinates = tasks.filter(entry => entry.data && entry.data[selectedLocationDataField]).map(entry =>  { return {task : entry, coordinates : [entry.data[selectedLocationDataField]?.latitude, entry.data[selectedLocationDataField]?.longitude]}});

    //     // User coordinates
    //     let users = await fetchUsers();
    //     const userCoordinates = users.filter(entry => entry.userLocation).map(entry =>  { return [entry.userLocation?.latitude, entry.userLocation?.longitude]});

    //     // Create markers for each coordinate set
    //     const markersTasks = taskAndCoordinates.map(pair => {
    //             const marker = L.marker(pair.coordinates, { icon: locationIcon }).addTo(map);
    //             // marker.bindPopup(getTaskPopupContent(users, pair));
    //             let div = document.createElement('div');
    //             // ReactDOM.render(<button onClick={testCall() => (users[0].userId)}>click111</button>, div);
    //             marker.bindPopup(div);
    //             return marker;
    //         }
    //     );

    //     const markersUsers = userCoordinates.map(coord =>
    //         L.marker(coord, { icon: userIdleIcon }).addTo(map)
    //     );

    //     markersRef.current = [...markersTasks, ...markersUsers];

    // Fit the map to markers
    //         if ((markersTasks && markersTasks.length > 0) || (markersUsers && markersUsers.length > 0)) {
    //             const group = new L.featureGroup([...map.markers]);
    //             map.fitBounds(group.getBounds());
    //         }
    //     }

    //     renderMarkers();
    // }, [taskAndCoordinates]);

    return (
        <div>
            <div>
                <MapContainer refs={mapRef} whenCreated={(mapInstance) => { mapRef.current = mapInstance; }}
                  center={[28.5934, 77.2223]} zoom={13}
                  style={{ zIndex: 0, height: '750px', width: '100%' }}>

                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="Map data © OpenStreetMap contributors" />

                    {taskAndCoordinates.map((entry, index) => (
                        <Marker position={entry.coordinates} icon={locationIcon} key={index}>
                            <Popup maxWidth="500" maxHeight="auto">
                                <GetTaskPopupContent users={userAndCoordinates.map(e => e.user)} taskAndCoordinatesPair={entry} selectedLocationDataField={selectedLocationDataField} selectedTeam={selectedTeam}/>
                            </Popup>
                        </Marker>
                    ))}
                    {userAndCoordinates.map((entry, index) => (
                        <Marker position={entry.coordinates} icon={entry.user.tasks?.length > 0 ? userBusyIcon : userIdleIcon} key={index}>
                            <Popup>
                                <GetUserPopupContent user={entry.user}/>
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </div>
            <div className="refresh-overlay">
                <IconButton color="primary" onClick={handleFilterButtonClick} aria-label='Refresh'>
                    <RefreshIcon/>
                </IconButton>
            </div>
            <div className="filter-overlay">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon fontSize='inherit' />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                        style={{ backgroundColor: '#e7e8e5' }}
                    >
                        <Typography>Filters</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: '100%', height: '100%' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                style={{ width: '100%' }}
                                disableToolbar
                                autoOk={true}
                                variant='inline'
                                format='dd/MM/yyyy'
                                margin='dense'
                                id='date-picker-inline'
                                label='Date'
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </LocalizationProvider>
                        <br/>
                        <TeamSelect
                            team={selectedTeam}
                            onChange={handleTeamChange}
                            size='120px'
                            label='Team'
                            variant='standard'
                        />
                        <TaskTypeSelect
                            onChange={handleTaskTypeChange}
                            size="full"
                            disabled={false}
                            type={selectedTaskType}
                        />
                        <Select
                            value={locationDataFields.find((c) => c.value === selectedLocationDataField) || ''}
                            onChange={handleLocationDataFieldChange}
                            options={locationDataFields}
                            defaultInputValue={locationDataFields[0]?.value}
                            isSearchable
                        />
                        <br/>
                        <Button variant="contained" onClick={handleFilterButtonClick}>Load</Button>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};

export default ZMap;
