import { useEffect, useState } from "react";
import FileUpload from "components/dropzone/FileUpload";
import { FunctionComponent } from "react";
import {
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
  UseFormGetValues,
  useFieldArray,
  Control,
  UseFormSetError,
  UseFormClearErrors
} from "react-hook-form";
import { IZUploader } from "./IZUploader";
import { AiOutlineWarning } from "react-icons/ai";
import ZShowImage from "components/ZImageViewer/ZShowImage";
import _ from "lodash";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { Modal } from "@mui/material";
import styled from "styled-components"
import { ComponentType } from "components/ComponentTypeEnum";
import { useDispatch } from "react-redux"
import { setTaskData } from "render-engine/reducers/show/TaskShowReducer"
import { BlockLabel, ColSpan4, TaskFormLabelForShow } from "render-engine/render.styles"
import useTaskShow from "views/workflows/hooks/useTaskShow"


const HeadingContainer = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
`


export const ZUploader: FunctionComponent<{
  config: IZUploader;
  onChange: Function;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  isOpenedAsSubfield: boolean;
  control: Control,
  setError: UseFormSetError<any>,
  clearErrors: UseFormClearErrors<any>
}> = ({ config, onChange, register,getValues, errors, isOpenedAsSubfield,control, setValue, setError, clearErrors }) => {
  // Construct validation

  const {convertDataTypeToString} = useTaskShow()

  // Make an entry for this config in the react hook form context.
  // Here the control is coming from the parent form
  // Parent for in case isOpenedAsSubfield === true is CreateSubFieldModal
  // else it is from CreateTask/EditTask
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: config.key, // unique name for your Field Array
  });

  const dispatch = useDispatch()

  const [showUploadModal,setShowUploadModal] = useState<boolean>(false)


  // config.key : []

  // this function will be called if the field is opened as a subfile for any list.
  // we are appending the link to the form array
  const updateLocalStateWhenOpenedAsSubfield = (
    key: string,
    value: string
  ): void => {
    // setUploadedImage((prev: string[]) => [...prev, value]); 
    append(value[0])
    const currValue = getValues()
    dispatch(
      setTaskData({ nodeId: config.key, data: currValue[`${config.key}`] })
    )
  
  };

  const removeFiles = (idx:number) => {
    remove(idx)
    const currValue = getValues()
    dispatch(
      setTaskData({ nodeId: config.key, data: currValue[`${config.key}`] })
    )
  }

  const handleChange = updateLocalStateWhenOpenedAsSubfield

  // ADD ONcHANGE for loading already created image in the array.
  useEffect(() => {
    const initialValue = convertDataTypeToString(`${config.key}` || "", false)
    const valueToBeSetToReactHookForm = initialValue || config.value
    // if (!isOpenedAsSubfield) onChange(config.key, config.value);
    setValue(config.key, valueToBeSetToReactHookForm);
  }, []);

  useEffect(() => {
    let minNumberOfFiles = config.minNumber || 0
    if(fields.length < minNumberOfFiles) {
      setError(config.key, {type: "manual", message: `${minNumberOfFiles - fields.length} file(s) is required`})
    } else {
      clearErrors(config.key)
    }
  }, [fields])

  const alreadyUploadedImages  = fields
  // const linksArrayBefore = getValues()[config.key]
  // linksArrayBefore?.forEach((link:string,index:number) => {
  //   linksArrayBefore[index] = {url:link, title:`Image ${index + 1}`}
  // })

  const linksArray = _.cloneDeep(getValues()[config.key])
  linksArray?.forEach((val:string,index:number) => {
    linksArray[index] = {
      url: val,
      title: `Image ${index}`
    }
  })
  
  return (
    <ColSpan4>
      
      {
        errors[config.key]?.message && (
          <div className="font-semibold text-xs text-red-500 mt-1">
            <AiOutlineWarning
              size={"1rem"}
              color="red"
              style={{ display: "inline", marginRight: "4px" }}
            />{" "}
            {errors[config.key]?.message}
          </div>
        )
      }
      <HeadingContainer>
        <TaskFormLabelForShow>
          {config?.label?.val}
        </TaskFormLabelForShow>
        <div>
          {/* <ZButton variant="contained" onClick={() => setShowUploadModal(true)} secondary > Upload Images </ZButton> */}
          <FileUpload
              config={config}
              onChange={handleChange}
              alreadyUploadedImages={alreadyUploadedImages}
              isOpenedAsSubfield={isOpenedAsSubfield}
              removeFiles={removeFiles}
            />
        </div>
      </HeadingContainer>
      <div style={{overflow:"auto"}}>
      <ZShowImage deleteFunc={removeFiles} linksArray={linksArray || []}/>
      </div>
      <Modal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
      >
        <div className="newwwwww">
          <FileUpload
              config={config}
              onChange={handleChange}
              alreadyUploadedImages={alreadyUploadedImages}
              isOpenedAsSubfield={isOpenedAsSubfield}
              removeFiles={removeFiles}
            />
        </div>
      </Modal>
      
    </ColSpan4>
  );
};
