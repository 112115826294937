//@ts-nocheck
import React, { FunctionComponent, useState, useEffect } from "react"
import { UseFormRegister, FieldErrors } from "react-hook-form"
import {
  BlockLabel,
  LinkContainer,
  TaskFormLabelForShow,
} from "render-engine/render.styles"

import { IZlocationValueInterface } from "../location-input/IZLocationValueInterface"
import { IZLocationDisplayInterface } from "./IZLocationDisplayInterface"

import { TaskMap } from "./TaskMap"
import styled from "styled-components"
import { device } from "utils/CommonUtils"
import useTaskShow from "views/workflows/hooks/useTaskShow"
import { ZComponent } from "components/common/ZComponent"

const Container = styled.div`
  display: flex;
  gap: 30px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`

export const ZLocationDisplay: FunctionComponent<{
  config: IZLocationDisplayInterface
  onChange: Function
  register: UseFormRegister<any>
  errors: FieldErrors<any>
}> = (props) => {
  const { config, onChange, register, errors } = props
  let isVisible = true
  if (config.hasOwnProperty("isVisible")) {
    if (
      (typeof config.isVisible === "string" ||
        config.isVisible instanceof String) &&
      config.isVisible.toLowerCase().trim() === "false"
    )
      isVisible = false
    else if (typeof config.isVisible === "boolean") isVisible = config.isVisible
    else if (typeof config.isVisible === "function")
      isVisible =
        config.isVisible() === "true" || config.isVisible() === true
          ? true
          : false
  }

  const { inEditState } = useTaskShow()

  // Find the map center
  // Map center is set to the first location value element
  let mapCenter: any
  let locations: Array<IZlocationValueInterface> = []
  if (config.value) {
    if (Array.isArray(config.value)) {
      if (config.value[0]) mapCenter = config.value[0]
      else mapCenter = { latitude: 0, longitude: 0 }

      locations = config.value
    } else {
      mapCenter = config.value
      locations = [config.value]
    }
  }

  const locationsDisplay = locations?.map(
    (location: IZlocationValueInterface, index: number) => {
      return (
        <div key={index} className="px-2 py-2">
          <TaskFormLabelForShow color={"rgba(0,0,0,0.5)"}>
            {" "}
            {locations?.length > 1 ? `${index + 1}.` : ""} Address{" "}
          </TaskFormLabelForShow>
          <LinkContainer>
            <a
              href={
                "https://maps.google.com?q=" +
                location?.latitude +
                "," +
                location?.longitude
              }
              target="_blank"
            >
              {location?.address}
            </a>
          </LinkContainer>
          <TaskFormLabelForShow color={"rgba(0,0,0,0.5)"}>
            {locations?.length > 1 ? `${index + 1}.` : ""} Latitude{" "}
          </TaskFormLabelForShow>
          <LinkContainer>
            <a
              href={
                "https://maps.google.com?q=" +
                location?.latitude +
                "," +
                location?.longitude
              }
              target="_blank"
            >
              {location?.latitude}
            </a>
          </LinkContainer>
          <TaskFormLabelForShow color={"rgba(0,0,0,0.5)"}>
            {locations?.length > 1 ? `${index + 1}.` : ""} Longitude{" "}
          </TaskFormLabelForShow>
          <LinkContainer>
            <a
              href={
                "https://maps.google.com?q=" +
                location?.latitude +
                "," +
                location?.longitude
              }
              target="_blank"
            >
              {location?.longitude}
            </a>
          </LinkContainer>
          <hr />
        </div>
      )
    }
  )

  // Markers
  // const markers = config.values?.map((loc: IZlocationValueInterface) => return {latitude})

  if (isVisible)
    return inEditState?.[config.key] ? (
      <ZComponent {...props} config={config.editConfig} />
    ) : (
      <Container>
        <div>
          <TaskMap
            locationDisplay={true}
            mapCenter={[mapCenter?.latitude ?? 0, mapCenter?.longitude ?? 0]}
            locations={locations}
          ></TaskMap>
        </div>
        <div>{locationsDisplay}</div>
      </Container>
    )
  else return <div></div>
}
