export const getClasses = (props) => {
  const backgroundColor = props.theme.color.primary;
  const borderColor = props.theme.color.secondary;
  return {
    table: {
      boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
      overflow: "auto"
    },
    '& .RaDatagrid-rowCell': {
      fontSize: "14px !important",
      padding:"6px 24px 6px 16px",
      color:"rgba(0, 0, 0, 0.87)",
      fontWeight: "500 !important",
      backgroundColor: "#fff",
      borderLeft: "1px solid #EAECF0",
      borderRight: "1px solid #EAECF0",
      borderBottom: "1px solid #EAECF0",
    },
    // rowEven: {
    //   backgroundColor: '#fff',
    //   borderLeft: "1px solid #EAECF0",
    //   borderRight: "1px solid #EAECF0",
    //   borderBottom: "1px solid #EAECF0",
    // },
    // rowOdd: {
    //   backgroundColor: '#fff',
    //   borderLeft: "1px solid #EAECF0",
    //   borderRight: "1px solid #EAECF0",
    //   borderBottom: "1px solid #EAECF0",
    // },
    '& .RaDatagrid-headerCell': {
      backgroundColor: '#F9FAFB',
      color: '#475467',
      textAlign: 'left',
      fontWeight: '500',
      fontSize: '12px',
      padding: "12px 24px"
    },
    ellipsis: {
      width: '200px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
    ellipsisAlert: {
      width: '700px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
    chip: {
      borderRadius: '0px !important',
      width: '105%',
    },
    createButton: {
      padding: '6px 16px',
      fontSize: '1rem',
      lineHeight: '1.8rem',
      fontWeight: 500,
      textTransform: 'capitalize',
      borderRadius: '0.4rem',
      border: '1px solid',
      boxShadow: 'none !important',
      maxHeight: ' 4rem',
      minWidth: 'auto',
      justifyContent: 'center',
      backgroundColor,
      borderColor,
      color: '#fff',
      marginLeft: '1rem',
      marginRight: '1rem',
      '&:hover': {
        backgroundColor: borderColor,
        color: '#ffffff',
      },
    },
  }
  
}
