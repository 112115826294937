// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  /* margin-left: 5%; */
  padding-left: 1rem;
}


.primary {
  background-color: #3e66fb;
  padding:10px 16px;
  width:6rem;
}

.secondary {
  background-color: #f2f2f2;
  border: 1px solid #C2C9D1;
  color:#616569 !important;

}

.button {
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display:flex;
}




.content__paramsTableRow:last-child {
  /* border-right: 1px solid #eaeaea; */
  /* border-bottom: 1px solid #eaeaea; */
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.content__tableInput {
  border-radius: 8px;
    border: 1px solid #d0d5dd;
    font-size: 12px;
    color: #555555;
    padding: 4px 8px;
    width: 95%;
    height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/views/workflows/workflowactions/api/APIAction/APIAction.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;;AAGA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,wBAAwB;;AAE1B;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd;;;;;AAKA;EACE,qCAAqC;EACrC,sCAAsC;EACtC,iCAAiC;EACjC,kCAAkC;AACpC;AACA;EACE,kBAAkB;IAChB,yBAAyB;IACzB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,UAAU;IACV,YAAY;AAChB","sourcesContent":[".content {\n  /* margin-left: 5%; */\n  padding-left: 1rem;\n}\n\n\n.primary {\n  background-color: #3e66fb;\n  padding:10px 16px;\n  width:6rem;\n}\n\n.secondary {\n  background-color: #f2f2f2;\n  border: 1px solid #C2C9D1;\n  color:#616569 !important;\n\n}\n\n.button {\n  padding: 8px 16px;\n  border-radius: 4px;\n  color: white;\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 16px;\n  display:flex;\n}\n\n\n\n\n.content__paramsTableRow:last-child {\n  /* border-right: 1px solid #eaeaea; */\n  /* border-bottom: 1px solid #eaeaea; */\n  border-right: 1px solid lightgray;\n  border-bottom: 1px solid lightgray;\n}\n.content__tableInput {\n  border-radius: 8px;\n    border: 1px solid #d0d5dd;\n    font-size: 12px;\n    color: #555555;\n    padding: 4px 8px;\n    width: 95%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
