import React, { useEffect } from "react";
import { MdOutlineTableChart } from "react-icons/md";
import { ColorPalets, Text } from "../styles";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { AiOutlinePlus } from "react-icons/ai";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setShowCreateEntityModal } from "views/entities/reducers/entityReducers";
import { recordRSEvent } from "utils/CommonUtils";
import { TableRSEvents } from "../types";
import usePorterPermissions from "hooks/usePorterPermissions";
import useEntity from "../hooks/useEntity";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
`;

type Props = {};

const NoEntity = (props: Props) => {
  const { allEntities } = useEntity();

  const dispatch = useDispatch();
  const porterPermissions = usePorterPermissions();

  const handleCreateNewEntity = () => {
    dispatch(setShowCreateEntityModal(true));
    recordRSEvent(TableRSEvents.ADD_NEW_TABLE_BUTTON_CLICKED);
  };

  useEffect(() => {
    if (allEntities && allEntities?.length === 0) {
      handleCreateNewEntity();
    }

    // on unmount
    return () => {
      dispatch(setShowCreateEntityModal(false));
    };
  }, []);

  return (
    <Wrapper style={{ height: "100%" }}>
      <Box>
        <Wrapper>
          <MdOutlineTableChart color={ColorPalets.gray600} size="3rem" />
        </Wrapper>
        <Text fontSize="24px" fontWeight="600" color={ColorPalets.gray600}>
          Select a table from sidebar{" "}
        </Text>
        <Text fontSize="18px" fontWeight="500" color={ColorPalets.gray600}>
          {" "}
          or start by creating a new table
        </Text>
        {porterPermissions.checkPermissionsForPorter(
          "f0z7jn",
          "create_table"
        ) && (
          <ZButton
            onClick={handleCreateNewEntity}
            startIcon={<AiOutlinePlus />}
            secondary
            variant="contained"
          >
            {" "}
            Create New Table{" "}
          </ZButton>
        )}
      </Box>
    </Wrapper>
  );
};

export default NoEntity;
