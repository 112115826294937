import { RoleService } from "providers/data/services/RoleService";
import React, { useEffect } from "react";
import Select from "react-select";
import { SelectComponentCustomStyles } from "render-engine/render.styles";
import jwt_decode from 'jwt-decode';
import useLoggedInUser from "hooks/useLoggedInUser";

const UserRole = (props) => {
  const [roles, setRoles] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState(props.role);
  const [disabled, setDisabled] = React.useState(true);

  const authTokenFromStorage = localStorage.getItem("at");
  let loggedInUser;

  if (authTokenFromStorage) {
    try {
      loggedInUser = jwt_decode(authTokenFromStorage);
    } catch (error) {
      console.error(`Error decoding auth token: ${error.message}`);
    }
  }

  useEffect(() => {
    setDisabled(props.disabled);
    setSelectedRole(props.type);
  }, [props.type, props.disabled]);

  useEffect(() => {
    setSelectedRole(props.role || selectedRole);
  }, [props.role, selectedRole]);

  useEffect(() => {
    const data = RoleService.listRoles(false);
    data
      .then((res) => {
        var roles = [];
        res?.data?.map((role) => {
          roles.push({
            value: role?.roleId,
            label: role?.name,
          });
        });

        // if account is payu, then allow only cn4wq4, whhvxm, oizh3g
        // if (!loggedInUser) return;
        if (
          loggedInUser?.accountId === "e1s2v8" &&
          loggedInUser?.role === "whhvxm"
        ) {
          roles = roles.filter((role) => {
            return (
              role.value === "cn4wq4" ||
              role.value === "whhvxm" ||
              role.value === "oizh3g"
            );
          });
        } else if (
          loggedInUser?.accountId === "e1s2v8" &&
          loggedInUser?.role === "oizh3g"
        ) {
          roles = roles.filter((role) => {
            return role.value === "cn4wq4" || role.value === "oizh3g";
          });
        }

        setRoles(roles);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (event) => {
    console.log(event);
    setSelectedRole(event.value);
    props.onChange(event.value);
  };

  return (
    <Select
      styles={SelectComponentCustomStyles}
      value={roles.find((c) => c.value === selectedRole)}
      disabled={disabled}
      variant="outlined"
      onChange={handleChange}
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      options={roles}
    />
  );
};

export default UserRole;
