import { createHashHistory } from "history";
import authProvider from "providers/auth/authProvider";
import zorpDataProvider from "providers/data/ZorpDataProvider";
import { useEffect } from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Sprig } from 'utils/Sprig.util';
import RoleEdit from "views/roles/RoleEdit";
import TeamEdit from "views/teams/TeamEdit";
import Flow from "views/workflows/WorkflowGraph";
import routes, { PrivateRoute } from "./routes/routes";
import store from "./store";
import theme from "./styles/theme";
import { AlertShow } from "./views/alerts/AlertDetails";
import { AlertList } from "./views/alerts/AlertList";
import ZorpLayout from "./views/layouts/layout";
import ZorpLoginPage from "./views/layouts/Login";
import NotFound from "./views/NotFound/NotFound";
import RoleCreate from "./views/roles/RoleCreate";
import RolesList from "./views/roles/RoleList";
import RoleShow from "./views/roles/RoleShow";
import TaskList from "./views/tasks/screens/task/list/TaskList";
import TeamCreate from "./views/teams/TeamCreate";
import { TeamShow } from "./views/teams/TeamShow";
import { TeamsList } from "./views/teams/TeamsList";
import {
  TaskCreate,
  TaskEditSection,
  TaskShowSection,
} from "./views/users/tasks";
import UserCreate from "./views/users/UserCreate";
import { UserEdit, UserList, UserShow } from "./views/users/UsersList";
import WorkflowList from "./views/workflows/WorkflowList";
import { GoogleOAuthProvider } from '@react-oauth/google';
import ZThemeProvider from "views/layouts/ZThemeProvider";
import {HashRouter} from "react-router-dom"
import "./styles/App.css";
import { getAccountDetails } from "utils/CommonUtils";


function App() {
  const { getIdentity, checkAuth } = authProvider;

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod' || process.env.REACT_APP_ENVIRONMENT === 'production') {
      import('./tracing')
        .then(module => {
          // Module is imported successfully
          console.log('Tracing module imported');
        })
        .catch(err => {
          // Handle the error case
          console.error('Error importing the tracing module:', err);
        });
    }
  }, []);


  useEffect(() => {
    // if (checkAuth()) {
      Promise.all([getIdentity(), checkAuth()]).then((response) => {
        const userData = { ...response?.[0], ...response?.[1] };
        window.pendo.initialize({
          visitor: {
            id: userData?.id,
            fullName: userData?.fullName,
            accountId: userData?.accountId,
            role: userData?.role,
          },
        });
        const preview = window.location.href.includes("preview=true");
        if (!preview) {
          localStorage.removeItem("isPreview");
          localStorage.removeItem("previewWorkflowId");
        }
        // Sprig.setEmail(userData?.accountId);
        // Sprig.setUserId(userData?.id);
        // Sprig.setAttributes({
        //   ROLE: userData?.role,
        //   FULLNAME: userData?.fullName,
        // });
      }).catch((err) => {
        console.log(err);
      });
    // }
  }, [getIdentity, checkAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  //The below code will be used while caching the changes, it internally uses react-query and it will be added as prop to react-admin
  //   const queryClient = new QueryClient({
  //     defaultOptions: {
  //         queries: {
  //             staleTime: 5 * 1000,
  //         },
  //     },
  // });

  return (
    <GoogleOAuthProvider clientId="1063036466845-5nq4h8eou11gncjmd2n5dq5ahc7osmh9.apps.googleusercontent.com">
      
        <Provider
          store={store({
            authProvider,
            zorpDataProvider
          })}
        >
          <HashRouter>
          <ZThemeProvider>
            <Admin
              layout={ZorpLayout}
              loginPage={ZorpLoginPage}
              theme={theme}
              catchAll={NotFound}
              // customRoutes={routes}
              dataProvider={zorpDataProvider}
              authProvider={authProvider}
            >
              
              
                <Resource
                  name="tasks"
                  list={TaskList}
                  create={TaskCreate}
                  show={TaskShowSection}
                  edit={TaskEditSection}
                />
                <Resource name="alerts" list={AlertList} show={AlertShow} />
                <Resource
                  name="users"
                  list={UserList}
                  create={UserCreate}
                  show={UserShow}
                  edit={UserEdit}
                />
                <Resource
                  name="teams"
                  list={TeamsList}
                  create={TeamCreate}
                  show={TeamShow}
                  edit={TeamEdit}
                />
                <Resource
                  name="roles"
                  list={RolesList}
                  create={RoleCreate}
                  show={RoleShow}
                  edit={RoleEdit}
                />
                <Resource
                  name="workflows"
                  list={WorkflowList}
                  create={Flow}
                  show={Flow}
                />
          
              
          {/* const routes = [

// tasks routes
<PrivateRoute exact path="/task/:taskType/show/:taskId" component={ShowTask} />,
<PrivateRoute exact path="/task/:taskType/create" component={CreateTask} />,

<Routes>
<Route path="/table" element={<PrivateRoute><TableLanding /></PrivateRoute>} />
</Routes>,
<PrivateRoute exact path="/table/:tableType" component={Entity} />,
<PrivateRoute exact path="/ticket/:tableType" component={TicketTable} />,
<PrivateRoute exact path="/table/:tableType/view/:viewId" component={Entity} />,
<PrivateRoute exact path="/table/create/:tableType" component={EntityCreate} />,
<PrivateRoute exact path="/table/:tableType/edit/:recordId" component={EntityEdit} />,
<PrivateRoute exact path="/table/:tableType/show/:recordId" component={EntityShow} />,
<PrivateRoute exact path="/table/document/:tableType" component={TableDocument} />,


<PrivateRoute exact path="/reports/:id" component={DashBoard} />,
<PrivateRoute exact path="/bireports/:label" component={BiDashboard} />,
<PrivateRoute exact path="/schedule" component={Scheduler} />,
<PrivateRoute exact path="/holder" component={PlaceHolder} />,
<PrivateRoute exact path="/customreport" component={CustomReport} />,
<PrivateRoute exact path="/projects-ul-modular">
  <UrbanLadderProjects
    url={
      "https://app.appsmith.com/app/urban-ladder-project-management/project-list-637b5d1654ea811eb25a2c13?embed=true"
    }
  />
</PrivateRoute>,
<PrivateRoute exact path="/porter-orders">
  <AppsmithDashboards
    url={
      "https://app.appsmith.com/app/porter-order-management/orders-649bcb099dd4927ca5db6293?embed=true"
    }
  />
</PrivateRoute>,
<PrivateRoute exact path="/porter-franchisee">
  <AppsmithDashboards
    url={
      "https://app.appsmith.com/app/porter-franchisee-management/franchisees-64b504213379621654158ed8?embed=true"
    }
  />
</PrivateRoute>,
<PrivateRoute exact path="/admin-ul-qc">
  <UrbanLadderProjects
    url={
      "https://app.appsmith.com/app/urbanladder-qc/page1-63e1f7e94fc63a0df6b85ae6?embed=true"
    }
  />
</PrivateRoute>,
<PrivateRoute exact path="/projects-a" component={AwfisProjects} />,
<PrivateRoute exact path="/customers" component={ContinuityCare} />,
<PrivateRoute exact path="/admin-cm" component={CarbonMasters} />,
<PrivateRoute exact path="/admin-cm-ops" component={CarbonMasters_Ops} />,
<PrivateRoute exact path="/stations" component={RidyStations} />,

<PrivateRoute exact path="/bulktask" component={TaskBulkCreate} />,
<PrivateRoute
  exact
  path="/task/:taskType/transition/:taskId?/:selectedTransition?"
  component={TransitionTask}
/>,
<PrivateRoute
  exact
  path="/task/:taskId/webf"
  component={FWebFrame}
/>,
<PrivateRoute exact path="/map" component={ZorpMap} />,
<PrivateRoute exact path="/zmap" component={ZMap} />,
<PrivateRoute exact path="/monitor" component={Monitor} />,
<PrivateRoute exact path="/monitor/shopify" component={ShopifyMonitor} />,
<PrivateRoute exact path="/monitor/manage" component={RetoolLanding} />,
<PrivateRoute exact path="/document/:taskType?" component={Document} />,
<PrivateRoute
  exact
  path="/health"
  component={() => <h3>Hey There!!! The App is Healthy</h3>}
/>,
<PrivateRoute exact path="/settings/:page" component={Settings} />,
<PrivateRoute exact path="/chronos" component={Chronos} />,
<PrivateRoute
  exact
  path="/settings/general/edit"
  component={GeneralSettingEdit}
/>,
<PrivateRoute exact path="/templates" component={WTMainModal} />,
<PrivateRoute exact path="/zorp-gpt" component={ZorpGPTModal} />,
<PrivateRoute exact path="/white-labelling" component={WTMainModal} />,
<PrivateRoute exact path="/:name/tasks" component={FilteredTaskList} />,
<PrivateRoute exact path="secret" component={SecretManagement} />,
<Route exact path="/reset-password" component={FPRequestScreen} noLayout />,
<Route exact path="/verify-otp/:userId" component={FPVerifyOTP} noLayout />,
<Route
  exact
  path="/change-password/:userId/:otp"
  component={FPChangePassword}
  noLayout
/>,
<Route exact path="/account-setup/:email" component={AccoutSetup} noLayout />,
<Route
  exact
  path="/awaiting-verification/:email"
  component={AwaitingVerification}
  noLayout
/>,
<Route exact path="/login" component={ZorpLoginPage} />,
<Routes>
  <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
</Routes>,
<Route exact path="/signup" component={SignUp} noLayout />,
]; */}
                {/* render all the routes using CustomRoutes */}
                {
                  routes.map((route, index) => {
                    return (
                      <CustomRoutes
                        key={index}
                        
                      >
                        {route}
                      </CustomRoutes>
                    )
                  })
                }
                
              {/* </CustomRoutes> */}
            </Admin>

            <ToastContainer
              icon={false}
              closeOnClick
              hideProgressBar
              autoClose={3000}
              limit={5}
              style={{ height: "2px" }}
            />
          </ZThemeProvider>
          </HashRouter>
        </Provider>
     
    </GoogleOAuthProvider>
  );
}

export default App;
