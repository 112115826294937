import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import SaveMonitorIcon from 'assests/save_monitor.svg';
import TestMonitorIcon from 'assests/test_monitor.svg';
import { cronDescriptionArray } from 'constants/MonitorConstant';
import notification from 'notifications/notifications';
import { IActionType } from "@zorp/common-libs-js/dist/monitor";
import { IQueryBuilderFetchColumnsPayload, MonitorService } from 'providers/data/services/MonitorService';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { generateId } from "utils/Utils";
import { useNavigate, useBlocker } from 'react-router-dom';
import { getAccountId, recordRSEvent } from 'utils/CommonUtils';
import { ISelectValueType } from './DatasourceModal';
import AlertMeSection from './MonitorInterfaceComponents/AlertMeSection';
import CustomiseMyAlertSection from './MonitorInterfaceComponents/CustomiseMyAlertSection';
import DatasourceAnamoliesSection from './MonitorInterfaceComponents/DatasourceAnamoliesSection';
import MonitorInterfacePageHeader from './MonitorInterfaceComponents/MonitorInterfacePageHeader';
import MonitorThisDatasourceSection from './MonitorInterfaceComponents/MonitorThisDatasourceSection';
import { ICronValueType, IMonitorExecutionType, IScreenContext, setMonitorInterfaceContext } from './reducer/MonitorReducer';
import useMonitor from './reducer/useMonitor';
import { cloneDeep, isEmpty } from 'lodash';
import { RudderStackAutomationBuilderEvents } from 'constants/RudderStackConstants';

export enum IQueryGeneratedType {
  SQL = 'SQL',
  QUERY_BUILDER = 'QUERY_BUILDER',
  CONVERSATIONAL = 'CONVERSATIONAL'
}

interface IMonitorInterface {
  openTemplatePage: (fetchMonitorList:boolean) => void;
}

export type IMonitorInterfaceFormValues = {
  datasourceId: ISelectValueType | undefined,
  tableName: ISelectValueType | undefined,
  monitorName: string,
  monitorDescription: string,
  timeZone: ISelectValueType | undefined,
  cronIntervalue: ISelectValueType | undefined,
  cronExpression: string,
}

const MonitorInterface = ({ openTemplatePage }: IMonitorInterface) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accountId = getAccountId();
  const { datasourceListData, monitorInterfaceContext, selectedMonitor, clearMonitorState } = useMonitor();
  const monitorInterfaceData = cloneDeep(monitorInterfaceContext);
  const monitorEditData = monitorInterfaceData?.monitorData;
  const isMonitorInEditMode = monitorInterfaceContext?.monitorScreenContext == IScreenContext.EDIT;
  const [queryBuilderFetchColumns, setQueryBuilderFetchColumns] = useState<any>([]);
  const [isTestMonitorLoding, setIsTestMonitorLoding] = useState<boolean>(false);
  const [isMonitorUpdateLoding, setIsMonitorUpdateLoding] = useState<boolean>(false);
  const [queryBuilderOutputData, setQueryBuilderOutputData] = useState<any>(monitorEditData?.secondaryCriteria || {});
  const [hideBelowSection, setHideBelowSection] = useState<boolean>(isMonitorInEditMode ? false : true);
  const [changeToQueryBuilder, SetChangeToQueryBuilder] = useState<boolean>(false);

  let datasourceValue;
  let cronExpressionValue;
  let cronIntervalValue;
  let primaryColumnField;

  if (isMonitorInEditMode) {
    const dataValue = datasourceListData.find((data) => data.datasourceId == monitorInterfaceContext?.monitorData?.datasourceId);
    datasourceValue = { value: dataValue?.datasourceId, label: dataValue?.displayName }

    if (monitorEditData?.cronData.cronType == ICronValueType.EXPRESSION) {
      //@ts-ignore
      cronExpressionValue = monitorEditData.cronData.cronData?.expression;
    } else if (monitorEditData?.cronData.cronType == ICronValueType.INTERVAL) {
      //@ts-ignore
      const hour = monitorEditData?.cronData.cronData?.hours.toString().length == 1 ? '0'+ monitorEditData?.cronData.cronData?.hours : monitorEditData?.cronData.cronData?.hours;
      //@ts-ignore
      const minute = monitorEditData?.cronData.cronData?.minutes.toString().length == 1 ? '0'+ monitorEditData?.cronData.cronData?.minutes : monitorEditData?.cronData.cronData?.minutes;
      const value = `${hour}:${minute}:00.000`

      cronIntervalValue = cronDescriptionArray.find((data) => data.value == value);
    }

    if (monitorEditData?.primaryColumnField) {
      primaryColumnField = { value: monitorEditData?.primaryColumnField, label: monitorEditData?.primaryColumnField }
    }
  }

  const entireEditActionData = monitorEditData?.actionData.map((data:any)=>{
      if (data.actionType == IActionType.TICKET) {
    // Check if userEmail is a string before modifying
    if (typeof data.payload.userEmail === "string") {
      data.payload.userEmail = {
        "label": data?.payload?.userEmail,
        "value": data.payload.userEmail
      };
      
      data.payload.priority = {
        "label": data.payload.priority,
        "value": data.payload.priority
      };
      return {...data, actionId:generateId(6)};
    } else {
      return data;
    }
  }  else if (data.actionType == IActionType.SLACK) {
      data.payload?.channelData.forEach((channel:any) => {
        // Add value and label, copying the id and name respectively
        channel.value = channel.id;
        channel.label = channel.name;
    });
      return {...data, actionId:generateId(6)};
    } else if (data.actionType == IActionType.EMAIL) {
      return {...data, actionId:generateId(6)};
    } else if (data.actionType == IActionType.AUTOMATION){
      return data;
    }
  })

  if (monitorEditData?.automationPayload?.uiFormat?.id) {
    const checkAutomationPayload = entireEditActionData?.find((data:any) => data.actionType == IActionType.AUTOMATION);
    if (!checkAutomationPayload){
      entireEditActionData.push({
        "actionId": generateId(6),
        "actionType": IActionType.AUTOMATION,
        "label": "Auto Resolution"
      })
    }
  }

  const { register, handleSubmit, control, formState: { errors }, setValue, watch, trigger, setError, setFocus, clearErrors } = useForm(
    {
      defaultValues: {
        datasourceId: isMonitorInEditMode ? datasourceValue : undefined,
        tableName: isMonitorInEditMode ? monitorEditData?.tableName : undefined,
        monitorName: isMonitorInEditMode ? monitorEditData?.monitorName : '',
        monitorDescription: isMonitorInEditMode ? monitorEditData?.monitorDescription : '',
        timeZone: isMonitorInEditMode ? monitorEditData?.timeZone : undefined,
        cronInterval: isMonitorInEditMode ? cronIntervalValue : cronDescriptionArray[0],
        cronExpression: isMonitorInEditMode ? cronExpressionValue : '',
        actionData: isMonitorInEditMode ? entireEditActionData : [],
        cronType: isMonitorInEditMode ? monitorEditData?.cronData.cronType : ICronValueType.INTERVAL,
        createBulkAlert: isMonitorInEditMode ? !monitorEditData?.createBulkAlert : false,
        avoidDuplicate:isMonitorInEditMode ? monitorEditData?.avoidDuplicate : false,
        autoResolve:isMonitorInEditMode ? monitorEditData?.autoResolve : false,
        query_generated_type:isMonitorInEditMode ? monitorEditData?.query_generated_type : IQueryGeneratedType.SQL,
        query:isMonitorInEditMode ? monitorEditData?.query : '',
        isInitiated:true,
        autoResolveCount:isMonitorInEditMode ? monitorEditData?.autoResolveCount : 0,
        primaryColumnField:isMonitorInEditMode ? primaryColumnField : undefined,
      }
    })


  const getMonitorUpdatePayload = async (data:any) =>{
    // configuring cron data
    let cronData;
    if (data.cronType == ICronValueType.INTERVAL) {
      cronData = {
        hours:parseInt(data?.cronInterval?.value?.split?.(':')?.[0]), 
        minutes:parseInt(data?.cronInterval?.value?.split?.(':')?.[1])
      }
      
    } else if (data.cronType == ICronValueType.EXPRESSION) {
      cronData = {
        "expression": data.cronExpression,
      }
    }

    // configuring action payload
    let actionData:any = data.actionData;
    actionData = actionData.map((data:any)=>{
      if (data.actionType == 'EMAIL') {
        const emailValue = data?.payload?.userEmail;
        return {...data, payload: {...data.payload, userEmail: typeof emailValue == 'string' ? emailValue.split(",") : emailValue}}
      }else {
        return data;
      }
    })
    
    const payloadOutput = {
      "monitorId": selectedMonitor.monitorId,
      "isActive": monitorInterfaceData?.monitorData?.isActive,
      "avoidDuplicate": data.avoidDuplicate,
      "accountId":accountId,
      "autoResolve": data.autoResolve,
      "createBulkAlert": !data.createBulkAlert,
      "isInitiated": true,
      "actionData": actionData,
      "autoResolveCount": data.autoResolveCount,
      "automationPayload": selectedMonitor?.monitorAutomation?.[0] || {},
      "cronData": {
          "cronType": data.cronType,
          "timeZone": data.timeZone?.value,
          "cronData": cronData,
          "isActive": monitorInterfaceData?.monitorData?.isActive
      },
      "datasourceId": data.datasourceId.value,
      "monitorDescription": data.monitorDescription,
      "monitorName": data.monitorName,
      "primaryColumnField": data.primaryColumnField?.value,
      "query": data.query,
      "query_generated_type": data.query_generated_type,
      "tableName": data?.tableName?.value || '',
      "secondaryCriteria": data.query_generated_type == IQueryGeneratedType.QUERY_BUILDER ? {...queryBuilderOutputData, selectFieldsOutput:[]} : {}
  }

  return payloadOutput;
  }

  const preValidation = async (data: any) => {
    if (data.actionData.length == 0) {
      notification('error', 'Please configure atleast one action');
      return;
    }

    if (data?.avoidDuplicate && isEmpty(data?.primaryColumnField)) {
      notification('error', 'Primary column field is necessary for Ticket avoid duplicate');
      setError(`primaryColumnField`, { type: 'custom', message: 'Primary column field is necessary for Ticket avoid duplicate' });
      return;
    }

    if (data?.autoResolve && data?.autoResolveCount  <=  0) {
      notification('error', 'Autoresolve count is necessary for Ticket auto resolve');
      setError(`autoResolveCount`, { type: 'custom', message: 'Autoresolve count is necessary for Ticket auto resolve' });
      return;
    }

    if (data?.autoResolve && isEmpty(data?.primaryColumnField)) {
      notification('error', 'Primary Key Column is necessary for Ticket auto resolve');
      setError(`autoResolveCount`, { type: 'custom', message: 'Primary Key Column is necessary for Ticket auto resolve' });
      return;
    }

    // check for all the necessary fields when the slack is configured
    const checkSlackIsConfigired = data.actionData.find((data:any) => data.actionType == IActionType.SLACK);

    if (checkSlackIsConfigired) {
      if (checkSlackIsConfigired.payload.channelData) {
        if (checkSlackIsConfigired.payload.channelData.length == 0) {
          notification('error', 'Please select atleast one channel or user in slack configuration');
          return;
        }
      }
    }
    
    // check for all the valid field when the email is configured
    const checkEmailIsConfigired = data.actionData.find((data:any) => data.actionType == IActionType.EMAIL);

    if (checkEmailIsConfigired) {
      if (isEmpty(checkEmailIsConfigired.payload.userEmail) || isEmpty(checkEmailIsConfigired.payload.mailSubject) || isEmpty(checkEmailIsConfigired.payload.mailBody)) {
        notification('error', 'Fill all the necessary fields in email configuration');
        return;
      }
    }

    // check for all the valid fields when the ticket is configure
    const checkTickerIsConfigured = data.actionData.find((data:any) => data.actionType == IActionType.TICKET);

    if (checkTickerIsConfigured) {
      if (isEmpty(checkTickerIsConfigured.payload.priority) || isEmpty(checkTickerIsConfigured.payload.ticketNotes) || isEmpty(checkTickerIsConfigured.payload.userEmail)) {
        notification('error', 'Fill all the necessary fields in ticket configuration');
        return;
      }
    }

    return true;
  }

  const submitForm = async (data: any) => {
    const check = await preValidation(data);
    
    if (!check){
      return;
    }
     
    const payloadOutput = await getMonitorUpdatePayload(data);
    setIsMonitorUpdateLoding(true);
    const updateMonitorResponse  = await MonitorService.updateMonitor(payloadOutput, selectedMonitor.monitorId);
  
    if (updateMonitorResponse?.code == '200') {
      
      if (isMonitorInEditMode) {
        notification('success', 'Alert updated');
      } else {
        notification('success', 'Alert created');
      }
      
      openTemplatePage(true);
    } else {
      // show error message
      notification('error', 'Failed while updating monitor');
    }

    setIsMonitorUpdateLoding(false);
    // check all the actions configured correctly

    // check if the cron in valid

    // hit monitor update api with proper payload


    // redirect to login screen
  }

  const testMonitor = async () => {
    const checkValidation  = await trigger();

    if (checkValidation) {
      const monitorData = watch();
      const check = await preValidation(monitorData);
      
      if (!check){
        return;
      }
      setIsTestMonitorLoding(true);
      const payloadOutput = await getMonitorUpdatePayload(monitorData);    
      const testMonitorPayload = {
        monitorId: payloadOutput.monitorId,
        executionType: IMonitorExecutionType.NO_MONITOR,
        monitorPayload:{...payloadOutput, isActive:true}
      }

      const testMonitorResponse = await MonitorService.testMonitor(testMonitorPayload);
      setIsTestMonitorLoding(false);
      if (testMonitorResponse?.code == '200') {
        notification('success', testMonitorResponse.message);
      } else {
        notification('error', testMonitorResponse?.message || 'Error in Executing monitor');
      }
    }
  }

  const preCheckBeforeSubmit = () =>{
    dispatch(setMonitorInterfaceContext({
      ...monitorInterfaceData, // Spread the previous state to retain other values
      monitorComponentsData: {
        ...monitorInterfaceData?.monitorComponentsData, // Spread the previous monitorComponentsData
        isdDatasourceSectionHidden: true,
        isConditionSectionHidden: true,
        isChannelSectionHidden: true,
        isMonitorCustomizationSectionHidden: true
      }
    }));
  }
  useEffect(() => {
    // Function to show confirmation dialog
    const handleBeforeUnload = (e:any) => {
      // Cancel the event as stated by the standard.
      e.preventDefault();
      // Chrome requires returnValue to be set.
      e.returnValue = '';
    };

    // Adding the event listener for the beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      clearMonitorState();
    };
  }, []); // Empty array means this effect runs once on mount
  //@ts-ignore
  const blocker = (transition) => {
    if (transition.action === 'POP') {
      return window.confirm("Do you really want to leave? You may lose unsaved changes.");
    }
    return true;
  };

  useBlocker(blocker, []);
   
  return (
    <>
      <form onSubmit={handleSubmit(submitForm)} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          height={'100%'}
          display={'flex'}
          flexDirection={'column'}
          alignContent={'center'}
          alignItems={'center'}
          margin={'16px 16px 64px 16px'}>

          <MonitorInterfacePageHeader
            discardMonitor={() => {
              openTemplatePage(false);
            }}
          />

          <MonitorThisDatasourceSection
          onDataSourceSelect={()=>{
            setHideBelowSection(false);
          }}
          clearErrors={clearErrors}
          //@ts-ignore
            control={control}
            //@ts-ignore
            setValue={setValue}
            //@ts-ignore
            watch={watch}
            //@ts-ignore
            errors={errors}
            onTableValueChange={async (queryBuilderFetchColumnsPayload: IQueryBuilderFetchColumnsPayload) => {
              const fetchQueryBuilderData: any = await MonitorService.queryBuilderFetchColumns(queryBuilderFetchColumnsPayload)
              setQueryBuilderFetchColumns(fetchQueryBuilderData.data.columns || [])
              SetChangeToQueryBuilder(true)
            }}
          />
{
  !hideBelowSection && 
<>
          <DatasourceAnamoliesSection
          setError={setError}
          setFocus={setFocus}
          changeToQueryBuilder={changeToQueryBuilder}
          queryBuilderInputDataComponent={queryBuilderOutputData}
          clearQueryBuilderValue={() => {
            setQueryBuilderOutputData({});
          }}
          onQueryBuilderSubmit={(queryData: any) => {
            setQueryBuilderOutputData(queryData)
          }}
            queryBuilderFetchColumns={queryBuilderFetchColumns}
            watch={watch}
            control={control}
            setValue={setValue}
            trigger={trigger}
          />

          <AlertMeSection
            control={control}
            setValue={setValue}
            watch={watch}
            errors={errors}
            register={register}
          />

          <CustomiseMyAlertSection
            errors={errors}
            setValue={setValue}
            watch={watch}
            control={control}
            register={register}
          />


          <Box width={'100%'} display={'flex'} justifyContent={'center'} gap={'12px'} marginTop={'24px'}>

            <LoadingButton
              color='secondary'
              loading={isTestMonitorLoding}
              disabled={isTestMonitorLoding}
              loadingPosition='start'
              startIcon={<img style={{ paddingRight: '8px' }} src={TestMonitorIcon} alt='Icon' />}
              onClick={async () => {
                
                preCheckBeforeSubmit();

                setTimeout(() => {
                  testMonitor();
                }, 100);

                recordRSEvent(RudderStackAutomationBuilderEvents.testMonitor, {
                  context: RudderStackAutomationBuilderEvents.monitorContext
                });
                

              }}
              style={{ 
                borderRadius: '4px', 
                height: '40px', 
                color: 'black', 
                fontSize: '12px', 
                fontFamily:'Inter',
                fontWeight: 'normal',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)', 
                border: '1px solid var(--Gray-300, #D0D5DD)', backgroundColor: '#fff', textTransform: 'none' }}
              sx={{ height: '40px' }} variant="contained">
              Test Alert
            </LoadingButton>


            <LoadingButton
              color='primary'
              loadingPosition='start'
              loading={isMonitorUpdateLoding}
              disabled={isMonitorUpdateLoding}
              type='submit'
              startIcon={<img style={{ paddingRight: '8px' }} src={SaveMonitorIcon} alt='Icon' />}
              onClick={async () => {
                preCheckBeforeSubmit();
                
                recordRSEvent(isMonitorInEditMode ? RudderStackAutomationBuilderEvents.monitorUpdate : RudderStackAutomationBuilderEvents.createNewMonitor, {
                  context: RudderStackAutomationBuilderEvents.monitorContext
                });
                
              }}
              style={{ 
                borderRadius: '4px', 
                height: '40px', 
                color: '#fff', 
                fontSize: '12px', 
                fontFamily:'Inter',
                fontWeight: 'normal',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)', border: '1px solid var(--Gray-300, #D0D5DD)', 
                backgroundColor: '#3C69E7', 
                textTransform: 'none' }}
              sx={{ height: '40px' }} variant="contained">
              {!isMonitorInEditMode ? 'Save & Activate Alert': 'Update Alert'}
            </LoadingButton>
          </Box>
          </>
}
        </Box>

      </form>
    </>
  )
}

export default React.memo(MonitorInterface);