import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect } from 'react';
import { ValidatorForm } from 'react-form-validator-core';
import moment from 'moment';
import { DateValidator } from './Validator';
import { getFullDate } from 'utils/DateFunctions.utils';

const PaperComp = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  backgroundColor: 'transparent',
}));

export default function TimeSlotSelection({ slots, setDeliverySlot, disabled,allowPast=false }) {
  const [fromTime, setFromTime] = React.useState(moment(slots?.fromDateTime) || null);
  const [toTime, setToTime] = React.useState(moment(slots?.toDateTime) || null);

  useEffect(() => {
    console.log("------- value updating -----");
    setFromTime(moment(slots?.fromDateTime));
    setToTime(moment(slots?.toDateTime));
  }, [`${slots}`]);

  useEffect(()=>{
    console.log("---- toTime, FromTime--------");
    console.log(toTime, fromTime);
  },[`${toTime}`,`${fromTime}`])

  useEffect(() => {
    ValidatorForm.addValidationRule('dateCheck', (value) => {
      if (fromTime !== null && fromTime !== 0 && toTime !== null && toTime !== 0) {
        if (fromTime < toTime) return true;
        else return false;
      } else {
        return true;
      }
    });

    ValidatorForm.addValidationRule('requiredFromCheck', (value) => {
      if (fromTime !== null || fromTime === 0) return true;
      else return false;
    });

    ValidatorForm.addValidationRule('requiredToCheck', (value) => {
      if (toTime !== null || toTime === 0) return true;
      else return false;
    });
  }, [fromTime, toTime]);

  const handleFromDateTimeSelection = (val) => {
    var time = null;
    if (val === '') time = null;
    else time = moment(val);
    setFromTime(time);
    setDeliverySlot({
      fromDateTime: time,
      toDateTime: toTime,
    });
  };

  const handleToDateTimeSelection = (val) => {
    var time = null;
    if (val === '') time = null;
    else time = moment(val);
    setToTime(time);
    setDeliverySlot({
      fromDateTime: fromTime,
      toDateTime: time,
    });
  };

  return (
    <PaperComp>
      <Grid style={{ display: 'flex', marginBottom: '10px' }}>
        <strong style={{color:'black'}}>Start Date-Time:</strong>
      </Grid>
      {!disabled && (
        <DateValidator
          onChange={(val) => handleFromDateTimeSelection(val)}
          name='date'
          value={fromTime}
          className='custom-date-picker'
          instantValidate={true}
          validators={['requiredFromCheck', 'dateCheck']}
          allowPast={allowPast}
          
          errorMessages={['This field is required', 'Start Time should be less than End Time']}
        />
      )}
      {disabled && <div className='text-left'>{getFullDate(fromTime)}</div>}

      <Grid style={{ display: 'flex', marginBottom: '10px', marginTop: '10px' }}>
        <strong style={{color:'black'}}>End Date-Time:</strong>
      </Grid>
      {!disabled && (
        <DateValidator
          onChange={(val) => handleToDateTimeSelection(val)}
          name='date'
          value={toTime}
          className='custom-date-picker'
          allowPast={allowPast}
          instantValidate={false}
          validators={['requiredToCheck', 'dateCheck']}
          errorMessages={['This field is required', 'End Time should be greater than Start Time']}
        />
      )}
      {disabled && <div className='text-left'>{getFullDate(toTime)}</div>}
    </PaperComp>
  );
}
