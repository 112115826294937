import { useJSEditor } from "lowcode/hooks/useJSEditor";
import { EditorScriptLocation } from "lowcode/state/types/types";
import { useFieldArray } from "react-hook-form";
import { BsPlus } from "react-icons/bs";
import { RiDeleteBin6Line as DeleteIcon } from "react-icons/ri";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { changePropertyName, getLowCodeEditorDefaultReturnText, getLowCodeEditorText } from "utils/Utils";
import { DataFieldType } from "views/DataField/DataField.types";
import usePayload from "views/automationBuilder/hooks/usePayload";
import useWorkFlow from "views/workflows/hooks/useWorkFlow";
import ModalSmartInputTextField from "views/workflows/workflowactions/api/components/ModalSmartInputTextField";
import { setSelectedAction } from "../../../../reducers/workflowAction";
import { cloneDeep } from "lodash";
import { useEffect, useRef } from "react";
import { useState } from 'react';
import getInputType from "lowcode/util/getInputType";
import { recordRSEvent, removeBraces } from "utils/CommonUtils";

const HeaderTable = styled.div`
  border-top: 1px solid #c2c9d1;
  height: 28vh;
  overflow: auto
`;

const HeaderLabel = styled.p`
  font-size: 12px;
  font-weight: 600;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 30% 60% 5% 5%;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 1px;
  gap: 16px;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  background-color: #f2f2f2;
  padding: 14px;
  height: 45px;
`;

const Params = ({ getValues, register, control, errors, setError, clearErrors }) => {


  const dispatch = useDispatch();
  const { openJSEditorLayout } = useJSEditor();
  const { actionConditionPayload } = usePayload();
  const { selectedAction, getScriptValue } = useWorkFlow();
  const { fields, append, remove } = useFieldArray({control,name: "headers"});
  const [valueWidth, setValueWidth] = useState(0);



    const handleChange = (e, index) => {
      let tmpSelectedActionData = cloneDeep(selectedAction);
      tmpSelectedActionData.headers[index].value= {
        type: getInputType(e.target.value),
        value: removeBraces(e.target.value),
        dataType: DataFieldType.TEXT,
      };
      tmpSelectedActionData.hasChanged = true;
      dispatch(setSelectedAction(tmpSelectedActionData));
    };

  const addFieldRow = () => {
    append({ key: "", value: [] });
    // when a header row is added, the selected Action is updated

    const tmpSelectedAction = cloneDeep(selectedAction);
    tmpSelectedAction.headers.push({key:'', value:{
      type:'literal',
      value:'',
      dataType:DataFieldType.TEXT
    }})

    dispatch(setSelectedAction(tmpSelectedAction))
  }

  const removeFieldRow = (index) => {
    // when a header row is removed, the selected Action is updated
    remove(index);
    const tmpSelectedAction = cloneDeep(selectedAction);
    tmpSelectedAction.headers.splice(index, 1);
    dispatch(setSelectedAction(tmpSelectedAction));
  }

  const onChangeKeyValue = (e, index) =>{
  // when a header key is changed, the selected Action is updated

  const tmpSelectedAction = cloneDeep(selectedAction);
  tmpSelectedAction.headers[index].key = e.target.value;
  // dispatch(setSelectedAction(tmpSelectedAction));
  console.log(tmpSelectedAction.headers[index]);
  }


  useEffect(()=>{
    const getElementWidth = document.getElementById('header-value');
    const getErrorMessageWidth = getElementWidth?.offsetWidth - 60;
    setValueWidth(getErrorMessageWidth);
  },[document.getElementById('header-value')])

  return (
    <HeaderTable>
      <Header>
        <div>
          <HeaderLabel> KEY</HeaderLabel>
        </div>
        <div>
          <HeaderLabel> VALUE </HeaderLabel>
        </div>
      </Header>
      <div>
        {fields.map((values, index) => {
            const actionExecutionScriptConfig = {
              headers: {
                script: selectedAction.headers[index]?.value?.type == 'script' ? selectedAction.headers[index]?.value?.value : ''  || getLowCodeEditorText(EditorScriptLocation.HEADERS, DataFieldType.TEXT, `return ${getLowCodeEditorDefaultReturnText(DataFieldType.TEXT)}`),
                dataType: DataFieldType.TEXT
              }
            };

          const getKey = getValues()["headers"][index].key;
          const fieldLength = fields.length;

          return (
            <Row>
              <div>
                <input
                  type="text"
                  style={{
                    height:'32px',
                    fontSize:'14px',
                  }}
                  {...register(`headers.${index}.key`, {
                    required: fieldLength === 1 && index === 0 && getKey === "" ? false : "Key is required",
                    validate: (value) => {
                      if (fieldLength === 1 && index === 0 && getKey === "") {
                        return true
                      } else {
                        return !!value.trim() ? true : "Value is required"
                      }
                    }
                  })}
                  className="content__tableInput"
                  placeholder="Key"
                  onChange={(e)=>{
                    clearErrors(`headers.${index}.key`);
                    onChangeKeyValue(e, index);
                  }}
                />
                <span className="text-red-500 text-xs"> {errors["headers"]?.[index]?.["key"]?.["message"]} </span>
              </div>

              <div id='header-value' style={{display: 'flex'}}>
                <ModalSmartInputTextField
                  type={selectedAction.headers[index]?.value?.type || 'literal'} // done
                  dataType={DataFieldType.TEXT}
                  control={control}
                  step="any"
                  avoidOverflow={false}
                  minWidth={valueWidth + 'px'}
                  isSmartField={true}
                  onChangeFunc={(e)=>{
                        handleChange(e, index);
                  }}
                  errors={errors}
                  register={register}
                  getValues={getValues}
                  setError={setError}
                  clearErrors={clearErrors}
                  apiBodyPayload={actionConditionPayload}
                  value={typeof selectedAction.headers[index]?.value?.value == 'string' ? selectedAction.headers[index]?.value?.value : selectedAction.headers[index]?.value?.[0]} // Done
                  placeholder="Value"
                  openJSEditor={openJSEditorLayout}
                  name={EditorScriptLocation.HEADERS + `[${index}].value`}
                  config={actionExecutionScriptConfig.headers}
                  editorLocation={EditorScriptLocation.HEADERS + `[${index}].value`}
                />
              </div>

          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft:'4px'
            }}
          >
            {fields.length > 1 && (
              <DeleteIcon
                size={"1.4rem"}
                color={"#9a99a2"}
                onClick={() => removeFieldRow(index)}
              />
            )}
          </div>

          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BsPlus
              size={"1.5rem"}
              color={"#9a99a2"}
              onClick={()=>{
                addFieldRow();
              }}
            />
          </div>
        </Row>
      )
        })}
      </div>
    </HeaderTable>

    
  );
};
export default Params; 