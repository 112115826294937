import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Grid, Link, Typography } from "@mui/material";
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  communicateToUserOnOpeningWorkflowFromPhone,
  getStringEllipsis,
  recordRSEvent,
} from "utils/CommonUtils";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { images } from "../../assests/images";
import {
  clearPublishedWorkflowData,
  publishTemplateAsWorkflow as publishTemplateAsWorkflowAction,
  setSelectedTemplateLHS,
} from "../../reducers/WorkflowTemplate.actions";
import {
  DocContainer,
  SubVideoDesc,
  SubVideoDuration,
  SubVideoTitle,
  TemplateContainer,
  TemplateDesc,
  TemplateTitle,
  ZChip,
} from "../../workflow-template.styles";

const WTContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // React ref to store array of refs
  // An array is needed since we may have multiple templates being listed
  const scrollRefs = useRef([]);

  // Used until the render with templates happen
  const dummyRef = useRef<HTMLDivElement>(null);

  const [show, setShow] = useState<string>("");
  const [recommendDocs, setRecommendDocs] = useState([]);
  const [recommendVideo, setRecommendVideo] = useState<any>([]);

  const {
    workflowTemplateHome,
    publishedWorkflow,
    selectedTemplateLHS = "",
  } = useSelector((state: any) => state.workflowTemplates); // eslint-disable-line react-hooks/exhaustive-deps

  const getDefaultImage = (e: any) => {
    e.target.src = images["DefaultImg"];
  };

  useEffect(() => {
    if (selectedTemplateLHS) {
      setShow(selectedTemplateLHS);

      //If a specific template is selected on the left pane, fetch the scroll ref and navigate to it
      const clickedTemplateIndex: number =
        workflowTemplateHome?.templates?.findIndex(
          (template: any) => template?.templateId === selectedTemplateLHS
        );
      if (clickedTemplateIndex >= 0)
        //@ts-ignore
        scrollRefs.current[clickedTemplateIndex].current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    }
  }, [selectedTemplateLHS]);

  useEffect(() => {
    if (workflowTemplateHome) {
      setRecommendDocs(workflowTemplateHome?.tutorials?.docs);
      setRecommendVideo(workflowTemplateHome?.tutorials?.videos?.[0]);

      // Populate scrollable refs
      scrollRefs.current = workflowTemplateHome?.templates?.map(
        (_: any, i: number) =>
          scrollRefs.current && scrollRefs.current?.length > i
            ? scrollRefs.current[i]
            : createRef()
      );
    }
  }, [workflowTemplateHome]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (publishedWorkflow) {
      Promise.resolve(navigate(`/workflows/${publishedWorkflow}/show`)).then(
        () => {
          dispatch(clearPublishedWorkflowData());
        }
      );
    }
  }, [publishedWorkflow]); // eslint-disable-line react-hooks/exhaustive-deps

  const publishTemplateAsWorkflow = (id: string) => {
    dispatch(publishTemplateAsWorkflowAction(id));
  };

  return (
    <Box p={3} className="wt-content">
      <Box>
        <Typography variant="h3" mb={2} display="flex">
          Welcome to Zorp!{" "}
          <img
            src={images["Rocket"]}
            alt="rocket"
            style={{ marginLeft: "1.6rem", marginBottom: "0.6rem" }}
          />
        </Typography>
        <hr />
        <Typography variant="h5" my={2}>
          Here are some introductory content that will help you understand Zorp
          better.
        </Typography>
      </Box>

      <Box>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <iframe
              width="420"
              height="200"
              src="https://www.youtube.com/embed/ViNDN1nQPE0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={1}>
              {recommendDocs?.map((doc: any) => (
                <Grid item xs={4}>
                  <Link href={doc?.url} target="_blank" rel="noopener">
                    <DocContainer display="flex" alignItems="center">
                      <img src={images["ZLogo"]} alt="logo" />
                      <Box className="ml-2">
                        <SubVideoTitle>{doc?.heading}</SubVideoTitle>
                        <SubVideoDesc className="mb-1">
                          {doc?.title}
                        </SubVideoDesc>
                        <SubVideoDuration>
                          <InsertDriveFileIcon
                            style={{
                              backgroundColor: "transparent",
                              color: "#999999",
                              margin: "0",
                            }}
                          />
                          <span className="mr-2">{doc?.duration}</span>
                        </SubVideoDuration>
                      </Box>
                    </DocContainer>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Typography variant="h6" className="mt-2">
        Choose a template to get started quickly.
      </Typography>
      <Grid container spacing={1}>
        {workflowTemplateHome?.templates?.map((template: any, i: number) => (
          <Grid item xs={4}>
            <TemplateContainer
              ref={
                scrollRefs.current && scrollRefs.current?.length > 0
                  ? scrollRefs.current[i]
                  : dummyRef
              }
              onMouseEnter={(e) => {
                setShow(template?.templateId);
                dispatch(setSelectedTemplateLHS(""));
              }}
              onMouseLeave={(e) => {
                setShow("");
              }}
              className={show === template?.templateId ? "active mt-2" : "mt-2"}
            >
              <img
                src={template?.thumbnail || images["DefaultImg"]}
                alt="thumbnail"
                onError={getDefaultImage}
              />
              <TemplateTitle className="mt-2 ml-1">
                {template?.name}
              </TemplateTitle>
              <TemplateDesc className="mt-1 ml-1">
                {getStringEllipsis(template?.description, 200)}
              </TemplateDesc>
              {show === template?.templateId && (
                <ZButton
                  variant="contained"
                  size="small"
                  sx={{ marginBottom: 2 }}
                  onClick={() => {
                    recordRSEvent("template_selected", {
                      context: "workflow_template",
                      template_id: template?.templateId,
                    });
                    communicateToUserOnOpeningWorkflowFromPhone();
                    publishTemplateAsWorkflow(template?.templateId);
                  }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 5,
                  }}
                >
                  Use this template
                </ZButton>
              )}
              <Box mt={2}>
                {template?.tags?.map((tag: any) => (
                  <ZChip size="small" label={tag} className="mr-1 mb-1 mt-1" />
                ))}
              </Box>
            </TemplateContainer>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WTContent;
