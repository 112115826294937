import VariableTreeDataFieldListAndSelector from 'lowcode/VariableTreeDataFieldListAndSelector';
import { IoIosSave } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { HorizontalLine, ModalHeading } from 'views/DataField/DataField.styles';
import useWorkflow from 'views/workflows/hooks/useWorkFlow';
import {
  setActiveEditField, setShowDataFieldCreateModal,
  setShowDataFieldEditModal
} from 'views/workflows/reducers/workflowAction';
import CreateView from './CreateView';
import EditView from './EditView';
import SubFieldView from './SubFieldView';

const Container = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.subField ? '30% 40% 30%' : props.secondDiv ? '40% 60%' : '')};
  background-color: white;
  width: ${(props) => (props.subField ? '75%' : props.secondDiv ? '50vw' : '25vw')};
  height: 84vh;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
  border: 1px solid #c2c9d1;
`;

const Button = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  background-color: ${(props) => props.theme.color.primary};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

const Heading = styled.p`
  font-size: 14px;
  color: #616569;
  font-weight: 600;
  padding: 4px 6px;
`;

const TreeView = styled.div`
  background-color: #f0f2f3;
`;

const Info = styled.p`
  font-size: 12px;
  color: #c2c9d1;
  font-weight: 600;
  margin-left: 12px !important;
`;

const DataFieldDrawer = () => {
  const dispatch = useDispatch();
  const { showDataFieldCreateModal, showDataFieldEditModal, activeSubField } = useWorkflow();

  return (
    <Container secondDiv={showDataFieldCreateModal || showDataFieldEditModal} subField={activeSubField}>
      <TreeView>
        <Header>
          <ModalHeading>Datafields</ModalHeading>
          {!showDataFieldCreateModal && !showDataFieldEditModal && (
            <Button
              onClick={() => {
                dispatch(setShowDataFieldEditModal(false));
                dispatch(setShowDataFieldCreateModal(true));
                dispatch(setActiveEditField({}));
              }}
            >
              <span>
                {' '}
                <IoIosSave size={'1.4rem'} />{' '}
              </span>
              <span style={{ marginLeft: '8px', marginTop: '1px' }}> Create new Datafield</span>
            </Button>
          )}
        </Header>
        <HorizontalLine />
        <Info> Double click on the field to edit. </Info>
        <VariableTreeDataFieldListAndSelector />
      </TreeView>
      {showDataFieldCreateModal && <CreateView />}
      {showDataFieldEditModal && <EditView />}
      {activeSubField && <SubFieldView />}
    </Container>
  );
};

export default DataFieldDrawer;
