//@ts-nocheck
import { FunctionComponent } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import {
  TaskFormInput,
  TaskFormLabelForShow,
  ZTextWrapper,
} from "render-engine/render.styles";
import { IZTextInterface } from "./IZTextInterface";
import { useTheme } from "styled-components";
import { getTeamInfoFromTeamId } from "utils/CommonUtils";

export const ZText: FunctionComponent<{
  config: IZTextInterface;
  onChange: Function;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
}> = ({ config, onChange, register, errors }) => {
  const urlRegex = new RegExp(
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  let val = config?.value?.val || "";
  if(config?.key === "teamIds"){
    const teamInfo = getTeamInfoFromTeamId(val)
    if(teamInfo){
      val = `${teamInfo.name} - ${val}`
    }
  }
  let allText = isNaN(val) ? val?.split(" ") || [] : [val];
  
  let modifiedText = "";

  const theme = useTheme()

  allText?.forEach((word, index) => {
    if (urlRegex.test(word)) {
      if (word.includes("http")) {
        modifiedText += ` <u><a style={{color:"blue"}} href="${word}" target="_blank">${word}</a></u>`;
      } else {
        modifiedText += ` <u><a style={{color:"blue"}} href="//${word}" target="_blank">${word}</a></u>`;
      }
    } else {
      modifiedText += ` ${word}`;
    }
  });

  // replace "\n" with <br/>
  modifiedText = modifiedText.replace(/\n/g, "<br/>");

  return (
    <>
      <ZTextWrapper>
        <TaskFormLabelForShow
          fontSize={config.label?.fontSize}
          color={config?.label?.color}
        >
          {config?.label?.val}
        </TaskFormLabelForShow>

        <div className="flex justify-between pr-2">
          {config?.key === "taskStatusName" ? (
            <TaskFormInput
              dangerouslySetInnerHTML={{ __html: modifiedText }}
              color={config?.value?.color}
              backgroundColor={theme.color.primary}
            />
          ) : (
            <TaskFormInput dangerouslySetInnerHTML={{ __html: modifiedText }} />
          )}
        </div>
      </ZTextWrapper>
    </>
  );
};