import styled from "styled-components";

type LabelType = {
  isInline?: "inline" | "";
};

export const ScriptWrapper = styled.div`
  display: flex;

  img {
    margin-bottom: 12px;
  }
`;

export const Button = styled.button`
  padding: 8px !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 4px;
`

export const IconWrapper = styled.div`
  padding: 8px !important;
`

export const IconText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #344054;
`



export const Container = styled.div`
  position: relative;
  margin-top: 1rem;
  width: ${(props: any) =>
    props.type === "updateDataFields" ? "850px" : "650px"};
  height: 750px;

  overflow: auto;
  background-color: white;
  padding: 15px;
  border: 1px solid #c2c9d1;
  // if screen height is smaller that 700px, make modal full screen
  @media (max-height: 700px) {
    height: 100vh;
  }
  /* border-radius: 4px; */
`;

export const Heading = styled.h4`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 19px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: space-between;
`;

export const FlexDiv = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 8px;
`;

export const APIFlexDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

export const CloseButton = styled.span`
  position: relative;
  display: block;
  right: 20px;
  top: 35px;
  transform: translateY(-50%);
  z-index: 999;
  color: white;
  cursor: pointer;
`;

export const Input = styled.input`
  // border: 2px solid #eaeaef;
  padding: 12px;
  border-radius: 8px;
  color: ${(props: any) =>
    props.isSmartInput === "true" ? "white" : "rgba(0,0,0,0.8)"};
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  background-color: ${(props: any) =>
    props.isSmartInput === "true" ? "#2e4cbc" : ""};
  height: ${(props: any) => props.small && "40px"};
  // on focus
  &:focus {
    outline: none;
    border: none;
  }
  border: none;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;

  @media (max-height: 700px) {
    bottom: ${(props: { makeBottom?: boolean }) =>
      props?.makeBottom ? "15px" : "10px"};
  }

  @media (max-height: 650px) {
    position: relative;
    margin-top: 15px;
    display: flex;
    float: right;
  }
`;

export const ABButtonContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  @media (max-height: 700px) {
    bottom: ${(props: { makeBottom?: boolean }) =>
      props?.makeBottom ? "15px" : "10px"};
  }
  @media (max-height: 650px) {
    position: relative;
    margin-top: 15px;
    display: flex;
    float: right;
  }
`;

export const InputContainer = styled.div`
  border: ${(props: any) =>
    props.hasError ? "2px solid red" : "0px solid red"};
  border-radius: 6px;
  align-items: center;
  display: flex;
  grid-template-columns: ${(props: any) =>
    !props.hasConfig ? "" : props.avoidOverflow ? "11% 73% 20%" : "6% 87% 20%"};
  max-width: ${(props: any) => (props.avoidOverflow ? "100%" : "100%")};
`;

export const URLInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  // column-gap: 7px;
  margin-top: 16px;
  gap:8px;
`;

export const Label = styled.p<LabelType>`
  color: var(--Gray-700, #344054);

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  display: ${(props: any) => (props.isInline ? "inline" : "")};
`;

export const CoordinatesWrapper = styled.div`
  background-color: #f0f1f3;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 2px;
`;

export const Divider = styled.div`
  display: grid;
  grid-template-columns: 11% 88%;
  align-items: center;
  margin-bottom: 12px;
`;

export const ToggleContainer = styled.div`
  display: grid;
  grid-template-columns: 19% 79%;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 15px;
`;

export const InputWrapper = styled.div`
  width: 100% !important;
  height:39px !important;
  overflow-x: hidden;
`;

// min-width: ${(props: { avoidOverflow?: boolean; minWidth?: string }) =>
  //   props.minWidth
  //     ? `${props.minWidth} !important`
  //     : props.avoidOverflow
  //     ? "90% !important"
  //     : "88% !important"};