//@ts-nocheck
import React, { FunctionComponent, useState, useEffect } from "react";
import {
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
  Controller,
  Control,
} from "react-hook-form";
import Callout from "views/onboarding/components/Callout";
import { TeamService } from "../../providers/data/services/TeamService";
import { IZTeamInterface } from "./IZTeamInterface";
import Select from "react-select";
import { Label } from "@mui/icons-material";
import { ColSpan4, HelperText, RedText } from "render-engine/render.styles";
import { AiOutlineWarning } from "react-icons/ai";

export const ZTeamList: FunctionComponent<{
  config: IZTeamInterface;
  onChange: Function;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  getValues: Function;
}> = ({ config, onChange, register, errors, setValue, control, getValues }) => {
  const [teams, setTeams] = useState<any[] | undefined>(undefined);
  const [selectedTeam, setSelectedTeam] = useState<any>(config.value);

  // Construct validation
  const validation: any = {};
  if (config.required) validation.required = `required`;

  useEffect(() => {
    const teamsResponse = TeamService.listTeams({
      filter: {},
      pagination: { page: 1, perPage: 100 },
    });
    teamsResponse
      .then((res) => {
        let teams: any[] = [];
        res?.data?.teams.map((team: any) => {
          teams.push({
            value: team?.teamId,
            label: team?.name + " (" + team?.teamId + ")",
          });
        });

        setTeams(teams);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (config.value) onChange(config.key, config.value);
    setValue(config.key, config.value);
  }, []);

  const handleChange = (event: any) => {
    setSelectedTeam(event.target.value);
    onChange(config.key, event.target.value);
  };

  const getDefaultValue = () => {
    const defaultValue = teams?.filter((option: any) => {
      return config?.value?.includes(option.value);
    });
    return defaultValue;
  };

  return (
    <>
      {teams && (
        <ColSpan4>
          {config?.label?.isVisible && (
            <div
              className="col-span-1 font-medium"
              style={{ padding: "4px 0px" }}
            >
              {config?.label?.val}
              {config?.isRequired && <RedText>*</RedText>}
            </div>
          )}
          <Controller
            name={config?.key}
            // defaultValue={true}
            control={control}
            rules={{
              required: {
                value: config?.isRequired || false,
                message: "This field is required",
              },
            }}
            render={({
              field: { onChange: fieldOnChange, value, name, ref },
            }) => (
              <Select
                // styles={customStyles}
                // value={teams.find((c: any) => c.value === value)}
                defaultValue={getDefaultValue()}
                isClearable
                isMulti={config?.isMultiselect}
                isSearchable={true}
                className="react-dropdown"
                classNamePrefix="dropdown"
                onChange={(selectedOption: any) => {
                  // if multiple select is not allowed, then send only the value (as the selectedOption is an object)
                  let val = undefined;
                  if (!config?.isMultiselect) {
                    val = selectedOption.value;
                  } else {
                    val = selectedOption.map((option: any) => option.value);
                  }
                  fieldOnChange(val);
                  onChange(config.key, val);
                  // to clear the already selected user as the team has changed.
                  onChange("userId", "");
                  setValue("userId", "");
                }}
                options={teams}
              />
            )}
          />
          {errors?.teamIds?.message ? (
            <p className="text-red-500 text-sm font-medium mt-1">
              <>
                <AiOutlineWarning
                  size={"1rem"}
                  color="red"
                  style={{ display: "inline", marginRight: "4px" }}
                />
                {errors?.teamIds?.message}
              </>
            </p>
          ) : (
            <HelperText>
              {" "}
              Select the team responsible for doing this task{" "}
            </HelperText>
          )}
        </ColSpan4>
      )}
    </>
  );
};
