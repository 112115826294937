import TextField from '@mui/material/TextField';
import { FormControlLabel } from '@mui/material';
import { cloneDeep } from "lodash";
import { useJSEditor } from 'lowcode/hooks/useJSEditor';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useStore } from 'react-redux';
import styled from 'styled-components';
import { recordRSEvent } from 'utils/CommonUtils';
import DeletionConfirmationDialog from 'utils/DeletionConfirmationDialog';
import { ABEntryButtonText } from 'views/automationBuilder/automationBuilder.styles';
import ABEntryList from 'views/automationBuilder/components/ABEntryList';
import { closeAutomation } from 'views/automationBuilder/reducer/automationReducer';
import { saveScreenTransitions } from 'views/automationBuilder/saveHandlers/saveScreenTransitions';
import { ABMetadataTypeEnum } from "views/automationBuilder/types/ABType";
import ZSwitch from 'views/commonComponents/ZSwitch';
import useAutomation from "views/automationBuilder/hooks/useAutomation";
import { Button } from 'views/DataField/DataField.styles';
import {
  deleteTransition, setSelectedAction, setTransitionEventName, setTransitionLocationCollection
} from "views/workflows/reducers/workflowAction";
import { CollectLocationContent } from 'views/workflows/TooltipContent/TooltipContent';
import ConfigureAutomation from './ConfigureAutomation';
import './css/flow.css';
import { DetectHomeNode } from "./DetectHomeUtils";
import useWorkFlow from './hooks/useWorkFlow';
import InfoIcon from './Info/InfoIcon';
import { WorkflowUtils } from "./WorkflowUtils";


const MainContainer = styled.div`
  width: 100%;
  padding-top: 10px;
`;

const FlowTransitionDetails = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const {
    mockData
  } = useJSEditor();

  const { selectedTransition, eventName, collectLocation, allScreenElements } =
    useWorkFlow();
  const { clearRightSide } = useAutomation();


  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const [openDeletionConfirmation, setOpenDeletionConfirmation] = useState(false);
  const [openAB, setOpenAB] = useState(false);
  const [automationId, setAutomationId] = useState('');

  useEffect(() => {
    setValue("event", selectedTransition?.data?.event);

    recordRSEvent("transition_page_load", {
      context: "builder_transition_config",
      transition_id: selectedTransition?.data?.id,
    });
  }, [selectedTransition]); // eslint-disable-line react-hooks/exhaustive-deps


  // Hide ActionBlock in AB when screen transition Target Node is a Decision Node
  const hideActionBlock = WorkflowUtils.checkElementTargetIsDecisionNode(selectedTransition?.target, allScreenElements);
  return (
    <>
      <form className="action__mainForm">
        <div className="controlCenter_sidebar grid grid-flow-col">
          <div className="col-span-1 pl-5 controlCenter_sidebar_subdiv">
            <div class="justify-items-start">
              <div class="py-2">
                <span class="font-bold text-sm"> Name </span>
                {
                  selectedTransition?.data?.errors?.length > 0 && (
                    <InfoIcon item={selectedTransition?.data?.errors} type="error" isCollectLocation={true} />
                  )
                }

                <TextField
                  {...register("event", { required: "Event is required" })}
                  fullWidth
                  id="event"
                  placeholder="Describe this event"
                  variant="outlined"
                  value={selectedTransition?.label || ""}
                  className="eventName_input_field"
                  onChange={(e) =>
                    dispatch(
                      setTransitionEventName({
                        eventName: e.target.value,
                        transition: selectedTransition,
                      })
                    )
                  }
                />
                <div class="font-semibold text-xs text-red-500">
                  {errors?.event?.message}
                </div>
              </div>
            </div>
            
            <div style={{
              display:"flex",
              alignItems:"center",
              justifyContent:"flex-start",
            }} className="py-4 px-4">
              <FormControlLabel
                control={
                  <ZSwitch
                    size="small"
                    checked={collectLocation}
                    onChange={(e) =>
                      dispatch(
                        setTransitionLocationCollection({
                          collectLocation: e.target.checked,
                          transition: selectedTransition,
                        })
                      )
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Collect Location"
                className="mt-1"
              />
              <InfoIcon
                isCollectLocation={true}
                item={CollectLocationContent}
                type={"info"}
              />
            </div>

            <Button onClick={(e) => {
              e.preventDefault();
              setOpenAB(true);
              dispatch(setSelectedAction(null));
            }}
              width='full'
              variant='secondary'
            >
              <ABEntryButtonText>Configure Transition Automation</ABEntryButtonText>
            </Button>

            <ABEntryList automationList={selectedTransition.data?.allAutomations} onAutomationClick={(automationId) => {
              setAutomationId(automationId);
              setOpenAB(true);
              dispatch(setSelectedAction(null));
            }} />

            <ConfigureAutomation
              metaDataType={ABMetadataTypeEnum.WORKFLOW_SCREEN_TRANSITION}
              prevAutomations={selectedTransition?.data?.allAutomations}
              onSaveHandler={saveScreenTransitions}
              onAutomationClose={() => {
                clearRightSide();
                dispatch(closeAutomation());
                setOpenAB(false);
              }}
              automationSelected={automationId}
              otherConfigs={{
                allowOnlySingleAutomation: true,
                hideTriggerConfigureButton: false,
                preFillTrigger: true,
                hideActionBlock: hideActionBlock
              }}
              openAutomationBuilder={openAB}
            />

            <MainContainer>
              <Button
                //  @ts-ignore
                width="full"
                type="button"
                variant="danger"
                onClick={() => setOpenDeletionConfirmation(true)}
              >
                {" "}
                Delete Transition{" "}
              </Button>
            </MainContainer>
          </div>
        </div>
      </form>
      <DeletionConfirmationDialog
        id={"flowTransitionDetail"}
        deletionTitle={"Delete Transition"}
        deletionText={"Do you want to delete this Transition?"}
        isOpen={openDeletionConfirmation}
        onConfirmDelete={() => {
          dispatch(deleteTransition(selectedTransition));
          DetectHomeNode.setHomeAndTerminalNodes(
            store.getState()?.builder?.workflow?.canvas?.elements || [],
            dispatch
          );
          DetectHomeNode.markAllReachableNode(store.getState()?.builder?.workflow?.canvas?.elements || [],
            dispatch)
        }}
        onClose={() => {
          setOpenDeletionConfirmation(false);
        }}
      />
    </>
  );
};

export default FlowTransitionDetails;
