import React, { useRef, useState, useEffect, memo } from 'react';
import { InfoTooltip as Tooltip } from "views/workflows/Tootltips/InfoTooltip";
import Avatar from "@mui/material/Avatar";
import {
  stringAvatar,
} from "utils/CommonUtils";
import styled from 'styled-components';
import RelationSVG from "assests/relation.svg"

const Badge = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  color: rgb(52, 64, 84);
  background-color: rgb(242, 244, 247);
  font-weight: 500;
  font-size: 11px;
  margin-left: 6px;
  cursor: pointer;
  width: max-content;
`;

const UserTeamRelationRender = ({ relationContent, isUser = false }:any) => {
  const containerRef = useRef(null);
  const [visibleValues, setVisibleValues] = useState([]);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    //@ts-ignore
    const containerWidth = containerRef.current.offsetWidth;
    let totalWidth = 0;
    const newVisibleValues = [];

    const measureElementWidth = (value:any) => {
      const badge = document.createElement('span');
      badge.style.visibility = 'hidden';
      badge.style.position = 'absolute';
      badge.style.padding = '4px 8px';
      badge.style.fontWeight = '500';
      badge.style.fontSize = '11px';
      badge.style.display = 'inline-flex';
      badge.style.alignItems = 'center';

      const img = document.createElement('img');
      img.src = RelationSVG;
      img.style.height = '12px';
      img.style.width = '12px';
      img.style.marginRight = '4px';
      badge.appendChild(img);

      const text = document.createElement('span');
      text.textContent = value;
      badge.appendChild(text);

      document.body.appendChild(badge);
      const width = badge.getBoundingClientRect().width + 2;
      document.body.removeChild(badge);
      return width;
    };

    for (let i = 0; i < relationContent.length; i++) {
      const value = relationContent[i]?.name;
      let elementWidth = 0;

      if (isUser) {
        elementWidth = 24 + 8;
      } else {
        elementWidth = measureElementWidth(value);
      }

      totalWidth += elementWidth + 8;

      if (totalWidth > containerWidth) {
        setIsOverflowing(true);
        break;
      }
      newVisibleValues.push(value);
    }
    //@ts-ignore
    setVisibleValues(newVisibleValues);
  }, [relationContent, isUser]);

  return (
    <>
      <div
        ref={containerRef}
        style={{
          display: 'inline-block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
          marginTop: '4px',
        }}
      >
        {visibleValues.map((value, index) =>
          value && (
            <Tooltip key={index} title={value} placement="top" arrow>
              {isUser ? 
              <span key={index} style={{ display: 'inline-flex' }}>
                <Avatar style={{ marginLeft: '8px' }} {...stringAvatar(value)} />
              </span> : 
              <Badge key={index}> <img height={12} width={12} src={RelationSVG} style={{display:"inline"}} /><span>{value}</span> </Badge>}
            </Tooltip>
          )
        )}
        {isOverflowing && (
          <Tooltip
            title={relationContent.map((value:any) => value.name).join(', ')}
            placement="top"
            arrow
          >
            <span style={{ cursor: 'pointer', marginLeft: '8px' }}>
              <span>...</span>
            </span>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default memo(UserTeamRelationRender);