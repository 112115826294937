import { ThemeProvider } from '@mui/material/styles';
import { Visibility, VisibilityOff } from "@mui/icons-material"
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  TextField,
} from "@mui/material"
import logo from "assests/darklogo.svg"
import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack"
import useQuery from "hooks/useQuery"
import useSessionStorage from "hooks/useSessionStorage"
import { isEmpty } from "lodash"
import notification from "notifications/notifications"
import { AccountService } from "providers/data/services/AccountService"
import { TaskFlowService } from "providers/data/services/TaskFlowService"
import { useEffect, useState } from "react"
import {
  Notification,
  useLogin,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin"
// import GoogleLogin from "react-google-login"
import { useDispatch } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"
import theme from "styles/theme"
import {
  extractCompanyName,
  getAccountDetails,
  isWhitelabelledUrl,
  nonWhitelabelledUrl,
  recordRSEvent,
} from "utils/CommonUtils"
import { fetchAndStoreAccountName, userDecodedData } from "utils/UserAuth.utils"
import { ZButton } from "views/commonComponents/commonComponents.styles.tsx"
import LoadingScreen from "views/LoadingScreen/LoadingScreen"
import { setPostPublish } from "views/workflows/reducers/workflowAction"
import { resetMenu, setTheme } from "views/layouts/reducer/menuSlice.ts"
import { RoleService } from "providers/data/services/RoleService"
import jwt_decode from 'jwt-decode';
import { GoogleLogin } from '@react-oauth/google';


const ZorpLoginPage = () => {
  const login = useLogin()
  const dispatch = useDispatch()

  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const query = useQuery()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [templateId, setTemplateId] = useSessionStorage(
    "templateId",
    query.get("templateId") || localStorage.getItem("templateId") || ""
  )
  const [solutionId, setSolutionId] = useSessionStorage(
    "solutionId",
    query.get("solutionId") || localStorage.getItem("solutionId") || ""
  )

  const [t, setT] = useSessionStorage(
    "t",
    query.get("t") || localStorage.getItem("t") || ""
  )

  const [redirectUrl, setRedirectUrl] = useSessionStorage(
    "redirectUrl",
    query.get("redirectUrl") || localStorage.getItem("redirectUrl") || ""
  )
  const [logoImage, setLogoImage] = useState(null)
  const [companyName, setCompanyName] = useState(() => extractCompanyName())

  const navigate = useNavigate();

  const getCompanyLogo = async () => {
    try {
      const res = await AccountService.getCompanyLogo(companyName)
      if (res?.data?.logo) {
        setLogoImage(res.data.logo)
      } else {
        setLogoImage(logo)
      }
    } catch (error) {
      setLogoImage(logo)
      console.log(error)
    }
  }

  const clientId =
    "835132187391-ad60oo7mp97hmrs540sd3hj71e2lgh4o.apps.googleusercontent.com"

  const publishTemplateWorkflow = async (id) => {
    if (id) {
      try {
        const data = await TaskFlowService.publishTemplateAsWorkflow(id)
        await TaskFlowService.publishWorkflow(data?.data)
        localStorage.removeItem("templateId")
        return data.data
      } catch (error) {
        console.log(">>>>>>error", { error })
        notification("error", "workflow publish failed")
      }
    }
  }

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.login, "signin")
    if (query.get("templateId")) {
      setTemplateId(query.get("templateId"))
    }
    if (query.get("solutionId")) {
      setSolutionId(query.get("solutionId"))
    }
    if (query.get("t")) {
      setT(query.get("t"))
    }
    getCompanyLogo()
  }, [])

  const handleClickShowPassword = () => {
    !showPassword ? setShowPassword(true) : setShowPassword(false)
  }

  useEffect(() => {
    if (!isEmpty(userDecodedData())) {
      if (templateId) {
        notification("success", "publishing your workflow...")
        publishTemplateWorkflow(templateId)
          .then((publishedWorkflowId) => {
            redirect(`/workflows/${publishedWorkflowId}/show`)
            refresh()
            dispatch(
              setPostPublish({
                postedWorkflow: "Workflow Created from template",
                setPostPublish: true,
                postedWorkflowId: publishedWorkflowId,
              })
            )
            setTemplateId("", true)
          })
          .catch((e) => {
            console.log(e)
          })
      }
    }
  }, [])

  if (!isEmpty(userDecodedData())) {
    return (
      <Navigate
        to={{
          pathname: redirectUrl
            ? redirectUrl
            : templateId
            ? `/workflows`
            : "/tasks",
        }}
      />
    )
  }
  else if (t){
    // set the localstorage and redirect to login page
    const user = jwt_decode(t);
    localStorage.setItem('at', t);
    localStorage.setItem('rt', t);
    RoleService.getAclForRole(user.role)
    .then((response) => {
      let roleAcls = response?.data;
      localStorage.setItem('permissions', JSON.stringify(roleAcls || {}));
      return <Navigate to="/login" />
    });    
  }

  const submit = (e) => {
    e.preventDefault()
    recordRSEvent("signin_button_click", {
      context: "signin",
      userName,
    })
    setLoading(true)
    
    const obj = login({
      loginType: "basic",
      param1: userName,
      param2: password,
    })
      .then(async (e) => {
        setLoading(false)
        dispatch(resetMenu())
        console.log(e);
        if(e?.twoFA?.enabled == true){
          notification('success', 'OTP sent to your E-mail and/or Phone Number');
          navigate(`/verify-otp/${userName}`, { state: { password: password, fromLogin: true, userId: userName } });
          return
        }else{
          // window.location.reload();
        if (redirectUrl && redirectUrl !== "undefined") {
          redirect(redirectUrl)
        } else if (templateId && templateId !== "undefined") {
          publishTemplateWorkflow(templateId)
            .then((publishedWorkflowId) => {
              setTemplateId("", true)
              refresh()
              redirect(`/workflows/${publishedWorkflowId}/show`)
              dispatch(
                setPostPublish({
                  postedWorkflow: "Workflow Created from template",
                  setPostPublish: true,
                  postedWorkflowId: publishedWorkflowId,
                })
              )
            })
            .catch((e) => {
              console.log(e)
            })
        } else {
          if (isWhitelabelledUrl()) {
            redirect("/tasks")
          } else {
            // redirect("/table");
            redirect("/home");
            window.location.reload()
          }
        }

        // fetch account name and store in local storage
        await fetchAndStoreAccountName()

        const accountConfig = await getAccountDetails()
        dispatch(
          setTheme({
            ...accountConfig?.accountConfig?.theme,
          })
        )

        recordRSEvent("signin_event_success", {
          context: "signin",
          userName,
        })

        recordRSEvent("page_load", {
          context: "login",userName
        });

        } 

        
      })
      .catch((err) => {
        notification("error", err?.message)
        // notify(err.message);
        recordRSEvent("signin_event_fail", {
          context: "signin",
          userName,
          error: err?.message,
        })
        setLoading(false)
      })

      console.log(">>>>> obj", { obj })
  }

  const onSuccess = (response) => {
    setLoading(true)
    const profile = jwt_decode(response.credential);
    login({
      loginType: "googleOauth",
      param1: profile.email,
      param2: response.credential
    })
      .then(async (e) => {
        setLoading(false)
        // window.location.reload();
        recordRSEvent("signin_event_success", {
          context: "signin",
          userName,
        })

        if(e?.twoFA?.enabled == true){
          notification('success', 'OTP sent to your E-mail and/or Phone Number');
          navigate(`/verify-otp/${response.profileObj.email}`, { state: { fromLogin: false, fromGoogleLogIn: true, googleEmail: response.profileObj.email, googleIdToken: response.tokenId } });
          return
        }else{

        publishTemplateWorkflow(localStorage.getItem("templateId"))
          .then(() => {
            setTemplateId("", true)
          })
          .catch((e) => {
            console.log(e)
          })
        if (companyName === "app") {
          redirect("/home")
        } else {
          redirect("/tasks")
        }}
      })
      .catch((err) => {
        notification("error", err?.message)
        // notify(err.message);
        recordRSEvent("signin_event_fail", {
          context: "signin",
          userName,
          error: err?.message,
        })
        setLoading(false)
      })
  }

  const onError = (response) => {
    recordRSEvent("login_event_fail", {
      context: "login",
      response,
    })
    // notification('error', 'Google signin not available, please try to login with email');
  }

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <ThemeProvider theme={theme}>
            <div className="login">
              <div className="loginInner">
                <div className="loginImg">
                  {logoImage && <img src={logoImage || logo} alt="" />}
                </div>
                <h2 className="loginTitle">Login</h2>
                <form className="loginForm" onSubmit={submit}>
                  <Box sx={{ mt: 3, width: "320px" }}>
                    <FormControl variant="standard" fullWidth>
                      <div class="my-2">
                        {/* <GoogleLogin
                          
                          onSuccess={onSuccess}
                          onFailure={onError}
                          style={{ width: "100%" }}
                          clientId={clientId}
                          // icon={false}
                        >
                          Sign in with Google
                        </GoogleLogin> */}
                        <GoogleLogin
                          className="googleLoginButtonLogin"
                          onSuccess={onSuccess}
                          onFailure={onError}
                          size='large'
                          theme='filled_blue'
                          width='320px'
                        />
                      </div>
                      <Divider
                        sx={{ mt: 3, mb: 3, color: "black" }}
                        className="signup-divider"
                      >
                        OR
                      </Divider>
                      <FormLabel
                        sx={{
                          fontFamily: "'Inter', sans-serif",
                          mb: 1,
                          color: "rgba(0,0,0,0.8)",
                          fontWeight: "bold",
                          fontSize: "small",
                        }}
                        htmlFor="my-input"
                      >
                        Work E-Mail / Phone Number / User Id
                      </FormLabel>
                      <TextField
                        id="my-input"
                        aria-describedby="my-helper-text"
                        size="small"
                        required
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ mt: 3, width: "320px" }}>
                    <FormControl variant="standard" fullWidth>
                      <FormLabel
                        sx={{
                          fontFamily: "'Inter', sans-serif",
                          mb: 1,
                          fontWeight: "bold",
                          color: "rgba(0,0,0,0.8)",
                          fontSize: "small",
                        }}
                        htmlFor="my-input"
                      >
                        Password
                      </FormLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        size="small"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ marginBottom: "3.2px", p: 0 }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                  <ZButton
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{ mt: 4, mb: 4 }}
                  >
                    Sign In
                  </ZButton>
                </form>
                <Link
                  style={{ fontWeight: "600", color: "#3e66fb" }}
                  href="/#/reset-password"
                  underline="hover"
                >
                  Forgot Password ?
                </Link>
                {console.log(">>>> is whitelabelled", isWhitelabelledUrl())}
                {!isWhitelabelledUrl() && (
                  <p
                    style={{
                      fontWeight: "500",
                      fontFamily: "'Inter', sans-serif",
                    }}
                  >
                    Don't have an account?{" "}
                    <Link
                      style={{ fontWeight: "600", color: "#3e66fb" }}
                      href={`/#/signup?templateId=${templateId}&solutionId=${solutionId}&t=`}
                      underline="hover"
                    >
                      Sign Up Now
                    </Link>
                  </p>
                )}

                {/* <p className='copyright'>© Zorp 2022 </p> */}
              </div>
            </div>
          </ThemeProvider>
        </>
      )}
      <Notification />
    </>
  )
}

export default ZorpLoginPage
