import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { green } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TextField from '@mui/material/TextField';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import React, { useEffect } from 'react';
import { Marker, Polyline, Popup } from 'react-leaflet';
import { getFullDateHour, getLocalizedTime } from 'utils/DateFunctions.utils';
import { builderTaskLocation, filterTasks, pickupLocationTasks } from 'utils/TasktypeUtils';
import notification from '../../notifications/notifications';
import { AssignmentService } from '../../providers/data/services/AssignmentService';
import defaultUserProfile from './default_user_profile.png';
import './map.css';
import iconAssignedOverdue from './task_assigned_overdue.png';
import iconCompleted from './task_completed.png';
import iconInprogress from './task_inprogress.png';
import iconNew from './task_new.png';
import iconNewAssigned from './task_new_assigned.png';
import iconNewOverdue from './task_new_overdue.png';
import iconUserBusy from './user_busy.png';
import iconUserIdle from './user_idle.png';
import iconUserOffline from './user_offline.png';

let taskNewIcon = L.icon({
  iconUrl: iconNew,
  iconSize: [20, 30],
  // shadowUrl: iconShadow
});

let taskNewSelectedIcon = L.icon({
  iconUrl: iconNew,
  iconSize: [30, 40],
  // shadowUrl: iconShadow,
});

let taskNewOverdueIcon = L.icon({
  iconUrl: iconNewOverdue,
  iconSize: [20, 30],
  // shadowUrl: iconShadow
});

let taskNewOverdueSelectedIcon = L.icon({
  iconUrl: iconNewOverdue,
  iconSize: [30, 40],
  // shadowUrl: iconShadow,
});

let taskNewAssignedIcon = L.icon({
  iconUrl: iconNewAssigned,
  iconSize: [20, 30],
  // shadowUrl: iconShadow,
});

let taskNewAssignedSelectedIcon = L.icon({
  iconUrl: iconNewAssigned,
  iconSize: [30, 40],
  // shadowUrl: iconShadow,
});

let taskAssignedOverdueIcon = L.icon({
  iconUrl: iconAssignedOverdue,
  iconSize: [20, 30],
  // shadowUrl: iconShadow,
});

let taskAssignedOverdueSelectedIcon = L.icon({
  iconUrl: iconAssignedOverdue,
  iconSize: [30, 40],
  // shadowUrl: iconShadow,
});

let taskInprogressIcon = L.icon({
  iconUrl: iconInprogress,
  iconSize: [20, 30],
  // shadowUrl: iconShadow
});

let taskInprogressSelectedIcon = L.icon({
  iconUrl: iconInprogress,
  iconSize: [30, 40],
  // shadowUrl: iconShadow,
});

let taskCompletedIcon = L.icon({
  iconUrl: iconCompleted,
  iconSize: [20, 30],
  // shadowUrl: iconShadow
});

let taskCompletedSelectedIcon = L.icon({
  iconUrl: iconCompleted,
  iconSize: [30, 40],
  // shadowUrl: iconShadow,
});

let userIdleIcon = L.icon({
  iconUrl: iconUserIdle,
  iconSize: [16, 16],
  // shadowUrl: iconShadow
});
let userIdleSelectedIcon = L.icon({
  iconUrl: iconUserIdle,
  iconSize: [30, 30],
  // shadowUrl: iconShadow
});

let userBusyIcon = L.icon({
  iconUrl: iconUserBusy,
  iconSize: [16, 16],
  // shadowUrl: iconShadow
});

let userBusySelectedIcon = L.icon({
  iconUrl: iconUserBusy,
  iconSize: [30, 30],
  // shadowUrl: iconShadow
});

let userOfflineIcon = L.icon({
  iconUrl: iconUserOffline,
  iconSize: [16, 16],
  // shadowUrl: iconShadow
});

let userOfflineSelectedIcon = L.icon({
  iconUrl: iconUserOffline,
  iconSize: [30, 30],
  // shadowUrl: iconShadow
});

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  '& > *': {
    margin: theme.spacing(1),
    width: '280px',
    height: theme.spacing(16),
  },
  '& .green': {
    color: '#fff',
    backgroundColor: green[500],
  },
}));

export default function MapUserList(props) {
  // const pickupLocationTasks = ['porter_order_pickup_task', 'porter_order_return_task', 'towing_service'];
  // const builderTaskLocation = new Map();
  // builderTaskLocation.set('wgpaq1' ,  'Customer_Location');
  // const filteredTasks = ['inventory_pickup_task', 'smoor_order_pickup_task'];
  const [userCardList, setUserCardList] = React.useState([]);
  const [search, setSearch] = React.useState();
  const [userCount, setUserCount] = React.useState(0);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [filters, setFilters] = React.useState({
    busyFilter: true,
    freeFilter: true,
    offlineFilter: false,
  });

  const handleListItemClick = (event, index, users, user) => {
    if (index !== selectedIndex) {
      props.setUserMarkerList(null);
      setUserCardList([]);
    }
    setSelectedIndex(index);
    setSelectedUser(user);
  };

  const handleListItemMouseOver = (event, index, users, user) => {};

  const handleAssignmentClick = (event, index, selectedUser) => {
    if (props.sTask.userId) {
      async function fetchData() {
        const response = await AssignmentService.unassignTask(props.sTask.taskId);

        if (response && response.code === '200') {
          props.onChange(props.sTask);
          notification('success', response?.message);
        }
      }
      fetchData();
    } else {
      async function fetchData() {
        const response = await AssignmentService.assignTask(props.sTask.taskId, selectedUser.userId);

        if (response && response.code === '200') {
          // window.location.reload();
          props.onChange(props.sTask);
          notification('success', response?.message);
        }
      }
      fetchData();
    }
  };

  useEffect(() => {
    async function fetchTaskUserData() {
      let payload = {
        from: props.selectedDateStartTime,
        to: props.selectedDateEndTime,
      };
      const response = await AssignmentService.getUserOfAssignmentTask(props.sTask.userId, payload, props.sTask.taskId);
      // console.log("Response", response)
      if (response && response.code === '200') {
        const user = response?.data;
        let usersTemp = [user];
        setUsers(usersTemp);
        setUserCardList([]);
        buildUserCardList(usersTemp, -1);
        if (props.sTask.taskInTerminalState === false) {
          handleMarkersAndPolylines('SELECTED_TASK_USERS', usersTemp, user, props.sTask, -1);
        } else {
          props.setUserPolylineList(null);
          props.setUserMarkerList(null);
        }
      }
    }

    if (props.sTask?.user) {
      setSelectedUser(null);
      setSelectedIndex(-1);
      setUsers([]);
      fetchTaskUserData();
    }
  }, [props.sTask]);

  function getUserFilters() {
    var filter = { userStatus: undefined, userLoad: undefined };

    if (
      !(filters.busyFilter && filters.freeFilter && filters.offlineFilter) &&
      !(!filters.busyFilter && !filters.freeFilter && !filters.offlineFilter)
    ) {
      if (filters.busyFilter && filters.freeFilter && !filters.offlineFilter) {
        filter.userStatus = 'ONLINE';
      }
      if (filters.busyFilter && !filters.freeFilter && filters.offlineFilter) {
        filter.userLoad = 'BUSY';
      }
      if (filters.busyFilter && !filters.freeFilter && !filters.offlineFilter) {
        filter.userStatus = 'ONLINE';
        filter.userLoad = 'BUSY';
      }
      if (!filters.busyFilter && filters.freeFilter && !filters.offlineFilter) {
        filter.userStatus = 'ONLINE';
        filter.userLoad = 'FREE';
      }
      if (filters.busyFilter && filters.freeFilter && !filters.offlineFilter) {
        filter.userStatus = 'ONLINE';
      }
      if (!filters.busyFilter && filters.freeFilter && filters.offlineFilter) {
        filter.userLoad = 'FREE';
      }
      if (!filters.busyFilter && !filters.freeFilter && filters.offlineFilter) {
        filter.userStatus = 'OFFLINE';
      }
      if (!filters.busyFilter && filters.freeFilter && filters.offlineFilter) {
        filter.userLoad = 'FREE';
      }
      if (filters.busyFilter && !filters.freeFilter && filters.offlineFilter) {
        filter.userLoad = 'BUSY';
      }
    }

    return filter;
  }

  useEffect(() => {
    async function fetchEligibleUsersData() {
      let payload = {};
      let userFilter = getUserFilters();
      payload = {
        search: search,
        userLoad: userFilter.userLoad,
        userStatus: userFilter.userStatus,
        from: props.selectedDateStartTime,
        to: props.selectedDateEndTime,
      };

      const response = await AssignmentService.getEligibleUsersForAssignment(props.sTask.taskId, builderTaskLocation.get(props.sTask.taskType), payload);

      // console.log("Response", response)

      if (response && response.code === '200') {
        const usersTemp = response?.data;
        setSelectedUser(null);
        setSelectedIndex(-1);
        setUsers(usersTemp);
        setUserCardList([]);
        buildUserCardList(usersTemp, -1);
        handleMarkersAndPolylines('SELECTED_TASK_USERS', usersTemp, null, props.sTask, -1);
      }
    }

    if (props.sTask && !props.sTask.user && !props.sTask.taskInTerminalState) {
      setSelectedUser(null);
      setSelectedIndex(-1);
      setUsers([]);
      // setUserCardList([]);
      fetchEligibleUsersData();
    } else if (props.sTask && !props.sTask.user && props.sTask.taskInTerminalState) {
      setSelectedUser(null);
      setSelectedIndex(-1);
      setUsers([]);
      setUserCardList([]);
    }

    if (search && props.sTask && !props.sTask.user && !props.sTask.taskInTerminalState) {
      setSelectedUser(null);
      setSelectedIndex(-1);
      setUsers([]);
      // setUserCardList([]);
      fetchEligibleUsersData();
    } else if (props.sTask && !props.sTask.user && props.sTask.taskInTerminalState) {
      setSelectedUser(null);
      setSelectedIndex(-1);
      setUsers([]);
      setUserCardList([]);
    }
  }, [props?.sTask, search, filters]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    async function fetchTeamUsersData() {
      var teamIds = props.selectedTeam ? [props.selectedTeam] : undefined;
      let payload = {};
      let userFilter = getUserFilters();
      payload = {
        search: search,
        userLoad: userFilter.userLoad,
        userStatus: userFilter.userStatus,
        from: props.selectedDateStartTime,
        to: props.selectedDateEndTime,
      };

      const response = await AssignmentService.getTeamUsers(payload);
      // console.log("Response", response)
      if (response && response.code === '200') {
        const usersTemp = response?.data;
        setUsers(usersTemp);
        setUserCardList([]);
        buildUserCardList(usersTemp, -1);
        handleMarkersAndPolylines('TEAM_USERS', usersTemp, null, props.sTask, -1);
      }
    }

    if (!props.sTask && props.selectedDateStartTime) {
      // setUserCardList([]);
      setSelectedUser(null);
      setSelectedIndex(-1);
      props.setUserPolylineList(null);
      fetchTeamUsersData();
    }

    if (search && !props.sTask && props.selectedDateStartTime) {
      // setUserCardList([]);
      setSelectedIndex(-1);
      fetchTeamUsersData();
    }
  }, [props.sTask, props.selectedDateStartTime, search, filters, props.selectedTeam]);

  useEffect(() => {
    async function fetchTeamUsersData() {
      var teamIds = props.selectedTeam ? [props.selectedTeam] : undefined;
      let payload = {};
      let userFilter = getUserFilters();
      payload = {
        search: search,
        userLoad: userFilter.userLoad,
        userStatus: userFilter.userStatus,
        from: props.selectedDateStartTime,
        to: props.selectedDateEndTime,
      };

      const response = await AssignmentService.getTeamUsers(payload);
      // console.log("Response", response)
      if (response && response.code === '200') {
        const usersTemp = response?.data;
        setUsers(usersTemp);
        setUserCardList([]);
        buildUserCardList(usersTemp, -1);
        handleMarkersAndPolylines('TEAM_USERS', usersTemp, null, props.sTask, -1);
      }
    }

    if (!props.sTask) {
      // setUserCardList([]);
      setSelectedUser(null);
      setSelectedIndex(-1);
      props.setUserMarkerList(null);
      props.setUserPolylineList(null);
      fetchTeamUsersData();
    }
  }, [props.sTask, props.refresh]);

  useEffect(() => {
    if (selectedUser) {
      buildUserCardList(users, selectedIndex);

      if (props.sTask) {
        // handleUserHover(user, props.sTask);
        if (props.sTask.taskInTerminalState === false) {
          handleMarkersAndPolylines('SELECTED_TASK_USERS', users, selectedUser, props.sTask, selectedIndex);
        }
      } else {
        // only user selected.. show his current tasks
        handleMarkersAndPolylines('SELECTED_TASK_USERS', users, selectedUser, props.sTask, selectedIndex);
      }
    }
  }, [selectedIndex]);

  const buildUserCardList = (users, sIndex) => {
    // console.log("Card Build List", users);
    setUserCount(users.length);
    let userCardList = [];
    for (let index = 0; index < users.length; index++) {
      userCardList.push(buildUserCard(index, users[index], users, sIndex));
    }

    setUserCardList(userCardList);
    // handleMarkersAndPolylines(users, null);
  };

  const buildUserCard = (index, user, users, sIndex) => {
    let text = user.name + ' ( # ' + user.userId + ' )';
    let phoneText = (user?.phone?.countryCode || '') + ' ' + (user?.phone?.num || '');
    let secondaryText = '';
    if (users[index].distanceFromTaskInMts) {
      secondaryText =
        (users[index].distanceFromTaskInMts / 1000).toFixed(2) +
        'km' +
        (users[index].timeFromTaskInSecs ? ' - ' + (users[index].timeFromTaskInSecs / 60).toFixed(2) + ' mins' : '');
    }
    let tertiaryText = (user.tasks ? filterTasks(user.tasks).length : 0) + ' task(s) pending';

    let statusColor = 'grey';
    if (user.status === 'ONLINE') {
      if (filterTasks(user.tasks).length > 0) {
        statusColor = 'blue';
      } else {
        statusColor = 'green';
      }
    }
    let actionButtonColor = props.sTask && props.sTask.taskInTerminalState === false ? 'secondary' : 'default';
    let buttonDisabled = props.sTask && props.sTask.taskInTerminalState === false ? false : true;
    let actionText = props.sTask?.userId ? 'Unassign' : 'Assign';
    let color = user.color ? user.color : actionButtonColor;

    return (
      <List
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
          height: '135px',
        }}
      >
        <ListItem
          key={index}
          button
          selected={sIndex === index}
          onClick={(event) => handleListItemClick(event, index, users, user)}
          onMouseOver={(event) => handleListItemMouseOver(event, index, users, user)}
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            justifyContent: 'center',
          }}
        >
          <div class='flex flex-row'>
            <div class='flex flex-col justify-start'>
              <ListItemAvatar>
                <Avatar variant='rounded' alt='z' src={defaultUserProfile}></Avatar>
              </ListItemAvatar>
            </div>
            <div class='flex flex-col justify-start items-start' style={{ marginLeft: '10px' }}>
              <div
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '180px',
                }}
                title={text}
              >
                <span class='text-xs font-bold'>{text}</span>
              </div>
              <div>
                <span className='text-xs'>{phoneText}</span>
              </div>
              <div>
                <span class='text-xs'>{secondaryText}</span>
              </div>
              <div>
                <span class='text-xs'>{tertiaryText}</span>
              </div>
              <div class='text-xs'>
                <Button
                  edge='end'
                  style={{ color: color }}
                  size='small'
                  variant='outlined'
                  // color={actionButtonColor}
                  disabled={buttonDisabled}
                  onClick={(event) => handleAssignmentClick(event, index, user)}
                >
                  {actionText}
                </Button>
              </div>
            </div>
            <div className='flex flex-col justify-start'>
              <div className='justify-center pb-2 ml-1'>
                <FiberManualRecordIcon style={{ color: statusColor }} fontSize='small' />
              </div>
            </div>
          </div>
        </ListItem>
      </List>
    );
  };

  const handleMarkersAndPolylines = (type, users, selectedUser, selectedTask, sIndex) => {
    switch (type) {
      case 'TEAM_USERS':
        var teamUsersMarkers = getTeamUsersMarkers(users, selectedUser);
        props.setUserMarkerList(teamUsersMarkers);
        props.setUserList(users);
        buildUserCardList(users, sIndex);
        break;

      case 'SELECTED_TASK_USERS':
        props.setUserMarkerList(null);
        var selectedTaskUsersMarkers = getSelectedTaskUsersMarkers(users, selectedUser, selectedTask);
        props.setUserMarkerList(selectedTaskUsersMarkers);
        buildUserCardList(users, sIndex);
        props.setTaskMarkerList(null);
        break;

      default:
        break;
    }
  };

  function getTeamUsersMarkers(users, selectedUser) {
    let markers = [];
    let index = -1;
    users.forEach((user) => {
      index = index + 1;

      let userLatitude = null;
      let userLongitude = null;
      let icon = null;

      if (user.userLocation) {
        userLatitude = user.userLocation.latitude;
        userLongitude = user.userLocation.longitude;
        if (selectedUser !== null && user.userId === selectedUser.userId) {
          if (user.userStatus == 'OFFLINE') {
            icon = userOfflineSelectedIcon;
          } else if (user.tasks?.length > 0) {
            icon = userBusySelectedIcon;
          } else {
            icon = userIdleSelectedIcon;
          }
        } else {
          if (user.userStatus === 'OFFLINE') {
            icon = userOfflineIcon;
          } else if (user.tasks?.length > 0) {
            icon = userBusyIcon;
          } else {
            icon = userIdleIcon;
          }
        }
      }

      if (userLatitude && userLongitude) {
        markers.push(
          <Marker position={[userLatitude, userLongitude]} icon={icon} key={index}>
            <Popup closeButton={false}>
              User Id -{' '}
              <b>
                <i>{user.userId}</i>
              </b>
              <br />
              User Name -{' '}
              <b>
                <i>{user.name}</i>
              </b>
              <br />
              Last Updated At -{' '}
              <b>
                <i>{user.userLocation ? getFullDateHour(new Date(user.userLocation.ts).toISOString()) : 'Unknown'}</i>
              </b>
            </Popup>
          </Marker>
        );
      }
    });

    return markers;
  }

  function getSelectedTaskUsersMarkers(users, selectedUser, selectedTask) {
    let markers = [];
    let polylines = [];
    if (selectedUser) {
      let tasks = [...selectedUser.tasks];
      tasks = filterTasks(tasks);
      if (selectedTask && !selectedTask.user) {
        tasks.push(selectedTask);
      }

      tasks.sort(function (a, b) {
        var dateA = new Date(a.scheduledSlot.from),
          dateB = new Date(b.scheduledSlot.from);
        return dateA - dateB;
      });
      let index = 0;
      var pathFillColor = '#C4C4C4';
      if (selectedUser.status === 'ONLINE') {
        if (selectedUser.tasks?.length > 0) {
          pathFillColor = '#4857D7';
        } else {
          pathFillColor = '#48CC19';
        }
      }
      // pathFillColor = "#" + ((Math.random() * 0xf6f6f6) << 0).toString(16);

      let icon = getIcon(pathFillColor, '', false, false);

      if (selectedUser.userLocation) {
        markers.push(getUserMarker(selectedUser, icon, index));
      }

      // markers.push(getTaskMarkers(tasks, selectedTask, null, pathFillColor));
      markers = markers.concat(getTaskMarkers(tasks, selectedTask, null, pathFillColor));
      polylines.push(getPolylines(tasks, selectedTask, selectedUser, pathFillColor));
    } else {
      let index = -1;

      users.forEach((user) => {
        index++;
        // var pathFillColor =
        //   "#" + ((Math.random() * 0xf6f6f6) << 0).toString(16);
        var pathFillColor = '#C4C4C4';
        if (user.status === 'ONLINE') {
          if (user.tasks?.length > 0) {
            pathFillColor = '#4857D7';
          } else {
            pathFillColor = '#48CC19';
          }
        }
        let icon = getIcon(pathFillColor, '', false, false);
        user.color = pathFillColor;
        if (user.userLocation) {
          markers.push(getUserMarker(user, icon, index));
        }

        markers = markers.concat(getTaskMarkers(filterTasks(user.tasks), selectedTask, null, pathFillColor));
        polylines.push(getPolylines(filterTasks(user.tasks), selectedTask, user, pathFillColor));
      });

      if (selectedTask) {
        let taskIcon = getTaskIcon(selectedTask, selectedTask);

        markers = markers.concat(getTaskMarkers([selectedTask], selectedTask, taskIcon, null));
      }
    }

    props.setUserPolylineList(polylines);

    return markers;
  }

  function getUserMarker(user, icon, index) {
    return (
      <Marker position={[user.userLocation.latitude, user.userLocation.longitude]} icon={icon} key={index}>
        <Popup closeButton={false}>
          User Name - <b>{user.name}</b>
          <br />
          User Id - <b>{user.userId}</b>
          <br />
          Last Updated At -{' '}
          <b>
            <i>{user.userLocation ? getFullDateHour(new Date(user.userLocation.ts).toISOString()) : 'Unknown'}</i>
          </b>
        </Popup>
      </Marker>
    );
  }

  function getTaskMarkers(tasks, selectedTask, useIcon, pathFillColor) {
    let markers = [];
    let index = 0;

    tasks.forEach((task) => {
      let orderInfo = task.data[0]?.info;

      index++;
      let icon = null;
      if (useIcon) {
        icon = useIcon;
      } else {
        if (selectedTask && selectedTask.taskId === task.taskId) {
          icon = getIcon(pathFillColor, index, true, true);
        } else {
          icon = getIcon(pathFillColor, index, true, false);
        }
      }
      let assignedText = 'None';
      if (task.userId) {
        assignedText = task.userId;
      }
      let slot =
        getLocalizedTime(task?.scheduledSlot?.from) +
        ' TO ' +
        getLocalizedTime(task?.scheduledSlot?.to);

      let location = pickupLocationTasks.includes(task?.taskType)
        ? orderInfo?.pickup?.location
        : (builderTaskLocation.has(task?.taskType) ?  task?.data[builderTaskLocation.get(task?.taskType)] : orderInfo?.customer?.location);

      markers.push(
        <Marker position={[location?.latitude, location?.longitude]} icon={icon} key={index}>
          <Popup closeButton={false}>
            Slot - <b>{slot}</b>
            <br />
            Assigned To - <b>{assignedText}</b>
            <br />
            <br />
            {task.taskTypeDisplayName} - <b>{task.taskId}</b> (<b>{task.taskStatusName}</b>)
            {orderInfo && 
            (<span><br /> Order # - <b>{orderInfo.orderId}</b>
            <br /> Customer Name - <b>{orderInfo.customer.name}</b></span>)}
            <br /> Address - <b>{location?.address}</b>
            <br /> Location -{' '}
            <b>
              {location?.latitude}, {location?.longitude}
            </b>
          </Popup>
        </Marker>
      );
    });

    return markers;
  }

  function getIcon(myCustomColour, index, isTask, isSelectedTask) {
    let icon = null;
    if (isTask === true && isSelectedTask === false) {
      icon = L.divIcon({
        className: 'my-custom-pin',
        iconAnchor: [0, 24],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span 
        style="background-color : ${myCustomColour};
        text-align:center;
        font-weight: normal;
        color:#fff;
        width: 1.3rem;
        height: 1.3rem;
        display: block;
        left: -0.6rem;
        top: 0.05rem;
        position: relative;
        border-radius: 3rem 3rem 0rem;
        transform: rotate(45deg);
        border: 1px solid #FFFFFF;"
        className="numberedTaskMarker"><div  style="transform: rotate(-45deg);
        text-align: center" className="textMarker numberedTaskMarker">${index}</div><</span>`,
      });
    } else if (isTask === true && isSelectedTask === true) {
      icon = L.divIcon({
        className: 'my-custom-pin',
        iconAnchor: [0, 24],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span 
        style="background-color : ${myCustomColour}; 
        text-align:center;
        font-weight: normal;
        color:#fff ;
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        left: -0.8rem;
        top: -0.3rem;
        position: relative;
        border-radius: 3rem 3rem 0rem;
        transform: rotate(45deg) ;
        border: 1px solid #000;"
         className="numberedSelectedTaskMarker">
         <div  style="transform: rotate(-45deg);
         text-align: center" className="textMarker numberedSelectedTaskMarker">
          ${index}
         </div>
         </span>`,
      });
    } else {
      icon = L.divIcon({
        className: 'my-custom-pin',
        iconAnchor: [0, 24],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span 
        style="background-color : ${myCustomColour};
        text-align:center;
        width: 0.8rem;
        height: 0.8rem;
        display: block;
        left: -0.5rem;
        top: 1.1rem;
        position: relative;
        border-radius: 3rem 3rem 3rem;
        transform: rotate(0deg);
        border: 1px solid #FFFFFF;" 
        className="numberedUserMarker">${index}</span>`,
      });
    }

    return icon;
  }

  function getPolylines(tasks, selectedTask, user, pathFillColor) {
    let polylineList = [];

    let polyline = [];
    let polylineBroken = [];
    if (user.userLocation) {
      polyline.push([user.userLocation.latitude, user.userLocation.longitude]);
    }

    let orderInfo = null;
    let selectedTaskAdded = false;
    for (let i = 0; i < tasks.length; i++) {
      let task = tasks[i];
      orderInfo = task.data[0]?.info;

      let location = pickupLocationTasks.includes(task?.taskType)
        ? orderInfo?.pickup?.location
        : (builderTaskLocation.has(task?.taskType) ?  task?.data[builderTaskLocation.get(task?.taskType)] : orderInfo?.customer?.location);

      if (selectedTask && selectedTask.user) {
        polyline.push([location?.latitude, location?.longitude]);
      } else {
        if (selectedTask && task.taskId === selectedTask.taskId) {
          selectedTaskAdded = true;
          if (polyline.length > 0) {
            polylineBroken.push(polyline[polyline.length - 1]);
          }
          polylineBroken.push([location?.latitude, location?.longitude]);
        } else {
          if (selectedTaskAdded === true) {
            polylineBroken.push([location?.latitude, location?.longitude]);
          } else {
            polyline.push([location?.latitude, location?.longitude]);
          }
        }
      }
    }

    if (polylineBroken.length > 0) {
      polylineList.push(
        <Polyline
          pathOptions={{
            color: '#000',
            dashArray: '5, 5',
            dashOffset: '5',
          }}
          positions={polylineBroken}
        />
      );
    }
    if (polyline.length > 0) {
      polylineList.push(
        <Polyline
          pathOptions={{ color: '#000', weight: '2', dashArray: null, dashOffset: null }}
          positions={polyline}
        />
      );
    }

    return polylineList;
  }

  function getTaskIcon(task, selectedTask) {
    let taskSelected = selectedTask && task.taskId === selectedTask.taskId;
    let icon = null;
    switch (task.progress) {
      case 'NEW':
        icon = taskSelected === true ? taskNewSelectedIcon : taskNewIcon;
        break;
      case 'NEW-ASSIGNED':
        icon = taskSelected === true ? taskNewAssignedSelectedIcon : taskNewAssignedIcon;
        // assignedText = task.user.name + " ( " + task.userId + " )";
        break;
      case 'NEW-OVERDUE':
        icon = taskSelected === true ? taskNewOverdueSelectedIcon : taskNewOverdueIcon;
        break;
      case 'ASSIGNED-OVERDUE':
        icon = taskSelected === true ? taskAssignedOverdueSelectedIcon : taskAssignedOverdueIcon;
        // assignedText = task?.user?.name + " ( " + task?.userId + " )";
        break;
      case 'INPROGRESS':
        icon = taskSelected === true ? taskInprogressSelectedIcon : taskInprogressIcon;
        // assignedText = task?.user?.name + " ( " + task?.userId + " )";
        break;
      case 'COMPLETED':
        icon = taskSelected === true ? taskCompletedSelectedIcon : taskCompletedIcon;
        // assignedText = task?.user?.name + " ( " + task?.userId + " )";
        break;
      default:
        icon = taskSelected === true ? taskNewSelectedIcon : taskNewIcon;
        break;
    }

    return icon;
  }

  // function filterTasks(tasks) {
  //   let fTasks = [];

  //   tasks.forEach((task) => {
  //     if ((task?.data[0]?.info && !filteredTasks.includes(task.taskType)) || builderTaskLocation.has(task.taskType)) {
  //       fTasks.push(task);
  //     }
  //   });

  //   return fTasks;
  // }

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row items-center'>
        <div className='flex flex-row justify-center items-center'>
          <Checkbox
            checked={filters.busyFilter}
            onChange={(e) =>
              setFilters({
                ...filters,
                busyFilter: !filters.busyFilter,
              })
            }
            size='small'
            inputProps={{ 'aria-label': 'Busy' }}
            color='black'
          />
          <span className='text-sm'>Busy</span>
        </div>
        <div className='flex flex-row justify-center items-center'>
          <Checkbox
            checked={filters.freeFilter}
            onChange={(e) =>
              setFilters({
                ...filters,
                freeFilter: !filters.freeFilter,
              })
            }
            size='small'
            inputProps={{ 'aria-label': 'Free' }}
            color='black'
          />
          <span className='text-sm'>Free</span>
        </div>
        <div className='flex flex-row justify-center items-center'>
          <Checkbox
            checked={filters.offlineFilter}
            onChange={(e) =>
              setFilters({
                ...filters,
                offlineFilter: !filters.offlineFilter,
              })
            }
            size='small'
            inputProps={{ 'aria-label': 'Offline' }}
            color='black'
          />
          <span className='text-sm'>Offline</span>
        </div>
        <div class='justify-center items-center ml-4'>
          <TextField
            id='searchUser'
            value={search}
            placeholder='Search User'
            variant='outlined'
            onChange={(e) => {
              handleSearchChange(e);
            }}
            InputProps={{
              endAdornment: <SearchIcon style={{ color: '#C0C0C0' }} />,
            }}
          />
        </div>
      </div>
      <Root key={1000 * Math.random()} style={{ width: `${userCount * 3}00px` }}>
        {userCardList}
      </Root>
    </div>
  );
}
