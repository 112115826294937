import { useState, useEffect, useCallback, memo } from "react"
import { ZButton } from "views/commonComponents/commonComponents.styles"
import useEntity from "../hooks/useEntity"
import { TableRSEvents, ViewsRSEvents } from "../types"
import LocalStorageUtil from "utils/LocalStorageUtil";
import debounce from 'lodash/debounce';
import ColumnCreation from "views/entities/components/ColumnCreation";
//@ts-ignore
import { EntityService } from "providers/data/services/EntityService"
import { MdOutlineSearch as SearchIcon } from "react-icons/md";
import {
  OnboardingIdsForComponent,
  recordRSEvent,
} from "utils/CommonUtils"
import {
  setSelectedRow,
} from "../reducers/entityReducers"
import { useDispatch } from "react-redux"
import DeletionConfirmationDialog from "utils/DeletionConfirmationDialog"
import { RiDeleteBin2Line } from "react-icons/ri"
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai"
import { getTaskTypeCustomFieldsForBulkTask } from "utils/TasktypeUtils"
import { HiOutlineFilter } from "react-icons/hi"
import notification from "notifications/notifications"
import styled from "styled-components"
import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"
import useAccessPermissions from "hooks/useAccessPermissions"
import { LuArrowUpDown as SortingIcon } from "react-icons/lu";
import usePorterPermissions from "hooks/usePorterPermissions"
import { ResourceTypeConstant } from "views/entities/permissions/types"
import { IoMdCloseCircleOutline as CloseIcon } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import {
  FilterAction,
  Actions,
  RefreshTableButton,
  ShowHideColumnAction,
} from "./actions/Actions";

// interface IndicatorProps {
//   global?: boolean
//   sorting?: boolean
//   reset?: boolean
// }

export const ZButtonWrapper = styled(ZButton) <{ $isFilter?: boolean, $isSorting?: boolean, $isReset?: boolean, $isGlobalFilter?: boolean }>`
  background-color: ${(props) => (props.$isFilter ? "#FFFAEB" : props.$isSorting ? "#ECFDF3" : props.$isReset ? "#FEF3F2" : props.$isGlobalFilter ? '#EFF8FF' : "#fff3d3")} !important;
  color: ${(props) => (props.$isFilter ? "#B54708" : props.$isSorting ? "#027A48" : props.$isReset ? "#B54708" : props.$isGlobalFilter ? '#175CD3' : "#000")} !important;
  padding: 2px 8px !important;
  align-items: center !important;
  border-radius: 4px !important;
  border: none !important;
  text-transform: none !important;
  font-size: 12px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  line-height: 18px !important;
`

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 200px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  border: 1px solid #EAECF0;
  border-radius: 4px;
`

const Input = styled.input`
  width: 100%;
  margin-right: 10px;
  font-size: 11px;
  outline: none;
  border: none;
`

type Props = {
  table: any;
  setLoading: any;
  viewChanges?: { [viewId: string]: boolean };
  setViewChanges?: (viewChanges: { [viewId: string]: boolean }) => void;
  isDefaultView?: boolean;
  getTableInstance?: any
  columnFilterFns: any;
  showColumnFilters: boolean
  setRefresh: any;
  setSelectedRows?: any
  viewId?: string
}

const CustomToolbar = ({
  table,
  setLoading,
  viewChanges,
  setViewChanges,
  getTableInstance,
  columnFilterFns,
  showColumnFilters,
  setRefresh,
  setSelectedRows,
  viewId,
}: Props) => {
  const selectedRows = table.getSelectedRowModel().rows.map((row: any) => {
    return row.original
  })

  const {
    selectedEntity,
    selectedEntityMetadata,
    savedViewChanges,
    showColumnCreationModal,
    recordTypeLabel,
  } = useEntity()
  const [showDeletionConfirmationDialog, setShowDeletionConfirmationDialog] =
    useState(false);

  const navigate = useNavigate()
  const porterPermissions = usePorterPermissions()
  const accessPermissions = useAccessPermissions({ resourceType: ResourceTypeConstant.TABLE })

  const currentTableState = LocalStorageUtil.getItem(`tableState_${selectedEntity?.tableType}_${viewId}`)
  const savedTableState = viewId ? viewId in savedViewChanges ? savedViewChanges[viewId] : currentTableState : 0

  const colFilterDiff = (savedTableState && currentTableState && savedTableState?.columnFilters) ? savedTableState?.columnFilters?.length : 0
  const sortDiff = (savedTableState && currentTableState && savedTableState?.sorting) ? savedTableState?.sorting?.length : 0

  const isFilterApplied = accessPermissions?.manage_table_view ? table.getState()?.columnFilters?.length : table.getState()?.columnFilters.length - colFilterDiff;

  const isSortingApplied = accessPermissions?.manage_table_view ? table.getState()?.sorting?.length : table.getState()?.sorting.length - sortDiff

  const isGlobalSearchApplied = table.getState()?.globalFilter?.length
  const tableState = localStorage.getItem(`tableState_${selectedEntity?.tableType}_${viewId}`);
  const globalFilter = tableState ? JSON.parse(tableState).globalFilter : "";
  const [searchInput, setSearchInput] = useState(globalFilter);

  const removeColumnFilters = () => {
    if (accessPermissions?.manage_table_view) {
      table.resetColumnFilters()
      table.setColumnFilters([])
    } else {
      if (!viewId || !savedViewChanges) return
      table.resetColumnFilters()
      table.setColumnFilters(savedViewChanges?.[viewId]?.columnFilters || [])
    }
  }

  const debouncedSetGlobalFilter = useCallback(
    debounce((value) => {
      table.setGlobalFilter(value);
    }, 1000),
    []
  );

  useEffect(() => {
    debouncedSetGlobalFilter(searchInput);
  }, [searchInput, debouncedSetGlobalFilter]);

  useEffect(() => {
    if (globalFilter) {
      setSearchInput(globalFilter);
    }
  }, [globalFilter]); 

  useEffect(() => {
    if(getTableInstance)
    getTableInstance(table);
  }, [table]);

  const removeSorting = () => {
    if (accessPermissions?.manage_table_view) {
      table.resetSorting()
      table.setSorting([])
    } else {
      if (!viewId || !savedViewChanges) return
      table.resetSorting()
      table.setSorting(savedViewChanges?.[viewId]?.sorting || [])
    }
  }

  const dispatch = useDispatch()

  const handleBulkDelete = async () => {
    try {
      if (selectedRows !== undefined) {
        // create an array of id from selectedRow
        const selectedRowId = selectedRows.map((row: any) => row.recordId);
        if (selectedEntity?.tableType) {
          await EntityService.bulkDeleteRecords(
            selectedEntity?.tableType,
            selectedRowId
          )
          notification("success", "Record deleted successfully")
          if (setRefresh) {
            setRefresh(true);
          }
          setShowDeletionConfirmationDialog(false);
          if (setRefresh) {
            setRefresh(true);
          }
          dispatch(setSelectedRow([]));
          recordRSEvent(TableRSEvents.BULK_DELETE_RECORDS_SUCCESS, {
            tableType: selectedEntity?.tableType || "",
            count: selectedRowId.length,
            deletedRecords: selectedRowId,
          });
          if (setSelectedRows) {
            setSelectedRows([]);
          }
        } else {
          notification("error", "Table id is missing");
        }
      } else {
        notification("error", "Something went wrong. Please try again");
      }
    } catch (error: any) {
      console.log(error)
      notification(
        "error",
        error?.response?.data?.message ||
          "Something went wrong. Please try again"
      );
      recordRSEvent(TableRSEvents.BULK_DELETE_RECORDS_FAILURE, {
        tableType: selectedEntity?.tableType || "",
      });
    }
  };

  const resetCurrentView = () => {
    recordRSEvent(ViewsRSEvents.RESET_VIEW_BUTTON_CLICKED);
    if (!savedViewChanges || !viewId || !savedViewChanges[viewId]) return;
    table.resetSorting()
    if (savedViewChanges[viewId].sorting) table.setSorting(savedViewChanges[viewId].sorting);
    table.resetGlobalFilter()
    if (savedViewChanges[viewId].globalFilter) table.setGlobalFilter(savedViewChanges[viewId].globalFilter);
    table.resetColumnFilters()
    if (savedViewChanges[viewId].columnFilters) table.setColumnFilters(savedViewChanges[viewId].columnFilters);
    table.resetColumnOrder()
    if (savedViewChanges[viewId].sorting) table.setColumnOrder(savedViewChanges[viewId].columnOrdering);
    table.resetColumnVisibility()
    if (savedViewChanges[viewId].columnVisibility) table.setColumnVisibility(savedViewChanges[viewId].columnVisibility);
    if (viewId) LocalStorageUtil.setItem(`tableState_${selectedEntity?.tableType}_${viewId}`, { ...savedViewChanges[viewId] });
    if (setViewChanges && viewChanges && viewId) {
      LocalStorageUtil.setItem(`viewChanges_${selectedEntity?.tableType}`, JSON.stringify({ ...viewChanges, [viewId]: false }));
      setViewChanges({ ...viewChanges, [viewId]: false });
    }
  }

  useEffect(() => {
    // remove the button, ID, createdOn and updatedOn column from the fields
    getTaskTypeCustomFieldsForBulkTask(selectedEntityMetadata)
  }, [selectedEntityMetadata])

  return (
    <>
      <div
        style={{
          width: "100%",
          // padding:"8px"
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            // alignItems: "flex-start", This is causing the badge to be top aligned
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "6px",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {isFilterApplied === 0 && <FilterAction showColumnFilters={showColumnFilters} table={table} />}
            <ShowHideColumnAction table={table} />
            {selectedRows.length > 0 && (
              <>
                {porterPermissions.checkPermissionsForPorter(
                  selectedEntity?.tableType,
                  "delete_record"
                ) && (
                  <ZButton
                    onClick={() => {
                      setShowDeletionConfirmationDialog(true);
                      recordRSEvent(
                        TableRSEvents.BULK_DELETE_RECORDS_BUTTON_CLICKED,
                        {
                          tableType: selectedEntity?.tableType || "",
                        }
                      );
                    }}
                    danger
                    variant="contained"
                    startIcon={<RiDeleteBin2Line />}
                    id={OnboardingIdsForComponent.DELETE_TABLE_RECORD_BUTTON}
                  >
                    {" "}
                    Delete{" "}
                  </ZButton>
                )}
              </>
            )}
            {isFilterApplied > 0 && (
              <ZButtonWrapper $isFilter={true} onClick={() => table.setShowColumnFilters(!showColumnFilters)}
                startIcon={<HiOutlineFilter size={"12px"} color="#B54708" />}
                endIcon={<AiOutlineClose color="#B54708" onClick={removeColumnFilters} size={"12px"} strokeWidth={1} />}
              >
                <span style={{ marginBottom: '1px' }}>{isFilterApplied} filter(s) applied</span>
              </ZButtonWrapper>
            )}
            {isSortingApplied > 0 && (
              <ZButtonWrapper $isSorting
                startIcon={<SortingIcon color={'#027A48'} size={'12px'} />}
                endIcon={<AiOutlineClose color={'#027A48'} onClick={removeSorting} size={"12px"} strokeWidth={1} />}>
                <span style={{ marginBottom: '1px' }}>Sorted by {isSortingApplied} field</span>
              </ZButtonWrapper>
            )}
            {
              (isGlobalSearchApplied > 0) && (
                <ZButtonWrapper $isGlobalFilter
                  endIcon={<AiOutlineClose
                    color={'#B54708'}
                    onClick={() => table.setGlobalFilter("")}
                    size={"12px"}
                  />}>
                  <span style={{ marginBottom: '1px' }}> Global search applied </span>
                </ZButtonWrapper>
              )
            }
            {viewId && viewChanges && viewChanges[viewId] && (
              <ZButtonWrapper onClick={resetCurrentView} $isReset startIcon={<CloseIcon color={'#DC6803'} size={"12px"} />}>
                <div>
                  <p> Reset View </p>
                </div>
              </ZButtonWrapper>
            )
            }
          </div>
          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "flex-start",
              alignItems: "center"
            }}
          >
            <InputContainer>
              <SearchIcon color={'#C2C9D1'} fontSize={20} />
              <Input
                type="text"
                placeholder="Search..."
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
              />
            </InputContainer>
            <RefreshTableButton setRefresh={setRefresh} table={table} />
            <Actions
              columnFilterFns={columnFilterFns}
              setLoading={setLoading}
              table={table}
            />
            {accessPermissions.create_record && porterPermissions.checkPermissionsForPorter(
              selectedEntity?.tableType,
              "create_record"
            ) && (
                <ZButton
                  onClick={() => {
                    navigate(`/table/create/${selectedEntity?.tableType}`);
                    recordRSEvent(TableRSEvents.ADD_NEW_RECORD_BUTTON_CLICKED, {
                      tableType: selectedEntity?.tableType || "",
                    });
                  }}
                  variant="contained"
                  startIcon={<AiOutlinePlus size={"16px"} />}
                  disabled={!selectedEntity}
                  id={OnboardingIdsForComponent.CREATE_NEW_TABLE_RECORD_BUTTON}
                >
                  <div style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>{recordTypeLabel}</div>
                </ZButton>
              )}
          </div>
        </div>
      </div>

      <DeletionConfirmationDialog
        id="deleteRecord"
        deletionTitle="Delete Records"
        deletionText="Are you sure you want to delete these records?"
        isOpen={showDeletionConfirmationDialog}
        onClose={() => {
          setShowDeletionConfirmationDialog(false);
          recordRSEvent(TableRSEvents.BULK_DELETE_RECORDS_CANCEL, {
            tableType: selectedEntity?.tableType || "",
          });
        }}
        onConfirmDelete={handleBulkDelete}
        primaryButtonName="Delete"
      />
      {showColumnCreationModal && accessPermissions.manage_table_column && <ColumnCreation />}
    </>
  );
};



export default CustomToolbar;