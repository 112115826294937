import { Show, ShowController, TopToolbar, ListButton, EditButton } from 'react-admin';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import React, { useEffect, useState } from 'react';

import notification from '../../notifications/notifications';
import RoleAcl from './RoleAcl';
import { recordPageVisit, PageCategoryEnum } from '../../config/Rudderstack';
import { TaskFormBody } from 'render-engine/render.styles';
import UserDisplayBlock from 'views/users/UserDisplayBlock';
import { hasPermission } from 'providers/auth/authUtils';
import { useParams } from 'react-router-dom';
import { RoleService } from 'providers/data/services/RoleService';
import { isEmpty } from 'lodash';

import styled from 'styled-components';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2rem; /* gap-x-8 in Tailwind CSS */

  @media (min-width: 640px) {
    grid-template-columns: repeat(8, 1fr); /* sm:grid-cols-8 in Tailwind CSS */
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 0.25rem; /* px-1 in Tailwind CSS */
  padding-right: 0.25rem; /* px-1 in Tailwind CSS */

  @media (min-width: 768px) {
    padding-left: 0.5rem; /* md:px-2 in Tailwind CSS */
    padding-right: 0.5rem; /* md:px-2 in Tailwind CSS */
  }
`;

const StyledDivFlex = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem; /* rounded-xl in Tailwind CSS */
`;

const RoleShowActions = ({ basePath, data, resource }) => {
  return (
    <>
      <TopToolbar className='tool-bar'>
        <div>
        <ListButton
            style={{
              border: "1px solid #c2c9d1",
              color: "#344054",
              fontSize: "1rem",
              fontWeight: "600",
              backgroundColor: "#ffffff",
              marginRight: "8px",
              padding: "6px 16px",
              borderRadius: "4px",
            }}
            basePath={'/settings/roles'} label='Back' icon={<ChevronLeft />} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          
          {hasPermission("role", "edit") && <EditButton
            style={{
              border: "1px solid #c2c9d1",
              color: "#344054",
              fontSize: "1rem",
              fontWeight: "600",
              backgroundColor: "#FFF",
              padding: "6px 16px",
            }}
          />}
        </div>
      </TopToolbar>
    </>
  );
};



const RoleShow = (props) => {
  // const classes = useStyles();
  const {id} = useParams();
  const [roleInfo, setRoleInfo] = useState({});

  const getRolesData = async () => {
    try {
      const response = await RoleService.getRole(id);
      setRoleInfo(response.data);
      return response.data
    } catch (error) {
      notification('error', 'Error in fetching role data');
    }
  
  }

  useEffect(() => {
    getRolesData();
  }, []);

  useEffect(() => {
    recordPageVisit(PageCategoryEnum.role, 'role_details');
  }, [props]);

  return (
    <div className='showTaskParentDiv'>
      {
        isEmpty(roleInfo) ? <div>Loading...</div> : (
          <TaskFormBody>
        
          <ShowController {...{...roleInfo, id: roleInfo.roleId}} component='div'>
            {() => {
              // console.log("Controller Props &&&&", controllerProps);
              return (
                <Show actions={<RoleShowActions />} {...{...roleInfo, id: roleInfo.roleId}}>
                  <div className='contentWrapper'>
                    <div>
                      <h3 className="font-medium" style={{ padding: "10px 0px" }}>
                        Role Details
                      </h3>
                      <hr/>
                    <GridContainer>
                  
                      <UserDisplayBlock label='Role Id' value={roleInfo?.roleId}/>
                      
                      <UserDisplayBlock label='Name' value={roleInfo?.name}/>
                      
                      <UserDisplayBlock label='Description' value={roleInfo?.description}/>
                      
                      <UserDisplayBlock label='Created On' value={roleInfo?.createdOn}/>
                    </GridContainer>
                    </div>
                    <div class='pt-10'>
                      <RoleAcl roleId={id} readOnly={true} />
                    </div>
                     {/* <div class="pt-10">
                                        <UsersOfRole teamId={props.id} />
                                    </div> */}
                  </div>
                </Show>
              );
            }}
          </ShowController>
        
      </TaskFormBody>
        )
      }
      
    </div>
  );
};

export default RoleShow;
