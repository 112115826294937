import { Avatar, Backdrop, Checkbox, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SignatureRIcon } from "assests";
import ImageIco from "assests/imageIcoBlue.svg";
import { ImageModal } from "components/ZImageViewer/ZShowImage";
import ImageSlider from "components/imageviewer/ImageSlider";
import { isArray, isFunction, isObject, union } from "lodash";
import {
  MRT_ColumnFiltersState,
  MRT_DensityState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_VisibilityState,
  MaterialReactTable,
} from "material-react-table";
import moment from "moment";
import notification from "notifications/notifications";
import { EntityService } from "providers/data/services/EntityService";
import { FunctionComponent, createElement, useEffect, useRef, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  OnboardingIdsForComponent,
  complimentColor, recordRSEvent,
  stringAvatar
} from "utils/CommonUtils";
import {
  ActionTypeEntity,
  DataFieldType,
  EntityTypeField,
} from "views/DataField/DataField.types";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import useAccount from "views/layouts/reducer/useAccount";
import { InfoTooltip } from "views/workflows/Tootltips/InfoTooltip";
import { setRowsToDelete } from "render-engine/reducers/show/TaskShowReducer";
import { RiDeleteBinLine } from "react-icons/ri";
import { IColDefs, IRelationReferringEntityType, IRowDefs, TableRSEvents } from "../types";
import ButtonAutomationHeader from "./ButtonAutomationHeader";
import ListOfValueBadges from "./ListOfValueBadges";
import SystemFieldHeader from "./SystemFieldHeader";
import { setShowCreationModal } from "../reducers/entityReducers";
import CustomIcons from "./CustomIcons";
import RelationCellBadge from "./RelationCellBadge";

type Props = {
  initTableConfig?: any;
  onRowDoubleClick?: (row: any) => void;
  enablePinning?: boolean;
  toolbarComponent?: FunctionComponent;
  enableRowSelection?: boolean;
  enableColumnFilterModes?: boolean;
  enableGlobalFilter?: boolean;
  hideToolbar?: boolean;
  fetcherFunction?: (params: {
    tableType: string;
    page: number;
    pageSize: number;
    sort: any[];
    filter: any[];
    search: string;
  }) => Promise<any>;
  tableType: string;
  allowColumnEdit?: boolean;
  setIsTableEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
  addedRecords?: any;
  nameOfTheTableInRelatedTable?: string;
  rowsToDelete?: any[];
  fromShowPage?: boolean;
};

/**
 * either pass initTableConfig or fetcherFunction
 * fetcherFunction should return {colDefs,rowDefs}
 * @param param0
 * @returns
 */
const ZTable = ({
  initTableConfig,
  onRowDoubleClick,
  enablePinning,
  toolbarComponent,
  enableRowSelection,
  enableColumnFilterModes,
  enableGlobalFilter,
  fetcherFunction,
  tableType,
  allowColumnEdit,
  hideToolbar,
  setIsTableEmpty,
  nameOfTheTableInRelatedTable = "",
  addedRecords = [],
  rowsToDelete = [],
  fromShowPage
}: Props) => {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [linksArray, setLinksArray] = useState<any[]>([]);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [columnFilterFns, setColumnFilterFns] = useState<any>({});
  const [columnPinning, setColumnPinning] = useState<any>({
    left: [],
    right: ["showRecord", "addRow"],
  });
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const isFirstRender = useRef(true);

  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    {}
  );
  const [density, setDensity] = useState<MRT_DensityState>("comfortable");
  const [globalFilter, setGlobalFilter] = useState<string | undefined>(
    undefined
  );
  const [showGlobalFilter, setShowGlobalFilter] = useState(true);
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [columnOrdering, setColumnOrdering] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { account } = useAccount();
  const dispatch = useDispatch();

  const executeButtonClick = async (data: any) => {
    try {
      recordRSEvent(TableRSEvents.TABLE_AUTOMATION_BUTTON_CLICKED, {
        ...data,
      });
      setButtonLoading(true);
      await EntityService.executeEntityColumnAutomation(data);
      setButtonLoading(false);
      notification(
        "success",
        "Action related to button has been executed successfully!"
      );
      recordRSEvent(TableRSEvents.ROW_LEVEL_AUTOMATION_BUTTON_SUCCESS, {
        ...data,
      });
    } catch (error) {
      notification(
        "error",
        //@ts-ignore
        error?.response?.data?.message || "Something went wrong"
      );
      setButtonLoading(false);
      recordRSEvent(TableRSEvents.ROW_LEVEL_AUTOMATION_BUTTON_FAILURE, {
        ...data,
      });
    }
  };

  const formatListingConfig = (data: any) => {
    const colDefs: any = data?.colDef?.headers?.map((col: IColDefs) => {
      const extraProps: any = {};
      if (col.colDef.dataType === DataFieldType.TEXT) {
        extraProps.columnFilterModeOptions = [
          "contains",
          "empty",
          "endsWith",
          "equals",
          "startsWith",
          "empty",
          "notEmpty",
          "notEquals",
        ];
        /**
         * Cell should only show first 40 characters and then show ... if more
         *
         */
        extraProps.Cell = (params: any) => {
          // return <p>ascasc</p>
          if (
            isArray(params?.cell?.renderValue?.()) ||
            isObject(params?.cell?.renderValue?.())
          ) {
            return <></>;
          }
          return params?.cell?.renderValue?.()?.length > 25 ? (
            <InfoTooltip placement="top" title={params?.cell?.renderValue?.()}>
              <div>
                <p
                  style={{
                    padding: "4px 12px",
                  }}
                >
                  {params?.cell?.renderValue?.()?.length > 25
                    ? `${params?.cell?.renderValue?.()?.substring?.(0, 25)} ...`
                    : params?.cell?.renderValue?.()}
                </p>
              </div>
            </InfoTooltip>
          ) : (
            <div>
              <p
                style={{
                  padding: "4px 12px",
                }}
              >
                {params?.cell?.renderValue()}
              </p>
            </div>
          );
        };
        extraProps.size = 250;
      }
      if (col.colDef.dataType === DataFieldType.NUMBER) {
        extraProps.columnFilterModeOptions = [
          "equals",
          "greaterThan",
          "greaterThanOrEqualTo",
          "lessThan",
          "lessThanOrEqualTo",
          "between",
          "notEquals",
          "empty",
          "notEmpty",
        ];
        // extraProps.Filter = ({ column }) => {
        //   console.log(">>>> column", column.columnDef._filterFn);
        //   return (
        //     <>
        //       <TextField
        //         type="number"
        //         placeholder={`Filter by ${col.colDef.displayName}`}
        //         fullWidth
        //         variant="standard"
        //         value={parseInt(column.getFilterValue()) || null}
        //         onChange={(e) => {
        //           column.setFilterValue(e.target.value);
        //         }}
        //       />
        //       <p
        //         style={{
        //           fontSize: "9px",
        //           fontWeight: 300,
        //         }}
        //       >
        //         {" "}
        //         Filter Mode : {columnFilterFns[column.id] || ""}{" "}
        //       </p>
        //     </>
        //   );
        // };
      }
      if (col.colDef.dataType === DataFieldType.BOOLEAN) {
        extraProps.enableColumnFilter = true;
        extraProps.enableSorting = false;
        extraProps.columnFilterModeOptions = ["equals"]; //only allow these filter modes

        extraProps.Cell = (params: any) => {
          // put the value in bold
          return (
            <Tooltip
              title={
                params.column.getIsFiltered()
                  ? "Filter Applied"
                  : params.column.getIsSorted()
                  ? "Column is Sorted"
                  : ""
              }
            >
              <div>
                <p
                  style={{
                    padding: "4px 12px",
                    background: params.column.getIsFiltered()
                      ? "#fef3ee"
                      : params.column.getIsSorted()
                      ? "#ecfbe7"
                      : "",
                  }}
                >
                  {params?.cell?.renderValue() === undefined
                    ? ""
                    : params?.cell?.renderValue()
                    ? "True"
                    : "False"}{" "}
                </p>
              </div>
            </Tooltip>
          );
        };
      }
      if (
        col.colDef.dataType === DataFieldType.DATE ||
        col.colDef.dataType === DataFieldType.DATETIME
      ) {
        extraProps.enableColumnFilter = true;
        extraProps.enableSorting = true;
        extraProps.sortingFn = "datetime";
        extraProps.size = 210;
        extraProps.columnFilterModeOptions = [
          "greaterThan",
          "lessThan",
          "equals",
          "empty",
          "notEmpty",
        ]; //only allow these filter modes
        extraProps.filterFn = "equals";
        // convert to MMM dd yyyy, hh:mm a from ISO
        extraProps.Cell = (params: any) => {
          // put the value in bold
          return (
            <Tooltip
              title={
                params.column.getIsFiltered()
                  ? "Filter Applied"
                  : params.column.getIsSorted()
                  ? "Column is Sorted"
                  : ""
              }
            >
              <div>
                <p
                  style={{
                    padding: "4px 12px",
                    background: params.column.getIsFiltered()
                      ? "#fef3ee"
                      : params.column.getIsSorted()
                      ? "#ecfbe7"
                      : "",
                  }}
                >
                  {" "}
                  {params?.cell?.renderValue()
                    ? moment(params?.cell?.renderValue()).format(
                        "Do MMM YYYY , h:mm A"
                      )
                    : ""}
                </p>
              </div>
            </Tooltip>
          );
        };
        extraProps.Filter = ({ column }:any) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              onChange={(newValue:any) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: "Filter Mode: Less Than",
                  sx: { minWidth: "120px" },
                  variant: "standard",
                },
                field: {
                  clearable: true,
                  onClear: () => column.setFilterValue(null),
                },
              }}
              value={
                column.getFilterValue()
                  ? new Date(column.getFilterValue())
                  : null
              }
            />
          </LocalizationProvider>
        );
      }
      if (
        col.colDef.dataType === DataFieldType.SIGNATURE ||
        col.colDef.dataType === DataFieldType.IMAGE ||
        col.colDef.dataType === DataFieldType.FILE
      ) {
        // disable filtering and sorting
        extraProps.enableColumnFilter = false;
        extraProps.enableSorting = false;
        console.log(">>", col);
        extraProps.size = 300;

        extraProps.Cell = (params: any) => {
          // put the value in bold
          return (
            <>
              {" "}
              {params?.cell?.renderValue()?.length > 0 ? (
                <p style={{ color: "#3067c4", padding: "4px 12px" }}>
                  {" "}
                  {col.colDef.dataType === DataFieldType.SIGNATURE ? (
                    <SignatureRIcon
                      style={{ display: "inline" }}
                      color="#667085"
                      size="1.2rem"
                    />
                  ) : (
                    <img
                      height={18}
                      width={18}
                      src={ImageIco}
                      style={{
                        display: "inline",
                        marginRight: "4px",
                        color: "#3067c4",
                      }}
                    />
                  )}
                  View Uploaded Images/Files{" "}
                </p>
              ) : (
                <p style={{ color: "#3067c4" }}> </p>
              )}{" "}
            </>
          );
        };
        extraProps.muiTableBodyCellProps = (params: any) => ({
          onClick: () => {
            const uploadedFiles = params?.cell?.renderValue();
            if (!uploadedFiles) return;
            if (uploadedFiles?.length === 0) return;

            if (typeof uploadedFiles === "string") {
              setLinksArray([{ url: uploadedFiles, title: "Image" }]);
            } else {
              const links = uploadedFiles.map((link: any, idx: any) => {
                return { url: link, title: `Image ${idx}` };
              });
              if (!links) {
                //@ts-ignore
                setLinksArray(undefined);
              }
              setLinksArray(links);
            }
            setShowImage(true);
          },
        });

        extraProps.cellType = col.colDef.dataType;

        extraProps.enableColumnFilter = false;
        extraProps.enableSorting = false;
      }
      if (col.colDef.dataType === ActionTypeEntity.BUTTON) {
        extraProps.Cell = (params: any) => {
          // put the value in bold
          return (
            <>
              {" "}
              {
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    padding: "4px 12px",
                  }}
                >
                  <ZButton
                    fullWidth
                    disabled={buttonLoading}
                    secondary={
                      //@ts-ignore
                      col.colDef?.button?.value.includes("secondary")
                        ? true
                        : false
                    }
                    //@ts-ignore
                    primary={
                      //@ts-ignore
                      col.colDef?.button?.value.includes("primary")
                        ? true
                        : false
                    }
                    style={{ padding: "0px 6px", fontSize: "12px" }}
                    variant={
                      col.colDef?.properties?.type.includes("Outlined")
                        ? "contained"
                        : "contained"
                    }
                    // primary={col.colDef?.properties?.properties?.type.includes("Primary")}
                    onClick={(e) => {
                      if (
                        params.column.columnDef.data?.action?.value ===
                        //@ts-ignore
                        IButtonActionType.OPEN_URL
                      ) {
                        if (params?.cell?.renderValue()) {
                          window.open(params?.cell?.renderValue(), "_blank");
                        } else {
                          e.stopPropagation();
                          notification(
                            "error",
                            "URL is not defined for this button"
                          );
                        }
                      } else {
                        e.stopPropagation();
                        executeButtonClick({
                          recordId: params.row.original.recordId,
                          //@ts-ignore
                          tableType: selectedEntity.tableType,
                          buttonId: col.colDef?.name,
                        });
                      }
                    }}
                  >
                    {" "}
                    {buttonLoading
                      ? "Loading"
                      : col.colDef?.buttonLabel || "Click Me"}{" "}
                  </ZButton>
                  {/* <ZIconButton2
                          onClick={() => {
                            dispatch(setSelectedActionColumn(col.colDef));
                            setOpenAB(true);
                          }}
                        >
                          <AiOutlineSetting />
                        </ZIconButton2> */}
                </div>
              }{" "}
            </>
          );
        };

        extraProps.disableColumnMenu = true;
        extraProps.enableColumnActions = false;
        extraProps.onClick = () => {
          return {};
        };
        extraProps.Header = (params: any) => {
          return (
            <div style={{ width: "100%" }}>

              <ButtonAutomationHeader
                allowColumnEdit={false}
                col={col}
                params={params}
              />
            </div>
          );
        };
        extraProps.enableColumnFilter = false;
        extraProps.enableSorting = false;
      }

      if (col.colDef.dataType === DataFieldType.LOCATION) {
        extraProps.Cell = (params: any) => {
          // put the value in bold
          const val =
            params?.cell?.renderValue?.()?.address?.length > 25
              ? `${params?.cell
                  ?.renderValue?.()
                  ?.address?.substring?.(0, 25)} ...`
              : params?.cell?.renderValue?.()?.address;

          return params?.cell?.renderValue?.()?.address?.length > 25 ? (
            <InfoTooltip
              placement="top"
              title={params?.cell?.renderValue?.()?.address}
            >
              <div>
                <p
                  style={{
                    padding: "4px 12px",
                    color: "#3067c4",
                  }}
                >
                  {params?.cell?.renderValue() &&
                  (params?.cell?.renderValue()?.address ||
                    (params?.cell?.renderValue()?.latitude &&
                      params?.cell?.renderValue()?.longitude))
                    ? val ||
                      `${params?.cell?.renderValue()?.latitude || ""}, ${
                        params?.cell?.renderValue()?.longitude || ""
                      }`
                    : ""}
                </p>
              </div>
            </InfoTooltip>
          ) : (
            <div>
              <p
                style={{
                  padding: "4px 12px",
                  color: "#3067c4",
                }}
              >
                {params?.cell?.renderValue() &&
                (params?.cell?.renderValue()?.address ||
                  (params?.cell?.renderValue()?.latitude &&
                    params?.cell?.renderValue()?.longitude))
                  ? params?.cell?.renderValue?.()?.address ||
                    `${params?.cell?.renderValue()?.latitude || ""}, ${
                      params?.cell?.renderValue()?.longitude || ""
                    }`
                  : ""}
              </p>
            </div>
          );
        };
        extraProps.enableColumnFilter = true;
        extraProps.enableSorting = false;
        extraProps.disableColumnMenu = true;
        extraProps.size = 250;
        extraProps.columnFilterModeOptions = [
          "contains",
          "empty",
          "endsWith",
          "equals",
          "startsWith",
          "empty",
          "notEmpty",
          "notEquals",
        ];
      }

      if (col.colDef.dataType === DataFieldType.LIST_OF_TEXT) {
        extraProps.Cell = (params: any) => {
          return (
            <div style={{ display: "flex", gap: "8px" }}>
              {isArray(params?.cell?.renderValue?.()) && (
                <>
                  {params?.cell
                    ?.renderValue?.()
                    ?.slice?.(0, 3)
                    ?.map?.((value: string) => {
                      if (typeof value !== "object" && !Array.isArray(value)) {
                        return (
                          <ListOfValueBadges
                            value={value}
                            backgroundColor={
                              complimentColor[
                                col.colDef?.properties?.color?.[value.trim()]
                              ] || "#F2F4F7"
                            }
                            color={
                              col.colDef?.properties?.color?.[value.trim()] ||
                              "#344054"
                            }
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  {params?.cell?.renderValue()?.length > 3 ? (
                    <Tooltip title={params?.cell?.renderValue()?.join(", ")}>
                      <div>
                        <ListOfValueBadges
                          backgroundColor="#F2F4F7"
                          color="#344054"
                          value={`+${params?.cell?.renderValue()?.length - 3}`}
                        />
                      </div>
                    </Tooltip>
                  ) : null}
                </>
              )}
            </div>
          );
        };
        extraProps.filterSelectOptions = col.colDef?.properties?.options?.map(
          (val: any) => {
            return {
              text: val,
              value: val,
            };
          }
        );
        extraProps.filterVariant = "select";
        extraProps.enableSorting = false;
        extraProps.columnFilterModeOptions = ["contains", "empty", "notEmpty"];
        extraProps.size = 220;
      }
      if (col.colDef.dataType === DataFieldType.RELATION) {
        let len = 0;
        extraProps.Cell = (params: any) => {
          const val = params.cell.renderValue?.();
          // return <p>kaush</p>
          if (!isArray(val)) {
            return <></>;
          }
          const displayColumn = col.colDef?.displayColumn;
          //@ts-ignore
          const referringEntity = col.colDef?.relationDetails?.referringEntity;
          const defaultDisplayColumn = ["recordId", "taskId"];
          len = val?.length;
          const values = val?.map((v: any) =>
          //@ts-ignore
            defaultDisplayColumn.includes(displayColumn)
            //@ts-ignore
              ? v?.[displayColumn]
              : referringEntity === IRelationReferringEntityType.USER
              //@ts-ignore
              ? v?.[displayColumn]
              //@ts-ignore
              : v?.data?.[displayColumn]
          );
          const valuesToShow =
            referringEntity === IRelationReferringEntityType.USER ? 6 : 3;
          return (
            <div style={{ display: "flex", gap: "8px" }}>
              {
                // get only first valuesToShow values
                values?.slice(0, valuesToShow)?.map((value: string) => {
                  return referringEntity ===
                    IRelationReferringEntityType.USER ? (
                    value && (
                      <Tooltip title={value}>
                        <Avatar {...stringAvatar(value)} />
                      </Tooltip>
                    )
                  ) : (
                    <RelationCellBadge
                      value={value}
                      backgroundColor={"#F2F4F7"}
                      color={"#344054"}
                    />
                  );
                })
              }
              {values?.length > valuesToShow ? (
                referringEntity === IRelationReferringEntityType.USER ? (
                  <Tooltip title={values?.join(", ")}>
                    <div>
                      <Avatar
                        {...stringAvatar(`+ ${values?.length - valuesToShow}`)}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title={values?.join(", ")}>
                    <div>
                      <RelationCellBadge
                        backgroundColor={"#F2F4F7"}
                        color={"#344054"}
                        value={`+${values?.length - valuesToShow}`}
                      />
                    </div>
                  </Tooltip>
                )
              ) : null}
            </div>
          );
        };
        // extraProps.Cell = (params: any) => {
        // return <p>Rrlation</p>
        // }

        extraProps.enableColumnFilter = false;
        extraProps.enableSorting = false;
        extraProps.disableColumnMenu = true;
        extraProps.size = 350;
      }

      if (col.colDef.dataType === EntityTypeField.COMPUTED) {
        extraProps.enableColumnFilter = false;
        extraProps.enableSorting = false;
        extraProps.disableColumnMenu = true;
      }
      return {
        //@ts-ignore
        accessorKey: col.id || col.type,
        header: col.label,
        filterFn: "contains",
        data: {
          ...col.colDef,
          type: col.colDef.dataType,
        },
        Header: (params: any) => {
          return (
            <div style={{ width: "100%" }}>
              <ButtonAutomationHeader
                allowColumnEdit={false}
                col={col}
                params={params}
              />
            </div>
          );
        },
        Cell: (params: any) => {
          return (
            <Tooltip
              title={
                params.column.getIsFiltered()
                  ? "Filter Applied"
                  : params.column.getIsSorted()
                  ? "Column is Sorted"
                  : ""
              }
            >
              <div>
                <p
                  style={{
                    padding: "4px 12px",
                    background: params.column.getIsFiltered()
                      ? "#fef3ee"
                      : params.column.getIsSorted()
                      ? "#ecfbe7"
                      : "",
                  }}
                >
                  {params?.cell?.renderValue() === undefined
                    ? ""
                    : params?.cell?.renderValue()}
                </p>
              </div>
            </Tooltip>
          );
        },
        ...extraProps,
      };
    });

    // add {field: id, headerName: id} to colDefs at index 1

    colDefs?.splice(0, 0, {
      accessorKey: "recordId",
      header: "Record Id",
      enableClickToCopy: true,
      // enableColumnFilter: true,
      enableSorting: true,
      pinned: "left",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellStyle: { fontWeight: "500" },
      props: {
        isSystemField: true,
      },
      size: 120,
      columnFilterModeOptions: [
        "contains",
        "empty",
        "endsWith",
        "equals",
        "startsWith",
        "empty",
        "notEmpty",
        "notEquals",
      ],
      Cell: (params: any) => {
        // put the value in bold
        return (
          <Tooltip
            title={
              params.column.getIsFiltered()
                ? "Filter Applied"
                : params.column.getIsSorted()
                ? "Column is Sorted"
                : ""
            }
          >
            <div>
              <p
                style={{
                  padding: "4px 12px",
                  background: params.column.getIsFiltered()
                    ? "#fef3ee"
                    : params.column.getIsSorted()
                    ? "#ecfbe7"
                    : "",
                }}
              >
                {params.cell.getValue()}
              </p>
            </div>
          </Tooltip>
        );
      },
      Header: (params: any) => {
        return (
          <div style={{ width: "100%" }}>
            <SystemFieldHeader
              dataType={DataFieldType.ID}
              name="ID"
              params={params}
            />
          </div>
        );
      },
    });

    // add {field: createdOn, headerName: Created On} to colDefs at index 1

    colDefs?.splice(1, 0, {
      accessorKey: "createdOn",
      header: "Created On",
      accessorFn: (row:any) =>
        moment(row.createdOn).tz(account?.accountConfig?.timezone),
      Header: (params: any) => {
        return (
          <div style={{ width: "100%" }}>
            <SystemFieldHeader
              dataType={DataFieldType.DATE}
              name="Created On"
              params={params}
            />
          </div>
        );
      },
      size: 210,
      enableColumnFilter: true,
      enableSorting: true,
      pinned: "left",
      columnFilterModeOptions: [
        "greaterThan",
        "lessThan",
        "equals",
        "empty",
        "notEmpty",
      ], //only allow these filter modes
      // columnFilterModeOptions: ["greaterThan", "lessThan","between", "greaterThanOrEqualTo", "lessThanOrEqualTo"],
      filterFn: "equals",
      sortingFn: "datetime",
      lockPinned: true,
      cellStyle: { fontWeight: "500" },
      props: {
        isSystemField: true,
      },
      Cell: (params: any) => {
        // put the value in bold
        return (
          <Tooltip
            title={
              params.column.getIsFiltered()
                ? "Filter Applied"
                : params.column.getIsSorted()
                ? "Column is Sorted"
                : ""
            }
          >
            <div>
              <p
                style={{
                  padding: "4px 12px",
                  background: params.column.getIsFiltered()
                    ? "#fef3ee"
                    : params.column.getIsSorted()
                    ? "#ecfbe7"
                    : "",
                }}
              >
                {" "}
                {moment(params.cell.getValue()).format(
                  "Do MMM YYYY , h:mm A"
                )}{" "}
              </p>
            </div>
          </Tooltip>
        );
      },
      Filter: ({ column }:any) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            onChange={(newValue:any) => {
              column.setFilterValue(newValue);
            }}
            slotProps={{
              textField: {
                helperText: "Filter Mode: Less Than",
                sx: { minWidth: "120px" },
                variant: "standard",
              },
              field: {
                clearable: true,
                onClear: () => column.setFilterValue(null),
              },
            }}
            value={
              column.getFilterValue() ? new Date(column.getFilterValue()) : null
            }
          />
        </LocalizationProvider>
      ),
    });

    // // add {field: updatedOn, headerName: Updated On} to colDefs at index 2

    colDefs?.splice(2, 0, {
      accessorKey: "updatedOn",
      accessorFn: (row:any) =>
        moment(row.updatedOn).tz(account?.accountConfig?.timezone),
      header: "Updated On",
      enableColumnFilter: true,
      enableSorting: true,
      size: 210,
      columnFilterModeOptions: [
        "greaterThan",
        "lessThan",
        "equals",
        "empty",
        "notEmpty",
      ], //only allow these filter modes
      sortingFn: "datetime",
      filterFn: "equals",
      pinned: "left",
      lockPinned: true,
      cellStyle: { fontWeight: "500" },
      props: {
        isSystemField: true,
      },
      Cell: (params: any) => {
        // put the value in bold
        return (
          <Tooltip
            title={
              params.column.getIsFiltered()
                ? "Filter Applied"
                : params.column.getIsSorted()
                ? "Column is Sorted"
                : ""
            }
          >
            <div>
              <p
                style={{
                  padding: "4px 12px",
                  background: params.column.getIsFiltered()
                    ? "#fef3ee"
                    : params.column.getIsSorted()
                    ? "#ecfbe7"
                    : "",
                }}
              >
                {" "}
                {moment(params.cell.getValue()).format(
                  "Do MMM YYYY , h:mm A"
                )}{" "}
              </p>
            </div>
          </Tooltip>
        );
      },
      Filter: ({ column }:any) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            onChange={(newValue:any) => {
              column.setFilterValue(newValue);
            }}
            slotProps={{
              textField: {
                helperText: "Filter Mode: Less Than",
                sx: { minWidth: "120px" },
                variant: "standard",
              },
              field: {
                clearable: true,
                onClear: () => column.setFilterValue(null),
              },
            }}
            value={
              column.getFilterValue() ? new Date(column.getFilterValue()) : null
            }
          />
        </LocalizationProvider>
      ),
      Header: (params: any) => {
        return (
          <div style={{ width: "100%" }}>
            <SystemFieldHeader
              dataType={DataFieldType.DATE}
              name="Updated On"
              params={params}
            />
          </div>
        );
      },
    });

    colDefs?.push({
      accessorKey: "addRow",
      size: 50,
      pinned: "right",
      lockPinned: true,
      enableSorting: false,
      enableColumnFilter: false,
      enableColumnActions: false,
      // enableColumnOrdering: false,
      enableResizing: false,
      disableColumnMenu: true,
      onClick: () => {
        return {};
      },
      Header: () => {
        return (
          <>
            <Tooltip title={"Add New Column"}>
              <div
                style={{ width: "100%" }}
                onClick={() => dispatch(setShowCreationModal(true))}
                id={OnboardingIdsForComponent.CREATE_NEW_COLUMN_BUTTON}
              >
                <AiOutlinePlus size="1.5rem" strokeWidth={"2px"} />
              </div>
            </Tooltip>
          </>
        );
      },
    });

    const rowDefs = data?.rowDef?.map((row: IRowDefs) => {
      return {
        ...row.data,
        recordId: row?.recordId,
        createdOn: row?.createdOn,
        updatedOn: row?.updatedOn,
      };
    });

    return {
      colDefs,
      rowDefs,
    };
  };

  const execFetcherFunction = async () => {
    if (!fetcherFunction) return;
    const data = await fetcherFunction({
      tableType,
      page: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sort: sorting,
      filter: columnFilters,
      search: globalFilter || "",
    });
    setRowCount(data?.count || 0);
    const { colDefs: initColDefs, rowDefs: initRowDefs } = formatListingConfig(
      data.body
    );
    setColumnDefs(initColDefs);
    setRowData(initRowDefs);
    if (initRowDefs && initRowDefs.length === 0) {
      setIsTableEmpty?.(true);
    }
  };

  const handleRowSelectionChange = (updaterFn:any) => {
    setSelectedRows((currentSelectedRows) => {
      // Update the state with the new selection
      if (isFunction(updaterFn)) return updaterFn(currentSelectedRows);
    });
  };

  const [selectedRows, setSelectedRows] = useState({});

  useEffect(() => {
    // if(isFirstRender.current) return;
    console.log("Updated selected rows: ", selectedRows);
    // get all the keys of selected rows
    const selectedRowKeys = Object.keys(selectedRows);
    // loop through the keys:
    const deletedRecords = selectedRowKeys.map((key: any) => {
      // if the value is true, then the row is selected
      //@ts-ignore
      if (selectedRows[key]) {
        //get this index from initConfig.rowData
        const val = initTableConfig?.rowDef?.[key];
        return val?.taskId;
      }
    });
    // remove undefined values
    const filteredDeletedRecords = deletedRecords.filter(
      (val) => val !== undefined
    );

    // merge the deleted records with rowsToDelete
    const newRowsToDelete = union(rowsToDelete, filteredDeletedRecords);
    

    if (nameOfTheTableInRelatedTable) {
      // remove value that are in filteredDeletedRecords from t
      // set the state
      dispatch(
        setRowsToDelete({
          nodeId: nameOfTheTableInRelatedTable,
          data: [...newRowsToDelete],
        })
      );
    }

    //
    // Additional logic to handle the updated state
  }, [selectedRows]);

  useEffect(() => {
    if (fetcherFunction) {
      execFetcherFunction();
    } else {
      const { colDefs: initColDefs, rowDefs: initRowDefs } =
        formatListingConfig(initTableConfig);
      setColumnDefs(initColDefs);
      setRowData(initRowDefs);
      if (initRowDefs && initRowDefs.length === 0) {
        setIsTableEmpty?.(true);
      }
    }
  }, [
    initTableConfig,
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  return (
    <div
      className={`zorpTable zorpTableComponent ${
        hideToolbar ? "hideToolbar" : ""
      }`}
    >
      <MaterialReactTable
        data={rowData}
        enableColumnResizing
        defaultColumn={{
          minSize: 20, //allow columns to get smaller than default
          maxSize: 9001, //allow columns to get larger than default
          size: 260, //make columns wider by default
        }}
        muiTableBodyCellProps={(rowInfo) => ({
          onDoubleClick: onRowDoubleClick
            ? () => onRowDoubleClick(rowInfo)
            : undefined,
        })}
        enableMultiRowSelection
        muiSelectCheckboxProps={({ row }) => ({
          color: "primary",
          // show "Delete" icon only if fromShowPage is true else show the default checkbox
          icon: fromShowPage ? <RiDeleteBinLine /> : <Checkbox />,
          onClick: () =>
          handleRowSelectionChange((prev:any) => ({
            ...prev,
            [row.id]: !prev[row.id],
          })),
        })}
        columns={columnDefs}
        enablePinning={enablePinning}
        enableStickyHeader
        muiTablePaperProps={{
          style: {
            height: "100%",
            position: "relative",
            zIndex: 0,
            boxShadow: "none",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            "& .Mui-TableHeadCell-Content": {
              justifyContent: "space-between",
            },
            "& .MuiButtonBase-root": {
              justifyContent: "start",
            },
          },
        }}
        // muiTableBodyRowProps={({ row }) => (
        //   {

        //   sx: {
        //     backgroundColor: addedRecords?.includes(row?.taskId)
        //       ? "#ECF3FF"
        //       : "white",
        //     // Apply any other row styles here
        //   },
        // })}
        muiTableBodyRowProps={({ row }) => {
          return {
            sx: {
              backgroundColor: rowsToDelete?.includes(
                //@ts-ignore
                row?.original.taskId
              )
                ? "#FFF4ED !important"
                //@ts-ignore
                : addedRecords?.includes(row?.original?.taskId)
                ? "#ECF3FF !important"
                : "white",
              // Apply any other row styles here
            },
          };
        }}
        muiTopToolbarProps={{
          sx: {
            padding: "8px",
          },
          className: hideToolbar ? "zorpTableTopToolbar" : "",
        }}
        renderToolbarInternalActions={({ table }) => {
          return (
            <>
              {/* <GlobalSearch table={table} />
              <FilterAction table={table} />
              <ShowHideColumnAction table={table} /> */}
              {/* <GlobalSearch table={table} /> */}
              {toolbarComponent &&
                //@ts-ignore
                createElement(toolbarComponent, { table })}
            </>
          );
        }}
        onRowSelectionChange={handleRowSelectionChange}
        enableRowSelection={enableRowSelection}
        enableGlobalFilter={enableGlobalFilter}
        positionGlobalFilter="left"
        enableColumnFilterModes={enableColumnFilterModes}
        muiSearchTextFieldProps={{
          placeholder: `Search ${rowCount} records`,
          inputProps: {
            sx: {
              fontSize: "14px",
            },
          },

          sx: {
            border: "1px solid #EAECF0",
            padding: "4px",
            borderRadius: "4px",
            width: "210px",
            marginRight: "10px",
          },
        }}
        globalFilterFn="contains"
        icons={CustomIcons}
        muiPaginationProps={{
          rowsPerPageOptions: [20, 30, 50, 100],
          showFirstButton: false,
          showLastButton: false,
          className: "ZorpTablePagination-root",
        }}
        state={{
          columnFilters,
          columnVisibility,
          density,
          globalFilter,
          showColumnFilters,
          showGlobalFilter,
          sorting,
          pagination,
          columnFilterFns,
          // columnOrder: columnOrdering,
          columnPinning,
          rowSelection: selectedRows,
        }}
        // rowCount={rowCount}
        onPaginationChange={setPagination}
        onColumnFiltersChange={setColumnFilters}
        onColumnVisibilityChange={setColumnVisibility}
        onDensityChange={setDensity}
        onGlobalFilterChange={setGlobalFilter}
        onColumnOrderChange={setColumnOrdering}
        onShowColumnFiltersChange={setShowColumnFilters}
        onShowGlobalFilterChange={setShowGlobalFilter}
        onSortingChange={setSorting}
        onColumnFilterFnsChange={setColumnFilterFns}
        onColumnPinningChange={setColumnPinning}
        manualPagination={initTableConfig === undefined}
        manualSorting={initTableConfig === undefined}
        manualFiltering={initTableConfig === undefined}
      />
      {linksArray?.length > 0 && (
        <ImageModal
          BackdropComponent={Backdrop}
          BackdropProps={{
            color: "rgba(0,0,0,0.8)",
          }}
          open={showImage}
          onClose={() => setShowImage(false)}
        >
          <ImageSlider
            //@ts-ignore
            onClose={() => setShowImage(false)}
            startIndex={0}
            slides={linksArray}
          />
        </ImageModal>
      )}
    </div>
  );
};

export default ZTable;