import { IconButton, TextField, Tooltip } from "@mui/material";
import { PageCategoryEnum, recordPageVisit } from "config/Rudderstack";
import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate } from "react-router-dom";
import { RedText, TaskFormBody } from "render-engine/render.styles";
import TopBar from "render-engine/TopBar";
import { recordRSEvent } from "utils/CommonUtils";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import InfoIcon from "@mui/icons-material/Info";
import notification from "../../notifications/notifications";
import authProvider from "../../providers/auth/authProvider";
import { UserService } from "../../providers/data/services/UserService";
import { PhoneValidator, TeamValidator } from "../tasks/components/Validator";
import UserRole from "./components/UserRole";
import {useParams} from "react-router-dom"
import styled from 'styled-components';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2rem; /* gap-x-8 in Tailwind CSS */

  @media (min-width: 640px) {
    grid-template-columns: repeat(8, 1fr); /* sm:grid-cols-8 in Tailwind CSS */
  }
`;

const ColSpan4 = styled.div`
  grid-column: span 4 / span 4;
`;


const StyledDiv = styled.div`
  grid-column: span 1 / span 1;
  font-weight: 500; /* font-medium in Tailwind CSS */
  padding: 20px 0px 4px 0px;
`;

const UserEditForm = () => {
  const [selectedRole, setSelectedRole] = React.useState("");
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneCode, setPhoneCode] = React.useState("");
  const [teamId, setTeamId] = React.useState("");
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [externalId, setExternalId] = React.useState("");
  const {id} = useParams()

  useEffect(() => {
    ValidatorForm.addValidationRule("teamIdCheck", (value) => {
      if (teamId && teamId.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  });

  useEffect(() => {
    ValidatorForm.addValidationRule("phoneNumberCheck", (value) => {
      if (phoneCode && phoneCode.length > 0 && phone && phone.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  });

  useEffect(() => {
    ValidatorForm.addValidationRule("checkEmail", (value) => {
      var mailformat =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if ((email.length > 0 && email.match(mailformat)) || email.length === 0) {
        return true;
      } else {
        return false;
      }
    });
  });

  const handleTeamIdChange = (id) => {
    setTeamId(id);
  };

  const handleRoleChange = (val) => {
    if (val === "") setSelectedRole("");
    else setSelectedRole(val);
  };

  const editUser = () => {
    recordRSEvent("edituser_button_click", {
      context: "user_edit",
      entity_id: id,
      // entity_status: props.status,
      // team_id: props.teamIds,
      // team_name: props.teams,
      // role_id: [teamId],
      // role_name: props.role,
    });
    const taskPayLoad = {
      userId: id,
      name: name,
      phone: {
        num: phone,
        countryCode: phoneCode,
      },
      role: selectedRole,
      teamIds: [teamId],
      email: email,
      externalId: externalId
    };

    UserService.userUpdate(taskPayLoad)
      .then((res) => {
        if (res?.code && res?.code.indexOf("200") < 0) {
          // Error
          if (res?.code === "401") {
            notification("error", res.message);
            authProvider.logout();
          } else {
            notification("error", res.message);
            recordRSEvent("edituser_event_fail", {
              context: "user_edit",
              entity_id: id,
              // entity_status: props.status,
              // team_id: props.teamIds,
              // team_name: props.teams,
              // role_id: [teamId],
              // role_name: props.role,
              // external_id: props.externalId
            });
          }
        } else {
          notification("success", res.message);
          recordRSEvent("edituser_event_success", {
            context: "user_edit",
            entity_id: id,
            // entity_status: props.status,
            // team_id: props.teamIds,
            // team_name: props.teams,
            // role_id: [teamId],
            // role_name: props.role,
          });
          navigate(`/users/${id}/show`);
        }
      })
      .catch((err) => {
        console.log("Error", err);
        notification("error", "User cannot be edited!");
        recordRSEvent("edituser_event_fail", {
          context: "user_edit",
          entity_id: id,
          // entity_status: props.status,
          // team_id: props.teamIds,
          // team_name: props.teams,
          // role_id: [teamId],
          // role_name: props.role,
        });
        navigate("/users");
      });
  };

  const handleNameChange = (val) => {
    setName(val);
  };

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhone(value.slice(data.dialCode.length));
    setPhoneCode(data.dialCode);
  };

  const handleEmailChange = (val) => {
    if (val == null || val.length == 0) setEmail("");
    else setEmail(val);
  };

  const cancelEdit = () => {
    notification("info", "User edit cancelled!");
    recordRSEvent("edituser_cancel_button_click", {
      context: "user_edit",
      entity_id: id,
      // entity_status: props.status,
      // team_id: props.teamIds,
      // team_name: props.teams,
      // role_id: [teamId],
      // role_name: props.role,
    });
    navigate(`/users/${id}/show`);
  };

/**
   * Records a page visit with the given page category, action, and data.
   * @example
   * recordPageVisit(PageCategoryEnum.userEdit, "page_load", {
   *   entity_id: "12345",
   *   entity_status: "active",
   *   team_id: ["team1", "team2"],
   *   team_name: ["Team 1", "Team 2"],
   *   role_id: "role1",
   *   role_name: "Role 1",
   * });
   * @param {PageCategoryEnum} pageCategory - The category of the page being visited.
   * @param {string} action - The action being performed on the page.
   * @param {Object} data - Additional data to be recorded with the page visit.
   * @returns {void} Does not return anything.
   * @description
   *   - Uses the PageCategoryEnum to specify the category of the page.
   *   - The action parameter should be a string.
   *   - The data parameter should be an object with specific key-value pairs.
   *   - Uses the UserService to fetch user data and update state.
   */
  useEffect(() => {
    recordPageVisit(PageCategoryEnum.userEdit, "page_load", {
      entity_id: id,
      // entity_status: props.status,
      // team_id: props.teamIds,
      // team_name: props.teams,
      // role_id: props.roleId,
      // role_name: props.role,
    });
    const data = UserService.getUser(id);
    data
      .then((res) => {
        const user = res?.data;
        setSelectedRole(user.role);
        setName(user?.name);
        setPhone(user?.phone?.num);
        if (user?.phone?.countryCode?.length > 0)
          setPhoneCode(user?.phone?.countryCode);
        else setPhoneCode("");
        setTeamId(user?.teamIds[0]);
        setEmail(user?.email == null ? "" : user?.email);
        setExternalId(user?.externalId);
      })
      .catch((err) => {
        notification("error", "Error while fetching user details");
        console.log(err);
      });
  }, [id]);

  return (
    <ValidatorForm
      onSubmit={editUser}
      onError={(errors) => console.log(errors)}
    >
      <TaskFormBody>
        <TopBar
          heading="Edit User"
          onCancelText={"Cancel"}
          onSaveText="Edit"
          onSave={() => {}}
          onCancel={cancelEdit}
        />
        <div className="contentWrapper">
          <GridContainer>
            <ColSpan4>
              <StyledDiv>
                Name <RedText>*</RedText>
              </StyledDiv>
              <TextValidator
                autoComplete={false}
                value={name}
                variant="outlined"
                size="large"
                className="inc-t0 oid-wd"
                style={{
                  width: "100%",
                
                }}
                onChange={(event) => handleNameChange(event.target.value)}
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </ColSpan4>

            <ColSpan4>
              <div
                className="col-span-1 font-medium"
                style={{ padding: "20px 0px 4px 0px" }}
              >
                Role <RedText>*</RedText>
              </div>

              <UserRole
                onChange={handleRoleChange}
                disabled={false}
                role={selectedRole}
              />
            </ColSpan4>
          </GridContainer>

          <GridContainer>
            <ColSpan4>
              <StyledDiv>
                Phone Number
              </StyledDiv>
              <PhoneValidator
                enableSearch={true}
                country={"us"}
                value={phoneCode + phone}
                onChange={handlePhoneChange}
                className="contactNumClass"
                // validators={['phoneNumberCheck']}
                validators={[]}
                errorMessages={["This field is required"]}
              />
            </ColSpan4>

            <ColSpan4>
              <StyledDiv>
                Team <RedText>*</RedText>
              </StyledDiv>
              <TeamValidator
                id="teamField"
                onChange={handleTeamIdChange}
                disabled={false}
                team={teamId}
                style={{ minWidth: "500px", maxWidth: "500px" }}
                validators={["teamIdCheck"]}
                errorMessages={["This field is required"]}
              />
            </ColSpan4>
          </GridContainer>

          <GridContainer>
            <ColSpan4>
              <StyledDiv>
                Email
              </StyledDiv>
              <TextValidator
                onChange={(event) => handleEmailChange(event.target.value)}
                variant="outlined"
                size="medium"
                validators={["checkEmail"]}
                className="inc-t0 oid-wd"
                style={{
                  width: "100%",
                
                }}
                errorMessages={["Enter a valid email address"]}
                value={email}
              />
            </ColSpan4>
            <ColSpan4>
              <StyledDiv>
                External Id
                <Tooltip title="This is the user id used within your ogranization">
                    <IconButton size="medium">
                      <InfoIcon fontSize="medium"/>
                    </IconButton>
                  </Tooltip>
              </StyledDiv>
              <TextValidator
                autoComplete={false}
                value={externalId}
                variant="outlined"
                size="large"
                className="inc-t0 oid-wd"
                onChange={(event) => setExternalId(event.target.value)}
                validators={[]}
                style={{
                  width: "100%",
                
                }}
              />
            </ColSpan4>
            <div></div>
          </GridContainer>
        </div>
      </TaskFormBody>
    </ValidatorForm>
  );
};

export default UserEditForm;
