import React, { useState } from 'react';
import jwtDecode from 'jwt-decode';
import styled from 'styled-components';
import { ResourceTypeList, ResourceTypeConstant, Permission } from "./types";
import useEntity from "views/entities/hooks/useEntity"
import { setEntityPermissions, setFinalEntityPermissions } from '../reducers/entityReducers';
import { EntityService } from 'providers/data/services/EntityService';
import Select from 'react-select';
import { getPermissionLevels } from 'utils/CommonUtils';
import { useDispatch } from 'react-redux';
import notification from 'notifications/notifications';
import SearchInputContainer from './components/SearchInputContainer';
import { getEntityMetadata } from '../reducers/entityReducers';
import { OptionType } from './types';
import { recordRSEvent } from 'utils/CommonUtils';
import { TablePermissionRsEvents } from '../types';

const customStyles = {
    control: (provided: any) => ({
        ...provided,
        border: 0,
        padding: 0,
        boxShadow: 'none',
        width: 'max-content',
        minWidth: '100%',
    }),
    menu: (provided: any) => ({
        ...provided,
        width: '100%',
        minWidth: 'max-content',
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: 'rgb(48, 84, 185)',
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        color: 'rgb(48, 84, 185)',
        padding: '0',
        transform: 'scale(0.8)',
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: '0',
        fontSize: '12px',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: 'rgb(48, 84, 185)',
    })
};

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContainer = styled.div`
    background-color: white;
    padding: 24px;
    border-radius: 4px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    z-index: 1001;
`;

const Button = styled.button <{ background?: string; color?: string; borderColor?: string; }>`
  display: flex;
  align-items: center;
  background-color: ${({ background }) => (background ? background : '#3c69e7')};
  color: ${({ color }) => (color ? color : '#fff')};
  border: 1px solid ${({ borderColor }) => (borderColor ? borderColor : '#3c69e7')};
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 14px;
`;

const SaveButton = styled.button<{ $active?: boolean; $confirm?: string; }>`
  display: flex;
  align-items: center;
  background-color: ${({ $active, $confirm }) => ($active ? $confirm ? $confirm : '#3c69e7' : 'grey')};
  border: 1px solid ${({ $active, $confirm }) => ($active ? $confirm ? $confirm : '#3c69e7' : 'grey')};
  color: ${({ $active }) => ($active ? '#fff' : '#fff')};
  border-radius: 4px;
  cursor: ${({ $active }) => ($active ? 'pointer' : 'not-allowed')};
  padding: 8px 14px;
  &:disabled {
    opacity: 0.5;
  }
`

const Heading = styled.h1`
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
`;

const SubHeading = styled.h2`
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 20px;
`;

const PermissionLabel = styled.div`
    font-size: 12px;
    color: #344054;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
`;

const ShareSectionContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    place-items: flex-start;
    margin-bottom: 10px;
    gap: 16px;
    width: 100%;
`;

const ActionButtons = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const ActionButtonWrapper = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
`

const ShareContainer = styled.div`
    width: 100%;
`

const ShareResourceContainer = styled.div`
    display: block;
    height: 14vh;
`

const ManageAccessButton = styled.button<{ $active?: boolean }>`
  color: ${props => props.$active ? "#475467" : "#3054B9"};
  cursor: ${props => props.$active ? "not-allowed" : "pointer"};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  &:disabled {
    opacity: 0.5;
  }
`;

const Error = styled.span`
    color: #D92D20;
    font-size: 11px;
`

export interface ShareResourceModalState {
    dropdownStates: { [key: number]: boolean };
}

type props = {
    resourceType: ResourceTypeList,
    onClose: () => void,
    context: "create" | "update" | "updateNew",
    initialSelectedOptions?: OptionType[],
    initialPermisionLevel?: any,
    setShowPermissionsModal?: React.Dispatch<React.SetStateAction<boolean>>
}

const ShareResourceModal = ({ resourceType, onClose, context, initialSelectedOptions, initialPermisionLevel, setShowPermissionsModal }: props) => {

    const { entityPermissions, selectedEntity, finalEntityPermissions, permissionConfiguration } = useEntity();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [confirmEdit, setConfirmEdit] = useState<boolean>(false);
    const [selectedSelectOptions, setSelectedSelectOptions] = useState<OptionType[]>(initialSelectedOptions ? initialSelectedOptions : []);
    const [selectedPermissionLevel, setSelectedPermissionLevel] = useState(initialPermisionLevel ? initialPermisionLevel : null);

    const handlePermissionLevelChange = (selectedOption: any, actionMeta: any) => {
        setSelectedPermissionLevel(selectedOption);
        recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_PERMISSION_LEVEL_CHANGE, {
            resourceType: resourceType,
            permissionLevel: selectedOption?.value
        })
    };

    const onSaveHandler = () => {
        recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_SAVE_BUTTON_CLICKED, {
            resourceType: resourceType,
            selectedOptions: selectedSelectOptions
        })
        if (!selectedPermissionLevel) {
            notification("error", "Please select a permission level to proceed.");
            return;
        }
        const at = localStorage.getItem('at');
        const decoded: any = !!at?.length ? jwtDecode(at) : null;
        if (initialSelectedOptions && initialSelectedOptions?.length > 0) {
            recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_EDIT_MODE_SAVE_BUTTON_CLICKED, {
                resourceType: resourceType,
                context: context
            })
            if (initialSelectedOptions.find(option => option.value === decoded?.role) &&
                !selectedSelectOptions.find(option => option.value === decoded?.role)) {
                setConfirmEdit(true);
            }
            else if (initialSelectedOptions.find(option => option.value === decoded?.userId) &&
                !selectedSelectOptions.find(option => option.value === decoded?.userId)) {
                setConfirmEdit(true);
            } else if (initialSelectedOptions.some(option => decoded?.teamIds.includes(option.value)) &&
                !selectedSelectOptions.some(option => decoded?.teamIds.includes(option.value))) {
                setConfirmEdit(true);
            } else {
                handleProceedSave();
            }

        } else {
            handleProceedSave();
        }
    }

    const handleProceedSave = async () => {
        const at = localStorage.getItem('at');
        const decoded: any = !!at?.length ? jwtDecode(at) : null;

        let newPermissions: Permission[];

        if (resourceType === ResourceTypeConstant.TABLE) {
            if (context === 'create' || context === 'update') {
                newPermissions = entityPermissions.map((permission) => {
                    if (permission.permissionSetName.toLowerCase() === selectedPermissionLevel.value.toLowerCase()) {
                        // Getting new options from selectedSelectOptions
                        const roleOptions = selectedSelectOptions.filter(option => option.type === 'role').map(option => ({ name: option.label, id: option.value }));
                        const userOptions = selectedSelectOptions.filter(option => option.type === 'user').map(option => ({ name: option.label, id: option.value }));
                        const teamOptions = selectedSelectOptions.filter(option => option.type === 'team').map(option => ({ name: option.label, id: option.value }));

                        const uniqueRoleIds = Array.from(new Map(roleOptions.map(item => [item.id, item])).values());
                        const uniqueUserIds = Array.from(new Map(userOptions.map(item => [item.id, item])).values());
                        const uniqueTeamIds = Array.from(new Map(teamOptions.map(item => [item.id, item])).values());

                        return {
                            ...permission,
                            or: {
                                ...permission.or,
                                roleIds: uniqueRoleIds,
                                userIds: uniqueUserIds,
                                teamIds: uniqueTeamIds,
                            },
                        };
                    }
                    if (permission.permissionSetName.toLowerCase() === initialPermisionLevel.value.toLowerCase()) {
                        return {
                            ...permission,
                            or: {
                                roleIds: [],
                                userIds: [],
                                teamIds: [],
                            },
                            and: {
                                roleIds: [],
                                userIds: [],
                                teamIds: [],
                            }
                        }
                    }
                    return permission;
                }).map((permission) => {
                    // Ensuring the creator permission level includes the Admin role always
                    if (permission.permissionSetName.toLowerCase() === 'creator' && !permission.or.roleIds.some(role => role.id === 'admin')) {
                        return {
                            ...permission,
                            or: {
                                ...permission.or,
                                roleIds: [...permission.or.roleIds, { name: 'Admin', id: 'admin' }]
                            }
                        };
                    }
                    return permission;
                });
            } else {
                newPermissions = entityPermissions.map((permission) => {
                    if (permission.permissionSetName.toLowerCase() === selectedPermissionLevel.value.toLowerCase()) {
                        const combinedRoleOptions = [...permission.or.roleIds, ...selectedSelectOptions.filter(option => option.type === 'role').map(option => ({ name: option.label, id: option.value }))];
                        const combinedUserOptions = [...permission.or.userIds, ...selectedSelectOptions.filter(option => option.type === 'user').map(option => ({ name: option.label, id: option.value }))];
                        const combinedTeamOptions = [...permission.or.teamIds, ...selectedSelectOptions.filter(option => option.type === 'team').map(option => ({ name: option.label, id: option.value }))];

                        // Deduplicating the combined options based on IDs.
                        const uniqueRoleOptions = Array.from(new Map(combinedRoleOptions.map(item => [item.id, item])).values());
                        const uniqueUserOptions = Array.from(new Map(combinedUserOptions.map(item => [item.id, item])).values());
                        const uniqueTeamOptions = Array.from(new Map(combinedTeamOptions.map(item => [item.id, item])).values());

                        return {
                            ...permission,
                            or: {
                                ...permission.or,
                                roleIds: uniqueRoleOptions,
                                userIds: uniqueUserOptions,
                                teamIds: uniqueTeamOptions,
                            },
                        };
                    }
                    return permission;
                });
            }

            dispatch(setEntityPermissions(newPermissions));

            const payload = {
                tablePermissions: newPermissions.map(permission => ({
                    ...permission,
                    or: {
                        ...permission.or,
                        roleIds: permission.or.roleIds.map(obj => obj.id),
                        userIds: permission.or.userIds.map(obj => obj.id),
                        teamIds: permission.or.teamIds.map(obj => obj.id),
                    },
                })),
                tableType: selectedEntity?.tableType,
                accountId: decoded?.accountId,
                notifyUsers: false
            };

            setIsLoading(true);
            try {
                const response = await EntityService.updateEntityPermissions(payload);
                if (response) {
                    recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_SUCCESS, {
                        resourceType: resourceType,
                        ...payload,
                    })
                    dispatch(setFinalEntityPermissions(newPermissions));
                    dispatch(getEntityMetadata(selectedEntity?.tableType || ""));
                    setSelectedSelectOptions([]);
                    onClose();
                    recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_MODAL_CLOSE);
                    notification("success", "Permissions updated successfully");
                } else {
                    recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_FAILURE, {
                        resourceType: resourceType,
                        ...payload,
                    })
                    notification("error", "Error while updating permissions");
                }
            } catch (error) {
                console.error(error);
                notification("error", "Error while updating permissions");
            } finally {
                setIsLoading(false);
                setConfirmEdit(false);
            }
        }
    }


    const onCancelHandler = () => {
        onClose();
        recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_MODAL_CLOSE);
        if (resourceType === ResourceTypeConstant.TABLE) {
            dispatch(setEntityPermissions(finalEntityPermissions));
            recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_CANCEL_BUTTON_CLICKED, {
                resourceType: resourceType,
                ...finalEntityPermissions,
                ...entityPermissions,
            })
        }
    }

    return (
        <ModalOverlay onClick={onCancelHandler}>
            <ModalContainer onClick={e => e.stopPropagation()}>
                <ShareContainer>
                    <Heading>Share this {resourceType}</Heading>
                    <SubHeading>You can share this {resourceType} with any users or roles or teams</SubHeading>
                    <ShareResourceContainer>
                        <ShareSectionContainer>
                            <SearchInputContainer context={context} selectedOptions={selectedSelectOptions} onChangeSelect={setSelectedSelectOptions} />
                            <PermissionLabel>are
                                <Select value={selectedPermissionLevel} isSearchable={false} options={getPermissionLevels(resourceType, permissionConfiguration)} onChange={(e) => handlePermissionLevelChange(e, null)} styles={customStyles} placeholder="Select permission level" />
                            </PermissionLabel>
                        </ShareSectionContainer>
                        {confirmEdit && <Error>*You are about to edit your own permissions</Error>}
                    </ShareResourceContainer>
                </ShareContainer>
                {/* <AddPermissionButton onClick={() => handleAddPermission()}>
                    <PlusIcon color='#667085' fontSize={'18px'} />
                    <Text color='#667085'><span style={{ cursor: 'pointer' }}>Add another permission</span></Text>
                </AddPermissionButton> */}
                {/* <StyledInputContainer>
                    <input type='checkbox' onClick={() => setNotifyAllUsers(!notifyAllUsers)} style={{ borderRadius: "4px", borderColor: "#344054" }} />
                    <Text>Notify all users</Text>
                </StyledInputContainer> */}
                <ActionButtons>
                    {context === 'create' ? <ManageAccessButton
                        $active={selectedSelectOptions.length > 0}
                        disabled={selectedSelectOptions.length > 0}
                        onClick={() => {
                            if (selectedSelectOptions.length === 0) {
                                if (setShowPermissionsModal) setShowPermissionsModal(true);
                                recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_MANAGE_ACCESS_BUTTON_CLICKED, {
                                    resourceType: resourceType,
                                })
                                onClose();
                                recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_MODAL_CLOSE);
                            }
                        }}
                    >
                        Manage Access
                    </ManageAccessButton> : <div></div>}
                    <ActionButtonWrapper>
                        <Button color="#344054" borderColor='#D0D5DD' background="#fff" onClick={onCancelHandler}>Cancel</Button>
                        {!confirmEdit && <SaveButton $active={!(selectedPermissionLevel === null) && selectedSelectOptions.length > 0 && !isLoading} disabled={selectedPermissionLevel === null || selectedSelectOptions.length === 0 || isLoading} onClick={() => onSaveHandler()}>{isLoading ? "Saving..." : "Save"}</SaveButton>}
                        {confirmEdit &&
                            <SaveButton
                                $confirm='#F03738'
                                $active={!(selectedPermissionLevel === null) && selectedSelectOptions.length > 0 && !isLoading}
                                disabled={selectedPermissionLevel === null || selectedSelectOptions.length === 0 || isLoading}
                                onClick={() => {
                                    handleProceedSave();
                                    setConfirmEdit(false);
                                    recordRSEvent(TablePermissionRsEvents.SHARE_RESOURCE_CONFIRM_BUTTON_CLICKED, {
                                        resourceType: resourceType
                                    })
                                }}>
                                {isLoading ? "Saving..." : "Confirm"}
                            </SaveButton>}
                    </ActionButtonWrapper>
                </ActionButtons>
                {/* <ChoiceConfirmation
                    isOpen={confirmEdit}
                    confirmationTitle='Warning'
                    confirmationMessage='You are about to change your own permissions'
                    onClose={() => { setConfirmEdit(false); setSelectedSelectOptions(initialSelectedOptions || []); }}
                    onConfirm={(e: any) => { handleProceedSave(); setConfirmEdit(false) }}
                /> */}
            </ModalContainer>
        </ModalOverlay>
    );
};

export default ShareResourceModal;
