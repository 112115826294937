import { useListContext } from "react-admin";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { recordRSEvent } from "utils/CommonUtils";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import _ from "lodash";
import CustomWorkflowContent from "./CustomWorkflowContent";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  margin-bottom: 16px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const CustomWorkflowList = (props: any) => {
  const { data }: any = useListContext();
  const navigate = useNavigate();

  const onOpenWorkflowClick = (e: any, record: any) => {
    console.log(">>>>>>>", record);
    recordRSEvent("workflowlist_row_click", {
      context: "builder_listing",
      workflow_id: record.id,
      workflow_name: record.name,
    });
    if (record?.isBuilderEditable === false) {
      alert("Editing is disabled for this workflow");
      return "";
    } else navigate(`/workflows/${record.id}/show`);
  };

  // show loader if data is not available
  if (_.isEmpty(data)) return <LoadingScreen />;

  return data.map((workflow: any) => {
    return (
      <Wrapper
        key={workflow.taskType}
        onClick={(e: any) => onOpenWorkflowClick(e, workflow)}
      >
        <CustomWorkflowContent data={workflow} {...props} />
      </Wrapper>
    );
  });
};

export default CustomWorkflowList;
