//@ts-nocheck
import { memo, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { IConditionOperatorType, IOperatorType, OperatorData, whereConditionOperator } from "./QueryBuilderConstant";
import {
  QueryBuilderWrapper,
  WhereConditionWrapper,
} from "./StyledComponents";
import WhereCondition from "./WhereCondition";

interface IQueryBuilder {
  model: any;
  onQueryBuilderOutput : (queryBuilderOutputData:any) => void;
}

const QueryBuilder = ({ model, onQueryBuilderOutput }:IQueryBuilder) => {
  const fieldOptionData = model?.columnsData?.map((data) => {
    return {
      value: data.name,
      label: data.name,
      dataType: data.dataType,
      disabled: !data.isSupported, // if it is not supported then disable it
      originalDataType: data.originalDataType,
    };
  });

  // Define the type for your default array value structure
type WhereConditionDefaultArrayType = {
  operatorArrayData: IOperatorType[];
  column: string;
  operator: IOperatorType;
  value: string;
  fromValue: string; // this is only for between operator for date and date time
  toValue: string; // this is only for between operator for date and date time
  betweenValue: IConditionOperatorType;
  dataType: string;
};


  const whereConditionDefaultArrayValue = {
    operatorArrayData: [],
    column: "",
    operator: OperatorData[0],
    value: "",
    fromValue: "", // this is only for between operator for date and date time
    toValue: "", // this is only for between operator for date and date time
    betweenValue: whereConditionOperator[0],
    dataType: "",
  };

  //{ register, control, handleSubmit, watch, setValue, reset }

  const whereConditionUseFormData = useForm<FormData | any>({
    defaultValues: {
      filterDropDownData: [whereConditionDefaultArrayValue],
    },
  });

  //{ fields, append, remove }
  const whereConditionUseFieldArray = useFieldArray({
    control: whereConditionUseFormData.control,
    name: "filterDropDownData",
  });

  const orderByDefaultArrayValue = {
    columnValue: "",
    orderValue: "",
  };

  // { register, control, handleSubmit, watch, setValue, reset }
  const orderByUseFormData = useForm({
    defaultValues: {
      orderByData: [orderByDefaultArrayValue],
    },
  });

  // { fields, append, remove }
  const orderByUseFieldArray = useFieldArray({
    control: orderByUseFormData.control,
    name: "orderByData",
  });

  useEffect(() => {
    if (model?.isBtnClicked == true) {
      onSubmit();
    }
  }, [model]);

  const onSubmit = () => {
    const whereFormData = whereConditionUseFormData.watch();
    const orderByFormData = orderByUseFormData.watch();

    const queryBuilderOutputData = { ...whereFormData, ...orderByFormData };

    console.log("queryBuilderOutputData", queryBuilderOutputData);
    
    // modelUpdate({
    //   isBtnClicked: false,
    //   outputData: queryBuilderOutputData,
    // });
    // TODO_MONITOR: 
    onQueryBuilderOutput(queryBuilderOutputData)
    // triggerQuery("get_query_payload");
    // TODO_MONITOR: 
  };

  return (
    <>
      {model && (
        <QueryBuilderWrapper>
          <WhereConditionWrapper>
            <WhereCondition
              model={model}
              fieldOptionData={fieldOptionData}
              whereConditionUseFormData={whereConditionUseFormData}
              whereConditionUseFieldArray={whereConditionUseFieldArray}
              whereConditionDefaultArrayValue={whereConditionDefaultArrayValue}
            />
          </WhereConditionWrapper>

          {/* <OptionsSelect>
          <OrderByComponent
            triggerQuery={triggerQuery}
            model={model}
            fieldOptionData={fieldOptionData}
            orderByUseFormData={orderByUseFormData}
            orderByUseFieldArray={orderByUseFieldArray}
            orderByDefaultArrayValue={orderByDefaultArrayValue}
          />
        </OptionsSelect>

        <SelectFieldWrapper>
          <SelectField
            triggerQuery={triggerQuery}
            model={model}
          />
        </SelectFieldWrapper> */}
        </QueryBuilderWrapper>
      )}
    </>
  );
};
// export default QueryBuilder;
export  default memo(QueryBuilder);