import WorkflowErrorDropdown from "./WorkflowErrorDropdown";
import useWorkflow from "views/workflows/hooks/useWorkFlow";
import { useDispatch } from "react-redux";
import { setShowWorkflowErrors } from "views/workflows/reducers/workflowAction";
import { Button } from "views/DataField/DataField.styles";
import {MdDangerous} from "react-icons/md"
import styled from "styled-components"


const ErrorButton = styled(Button)`
    padding: 10px 10px;
    margin-right: 0px;
    border: none !important;
    display: flex;
    align-items: center;
`

const ShowWorkflowErrorButton = ({allWorkflowErrors}:{allWorkflowErrors:any}) => {
  const dispatch = useDispatch();
  const { showWorkflowErrors } = useWorkflow();

  return (
    <>
      {/* <AiOutlineBug  /> */}
      <ErrorButton
        onClick={() => dispatch(setShowWorkflowErrors(true))}
        variant="danger"
      >
        {" "}
        <MdDangerous
              style={{ display: "inline",marginRight:"4px"}}
              size={"1.2rem"}
              color="white"
        />{allWorkflowErrors.length || 0} {allWorkflowErrors.length === 1 ? "Issue" : "Issues"} Detected {" "}
      </ErrorButton>
      
      {showWorkflowErrors && <WorkflowErrorDropdown handleClose={() => dispatch(setShowWorkflowErrors(false))} allErrors={allWorkflowErrors} />}
    </>
  );
};

export default ShowWorkflowErrorButton;
