import { useEffect, useState } from "react";
import { AppBar, Layout, useSidebarState } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import Menu from "./Menu.tsx";
import ZorpAppBar from "./MyAppBar";
import { initAccount } from "./reducer/accountSlice";
import { initMenu } from "./reducer/menuSlice";
import classnames from "classnames";
import { initEntities } from "views/entities/reducers/entityReducers.tsx";
import { useMediaQuery, useTheme } from "@mui/material";
import {useTheme as useStyledComponentTheme} from "styled-components"
import { setCopiedAction } from "views/actions/Action";
const ZorpLayout = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useSidebarState();
  const activeTheme = useStyledComponentTheme();
  const theme = useTheme();
  // const [activeTheme, setActiveTheme] = useState({
  //   color: { primary: "#3054B9", secondary: "#243F8B", tertiary: "#F5F8FF" },
  // });

  
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isPreview = localStorage.getItem("isPreview");

  // show Appbar only if isMobile is true and isPreview is false
  const showAppBar = isMobile && !isPreview

  // const getAccountDetailsFunc = async () => {
  //   try {
  //     const accountInfo = await getAccountDetails();
  //     let link = document.querySelector("link[rel~='icon']");
  //     if (!link) {
  //       link = document.createElement("link");
  //       link.rel = "icon";
  //       document.getElementsByTagName("head")[0].appendChild(link);
  //     }
  //     link.href = accountInfo?.accountConfig?.logo;
  //     const newTheme = {
  //       primary: accountInfo?.accountConfig?.theme?.primaryColor,
  //       secondary: accountInfo?.accountConfig?.theme?.secondaryColor,
  //       tertiary: accountInfo?.accountConfig?.theme?.tertiaryColor,
  //     }
  //     setActiveTheme({ color: newTheme });
  //   } catch (error) {
  //     console.log(error)
  //   }
    
  // };

  // useEffect(() => {
  //   getAccountDetailsFunc()
  // },[])


  useEffect(() => {
    
    dispatch(initMenu());
    dispatch(initAccount());
    dispatch(initEntities());
  }, []);

  useEffect(() => {
    try {
      const copiedActionDataString = localStorage.getItem('copiedAction');
      if (copiedActionDataString) {
        const copiedAction = JSON.parse(copiedActionDataString);
        dispatch(setCopiedAction(copiedAction))
      }
    } catch (error) {
      console.log(`Error in setting copied action from localstorage to redux ${error}`);
    }
  }, []);

  return (
    <Layout
      {...props}
      // customize sidebar deisgn
      className={classnames(open ? "zLayout-open" : "zLayout-close")}
      appBar={showAppBar ? AppBar : ZorpAppBar}
      menu={() => <Menu {...props} zTheme={activeTheme} />}
    />
  );
};

export default ZorpLayout;
