//@ts-nocheck
import { FunctionComponent, useEffect } from "react";
import { Controller } from "react-hook-form";
import { IZComponentPropType } from "render-engine/models/ITaskTypeWebBodyRenderModel";
import Select from "react-select";
import {
  ColSpan4,
  RedText,
  TaskFormLabelForCreateAndEdit,
} from "render-engine/render.styles";

const ZBoolean: FunctionComponent<IZComponentPropType> = ({
  config,
  onChange,
  control,
  setValue,
}) => {
  const options = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];

  useEffect(() => {
    setValue(config.key, config?.value ?? true);
  }, []);

  const customStyles = {
    container: (provided) => ({ ...provided, width: "100%" }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#101828",
        fontSize: "12px",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        border: "1px solid #D0D5DD",
        fontSize: "12px",
        borderRadius: "6px",
        backgroundColor: "none", // change background color of the pill
      };
    },
    multiValueLabel: (styles) => {
      return {
        ...styles,
        fontSize: "12px",
        color: "#101828", // change text color inside the pill
      };
    },
    singleValue: (provided) => ({
      ...provided,
      fontSize: "12px", // added fontSize here
    }),
    control: (provided) => ({
      ...provided,
      // padding: "4px 6px", // added padding here
    }),
  };

  const isVisible = config?.isVisible || true;

  return (
    <>
      {isVisible && (
        <ColSpan4>
          {config?.label?.isVisible && (
            <TaskFormLabelForCreateAndEdit
              fontSize={config?.label?.fontSize ? config.label.fontSize : 14}
              color={config?.label?.color ? config.label.color : "black"}
              className="col-span-1 font-medium"
              style={{ padding: "20px 0px 4px 0px" }}
            >
              {config?.label?.val}
              {config?.isRequired && <RedText>*</RedText>}
            </TaskFormLabelForCreateAndEdit>
          )}
          <Controller
            name={config?.key}
            defaultValue={true}
            control={control}
            // rules={{
            //   required: config?.isRequired ? config.isRequired : false,
            // }}
            render={({ field: { onChange: fieldOnChange, value } }) => (
              <Select
                styles={customStyles}
                value={options.find((c) => c.value === value)}
                // isClearable
                isSearchable={false}
                className="react-dropdown"
                classNamePrefix="dropdown"
                onChange={(selectedOption: any) => {
                  fieldOnChange(selectedOption?.value);
                  onChange(config.key, selectedOption?.value);
                }}
                options={options}
              />
            )}
          />
        </ColSpan4>
      )}
    </>
  );
};

export default ZBoolean;
