import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { TabContext } from "@mui/lab";
import {
  Box,
  Link,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getStringEllipsis,
  getTemplateIdFromNewWorkflow,
} from "utils/CommonUtils";
import {
  ZButton,
  ZTabList,
} from "views/commonComponents/commonComponents.styles";
import ZModal from "views/commonComponents/ZModal";
import ZTabPanel from "views/commonComponents/ZTabPanel";
import { images } from "views/workflow-templates/assests/images";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  communicateToUserOnOpeningWorkflowFromPhone,
  getDeviceType,
  recordRSEvent,
} from "utils/CommonUtils";
import {
  BookASessionWithZorpExpert,
  CreateFromScratch,
  SuggestATemplate,
  ViewVideoTutorials,
} from "views/workflow-templates/constants/WTConstants";
import {
  DocContainer,
  MainVideoDesc,
  MainVideoDuration,
  MainVideoTitle,
  RecommendVideo,
  SubVideoDesc,
  SubVideoDuration,
  SubVideoTitle,
  TemplateContainer,
  TemplateDesc,
  TemplateTitle,
  ZChip,
} from "views/workflow-templates/workflow-template.styles";
import {
  clearPublishedWorkflowData,
  publishTemplateAsWorkflow as publishTemplateAsWorkflowAction,
  setSelectedTemplateLHS,
} from "../../reducers/WorkflowTemplate.actions";
import { TaskFlowService } from "providers/data/services/TaskFlowService";

const WTMobileTabs = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { open, handleClose } = props;

  const [show, setShow] = useState("");
  const [value, setValue] = useState<any>(0);
  const [recommendDocs, setRecommendDocs] = useState([]);
  const [recommendVideo, setRecommendVideo] = useState<any>([]);

  const divRef = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { workflowTemplateHome, publishedWorkflow } = useSelector(
    (state: any) => state.workflowTemplates
  );

  useEffect(() => {
    if (workflowTemplateHome) {
      setRecommendDocs(workflowTemplateHome?.tutorials?.docs);
      setRecommendVideo(workflowTemplateHome?.tutorials?.videos?.[0]);
    }
  }, [workflowTemplateHome]); // eslint-disable-line react-hooks/exhaustive-deps

  const style = {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "14px",
  };

  const getDefaultImage = (e: any) => {
    e.target.src = images["DefaultImg"];
  };

  const handleChange = (_event: any, newValue: string) => {
    setValue(newValue);
  };

  const publishTemplateAsWorkflow = (id: string) => {
    dispatch(publishTemplateAsWorkflowAction(id));
  };

  const handleClick = async () => {
    try {
      const templateId = getTemplateIdFromNewWorkflow();
      const data: any = await TaskFlowService.publishTemplateAsWorkflow(
        templateId
      );
      recordRSEvent(CreateFromScratch.name, CreateFromScratch);
      communicateToUserOnOpeningWorkflowFromPhone();
      navigate(`/workflows/${data.data}/show`);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (publishedWorkflow) {
      Promise.resolve(navigate(`/workflows/${publishedWorkflow}/show`)).then(
        () => {
          dispatch(clearPublishedWorkflowData());
          handleClose(false);
        }
      );
    }
  }, [publishedWorkflow]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ZModal
      title="Workflow Templates"
      isOpen={open}
      handleClose={handleClose}
      childComponent={
        <>
          <Box>
            <Typography variant="h3" mb={2} display="flex">
              Welcome to Zorp!{" "}
              <img
                src={images["Rocket"]}
                alt="rocket"
                style={{ marginLeft: "1.6rem", marginBottom: "0.6rem" }}
              />
            </Typography>
            <Typography variant={isMobile ? "h6" : "h5"} mb={2}>
              Get your tools built without delays or dependencies so you can{" "}
              <b>focus on your core business</b>
            </Typography>
            <hr />
            <Typography variant={isMobile ? "h6" : "h5"} my={2}>
              To learn more about Zorp, check out tutorials
            </Typography>
            <ZButton
              variant="contained"
              className="mb-2"
              fullWidth
              startIcon={
                <AddOutlinedIcon
                  className="svg_icons_l"
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    marginRight: 0,
                  }}
                />
              }
              onClick={handleClick}
            >
              Create from Scratch
            </ZButton>
          </Box>
          <TabContext value={value}>
            <ZTabList onChange={handleChange} secondary>
              <Tabs value={value}>
                <Tab sx={style} label="Workflow Templates" />
                <Tab sx={style} label="Tutorials" />
              </Tabs>
            </ZTabList>
            <ZTabPanel value={value} index={0}>
              {workflowTemplateHome?.templates?.map((template: any) => (
                <TemplateContainer
                  ref={divRef}
                  onMouseEnter={() => {
                    setShow(template?.templateId);
                    dispatch(setSelectedTemplateLHS(""));
                  }}
                  onMouseLeave={() => {
                    setShow("");
                  }}
                  className={
                    show === template?.templateId
                      ? "active mt-2 p-1"
                      : "mt-2 p-1"
                  }
                >
                  <img
                    src={template?.thumbnail || images["DefaultImg"]}
                    alt="thumbnail"
                    onError={getDefaultImage}
                  />
                  <TemplateTitle className="mt-2 ml-1">
                    {template?.name}
                  </TemplateTitle>
                  <TemplateDesc className="mt-1 ml-1">
                    {getStringEllipsis(template?.description, 200)}
                  </TemplateDesc>
                  {show === template?.templateId && (
                    <ZButton
                      variant="contained"
                      size="small"
                      sx={{ marginBottom: 2 }}
                      onClick={() => {
                        publishTemplateAsWorkflow(template?.templateId);
                      }}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 2,
                      }}
                    >
                      Use this template
                    </ZButton>
                  )}
                  <Box mt={2}>
                    {template?.tags?.map((tag: any) => (
                      <ZChip
                        size="small"
                        label={tag}
                        className="mr-1 mb-1 mt-1"
                      />
                    ))}
                  </Box>
                </TemplateContainer>
              ))}
            </ZTabPanel>
            <ZTabPanel value={value} index={1}>
              <Link href={recommendVideo?.url} target="_blank" rel="noopener">
                <RecommendVideo display="flex" alignItems="center">
                  <img src={images["ZLogo"]} alt="logo" />
                  <Box ml={2}>
                    <MainVideoTitle className="mb-1">
                      {recommendVideo?.heading}
                    </MainVideoTitle>
                    <MainVideoDesc className="mt-1 mb-1">
                      {recommendVideo?.title}
                    </MainVideoDesc>
                    <MainVideoDuration>
                      {" "}
                      <PlayArrowIcon
                        style={{
                          backgroundColor: "transparent",
                          color: "#999999",
                        }}
                        sx={{ m: "1,0,0,0" }}
                      />
                      {recommendVideo?.duration}
                    </MainVideoDuration>
                  </Box>
                </RecommendVideo>
              </Link>
              {recommendDocs?.map((doc: any) => (
                <Link href={doc?.url} target="_blank" rel="noopener">
                  <DocContainer display="flex" alignItems="center">
                    <img src={images["ZLogo"]} alt="logo" />
                    <Box className="ml-2">
                      <SubVideoTitle>{doc?.heading}</SubVideoTitle>
                      <SubVideoDesc className="mb-1">{doc?.title}</SubVideoDesc>
                      <SubVideoDuration>
                        <InsertDriveFileIcon
                          style={{
                            backgroundColor: "transparent",
                            color: "#999999",
                            margin: "0",
                          }}
                        />
                        <span className="mr-2">{doc?.duration}</span>
                      </SubVideoDuration>
                    </Box>
                  </DocContainer>
                </Link>
              ))}
            </ZTabPanel>
          </TabContext>
        </>
      }
    />
  );
};

export default WTMobileTabs;
