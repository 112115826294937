//@ts-nocheck
import { useState } from "react";
import useListInfo from "views/workflows/hooks/useListInfo";
import useTaskEdit from "views/workflows/hooks/useTaskEdit";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { AiOutlineClose } from "react-icons/ai";
import {
  useForm,
  UseFormGetValues,
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
} from "react-hook-form";
import { useDispatch } from "react-redux";
import { Button } from "views/DataField/DataField.styles";
import notification from "notifications/notifications";
import "react-datetime/css/react-datetime.css";
import { Render } from "render-engine/Render";
import { BffService } from "providers/data/services/BffService";
import {
  loadListData,
  loadUIElements,
} from "render-engine/reducers/listInfo/index";
import ZBoolean from "components/boolean/ZBoolean";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { setElements } from "react-flow-renderer/dist/store/actions";
import TopBar from "render-engine/TopBar";
import { setTaskData } from "render-engine/reducers/show/TaskShowReducer";


const Wrapper = styled.form`
  margin-right: 11rem;
  max-height: 70vh;
  /* overflow-y: auto; */
  overflow: hidden;
  background-color: white;
  margin-left: 10vw;
  margin-top: 10vh;
  /* padding: 15px; */
  /* border-radius: 4px; */
`;

const ButtonContainer = styled.div`
  display: flex;
  /* gap: 8px; */
  justify-content: flex-end;
  margin-top: 10px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  p {
    font-weight: 500;
    font-size: 14px;
  }

  svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

type Props = {
  showModal: boolean;
  setShowModal: any;
  name: string;
  mode: "create" | "edit";
  location: string;
  activeEditListSubfield: any;
  control: Control;
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
  getValues: UseFormGetValues<any>;
  append: UseFieldArrayAppend<any>;
  update: Function;
  remove: UseFieldArrayRemove;
};

const CreateSubFieldModal = ({
  location,
  showModal,
  setShowModal,
  name,
  activeEditListSubfield,
  register,
  control,
  setValue,
  append,
  getValues,
  update,
  mode,
  remove,
}: Props) => {
  const {
    register: modalRegister,
    control: modalControl,
    handleSubmit,
    formState,
    setValue: modalSetValue,
    getValues: modalGetValues,
    setError: modalSetError,
    clearErrors: modalClearErrors,
  } = useForm();

  const { metaData, uiElements, listData } = useListInfo({ name });
  const { taskId } = useTaskEdit({ name: "" });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const saveData = async (data: any) => {
    try {
      setLoading(true);
      const res = await BffService.updateSubfields({
        taskId,
        type: mode === "create" ? "ADD" : "MODIFY",
        listKey: name,
        modifyMetadata: metaData,
        item: data,
        index: mode === "create" ? undefined : activeEditListSubfield.id,
        listData: listData || [],
      });
      if (mode === "edit") {
        update(activeEditListSubfield.id, data);
      } else {
        append(data);
      }
      dispatch(
        setTaskData({
          nodeId: name,
          data: res?.data?.listData,
        })
      );
      dispatch(loadListData({ name, val: res?.data?.listData }));
      dispatch(loadUIElements({ name, val: res?.data?.uiElements }));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setShowModal(false);
    notification("success", "Subfield added");
  };

  const handleSubmitWithoutPropagation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(saveData)(e);
  };

  const handleChange = (key: string, value: string) => {};

  // Get the Correct render config
  const subRenderConfig =
    mode === "create" ? metaData : uiElements[activeEditListSubfield.id];
  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper onSubmit={handleSubmitWithoutPropagation}>
        {/* <Heading> */}
        {/* <div>
            <p>
              {mode === "create"
                ? `${name}: Create`
                : `${name}: Edit`}
            </p>
          </div>
          <div>
            <AiOutlineClose size="1.2rem" onClick={() => setShowModal(false) } />
          </div> */}
        <TopBar
          heading={mode === "create" ? `${name}: Create` : `${name}: Edit`}
          onSaveText={mode === "create" ? "Create" : "Edit"}
          onCancelText={"Cancel"}
          onSave={() => {}}
          onCancel={() => setShowModal(false)}
        />
        {/* </Heading> */}
        <hr />
        <div style={{ padding: "15px", maxHeight: "60vh", overflow: "auto" }}>
          <Render
            renderConfig={subRenderConfig}
            componentParams={{
              register: modalRegister,
              onChange: handleChange,
              errors: formState.errors,
              control: modalControl,
              setValue: modalSetValue,
              isOpenedAsSubfield: true,
              getValues: modalGetValues,
              setError: modalSetError,
              clearErrors: modalClearErrors,
            }}
          ></Render>
        </div>
      </Wrapper>
    </Modal>
  )
}

export default CreateSubFieldModal;
