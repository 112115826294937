//@ts-nocheck
import { Grid, Typography } from '@mui/material';
import Modal from "@mui/material/Modal";
import notification from "notifications/notifications";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { getArrayfromObject } from "utils/Utils";
import { ZButton } from "views/commonComponents/commonComponents.styles";
import { HorizontalLine, ModalHeading } from "views/DataField/DataField.styles";
import CoreDataFieldsList from "./CoreDataFieldsList";
import { useSelector } from "react-redux";
import Tooltip from '@mui/material/Tooltip';
import CustomDataFieldsList from "./CustomDataFieldsList";

type DataFieldsType = {
  name: string;
};

type inputListDataType = {
  coreDataFields: Array<DataFieldsType>;
  customDataFields: Array<string>;
};

type gridExportModalProp = {
  isOpen: boolean;
  allRowsFill:boolean;
  setShowModal: any;
  modalTitle: string;
  inputListData: inputListDataType;
  exportData: (columnList: Array<any>) => void;
  onAllRowsFillClick:() => void;
};


const GridExportModal = ({
  isOpen,
  setShowModal,
  modalTitle,
  inputListData,
  exportData,
  allRowsFill,
  onAllRowsFillClick
}: gridExportModalProp) => {
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [newSelectList, setNewSelectList] = useState({});
  const taskListFilter = useSelector(
    (state) => state.taskShow.taskListFilterInfo
  );

  useEffect(() => {
    if (
      inputListData?.coreDataFields !== undefined &&
      inputListData?.customDataFields !== undefined
    ) {
      inputListData?.coreDataFields?.forEach((element: any) => {
        setSelectedList((prev) => [...prev, element.name]);
      });

      let filteredCheckList: any = {};

      Object.keys(inputListData?.customDataFields).forEach((element: any) => {
        inputListData?.customDataFields[element].forEach((data: any) => {
          if (filteredCheckList.hasOwnProperty(element)) {
            if (taskListFilter?.taskTypes?.includes(element)) {
              filteredCheckList[element].push(data.name);
            } else if (
              taskListFilter?.taskTypes?.length == 0 ||
              taskListFilter?.taskTypes == undefined
            ) {
              filteredCheckList[element].push(data.name);
            }
          } else {
            filteredCheckList[element] = [];
            if (taskListFilter?.taskTypes?.includes(element)) {
              filteredCheckList[element].push(data.name);
            } else if (
              taskListFilter?.taskTypes?.length == 0 ||
              taskListFilter?.taskTypes == undefined
            ) {
              filteredCheckList[element].push(data.name);
            }
          }
        });
      });
      setNewSelectList([filteredCheckList]);
    }
  }, [inputListData, taskListFilter]);

  return (
    <Modal
      open={isOpen}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        overflow: "auto",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid
        style={{
          position: "relative",
          height: "700px",
          width: "400px",
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
          backgroundColor: "white",
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            position: "sticky",
            top: 0,
            alignSelf: "flex-start",
            backgroundColor: "white",
            width: "100%",
            zIndex: 100,
          }}
        >
          <Grid>
            <ModalHeading>{modalTitle}</ModalHeading>
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <AiOutlineClose size="1.5rem" onClick={() => setShowModal(false)} />
          </Grid>
        </Grid>
        <HorizontalLine />

        {
          <Grid style={{ marginTop: "8px" }}>
            {selectedList && (
              <>
                <ModalHeading>Core Datafields</ModalHeading>
                <CoreDataFieldsList
                  inputListData={inputListData.coreDataFields}
                  selectedList={selectedList}
                  setSelectedList={setSelectedList}
                />
              </>
            )}

            {newSelectList && (
              <>
                <ModalHeading>Custom Datafields</ModalHeading>
                <CustomDataFieldsList
                  inputListData={inputListData.customDataFields}
                  newSelectList={newSelectList}
                  setSelectedList={(data: any) => {
                    setNewSelectList([data]);
                  }}
                />
              </>
            )}
          </Grid>
        }
        <Grid
         style={{
          justifyContent: 'flex-end',
          position: 'sticky',
          bottom: 0,
          marginTop: 'auto',
          padding: '7px',
          backgroundColor: 'white',
          display: 'flex',
          width: '100%',
          zIndex: 100,
        }}
        >
          <div className="" style={{ width:'100%', paddingLeft:'15px',display:'flex', alignItems:'center'}}>
            {/* <FormControlLabel
              control={
                <input
                  type="checkbox"
                  style={{
                  }}
                  checked={allRowsFill}
                  onChange={()=>{onAllRowsFillClick()}}
                  value="one"
                />

              }
              label={
                <CustomTooltip placement='right-start' title="Option to Fill all the rows, when the list component is available">
                <Typography style={{paddingLeft:'5px'}}  variant="h6">Fill task's All Rows</Typography>  
                </CustomTooltip>
              }
            /> */}
          </div>
          <div
            className=""
            style={{ display: "flex", justifyContent: "flex-end", width:'100%'}}
          >
            <ZButton
              secondary
              variant="contained"
              onClick={() => setShowModal(false)}
              type="button"
              className="mr-2"
            >
              Cancel
            </ZButton>
            <ZButton
              variant="contained"
              onClick={() => {
                if (
                  selectedList.length > 0 ||
                  Object.keys(newSelectList[0]).length > 0
                ) {
                  const finalSelected = getArrayfromObject(
                    newSelectList[0]
                  ).concat([...new Set(selectedList)]);
                  setShowModal(false);
                  exportData(finalSelected);
                } else {
                  notification("warning", "Select minimum one field");
                }
              }}
              type="submit"
            >
              Export
            </ZButton>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default GridExportModal;
