//@ts-nocheck
import  { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { ZButton } from "views/commonComponents/commonComponents.styles";

const SetupForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      //   confirmParams: {
      //     return_url: 'http://localhost:3000/#/payments/setup-complete',
      //   },
      redirect: "if_required",
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      console.log("setupIntent", setupIntent);
      if (setupIntent) {
        localStorage.setItem("setup_intent", setupIntent.id);
        localStorage.setItem(
          "setup_intent_client_secret",
          setupIntent.client_secret
        );
        localStorage.setItem("redirect_status", setupIntent.status);
        window.location.reload();
        // navigate('/settings/payment');
        // navigate(`/payments/setup-complete?setup_intent=${setupIntent.id}&setup_intent_client_secret=${setupIntent.client_secret}&redirect_status=${setupIntent.status}`);
      }
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const opt = {
    defaultValues: {
      billingDetails: {
        name: "name",
        email: "email",
        phone: "phone",
        address: {
          country: "country",
          postal_code: "code",
          state: "state",
          city: "city",
          line1: "line1",
          line2: "line2",
        },
      },
    },
  };
  return (
    <form class="setupFormCard" onSubmit={handleSubmit}>
      <PaymentElement />

      <ZButton disabled={!stripe} variant="contained" type="submit">
        Submit
      </ZButton>
      {/* <button disabled={!stripe}>Submit</button> */}
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default SetupForm;
