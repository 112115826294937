import 'leaflet/dist/leaflet.css';
import { CreateTask } from '../../render-engine/create/CreateTask';
import EditTask from '../../render-engine/edit/EditTask';
import { ShowTask } from '../../render-engine/show/ShowTask';


export const TaskCreate = (props) => {
  const queryParams = Object.fromEntries(new URLSearchParams(props.location.search.substring(1)));
  console.log(">>>>> queryParams", queryParams)
  return <CreateTask key={Date.now()} taskId={queryParams["taskId"] || ""} />;
};

export const TaskShowSection = (props) => {
  return <ShowTask {...props} />;
};

export const TaskEditSection = (props) => {
  const preview = localStorage.getItem('isPreview') || false;
  return <EditTask record={props} preview={preview} />;
};
